import { Player } from "@lottiefiles/react-lottie-player";
import React, { Fragment } from "react";

export default function MyAnimation(props) {
  const { lottieFile, style, loop } = props;

  return (
    <Fragment>
      <Player
        autoplay
        loop={loop ? false : true}
        src={lottieFile}
        style={style}
      />
    </Fragment>
  );
}

import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  rightButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    justifyContent: "flex-end"
  },
  backDrop: {
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  DialogContent: {
    display: "flex",
    paddingLeft: "50px",
    paddingRight: "50px"
  }
}));

function DealExtentionConfirmation(props) {
  const classes = useStyles();
  const { isOpen, handleCancel, handleConfirm } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
    >
      <DialogTitle>
        <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
          {t("common:button.configurationApp.configurationUpload.alert.title")}
        </Typography>
        <Grid container className={classes.rightButton}>
          <Grid item>
            <IconButton aria-label="close" onClick={handleCancel} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={1} className={classes.DialogContent}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            {t(
              "common:button.configurationApp.configurationUpload.alert.subTitle"
            )}
          </Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel} color="primary">
          {t("common:button.cancel")}
        </Button>
        <Button variant="contained" onClick={handleConfirm} color="primary">
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DealExtentionConfirmation;

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
    textAlign: "center"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  content: {
    marginBottom: "140px"
  },
  allowMargin: {
    marginLeft: "13%",
    marginRight: "10%"
  },
  text: {
    color: "#000000"
  },
  buttonSpacing: {
    marginRight: 10
  },
  compareButton: {
    backgroundColor: "#075782",
    boxShadow:
      "0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
  },
  dailogContent: {
    marginBottom: 60
  },
  infoIcon: {
    cursor: "pointer"
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  paper: {
    padding: theme.spacing(2)
  }
}));

export default useStyles;

import React from "react";
import ChaidSelectionType from "./ChaidSelectionType";

function UnSplitCarepacks(props) {
  const { filteredChoices, modelData, handleItemChange, handleQuantityChange } =
    props;
  return (
    <>
      {filteredChoices.map(chaid =>
        ["ELECTCPQ"].includes(chaid.chaId) &&
        chaid.visible &&
        (chaid.precon >= 0 ||
          chaid.isOverridePrecon ||
          chaid.required ||
          chaid.selcon) ? (
          <ChaidSelectionType
            chaid={chaid}
            modelData={modelData}
            handleItemChange={handleItemChange}
            handleQuantityChange={handleQuantityChange}
          />
        ) : null
      )}
    </>
  );
}

export default UnSplitCarepacks;

import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import moment from "moment";
import {
  BUNDLED_COMPONENT,
  CLONING_SKU,
  HP_LOGO
} from "../../components/Configurator/Rsku/Constants";
import { customerSpecificChaIds } from "../../services/bomAndCarePackLib/utils";
import { fetchUiSpecVerticalView } from "../../services/common/utils";
export const isEmpty = val => {
  return val === undefined ||
    val === null ||
    val === "" ||
    val === 0 ||
    val === {} ||
    val === []
    ? true
    : false;
};

export const displayStyledCurrencyLite = (
  data = {
    amount: 0.0,
    currencyArg: "",
    locale: "en-US"
  },
  style = {
    color: "#0096d6"
  }
) => {
  if (isNaN(data.amount) || data.amount === "") {
    return "";
  }

  let styledCurrency = Number(Math.abs(data.amount)).toLocaleString(
    data.locale,
    {
      style: "currency",
      currency: data.currencyArg,
      currencyDisplay: "code"
    }
  );
  let amountNonDecimal = styledCurrency.substring(
    data.currencyArg.length,
    styledCurrency.length - 2
  );
  let decimal = styledCurrency.substring(styledCurrency.length - 2);
  let color = style.color ? style.color : "#0096d6";
  return (
    <span style={{ display: style.displayStyle }}>
      <Typography variant="inherit" style={{ color: color }} component={"span"}>
        {data.amount < 0 ? "-" + amountNonDecimal : amountNonDecimal}
      </Typography>
      <Box
        position="relative"
        top={"-0.2em"}
        fontSize={"0.675em"}
        fontWeight={"normal"}
        component={"span"}
      >
        {decimal}
      </Box>
    </span>
  );
};

export const displayCurrencyLite = price => {
  if (price.value === "0.00") {
    return "0.00";
  }
  let currencySymbol = Number(0)
    .toLocaleString(price.locale, {
      style: "currency",
      currency: price.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, "");
  let styledCurrency = displayCurrency(price);
  return styledCurrency.substring(currencySymbol.length, styledCurrency.length);
};

export const displayCurrency = price => {
  return price.value.toLocaleString("en-US", {
    style: "currency",
    currency: price.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const getCommonRequestConfig = (quote, countryCode, prtnrPortalFlag) => {
  quote = quote || {};
  const quoteRecord = quote.record || {};
  const transactionID = quoteRecord.transactionID_quote
    ? quoteRecord.transactionID_quote
    : "manageConfig";
  const currencyCode = quoteRecord.hpQuoteNumber_quote
    ? quote.currency
      ? quote.currency.value
      : ""
    : getAttributeValue("attribute-currency");
  const routeToMarket = quoteRecord.goToMarketRoute_quote
    ? quoteRecord.goToMarketRoute_quote
    : prtnrPortalFlag
    ? "Indirect"
    : "Direct";
  const incoterms = quoteRecord.incoterms_quote
    ? quoteRecord.incoterms_quote.value
    : getAttributeValue("attribute-incoterms");
  const pricingGeo = quoteRecord.geo_quote
    ? quoteRecord.geo_quote.value
    : getAttributeValue("attribute-pricingGeo");
  const mDCPOrgID =
    Object.keys(quoteRecord).length > 0
      ? quoteRecord.mdcpOrgID_quote
        ? quoteRecord.mdcpOrgID_quote
        : ""
      : getAttributeValue("attribute-mDCPOrgID");
  const locationID =
    Object.keys(quoteRecord).length > 0
      ? quoteRecord.locationID_PrimaryReseller_channel_quote
        ? quoteRecord.locationID_PrimaryReseller_channel_quote
        : ""
      : getAttributeValue("attribute-location");
  const listOfPLForPANumber =
    Object.keys(quoteRecord).length > 0
      ? quoteRecord.productLineDelimitedString_channel_quote
        ? quoteRecord.productLineDelimitedString_channel_quote.replaceAll(
            "##",
            "~"
          )
        : ""
      : getAttributeValue("attribute-listOfPLForPANumber");
  return {
    transactionID,
    currencyCode,
    routeToMarket,
    countryCode,
    incoterms,
    pricingGeo,
    mDCPOrgID,
    prtnrPortalFlag,
    locationID,
    listOfPLForPANumber
  };
};
export const getConfigurationLink = (quote, link) => {
  const quoteRecord = quote && quote.record ? quote.record : {};
  const urlParams =
    quoteRecord && quoteRecord.linkToDocument_quote
      ? quoteRecord.linkToDocument_quote.substring(
          quoteRecord.linkToDocument_quote.indexOf("&")
        )
      : "&action_id=1307461495&document_id=4653823&id=8524672957&version_id=4748762";
  return quoteRecord && quoteRecord.hpQuoteNumber_quote
    ? `${link}&isFixedConfiguration=true${urlParams}`
    : link;
};

export const getAttributeValue = elementId => {
  const element = document.getElementById(elementId);
  return element ? element.value : "";
};

export const getStorageValue = elementId => {
  const element = JSON.parse(localStorage.getItem("bmiData"));
  return element ? element[elementId] : "";
};

export const getPlaceholder = (t, selectedValue) => {
  switch (selectedValue) {
    case "None":
      return t("common:productSelector.textMessage.pleaseEnterNone");
    case "configID":
      return t("common:productSelector.textMessage.pleaseEnterConfigID");
    case "configName":
      return t("common:productSelector.textMessage.pleaseEnterConfigName");
    case "email":
      return t("common:productSelector.textMessage.pleaseEnterEmail");
    case "Customer ID (MDCP ID)":
      return t("common:productSelector.textMessage.pleaseEnterCustomerID");
    case "btoSku":
      return t("common:productSelector.textMessage.pleaseEnterBtoSku");
    default:
      return;
  }
};
export const sortAlphabeticallyByName = (x, y) => {
  let a = x.name.toUpperCase(),
    b = y.name.toUpperCase();
  return a === b ? 0 : a > b ? 1 : -1;
};

export const formatDate = date => {
  return date.slice(6) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4);
};

export const obsoleteDateFormat = date => {
  return date.slice(4, 6) + "/" + date.slice(6) + "/" + date.slice(0, 4);
};

export const dateFormat = date => {
  return date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6);
};

export const gadateFormat = date => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return (
    date?.slice(8) +
    " " +
    monthNames[date?.slice(5, 7) - 1] +
    " " +
    date?.slice(0, 4)
  );
};

export const calculateDays = formatEod => {
  let eod = new Date(formatEod);
  let toDay = new Date();
  let timeDifference = eod.getTime() - toDay.getTime();
  let days = Math.abs(timeDifference / (1000 * 3600 * 24));

  return days;
};

export const showEsAndPriceSource = (
  channelRegion,
  countryCode,
  type,
  configID,
  configOrigin
) => {
  let showEsAndPriceSource;
  if (type === "EM" && !configID && ["AP", "APJ"].includes(channelRegion)) {
    showEsAndPriceSource = true;
  } else if (configOrigin === "OCA") {
    showEsAndPriceSource = true;
  } else {
    showEsAndPriceSource =
      !["EMEA", "EU", "NA"].includes(channelRegion) && countryCode !== "US";
  }
  return showEsAndPriceSource;
};

// If selcon and selected are true.And if its true, we are disabling the checkbox and boldening the text as it is selected.
export const loadingLabel = (
  text,
  loading,
  isTextDisable,
  dateMessage,
  showDateMessage
) =>
  loading ? (
    <div style={{ position: "relative" }}>
      <div>{text}</div>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(50%,-50%)"
        }}
      >
        <HourglassFullIcon style={{ color: "rgb(41, 168, 221)" }} />
      </div>
    </div>
  ) : isTextDisable ? (
    <div style={{ color: "rgba(0, 0, 0, 0.87)" }}> {text}</div>
  ) : (
    <>
      {text}
      {showDateMessage && (
        <div style={{ color: "#9e9e9e", fontWeight: "normal" }}>
          {dateMessage}
        </div>
      )}
    </>
  );

export const delay = timeout => {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
};
export const dateCompare = formatGaD => {
  if (!["", "NA"].includes(formatGaD)) {
    let gaD = moment(formatGaD).format("YYYY/MM/DD");
    let toDay = new Date();
    let date = moment(toDay).format("YYYY/MM/DD");
    return gaD > date;
  } else {
    return false;
  }
};

export const showFutureGADate = (configID, configOrigin, GADate) => {
  const isFutureDate = dateCompare(GADate);
  const showFutureGADateFeature = false; //TODO: Hardcoded to turn off the feature #6289
  return (
    configID &&
    configOrigin === "OCA" &&
    isFutureDate &&
    showFutureGADateFeature
  );
};

export const getHighestDate = (firstDate, secondDate) => {
  if (firstDate === "") {
    return secondDate;
  }
  if (secondDate === "") {
    return firstDate;
  }
  return moment(firstDate).format("YYYY-MM-DD") >
    moment(secondDate).format("YYYY-MM-DD")
    ? firstDate
    : secondDate;
};

export const isFutureDate = date => {
  if (!["", "NA"].includes(date)) {
    const inputDate = moment(date).format("YYYY-MM-DD");
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    return todayDate < inputDate;
  } else {
    return false;
  }
};
export const updateIframeHeight = async (isLoading = false) => {
  await delay(1000);
  if (document.querySelector("#product-selector")) {
    const height = isLoading
      ? window.document.querySelector("body")?.offsetHeight
      : window.document.querySelector("#configContainer")?.offsetHeight;
    document.querySelector("#product-selector").dispatchEvent(
      new CustomEvent("iframeloaded", {
        detail: { height: `${height + 100}` }
      })
    );
  } else if (document.querySelector("#configurator")) {
    const height = isLoading
      ? window.document.querySelector("body")?.offsetHeight
      : window.document.querySelector("#configContainer")?.offsetHeight;
    document.querySelector("#configurator").dispatchEvent(
      new CustomEvent("iframeloaded", {
        detail: { height: `${height + 100}` }
      })
    );
  }
};

export const getFilteredAccessories = products => {
  const currencyCode = getStorageValue("currencyCode");
  const sectionInfo = [
    "carePacks",
    "monitoringAccesories",
    "deploymentServices",
    "manageabilityService",
    "otherServices"
  ];
  const filteredRskuAccesories = products.filter(each =>
    sectionInfo.includes(each.sectionId)
  );
  return filteredRskuAccesories.map(each => {
    return {
      sku: each.partNumber,
      name: each.description,
      listPrice: `${each.listPrice}`,
      quantity: each.qty,
      currency: currencyCode,
      currencySign: currencyCode,
      image: [HP_LOGO]
    };
  });
};

export const updateCheckoutRSKUQuantity = (
  checkoutRskus,
  partNumber,
  skuArray
) => {
  let updatedSkuList = [...checkoutRskus];
  const index = checkoutRskus.findIndex(each => each.sku === partNumber);
  if (index === -1) {
    const newSku = skuArray.find(each => each.sku === partNumber);
    updatedSkuList.push(newSku);
  }
  return updatedSkuList.filter(each => each.quantity > 0);
};

export const getSkuNumber = products => {
  return products.reduce((acc, el) => {
    acc.push(el.sku);
    return acc;
  }, []);
};

export const getCarepackData = (accessories, nonHardwareProducts) => {
  const { products } = nonHardwareProducts;
  const filteredAccesoryImages =
    !isEmpty(products) &&
    Object.keys(products).reduce((acc, ele) => {
      const productDetails = products[ele]?.images?.[0]?.details?.[0];
      const productImage = productDetails?.imageUrlHttps
        ? [productDetails.imageUrlHttps]
        : [HP_LOGO];
      acc[ele] = productImage;
      return acc;
    }, {});
  return accessories.map(each => {
    return {
      ...each,
      image: filteredAccesoryImages
        ? filteredAccesoryImages[each.sku]
        : [HP_LOGO]
    };
  });
};

export const getHardwareAvs = bomData => {
  const avs = bomData.reduce((acc, el) => {
    if (el.sectionId === "hardware") {
      acc.push(el.partNumber);
    }
    return acc;
  }, []);
  return avs;
};

/*  ----Exact Match Products-----  */

export const getBaseProductData = (
  bomData,
  bomThreeCompatibleCarePacksInfo
) => {
  let listPrice;
  listPrice = bomData.reduce((acc, el) => {
    const slicedPartNumber = el.partNumber.slice(0, 2);
    if (
      el.sectionId === "hardware" &&
      !(slicedPartNumber === "YT" && el.choiceId === "")
    ) {
      acc += el.listPrice;
    }
    return acc;
  }, 0);

  listPrice = bomThreeCompatibleCarePacksInfo?.length
    ? bomThreeCompatibleCarePacksInfo?.reduce((carePackListPrice, product) => {
        carePackListPrice += product?.listPrice;
        return carePackListPrice;
      }, listPrice)
    : listPrice;

  const attributes = bomData.reduce((acc, el, index) => {
    if (
      el.sectionId === "hardware" &&
      index <= 6 &&
      !el.partNumber.includes("#")
    ) {
      acc.push({ value: el.description });
    }
    return acc;
  }, []);

  return { listPrice, attributes };
};

export const getProductData = (
  product,
  bomData,
  currency,
  rskuImage,
  bomThreeCompatibleCarePacksInfo
) => {
  const { listPrice, attributes } = getBaseProductData(
    bomData,
    bomThreeCompatibleCarePacksInfo
  );
  let requestRskuProduct = {};
  requestRskuProduct.sku = product.skuNumber
    ? product.skuNumber
    : product.rskuMaterialNumber;
  requestRskuProduct.name = product.ShortDescription
    ? product.ShortDescription
    : product.shortDescription;
  requestRskuProduct.listPrice = parseFloat(listPrice).toFixed(2);
  requestRskuProduct.attributes = attributes;
  requestRskuProduct.currency = currency;
  requestRskuProduct.currencySign = currency;
  requestRskuProduct.quantity = 0;
  requestRskuProduct.image = rskuImage[0];
  requestRskuProduct.isRSKUFinalized = Boolean(product.isRSKUFinalized);
  return [requestRskuProduct];
};

/*  ----Product Skeleton-----  */

export const generatArrayOfValues = count => {
  return [...Array(count).keys()].map(() => Math.floor(Math.random() * 100));
};

/*  ----Close Match Products-----  */

export const mergeAttributesByValue = (
  difference,
  other,
  differValue,
  otherValue
) => {
  const filteredDifferences = [];
  const filteredOtherAttributes = other?.length
    ? other.filter(each => {
        if (difference?.length) {
          let findDuplicate = difference.find(
            item => item.partNumber === each.partNumber
          );
          if (!findDuplicate) {
            return each;
          } else {
            filteredDifferences.push({
              ...findDuplicate,
              listPrice: each.listPrice
            });
          }
        } else {
          return each;
        }
      })
    : [];
  const filterOtherValue = otherValue
    ? otherValue
    : filteredOtherAttributes.length;
  const filterDifferValue = differValue ? differValue : difference.length;
  const attributes = other?.length
    ? [
        ...filteredOtherAttributes.slice(0, filterOtherValue),
        ...filteredDifferences.slice(0, filterDifferValue)
      ]
    : [...filteredDifferences.slice(0, filterDifferValue)];
  return attributes;
};

export const getAttributes = (bomDetail, typeOfAv, typeOfAttribute) => {
  return bomDetail.reduce((acc, ele) => {
    let checkType =
      typeOfAv === "localizationAvs"
        ? ele.productNumber.includes("#")
        : !ele.productNumber.includes("#");
    if (checkType) {
      acc.push({
        value: ele.shortDescription,
        partNumber: ele.productNumber,
        highlight: typeOfAttribute === "difference" ? true : false,
        listPrice: !isEmpty(ele.listPrice) ? ele.listPrice : 0,
        quantity: 1,
        sectionId: !isEmpty(ele.sectionId) ? ele.sectionId : ""
      });
    }
    return acc;
  }, []);
};

export const getSimilarProductData = (
  currency,
  closeMatchSkus,
  rskuImage,
  difference,
  other
) => {
  const closeMatchProducts = closeMatchSkus.slice(0, 3).map(each => {
    const { closeMatchDifference, closeMatchComponents } = each;
    let allDifferences = [];
    let allOtherComponent = [];

    if (closeMatchDifference && closeMatchDifference.bomDetail?.length) {
      const localizationDifferences = getAttributes(
        closeMatchDifference.bomDetail,
        "localizationAvs",
        "difference"
      );

      const nonLocalizationDifferences = getAttributes(
        closeMatchDifference.bomDetail,
        "nonLocalizationAvs",
        "difference"
      );

      allDifferences = mergeAllBomAttributes(
        localizationDifferences,
        nonLocalizationDifferences,
        "difference"
      );
    }

    if (closeMatchComponents && closeMatchComponents.bomDetail?.length) {
      const localizationComponent = getAttributes(
        closeMatchComponents.bomDetail,
        "localizationAvs",
        "other"
      );

      const nonLocalizationComponent = getAttributes(
        closeMatchComponents.bomDetail,
        "nonLocalizationAvs",
        "other"
      );

      allOtherComponent = mergeAllBomAttributes(
        localizationComponent,
        nonLocalizationComponent,
        "other"
      );
    }

    const attributes = mergeAttributesByValue(
      allDifferences,
      allOtherComponent,
      difference,
      other
    );
    return {
      sku: each.skuNumber,
      name: each.shortDescription,
      attributes: attributes,
      image: rskuImage[0],
      listPrice: each.closeMatchComponents?.totalListPrice
        ? each.closeMatchComponents?.totalListPrice.toFixed(2)
        : "0.00",
      currency: currency,
      currencySign: currency,
      quantity: 0,
      isRSKUFinalized: each.isRSKUFinalized,
      isBOM3: Boolean(each.isBom3)
    };
  });
  return closeMatchProducts;
};

/*  ----BOM Details With All The Hardware Products-----  */

export const getUniqueAttributes = attributes => {
  return [...new Map(attributes.map(item => [item.partNumber, item])).values()];
};

export const mergeAllBomAttributes = (
  localizationAttriutes,
  allAttributes,
  typeOfAttribute
) => {
  const highlightValue = typeOfAttribute === "difference" ? true : false;
  const uniqueAttributes = getUniqueAttributes(allAttributes);
  const uniqueLocalizationAttriutes = getUniqueAttributes(
    localizationAttriutes
  );
  const filteredAttributes = uniqueAttributes.map(each => {
    const findDuplicate = uniqueLocalizationAttriutes.find(item =>
      item.partNumber.includes(each.partNumber)
    );
    if (findDuplicate) {
      return {
        value: findDuplicate.value,
        highlight: highlightValue,
        partNumber: findDuplicate.partNumber,
        listPrice: each.listPrice,
        quantity: 1,
        sectionId: findDuplicate.sectionId
      };
    } else {
      return {
        value: each.value,
        highlight: highlightValue,
        partNumber: each.partNumber
      };
    }
  });
  const attributes = uniqueLocalizationAttriutes?.length
    ? getUniqueAttributes(
        filteredAttributes.concat(uniqueLocalizationAttriutes)
      )
    : filteredAttributes;
  return attributes;
};

export const getAllBomDetails = (bomData, rskuImage, bomThreeData) => {
  const filteredBomData = bomData.find(each => each.choiceId === "BUNIT");

  const filteredLocalizationAttributes = bomData.reduce((acc, ele) => {
    if (ele.partNumber.includes("#")) {
      acc.push({
        value: ele.description,
        partNumber: ele.partNumber
      });
    }
    return acc;
  }, []);

  const allAttributes = bomData.reduce((acc, el) => {
    if (el.sectionId === "hardware" && !el.partNumber.includes("#")) {
      acc.push({
        value: el.description,
        partNumber: el.partNumber
      });
    }
    return acc;
  }, []);

  const bomThreeAttributes = bomThreeData?.length
    ? bomThreeData.map(product => {
        return {
          value: product.description,
          highlight: false,
          partNumber: product.partNumber
        };
      })
    : [];

  const bomAttributes = mergeAllBomAttributes(
    filteredLocalizationAttributes,
    allAttributes,
    "other"
  );

  const attributes = bomThreeAttributes?.length
    ? [...bomAttributes, ...bomThreeAttributes]
    : bomAttributes;

  const sku = filteredBomData.partNumber;
  const name = filteredBomData.description;
  const quantity = filteredBomData.qty;
  const image = rskuImage[0];

  return { attributes, sku, name, quantity, image };
};

export const getMandatorySkus = (mandatory, bomData) => {
  const filteredChoiceId = bomData.filter(item =>
    mandatory.includes(item.choiceId)
  );
  const filteredAvs = filteredChoiceId.reduce((acc, el) => {
    acc.push(el.partNumber);
    return acc;
  }, []);

  return filteredAvs;
};

export const shouldShowOnUI = (filterParams, gaDate, selected, partno) => {
  let flag = true;
  if (!selected) {
    flag = !(
      filterParams &&
      filterParams.currentConfigID &&
      !filterParams.showGADate &&
      gaDate &&
      dateCompare(gaDate)
    );
  }
  return flag;
};

export const getFilteredBomData = bomData => {
  const filteredBom = bomData.map(each => {
    return {
      productNumber: each.partNumber,
      shortDescription: each.description,
      listPrice: each.listPrice,
      sectionId: each.sectionId
    };
  });
  const localizatiedBomData = getAttributes(
    filteredBom,
    "localizationAvs",
    "other"
  );

  const nonLocalizatedBomData = getAttributes(
    filteredBom,
    "nonLocalizationAvs",
    "other"
  );

  const filteredBomAttributes = mergeAllBomAttributes(
    localizatiedBomData,
    nonLocalizatedBomData,
    "other"
  );
  return filteredBomAttributes;
};

export const getProductAttribute = bom => {
  return {
    partNumber: bom.partNumber,
    description: bom.description,
    quantity: bom.quantity ? bom.quantity : bom.qty,
    listPrice: bom.listPrice.toFixed(2).toString(),
    plcOverride: false,
    requestedBDNetPrice: "150.00"
  };
};

export const getCloseMatchBomData = closeMatchSkus => {
  const closeMatchBom = closeMatchSkus.slice(0, 3).map(each => {
    const { closeMatchComponents } = each;
    let attributes = [];
    if (closeMatchComponents && closeMatchComponents.bomDetail?.length) {
      attributes = closeMatchComponents.bomDetail.map(ele => {
        return {
          partNumber: ele.productNumber,
          description: ele.shortDescription,
          listPrice: !isEmpty(ele.listPrice) ? ele.listPrice : 0,
          quantity: 1
        };
      });
    }

    return {
      sku: each.skuNumber,
      attributes: attributes
    };
  });

  return closeMatchBom;
};

export const baseAndLocalizedProduct = (components, partNumber) => {
  let isBaseProduct;
  if (partNumber.includes("#")) {
    const baseProduct = partNumber.split("#")[0];
    isBaseProduct = components.find(each => each.partNumber === baseProduct);
  }
  return isBaseProduct;
};

export const validateRsku = (btoProducts, ctoProducts, rskuAnalytics) => {
  let bto = [];
  let cto = {};
  if (!isEmpty(btoProducts)) {
    bto = btoProducts.reduce((acc, ele) => {
      ele.isRSKU = true;
      if (ele.sku.includes("#") && ele.isRSKUFinalized) {
        rskuAnalytics.rsku_status = [
          ...rskuAnalytics?.rsku_status,
          ele.isRSKUFinalized
        ];
        rskuAnalytics.quantity = [...rskuAnalytics?.quantity, ele?.quantity];
        rskuAnalytics.sku_id = [...rskuAnalytics?.sku_id, ele?.sku];

        const baseProduct = {
          ...ele,
          sku: ele.sku.split("#")[0]
        };
        const localizedProduct = {
          ...ele,
          listPrice: "0.00"
        };
        acc.push(baseProduct, localizedProduct);
      } else {
        acc.push(ele);
      }
      return acc;
    }, []);
  }

  if (!isEmpty(ctoProducts) && !isEmpty(ctoProducts.components)) {
    const { components, quantity, configID } = ctoProducts;
    rskuAnalytics.sku_id = [...rskuAnalytics.sku_id, ...configID];
    rskuAnalytics.quantity = [...rskuAnalytics.quantity, ...quantity];
    rskuAnalytics.rsku_status = [
      ...rskuAnalytics.rsku_status,
      ...new Array(configID?.length).fill(!Boolean)
    ];
    const filteredComponents = components?.length
      ? components.reduce((acc, ele) => {
          const attributes = ele.reduce((ac, el) => {
            if (
              el.partNumber.includes("#") &&
              isEmpty(baseAndLocalizedProduct(ele, el.partNumber))
            ) {
              const baseProduct = {
                ...el,
                partNumber: el.partNumber.split("#")[0]
              };
              const localizedProduct = {
                ...el,
                listPrice: "0.00"
              };
              ac.push(baseProduct, localizedProduct);
            } else {
              ac.push(el);
            }
            return ac;
          }, []);
          acc.push(getUniqueAttributes(attributes));
          return acc;
        }, [])
      : [];
    cto = {
      ...ctoProducts,
      components: filteredComponents
    };
  }

  rskuAnalytics.rsku_status = rskuAnalytics?.rsku_status?.length
    ? [...rskuAnalytics.rsku_status]
        .map(each => (each ? "Finalized" : "Not Finalized"))
        .join(";")
    : [];
  rskuAnalytics.quantity = rskuAnalytics?.quantity?.length
    ? [...rskuAnalytics.quantity].join(";")
    : [];
  rskuAnalytics.sku_id = rskuAnalytics?.sku_id?.length
    ? [...rskuAnalytics.sku_id].join(";")
    : [];
  return { bto, cto, skuAnalytics: rskuAnalytics };
};

export const errorCodeFilter = (icConflicts, code) => {
  return icConflicts?.find(item => item.code === code);
};
export const chaIdCheck = (uiChaId, chaId, itemConflictPriority) => {
  if (itemConflictPriority !== undefined) {
    return ["ELECTCPQ"].includes(chaId)
      ? uiChaId === "99999" &&
        itemConflictPriority <= 5 &&
        itemConflictPriority >= 1
        ? true
        : uiChaId === "99998"
      : uiChaId === chaId;
  } else {
    return ["99998", "99999"].includes(uiChaId)
      ? ["ELECTCPQ"].includes(chaId)
      : uiChaId === chaId;
  }
};
export const icConflictsFilter = (icConflicts, chaid) => {
  const icFind = icConflicts?.find(item =>
    chaid?.items?.find(
      chaidItem =>
        chaidItem.partno === item.partNo && chaIdCheck(chaid.chaId, item.chaId)
    )
  );
  return icFind;
};

export const getMandaChaid = chaId => {
  if (chaId.startsWith("CAPQ_") && !chaId.startsWith("CAPQ_P_")) {
    let index = chaId.indexOf("_recommended");
    if (index !== -1) {
      return chaId.substring(0, index);
    }
  }
  return chaId;
};

export const mandaConflictsFilter = (conflicts, chaid) => {
  const conflictsFind = conflicts?.find(
    item => item.chaId === getMandaChaid(chaid.chaId)
  );
  return conflictsFind;
};

export const transformDateFormat = (chosenDate, format) => {
  return chosenDate ? dayjs(chosenDate).format(format) : "";
};

export const showAddToQuoteBtnWithGADate = products => {
  const isInValid = products.some(
    item =>
      ["99990101"].includes(item.gaDate) ||
      item.gaDate === "" ||
      (item.gaDate === "" && item.plcStatus !== "V") ||
      ["99990101"].includes(item.emDate) ||
      (item.emDate === "" && item.plcStatus !== "V") ||
      (item.emDate !== "" && item.emDate < moment().format("YYYYMMDD")) ||
      ["", "N/A"].includes(item.listPrice) ||
      item.notes.includes("Not offered for CTO")
  );
  return !isInValid;
};

export const getMaxGaDateFromItems = items => {
  let maxGaDate = "";
  items.forEach(item => {
    if (dateCompare(item?.gaDate)) {
      maxGaDate =
        maxGaDate && maxGaDate > item?.gaDate ? maxGaDate : item?.gaDate;
    }
  });
  return maxGaDate;
};

export const checkAllGaDateBeforeSystemDate = items => {
  return items.every(
    item =>
      moment().format("YYYY-MM-DD") > moment(item.gaDate).format("YYYY-MM-DD")
  );
};

export const bomThreeInfoMessage = (
  selectedItemsPartNo,
  chaid,
  choiceBannerStyles,
  t
) => {
  for (let i = 0; i < chaid.items.length; i++) {
    if (
      selectedItemsPartNo.includes(chaid.items[i].partno) &&
      BUNDLED_COMPONENT.includes(chaid.items[i].bndlBomType)
    ) {
      return (
        <Box className={choiceBannerStyles}>
          <Typography align="center" variant="subtitle2">
            {t("common:configurator:bom3Compatible")}
          </Typography>
        </Box>
      );
    }
  }
  return null;
};

export const filteredRskuBomList = (bomInfo, status) => {
  return bomInfo.reduce((acc, ele) => {
    acc.push(status === "rSku" ? ele.materialNum : ele.productNumber);
    return acc;
  }, []);
};

export const validateSkuIDSearch = (searchValue, userRegion) => {
  const skuVersion = searchValue?.split("#")[0].slice(-2);
  const isValidSkuId = CLONING_SKU[userRegion] === skuVersion.toUpperCase();
  const validSkuVersion = CLONING_SKU[userRegion];
  return { isValidSkuId, validSkuVersion };
};
export const checkSectionID = (sectionIds, bomData) => {
  const filteredSectionId = bomData?.length
    ? bomData?.filter(
        product =>
          (sectionIds?.includes(product?.sectionId) &&
            (!BUNDLED_COMPONENT.includes(product.bndlBomType) ||
              (BUNDLED_COMPONENT.includes(product.bndlBomType) &&
                product.sectionId === "configurationService"))) ||
          (product?.choiceId === "" && product.partNumber.slice(0, 2) === "YT")
      )
    : [];
  const standardCarePacks = bomData?.length
    ? bomData?.filter(
        product =>
          product?.sectionId === "carePacks" &&
          !BUNDLED_COMPONENT.includes(product.bndlBomType)
      )
    : [];

  const bomThreeCompatiblePartNumbers = [];
  const bomThreeCompatibleCarePacksInfo = [];

  const bomThreeCompatibleCarePacks = bomData?.length
    ? bomData?.reduce((carePackList, product) => {
        if (
          BUNDLED_COMPONENT.includes(product.bndlBomType) &&
          product?.sectionId !== "configurationService"
        ) {
          carePackList.push({
            productNumber: product.partNumber,
            description: product.description
          });
          bomThreeCompatibleCarePacksInfo.push(product);
          bomThreeCompatiblePartNumbers.push(product.partNumber);
        }
        return carePackList;
      }, [])
    : [];

  const rSKUBom = bomData?.length
    ? bomData?.filter(product => {
        const slicedPartNumber = product.partNumber.slice(0, 2);

        if (
          !sectionIds.includes(product?.sectionId) &&
          (product.choiceId !== "" || slicedPartNumber !== "YT") &&
          !BUNDLED_COMPONENT.includes(product.bndlBomType)
        ) {
          return product;
        }
      })
    : [];
  const grandPrice = rSKUBom.reduce((sum, f) => sum + f.listPrice, 0);
  const hasOtherComponent = Boolean(filteredSectionId?.length);
  let warrentyDescription = rSKUBom.find(
    bom => bom.choiceId === "WRRTY"
  )?.description;
  warrentyDescription = !isEmpty(warrentyDescription)
    ? warrentyDescription
    : "";

  return {
    filteredSectionId,
    hasOtherComponent,
    rSKUBom,
    grandPrice,
    standardCarePacks,
    bomThreeCompatibleCarePacks,
    bomThreeCompatiblePartNumbers,
    bomThreeCompatibleCarePacksInfo,
    warrentyDescription
  };
};

export const getRskuImages = baseProductImages => {
  const sortingOrder = ["center", "left", "right"];
  baseProductImages = baseProductImages
    .slice()
    .sort(
      (prev, curr) =>
        sortingOrder.indexOf(prev.orientation.toLowerCase()) -
        sortingOrder.indexOf(curr.orientation.toLowerCase())
    );
  const baseImages = baseProductImages.reduce((images, product) => {
    images.push(product.httpsUrl);
    return images;
  }, []);
  return baseImages;
};

export function updatedModelData(modelJson, t) {
  const newModelData = { ...modelJson };
  // If there are plcConflicts,
  // put the data in newModelData.Items respective items
  if (modelJson) {
    if (newModelData.conflicts[0]["plcConflicts"]) {
      newModelData.conflicts[0]["plcConflicts"].forEach(c => {
        newModelData.Items[c.itemiNode].plcMessage = t(
          "common:configurator.componentNotAvailable"
        );
      });
    }
    return newModelData;
  }
}

export const checkStoreFront = modelData => {
  let isHP2BOrECOMMConfig = false;
  const bappchoice = modelData.Chaids.filter(each => each.chaId === "BAPP")[0];
  if (bappchoice) {
    for (let i = bappchoice?.firstItem; i <= bappchoice?.lastItem; i++) {
      const item = modelData.Items[i];
      if (
        item.selected &&
        ["HP2B WCS", "HP2B US Public Sector", "HP eCommerce"].includes(
          item.partdes
        )
      ) {
        isHP2BOrECOMMConfig = modelData.Items[i].selected;
        break;
      }
    }
  }
  return isHP2BOrECOMMConfig;
};
export function scrollToChoice({
  chaId,
  iNodeORCarepacksErr,
  modelData,
  defaultConfigStepSlected,
  isAutoScrollEnabled
}) {
  if (isAutoScrollEnabled === false) return;
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected);
  const chaIdFilter = chaId => {
    if (
      iNodeORCarepacksErr === "topRecommend" ||
      (isVerticalView === "Y" &&
        ["ELECTCPQ"].includes(chaId) &&
        document.querySelectorAll(".data-99999")[0]?.dataset?.order === "show")
    ) {
      return (chaId = "99999");
    } else if (
      iNodeORCarepacksErr === "additional" ||
      (isVerticalView === "Y" &&
        ["ELECTCPQ"].includes(chaId) &&
        document.querySelectorAll(".data-99998")[0]?.dataset?.order === "show")
    ) {
      return (chaId = "99998");
    } else if (
      (chaId === "ABSCP" || chaId === "PSE") &&
      isVerticalView === "N"
    ) {
      return (chaId = "SSS99");
    } else if (chaId === "ADP") {
      return (chaId = "ACPS99");
    } else if (chaId === "SUPPEXT_CP" || chaId === "SEE") {
      return (chaId = "SE99");
    } else if (customerSpecificChaIds.includes(chaId)) {
      return (chaId = "CSS99");
    }
    return chaId;
  };
  const inode = iNodeORCarepacksErr;
  let targetSection;

  if (inode && !isNaN(inode)) {
    const items =
      modelData?.Items[inode]?.precon < 0 && modelData?.Items[inode]?.selected;
    if (items) {
      targetSection = document.getElementById(inode);
    }
  } else {
    targetSection = document.getElementById("chaid-" + chaIdFilter(chaId));
  }
  if (targetSection) {
    let targetPosition = targetSection.offsetTop;
    const scrollParent = targetSection.closest("[role=dialog]");
    if (scrollParent) scrollParent.scrollTop = targetPosition;
    else {
      if (window !== window.parent) {
        const parentWindow = window.parent;
        const parentDocument = parentWindow?.document;
        const iframeOffset = parentDocument.querySelector("iframe").offsetTop;
        parentWindow.scrollTo({
          top: iframeOffset + targetPosition - 64,
          behavior: "smooth"
        });
      }
    }
  }
}

export const updateExpandAllChoicePayload = (
  expandAll,
  chaid,
  expanded,
  defaultConfigStepSlected
) => {
  const relevantExpandData = expandAll.find(
    expandedData => expandedData.tab === defaultConfigStepSlected
  );
  if (relevantExpandData && relevantExpandData.choicesExpandState) {
    relevantExpandData.choicesExpandState[chaid] = !expanded;
  }
  if (
    defaultConfigStepSlected === "carePacks" &&
    (chaid.chaId === "99999" || chaid.chaId === "99998")
  ) {
    if (relevantExpandData.choicesExpandState.hasOwnProperty("ELECTCPQ"))
      relevantExpandData.choicesExpandState["ELECTCPQ"] = !expanded;
  }
  const payload = {
    tab: defaultConfigStepSlected,
    choicesExpandState: relevantExpandData.choicesExpandState
  };
  return payload;
};

export const getRskuBomList = bomData => {
  const futureGAProductInfo = [];
  const rskuBomList = bomData.reduce((bomInfo, product) => {
    const slicedPartNumber = product.partNumber.slice(0, 2);

    if (
      (product.sectionId === "hardware" &&
        !(slicedPartNumber === "YT" && product.choiceId === "")) ||
      (BUNDLED_COMPONENT.includes(product.bndlBomType) &&
        product.sectionId !== "configurationService")
    ) {
      bomInfo.push({
        productNumber: product.partNumber,
        description: product.description,
        quantity: product.qty,
        isBaseUnit: product.choiceId === "BUNIT",
        choiceId: product.choiceId,
        choiceDescription: product.choiceName
      });
      if (product.isFutureItem) {
        futureGAProductInfo.push({
          partNumber: product.partNumber,
          description: product.description
        });
      }
    }
    return bomInfo;
  }, []);
  return { rskuBomList, futureGAProductInfo };
};

export const getFilteredConfigBom = (configBOM, bomList) => {
  const rskuBomList = bomList.map(bom => bom.productNumber);

  return configBOM.filter(product =>
    rskuBomList.includes(product.materialNumber)
  );
};

export const isChaidExpanded = ({
  isExpandAll,
  isChaidExpand,
  chaid,
  defaultConfigStepSlected
}) => {
  if (isExpandAll) {
    return !Object.keys(isChaidExpand).length
      ? true
      : isChaidExpand[defaultConfigStepSlected] &&
          (chaid in isChaidExpand[defaultConfigStepSlected]
            ? isChaidExpand[defaultConfigStepSlected][chaid]
            : true);
  } else {
    return false;
  }
};

export const scrollToStepper = stepIndex => {
  const ele = document.querySelector(`#stepper-${stepIndex}`);
  const scrollParent = ele.closest("[role=dialog]");
  const targetPosition = ele.offsetTop;
  if (scrollParent) {
    scrollParent.scrollTop = targetPosition;
  } else {
    if (window !== window.parent) {
      const parentWindow = window.parent;
      const parentDocument = parentWindow?.document;
      const iframeOffset = parentDocument.querySelector("iframe").offsetTop;
      parentWindow.scrollTo({
        top: iframeOffset + targetPosition - 64,
        behavior: "smooth"
      });
    }
  }
};

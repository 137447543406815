import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  chaIdCheck,
  errorCodeFilter,
  icConflictsFilter
} from "../lib/common/util";
import { handleDuplicateItemConflicts, shouldShowLeastPriorityConflicts } from "../services/common/utils";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important",
    fontSize: theme.typography.pxToRem(12)
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: 400,
    padding: "8px 0px 0px 0px"
  },
  secondaryHeading: {
    "&.MuiTypography-root": {
      fontSize: theme.typography.pxToRem(16),
      color: "#000000",
      fontWeight: 300,
      padding: "8px 0px 0px 18px"
      // marginLeft: "1.5rem"
    }
  },
  choiceLevelErrmsgCls: {
    backgroundColor: "red",
    color: "white",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "grid !important"
  },
  choiceLevelMarginTop: {
    marginTop: "0px !important"
  },
  warningStyle: {
    backgroundColor: "#ffbf00",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  warningErrorStyle: {
    backgroundColor: "#FD0032",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  caption: {
    paddingTop: "7px"
  },
  currencyStyle: {
    fontWeight: "bold",
    fontSize: "12px"
  },
  captionStyle: {
    fontSize: "12px",
    fontWeight: "normal",
    paddingTop: "3px"
  },
  choiceBannerStyles: {
    backgroundColor: "#0096D6",
    color: "#FFFFFF",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    padding: "8px 16px"
  },
  banner: {
    backgroundColor: "#0096D6",
    color: "#FFFFFF",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "grid !important"
  }
}));
const ErrorPart = ({ chaid, model, expanded, setMargin }) => {
  const expandedMargin = { margin: "-12px -41px 15px -16px" };
  const collapsedMargin = { margin: "-13px -41px 15px -16px" };
  const parentMargin = { margin: "8px 0px -15px 0px " };
  const classes = useStyles();
  const { t } = useTranslation();
  const errorData = useSelector(state => state.configFilterData.errorData);
  const loadingSelection = useSelector(
    state => state.configFilterData.loadingSelection
  );
  const localizationChDes = {
    SYS_LOC_OPTION: "System Localization Options",
    OS_LOC_OPTION: "OS Localization Options",
    KYBD_LOC_OPTION: "Keyboard Localization Options"
  };

  const isLoadingSelection =
    model &&
    model.Items &&
    model.Items.some(item => item.inode === loadingSelection);

  let objCause;
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.some(res => {
      if (res.effect.chaId === chaid.chaId) {
        objCause =
          model?.conflicts[0]?.itemConflictsList.length > 0 && res.cause;
        return true;
      } else if (
        chaid.chaId === "99999" &&
        (res.effect.chaId.includes("ELECTCPQ"))
      ) {
        let RecommendErr = document.querySelectorAll(".data-99999");
        if (RecommendErr[0]?.dataset?.order === "show") {
          objCause =
            model?.conflicts[0]?.itemConflictsList.length > 0 && res.cause;
          return true;
        } else return false;
      } else if (
        chaid.chaId === "99998" &&
        (res.effect.chaId.includes("ELECTCPQ"))
      ) {
        let additionalErr = document.querySelectorAll(".data-99998");
        if (additionalErr[0]?.dataset?.order === "show") {
          objCause =
            model?.conflicts[0]?.itemConflictsList.length > 0 && res.cause;
          return true;
        } else return false;
      } else return false;
    });

  const getChaid = chaid => {
    if (chaid.chaId.startsWith("CAPQ_")) {
      let index = chaid.chaId.indexOf("_additional");
      if (index !== -1) {
        chaid = chaid.chaId.substring(0, index);
      } else {
        index = chaid.chaId.indexOf("_recommended");
        if (index !== -1) chaid = chaid.chaId.substring(0, index);
      }
    }
    return chaid;
  };

  let effects = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.map(itemConflict => {
      if (itemConflict.effect.chaId === chaid.chaId) {
        !effects.includes(itemConflict.effect.chaDes) &&
          effects.push(itemConflict.effect.chaDes);
        return true;
      } else return false;
    });
  const updatedItemConflictsList = handleDuplicateItemConflicts(model);
  return (
    <>
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        model &&
        model.conflicts &&
        model.conflicts[0].icConflicts &&
        model.conflicts[0].icConflicts.length > 0 &&
        model.conflicts[0].icConflicts.map(res =>
          res.code === "ERR04" && res.chaId === getChaid(chaid).chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${t(
                    "common:configurator:plcConflict1"
                  )} ${chaid.chaDes.toUpperCase()} ${t(
                    "common:configurator:currentConflict5"
                  )} `}
                </Typography>
              </Box>
            </div>
          ) : null
        )}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
      !objCause &&
      effects.length === 0 &&
      model &&
      model.conflicts &&
      model.conflicts[0].icConflicts &&
      (errorCodeFilter(model?.conflicts[0]?.icConflicts, "ERR03") ||
        errorCodeFilter(model?.conflicts[0]?.icConflicts, "ERR10")) &&
      icConflictsFilter(model?.conflicts[0]?.icConflicts, chaid) ? (
        <div
          className={classes.choiceLevelErrmsgCls}
          style={
            setMargin
              ? parentMargin
              : expanded
              ? expandedMargin
              : collapsedMargin
          }
        >
          <Box className={classes.warningErrorStyle} padding={1}>
            <Typography align="center" variant={"subtitle2"}>
              {`${t("common:configurator:currentConflict1")} ${
                chaid.chaDes
              } ${t("common:configurator:currentConflict2")} `}
            </Typography>
          </Box>
        </div>
      ) : null}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        model &&
        model.conflicts &&
        model.conflicts[0].counterConflicts &&
        model.conflicts[0].counterConflicts.map(res =>
          res.code === "ERR05" && res.chaId === getChaid(chaid).chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${res.chaDes.toUpperCase()} ${t(
                    "common:configurator:pcieConflict4"
                  )} ${res?.partNos.map(item => " " + item)}.`}
                </Typography>
              </Box>
            </div>
          ) : res.code === "ERR07" && res.chaId === getChaid(chaid).chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${
                    res?.chaDes?.includes("Poly")
                      ? t("common:configurator:mismatchQuantity1").replace("{0}","Poly")
                      : t("common:configurator:mismatchQuantity1").replace("{0}","")
                  } (${res.qty[0]}) ${
                    res?.chaDes?.includes("Poly")
                      ? t("common:configurator:mismatchQuantity2").replace("{0}","Poly")
                      : t("common:configurator:mismatchQuantity2").replace("{0}","")
                  } (${res.qty[1]}) ${t(
                    "common:configurator:mismatchQuantity3"
                  )}`}
                </Typography>
              </Box>
            </div>
          ) : null
        )}
      {/* Handling PLC conflict here */}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        model &&
        model.conflicts &&
        model.conflicts[0].plcConflicts &&
        model.conflicts[0].plcConflicts
          .map(res =>
            res.code === "ERR06" &&
            (res.chaId === getChaid(chaid).chaId ||
              chaid?.items?.some(
                choice =>
                  res.partNo === choice.partno && res.itemiNode === choice.inode
              )) ? (
              <div
                className={classes.choiceLevelErrmsgCls}
                style={
                  setMargin
                    ? parentMargin
                    : expanded
                    ? expandedMargin
                    : collapsedMargin
                }
              >
                <Box className={classes.warningErrorStyle} padding={1}>
                  <Typography align="center" variant={"subtitle2"}>
                    {`${t("common:configurator:plcConflict1")} ${
                      chaid.chaId.includes("CAPQ")
                        ? t("common:configurator:plcConflictManda")
                        : ""
                    } ${chaid.chaDes.toUpperCase()} ${t(
                      "common:configurator:plcConflict2"
                    )} `}
                  </Typography>
                </Box>
              </div>
            ) : null
          )
          .find(err => err)}

      {/* Handling GA-Date conflict here */}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        model &&
        model.conflicts &&
        model.conflicts[0].gaDateConflicts &&
        model.conflicts[0].gaDateConflicts.map(res =>
          res.code === "ERR10" && res.chaId === getChaid(chaid).chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${t(
                    "common:configurator:gaDateConflict1"
                  )} ${chaid.chaId.toUpperCase()} ${t(
                    "common:configurator:gaDateConflict2"
                  )} `}
                </Typography>
              </Box>
            </div>
          ) : null
        )}
      {/* error part ends */}
      {/* Handling itemConflicts conflict here */}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        updatedItemConflictsList &&
        updatedItemConflictsList.conflicts &&
        updatedItemConflictsList.conflicts[0]?.itemConflictsList &&
        updatedItemConflictsList.conflicts[0]?.itemConflictsList?.map(res =>
          chaIdCheck(
            chaid.chaId,
            res.effect.chaId,
            model.Items[res.effect.iitem]?.priority
          ) ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
              key={res.chaId}
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {res.effect.chaId === res.cause.chaId
                    ? `${t("common:configurator:currentConflict1")}  ${
                        chaid.chaDes
                      } ${t("common:configurator:currentConflict6")} `
                    : `${t("common:configurator:currentConflict1")} ${
                        chaid.chaDes
                      }
                           ${t("common:configurator:currentConflict3")}  ${
                        res.cause.chaDes !== ""
                          ? res.cause.chaDes
                          : localizationChDes[res.cause.chaId] || ""
                      } - ${t("common:configurator:currentConflict4")} `}
                </Typography>
              </Box>
            </div>
          ) : null
        )}

      {/* {Handeling hiddenChoiceConflicts error here } */}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        shouldShowLeastPriorityConflicts(model) &&
        model.conflicts[0]?.hiddenChoiceConflicts &&
        model.conflicts[0]?.hiddenChoiceConflicts?.map(res =>
          res.cause.chaId === chaid.chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
              key={res.chaId}
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${t("common:configurator:currentConflict1")} ${chaid.chaDes}
                           ${t("common:configurator:hiddenChoiceConflict")} `}
                </Typography>
              </Box>
            </div>
          ) : null
        )}
      {/* error part ends */}
      {/* {Handeling choiceConflicts error here } */}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        shouldShowLeastPriorityConflicts(model) &&
        model.conflicts[0]?.choiceConflicts &&
        model.conflicts[0]?.choiceConflicts?.map(choiceConflict =>
          (choiceConflict.effectChoice.chaId === "ELECTCPQ" &&
            ["99998", "99999"].includes(chaid.chaId)) ||
            choiceConflict.effectChoice.chaId === chaid.chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
              key={choiceConflict.chaId}
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${t("common:configurator:currentConflict1")} ${chaid.chaDes}
                         ${t("common:configurator:currentConflict3")}  ${
                          choiceConflict.cause.chaDes
                  } - ${t("common:configurator:currentConflict4")} `}
                </Typography>
              </Box>
            </div>
          ) : null
        )}
      {/* error part ends */}
      {/* Handling choice level messages here */}
      {model?.icMsgs?.choiceMsgs?.map(choiceMsg =>
        choiceMsg?.choiceId === chaid?.chaId && choiceMsg.msgs.length > 0 ? (
          <div
            className={classes.banner}
            style={
              setMargin
                ? parentMargin
                : expanded
                ? expandedMargin
                : collapsedMargin
            }
          >
            <Box className={classes.choiceBannerStyles}>
              {choiceMsg.msgs?.map(choiceMsg => (
                <Typography align="center" variant="subtitle2">
                  {choiceMsg}
                </Typography>
              ))}
            </Box>
          </div>
        ) : null
      )}
      {/* Choice level ends */}
    </>
  );
};

export default ErrorPart;

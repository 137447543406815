import {
  BOM_SELECTION,
  CHECKED_FILTER_PARTS,
  CONFIG_STEP_SELECTION,
  CONFIG_VERTICAL_STEP_SELECTION,
  LOAD_CONFIG_STEPPER,
  SET_ACCESS_TYPE,
  SET_CAREPACKS_STATE,
  SET_CONFIG_BOM,
  SET_CONFIG_LOAD_MODEL,
  SET_CONFIG_NAME,
  SET_CONFIG_NAME_SAVE,
  SET_CONFLICT_ITEMS,
  SET_ERROR_DATA,
  SET_EXPAND_COLLAPSE,
  SET_EXPAND_COLLAPSE_BUTTON,
  SET_FILTERED_CHOICES,
  SET_FILTERED_LOC,
  SET_IS_CONFIG_OVERDATE,
  SET_KMAT_ID,
  SET_LOADING_SELECTION,
  SET_ORIGINAL_ACCESS_TYPE,
  SET_ORIGIN_SELECTED_ITEMS,
  SET_SAVE_CONFIG_RESPONSE,
  SET_SEARCHED_TEXT,
  SET_SHOW_ADDITIONAL,
  SET_SHOW_BOM_SECTION_FLAG,
  SET_SHOW_OTHER_SERVICES,
  SET_SHOW_PREAPPROVED_CONFIG_BUTTON,
  SET_SHOW_TOP_RECOMMEND,
  SET_SPC_ID,
  SET_VERTICAL_SCROLLTO_CHAID,
  SET_VERTICAL_STEP_SELECTED,
  SET_WARNING,
  UPDATE_CHOICE_EXPAND,
  UPDATE_SCROLL_FILTER_CHOICE,
  WEB_ASSEMBLY_UPDATE_MODEL
} from "../actions/Types";

const initialState = {
  expandAll: [],
  updateExpandAll: [],
  expandCount: {},
  defaultConfigStepSlected: "hardware",
  filteredChoices: [],
  filteredLoc: [],
  showBOMSectionFlag: false,
  showWarning: {},
  modelData: {},
  errorData: {},
  accessType: "",
  loadingSelection: {},
  kmatId: "",
  configNameRed: "",
  originSelectedItems: "[]",
  originalAccessType: "",
  haveSaved: false,
  searchText: "",
  configuration: {},
  showPreApprovedConfigButton: false,
  isConfigOverDate: false,
  showTopRecommended: false,
  showAdditional: false,
  originalData: {},
  notesAttachments: [],
  searchForOtherServices: {},
  expColButton: {},
  verticalStepper: {
    carePacks: "",
    otherServices: "",
    configurationService: "",
    deploymentServices: "",
  },
  scrollToChaId: "",
  isVerticalView: false,
  conflictFilter: [],
  isChaidExpand: {}
};

function configFilterData(state = initialState, action) {
  switch (action.type) {
    case "SET_CHAID_EXPAND_COLLAPSE":
      return {
        ...state,
        isChaidExpand: action.payload
      }
    case "RESET_CHAID_EXPAND_COLLAPSE":
      return {
        ...state,
        isChaidExpand: { ...state.isChaidExpand, ...action.payload }
      }
    case "RE_SET_EXPAND_COLLAPSE":
      return {
        ...state,
        expandAll: [...action.payload]
      };
    case "SET_EXPAND_DEFAULT_VERTICAL_COLLAPSE_BUTTON":
      return {
        ...state,
        expColButton: {
          ...state.expColButton,
          ...action.payload
        }
      };  
    case "SET_EXPAND_COLLAPSE_UPDATE":
      return {
        ...state,
        expandAll: action.payload,
        updateExpandAll: action.payload
      };
    case SET_EXPAND_COLLAPSE: {
      return {
        ...state,
        expandAll: [...state.expandAll, action.payload],
        updateExpandAll: [...state.updateExpandAll, action.payload]
      };
    }
    // The UPDATE_SCROLL_FILTER_CHOICE will update the exp/col state for choices when
    // there is conflicts and user search/filter which update accordion exp/col on autoscroll.
    case UPDATE_SCROLL_FILTER_CHOICE:
      const expandAll = state.expandAll.map(expandedData => {
        if (expandedData?.tab === state.defaultConfigStepSlected) {
          return action.payload;
        } else {
          return expandedData;
        }
      });
      return {
        ...state,
        expandAll: expandAll,
        updateExpandAll: expandAll
      };
    //The action UPDATE_CHOICE_EXPAND will update the updateExpandAll state when user click on accordion and move the other tab
    // here the action which updates to expandAll CONFIG_STEP_SELECTION
    case UPDATE_CHOICE_EXPAND: {
      const expandAll = state.updateExpandAll.map(expandedData => {
        if (expandedData?.tab === state.defaultConfigStepSlected) {
          return action.payload;
        } else {
          return expandedData;
        }
      });
      return {
        ...state,
        updateExpandAll: expandAll
      };
    }
    case SET_EXPAND_COLLAPSE_BUTTON:
      return {
        ...state,
        expColButton: {
          ...state.expColButton,
          [state.defaultConfigStepSlected]: action.payload
        }
      };
    case "SET_EXPAND_COLLAPSE_BUTTON_VERTICAL":
      return {
        ...state,
        expColButton: {
          ...state.expColButton,
          [action.payload.stepper]: action.payload.expand
        }
      };
    case CONFIG_STEP_SELECTION: {
      return {
        ...state,
        defaultConfigStepSlected: action.selectedStep,
        filteredChoices: [],
        expandAll:
          state.updateExpandAll.length > 0
            ? state.updateExpandAll
            : state.expandAll
      };
    }
    //case to store the access type when chanegd by the user
    case SET_ACCESS_TYPE: {
      return {
        ...state,
        accessType: action.payload
      };
    }

    case SET_ORIGINAL_ACCESS_TYPE: {
      return {
        ...state,
        originalAccessType: action.payload
      };
    }
    case SET_CONFIG_LOAD_MODEL: {
      const newModelData = { ...action.modelData };
      return {
        ...state,
        configuration: action.configuration,
        modelData: newModelData
      };
    }
    case SET_KMAT_ID: {
      return {
        ...state,
        kmatId: action.payload
      };
    }
    case SET_SPC_ID: {
      return {
        ...state,
        spcId: action.payload
      };
    }
    case LOAD_CONFIG_STEPPER: {
      return {
        ...state,
        steppers: action.steppers
      };
    }
    case SET_FILTERED_CHOICES: {
      return {
        ...state,
        filteredChoices: action.payload
      };
    }
    case BOM_SELECTION: {
      return {
        ...state,
        bomSelected: action.payload
      };
    }
    case SET_FILTERED_LOC: {
      return {
        ...state,
        filteredLoc: action.payload
      };
    }
    case CHECKED_FILTER_PARTS: {
      return {
        ...state,
        checkedFilterParts: action.payload
      };
    }
    case WEB_ASSEMBLY_UPDATE_MODEL: {
      const newModelData = { ...action.modelData };
      return {
        ...state,
        modelData: newModelData
      };
    }
    case SET_CONFIG_BOM: {
      return {
        ...state,
        selectedItemsPartNo: action.payload.map(el => el.partNumber),
        bomData: action.payload
      };
    }
    case SET_SHOW_BOM_SECTION_FLAG: {
      return {
        ...state,
        showBOMSectionFlag: action.payload
      };
    }
    case SET_WARNING: {
      return {
        ...state,
        showWarning: action.payload
      };
    }
    case SET_ERROR_DATA: {
      return {
        ...state,
        errorData: action.payload
      };
    }
    case SET_SAVE_CONFIG_RESPONSE: {
      return {
        ...state,
        newSavedConfigId: action.newSavedConfigId,
        eventTriggered: action.eventTriggered
      };
    }
    case SET_LOADING_SELECTION: {
      return {
        ...state,
        loadingSelection: action.payload
      };
    }
    case SET_CONFIG_NAME: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          configHeader: {
            ...state.configuration?.configHeader,
            configName: action.payload
          }
        },
        configNameRed: action.payload
      };
    }
    case SET_CONFIG_NAME_SAVE: {
      return {
        ...state,
        configNameRedSaved: action.payload
      };
    }
    case SET_ORIGIN_SELECTED_ITEMS: {
      return {
        ...state,
        originSelectedItems: action.selectedItems,
        haveSaved: action.haveSaved
      };
    }
    case SET_ORIGINAL_ACCESS_TYPE: {
      return {
        ...state,
        originalAccessType: action.payload
      };
    }
    case SET_SEARCHED_TEXT: {
      return {
        ...state,
        searchText: action.payload
      };
    }
    case SET_SHOW_PREAPPROVED_CONFIG_BUTTON: {
      return {
        ...state,
        showPreApprovedConfigButton: action.payload
      };
    }

    case SET_IS_CONFIG_OVERDATE: {
      if (localStorage.getItem("isDebug") === "true") {
        console.log("SET_IS_CONFIG_OVERDATE", action.payload);
      }
      return {
        ...state,
        isConfigOverDate: action.payload
      };
    }
    case SET_SHOW_TOP_RECOMMEND: {
      return {
        ...state,
        showTopRecommended: action.payload
      };
    }
    case SET_SHOW_ADDITIONAL: {
      return {
        ...state,
        showAdditional: action.payload
      };
    }
    case SET_CAREPACKS_STATE: {
      return {
        ...state,
        showTopRecommended: action.payload,
        showAdditional: action.payload
      };
    }

    case SET_VERTICAL_STEP_SELECTED: {
      return {
        ...state,
        activeStep: action.payload
      };
    }
    case SET_SHOW_OTHER_SERVICES: {
      return {
        ...state,
        searchForOtherServices: {
          ...state.searchForOtherServices,
          ...action.payload
        }
      };
    }
    case CONFIG_VERTICAL_STEP_SELECTION: {
      return {
        ...state,
        verticalStepper: action.payload
      };
    }
    case SET_VERTICAL_SCROLLTO_CHAID: {
      return {
        ...state,
        scrollToChaId: action.payload
      };
    }
    case SET_CONFLICT_ITEMS: {
      return {
        ...state,
        conflictFilter: action.payload
      };
    }

    default:
      return {
        ...state
      };
  }
}

export default configFilterData;

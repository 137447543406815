import { useLazyQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  GET_CHOICEID,
  GET_DEFAULT_CONFIGURATION,
  GET_RSKU_IMAGE,
  RSKU_MATCH
} from "../../../GraphQL";
import ExternalProps from "../../../contexts/externalPropsContext";
import displayNotistack from "../../../lib/common/SnackBarUtils";
import {
  getFilteredAccessories,
  getHardwareAvs,
  getMandatorySkus,
  getRskuBomList,
  getRskuImages,
  getStorageValue
} from "../../../lib/common/util";
import { HP_LOGO } from "../Rsku/Constants";

const RSKUV2 = props => {
  const { setIsSearchSKU } = props;
  const {
    localization,
    isSkuCloning,
    rSKUBom,
    productCountryCode,
    productRegionCode,
    configID,
    skuConfiguration,
    bomThreeCompatiblePartNumbers,
    visibleSKUs
  } = useSelector(state => state.rskuReducer);
  const bomData = useSelector(state => state.configFilterData.bomData);

  const dispatch = useDispatch();
  const { currencyCode, incoterms } = useContext(ExternalProps);
  const { modelData, configuration, steppers } = useSelector(
    state => state.configFilterData
  );
  const currency = currencyCode || modelData.currency;

  const { isUploadConfig } = useSelector(state => state.uploadConfig);
  const { showRequestSku } = useSelector(state => state.productSelector);
  const { countryRegion } = useSelector(state => state.productSelector);

  const [isSimilar, setIsSimilar] = useState(false);
  const [isExact, setIsExact] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [mandatorySkus, setMandatorySkus] = useState([]);
  const [isQueryCalled, setISQueryCalled] = useState(false);
  const [hasSelectedLocProduct, setHasSelectedLocProduct] = useState(false);
  const [avs] = useState(getHardwareAvs(rSKUBom));
  const [updatedConfigId, setUpdatedConfigId] = useState(configID);
  const filteredAccessories = getFilteredAccessories(rSKUBom);
  const clientOrigin = getStorageValue("clientOrigin");
  const categoryVal = localStorage.getItem("selectedCategory");
  const otherComponents = filteredAccessories.map(item => {
    let newRecommendation = {};
    newRecommendation = {
      sku: item.sku,
      name: item.name,
      type: "Services",
      listPrice: item.listPrice,
      category: item?.category || "",
      description: item.name,
      quantity: 1,
      hasError: false,
      errorMessage: null,
      overview: item.overview || "",
      images: [
        {
          httpUrl: [HP_LOGO],
          httpsUrl: [HP_LOGO],
          color: ""
        }
      ],
      companions: null,
      isSelected: false,
      isLocalizationSku: item.sku.includes("#")
    };
    return newRecommendation;
  });

  const getFilteredSKUs = skuInfo => {
    return skuInfo.reduce((acc, product) => {
      acc[product.skuNumber] = product.isRSKUFinalized
        ? "Finalized"
        : "Not Finalized";
      return acc;
    }, {});
  };
  const [getRskuMatch] = useLazyQuery(RSKU_MATCH, {
    onCompleted(response) {
      let filteredSKUs = {};

      if (response) {
        const { exactMatchSkus, closeMatchSkus } = response.getSkuMatches;
        if (!isEmpty(exactMatchSkus)) {
          setIsExact(true);
          filteredSKUs = getFilteredSKUs(exactMatchSkus);
          const hasSystemLocProduct = exactMatchSkus.filter(each =>
            each.skuNumber.includes(localization)
          )?.length;
          setHasSelectedLocProduct(Boolean(hasSystemLocProduct));
          dispatch({ type: "SET_SKU_SERVICE_NAME", payload: "exactMatch" });
        } else if (!isEmpty(closeMatchSkus)) {
          setIsSimilar(true);
          filteredSKUs = getFilteredSKUs(closeMatchSkus);
          dispatch({ type: "SET_SKU_SERVICE_NAME", payload: "closeMatch" });
        } else {
          setNoMatch(true);
        }

        dispatch({ type: "SET_SKU_IDS", payload: filteredSKUs });
      }

      setTimeout(() => {
        dispatch({ type: "SET_SHOW_BACKDROP", payload: false });
      }, 2000);
      setISQueryCalled(true);
    },
    onError({ graphQLErrors, networkError }) {
      setNoMatch(true);
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          displayNotistack.error(false, `${message}`);
        });
      }
      dispatch({ type: "SET_SHOW_BACKDROP", payload: false });
      setISQueryCalled(true);
    }
  });

  const [getRskuImage] = useLazyQuery(GET_RSKU_IMAGE, {
    onCompleted(response) {
      if (response?.getProductImages?.length) {
        const baseProductImages = getRskuImages(response.getProductImages);
        dispatch({
          type: "SET_RSKU_IMAGE",
          payload: baseProductImages
        });
      }
    }
  });
  const [getChoiceID] = useLazyQuery(GET_CHOICEID, {
    onCompleted({ getMandatoryChoiceIDs }) {
      const filteredSku = getMandatorySkus(getMandatoryChoiceIDs, rSKUBom);
      setMandatorySkus(filteredSku);
      getRskuImage({
        variables: {
          skuNumbers: [rSKUBom[0].partNumber]
        }
      });
      getRskuMatch({
        variables: {
          filter: {
            configId: updatedConfigId.toString(),
            country: modelData?.country || productCountryCode,
            incoTerm: modelData?.incoTerm || incoterms,
            region: productRegionCode,
            numberOfRecord: 99999,
            skuNumbers: [...avs, ...bomThreeCompatiblePartNumbers],
            closeMatchSKUNumbers: filteredSku,
            localization,
            currency
          }
        }
      });
    },
    onError({ graphQLErrors, networkError }) {
      setNoMatch(true);
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          displayNotistack.error(false, ` ${message}`);
        });
      }
    }
  });

  const [getCategory] = useLazyQuery(GET_DEFAULT_CONFIGURATION, {
    onCompleted({ catalogLookup }) {
      const category = catalogLookup?.data?.searchCatalog?.length
        ? catalogLookup?.data?.searchCatalog[0].category
        : "";

      dispatch({ type: "SET_SELECTED_CATEGORY", payload: category });
      getChoiceID({
        variables: {
          category
        }
      });
    },
    onError() {
      getChoiceID({
        variables: {
          category: categoryVal
        }
      });
    }
  });

  useEffect(() => {
    const filteredConfig =
      configID ||
      skuConfiguration?.configId ||
      configuration?.configHeader?.configId ||
      0;
    setUpdatedConfigId(filteredConfig);

    const selectedSysLoc = modelData.systemLocOptions.find(
      loc => loc.selected && loc.lclzBanding
    );
    batch(() => {
      dispatch({
        type: "SET_EXACT_CLOSE_MATCH_CONFIG",
        payload: filteredConfig
      });
      dispatch({
        type: "SET_LOCALIZATION",
        payload: selectedSysLoc?.locOption
      });
      dispatch({
        type: "SET_SEARCH_RSKU",
        payload: {
          carepack: filteredAccessories
        }
      });
    });
    return () => {
      dispatch({ type: "SET_SHOW_BACKDROP", payload: false });
    };
  }, []);

  useEffect(() => {
    if (isQueryCalled && (isExact || isSimilar || noMatch)) {
      const eolHardware = steppers
        ?.map(stepper => {
          if (stepper.isWarning) return stepper.id;
        })
        .includes("hardware");

      let requestRsku = false;
      if (isExact) {
        requestRsku = countryRegion === "NA";
      } else {
        requestRsku = true;
      }
      setIsSearchSKU(false);
      const { futureGAProductInfo } = getRskuBomList(bomData);

      const rskuData = {
        showRequestRSKU: requestRsku && showRequestSku,
        disableRequestRSKU: false,
        visibleSKUs: Object.keys(visibleSKUs || {}), // sku number to smart search
        visibleSKUStatus: visibleSKUs || {}, // sku number with sku status
        showSnackbar: {
          isExactMatch: isExact,
          isCloseMatch: isSimilar,
          isNoMatch: noMatch,
          hasSelectedLocProduct: hasSelectedLocProduct,
          hasMandatoryChoices: mandatorySkus?.length
        },
        otherComponents: otherComponents,
        configId: updatedConfigId,
        isUploadFlow: isUploadConfig,
        futureGAProductInfo
      };

      dispatch({ type: "SET_RSKU_INFO", payload: rskuData });
      document
        .querySelector(
          `${isUploadConfig ? "#upload-config" : "#product-selector"}`
        )
        .dispatchEvent(
          new CustomEvent("smartSearchIntegration", {
            detail: rskuData
          })
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQueryCalled, isExact, isSimilar, noMatch]);

  useEffect(() => {
    isSkuCloning || isUploadConfig
      ? getCategory({
          variables: {
            filter: {
              baseUnit: rSKUBom[0].partNumber,
              countryCode: productCountryCode,
              storeFront: "IQ"
            }
          }
        })
      : getChoiceID({
          variables: {
            category: categoryVal
          }
        });
  }, []);

  return null;
};

export default RSKUV2;

export const binarySearch = (modelData, iNode, key) => {
  let left = 0;
  let right = modelData?.length - 1;
  while (left <= right) {
    let middle = Math.floor((left + right) / 2);
    let middleData = key ? modelData[middle][key] : modelData[middle];
    if (middleData === iNode) {
      return middle;
    } else if (middleData < iNode) {
      left = middle + 1;
    } else {
      right = middle - 1;
    }
  }
  return -1;
};
export function findChoiceByItem(modelData, item) {
  let iChaId = 0;
  if (item === 0) {
    return iChaId;
  }
  for (let c = 1; c <= modelData.Chaids.length - 1; c++) {
    if (item <= modelData.Chaids[c].lastItem) {
      iChaId = c;
      break;
    }
  }
  return iChaId;
}

export const isAnyItemSelectedIn = (kbModel, iChaId) => {
  for (
    let i = kbModel.Chaids[iChaId].firstItem;
    i <= kbModel.Chaids[iChaId].lastItem;
    i++
  ) {
    if (kbModel.Items[i].selected) {
      return true;
    }
  }
  return false;
};

export function binarySearchFunc(n, cmp) {
  let i = 0,
    j = n;

  while (i < j) {
    let h = Math.floor((i + j) / 2);

    if (cmp(h) > 0) {
      i = h + 1;
    } else {
      j = h;
    }
  }
  return i;
}

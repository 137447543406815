const productSelectorBaseUnitColumns = [
  {
    id: "configURL",
    label: "",
    width: "20%"
  },
  {
    id: "name",
    label: "Description",
    width: "45%"
  }
];
export default productSelectorBaseUnitColumns;

import { gql } from "@apollo/client";

export const SIMPLE_MODEL_CONFIGURATION = gql`
  query SimpleModelConfiguration($filter: SimpleModelConfigurationRequest!) {
    simpleModelConfiguration(filter: $filter) {
      baseUnitAv
      carePacks {
        ...cp
      }
      Chaids: chaIds {
        ...choiceIds
      }
      modelRequirement {
        productGroup
      }
      clicPartiallyAffectedItems
      configDesc
      configId
      docType
      configName
      configDesc
      kmatId
      baseUnitAv
      refConfigId
      priceGeo
      priceListType
      configName
      conflicts {
        ...conf
      }
      configuration {
        configHeader
        itemsBanded
        configBom {
          ...cb
        }
        countryLcLz {
          ...cl
        }
      }
      costPriceError
      counters {
        counter
        chaDes
        count
        min
        max
        iNode
      }
      country
      currency
      icMsgs {
        ...icMsg
      }
      incoTerm
      docType
      isComplete
      isConflict
      isChoiceConflict
      isCounterConflict
      isHiddenConflict
      isGaDateConflict
      isKmatValid
      priorityStorage
      ipgLocOptions {
        ...locOption
      }
      isPlcConflict
      autoAttachProducts {
        ...autoAttachProduct
      }
      Items: items {
        ...it
      }
      isValid
      kmatId
      kybdLocOptions {
        ...locOption
      }
      messages
      monitorLocOptions {
        ...locOption
      }
      marketProgramName
      marketProgramType
      msgInfo
      Nodes: nodes {
        ...n
      }
      noneChaids
      osLocOptions {
        ...locOption
      }
      preConConflict
      priceDescriptor
      priceGeo
      priceListType
      productCategories {
        productCategories {
          ...pc
        }
      }
      refConfigId
      region
      requiredMaterials
      sameConflictItems
      selConConflict
      systemLocOptions {
        ...locOption
      }
      uiSpec
      wlanLocOptions {
        ...locOption
      }
      wwanLocOptions {
        ...locOption
      }
    }
  }
  fragment cp on CarePacks {
    chaId
    itemInode
    partNo
    valueId
    selected
  }
  fragment choiceIds on ChaIdsOfferPrcReconfigure {
    chaId
    chaDes
    multiple
    required
    visible
    precon
    selcon
    count
    firstItem
    lastItem
    iNode
    specified
    isSpecOrigin
    qtyFlag
  }
  fragment c on Cause {
    iitem
    partno
    ichaid
    chaId
    chaDes
    ichange
    etype
  }
  fragment eff on Effect {
    iitem
    partno
    ichaid
    chaId
    chaDes
    ichange
    etype
    iprecon
  }
  fragment conf on ConflictOfferPrcReconfigure {
    icConflicts {
      code
      messageType
      chaId
      chaDes
      partNo
    }
    counterConflicts {
      code
      messageType
      chaId
      chaDes
      partNos
      count
      min
      max
    }
    itemConflicts {
      cause {
        ...c
      }
      effect {
        ...eff
      }
    }
    itemConflictsList {
      cause {
        ...c
      }
      effect {
        ...eff
      }
    }
    plcConflicts {
      code
      messageType
      plcMessage
      itemiNode
      chaId
      chaDes
      partNo
      saDate
      esDate
    }
    gaDateConflicts {
      code
      messageType
      chaId
      chaDes
      partNo
    }
    hiddenChoiceConflicts {
      cause {
        ...c
      }
      effect {
        ...eff
      }
    }
    choiceConflicts {
      cause {
        ...c
      }
      effectChoice {
        ichaid
        chaId
        chaDes
        ichange
        etype
        iprecon
      }
    }
  }
  fragment autoAttachProduct on AutoAttachProduct {
    name
    companions {
      name
      companions {
        name
        companions {
          name
        }
      }
    }
  }
  fragment locOption on LocalizationOption {
    locOptionCategory
    locOption
    selected
    lclzBanding
    lclzDefault
  }
  fragment it on ItemSmc {
    partno
    partdes
    visible
    isBandedItem
    precon
    selcon
    selected
    autoAttach
    valueId
    inode
    quantity
    cntryLocFlag
    plcStatus
    plcMessage
    saDate
    gaDate
    emDate
    esDate
    netPrice
    listPrice
    priceSource
    priceStartDate
    priceEndDate
    priceStatus
    priceMessage
    openMrktFlag
    configPriceSource
    configPriceSourceDetail
    configDealNumber
    configDealDescription
    configDealMCC
    configDealVersion
    configDiscountAmt
    priority
    productCost
    costPriceSource
    bndlBomType
    mfgCO2
    energyUseCO2
    transportCO2
    totalCO2
    clinKey
    iDefault
  }
  fragment n on Nodes {
    id
    name
    type
    g
    a
    b
    edges {
      type
      node
    }
  }
  fragment cb on RestoredConfigBom {
    lineItemId
    materialNumber
    qty
    startingPointFlag
    parentLineItemId
    choiceId
    choiceName
    materialDescription
    delFl
    hidePtnrFl
    reqFl
  }
  fragment cl on ConfigCountryLocalization {
    countryCode
    systemLocalization
    osLocalization
    keyBoardLocalization
    monLocalization
    kybdDflt
    osDflt
    sysDflt
    monDflt
  }
  fragment pc on ProductCategories {
    description
    imageUrl
    levelId
    name
    nodeId
    selected
    series {
      imageUrl
      levelId
      name
      nodeId
      selected
    }
  }
  fragment icMsg on IcMsgs {
    itemMsgs {
      itemInode
      msgs
    }
    choiceMsgs {
      choiceIndex
      choiceId
      msgs
    }
  }
`;

import ChaidMultiple from "./ChaidMultiple";
import ChaidSingle from "./ChaidSingle";

function ChaidSelectionType({
  chaid,
  modelData,
  handleItemChange,
  handleQuantityChange
}) {
  return chaid.multiple ? (
    <ChaidMultiple
      key={chaid.chaId}
      chaid={chaid}
      model={modelData}
      handleItemChange={handleItemChange}
      handleQuantityChange={handleQuantityChange}
    />
  ) : (
    <ChaidSingle
      key={chaid.chaId}
      chaid={chaid}
      model={modelData}
      handleItemChange={handleItemChange}
      handleQuantityChange={handleQuantityChange}
    />
  );
}

export default ChaidSelectionType;

/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { isEmpty, uniq } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_RSKU } from "../../../../GraphQL";
import { togglePopupActions } from "../../../../actions/togglePopupActions";
import ExternalProps from "../../../../contexts/externalPropsContext";
import displayNotistack from "../../../../lib/common/SnackBarUtils";
import {
  getFilteredConfigBom,
  getProductData,
  getRskuBomList
} from "../../../../lib/common/util";
import { getConfigBom } from "../../../../services/bomAndCarePackLib/utils";
import {
  getConfigObjectDataForSave,
  getLocalizationOption,
  setModelDataForDefaultConfig
} from "../../../../services/common/utils";
import WithAddToQuote from "../withAddToQuote";
import RequestRskuContent from "./RequestRskuContent";
import useStyles from "./styles";

const RequestRsku = props => {
  const { addCTOToQuote } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    userEmail,
    currencyCode,
    configurationName = "",
    userId = "",
    brID,
    companyId = "",
    spcType = "C",
    isStandaloneAccess = false
  } = useContext(ExternalProps);

  //useSelector
  const { modelData } = useSelector(state => state.configFilterData);
  const {
    configID,
    proCategory,
    localization,
    rSKUBom,
    productCountryCode,
    productRegionCode,
    rskuImage,
    bomThreeCompatibleCarePacks,
    bomThreeCompatibleCarePacksInfo,
    selectedWarrenty,
    isSkuCloning,
    otherComponents,
    standardCarePacks
  } = useSelector(state => state.rskuReducer);
  const { configNameRed, configuration, accessType, kmatId } = useSelector(
    state => state.configFilterData
  );
  const { selectedSeries, selectedCategory, skuIdData } = useSelector(
    state => state.productSelector
  );

  const bomData = useSelector(state => state.configFilterData.bomData);

  // states
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isGraphQLErrors, setIsGraphQLErrors] = useState(false);
  const [requestrSKu, setRequestrSku] = useState({});
  const [requestrSkuProduct, setRequestrSkuProduct] = useState([]);
  const baseModelCategory = localStorage.getItem("selectedCategory");
  let configNameRes = localStorage.getItem("configName");
  const { skuId } = skuIdData;
  const docType = configuration?.configHeader?.docType;
  let selectedBaseUnit = undefined;
  let { formattedBOMItems: configBOM } = getConfigBom(modelData);
  if (configBOM && configBOM.length) {
    selectedBaseUnit = configBOM.find(config => config.choiceId === "BUNIT");
  }
  const refConfigId =
    docType === "SP" || docType === "CC"
      ? configuration?.configHeader?.configId?.toString() || ""
      : configuration?.configHeader?.refConfigId?.toString() || "";
  let bmiData = JSON.parse(localStorage.getItem("bmiData"));
  const mDCPOrgID = bmiData["mDCPOrgID"] || "";
  const systemLocalizationDefault = getLocalizationOption(
    modelData.systemLocOptions
  );
  const osLocalizationDefault = getLocalizationOption(modelData.osLocOptions);
  const keyBoardLocalizationDefault = getLocalizationOption(
    modelData.kybdLocOptions
  );
  let systemLocOptions, osLocOptions, kybdLocOptions;
  systemLocOptions =
    modelData.systemLocOptions &&
    modelData.systemLocOptions.filter(item => item.selected === true);
  osLocOptions =
    modelData.osLocOptions &&
    modelData.osLocOptions.filter(item => item.selected === true);
  kybdLocOptions =
    modelData.kybdLocOptions &&
    modelData.kybdLocOptions.filter(item => item.selected === true);
  let countryLcLzRes = [
    {
      countryCode: productCountryCode,
      systemLocalization: systemLocOptions && systemLocOptions[0]?.locOption,
      osLocalization: osLocOptions && osLocOptions[0]?.locOption,
      keyBoardLocalization: kybdLocOptions && kybdLocOptions[0]?.locOption,
      delFl: "N"
    }
  ];
  const updatedChoicesDetails = setModelDataForDefaultConfig(modelData);
  const dropListServices = otherComponents.map(item => item.partNumber);
  const dropListStandardCarepacks = standardCarePacks.map(
    item => item.partNumber
  );
  const dropListPartNumbers = uniq(
    dropListServices.concat(dropListStandardCarepacks)
  );

  const getConfigObjectDataRes = getConfigObjectDataForSave(
    updatedChoicesDetails,
    dropListPartNumbers,
    "rSKU"
  );

  //function calls
  const handleRequestSkuDialogClose = useCallback(
    (isGraphqlError, isComponentError) => {
      if (!(isGraphqlError || isComponentError)) {
        dispatch(
          togglePopupActions({
            name: "confirmationDialog",
            open: !isStandaloneAccess
          })
        );
        dispatch(
          togglePopupActions({
            name: "requestSkuDialog",
            open: !isStandaloneAccess
          })
        );
        dispatch({
          type: "SET_RSKU_DAILOG_ALERT",
          payload: !isStandaloneAccess
        });
      } else {
        dispatch(
          togglePopupActions({
            name: "requestSkuDialog",
            open: false
          })
        );
      }
    },
    []
  );

  const { rskuBomList } = getRskuBomList(bomData);

  const handleClickRsku = () => {
    setLoading(true);
    setIsGraphQLErrors(false);
    createRsku({
      variables: {
        input: {
          bomList: rskuBomList,
          skuBase: isSkuCloning
            ? skuId.split("#")[1] !== localization
              ? skuId.slice(0, 5)
              : ""
            : "",
          carePackList: bomThreeCompatibleCarePacks,
          localization,
          baseModelType: selectedCategory?.name || baseModelCategory || "",
          mloCd: selectedWarrenty,
          configID: `${configID}`,
          countryCode: productCountryCode,
          requestorEmail: userEmail,
          configName: configNameRed
            ? configNameRed
            : configNameRes || configurationName,
          configHeader: {
            configType: configuration?.configHeader?.configType || "custom",
            leadCountryCode: productCountryCode || "US",
            configHighLights:
              configuration?.configHeader?.configHighlights || "",
            configImage:
              encodeURIComponent(configuration?.configHeader?.configImage) ||
              "",
            kmatId:
              configuration?.configHeader?.kmatId ||
              modelData?.kmatId ||
              kmatId ||
              "",
            baseUnitAv:
              selectedBaseUnit?.materialNumber ||
              configuration?.configHeader?.baseUnitAv ||
              "",
            programName: configuration?.configHeader?.programName || "",
            accessType: accessType || "organization",
            refConfigId,
            userId: userId,
            userEmail: userEmail,
            customerCompanyName:
              brID ||
              (companyId
                ? companyId
                : configuration?.configHeader?.companyName) ||
              "",
            mdcpOrgId: mDCPOrgID
              ? mDCPOrgID
              : configuration?.configHeader?.mdcpOrgId || "",
            originatingAsset: "RSKU",
            bandingFlag: "N",
            bandingType: "OC-FIX",
            docType: "QU",
            regionCode:
              productRegionCode ||
              configuration?.configHeader?.rgnCd ||
              configuration?.configHeader?.regionCode,
            lead_locale: configuration?.configHeader?.leadLocale || "",
            autoLocalized: configuration?.configHeader?.autoLocalized || "",
            kbVersion: configuration?.configHeader?.kbVersion || "",
            marketProgramName:
              configuration?.configHeader?.mktProgramName || "",
            marketProgramType:
              configuration?.configHeader?.mktProgramType || "",
            shipmentDate: configuration?.configHeader?.shipmentDate
              ? moment(configuration?.configHeader?.shipmentDate).format(
                  "YYYY-MM-DD"
                )
              : null,
            exportStatusId: configuration?.configHeader?.exportStatusId || "",
            bandingEol: configuration?.configHeader?.bandingEol
              ? moment(configuration?.configHeader?.bandingEol).format(
                  "YYYY-MM-DD"
                )
              : null,
            configEol: configuration?.configHeader?.configEol
              ? moment(configuration?.configHeader?.configEol).format(
                  "YYYY-MM-DD"
                )
              : null,
            businessModel: configuration?.configHeader?.businessModel
              ? configuration?.configHeader?.businessModel.toLowerCase()
              : "indirect",
            copiedFrom: configuration?.configHeader?.copiedFrom || "",
            localizationType: configuration?.configHeader?.lclznType || "",
            priceGeo:
              modelData?.priceGeo ||
              configuration?.configHeader?.priceGeo ||
              "",
            currencyCd: modelData?.currency
              ? modelData?.currency
              : configuration?.configHeader?.currCd
              ? configuration?.configHeader?.currCd.toUpperCase()
              : configuration?.configHeader?.currencyCd
              ? configuration?.configHeader?.currencyCd.toUpperCase()
              : "USD",
            incoterm:
              configuration?.configHeader?.incoterm || modelData.incoTerm || "",
            priceListType: configuration?.configHeader?.priceListType || "",
            globalFl: configuration?.configHeader?.globalFl || "N",
            activeFl: configuration?.configHeader?.activeFl || "Y",
            delFl: configuration?.configHeader?.delFl || "N",
            favoriteFl: "N",
            productLine: modelData?.modelRequirement?.productGroup || "DT",
            storeFront: "IQ",
            startDate: configuration?.configHeader?.startDate
              ? moment(configuration?.configHeader?.startDate).format(
                  "YYYY-MM-DD"
                )
              : null,
            endDate: configuration?.configHeader?.endDate
              ? moment(configuration?.configHeader?.endDate).format(
                  "YYYY-MM-DD"
                )
              : null,
            geoMrkt: "",
            spcType
          },
          countryLcLz: countryLcLzRes,
          configBom:
            configBOM && configBOM.length
              ? getFilteredConfigBom(configBOM, rskuBomList)
              : [],
          configObject: {
            configDefault: {
              configuration: {
                localization: [
                  {
                    choiceId: "systemLocalization",
                    values: [...systemLocalizationDefault]
                  },
                  {
                    choiceId: "osLocalization",
                    values: [...osLocalizationDefault]
                  },
                  {
                    choiceId: "keyBoardLocalization",
                    values: [...keyBoardLocalizationDefault]
                  }
                ],
                choices: [...getConfigObjectDataRes]
              }
            },
            configBanding: { banding: {} }
          },
          hierarchy: {
            categoryName:
              selectedCategory?.name ||
              baseModelCategory ||
              "Business Desktop PCs",
            seriesName: selectedSeries?.name || "HP Elite Slice series",
            productType: proCategory || "compute"
          }
        }
      }
    });
  };

  // hooks call
  useEffect(() => {
    handleClickRsku();
  }, []);

  useEffect(() => {
    if (requestrSkuProduct?.length) {
      dispatch({
        type: "SET_REQUEST_RSKU",
        payload: {
          requestRsku: requestrSkuProduct
        }
      });

      return () => {
        dispatch({
          type: "SET_REQUEST_RSKU",
          payload: {
            requestRsku: []
          }
        });
      };
    }
  }, [requestrSkuProduct]);

  useEffect(() => {
    if (!isEmpty(requestrSKu)) {
      const requestRskuProductData = getProductData(
        requestrSKu,
        rSKUBom,
        currencyCode,
        rskuImage,
        bomThreeCompatibleCarePacksInfo
      );
      setRequestrSkuProduct(requestRskuProductData);
    }
  }, [requestrSKu]);

  // middleware calls
  const [createRsku] = useMutation(CREATE_RSKU, {
    fetchPolicy: "no-cache",

    onCompleted(response) {
      setLoading(false);
      setIsError(false);
      if (response) {
        const { rskuMaterialNumber, shortDescription, configID, errorMessage } =
          response.createRSKU;
        if (errorMessage?.length) {
          displayNotistack.error(false, errorMessage[0]);
          setIsError(true);
        } else {
          if (rskuMaterialNumber) {
            const rskuProduct = {
              rskuMaterialNumber,
              shortDescription
            };
            setRequestrSku(rskuProduct);
            const successMsg = `Successfully saved ${configID}`;
            displayNotistack.success(successMsg, "success", 6000);
            dispatch({ type: "SET_REQUEST_SKU_CONFIG", payload: configID });
          } else {
            setIsError(true);
          }
        }
      } else {
        setIsError(true);
      }
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
      setIsGraphQLErrors(true);
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (localStorage.getItem("isDebug") === "true") {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
        });
      }
      if (networkError) {
        if (localStorage.getItem("isDebug") === "true") {
          console.log(networkError);
        }
      }
    }
  });

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="md"
      classes={{ paper: isGraphQLErrors && classes.paper }}
    >
      <DialogTitle className={classes.title}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          {t("common:rsku.requestSKU.mainTitle")}
        </Typography>
        <IconButton
          onClick={() => handleRequestSkuDialogClose(isGraphQLErrors, isError)}
          className={classes.closeButton}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <RequestRskuContent
        loading={loading}
        isGraphQLErrors={isGraphQLErrors}
        isError={isError}
        handleClickRsku={handleClickRsku}
        isStandaloneAccess={isStandaloneAccess}
        addCTOToQuote={addCTOToQuote}
      />
    </Dialog>
  );
};

export default WithAddToQuote(RequestRsku);

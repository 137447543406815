import Button from "@mui/material/Button";
import { saveAs } from "file-saver";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import { checkSectionID, getBaseProductData } from "../../../lib/common/util";
import { getBOMData } from "../../../services/bomAndCarePackLib/utils";
import {
  EXCLUDE_ALL_OTHER_COMPONENENTS,
  EXCLUDE_OTHER_COMPONENENTS,
  EXPORT_RSKU
} from "./Constants";

const ExportRskuBomToExcel = props => {
  const { loading, isError } = props;
  const { isStandaloneAccess = false } = useContext(ExternalProps);
  const modelData = useSelector(state => state.configFilterData.modelData);
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const { requestRskuProduct } = useSelector(state => state.rskuReducer);
  const skuId = requestRskuProduct[0]?.sku;

  /**
   * It helps to generate a excel file with the bom data of that particular sku
   *
   * @param {string} action Button action
   * @returns It generates xls sheet directly with the file name as sku Number
   */
  const handleClick = action => {
    if (action === "EXPORT_TO_EXCEL") {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExt = "xlsx";
      const fileName = `${skuId}.${fileExt}`;
      const sectionIds = isStandaloneAccess
        ? EXCLUDE_ALL_OTHER_COMPONENENTS
        : EXCLUDE_OTHER_COMPONENENTS;
      const { formattedBOMItems } = getBOMData(
        modelData,
        "exportToExcel",
        configuration
      );
      const { rSKUBom, bomThreeCompatibleCarePacksInfo } = checkSectionID(
        sectionIds,
        formattedBOMItems
      );

      const { listPrice } = getBaseProductData(
        rSKUBom,
        bomThreeCompatibleCarePacksInfo
      );
      const bomInfo = [...rSKUBom, ...bomThreeCompatibleCarePacksInfo];
      var ws = XLSX.utils.aoa_to_sheet([["rSKU ID", "Grand Total List Price"]]);
      XLSX.utils.sheet_add_aoa(ws, [[skuId, listPrice.toFixed(2)]], {
        origin: -1
      });
      XLSX.utils.sheet_add_aoa(ws, [new Array(4)], { origin: -1 });
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Description", "Part Number", "Qty", "List Price"]],
        { origin: -1 }
      );
      bomInfo.forEach(item => {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              item.description,
              item.partNumber,
              item.qty,
              item.listPrice.toLocaleString("en-US") || "NA"
            ]
          ],
          { origin: -1 }
        );
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuff = XLSX.write(wb, {
        bookType: fileExt,
        type: "array"
      });
      const dataBuff = new Blob([excelBuff], { type: fileType });
      saveAs(dataBuff, fileName);
    }
  };

  return (
    <Button
      color="primary"
      variant="outlined"
      disabled={loading || isError}
      onClick={() => handleClick("EXPORT_TO_EXCEL")}
    >
      {EXPORT_RSKU}
    </Button>
  );
};

export default ExportRskuBomToExcel;

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles, withStyles } from "@mui/styles";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BUNDLED_COMPONENT } from "../components/Configurator/Rsku/Constants";
import { ExternalProps } from "../contexts/externalPropsContext";
import {
  dateCompare,
  displayCurrencyLite,
  loadingLabel,
  shouldShowOnUI,
  showEsAndPriceSource,
  showFutureGADate,
  transformDateFormat,
  updateIframeHeight
} from "../lib/common/util";
import {
  carePackAdditionalChaId,
  carePackRecommendedChaId
} from "../services/bomAndCarePackLib/utils";
import {
  getFieldWidth,
  maxQuantityValue,
  minQuantityValue,
  preventSpecialCharacters
} from "./util";
function getPrice(item, currencyCode) {
  // below condition is for issue no:3127
  let price = item.netPrice;
  let color = "#1f96d8";
  if (item.costPriceSource) {
    price = item.productCost;
    color = "#08a06d";
  }
  return (
    <>
      <Typography
        style={{ color: color }}
        variant={item.selected ? "body" : "caption"}
        fontWeight={item.selected ? "bold" : "normal"}
        display="inline"
      >
        {currencyCode} &nbsp;
        {displayCurrencyLite({
          value: Math.abs(isNaN(price) ? 0 : price) || "0.00",
          currency: currencyCode,
          locale: "en-US"
        })}
      </Typography>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  radioLabel: {
    fontSize: "12px"
  },
  overWriteDisable: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  selectedItemStyle: {
    fontSize: 12,
    fontWeight: "bold"
  },
  negativePrecon: {
    fontSize: "12px",
    color: "#9e9e9e"
  },
  caption: {
    paddingTop: "7px"
  },
  box: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
    whiteSpace: "nowrap"
  },
  notCompatible: {
    color: "#FF8540",
    borderBottom: "1px solid #FF8540"
  },
  viewMore: {
    cursor: "pointer"
  },
  itemName: {
    flex: 1
  },
  customGrid: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "0.74rem"
  },
  quantity: {
    margin: `0px ${theme.spacing(0.5)}`,
    "& [class*=MuiInput-input]": {
      textAlign: "center"
    },
    "& input::-webkit-inner-spin-button": {
      opacity: 1
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      fontSize: "0.9rem"
    },
    left: 5,
    right: 10,
    marginRight: 10
  }
  // marginWrapper: {
  //   marginLeft: 106
  // }
}));

function ItemMultipleCheckBox({
  chaid,
  model,
  handleItemChange,
  handleQuantityChange,
  itemQuantityRef
  // selectedItemPrice,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    currencyCode,
    countryCode,
    channelRegion,
    isStandaloneAccess = false,
    configID,
    configOrigin,
    isGenericSKU,
    visibility: { showGaDateConfigError = true },
    proCategory: proCategoryFromContext,
    isSKUModule,
    isBaseCreated,
    restrictedChoicesForSKUModule = [],
    disableSelectedPN
  } = React.useContext(ExternalProps);
  const country = useSelector(state => state.productSelector.country);
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const errorData = useSelector(state => state.configFilterData.errorData);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const loadingSelection = useSelector(
    state => state.configFilterData.loadingSelection
  );
  const modelData = useSelector(state => state.configFilterData.modelData);
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const proCategory =
    useSelector(state => state.productSelector.category) ||
    proCategoryFromContext;
  const showGADate = modelData.showGADate;
  const isConfigOverDate = useSelector(
    state => state.configFilterData.isConfigOverDate
  );
  const region = isStandaloneAccess
    ? configuration?.configHeader?.rgnCd || "NA"
    : channelRegion;
  const currentConfigID = useSelector(
    state => state.productSelector.currentConfigID
  );
  const [conflictItems2, setConflictItems2] = React.useState([]);
  const [toggleName, setToggleName] = React.useState(
    t("common:conflicts.viewMore")
  );
  const [check, setCheck] = React.useState(true);

  let noneState = true;
  const [selected, setSelected] = React.useState(noneState);
  for (let i = 0; i < chaid.items.length; i++) {
    if (chaid.items[i].selected) {
      noneState = false;
      break;
    }
  }
  const handleOpen = () => {
    setCheck(!check);
    setConflictItems2(check ? itemShowOnPopup : []);
    setToggleName(
      check ? t("common:conflicts.viewLess") : t("common:conflicts.viewMore")
    );
    updateIframeHeight(false);
  };

  const BlueCheckbox = withStyles({
    root: {
      color: "#00000054;",
      paddingBottom: "1px",
      paddingTop: "1px",
      alignSelf: "baseline",
      "&$checked": {
        color: "#29A8DD"
      },
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    checked: {},
    disabled: {
      "&$disabled": {
        color: "#00000054"
      }
    }
  })(props => <Checkbox disableRipple color="default" {...props} />);

  // const item = model.Items.find(
  //   part =>
  //     part.visible &&
  //     part.precon &&
  //     part.inode >= chaid.firstItem &&
  //     part.inode <= chaid.lastItem &&
  //     part.selected
  // );

  // const invisibleItems = model.Items.filter(
  //   part =>
  //     part.visible === false &&
  //     part.precon &&
  //     part.inode >= chaid.firstItem &&
  //     part.inode <= chaid.lastItem
  // ).map(item => item);
  const compare = (a, b) => {
    const valueA = a.partno.toUpperCase();
    const valueB = b.partno.toUpperCase();
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  };

  const comparePriority = (a, b) => {
    // console.log("1", a);
    // console.log("2", b);
    const valueA = a.priority;
    const valueB = b.priority;
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  };

  let conflictItems = useMemo(
    () =>
      [carePackRecommendedChaId, carePackAdditionalChaId].includes(chaid.chaId)
        ? chaid.items?.filter(part => part.visible === true && part.precon < 0)
        : model?.Items?.filter(
            part =>
              part.visible === true &&
              part.precon < 0 &&
              part.inode >= chaid.firstItem &&
              part.inode <= chaid.lastItem &&
              shouldShowOnUI(
                { currentConfigID, showGADate, isConfigOverDate },
                part.gaDate,
                part.selected
              )
          ) || [],
    [model]
  );

  // Check itemConflicts
  conflictItems.forEach(part => {
    if (
      model &&
      model.conflicts &&
      model.conflicts.length &&
      model.conflicts[0].itemConflictsList
    ) {
      let index = model.conflicts[0].itemConflictsList.filter(
        (x, i) => x.effect.partno === part.partno
      );
      if (index.length >= 0) {
        const causeItem = index[0]?.cause;
        if (causeItem) {
          part.cause = causeItem;
        }
      }
    }
  });

  // Handle conflict items
  if (chaid.chaId === carePackRecommendedChaId) {
    // No conflict items for Top Recommended Care Packs
    // Except if it was previously selected, to allow to deselect
    conflictItems = conflictItems.filter(part => part.selected);
  }
  if (chaid.chaId === carePackAdditionalChaId) {
    // it could appear in Top, filter out
    conflictItems = conflictItems.filter(
      part => part.priority === "" || Number(part.priority) > 5
    );
  }

  const chaidValue = chaid?.carePackParentChoiceID
    ? chaid?.carePackParentChoiceID
    : chaid.chaId;
  const maxQuantity = maxQuantityValue(
    chaidValue,
    proCategory,
    ocfgParams,
    modelData
  );
  const minQuantity = minQuantityValue(
    chaidValue,
    proCategory,
    ocfgParams,
    modelData
  );
  const maxQtyLength = maxQuantity.toString().length;

  const ignoreLeadingZeroes = event => {
    if (event.target.value.length > maxQtyLength) {
      event.target.value = parseInt(event.target.value);
    }
  };

  useEffect(() => {
    if (modelData) {
      let chaidIndex =
        modelData.Chaids?.findIndex(el => el.chaId === chaid.chaId) ?? -1;
      if (chaidIndex !== -1) {
        modelData.Chaids[chaidIndex].conflictItems = conflictItems;
      }
    }
  }, [conflictItems, modelData, chaid.chaId]);

  conflictItems?.sort(item => {
    if (item.selected) return -1;
    else return 1;
  });
  let itemShowOnPopup = conflictItems?.slice(5);
  const finalConflictItems = [...conflictItems];
  finalConflictItems?.sort(a => (a?.selected !== true ? 1 : -1));
  const finalConflictItemsSort = finalConflictItems.slice(0, 5);

  function getItems() {
    const filterParams = {
      currentConfigID: currentConfigID || showGaDateConfigError,
      showGADate,
      isConfigOverDate
    };
    const sortedItems = chaid.items
      .filter(
        part =>
          part.visible &&
          part.precon >= 0 &&
          shouldShowOnUI(filterParams, part.gaDate, part.selected)
      )
      .sort(
        chaid.chaId === carePackRecommendedChaId ? comparePriority : compare
      )
      .concat(
        conflictItems2.length === 0
          ? finalConflictItemsSort
          : finalConflictItems
      );

    // For handling itemQuantityRef
    sortedItems.length > 10
      ? (itemQuantityRef.current = true)
      : (itemQuantityRef.current = false);
    if (
      chaid.chaId === carePackRecommendedChaId ||
      chaid.chaId === carePackAdditionalChaId ||
      chaid.chaId.includes("_recommended") ||
      chaid.chaId.includes("_additional")
    ) {
      return (
        <FormControl component="fieldset" fullWidth>
          <FormGroup>
            {(chaid.required || chaid.selcon) && (
              <FormControlLabel
                value="None"
                control={
                  <BlueCheckbox
                    name="None"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={
                      <CheckBoxIcon fontSize="small" width="45" height="45" />
                    }
                    checked={noneState}
                    disabled
                  />
                }
                label="None"
                classes={{ label: classes.radioLabel }}
              />
            )}
            {sortedItems.map(item => (
              <Box key={item.inode} display="flex">
                <Box flex="1" style={{ wordBreak: "break-word" }}>
                  <FormControlLabel
                    checked={item.selected}
                    classes={{
                      label: item.selected
                        ? classes.selectedItemStyle
                        : item.precon < 0
                        ? classes.negativePrecon
                        : classes.radioLabel
                    }}
                    value={item.inode}
                    control={
                      chaid.qtyFlag ? (
                        <Tooltip
                          title={
                            t("common:configurator.maxQuantity") + maxQuantity
                          }
                          placement="right-start"
                          arrow
                        >
                          <TextField
                            variant="standard"
                            onBlur={event => {
                              if (item.quantity === "") {
                                handleQuantityChange(
                                  event,
                                  0,
                                  item.partno,
                                  item.chaId,
                                  item.inode,
                                  maxQuantity,
                                  minQuantity
                                );
                              }
                            }}
                            className={classes.quantity}
                            type="number"
                            onKeyDown={event => preventSpecialCharacters(event)}
                            style={{
                              minWidth: "50px",
                              maxWidth: `${getFieldWidth(item.quantity)}px`
                            }}
                            InputProps={{
                              defaultValue: item.quantity || 1,
                              inputProps: {
                                min: minQuantity,
                                max: maxQuantity
                              }
                            }}
                            onInput={event => ignoreLeadingZeroes(event)}
                            value={parseInt(item.quantity)}
                            onChange={event =>
                              handleQuantityChange(
                                event,
                                event.target.value,
                                item.partno,
                                item.chaId,
                                item.inode,
                                maxQuantity,
                                minQuantity
                              )
                            }
                          />
                        </Tooltip>
                      ) : (
                        <BlueCheckbox
                          name={chaid.chaId}
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={item.selected}
                          disabled={
                            (item.selected && isGenericSKU) ||
                            (isSKUModule &&
                              isBaseCreated &&
                              (restrictedChoicesForSKUModule.includes(
                                chaid.chaId
                              ) ||
                                BUNDLED_COMPONENT.includes(item.bndlBomType) ||
                                disableSelectedPN))
                          }
                        />
                      )
                    }
                    label={loadingLabel(
                      item.partno !== ""
                        ? `${item.partno} - ${item.partdes}`
                        : `${item.partdes}`,
                      loadingSelection === item.inode,
                      false,
                      t("common:configurator.futureGADateMsg"),
                      showFutureGADate(configID, configOrigin, item.gaDate) &&
                        item.selected
                    )}
                    name={chaid.chaId}
                    id={item.inode}
                    onChange={handleItemChange}
                  />
                </Box>
                {item.precon >= 0 || (item.precon < 0 && item.selected) ? (
                  <>
                    <Box
                      display="flex"
                      fontWeight={item.selected ? "bold" : "normal"}
                    >
                      <Box
                        width="120px"
                        fontWeight={item.selected ? "bold" : "normal"}
                        className={classes.box}
                      >
                        {getPrice(item, currencyCode)}
                      </Box>
                      {showFutureGADate(
                        configID,
                        configOrigin,
                        item.gaDate
                      ) && (
                        <Box
                          width="100px"
                          className={classes.box}
                          fontWeight={item.selected ? "bold" : "normal"}
                        >
                          {item?.gaDate && (
                            <>
                              GA:{" "}
                              {item.gaDate
                                ? transformDateFormat(item.gaDate, "MM/DD/YYYY")
                                : ""}
                            </>
                          )}
                        </Box>
                      )}
                      {showEsAndPriceSource(
                        region,
                        isStandaloneAccess ? country : countryCode,
                        "EM",
                        currentConfigID,
                        configOrigin
                      ) && (
                        <Typography
                          component="span"
                          variant="caption"
                          color="black"
                        >
                          <Box
                            width="100px"
                            fontWeight={item.selected ? "bold" : "normal"}
                          >
                            {t("common:configurator.EM") +
                              transformDateFormat(item.esDate, "MM/DD/YYYY")}
                          </Box>
                        </Typography>
                      )}
                      {/* Commented below code for getting pricesource for allthe regions
                            issue no: 3126 */}
                      {showEsAndPriceSource(
                        region,
                        isStandaloneAccess ? country : countryCode,
                        "PriceSource",
                        currentConfigID,
                        configOrigin
                      ) && (
                        <Typography
                          component="span"
                          variant="caption"
                          color="black"
                        >
                          <Box
                            width="100px"
                            fontWeight={item.selected ? "bold" : "normal"}
                          >
                            {/*Below condition is for issue no:3126 */}
                            {t("common:configurator.priceSource") +
                              (item.costPriceSource
                                ? item.costPriceSource
                                : item.priceSource)}
                          </Box>
                        </Typography>
                      )}
                      {currentConfigID && showGADate && isConfigOverDate && (
                        <Box
                          width="100px"
                          className={classes.box}
                          fontWeight={item.selected ? "bold" : "normal"}
                        >
                          {item?.gaDate && dateCompare(item?.gaDate) && (
                            <>
                              GA:{" "}
                              {item.gaDate
                                ? transformDateFormat(item.gaDate, "MM/DD/YYYY")
                                : ""}
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                    {item.selected && item.plcStatus === "I" && (
                      <div style={{ color: "red" }}>{item.plcMessage}</div>
                    )}
                  </>
                ) : (
                  <Typography
                    variant="caption"
                    className={classes.negativePrecon}
                  >
                    {item.cause
                      ? `This component has a conflict with current ${item.cause.chaDes} ${item.cause.partno}`
                      : t("common:conflicts.text4")}
                  </Typography>
                )}
              </Box>
            ))}
          </FormGroup>
        </FormControl>
      );
    } else {
      const recommndedProducts =
        (model &&
          model.Items?.filter(
            item => Number(item.priority) >= 1 && Number(item.priority) <= 5
          )?.sort(comparePriority)) ||
        [];
      const nonRecommendedProducts =
        (model &&
          model.Items?.filter(
            item => Number(item.priority) > 5 || item.priority === ""
          )?.sort(compare)) ||
        [];
      const sortByPriority = [...recommndedProducts, ...nonRecommendedProducts];
      return (
        <>
          <FormControl component="fieldset" fullWidth>
            <FormGroup defaultValue="None">
              {(chaid.required || chaid.selcon) && !chaid.qtyFlag && (
                <FormControlLabel
                  value="None"
                  control={
                    <BlueCheckbox
                      name="None"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      checked={noneState}
                      disabled
                    />
                  }
                  label="None"
                  classes={{ label: classes.radioLabel }}
                />
              )}
              {sortByPriority
                .filter(
                  part =>
                    part.visible &&
                    part.precon >= 0 &&
                    part.inode >= chaid.firstItem &&
                    part.inode <= chaid.lastItem &&
                    shouldShowOnUI(filterParams, part.gaDate, part.selected)
                )
                .concat(
                  conflictItems2.length === 0
                    ? finalConflictItemsSort
                    : finalConflictItems
                )
                .map(item => {
                  const isGaDateConflicts =
                    model &&
                    model.conflicts &&
                    model.conflicts[0].gaDateConflicts &&
                    model.conflicts[0].gaDateConflicts.some(
                      res =>
                        res.code === "ERR10" &&
                        res.chaId == chaid.chaId &&
                        res.partNo === item.partno
                    );
                  return (
                    <Grid container key={item.inode}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          checked={item.selected}
                          classes={{
                            label: item.selected
                              ? classes.selectedItemStyle
                              : item.precon < 0
                              ? classes.negativePrecon
                              : classes.radioLabel
                          }}
                          value={item.inode}
                          control={
                            chaid.qtyFlag ? (
                              <Tooltip
                                title={
                                  t("common:configurator.maxQuantity") +
                                  maxQuantity
                                }
                                placement="right-start"
                                arrow
                              >
                                <TextField
                                  variant="standard"
                                  onBlur={event => {
                                    if (item.quantity === "") {
                                      handleQuantityChange(
                                        event,
                                        event.target.value,
                                        item.partno,
                                        item.chaId,
                                        item.inode,
                                        maxQuantity,
                                        minQuantity
                                      );
                                    }
                                  }}
                                  className={classes.quantity}
                                  type="number"
                                  style={{
                                    minWidth: "50px",
                                    maxWidth: `${getFieldWidth(
                                      item.quantity
                                    )}px`
                                  }}
                                  InputProps={{
                                    defaultValue: parseInt(item.quantity) || 1,
                                    inputProps: {
                                      min: minQuantity,
                                      max: maxQuantity
                                    }
                                  }}
                                  value={parseInt(item.quantity)}
                                  onInput={event => ignoreLeadingZeroes(event)}
                                  onKeyDown={event =>
                                    preventSpecialCharacters(event)
                                  }
                                  onChange={event =>
                                    handleQuantityChange(
                                      event,
                                      event.target.value,
                                      item.partno,
                                      item.chaId,
                                      item.inode,
                                      maxQuantity,
                                      minQuantity
                                    )
                                  }
                                />
                              </Tooltip>
                            ) : (
                              <BlueCheckbox
                                name={chaid.chaId}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={item.selected}
                                disabled={
                                  (item.selcon && item.selected) ||
                                  (isSKUModule &&
                                    isBaseCreated &&
                                    (restrictedChoicesForSKUModule.includes(
                                      chaid.chaId
                                    ) ||
                                      BUNDLED_COMPONENT.includes(
                                        item.bndlBomType
                                      ) ||
                                      disableSelectedPN))
                                }
                              />
                            )
                          }
                          label={loadingLabel(
                            item.partno !== ""
                              ? `${item.partno} - ${item.partdes}`
                              : `${item.partdes}`,
                            loadingSelection === item.inode,
                            item.selcon && item.selected,
                            t("common:configurator.futureGADateMsg"),
                            showFutureGADate(
                              configID,
                              configOrigin,
                              item.gaDate
                            ) && item.selected
                          )}
                          name={chaid.chaId}
                          id={item.inode}
                          onChange={handleItemChange}
                          className={
                            model &&
                            model.conflicts &&
                            model.conflicts[0]?.itemConflictsList.length ===
                              1 &&
                            Number(errorData.value) === item.inode
                              ? classes.required
                              : null
                          }
                        />
                      </Grid>
                      {item.precon >= 0 ||
                      (item.precon < 0 && item.selected) ? (
                        <>
                          <Grid container item xs={6}>
                            <Grid item xs display="flex" alignItems="center">
                              {defaultConfigStepSlected ===
                                "monitoringAccesories" &&
                                Number(item.priority) >= 1 &&
                                Number(item.priority) <= 5 && (
                                  <Box width="100px">
                                    {t("common:configurator.recommended")}
                                  </Box>
                                )}
                            </Grid>
                            <Grid
                              item
                              xs
                              fontWeight={item.selected ? "bold" : ""}
                              className={classes.customGrid}
                            >
                              <Box
                                width="120px"
                                fontWeight={item.selected ? "bold" : "normal"}
                                className={classes.box}
                              >
                                {getPrice(item, currencyCode)}
                              </Box>
                              {showFutureGADate(
                                configID,
                                configOrigin,
                                item.gaDate
                              ) && (
                                <Box
                                  width="100px"
                                  className={classes.box}
                                  fontWeight={item.selected ? "bold" : "normal"}
                                >
                                  {item?.gaDate && (
                                    <>
                                      GA:{" "}
                                      {item.gaDate
                                        ? transformDateFormat(
                                            item.gaDate,
                                            "MM/DD/YYYY"
                                          )
                                        : ""}
                                    </>
                                  )}
                                </Box>
                              )}
                              {showEsAndPriceSource(
                                region,
                                isStandaloneAccess ? country : countryCode,
                                "EM",
                                currentConfigID,
                                configOrigin
                              ) && (
                                <Box
                                  width="100px"
                                  fontWeight={item.selected ? "bold" : "normal"}
                                >
                                  {t("common:configurator.EM") +
                                    (item.esDate
                                      ? transformDateFormat(
                                          item.esDate,
                                          "MM/DD/YYYY"
                                        )
                                      : "")}
                                </Box>
                              )}
                              {showEsAndPriceSource(
                                region,
                                isStandaloneAccess ? country : countryCode,
                                "PriceSource",
                                currentConfigID,
                                configOrigin
                              ) && (
                                <Box
                                  width="100px"
                                  fontWeight={item.selected ? "bold" : "normal"}
                                >
                                  {t("common:configurator.priceSource") +
                                    (item.costPriceSource
                                      ? item.costPriceSource
                                      : item.priceSource)}
                                </Box>
                              )}
                              {currentConfigID &&
                                showGADate &&
                                isConfigOverDate && (
                                  <Box
                                    width="100px"
                                    className={classes.box}
                                    fontWeight={
                                      item.selected ? "bold" : "normal"
                                    }
                                  >
                                    {item?.gaDate &&
                                      dateCompare(item?.gaDate) && (
                                        <>
                                          GA:{" "}
                                          {item.gaDate
                                            ? transformDateFormat(
                                                item.gaDate,
                                                "MM/DD/YYYY"
                                              )
                                            : ""}
                                        </>
                                      )}
                                  </Box>
                                )}
                            </Grid>
                          </Grid>
                          {item.selected && item.plcStatus === "I" && (
                            <div style={{ color: "red" }}>
                              {item.plcMessage}
                            </div>
                          )}
                          {item.plcStatus !== "I" && isGaDateConflicts && (
                            <Box style={{ color: "red" }}>
                              {t("common:configurator.componentNotAvailable")}
                            </Box>
                          )}
                          {/* Handling item level messages here */}
                          {model.icMsgs?.itemMsgs?.map(itemMsg =>
                            itemMsg?.itemInode === item?.inode &&
                            item?.selected &&
                            itemMsg.msgs?.length > 0 ? (
                              <Grid xs={12}>
                                <Alert
                                  severity="info"
                                  style={{
                                    margin: "8px 0px",
                                    borderRadius: "5",
                                    alignItems: "center"
                                  }}
                                >
                                  {itemMsg.msgs.map(itemMsg => (
                                    <Typography variant={"subtitle2"}>
                                      {itemMsg}
                                    </Typography>
                                  ))}
                                </Alert>
                              </Grid>
                            ) : null
                          )}
                          {/* item messages ends here */}
                        </>
                      ) : (
                        <Box display="flex" flex={1} justifyContent="flex-end">
                          <Typography
                            variant="caption"
                            className={classes.negativePrecon}
                          >
                            {item.cause
                              ? `This component has a conflict with current ${item.cause.chaDes} ${item.cause.partno}`
                              : t("common:conflicts.text4")}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  );
                })}
            </FormGroup>
          </FormControl>
        </>
      );
    }
  }
  // const ld = labelDisplay ? true : false;
  return (
    <>
      <Box width="100%" id={"chaid-dropdown-" + chaid.chaId}>
        <FormGroup>
          {getItems()}
          {itemShowOnPopup?.length > 0 && (
            <Typography variant="caption" className={classes.caption}>
              {t("common:conflicts.text5").replace(
                "{0}",
                finalConflictItems.length
              )}
              <Typography
                component="span"
                variant="caption"
                color="primary"
                onClick={handleOpen}
              >
                <span className={classes.viewMore}>{toggleName}.</span>
              </Typography>
            </Typography>
          )}
        </FormGroup>
      </Box>
    </>
  );
}

export default ItemMultipleCheckBox;

import { useMutation } from "@apollo/client";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Checkbox, Grid } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import { useSnackbar, withSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { SAVE_CONFIGURATION } from "../../../GraphQL";
import {
  CHECKED_FILTER_PARTS,
  SET_CAREPACKS_STATE,
  SET_CONFIG_NAME_SAVE,
  SET_FILTERED_CHOICES,
  SET_FILTERED_LOC,
  SET_ORIGINAL_ACCESS_TYPE,
  SET_ORIGIN_SELECTED_ITEMS,
  SET_SAVE_CONFIG_RESPONSE,
  SET_SEARCHED_TEXT,
  SET_SHOW_ADDITIONAL,
  SET_SHOW_OTHER_SERVICES,
  SET_SHOW_TOP_RECOMMEND
} from "../../../actions/Types";
import { togglePopupActions } from "../../../actions/togglePopupActions";
import ExternalProps from "../../../contexts/externalPropsContext";
import CpqButton from "../../../lib/common/CpqButton";
import {
  checkSectionID,
  getHighestDate,
  getStorageValue
} from "../../../lib/common/util";
import {
  getBOMData,
  getConfigBom
} from "../../../services/bomAndCarePackLib/utils";
import {
  checkGaDateWithinRange,
  getChoices,
  getConfigData,
  getConfigObjectDataForSave,
  getLocalizationOption,
  setModelDataForDefaultConfig
} from "../../../services/common/utils";
import {
  filterSearchTextChoices,
  handleConfigFilterCheckbox,
  handleConfigFilterClick,
  handleData
} from "../../../services/configFilterUtils";
import exportToPdf from "../../../services/exportToPdf";
import RSKUV2 from "../RSKUV2";
import RequestRskuV2 from "../RSKUV2/RequestRskuV2";
import {
  EXCLUDE_ALL_OTHER_COMPONENENTS,
  EXCLUDE_OTHER_COMPONENENTS
} from "../Rsku/Constants";
var XLSX = require("xlsx-color");

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    alignItems: "left",
    justifyContent: "left",
    paddingTop: "10px"
  },
  container: {
    maxHeight: 440
  },
  topPadding: {
    paddingTop: "20px"
  },
  searchInput: {
    marginTop: "7px",
    backgroundColor: "#fafafa"
  },
  input: {
    padding: "9px !important ",
    border: "none !important",
    width: "25ch"
  },
  listItem: {
    marginTop: "10px",
    "& button span": {}
  },
  searchButtonStyle: {
    marginLeft: "10px"
  },
  button: {
    whiteSpace: "nowrap",
    marginLeft: "auto",
    label: {
      fontWeight: "normal"
    },
    [theme.breakpoints.down("md")]: {
      width: 100,
      "& .MuiButton-label": {
        overflow: "hidden",
        "text-overflow": "ellipsis"
      }
    }
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  shortWrapper: {
    position: "absolute",
    right: "0",
    transform: "translateY(10px)",
    paddingLeft: "12px",
    "&.MuiGrid-root": {
      width: "33.333333%"
    }
  },
  popperContainer: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%"
  },
  popperHeader: {
    order: 1,
    flexShrink: 0,
    flexBasis: "30px",
    fontSize: "0.7em",
    alignSelf: "stretch"
  },
  popperList: {
    order: 2,
    overflowY: "scroll",
    flex: 1,
    scrollbarWidth: "thin" /*Firefox*/,
    width: "calc(100% - 2px)",
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgb(179, 178, 178)",
      borderRadius: "20px",
      border: "1px solid rgb(179, 178, 178)"
    }
  },
  popperFooter: {
    order: 3,
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
    alignItems: "flex-end",
    flexBasis: "30px",
    padding: "0px 0px 6px"
  },
  closeIcon: {
    cursor: "pointer",
    fontSize: "1.2rem",
    flex: "0.1",
    "&:hover": {
      color: "white",
      background: "#f50"
    }
  },
  checkedDiv: {
    display: "flex",
    border: "1px solid #29a8dd",
    marginRight: "10px",
    marginLeft: "3px",
    backgroundColor: "#ccf5ff",
    padding: "1px 3px",
    width: "105px",
    height: "33px",
    justifyContent: "center",
    alignItems: "center"
  },
  checkedItem: {
    textDecoration: "none",
    textOverflow: "ellipsis",
    fontSize: "0.75rem",
    fontWeight: "400",
    color: "black",
    position: "relative",
    verticalAlign: "middle",
    alignItems: "center",
    overflow: "hidden",
    overflowWrap: "break-word",
    display: "-webkit-box",
    "&::-webkit-line-clamp": 2,
    "&::-webkit-box-orient": "vertical",
    flex: "0.9",
    justifyContent: "center"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  wordBreak: {
    wordWrap: "break-word"
  },
  dropDownArrowIcon: {
    color: "#000000",
    opacity: "0.54"
  },
  ocaBottomSnackbar: {
    marginBottom: 0
  },
  bottomSnackbar: {
    marginBottom: 115
  },
  oracleExitSnackbar: {
    marginBottom: 115,
    backgroundColor: "#00d072 !important"
  }
}));

const mystyleButton = {
  marginLeft: "6px",
  textTransform: "none",
  color: "#29A8DD",
  fontWeight: 200,
  fontSize: "12px"
};
const myStyleFilter = {
  marginLeft: "6px",
  width: "auto",
  height: "36px",
  textTransform: "none",
  zIndex: "1",
  color: "#29A8DD",
  border: "1px solid #29A8DD",
  borderRadius: "0px",
  fontSize: "14px",
  fontWeight: "bold"
};
const myStyleExport = { marginLeft: "10px", fontWeight: "bold" };
const myStylePopper = {
  zIndex: "100",
  position: "fixed"
};
const myStylePaper = {
  height: "360px",
  maxHeight: "360px",
  overflow: "hidden",
  width: "430px",
  marginTop: "7px",
  zIndex: "1000",
  border: "2px solid #29A8DD",
  scrollbarWidth: "thin"
};

const ConfigFilter = props => {
  const { proCategory, currentCountry } = props;
  //const location = useLocation();
  //const [configName, setConfigName] = useState(location.configName);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isButtonShort, setIsButtonShort] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  //const history = useHistory();
  const dispatch = useDispatch();
  const bomData = useSelector(state => state.configFilterData.bomData);
  const isUploadConfig = useSelector(
    state => state.uploadConfig.isUploadConfig
  );
  const newSavedConfigId = useSelector(
    state => state.configFilterData.newSavedConfigId
  );
  const originalAccess = useSelector(
    state => state.configFilterData.originalAccessType
  );
  const accessType = useSelector(state => state.configFilterData.accessType);
  const configNameRed = useSelector(
    state => state.configFilterData.configNameRed
  );
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const configNameRedSaved = useSelector(
    state => state.configFilterData.configNameRedSaved
  );
  const modelData = useSelector(state => state.configFilterData.modelData);
  const haveSaved = useSelector(state => state.configFilterData.haveSaved);
  const originSelectedItems = useSelector(
    state => state.configFilterData.originSelectedItems
  );
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );

  const showBOMSectionFlag = useSelector(
    state => state.configFilterData.showBOMSectionFlag
  );
  const showGADate = modelData.showGADate;

  const configFilterData = useSelector(state => state.configFilterData);
  const { showSearchSku, showRskuBackDrop, category } = useSelector(
    state => state.productSelector
  );
  const programType = useSelector(state => state.productSelector.programType);
  const { isSkuCloning, isRequestRSKU } = useSelector(
    state => state.rskuReducer
  );
  const { skuIdData } = useSelector(state => state.productSelector);
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const originalData = useSelector(
    state => state.configFilterData.originalData
  );

  const filteredLoc = useSelector(state => state.configFilterData.filteredLoc);

  const { skuId } = skuIdData;

  const configName = props.name || configNameRed;
  const {
    transactionID = "",
    snackbarPosition = "top-left",
    saveConfigurationLabel = "SAVE CONFIGURATION",
    countryCode = "US",
    userId = "",
    userEmail = "",
    companyId = "",
    // productLine = "DT",
    spcType = "C",
    configurationName = "",
    visibility = {},
    brID,
    configOrigin,
    isStandaloneAccess = false,
    isOracleExit = false,
    pricingGeo,
    currencyCode,
    incoterms,
    channelRegion = "",
    configRegion,
    originatingAsset,
    clientOrigin,
    isReConfigureCTOBtn = false
  } = React.useContext(ExternalProps);
  const {
    showConfigurationButton = false,
    showStartOverButton = false,
    showExportToPdf = false,
    showExportToExcel = false
  } = visibility;
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);
  const [newSelectedItems, setNewSelectedItems] = useState([]);
  const [oldSelectedItems, setOldSelectedItems] = useState([]);
  const [isItemOverDate, setIsItemOverDate] = useState(false);
  const [isSearchSKU, setIsSearchSKU] = useState(false);

  const isConfigOverDate = useSelector(
    state => state.configFilterData.isConfigOverDate
  );
  const countryRegion = useSelector(
    state => state.productSelector.countryRegion
  );
  const currentConfigID = useSelector(
    state => state.productSelector.currentConfigID
  );

  const isRSKU = localStorage.getItem("isRSKU") === "true";
  const openSmartSearch = !isRSKU ? !isStandaloneAccess : false;
  const { haveFutureItem } = getConfigBom(modelData, {
    currentConfigID,
    showGADate,
    isConfigOverDate
  });
  const userType = getStorageValue("userType");
  const [isOverDate, setIsOverDate] = useState(
    (showGADate && isConfigOverDate) || haveFutureItem
  );

  const anchorRef = useRef(null);

  let textInput = useRef(null);
  let buttonsRef = useRef(null);
  const FILTER_PARTS = "Filter Parts";
  const showFilterParts =
    defaultConfigStepSlected === "hardware" && proCategory !== "poly";
  const originRetainConfigID = getStorageValue("retainConfigID");
  const [retainConfigID, setRetainConfigID] = useState(originRetainConfigID);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbar = { enqueueSnackbar, closeSnackbar };
  const sectionIds = isStandaloneAccess
    ? EXCLUDE_ALL_OTHER_COMPONENENTS
    : EXCLUDE_OTHER_COMPONENENTS;
  const {
    filteredSectionId,
    hasOtherComponent,
    rSKUBom,
    standardCarePacks,
    bomThreeCompatibleCarePacks,
    bomThreeCompatiblePartNumbers,
    bomThreeCompatibleCarePacksInfo,
    warrentyDescription
  } = checkSectionID(sectionIds, bomData);
  const selectedSeries = useSelector(
    state => state.productSelector.selectedSeriesFromAPI
  );
  const selectedCategory = useSelector(
    state => state.productSelector.selectedCategoryFromAPI
  );
  const docType = configuration?.configHeader?.docType;
  const isLatamRegion =
    countryRegion === "LA" || channelRegion === "LA" || configRegion === "LA";
  const refConfigId =
    docType === "SP" || docType === "CC"
      ? configuration?.configHeader?.configId?.toString() || ""
      : configuration?.configHeader?.refConfigId?.toString() || "";

  const otherHardCodeChaDes = [
    "Top Recommended Care Packs",
    "Additional Care Packs",
    "Software Solution Services",
    "Care Pack Services for:"
  ];
  const otherServicesChaDes = [
    "Protect (Security Services)",
    "Optimize (Manageability Services)",
    "Renew (Recover & Renew Services)",
    "Customer Specific Services",
    "SmartFriend Pro Bundle",
    "TC Conversion Solution"
  ];

  const CloseButton = ({ caption }) => (
    <div className={classes.checkedDiv}>
      <div
        className={classes.checkedItem}
        style={{
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical"
        }}
      >
        {caption}
      </div>
      <span
        className={classes.closeIcon}
        onClick={() => handleCheckbox(caption, 2)}
      >
        &times;
      </span>
    </div>
  );

  const CheckedItemsDiv = () => (
    <div style={{ display: "flex", flexDirection: "row", marginTop: "6px" }}>
      {checked
        .filter(item => item !== "CECP Compliance")
        .map((item, index) => (
          <CloseButton caption={item} key={index} />
        ))}
    </div>
  );

  const getGroupData = val => {
    const priorityList = _.groupBy(val, item => Number(item.priority) <= 5);
    return priorityList;
  };

  const setChoicesAndParts = useCallback(() => {
    const choices = getChoices(
      modelData,
      defaultConfigStepSlected,
      proCategory
    );
    const carePackAddOns =
      defaultConfigStepSlected === "carePacks" &&
      choices &&
      choices.length &&
      choices[0].items
        ? getGroupData(choices[0].items)
        : false;
    // getGroupData(choices[0].items);
    const carePacks = defaultConfigStepSlected === "carePacks" && [
      { chaDes: "Top Recommended Care Packs", items: carePackAddOns["true"] },
      { chaDes: "Additional Care Packs", items: carePackAddOns["false"] },
      ...choices.filter(chaid => chaid.chaId !== "ELECTCPQ")
    ];

    const arrChaDes =
      defaultConfigStepSlected === "carePacks"
        ? _.map(carePacks, "chaDes")
        : _.map(choices, "chaDes");
    setOptions(arrChaDes);
    // const data = getConfigData(modelData, defaultConfigStepSlected);
    // dispatch({ type: SET_FILTERED_CHOICES, payload: data });
    // dispatch({ type: SET_FILTERED_LOC, payload: [] });
  }, [dispatch, modelData, defaultConfigStepSlected, proCategory]);

  useEffect(() => {
    let billMaterials = document.querySelector("#container").children[1];
    let billWidth = getComputedStyle(billMaterials).width;
    billWidth = Number(billWidth.substring(billWidth.length - 2, 0));
    let buttonsWidth = [...buttonsRef.current.children].reduce((pre, cur) => {
      let width = getComputedStyle(cur).width;
      return pre + Number(width.substring(width.length - 2, 0)) + 10;
    }, 0);
    if (buttonsWidth <= billWidth) {
      setIsButtonShort(true);
    } else {
      setIsButtonShort(false);
    }
  }, [
    showStartOverButton,
    showSearchSku,
    isConfigOverDate,
    showBOMSectionFlag,
    showConfigurationButton,
    showExportToPdf,
    showExportToExcel,
    modelData?.isUploadFlow
  ]);
  useEffect(() => {
    let billMaterials = document.querySelector("#container").children[1];
    let billWidth = getComputedStyle(billMaterials).width;
    billWidth = Number(billWidth.substring(billWidth.length - 2, 0));
    let buttonsWidth = [...buttonsRef.current.children].reduce((pre, cur) => {
      let width = getComputedStyle(cur).width;
      return pre + Number(width.substring(width.length - 2, 0)) + 10;
    }, 0);
    if (buttonsWidth <= billWidth) {
      setIsButtonShort(true);
    } else {
      setIsButtonShort(false);
    }
  }, [
    showStartOverButton,
    showSearchSku,
    isConfigOverDate,
    showBOMSectionFlag,
    showConfigurationButton,
    showExportToPdf,
    showExportToExcel,
    modelData?.isUploadFlow
  ]);

  useEffect(() => {
    // TODO: Remember per tab?
    setSearchText("");
    setChoicesAndParts();
  }, [setChoicesAndParts]);
  useEffect(() => {
    if (proCategory) {
      const data = getConfigData(modelData, defaultConfigStepSlected);
      const choices = getChoices(
        modelData,
        defaultConfigStepSlected,
        proCategory
      );
      dispatch({
        type: SET_CAREPACKS_STATE,
        payload: false
      });
      let filterSearchTextChoicesParams = {
        searchText,
        otherHardCodeChaDes,
        dispatch,
        SET_SHOW_TOP_RECOMMEND,
        SET_SHOW_ADDITIONAL,
        otherServicesChaDes,
        SET_SHOW_OTHER_SERVICES,
        data,
        filters,
        SET_FILTERED_CHOICES,
        choices,
        defaultConfigStepSlected,
        userType,
        setSearchFilters,
        SET_FILTERED_LOC,
        filteredLoc,
        showFilterParts,
        configOrigin,
        originatingAsset,
        modelData
      };
      filterSearchTextChoices(filterSearchTextChoicesParams);
    }
  }, [
    dispatch,
    searchText,
    modelData,
    defaultConfigStepSlected,
    proCategory,
    filters
  ]);

  useEffect(() => {
    const filterSelectedItems =
      modelData?.Items &&
      modelData.Items.filter(
        item =>
          item.selected === true && item.partno !== "" && item.visible === true
      );

    filterSelectedItems &&
      setNewSelectedItems(
        _.orderBy(filterSelectedItems, ["partno"], ["asc"]).map(el => {
          delete el["tab"];
          delete el["cause"];
          return el;
        })
      );
    try {
      setOldSelectedItems(
        _.orderBy(JSON.parse(originSelectedItems), ["partno"], ["asc"])
      );
    } catch (e) {
      if (localStorage.getItem("isDebug") === "true") {
        console.log("No originSelectedItems");
      }
    }
    const baseUnitItem = modelData?.Chaids?.filter(
      item => item.chaId === "BUNIT"
    );
    const baseUnitSelectItems =
      baseUnitItem &&
      modelData?.Items &&
      modelData.Items.filter(
        item =>
          item.selected === true &&
          item.partno !== "" &&
          item.visible === true &&
          item.inode >= baseUnitItem[0].firstItem &&
          item.inode <= baseUnitItem[0].lastItem
      );
    setIsItemOverDate(
      baseUnitSelectItems?.some(
        item =>
          item.gaDate !== "NA" &&
          item.gaDate !== "" &&
          moment(item.gaDate).format("YYYY-MM-DD") >
            moment().format("YYYY-MM-DD")
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData, originSelectedItems]);

  useEffect(() => {
    const { haveFutureItem } = getConfigBom(modelData, {
      currentConfigID,
      showGADate,
      isConfigOverDate
    });
    setIsOverDate((showGADate && isConfigOverDate) || haveFutureItem);
    if (localStorage.getItem("isDebug") === "true") {
      console.log(
        "isConfigOverDate",
        isConfigOverDate,
        showGADate,
        haveFutureItem
      );
    }
  }, [showGADate, isConfigOverDate, currentConfigID, modelData]);

  const debounce = (func, timeout = 500) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
  const setSearchData = event => {
    const value = event.target.value.toString().trim().slice(0, 100);
    dispatch({ type: SET_SEARCHED_TEXT, payload: value });
    setSearchText(value);
  };
  const handleSearchChange = debounce(event => setSearchData(event));
  const [saveConfiguration] = useMutation(SAVE_CONFIGURATION, {
    fetchPolicy: "no-cache",
    onCompleted({ createConfiguration }) {
      if (createConfiguration) {
        // TODO: find another way to keep button loading
        // below line makes button keep spinning in standalone after save
        // setLoading(true);
        const position = snackbarPosition.split("-");
        const snackbarKey = enqueueSnackbar(
          t("common:snackbar.saveConfiguration"),
          {
            variant: "success",
            anchorOrigin: {
              vertical: position[0],
              horizontal: position[1]
            },
            className:
              configOrigin === "OCA" && snackbarPosition.includes("bottom")
                ? classes.ocaBottomSnackbar
                : snackbarPosition.includes("bottom") && isOracleExit
                ? classes.oracleExitSnackbar
                : snackbarPosition.includes("bottom")
                ? classes.bottomSnackbar
                : {},
            action: (
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={() => closeSnackbar(snackbarKey)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            )
          }
        );
      }
      batch(() => {
        dispatch({
          type: SET_SAVE_CONFIG_RESPONSE,
          newSavedConfigId: createConfiguration.configId,
          eventTriggered:
            saveConfigurationLabel === "SAVE CONFIGURATION" || isOverDate
              ? "SAVE_CONFIGURATION"
              : "ADD_TO_QUOTE"
        });
        dispatch({
          type: SET_CONFIG_NAME_SAVE,
          payload: configNameRed ? configNameRed : configName
        });
        dispatch({
          type: SET_ORIGIN_SELECTED_ITEMS,
          selectedItems: JSON.stringify(newSelectedItems),
          haveSaved: true
        });
        dispatch({
          type: SET_ORIGINAL_ACCESS_TYPE,
          payload: accessType
        });
      });

      if (isOverDate && checkGaDateWithinRange(highestDate)) {
        setLoading(false);
      } else {
        let handleDataParams = {
          newConfigId: createConfiguration.configId,
          proCategory,
          setLoading,
          configNameRed,
          configuration,
          docType,
          bomData,
          refConfigId,
          modelData,
          transactionID,
          saveConfigurationLabel,
          retainConfigID
        };
        handleData(handleDataParams);
      }
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
      if (localStorage.getItem("isDebug") === "true") {
        if (graphQLErrors) {
          console.log(graphQLErrors);
        }
        if (networkError) {
          console.log(networkError);
        }
      }
    }
  });

  const handleClick = action => {
    let handleConfigFilterClickParams = {
      action,
      options,
      setChecked,
      programType,
      isSkuCloning,
      skuId,
      newSavedConfigId,
      configuration,
      getBOMData,
      modelData,
      XLSX,
      saveAs,
      exportToPdf,
      setLoading,
      setModelDataForDefaultConfig,
      getConfigBom,
      getConfigObjectDataForSave,
      getLocalizationOption,
      currentCountry,
      countryCode,
      newSelectedItems,
      oldSelectedItems,
      setRetainConfigID,
      originRetainConfigID,
      configNameRed,
      docType,
      configurationName,
      accessType,
      refConfigId,
      userId,
      userEmail,
      brID,
      companyId,
      configOrigin,
      isStandaloneAccess,
      pricingGeo,
      currencyCode,
      incoterms,
      spcType,
      selectedCategory,
      selectedSeries,
      proCategory,
      saveConfiguration,
      checked,
      searchFilters,
      getConfigData,
      defaultConfigStepSlected,
      dispatch,
      SET_FILTERED_CHOICES,
      setFilters,
      SET_FILTERED_LOC,
      setOpen,
      setSaved
    };
    handleConfigFilterClick(handleConfigFilterClickParams);
  };
  useEffect(() => {
    const handleRequestRSKU = event => {
      dispatch({
        type: "SET_IS_REQUEST_RSKU",
        payload: event.detail?.isRequestRSKU
      });
    };
    const requestRSKUTarget = document.querySelector(
      `${isUploadConfig ? "#upload-config" : "#product-selector"}`
    );
    requestRSKUTarget?.addEventListener("isRequestRSKU", handleRequestRSKU);

    const handleCreateTxLindesFailed = event => {
      localStorage.getItem("isDebug") === "true" &&
        console.log("isCreateTxLindesFailed triggered", event.detail);
      if (event.detail?.isCreateTxLindesFailed) {
        setLoading(false);
        batch(() => {
          dispatch({ type: "SET_SHOW_BACKDROP", payload: false });
          dispatch({ type: "SET_IS_ADD_TO_QUOTE", payload: false });
        });
      }
    };
    const createTxLindesFailedTarget =
      document.querySelector("#product-selector");
    createTxLindesFailedTarget?.addEventListener(
      "isCreateTxLindesFailed",
      handleCreateTxLindesFailed
    );

    return () => {
      requestRSKUTarget?.removeEventListener(
        "isRequestRSKU",
        handleRequestRSKU
      );
      createTxLindesFailedTarget?.removeEventListener(
        "isCreateTxLindesFailed",
        handleCreateTxLindesFailed
      );
    };
  }, []);

  const handleCheckbox = (value, sel) => {
    let handleConfigFilterCheckboxParams = {
      value,
      sel,
      checked,
      setChecked,
      options,
      getConfigData,
      modelData,
      defaultConfigStepSlected,
      dispatch,
      SET_FILTERED_CHOICES,
      setFilters,
      searchFilters,
      SET_FILTERED_LOC
    };
    handleConfigFilterCheckbox(handleConfigFilterCheckboxParams);
  };
  const handleToggle = () => {
    if (open) {
      setChecked([...prevChecked]);
    } else {
      setPrevChecked([...checked]);
    }
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickAway = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setChecked(prevChecked);
    setOpen(false);
  };

  const [checked, setChecked] = useState([]);
  const [prevChecked, setPrevChecked] = useState([]);
  const [saved, setSaved] = useState(false);

  const handleRskuDialogOpen = React.useCallback(
    (params = {}) => {
      if (openSmartSearch) {
        setIsSearchSKU(true);
        dispatch({ type: "SET_SHOW_BACKDROP", payload: true });
      }

      batch(() => {
        dispatch({ type: "SET_RSKU_BOM", payload: rSKUBom });
        !openSmartSearch &&
          dispatch(
            togglePopupActions({
              name: "rskuDialog",
              open: true,
              ...params
            })
          );
        dispatch({
          type: "SET_OTHER_COMPONENTS",
          payload: filteredSectionId
        });
        dispatch({
          type: "SET_PROCATEGORY",
          payload: proCategory
        });
        dispatch({
          type: "SET_WARRANTY_DESCRIPTION",
          payload: warrentyDescription
        });
        dispatch({
          type: "SET_CAREPACKS",
          payload: {
            standardCarePacks,
            bomThreeCompatibleCarePacks: bomThreeCompatibleCarePacks,
            bomThreeCompatiblePartNumbers: bomThreeCompatiblePartNumbers,
            bomThreeCompatibleCarePacksInfo: bomThreeCompatibleCarePacksInfo
          }
        });
      });
    },
    [
      hasOtherComponent,
      filteredSectionId,
      standardCarePacks,
      bomThreeCompatibleCarePacks,
      warrentyDescription
    ]
  );
  useEffect(() => {
    dispatch({
      type: CHECKED_FILTER_PARTS,
      payload: filters.length > 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(true);

  useEffect(() => {
    if (category === "print" && configuration?.configHeader?.docType === "SP") {
      dispatch({
        type: SET_CONFIG_NAME_SAVE,
        payload: configuration?.configHeader?.configDesc
      });
    }
  }, []);

  const isPrintDefaultSPC =
    category === "print" && configuration?.configHeader?.docType === "SP";

  useEffect(() => {
    let isDisable;
    if (isPrintDefaultSPC && !haveSaved) {
      isDisable =
        JSON.stringify(newSelectedItems) === JSON.stringify(oldSelectedItems);
    } else {
      isDisable =
        accessType?.toLowerCase() === originalAccess?.toLowerCase() &&
        haveSaved &&
        JSON.stringify(newSelectedItems) === JSON.stringify(oldSelectedItems) &&
        (configNameRed === "" || configNameRed === configNameRedSaved);
    }
    setIsSaveButtonDisabled(isDisable);
  }, [configFilterData]);

  useEffect(() => {
    let buttonStatus = isSaveButtonDisabled && !haveSaved;
    setIsExportButtonDisabled(buttonStatus);
  }, [isSaveButtonDisabled]);

  const { maxGaDate } = getConfigBom(modelData, {
    currentConfigID,
    showGADate,
    isConfigOverDate
  });
  const highestDate = getHighestDate(
    configuration?.configHeader?.startDate,
    maxGaDate
  );
  const isSearchSKUVisible =
    showSearchSku &&
    configOrigin !== "OCA" &&
    proCategory !== "print" &&
    proCategory !== "poly";

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={
          showRskuBackDrop
            ? showRskuBackDrop
            : ["SAVE CONFIGURATION", "Add To Quote"].includes(
                saveConfigurationLabel
              ) || isOverDate
            ? loading
            : loading ||
              (haveSaved &&
                JSON.stringify(newSelectedItems) ===
                  JSON.stringify(oldSelectedItems) &&
                (configNameRed === "" ||
                  configNameRed === configuration?.configHeader?.configName))
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {proCategory !== "poly" && (
        <div>
          <Typography
            variant="h5"
            align="left"
            className={`${classes.bold} ${classes.wordBreak}`}
          >
            {configNameRedSaved ? t(configNameRedSaved) : t(configNameRed)}
          </Typography>
        </div>
      )}
      <Grid container className={classes.root}>
        <Grid item xs={3}>
          {proCategory !== "poly" && (
            <TextField
              placeholder={t(
                "common:productSelector.textMessage.pleaseEnterNone"
              )}
              variant="outlined"
              fullWidth
              size="small"
              className={classes.searchInput}
              // value={searchText}
              // onChange={handleSearchChange}
              onKeyUp={handleSearchChange}
              onKeyPress={e => {
                if (e.key === "Enter") handleClick("ALL_SEARCH");
              }}
              ref={anchorRef}
              InputProps={{
                classes: { input: classes.input },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                    {/* style={{marginTop:'8px'}}  */}
                  </InputAdornment>
                )
              }}
              inputRef={textInput}
              // onInput={e => {
              //   e.target.value = e.target.value.toString().slice(0, 100);
              // }}
            />
          )}
          <Popper
            style={myStylePopper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            disablePortal
            modifiers={{
              flip: {
                enabled: false
              },
              offset: {
                enabled: true,
                offset: "142, 0"
              },
              preventOverflow: {
                enabled: false,
                boundariesElement: "scrollParent"
              },
              hide: { enabled: false },
              computeStyle: { gpuAcceleration: false }
            }}
          >
            {
              <Paper style={myStylePaper}>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className={classes.popperContainer}>
                    <div className={classes.popperHeader}>
                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        style={mystyleButton}
                        onClick={event => handleClick("Select_All")}
                      >
                        {t("common:bom.selectAll")}
                      </Button>

                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        style={mystyleButton}
                        onClick={event => handleClick("Clear_All")}
                      >
                        {t("common:configurator.clearAll")}
                      </Button>
                    </div>

                    <MenuList
                      id="split-button-menu"
                      className={classes.popperList}
                    >
                      {options
                        .filter(option => option !== "CECP Compliance")
                        .map((option, index) => (
                          <MenuItem
                            key={index}
                            disableRipple
                            onClick={() => handleCheckbox(option, 1)}
                            style={{ fontSize: "smaller", height: "30px" }}
                          >
                            <Checkbox
                              disableRipple
                              edge="start"
                              checked={checked.indexOf(option) !== -1}
                              onClick={() => handleCheckbox(option, 1)}
                              color="primary"
                              style={{ padding: "0px 3px" }}
                            />
                            <span className={classes.partsOptions}>
                              {option}
                            </span>
                          </MenuItem>
                        ))}
                    </MenuList>
                    <div className={classes.popperFooter}>
                      <Button
                        style={{
                          textTransform: "none",
                          color: "#29A8DD",
                          width: "100px",
                          border: "2px solid #29A8DD",
                          marginRight: "15px"
                        }}
                        className={classes.button}
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={event => handleClick("SAVE_FILTER")}
                      >
                        {t("button.save")}
                      </Button>
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            }
          </Popper>
        </Grid>

        <Grid item xs={2} className={classes.listItem}>
          {showFilterParts && (
            <CpqButton
              style={myStyleFilter}
              color="primary"
              // size="small"
              variant="outlined"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              {t("common:configurator.filterParts") + " "}
              <ArrowDropDownIcon className={classes.dropDownArrowIcon} />
            </CpqButton>
          )}
        </Grid>
        <Grid
          container
          item
          xs
          className={!isButtonShort ? classes.wrapper : classes.shortWrapper}
          ref={buttonsRef}
        >
          <Grid>
            {showStartOverButton && (
              <Button
                sx={{ fontWeight: "bold" }}
                color="primary"
                variant="contained"
                size="medium"
                onClick={event => handleClick("START_OVER")}
              >
                {t("common:productSelector.button.startOver")}
              </Button>
            )}
          </Grid>
          {isSearchSKUVisible && (
            <Grid>
              <CpqButton
                color="primary"
                variant="outlined"
                size="medium"
                style={{ marginLeft: "10px", fontWeight: "bold" }}
                disabled={!showBOMSectionFlag}
                onClick={handleRskuDialogOpen}
              >
                {t("common:productSelector.button.searchSKU")}
              </CpqButton>
            </Grid>
          )}
          {showBOMSectionFlag &&
            showConfigurationButton &&
            (!isLatamRegion || (isLatamRegion && category !== "compute")) && (
              <Grid>
                <CpqButton
                  color="primary"
                  variant="outlined"
                  disabled={isSaveButtonDisabled}
                  onClick={event =>
                    handleClick(
                      isReConfigureCTOBtn && clientOrigin === "BMI"
                        ? "SAVE_CONFIGURATION"
                        : clientOrigin === "BMI" &&
                          !checkGaDateWithinRange(highestDate)
                        ? "ADD_TO_QUOTE"
                        : isOverDate
                        ? "SAVE_CONFIGURATION"
                        : saveConfigurationLabel.toUpperCase() ===
                          "SAVE CONFIGURATION"
                        ? "SAVE_CONFIGURATION"
                        : "ADD_TO_QUOTE"
                    )
                  }
                  style={myStyleExport}
                  isToolTipText={
                    category === "print" &&
                    isSaveButtonDisabled &&
                    t("common:configurator:saveConfigDisable")
                  }
                >
                  {isReConfigureCTOBtn && clientOrigin === "BMI"
                    ? t("common:configurator:saveConfig")
                    : clientOrigin === "BMI" &&
                      !checkGaDateWithinRange(highestDate)
                    ? t("common:searchProduct.addToQuote")
                    : isOverDate
                    ? t("common:configurator:saveConfig")
                    : saveConfigurationLabel.toUpperCase() ===
                      "SAVE CONFIGURATION"
                    ? t("common:configurator:saveConfig")
                    : t("common:searchProduct.addToQuote")}
                </CpqButton>
              </Grid>
            )}
          {showBOMSectionFlag &&
            showExportToPdf &&
            !modelData?.isUploadFlow && (
              <Grid>
                <CpqButton
                  color="primary"
                  variant="outlined"
                  style={myStyleExport}
                  onClick={event => handleClick("EXPORT_TO_PDF")}
                  //   href={locationUrl}
                  disabled={isPrintDefaultSPC && isExportButtonDisabled}
                >
                  {t("common:configurator:exportPdf")}
                </CpqButton>
              </Grid>
            )}
          {showBOMSectionFlag &&
            showExportToExcel &&
            !modelData?.isUploadFlow && (
              <Grid>
                <CpqButton
                  color="primary"
                  variant="outlined"
                  style={myStyleExport}
                  onClick={event => handleClick("EXPORT_TO_EXCEL")}
                  disabled={isPrintDefaultSPC && isExportButtonDisabled}
                >
                  {t("common:configurator:exportExcel")}
                </CpqButton>
              </Grid>
            )}
        </Grid>
      </Grid>
      {showFilterParts && checked.length && saved ? <CheckedItemsDiv /> : null}
      {isSearchSKU && <RSKUV2 setIsSearchSKU={setIsSearchSKU} />}
      {isRequestRSKU && <RequestRskuV2 />}
    </>
  );
};

export default withSnackbar(ConfigFilter);

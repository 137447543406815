import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_CHOICE_EXPAND } from "../actions/Types";
import { checkStoreFront } from "../lib/common/util";
import {
  adpCarepackChaId,
  customerSpecificChaIds,
  customerSpecificServicesChaId,
  otherCarePacksChaId,
  supportExtensionCarepackChaId
} from "../services/bomAndCarePackLib/utils";
import {
  shouldHideChoices
} from "../services/common/utils";
import { CarePackAdditional } from "./Localization/CarePackAdditional";
import { CarePackRecommendation } from "./Localization/CarePackRecommendation";
import { OtherCarePacks } from "./Localization/OtherCarePacks";
import { MandaCarePacks } from "./MandaCarePack";
import UnSplitCarepacks from "./UnSplitCarepacks";

const CarePacksStepper = props => {
  const { proCategory, handleItemChange, handleQuantityChange } = props;
  //importing state from store
  const modelData = useSelector(state => state.configFilterData.modelData);
  const verticalStepSelected = useSelector(
    state => state.configFilterData.activeStep
  );
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  let filteredChoices = useSelector(
    state => state.configFilterData.filteredChoices
  );

  filteredChoices =
    defaultConfigStepSlected === "carePacks" && verticalStepSelected
      ? filteredChoices.filter(
          choices => choices.parentId === verticalStepSelected
        )
      : filteredChoices;
  const showWarning = useSelector(state => state.configFilterData.showWarning);
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const expColButton = useSelector(
    state => state.configFilterData.expColButton
  );
  const isHP2BOrECOMMConfig = checkStoreFront(modelData);
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const isExpandAll = expColButton[defaultConfigStepSlected] || false;
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const searchText = useSelector(state => state.configFilterData.searchText);
  const filteredLoc = useSelector(state => state.configFilterData.filteredLoc);
  //definign local states
  const [expanded, setExpanded] = useState(true);
  const [expandedAdp, setExpandedAdp] = useState(true);
  const [expandedSE, setExpandedSE] = useState(true);
  const [expandedCSS, setExpandedCSS] = useState(true);

  const dispatch = useDispatch();

  //Check current expand state of this tab and update the local state accordingly
  //Local state will change the behaviour of a single conatiner kind of Accordian
  useEffect(() => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (relevantTabData) {
        if (isExpandAll) {
          setExpanded(relevantTabData.choicesExpandState[otherCarePacksChaId]);
          setExpandedAdp(relevantTabData.choicesExpandState[adpCarepackChaId]);
          setExpandedCSS(
            relevantTabData.choicesExpandState[customerSpecificServicesChaId]
          );
          if (
            !relevantTabData.choicesExpandState.hasOwnProperty("SUPPEXT_CP")
          ) {
            setExpandedSE(
              relevantTabData.choicesExpandState[supportExtensionCarepackChaId]
            );
          } else {
            setExpandedSE(relevantTabData.choicesExpandState["SUPPEXT_CP"]);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll, defaultConfigStepSlected]);

  useEffect(() => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (relevantTabData && !isExpandAll) {
        setExpanded(relevantTabData.choicesExpandState[otherCarePacksChaId]);
        setExpandedAdp(relevantTabData.choicesExpandState[adpCarepackChaId]);
        setExpandedSE(
          relevantTabData.choicesExpandState[supportExtensionCarepackChaId]
        );
        setExpandedCSS(
          relevantTabData.choicesExpandState[customerSpecificServicesChaId]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultConfigStepSlected]);

  useEffect(() => {
    setExpanded(isExpandAll);
    setExpandedAdp(isExpandAll);
    setExpandedSE(isExpandAll);
  }, [isExpandAll]);
  useEffect(() => {
    if (searchText) {
      if (
        filteredLoc.includes("HP Absolute Care Pack Services") ||
        filteredLoc.includes("HW Support  CTO/BTO only (E)")
      ) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (filteredLoc.includes("HP ADP Care Pack Services")) {
        setExpandedAdp(true);
      } else {
        setExpandedAdp(false);
      }
      if (filteredLoc.includes("Support Extensions")) {
        setExpandedSE(true);
      } else {
        setExpandedSE(false);
      }
    }
  }, [searchText]);
  let chaId;
  const handleChange = (otherCarePacks, e) => {
    e.preventDefault();
    const relevantExpandData = expandAll.find(
      expandedData => expandedData.tab === defaultConfigStepSlected
    );
    chaId = otherCarePacks.map(item => item.key);
    if (relevantExpandData && otherCarePacks) {
      otherCarePacks.map(item => {
        if (
          Object.keys(relevantExpandData.choicesExpandState).indexOf(
            item.key
          ) !== -1
        ) {
          relevantExpandData.choicesExpandState[item.key] = false;
        }
      });
      if (chaId.includes("PSE") || chaId.includes("ABSCP")) {
        relevantExpandData.choicesExpandState[otherCarePacksChaId] = !expanded;
        if (relevantExpandData.choicesExpandState.hasOwnProperty("PSE")) {
          relevantExpandData.choicesExpandState["PSE"] = false;
        }
        if (relevantExpandData.choicesExpandState.hasOwnProperty("ABSCP")) {
          relevantExpandData.choicesExpandState["ABSCP"] = false;
        }
      } else if (customerSpecificChaIds.find(item => chaId?.includes(item))) {
        relevantExpandData.choicesExpandState[customerSpecificServicesChaId] =
          !expandedCSS;
      } else if (chaId.includes("ADP")) {
        relevantExpandData.choicesExpandState[adpCarepackChaId] = !expandedAdp;
        if (relevantExpandData.choicesExpandState.hasOwnProperty("ADP")) {
          relevantExpandData.choicesExpandState["ADP"] = false;
        }
      } else {
        relevantExpandData.choicesExpandState[supportExtensionCarepackChaId] =
          !expandedSE;

        if (
          relevantExpandData.choicesExpandState.hasOwnProperty("SUPPEXT_CP")
        ) {
          relevantExpandData.choicesExpandState["SUPPEXT_CP"] = false;
        }
        if (relevantExpandData.choicesExpandState.hasOwnProperty("SEE")) {
          relevantExpandData.choicesExpandState["SEE"] = false;
        }
      }

      const payload = {
        tab: defaultConfigStepSlected,
        choicesExpandState: relevantExpandData.choicesExpandState
      };
      dispatch({
        type: UPDATE_CHOICE_EXPAND,
        payload
      });
    }
    (chaId.includes("PSE") || chaId.includes("ABSCP")) &&
      setExpanded(!expanded);
    chaId.includes("ADP") && setExpandedAdp(!expandedAdp);
    (chaId.includes("SEE") || chaId.includes("SUPPEXT_CP")) &&
      setExpandedSE(!expandedSE);
    customerSpecificChaIds.find(item => chaId?.includes(item)) &&
      setExpandedCSS(!expandedCSS);
  };

  const isChaidHideBasedOnRegion = chaidId => {
    return shouldHideChoices(ocfgParams?.hideChoice, chaidId, modelData);
  };

  //actual rendering of the component
  return (
    <React.Fragment>
      {isHP2BOrECOMMConfig ? (
        <UnSplitCarepacks
          filteredChoices={filteredChoices}
          modelData={modelData}
          handleItemChange={handleItemChange}
          handleQuantityChange={handleQuantityChange}
        />
      ) : (
        <>
          <CarePackRecommendation
            filteredChoices={filteredChoices}
            proCategory={proCategory}
            isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
            modelData={modelData}
            handleItemChange={handleItemChange}
          />
          <CarePackAdditional
            filteredChoices={filteredChoices}
            isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
            modelData={modelData}
            handleItemChange={handleItemChange}
          />
        </>
      )}
      <OtherCarePacks
        proCategory={proCategory}
        filteredChoices={filteredChoices}
        isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
        expanded={expanded}
        expandedAdp={expandedAdp}
        expandedSE={expandedSE}
        expandedCSS={expandedCSS}
        handleChange={handleChange}
        showWarning={showWarning}
        modelData={modelData}
        handleItemChange={handleItemChange}
        handleQuantityChange={handleQuantityChange}
      />
      <MandaCarePacks
        filteredChoices={filteredChoices}
        isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
        modelData={modelData}
        handleItemChange={handleItemChange}
        handleQuantityChange={handleQuantityChange}
        showWarning={showWarning}
      />
    </React.Fragment>
  );
};

export default CarePacksStepper;

import { ErrorOutline } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { ExternalProps } from "../../../contexts/externalPropsContext";
export const UploadConfigBomTip = prpos => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(true);
  const { channelRegion } = React.useContext(ExternalProps);
  const useStyles = makeStyles(theme => ({
    tipContent: {
      color: "#075782",
      background: "rgb(229, 245, 251)",
      fontWeight: "400",
      border: "1px solid #cceaf7",
      borderRadius: "4px",
      fontSize: "16px"
    },
    title: {
      fontSize: "20px",
      flex: 1,

      "&>div": {
        display: "flex",
        alignItems: "center",
        "& .error_icon": {
          width: "26px",
          height: "26px",
          marginRight: "8px",
          color: "rgb(0, 150, 214)"
        }
      }
    },
    expandOrCollapseBtn: {
      height: "38px",
      lineHeight: "38px"
    }
  }));
  const classes = useStyles();
  const handleChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion
      className={classes.tipContent}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.title}>
          <div>
            <ErrorOutline className="error_icon" />
            {t("common:uploadConfig.bomTipTitle")}
          </div>
        </Typography>
        <Typography className={classes.expandOrCollapseBtn}>
          {!expanded
            ? t("common:uploadConfig.bomTipExpand")
            : t("common:uploadConfig.bomTipCollapse")}
        </Typography>
      </AccordionSummary>

      <AccordionDetails style={{ paddingLeft: "50px", paddingTop: "0" }}>
        <Typography>{t("common:uploadConfig.startTip")}</Typography>
        <Typography>{t("common:uploadConfig.inputTip")}</Typography>
        {channelRegion === "AP" && (
          <Typography>{t("common:uploadConfig.uploadTip2")}</Typography>
        )}
        <Typography>{t("common:uploadConfig.uploadTip3")}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

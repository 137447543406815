const productSelectorBtoSkuColumns = [
  {
    id: "configPunchInURL",
    label: ""
  },
  {
    id: "skuId",
    label: "SKU ID",
    displayAs: "chip"
  },
  {
    id: "description",
    label: "Description",
   
  },
  {
    id: "skuStatus",
    label: "Status",
  }
];
export default productSelectorBtoSkuColumns;

import { MonLocChaids } from "../Constants"
import { removeDuplicate } from "../util"

export const getAllMonitorParts = modelData => {
  let partNumber = []
  for (let choice of modelData.Chaids) {
    if (MonLocChaids.includes(choice.chaId)) {
      for (let i = choice.firstItem; i <= choice.lastItem; i++) {
        const wP = modelData.Items[i].partno.split("#")[0]
        partNumber = [...partNumber, wP]
      }
    }
  }
  return removeDuplicate(partNumber)
}
export const doesElementExistString = (elm, arr) => {
  return arr.some(each => each === elm)
}

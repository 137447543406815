import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  SET_EXPAND_COLLAPSE,
  SET_EXPAND_COLLAPSE_BUTTON,
  UPDATE_SCROLL_FILTER_CHOICE
} from "../../../actions/Types";
import ExternalProps from "../../../contexts/externalPropsContext";
import { customerSpecificChaIds } from "../../../services/bomAndCarePackLib/utils";
import {
  getExpandState,
  shouldHideChoices
} from "../../../services/common/utils";

const useStyles = makeStyles(theme => ({
  btn: {
    fontFamily: "inherit",
    fontSize: "16px",
    color: "#29A8DD",
    fontWeight: "normal",
    textTransform: "capitalize"
  }
}));

const ExpandAndCollapseBtn = ({ proCategory }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { visibility = {}, userType } = React.useContext(ExternalProps);
  const { showAccessType } = visibility;
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const filteredChoices = useSelector(
    state => state.configFilterData.filteredChoices
  );
  const filteredLoc = useSelector(state => state.configFilterData.filteredLoc);
  function showLoc(locCaption) {
    return filteredLoc.includes(locCaption);
  }
  const configFilterData = useSelector(state => state.configFilterData);
  const { isVerticalStepper, stepId, expandValue } =
    getExpandState(configFilterData);

  const isExpandAll =
    typeof expandValue === "boolean" ? expandValue : isVerticalStepper;
  const searchText = useSelector(state => state.configFilterData.searchText);
  const checkedFilterParts = useSelector(
    state => state.configFilterData.checkedFilterParts
  );

  const isChaidExpand = useSelector(
    state => state.configFilterData.isChaidExpand
  );

  const modelData = useSelector(state => state.configFilterData.modelData);
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const showAccessOrganization =
    showAccessType && !searchText && !checkedFilterParts;
  const expandAll = useSelector(state => state.configFilterData.expandAll);

  const handleExpandDataPayload = () => {
    let choiceObject = {};
    for (let i = 0; i < filteredChoices.length; i++) {
      const shouldHideChoice = shouldHideChoices(
        ocfgParams?.hideChoice,
        filteredChoices[i].chaId,
        modelData
      );
      if (!shouldHideChoice) {
        choiceObject[filteredChoices[i].chaId] = isVerticalStepper;
      }

      if (
        defaultConfigStepSlected === "carePacks" &&
        filteredChoices[i].chaId.startsWith("CAPQ_")
      ) {
        choiceObject[filteredChoices[i].chaId + "_additional"] = isVerticalStepper;
        choiceObject[filteredChoices[i].chaId + "_recommended"] = isVerticalStepper;
      }
    }
    //to incorporate the locale display options in hardware tab
    if (defaultConfigStepSlected === "hardware") {
      if (showAccessOrganization) {
        choiceObject["access-type"] = false;
      }
      if (showLoc("System Localization Options")) {
        choiceObject["local-1"] = false;
      }
      if (showLoc("OS Localization Options")) {
        choiceObject["local-2"] = false;
      }
      if (showLoc("Keyboard Localization Options")) {
        choiceObject["local-3"] = false;
      }
      if (showLoc("Monitor Localization Options")) {
        choiceObject["local-4"] = false;
      }
    }

    //to incorporate the locale display options in carePack tab
    if (defaultConfigStepSlected === "carePacks") {
      choiceObject["99999"] = isVerticalStepper;
      choiceObject["99998"] = isVerticalStepper;
      choiceObject["ELECTCPQ"] = isVerticalStepper;
      if (proCategory === "compute") {
        choiceObject["SSS99"] = isVerticalStepper;
        choiceObject["CSS99"] = isVerticalStepper;
        if (filteredChoices.includes("ADP")) choiceObject["ACPS99"] = isVerticalStepper;
        filteredChoices.forEach(obj => {
          if (
            Object.values(obj).includes("SUPPEXT_CP") ||
            Object.values(obj).includes("SEE")
          ) {
            choiceObject["SE99"] = false;
          }
        });
      }
    }
    if (
      defaultConfigStepSlected === "configurationService" &&
      userType?.toUpperCase() !== "PARTNER"
    ) {
      const isCustomerChoice = modelData.Chaids.some(
        chaid => chaid.chaId === "CS_C"
      );
      if (isCustomerChoice) {
        choiceObject["CS_C"] = isVerticalStepper;
      }
    }
    const payload = {
      tab: defaultConfigStepSlected,
      choicesExpandState: choiceObject
    };
    if (defaultConfigStepSlected === "carePacks") {
      if (!window.hasAnyAdditionalPacks) {
        delete payload?.choicesExpandState["99998"];
      }
      if (!window.hasAnyRecommendedPacks) {
        delete payload?.choicesExpandState["99999"];
      }

      if (window.hasAnyRecommendedPacks || window.hasAnyAdditionalPacks) {
        delete payload?.choicesExpandState["ELECTCPQ"];
      }
    }
    return payload;
  };

  const isEqualChoicesExpandState = (found = [], payload = []) => {
    return found.every(el => payload.includes(el))
  }

  useEffect(() => {
    if (filteredChoices && filteredChoices.length) {
      if (defaultConfigStepSlected && expandAll) {
        const found = expandAll.find(
          expandedData => expandedData.tab === defaultConfigStepSlected
        );
        if (!found || !searchText) {
          const payload = handleExpandDataPayload();
          const newPayloadDataLength =
            payload?.choicesExpandState &&
            Object.keys(payload?.choicesExpandState)?.length;
          const existingExpandDataLength =
            found?.choicesExpandState &&
            Object.keys(found?.choicesExpandState)?.length;
          if (found && newPayloadDataLength !== existingExpandDataLength) {
            Object.keys(found?.choicesExpandState).map(choice => {
              payload.choicesExpandState[choice] =
                found.choicesExpandState[choice];
            });
            dispatch({
              type: UPDATE_SCROLL_FILTER_CHOICE,
              payload
            });
          } else {
            if (found && payload && isEqualChoicesExpandState(Object.keys(found?.choicesExpandState), Object.keys(payload?.choicesExpandState))) {
              dispatch({
                type: SET_EXPAND_COLLAPSE,
                payload
              });
            } else {
              let expandAllChaIds = []
              if (found) {
                expandAllChaIds = [...expandAll].map(el => el.tab == payload.tab ?
                  {
                    ...el, choicesExpandState: {
                      ...payload?.choicesExpandState,
                      ...el?.choicesExpandState
                    }
                  } : el)
              }
              else {
                expandAllChaIds = [...expandAll, payload]
              }

              dispatch({
                type: "SET_EXPAND_COLLAPSE_UPDATE",
                payload: expandAllChaIds
              });
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredChoices]);

  useEffect(() => {
    if (defaultConfigStepSlected && expandAll) {
      const found = expandAll.find(
        expandedData => expandedData.tab === defaultConfigStepSlected
      );
      let filterSearchChoiceObject = {};
      if (found) {
        if (checkedFilterParts && defaultConfigStepSlected === "hardware") {
          if (filteredLoc) {
            if (filteredLoc.includes("System Localization Options")) {
              filterSearchChoiceObject["local-1"] = true;
            }
            if (filteredLoc.includes("OS Localization Options")) {
              filterSearchChoiceObject["local-2"] = true;
            }
            if (filteredLoc.includes("Keyboard Localization Options")) {
              filterSearchChoiceObject["local-3"] = true;
            }
            if (filteredLoc.includes("Monitor Localization Options")) {
              filterSearchChoiceObject["local-4"] = true;
            }
          }
          if (filteredChoices.length) {
            for (let index = 0; index < filteredChoices.length; index++) {
              filterSearchChoiceObject[filteredChoices[index].chaId] = false;
            }
            const payload = {
              tab: defaultConfigStepSlected,
              choicesExpandState: filterSearchChoiceObject
            };
            dispatch({
              type: UPDATE_SCROLL_FILTER_CHOICE,
              payload
            });
          }
        }
        if (searchText) {
          if (filteredLoc.length > 0) {
            for (let index = 0; index < filteredChoices?.length; index++) {
              let choice = filteredChoices[index];
              filterSearchChoiceObject[choice.chaId] = true;

              if (defaultConfigStepSlected === "carePacks") {
                if (
                  choice.chaId.startsWith("CAPQ_") &&
                  (choice.chaId.endsWith("_recommended") ||
                    choice.chaId.endsWith("_additional"))
                ) {
                  filterSearchChoiceObject[choice.chaId] = false;
                }
                if (filteredChoices[index].chaId === "ELECTCPQ") {
                  filterSearchChoiceObject["99998"] = true;
                  filterSearchChoiceObject["99999"] = true;
                }
                if (
                  proCategory === "compute" &&
                  (filteredChoices[index].chaId === "PSE" ||
                    filteredChoices[index].chaId === "ABSCP")
                ) {
                  filterSearchChoiceObject["SSS99"] = true;
                }
                if (
                  proCategory === "compute" &&
                  customerSpecificChaIds.includes(filteredChoices[index].chaId)
                ) {
                  filterSearchChoiceObject["CSS99"] = true;
                }
              }
            }
            if (defaultConfigStepSlected === "hardware") {
              if (filteredLoc.includes("System Localization Options")) {
                filterSearchChoiceObject["local-1"] = true;
              }
              if (filteredLoc.includes("OS Localization Options")) {
                filterSearchChoiceObject["local-2"] = true;
              }
              if (filteredLoc.includes("Keyboard Localization Options")) {
                filterSearchChoiceObject["local-3"] = true;
              }
              if (filteredLoc.includes("Monitor Localization Options")) {
                filterSearchChoiceObject["local-4"] = true;
              }
            }
            if (filteredLoc.includes("Customer")) {
              filterSearchChoiceObject["CS_C"] = true;
            }

            const payload = {
              tab: defaultConfigStepSlected,
              choicesExpandState: filterSearchChoiceObject
            };
            dispatch({
              type: UPDATE_SCROLL_FILTER_CHOICE,
              payload
            });
          }
        }

        if (checkedFilterParts && filteredLoc?.length > 0) {
          if (defaultConfigStepSlected === "hardware") {
            if (filteredLoc.includes("System Localization Options")) {
              filterSearchChoiceObject["local-1"] = true;
            }
            if (filteredLoc.includes("OS Localization Options")) {
              filterSearchChoiceObject["local-2"] = true;
            }
            if (filteredLoc.includes("Keyboard Localization Options")) {
              filterSearchChoiceObject["local-3"] = true;
            }
            if (filteredLoc.includes("Monitor Localization Options")) {
              filterSearchChoiceObject["local-4"] = true;
            }
          }
          for (let index = 0; index < filteredChoices.length; index++) {
            if (
              !shouldHideChoices(
                ocfgParams?.hideChoice,
                filteredChoices[index].chaId,
                modelData
              )
            ) {
              filterSearchChoiceObject[filteredChoices[index].chaId] = true;
            }
            if (
              defaultConfigStepSlected === "carePacks" &&
              filteredChoices[index].chaId.startsWith("CAPQ_")
            ) {
              filterSearchChoiceObject[
                filteredChoices[index].chaId + "_additional"
              ] = false;
              filterSearchChoiceObject[
                filteredChoices[index].chaId + "_recommended"
              ] = false;
            }
            // eslint-disable-next-line array-callback-return
            Object.keys(found.choicesExpandState).map(el => {
              if (defaultConfigStepSlected === "carePacks") {
                if (
                  el.startsWith("CAPQ_") &&
                  (el.endsWith("_recommended") || el.endsWith("_additional"))
                ) {
                  filterSearchChoiceObject[el] = false;
                }
                if (
                  filteredChoices[index].chaId === "ELECTCPQ" &&
                  (el === "99998" || el === "99999")
                ) {
                  filterSearchChoiceObject[el] = false;
                }
                if (
                  filteredChoices[index].chaId === "PSE" ||
                  filteredChoices[index].chaId === "ABSCP"
                )
                  if (proCategory === "compute")
                    filterSearchChoiceObject["SSS99"] = false;
                if (
                  customerSpecificChaIds.includes(
                    filteredChoices[index].chaId
                  ) &&
                  proCategory === "compute"
                ) {
                  filterSearchChoiceObject["CSS99"] = false;
                }
                if (filteredChoices[index].chaId === "ADP")
                  if (proCategory === "compute")
                    filterSearchChoiceObject["ACPS99"] = false;
                if (
                  filteredChoices[index].chaId === "SUPPEXT_CP" ||
                  filteredChoices[index].chaId === "SEE"
                )
                  if (proCategory === "compute")
                    filterSearchChoiceObject["SE99"] = false;
              }
            });
          }
          const payload = {
            tab: defaultConfigStepSlected,
            choicesExpandState: filterSearchChoiceObject
          };
          dispatch({
            type: UPDATE_SCROLL_FILTER_CHOICE,
            payload
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, checkedFilterParts, filteredLoc]);

  //this function will handle the onClick event of Button which will expand all or collapse all the choice accordians
  const onExpandBtnHandler = e => {
    e.preventDefault();
    if (isVerticalStepper) {
      dispatch({
        type: "SET_EXPAND_COLLAPSE_BUTTON_VERTICAL",
        payload: { expand: !isExpandAll, stepper: stepId }
      });
    } else {
      dispatch({
        type: SET_EXPAND_COLLAPSE_BUTTON,
        payload: !isExpandAll
      });
    }
    if (!isExpandAll) {
      dispatch({
        type: "RESET_CHAID_EXPAND_COLLAPSE",
        payload: { ...isChaidExpand, [defaultConfigStepSlected]: {} }
      })
    } else {
      for (let i = 0; i < expandAll.length; i++) {
        if (expandAll[i].tab === defaultConfigStepSlected) {
          const _Object = {}
          Object.keys(expandAll[i].choicesExpandState).forEach(el => {
            _Object[el] = false;
          })
          expandAll[i].choicesExpandState = _Object;
          break;
        }
      }

      dispatch({
        type: "RE_SET_EXPAND_COLLAPSE",
        payload: expandAll
      })
    }
  };

  // TODO: 37280
  const fetchButtonContent = () => {
    return expandAll.length > 0
      ? isExpandAll
        ? t("common:configurator:collapseAll")
        : t("common:configurator:expandAll")
      : "Loading";
  };
  return (
    <Box display="flex" justifyContent="flex-end">
      {filteredChoices.length || filteredLoc.length ? (
        <Button
          color="primary"
          className={classes.btn}
          onClick={onExpandBtnHandler}
        >
          {fetchButtonContent()}
        </Button>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ExpandAndCollapseBtn;

import { useLazyQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GET_RSKU_USER_ACCESS } from "../../../../GraphQL";
import ExternalProps from "../../../../contexts/externalPropsContext";
import displayNotistack from "../../../../lib/common/SnackBarUtils";

const useInvokeRskuUserAccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isStandaloneAccess, routeToMarket } = React.useContext(ExternalProps);

  const [rSkuUserInfo, setRskuUserInfo] = useState({});

  const { productRegionCode, productCountryCode } = useSelector(
    state => state.rskuReducer
  );

  const businessModel = useSelector(
    state => state.productSelector?.businessModel
  );
  useEffect(() => {
    if (!isEmpty(rSkuUserInfo)) {
      dispatch({
        type: "SET_RSKU_USER_ACCESS",
        payload: {
          ...rSkuUserInfo,
          routeToMarket: isStandaloneAccess ? businessModel : routeToMarket,
          productRegionCode,
          productCountryCode
        }
      });
    }
  }, [rSkuUserInfo, productRegionCode, productCountryCode, businessModel]);

  const [getRSKUAccess] = useLazyQuery(GET_RSKU_USER_ACCESS, {
    fetchPolicy: "no-cache",
    onCompleted({ getRskuUserAccess }) {
      const { roleName, userCountryMap } = getRskuUserAccess;
      if (getRskuUserAccess) {
        const payload = {
          roleName,
          userCountryMap
        };
        setRskuUserInfo(payload);
      }
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          displayNotistack.error(false, `${message}`);
        });
      }
      if (networkError) {
        displayNotistack.error(false, t("common:table.networkErrorMsg"));
      }
    }
  });

  return input => getRSKUAccess(input);
};

export default useInvokeRskuUserAccess;

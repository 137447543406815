import { useSelector } from "react-redux";
import CompareDialog from "./CompareDialog";
import ConfirmationDialog from "./ConfirmationDialog";
import InfoDialog from "./InfoDialog";
import RequestRsku from "./RequestRsku";
import RskuDialog from "./RskuDialog";
const Popups = () => {
  const {
    rskuDialog,
    compareDialog,
    requestSkuDialog,
    confirmationDialog,
    rskuInfoDialog
  } = useSelector(state => state.rskuReducer.popups);

  return (
    <>
      {rskuDialog?.open && <RskuDialog />}
      {compareDialog?.open && <CompareDialog />}
      {requestSkuDialog?.open && <RequestRsku />}
      {confirmationDialog?.open && <ConfirmationDialog />}
      {rskuInfoDialog?.open && <InfoDialog />}
    </>
  );
};

export default Popups;

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ExternalProps } from "../../../contexts/externalPropsContext";

const useStyles = makeStyles(theme => {
  return createStyles({
    quantityTextField: {
      margin: `0px ${theme.spacing(0.5)}`,
      "& [class*=MuiInput-input]": {
        textAlign: "center"
      }
    },
    quantityButton: {
      padding: 0
    },
    deleteIcon: {
      minWidth: 0,
      marginLeft: theme.spacing(1.5),
      padding: theme.spacing(0.5),
      "&:hover": {
        color: theme.palette.secondary.main
      }
    },
    deleteIconTooltipTitle: {
      textTransform: "capitalize"
    }
  });
});

const ProductQuantity = props => {
  const {
    sku,
    category,
    listPrice,
    name,
    image,
    parentSku,
    currency,
    currencySign,
    quantity,
    isSimilarProduct,
    skuType
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isStandaloneAccess = false } = useContext(ExternalProps);
  const [inputWidth, setInputWidth] = useState(27);
  const handleOutputQuantities = quantityValue => {
    const payload = {
      sku,
      quantity: quantityValue,
      category,
      name,
      listPrice,
      image,
      parentSku,
      currency,
      currencySign,
      isSimilarProduct,
      skuType
    };

    dispatch({
      type: "SET_UPDATE_QUANTITY",
      payload
    });
  };
  const handleIncrement = () => {
    handleOutputQuantities(parseInt(quantity) + 1);
  };

  const handleDecrement = () => {
    handleOutputQuantities(parseInt(quantity) - 1);
  };

  const handleQuantity = event => {
    const regexp = /^[0-9\b]+$/;
    const quantityValue = event.target.value || 0;
    if (
      regexp.test(quantityValue) &&
      quantityValue >= 0 &&
      quantityValue <= 999999
    ) {
      handleOutputQuantities(parseInt(quantityValue));
    }
  };

  const getFieldWidth = useCallback(value => {
    const input = value ? value.toString() : 0;
    const width = input ? (parseInt(input.toString().length) + 2) * 9 : 27;
    setInputWidth(width);
  }, []);

  useEffect(() => {
    getFieldWidth(quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  return (
    <>
      <Box whiteSpace={"nowrap"}>
        <IconButton
          onClick={handleDecrement}
          size="small"
          disabled={isStandaloneAccess || quantity <= 0}
          color="primary"
          component="span"
          className={classes.quantityButton}
        >
          <RemoveCircleIcon />
        </IconButton>
        <TextField
          variant="standard"
          style={{ width: `${inputWidth}px` }}
          onChange={handleQuantity}
          type="text"
          value={quantity}
          disabled={isStandaloneAccess}
          InputProps={{
            inputProps: {
              maxLength: 6
            }
          }}
          className={classes.quantityTextField}
        />
        <IconButton
          onClick={handleIncrement}
          component="span"
          size="small"
          color="primary"
          disabled={isStandaloneAccess || quantity >= 999999}
          className={classes.quantityButton}
        >
          <AddCircleIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default ProductQuantity;

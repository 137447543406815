import {
  CODE_CHOICE_CONFLICT,
  CODE_HIDDEN_CHOICE_CONFLICT,
  ClicChaid,
  ConfigurationServicesChaidsPrefix,
  DO_DESELECT,
  DO_SELECT,
  DefaultQty,
  DummyChoiceIDs,
  EdgeType_C_AVL,
  EdgeType_C_INC,
  EdgeType_C_MSG,
  EdgeType_C_REQ,
  EdgeType_FLAGS,
  EdgeType_GTE_0,
  EdgeType_INVERT,
  EdgeType_I_AVL,
  EdgeType_I_DEF,
  EdgeType_I_MSG,
  EdgeType_I_REQ,
  EdgeType_I_SEL,
  EdgeType_R_INC,
  HIDDEN_CHOICES,
  ItemDisabledForPartner,
  SelectedQty
} from "../Constants";
import {
  RemoveElmConflictsForNotSelected,
  doesSubElementExistString,
  isParentChoice,
  segregatePayload,
  updateCarePacks
} from "../util";
import { processInput } from "./processInput";
import { isAnyItemSelectedIn } from "./util";

let eff = [];
let cse = [];
let itemConflicts = [];
let reqCse;
let techAvitems = [];
let currentItem;
let selItem = [];
let hcConflicts = [];
let sameConflictItems = {};
let chConflicts = [];
const Edge_Type_Sel = 68;
const Edge_Type_DeSel = 67;

const isDebug = window.localStorage.getItem("isDebug") === "true";
//src/model/neural-net.go
export const getIChaid = (itemNode, model) => {
  const chaids = model.Chaids;
  const index = chaids.findIndex(
    (chaid, index) => index !== 0 && itemNode <= chaid?.lastItem
  );
  let iChaId = {};
  if (index > 0) {
    iChaId = {
      Ichaid: index,
      ChaId: chaids[index].chaId,
      ChaDes: chaids[index].chaDes
    };
  }
  return iChaId;
};
const doesElementExistItemConflictsNn = (iNode, iprecon, itemConflicts) => {
  let conflicts = false;
  for (let i = 0; i < itemConflicts.length; i++) {
    if (
      itemConflicts[i].effect.iitem === iNode &&
      itemConflicts[i].effect.iprecon === iprecon
    ) {
      conflicts = true;
      break;
    }
  }
  return conflicts;
};
export const initializeNeuralNetVars = () => {
  eff = [];
  cse = [];
  itemConflicts = [];
  reqCse = [];
  techAvitems = [];
  currentItem = -1;
  selItem = [];
  hcConflicts = [];
  sameConflictItems = {};
};
//src/model/neural-net.go
const addElmConflictsNn = (iNode, iprecon, change, modelData) => {
  const { Ichaid, ChaId, ChaDes } = getIChaid(iNode, modelData);
  const newEffect = {
    iitem: iNode,
    partno: modelData.Items[iNode].partno,
    ichaid: Ichaid,
    chaId: ChaId,
    chaDes: ChaDes,
    ichange: change,
    etype: EdgeType_I_AVL,
    iprecon: iprecon
  };
  return newEffect;
};
const removeElmConflictsNn = (iNode, iprecon, itemConflict) => {
  for (let i = 0; i < itemConflict.length; i++) {
    if (
      itemConflict[i].effect.iprecon === iprecon &&
      itemConflict[i].effect.iitem === iNode
    ) {
      return [...itemConflict.slice(0, i), ...itemConflict.slice(i + 1)];
    }
  }
  return itemConflict;
};
export const getItemConflictsNn = () => {
  return itemConflicts;
};

export const setItemConflictsNn = itemConflict => {
  itemConflicts = itemConflict;
};

export const getDeselItemsList = () => {
  return currentItem;
};
export const setDeselItemsList = () => {
  currentItem = 0;
};

export const getReqCause = () => {
  return reqCse;
};

export const getTechAvItems = () => {
  return techAvitems;
};

export const setTechAvItems = () => {
  techAvitems = [];
};
export const getItemConflicts = () => {
  let effect;
  let cause;
  if (eff.length > 0) {
    cause = cse;
    effect = eff;
  }
  return cause?.length > 0 || effect?.length > 0
    ? {
        cause,
        effect
      }
    : {};
};
const getSelectedTechavItems = (modelData, reqCse, partno) => {
  modelData.Items.forEach(vI => {
    if (vI.partno === partno && vI.valueId?.length !== 0 && !vI.selected) {
      const { Ichaid, ChaId, ChaDes } = getIChaid(vI.inode, modelData);
      const newItem = {
        //Doubt:caps case or small case of letters
        partNo: vI.partno,
        chaId: ChaId,
        itemInode: vI.inode,
        selected: true,
        cause: reqCse
      };
      techAvitems = [...techAvitems, newItem];
    }
  });
};
const doesElementExistHiddenChoiceConflicts = (
  inode,
  iprecon,
  hiddenChoiceConflicts
) => {
  let conflicts = false;
  for (let i = 0; i < hiddenChoiceConflicts.length; i++) {
    if (
      hiddenChoiceConflicts[i].effect.iitem === inode &&
      hiddenChoiceConflicts[i].effect.iprecon === iprecon
    ) {
      conflicts = true;
      break;
    }
  }
  return conflicts;
};
const doesElementExistChoiceConflicts = (inode, iprecon, choiceConflicts) => {
  let conflicts = false;
  for (let i = 0; i < choiceConflicts?.length; i++) {
    if (
      choiceConflicts[i]?.effectChoice.ichaid === inode &&
      choiceConflicts[i]?.effectChoice.iprecon === iprecon
    ) {
      conflicts = true;
      break;
    }
  }
  return conflicts;
};

const removeElmChoiceConflicts = (inode, iprecon, choiceConflicts) => {
  let tempArr = [];
  choiceConflicts?.forEach(conflict => {
    if (
      conflict?.effectChoice?.iprecon != iprecon &&
      conflict?.effectChoice.ichaid != inode
    ) {
      tempArr = [...tempArr, conflict];
    }
  });
  return tempArr;
};
const addElmChoiceConflicts = (chaidInode, iprecon, iChange, model) => {
  return {
    ichaid: chaidInode,
    chaId: model.Chaids[chaidInode].chaId,
    chaDes: model.Chaids[chaidInode].chaDes,
    ichange: iChange,
    etype: Edge_Type_DeSel,
    iprecon: iprecon
  };
};

const updateEdgesForClicPartiallyAffectedItems = (
  change,
  edgeTypePrev,
  iNode,
  nodeName,
  modelData
) => {
  if (nodeName?.includes(ClicChaid)) {
    const { idx, flag } = doesSubElementExistString(
      String(iNode),
      modelData?.clicPartiallyAffectedItems
    );
    if (flag) {
      if (change == -1) {
        if (modelData?.clicPartiallyAffectedItems[idx]?.includes(":")) {
          modelData.clicPartiallyAffectedItems[idx] =
            modelData.clicPartiallyAffectedItems[idx] +
            "," +
            String(edgeTypePrev);
        } else {
          modelData.clicPartiallyAffectedItems[idx] =
            modelData.clicPartiallyAffectedItems[idx] +
            ":" +
            String(edgeTypePrev);
        }
      } else {
        if (modelData?.clicPartiallyAffectedItems[idx]?.includes(":")) {
          const clicData =
            modelData.clicPartiallyAffectedItems?.[idx]?.split(":");
          if (clicData && clicData[1].includes(",")) {
            const clicD = clicData[1].split(",");
            const clic = clicD.slice(0, clicD?.length - 1);
            modelData.clicPartiallyAffectedItems[idx] =
              clicData[0] + ":" + clic.join(",");
          } else {
            modelData.clicPartiallyAffectedItems[idx] = clicData[0];
          }
        }
      }
    }
  }
};

const selectInput = (input, modelData) => {
  let cause = cse
  let selItems = selItem

  let updatedModelData = modelData
  let data = []
  data.push(input)

  let { cpPayload, nonCpPayload } = segregatePayload(
    { itemChanges: data },
    updatedModelData
  )
  if (cpPayload.itemChanges.length !== 0) {
    updatedModelData = updateCarePacks(cpPayload, updatedModelData, true)
  }
  nonCpPayload.itemChanges.forEach(iPayLoad => {
    updatedModelData = processInput(input, modelData)
  })
  cse = cause
  selItem = selItems
  return updatedModelData
}

const addElmHiddenConflicts = (itemInode, iprecon, iChange, model) => {
  const { Ichaid, ChaId, ChaDes } = getIChaid(itemInode, model);
  return {
    iitem: itemInode,
    partno: model.Items[itemInode].partno,
    ichaid: Ichaid,
    chaId: ChaId,
    chaDes: ChaDes,
    ichange: iChange,
    etype: Edge_Type_DeSel,
    iprecon: iprecon
  };
};
const RemoveElmHiddenConflictsForNotSelected = (
  iNode,
  hiddenChoiceConflicts
) => {
  let tempArr = [];
  hiddenChoiceConflicts.forEach(conflict => {
    if (conflict.effect.iitem !== iNode) {
      tempArr = [...tempArr, conflict];
    }
  });
  return tempArr;
};
const removeElmHiddenChoiceConflicts = (
  inode,
  iprecon,
  hiddenChoiceConflicts
) => {
  let tempArr = [];
  hiddenChoiceConflicts.forEach(conflict => {
    if (
      conflict.effect.iprecon !== iprecon &&
      conflict.effect.iitem !== inode
    ) {
      tempArr = [...tempArr, conflict];
    }
  });
  return tempArr;
};
export const setHiddenChoiceConflicts = hiddenChoiceConflict => {
  hcConflicts = hiddenChoiceConflict;
};

export const getHiddenChoiceConflicts = () => {
  return hcConflicts;
};

export const getSameConflictItems = () => {
  return sameConflictItems;
};
export const getChoiceConflicts = () => {
  return chConflicts;
};

export const setChoiceConflicts = choiceConflicts => {
  chConflicts = choiceConflicts;
};

//src/model/neural-net.go
export const processOutput = (payload, modelData) => {
  isDebug && console.log("Process output START");
  isDebug && console.log("Process output => input arguments payload", payload);
  let { edgeType, iNode, change, currentInode, nodeName } = payload;
  let edgeTypePrev = edgeType;

  // detect the invert flag on the input and invert the signal carryIn
  if ((edgeType & EdgeType_INVERT) == EdgeType_INVERT) {
    change = -change;
  }
  // strip the INVERT flag from the Edge before processing the transit
  edgeType = edgeType & 0x7f;
  let cseInode;

  let { Chaids, Items, Counters } = modelData;
  switch (edgeType) {
    case EdgeType_FLAGS:
      if (iNode === 0) {
        modelData.preConConflict = change === 1 ? true : false;
      } else {
        modelData.selConConflict = change === 1 ? true : false;
      }
      break;
    case EdgeType_C_AVL:
      Chaids[iNode].precon += change;
      cseInode = 0;
      if (cse != null && cse.length > 0) {
        cseInode = cse[0].iitem;
      }

      if (!HIDDEN_CHOICES[Chaids[iNode].chaId]) {
        if (change == 1) {
          if (
            doesElementExistChoiceConflicts(iNode, currentInode, chConflicts)
          ) {
            chConflicts = removeElmChoiceConflicts(
              iNode,
              currentInode,
              chConflicts
            );
          }
          if (
            cse &&
            cse[0].ichange > 0 &&
            cse[0].chaId.startsWith(ConfigurationServicesChaidsPrefix) &&
            isParentChoice(cse[0].chaId, modelData) &&
            modelData.Chaids[iNode].chaId.startsWith(cse[0].chaId) &&
            modelData.Chaids[iNode].precon >= 0
          ) {
            if (!modelData.parentChildChoiceMapping) {
              modelData.parentChildChoiceMapping = {};
            }

            if (!modelData.parentChildChoiceMapping[cse[0].chaId]) {
              modelData.parentChildChoiceMapping[cse[0].chaId] = {};
            }
            modelData.parentChildChoiceMapping[cse[0].chaId][cse[0].iitem] = iNode;
          }
        } else if (change === -1 && cseInode > 0 && cseInode !== iNode) {
          if (
            !doesElementExistChoiceConflicts(iNode, currentInode, chConflicts)
          ) {
            const choiceConflicts = {
              code: CODE_CHOICE_CONFLICT,
              cause: cse[0],
              effectChoice: addElmChoiceConflicts(
                iNode,
                currentInode,
                change,
                modelData
              )
            };
            chConflicts = [...chConflicts, choiceConflicts];
          }
        }
      }
      modelData.conflicts[0].choiceConflicts = chConflicts;

      if (Chaids[iNode].precon) {
        isDebug &&
          console.log(
            "CASE EdgeType_C_AVL==> Chaid:",
            Chaids[iNode].chaId,
            "ChaidPrecon:",
            Chaids[iNode].precon
          );
      }
      break;
    case EdgeType_C_REQ:
      Chaids[iNode].selcon = change === 1 ? true : false;
      break;
    case EdgeType_I_AVL:
      Items[iNode].precon += change;
      cseInode = 0;
      if (cse != null && cse.length > 0) {
        cseInode = cse[0].iitem;
      }

      const itemSelected = Items[iNode].selected;
      const { Ichaid, ChaId, ChaDes } = getIChaid(iNode, modelData);
      if (HIDDEN_CHOICES[ChaId]) {
        if (itemSelected) {
          if (change == 1) {
            if (
              doesElementExistHiddenChoiceConflicts(
                iNode,
                currentInode,
                hcConflicts
              )
            ) {
              hcConflicts = removeElmHiddenChoiceConflicts(
                iNode,
                currentInode,
                hcConflicts
              );
            }
          } else if (change == -1 && cseInode > 0 && cseInode != iNode) {
            if (
              !doesElementExistHiddenChoiceConflicts(
                iNode,
                currentInode,
                hcConflicts
              )
            ) {
              const hiddenChoiceConflicts = {
                code: CODE_HIDDEN_CHOICE_CONFLICT,
                cause: cse[0],
                effect: addElmHiddenConflicts(
                  iNode,
                  currentInode,
                  change,
                  modelData
                )
              };
              hcConflicts = [...hcConflicts, hiddenChoiceConflicts];
            }
          } else if (
            change == -1 &&
            cseInode > 0 &&
            cseInode == iNode &&
            Ichaid > 0 &&
            !modelData.Chaids[Ichaid].multiple
          ) {
            sameConflictItems[iNode] = Items[iNode].partno;
          }
        } else {
          hcConflicts = RemoveElmHiddenConflictsForNotSelected(
            iNode,
            hcConflicts
          );
        }
      } else {
        if (itemSelected) {
          if (change === 1) {
            if (
              doesElementExistItemConflictsNn(
                iNode,
                currentInode,
                itemConflicts
              )
            ) {
              itemConflicts = removeElmConflictsNn(
                iNode,
                currentInode,
                itemConflicts
              );
            }
          } else if (change === -1 && cseInode > 0 && cseInode != iNode) {
            if (
              !doesElementExistItemConflictsNn(
                iNode,
                currentInode,
                itemConflicts
              )
            ) {
              const itemConflictList = {
                cause: cse[0],
                effect: addElmConflictsNn(
                  iNode,
                  currentInode,
                  change,
                  modelData
                )
              };

              itemConflicts = [...itemConflicts, itemConflictList];
            }
          } else if (
            change == -1 &&
            cseInode > 0 &&
            cseInode == iNode &&
            Ichaid > 0 &&
            !modelData.Chaids[Ichaid].multiple
          ) {
            sameConflictItems[iNode] = Items[iNode].partno;
          }
        } else {
          itemConflicts = RemoveElmConflictsForNotSelected(
            iNode,
            itemConflicts
          );
        }
        updateEdgesForClicPartiallyAffectedItems(
          change,
          edgeTypePrev,
          iNode,
          nodeName,
          modelData
        );
      }
      break;
    case EdgeType_I_SEL: // = &0x44 SelCon KMAT Item
      Items[iNode].selected = change === 1;
      isDebug &&
        console.log(
          "CASE EdgeType_I_SEL==> PartDesc:",
          Items[iNode].partdes,
          "PartNo:",
          Items[iNode].partno,
          "Inode:",
          iNode,
          "Selected:",
          Items[iNode].selected,
          "Precon:",
          Items[iNode].precon
        );

      if (change === -1) {
        itemConflicts = RemoveElmConflictsForNotSelected(iNode, itemConflicts);
        if (Items[iNode].iDefault) {
          //In case of T I_DEF rule, if pre-selected item is getting deselected by the user, we will make its iDefault as false
          Items[iNode].iDefault = false;
        }
      }

      currentItem = Items[iNode].inode;

      if (Items[iNode].valueId?.length !== 0) {
        cse = [];
        eff = [];
        const { Ichaid, ChaId, ChaDes } = getIChaid(iNode, modelData);
        const newCause = {
          iitem: iNode,
          partno: Items[iNode].partno,
          ichaid: Ichaid,
          chaId: ChaId,
          chaDes: ChaDes,
          ichange: change,
          etype: Edge_Type_Sel
        };
        cse = [...cse, newCause];
      }
      if (change === 1 && Items[iNode].valueId?.length !== 0) {
        selItem = [];
        selItem = cse;
      }
      break;
    case EdgeType_I_REQ:
      Items[iNode].selcon = change === 1;
      Items[iNode].selected = change === 1;
      if (
        modelData.modelRequirement.productGroup === "PY" &&
        cse.length > 0 
      ) {
        Items[iNode].quantity = Items[cse[0].iitem].quantity;
      }
      if (Items[iNode]?.selcon) {
        isDebug &&
          console.log(
            "CASE EdgeType_I_REQ==> PartDesc",
            Items[iNode]?.partdes,
            "Selcon:",
            Items[iNode]?.selcon
          );
      }
      if (Items[iNode].valueId?.length === 0 && change === 1) {
        let inode;
        let choiceId;
        let resflag = false;
        modelData.Items.forEach(val => {
          if (val.partno == Items[iNode].partno && val.valueId?.length !== 0) {
            inode = val.inode;
            resflag = true;
          }
        });
        if (resflag) {
          const { Ichaid, ChaId, ChaDes } = getIChaid(inode, modelData);
          choiceId = ChaId;
        }
        if (choiceId?.length > 0) {
          if (cse?.length > 0 && Items[iNode].partno !== cse[0].partno) {
            reqCse = cse[0];
            getSelectedTechavItems(modelData, reqCse, Items[iNode].partno);
          } else if (cse?.length > 0 && Items[iNode].partno === cse[0].partno) {
            if (selItem?.length > 0 && selItem[0].partno !== cse[0].partno) {
              getSelectedTechavItems(
                modelData,
                selItem[0],
                Items[iNode].partno
              );
            } else {
              getSelectedTechavItems(modelData, reqCse, Items[iNode].partno);
            }
          }
        }
      }
      break;
    case EdgeType_R_INC:
      Counters[iNode].count = Counters[iNode].count + change;
      break;
    case EdgeType_C_INC:
      Chaids[iNode].count = Chaids[iNode].count + change;
      break;
    case EdgeType_I_DEF:
      //changes for preselection R type
      if (!Items[iNode]?.plcMessage?.includes(ItemDisabledForPartner)) {
        if (change >= 4) {
          change -= 4;
          Items[iNode].selcon = true;
        } else if (change <= -4) {
          change += 4;
          Items[iNode].selcon = false;
        }
        // If item not selected and
        if (change === 1 || change === 3) {
          if (!Items[iNode].selected) {
            //Change == T or TF and Item Dynamic Select condn goes True
            const { Ichaid: iChaid, ChaId} = getIChaid(iNode, modelData);
            if (
              modelData.Chaids[iChaid].multiple ||
              (!isAnyItemSelectedIn(modelData, iChaid) &&
                Items[iNode].plcStatus == "V" &&
                (Items[iNode].priceStatus == "V" || DummyChoiceIDs.includes(ChaId)))
            ) {
              modelData = selectInput(
                {
                  // Add the Item Dynamic Select(+1) to ProcessInput
                  inputEdge: EdgeType_GTE_0,
                  inputNode: iNode,
                  changeIn: DO_SELECT
                },
                modelData
              );
              Items[iNode].iDefault = true;
              Items[iNode].quantity = SelectedQty
            }
          } else {
            Items[iNode].iDefault = true;
          }
        } else if (change === -2 || change === -3) {
          //Change == F or TF and Item Dynamic Select condn goes False
          if (Items[iNode].selected) {
            modelData = selectInput(
              {
                // Add the Item Dynamic Select(-1) to ProcessInput
                inputEdge: EdgeType_GTE_0,
                inputNode: iNode,
                changeIn: DO_DESELECT
              },
              modelData
            );
            Items[iNode].iDefault = false;
            Items[iNode].quantity = DefaultQty
          }
        }
      }
      break;
    //2 Cases with same definition
    //change is going to be msg index, can be > 1, if +ve add msg string else remove string, will also tell which item/chaid it is associated, cases would be same but table entries would be different as its based on iNode only.
    case EdgeType_C_MSG:
      if (change > 0) {
        if (modelData?.MsgInfo?.length > 0) {
          if (modelData?.icMsgs?.choiceMsgs) {
            let flag = false;
            for (
              let idx = 0;
              idx < modelData?.icMsgs?.choiceMsgs?.length;
              idx++
            ) {
              const choice = modelData?.icMsgs?.choiceMsgs[idx];
              if (choice.choiceIndex == iNode) {
                if (modelData?.icMsgs?.choiceMsgs[idx]?.msgs?.length > 0) {
                  flag = true;
                }

                if (
                  !modelData?.icMsgs?.choiceMsgs[idx]?.msgs?.includes(
                    modelData.MsgInfo[change]
                  )
                ) {
                  modelData.icMsgs.choiceMsgs[idx].msgs = [
                    ...modelData.icMsgs.choiceMsgs[idx].msgs,
                    modelData.MsgInfo[change]
                  ];
                }
                break;
              }
            }
            if (!flag) {
              let choiceMsgs = {
                choiceIndex: iNode,
                choiceId: modelData.Chaids[iNode].chaId,
                msgs: [modelData.MsgInfo[change]]
              };
              modelData.icMsgs.choiceMsgs = [
                ...modelData.icMsgs.choiceMsgs,
                choiceMsgs
              ];
            }
          } else {
            modelData.icMsgs.choiceMsgs = [];
            let choiceMsgs = {
              choiceIndex: iNode,
              choiceId: modelData.Chaids[iNode].chaId,
              msgs: [modelData.MsgInfo[change]]
            };
            modelData.icMsgs.choiceMsgs = [
              ...modelData.icMsgs.choiceMsgs,
              choiceMsgs
            ];
          }
        }
      } else if (change < 0) {
        if (modelData.icMsgs.choiceMsgs) {
          for (
            let idx = 0;
            idx < modelData?.icMsgs?.choiceMsgs?.length;
            idx++
          ) {
            const choice = modelData?.icMsgs?.choiceMsgs[idx];
            if (choice?.choiceIndex == iNode) {
              if (choice?.msgs?.length > 1) {
                const tmpMsgData = choice?.msgs;
                modelData.icMsgs.choiceMsgs[idx].msgs = [];
                tmpMsgData?.forEach(tmpMsg => {
                  if (tmpMsg != modelData.MsgInfo[-change]) {
                    modelData.icMsgs.choiceMsgs[idx].msgs = [
                      ...modelData.icMsgs.choiceMsgs[idx]?.msgs,
                      tmpMsg
                    ];
                  }
                });
              } else {
                modelData.icMsgs.choiceMsgs = [
                  ...modelData.icMsgs.choiceMsgs.slice(0, idx),
                  ...modelData.icMsgs.choiceMsgs.slice(idx + 1)
                ];
              }
              break;
            }
          }
        }
      }
      break;
    case EdgeType_I_MSG:
      // fmt.Println("Item level messaging")
      if (change > 0) {
        if (modelData?.MsgInfo?.length > 0) {
          if (modelData?.icMsgs?.itemMsgs) {
            let flag = false;
            for (
              let idx = 0;
              idx < modelData?.icMsgs?.itemMsgs?.length;
              idx++
            ) {
              const itemMsg = modelData.icMsgs.itemMsgs[idx];
              if (itemMsg?.itemInode == iNode) {
                if (modelData?.icMsgs?.choiceMsgs[idx]?.msgs?.length > 0) {
                  flag = true;
                }

                if (
                  !modelData?.icMsgs?.itemMsgs[idx]?.msgs?.includes(
                    modelData?.MsgInfo[change]
                  )
                ) {
                  modelData.icMsgs.itemMsgs[idx].msgs = [
                    ...modelData.icMsgs.itemMsgs[idx].msgs,
                    modelData?.MsgInfo[change]
                  ];
                }
                break;
              }
            }
            if (!flag) {
              const itemMsgs = {
                itemInode: iNode,
                msgs: [modelData.MsgInfo[change]]
              };
              modelData.icMsgs.itemMsgs = [
                ...modelData.icMsgs.itemMsgs,
                itemMsgs
              ];
            }
          } else {
            modelData.icMsgs.itemMsgs = [];
            const itemMsgs = {
              itemInode: iNode,
              msgs: [modelData.MsgInfo[change]]
            };
            modelData.icMsgs.itemMsgs = [
              ...modelData.icMsgs.itemMsgs,
              itemMsgs
            ];
          }
        }
      } else if (change < 0) {
        if (modelData?.icMsgs?.itemMsgs) {
          for (let idx = 0; idx < modelData?.icMsgs?.itemMsgs?.length; idx++) {
            const itemMsg = modelData?.icMsgs?.itemMsgs[idx];
            if (itemMsg.itemInode == iNode) {
              if (itemMsg?.msgs?.length > 1) {
                const tmpMsgData = itemMsg?.msgs;
                modelData.icMsgs.itemMsgs[idx].msgs = [];
                tmpMsgData?.forEach(tempMsg => {
                  if (tempMsg != modelData.MsgInfo[-change]) {
                    modelData.icMsgs.itemMsgs[idx].msgs = [
                      ...modelData.icMsgs.itemMsgs[idx].msgs,
                      tempMsg
                    ];
                  }
                });
              } else {
                modelData.icMsgs.itemMsgs = [
                  ...modelData.icMsgs.itemMsgs.slice(0, idx),
                  ...modelData.icMsgs.itemMsgs.slice(idx + 1)
                ];
              }
            }
            break;
          }
        }
      }
    default:
      modelData.conflicts.itemConflicts =
        cse?.length > 0 || eff?.length > 0
          ? {
              cause: cse?.length > 0 ? cse : undefined,
              effect: eff?.length > 0 ? eff : undefined
            }
          : {};
  }
  isDebug && console.log("Process output END");
  return modelData;
};

// module.exports = {
//   processOutput,
//   getIChaid
// };

import { Tooltip,Typography } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

import React from "react";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    flex: 1,
    display: "inline-block"
  },
  wrapperUploadSku: {
    position: "relative",
    display: "grid"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

function CpqButton(props) {
  const classes = useStyles();
  const buttonProps = {
    id: props.id ? props.id : props.children,
    type: props.type ? props.type : "",
    size: props.size ? props.size : "medium",
    title: props.title ? props.title : "",
    color: props.color ? props.color : "primary",
    variant: props.variant ? props.variant : "contained",
    disabled: props.disabled,
    onClick: props.onClick,
    className: props.className,
    style: props.style ? props.style : {}
  };
  const isToolTipText = props?.isToolTipText ? props.isToolTipText : "";
  if (props.href) {
    buttonProps["href"] = props.href;
  }

  return (
    <div
      className={
        props.type === "uploadSku" ? classes.wrapperUploadSku : classes.wrapper
      }
    >
      {props.icon ? (
        <IconButton {...buttonProps} component="span" size="large">
          {props.icon}
        </IconButton>
      ) : (
        <>
          {isToolTipText.length != 0 ? (
            <Tooltip
              title={<Typography variant="body2">{isToolTipText}</Typography>}
            >
              <span>
                <Button {...buttonProps}>{props.children}</Button>
              </span>
            </Tooltip>
          ) : (
            <Button {...buttonProps}>{props.children}</Button>
          )}
        </>
      )}
      {props.loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}

export default CpqButton;


import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    title: {
      margin: 0,
      textAlign: "center"
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    message: {
      fontWeight: "bold",
      fontSize: "1.12rem"
    },
    paper: {
      minWidth: "45%",
      minHeight: "35%"
    },
    infoContainer: {
      display: "flex",
      border: "1px solid #0096d6",
      padding: "10px 15px",
      margin: "10px 0px",
      borderRadius: 5
    },
    infoMessage: {
      fontSize: "small",
      paddingLeft: "10px"
    }
  }));

  export default useStyles
export const HARDWARE = "HARDWARE";
export const OTHER_COMPONENTS = "OTHER COMPONENTS";
export const AMS = "AMS";
export const NA = "NA";
export const BOM = "BOM";
export const HP_LOGO = "https://cpq-portal.hpcloud.hp.com/assets/images/Hplogo.png";
export const CLONING_SKU = {
  NA: "UA",
  AMS: "UA",
  EU: "EA",
  EMEA: "EA",
  AP: "PA",
  APJ: "PA",
  LA: "LA",
  LAT: "LA"
};

export const RSKU_REGIONS = {
  EMEA: "EU",
  APJ: "AP",
  AMS: "NA",
  EU: "EU",
  AP: "AP",
  NA: "NA",
  LA: "LA",
  LAT: "LA"
};

export const REGIONS = {
  EU: "EMEA",
  EA: "EMEA",
  AP: "APJ",
  NA: "AMS",
  WW: "WW"
};

export const SKIP_USER_VALIDATION_REGIONS = ["EMEA", "EU"];

// Fix me after discussion on CTO service exclusion for RSKU
export const EXCLUDE_OTHER_COMPONENENTS = [
  "configurationService",
  "deploymentServices",
  "otherServices"
];
export const EXCLUDE_ALL_OTHER_COMPONENENTS = [
  "configurationService",
  "carePacks",
  "monitoringAccesories",
  "manageabilityService",
  "deploymentServices",
  "otherServices"
];
export const EXPORT_RSKU = "EXPORT RSKU";

// rSKU & SKU Module supports only below components for SKU Bundling

export const BUNDLED_COMPONENT = ["BOM3", "BOM3_BOM5"];

import moment from "moment";
import {
  checkStoreFront,
  displayCurrencyLite,
  getStorageValue
} from "../lib/common/util";
import { fetchUiSpecVerticalView } from "./common/utils";

export const handleData = params => {
  const {
    newConfigId,
    proCategory,
    setLoadingFn,
    configNameRed,
    configuration,
    docType,
    bomData,
    refConfigId,
    modelData,
    transactionID,
    saveConfigurationLabel,
    retainConfigID
  } = params;
  const data = {
    transactionID,
    eventTriggered:
      saveConfigurationLabel === "SAVE CONFIGURATION"
        ? "SAVE_CONFIGURATION"
        : "ADD_TO_QUOTE",
    configID: newConfigId,
    configName: configNameRed
      ? configNameRed
      : configuration?.configHeader?.configName,
    refConfigID: refConfigId,
    disableReconfigure: false,
    retainConfigID: modelData?.isUploadFlow ? true : retainConfigID,
    productCategory: proCategory,
    components: bomData.map(bom => {
      return {
        partNumber: bom.partNumber,
        description: bom.description,
        quantity: bom.qty,
        listPrice: bom.listPrice.toFixed(2).toString(),
        requestedBDNetPrice: "150.00",
        plcOverride: false,
        ...(proCategory !== "print" && { choiceId: bom?.choiceId })
      };
    })
  };
  handleEvent(data, setLoadingFn);
};

const handleEvent = (modelJson, setLoadingFn) => {
  const clientOrigin = getStorageValue("clientOrigin");
  if (!clientOrigin || clientOrigin !== "BMI") setLoadingFn(false);
  if (document.querySelector("#product-selector")) {
    let updatedComponents = modelJson?.components?.reduce((result, item) => {
      item = {
        ...item,
        listPrice: (parseFloat(item?.listPrice) / item?.quantity).toFixed(2)
      };
      return [...result, item];
    }, []);
    modelJson = { ...modelJson, components: updatedComponents };
    document.querySelector("#product-selector").dispatchEvent(
      new CustomEvent("saveConfig", {
        detail: modelJson
      })
    );
  } else if (document.querySelector("#configurator")) {
    document
      .querySelector("#configurator")
      .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
  } else if (document.querySelector("#upload-config")) {
    document
      .querySelector("#upload-config")
      .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
  }
};

export const handleConfigFilterClick = params => {
  const {
    action,
    options,
    setChecked,
    programType,
    isSkuCloning,
    skuId,
    newSavedConfigId,
    configuration,
    getBOMData,
    modelData,
    XLSX,
    saveAs,
    exportToPdf,
    setLoading,
    setModelDataForDefaultConfig,
    getConfigBom,
    getConfigObjectDataForSave,
    getLocalizationOption,
    currentCountry,
    countryCode,
    newSelectedItems,
    oldSelectedItems,
    setRetainConfigID,
    originRetainConfigID,
    configNameRed,
    docType,
    configurationName,
    accessType,
    refConfigId,
    userId,
    userEmail,
    brID,
    companyId,
    configOrigin,
    isStandaloneAccess,
    pricingGeo,
    currencyCode,
    incoterms,
    spcType,
    selectedCategory,
    selectedSeries,
    proCategory,
    saveConfiguration,
    checked,
    searchFilters,
    getConfigData,
    defaultConfigStepSlected,
    dispatch,
    SET_FILTERED_CHOICES,
    setFilters,
    SET_FILTERED_LOC,
    setOpen,
    setSaved
  } = params;
  if (action === "Select_All") {
    setChecked(options);
  }

  if (action === "Clear_All") {
    setChecked([]);
  }

  if (action === "START_OVER") {
    window.location.reload();
    localStorage.setItem("setProgramType", programType);
  }

  if (action === "EXPORT_TO_EXCEL") {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExt = "xlsx";
    const fileName =
      proCategory === "poly"
        ? `NA.${fileExt}`
        : isSkuCloning
        ? `${skuId}.${fileExt}`
        : `${
            newSavedConfigId
              ? newSavedConfigId
              : configuration?.configHeader?.docType === "QU"
              ? configuration.configHeader.configId
              : "NA"
          }.${fileExt}`;
    const { formattedBOMItems, grandPrice } = getBOMData(
      modelData,
      "exportToExcel",
      configuration
    );
    var ws = XLSX.utils.aoa_to_sheet([
      proCategory !== "poly"
        ? [
            "Config ID",
            "Config Name",
            "KMAT",
            {
              v: "Grand Price",
              s: {
                alignment: { horizontal: "right" }
              }
            }
          ]
        : [
            "KMAT",
            {
              v: "Grand Price",
              s: {
                alignment: { horizontal: "right" }
              }
            }
          ]
    ]);
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        proCategory !== "poly"
          ? [
              newSavedConfigId
                ? newSavedConfigId
                : configuration?.configHeader?.docType === "QU"
                ? configuration.configHeader.configId
                : "NA",
              configuration?.configHeader?.configName,
              configuration.configHeader.kmatId,
              {
                v: displayCurrencyLite({
                  value: grandPrice,
                  currency: currencyCode,
                  locale: "en-US"
                }),
                s: {
                  alignment: { horizontal: "right" }
                }
              }
            ]
          : [
              configuration.configHeader.kmatId,
              {
                v: displayCurrencyLite({
                  value: grandPrice,
                  currency: currencyCode,
                  locale: "en-US"
                }),
                s: {
                  alignment: { horizontal: "right" }
                }
              }
            ]
      ],
      { origin: -1 }
    );
    XLSX.utils.sheet_add_aoa(ws, [new Array(4)], { origin: -1 });
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "Description",
          "Part Number",
          "Quantity",
          {
            v: "Price",
            s: {
              alignment: { horizontal: "right" }
            }
          }
        ]
      ],
      { origin: -1 }
    );
    formattedBOMItems.forEach(item => {
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            item.description,
            item.partNumber,
            {
              v: item.qty,
              s: {
                alignment: { horizontal: "left" }
              }
            },
            {
              v:
                item.listPrice !== "NA"
                  ? displayCurrencyLite({
                      value: item.listPrice || "0.00",
                      currency: currencyCode,
                      locale: "en-US"
                    })
                  : "NA",
              s: {
                alignment: { horizontal: "right" }
              }
            }
          ]
        ],
        { origin: -1 }
      );
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuff = XLSX.write(wb, { bookType: fileExt, type: "array" });
    const dataBuff = new Blob([excelBuff], { type: fileType });
    saveAs(dataBuff, fileName);
  }

  if (action === "EXPORT_TO_PDF") {
    // const data = getConfigData(modelData);
    exportToPdf(
      newSavedConfigId,
      configuration,
      modelData,
      skuId,
      currencyCode,
      proCategory
    );
  }

  if (action === "SAVE_CONFIGURATION" || action === "ADD_TO_QUOTE") {
    setLoading(true);
    let bmiData = JSON.parse(localStorage.getItem("bmiData"));
    const mDCPOrgID = bmiData["mDCPOrgID"] || "";
    let configNameRes = localStorage.getItem("configName");
    let configDescRes = configuration?.configHeader?.configDesc;
    const updatedChoicesDetails = setModelDataForDefaultConfig(modelData);
    let { formattedBOMItems: configBOM } = getConfigBom(modelData);
    const getConfigObjectDataRes = getConfigObjectDataForSave(
      updatedChoicesDetails
    );
    const systemLocalizationDefault = getLocalizationOption(
      modelData.systemLocOptions
    );
    const osLocalizationDefault = getLocalizationOption(modelData.osLocOptions);
    const keyBoardLocalizationDefault = getLocalizationOption(
      modelData.kybdLocOptions
    );
    const monitorLocalizationDefault = getLocalizationOption(
      modelData.monitorLocOptions
    );
    let systemLocOptions, osLocOptions, kybdLocOptions, monitorLocOptions;
    systemLocOptions =
      modelData.systemLocOptions &&
      modelData.systemLocOptions.filter(item => item.selected === true);
    osLocOptions =
      modelData.osLocOptions &&
      modelData.osLocOptions.filter(item => item.selected === true);
    kybdLocOptions =
      modelData.kybdLocOptions &&
      modelData.kybdLocOptions.filter(item => item.selected === true);
    monitorLocOptions =
      modelData.monitorLocOptions &&
      modelData.monitorLocOptions.filter(item => item.selected === true);

    let countryLcLzRes = [
      {
        countryCode: currentCountry || countryCode,
        systemLocalization: systemLocOptions && systemLocOptions[0]?.locOption,
        osLocalization: osLocOptions && osLocOptions[0]?.locOption,
        keyBoardLocalization: kybdLocOptions && kybdLocOptions[0]?.locOption,
        monLocalization: monitorLocOptions && monitorLocOptions[0]?.locOption,
        delFl: "N"
      }
    ];
    const changeSelectedItem =
      JSON.stringify(newSelectedItems) !== JSON.stringify(oldSelectedItems);
    const getConfigId = () => {
      const originConfigId = configuration?.configHeader?.configId;
      let configId = null;
      if (action === "ADD_TO_QUOTE") {
        if (docType === "QU") {
          if (changeSelectedItem) {
            setRetainConfigID(true);
          } else {
            setRetainConfigID(false);
            configId = originConfigId || null;
          }
        } else if (docType === "SP" || docType === "CC") {
          setRetainConfigID(true);
        }
      } else if (action === "SAVE_CONFIGURATION") {
        if (originRetainConfigID) {
          configId = originConfigId || null;
        } else {
          if (docType === "QU") {
            if (changeSelectedItem) {
              setRetainConfigID(true);
            } else {
              configId = newSavedConfigId || originConfigId || null;
            }
          }
        }
      }
      return configId;
    };

    // Extract Base Unit to pass inside configHeader if changed
    let selectedBaseUnit = undefined;
    if (configBOM && configBOM.length) {
      selectedBaseUnit = configBOM.find(config => config.choiceId === "BUNIT");
    }

    let inputData = {
      configName: configNameRed
        ? configNameRed
        : configNameRes || configurationName,
      configId: getConfigId(),
      configDesc: configDescRes,
      configHeader: {
        addtnlRegs: "",
        configType: configuration?.configHeader?.configType || "custom",
        leadCountryCode: currentCountry || countryCode || "US",
        configHighLights: configuration?.configHeader?.configHighlights || "",
        configImage:
          encodeURIComponent(configuration?.configHeader?.configImage) || "",
        kmatId: configuration?.configHeader?.kmatId || modelData.kmatId || "",
        baseUnitAv:
          selectedBaseUnit?.materialNumber ||
          configuration?.configHeader?.baseUnitAv ||
          "",
        programName: configuration?.configHeader?.programName || "",
        accessType,
        refConfigId,
        userId: userId,
        userEmail: userEmail,
        customerCompanyName:
          brID ||
          (companyId ? companyId : configuration?.configHeader?.companyName) ||
          "",
        mdcpOrgId: mDCPOrgID
          ? mDCPOrgID
          : configuration?.configHeader?.mdcpOrgId || "",
        originatingAsset: configOrigin ? configOrigin : "OCIQ",
        bandingFlag: "N",
        bandingType: "OC-FIX",
        docType: "QU",
        regionCode:
          configuration?.configHeader?.rgnCd || modelData.region || "NA",
        lead_locale: configuration?.configHeader?.leadLocale || "",
        autoLocalized: configuration?.configHeader?.autoLocalized || "",
        kbVersion: configuration?.configHeader?.kbVersion || "",
        marketProgramName: configuration?.configHeader?.mktProgramName || "",
        marketProgramType: configuration?.configHeader?.mktProgramType || "",
        shipmentDate: configuration?.configHeader?.shipmentDate
          ? moment(configuration?.configHeader?.shipmentDate).format(
              "YYYY-MM-DD"
            )
          : "",
        exportStatusId:
          configuration?.configHeader?.exportStatusId ||
          (modelData.isUploadFlow ? "new" : ""),
        bandingEol: configuration?.configHeader?.bandingEol
          ? moment(configuration?.configHeader?.bandingEol).format("YYYY-MM-DD")
          : null,
        configEol: configuration?.configHeader?.configEol
          ? moment(configuration?.configHeader?.configEol).format("YYYY-MM-DD")
          : null,
        businessModel: configuration?.configHeader?.businessModel
          ? configuration?.configHeader?.businessModel.toLowerCase()
          : "indirect",
        copiedFrom: configuration?.configHeader?.copiedFrom || "",
        localizationType: configuration?.configHeader?.lclznType || "",
        priceGeo:
          !isStandaloneAccess && pricingGeo
            ? pricingGeo
            : configuration?.configHeader?.priceGeo || modelData.priceGeo || "",
        currencyCd:
          !isStandaloneAccess && currencyCode
            ? currencyCode
            : configuration?.configHeader?.currCd
            ? configuration?.configHeader?.currCd.toUpperCase()
            : modelData.currency,
        incoterm:
          !isStandaloneAccess && incoterms
            ? incoterms
            : configuration?.configHeader?.incoterm || modelData.incoterm || "",
        priceListType:
          configuration?.configHeader?.priceListType ||
          modelData.priceListType ||
          "",
        globalFl: configuration?.configHeader?.globalFl || "N",
        activeFl: configuration?.configHeader?.activeFl || "Y",
        delFl: configuration?.configHeader?.delFl || "N",
        favoriteFl: "N",
        productLine: modelData?.modelRequirement?.productGroup || "NA", // when UI does not have valid value , UI should send NA so that API would handle the valid value
        storeFront: "IQ",
        startDate: configuration?.configHeader?.startDate
          ? moment(configuration?.configHeader?.startDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD") || null,
        endDate: configuration?.configHeader?.endDate
          ? moment(configuration?.configHeader?.endDate).format("YYYY-MM-DD")
          : moment().add(1, "year").format("YYYY-MM-DD") || null,
        geoMrkt: "",
        spcType
      },
      countryLcLz: countryLcLzRes,
      configBom: configBOM && configBOM.length ? configBOM : [],
      configObject: {
        configDefault: {
          configuration: {
            localization: [
              {
                choiceId: "systemLocalization",
                values: [...systemLocalizationDefault]
              },
              {
                choiceId: "osLocalization",
                values: [...osLocalizationDefault]
              },
              {
                choiceId: "keyBoardLocalization",
                values: [...keyBoardLocalizationDefault]
              },
              {
                choiceId: "monitorLocalization",
                values: [...monitorLocalizationDefault]
              }
            ],
            choices: [...getConfigObjectDataRes]
          }
        },
        configBanding: { banding: {} }
      },
      hierarchy: {
        categoryName: selectedCategory || "N/A", // when UI does not have valid value , UI should send N/A so that API would handle the valid value
        seriesName: selectedSeries || "N/A",
        productType: proCategory || "compute"
      }
    };
    saveConfiguration({
      variables: {
        input: inputData
      }
    });
  }

  if (action === "SAVE_FILTER") {
    //TODO: Save if need
    let result = [];
    result = options.filter(cha => {
      return checked.includes(cha);
    });
    const inclusiveList = [...searchFilters, ...result];
    const data = getConfigData(modelData, defaultConfigStepSlected);
    if (inclusiveList.length) {
      dispatch({
        type: SET_FILTERED_CHOICES,
        payload: data.filter(cha => inclusiveList.includes(cha.chaDes))
      });
    } else {
      dispatch({ type: SET_FILTERED_CHOICES, payload: data });
    }
    const defaultData = getConfigData(modelData, defaultConfigStepSlected);
    if (result.length) {
      dispatch({
        type: SET_FILTERED_CHOICES,
        payload: defaultData.filter(cha => result.includes(cha.chaDes))
      });
    } else {
      dispatch({ type: SET_FILTERED_CHOICES, payload: defaultData });
    }
    setFilters(result);
    dispatch({ type: SET_FILTERED_LOC, payload: inclusiveList });
    setOpen(false);
    setChecked(result);
    setSaved(true);
  }
};

export const handleConfigFilterCheckbox = params => {
  const {
    value,
    sel,
    checked,
    setChecked,
    options,
    getConfigData,
    modelData,
    defaultConfigStepSlected,
    dispatch,
    SET_FILTERED_CHOICES,
    setFilters,
    searchFilters,
    SET_FILTERED_LOC
  } = params;
  const currentIndex = checked.indexOf(value);
  const newChecked = [...checked];
  if (currentIndex === -1) {
    newChecked.push(value);
  } else {
    newChecked.splice(currentIndex, 1);
  }
  setChecked(newChecked);
  if (sel === 2) {
    // set new data
    let result = [];
    result = options.filter(cha => {
      return newChecked.includes(cha);
    });
    const data = getConfigData(modelData, defaultConfigStepSlected);
    if (result.length) {
      dispatch({
        type: SET_FILTERED_CHOICES,
        payload: data.filter(cha => result.includes(cha.chaDes))
      });
    } else {
      dispatch({ type: SET_FILTERED_CHOICES, payload: data });
    }
    setFilters(result);
    const inclusiveList = [...searchFilters, ...result];
    dispatch({ type: SET_FILTERED_LOC, payload: inclusiveList });
  }
};

export const filterSearchTextChoices = params => {
  const {
    searchText,
    otherHardCodeChaDes,
    dispatch,
    SET_SHOW_TOP_RECOMMEND,
    SET_SHOW_ADDITIONAL,
    otherServicesChaDes,
    SET_SHOW_OTHER_SERVICES,
    data,
    filters,
    SET_FILTERED_CHOICES,
    choices,
    defaultConfigStepSlected,
    userType,
    setSearchFilters,
    SET_FILTERED_LOC,
    filteredLoc,
    showFilterParts,
    configOrigin,
    originatingAsset,
    modelData
  } = params;
  if (searchText) {
    const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected);
    // Choices
    const none = "none";
    const isHP2BOrECOMMConfig = checkStoreFront(modelData);
    // code for vertical steppper search
    if (isVerticalView === "Y") {
      // TODO: check for title description matching
      const indexOfDefaultConfigstepSelected =
        window.uiSpecData?.UISpec[0]?.sections?.findIndex(
          tab => tab.id === defaultConfigStepSlected
        );
      const filteringSearchedChaid = window.uiSpecData?.UISpec[0]?.sections[
        indexOfDefaultConfigstepSelected
      ].subsections
        ?.filter(choice =>
          choice.title.toLowerCase().includes(searchText.toLowerCase())
        )
        .map(choice => choice.choices.map(choiceId => choiceId.id));
      const filteringSearchedChaidAfterFlat = filteringSearchedChaid.flat(); // converting nested array to single array.
      const matches = data.filter(choice => {
        const topRecommendCarepack =
          choice.chaId === "ELECTCPQ" ||
          (choice.chaId.startsWith("CAPQ_") &&
            !choice.chaId.startsWith("CAPQ_P_") &&
            choice.priority <= 5);
        const additionalCarepack =
          choice.chaId === "ELECTCPQ" ||
          (choice.chaId.startsWith("CAPQ_") &&
            !choice.chaId.startsWith("CAPQ_P_") &&
            choice.priority > 5);
        const topRecommendPoly =
          choice.chaId.startsWith("CAPQ_P_") && choice.priority <= 5;
        const additionalPoly =
          choice.chaId.startsWith("CAPQ_P_") && choice.priority > 5;
        const otherHardCodeChaDes = [
          topRecommendCarepack
            ? "Top recommended care packs"
            : topRecommendPoly
            ? "Top Recommended Poly Services - "
            : "",
          additionalCarepack
            ? "Additional care packs"
            : additionalPoly
            ? "Additional Poly Services - "
            : ""
        ];
        const checkHardCodeDes = () => {
          const res = otherHardCodeChaDes.find((entry, index) => {
            if (entry.toLowerCase().includes(searchText.toLowerCase())) {
              return entry;
            } else return undefined;
          });
          return res !== undefined;
        };

        const monLocChaids = modelData.Chaids.filter(
          chaid =>
            chaid.chaId === "ASCM_POLYACC" ||
            chaid.chaId === "ASCM_64283" ||
            chaid.chaId === "ASCM_3242660" ||
            chaid.chaId === "ASCM_POLYMON"
        )
          .map(chaid => chaid.items)
          .flat(); // o/p [[arr 1],[arr2],[arr3]], using flat -[arr1,arr2,arr3]

        const searchedMandaHeaderPartno = monLocChaids
          .filter(
            item =>
              item?.visible === true &&
              item?.partno?.toLowerCase()?.includes(searchText.toLowerCase())
          )
          .map(item => item?.partno?.toLowerCase()?.split("#"))
          .flat();
        const filteringPartnoCarepack = choice.chaId
          .toLowerCase()
          .split("capq_")[1];
        const filteringPartnoPoly = choice.chaId
          .toLowerCase()
          .split("capq_p_")[1];

        // checking for choice  description match and choice title
        if (
          ((choice.chaId === "ELECTCPQ" ||
            (choice.chaId.startsWith("CAPQ_") &&
              !choice.chaId.startsWith("CAPQ_P_"))) &&
            (isHP2BOrECOMMConfig
              ? choice.chaDes.toLowerCase().includes(searchText.toLowerCase())
              : checkHardCodeDes())) ||
          (choice.chaId !== "ELECTCPQ" &&
            !choice.chaId.startsWith("CAPQ_") &&
            choice.chaDes.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return choice;
        } else if (
          (choice.chaId.startsWith("CAPQ_P_") && checkHardCodeDes()) ||
          (choice.chaId.startsWith("CAPQ_P_") &&
            searchedMandaHeaderPartno.includes(filteringPartnoPoly)) ||
          (choice.chaId.startsWith("CAPQ_") &&
            searchedMandaHeaderPartno.includes(filteringPartnoCarepack))
        ) {
          return choice;
        } else if (choice.items) {
          // checking for items description match
          const itemMatches = choice.items.find(item => {
            return (
              item.partno.toLowerCase().includes(searchText.toLowerCase()) ||
              item.partdes.toLowerCase().includes(searchText.toLowerCase())
            );
          });
          if (itemMatches !== undefined) return choice;
        }
        if (filteringSearchedChaidAfterFlat?.includes(choice.chaId)) {
          return choice;
        }
      });
      dispatch({
        type: SET_FILTERED_CHOICES,
        payload: matches.length ? matches : []
      });
    } else {
      let showCarePark = false;
      let shouldFilteredSoftware = false;
      otherHardCodeChaDes.forEach((item, index) => {
        if (item.toLowerCase().includes(searchText.toLowerCase())) {
          if (index === 0 && window.hasAnyRecommendedPacks) {
            dispatch({
              type: SET_SHOW_TOP_RECOMMEND,
              payload: true
            });
          } else if (index === 1) {
            dispatch({
              type: SET_SHOW_ADDITIONAL,
              payload: true
            });
          } else if (index === 2) {
            shouldFilteredSoftware = true;
          }
          showCarePark = true;
        }
      });
      otherServicesChaDes.forEach(item => {
        const payload = { [item]: false };
        if (item.toLowerCase().includes(searchText.toLowerCase())) {
          payload[item] = true;
        }
        dispatch({
          type: SET_SHOW_OTHER_SERVICES,
          payload
        });
      });
      const result = data.filter(choice => {
        const filteredItems = choice.items.filter(
          chItems =>
            chItems.partno.toLowerCase().includes(searchText.toLowerCase()) ||
            chItems.partdes.toLowerCase().includes(searchText.toLowerCase())
        );
        const filteredconflictItems =
          choice.conflictItems &&
          choice.conflictItems.filter(
            chItems =>
              chItems.partno.toLowerCase().includes(searchText.toLowerCase()) ||
              chItems.partdes.toLowerCase().includes(searchText.toLowerCase())
          );
        if (
          choice.chaDes.toLowerCase().includes(searchText.toLowerCase()) ||
          (none.includes(searchText.toLowerCase()) &&
            choice.visible &&
            !choice.multiple)
        ) {
          choice.items = filteredItems;
          choice.conflictItems = filteredconflictItems;
          return choice.chaDes;
        } else {
          const carePackServicesFor = "Care Pack Services for";
          const polyPackServicesFor = "Poly Services for";
          if (choice.chaId.includes("ELECTCPQ") && showCarePark) {
            //For top Recommended and additional
            return (
              (choice.items && choice.items.length) ||
              (choice.conflictItems && choice.conflictItems.length)
            );
          } else if (
            !choice.chaId.includes("ELECTCPQ") &&
            !choice.chaId.startsWith("CAPQ_")
          ) {
            //For software solution
            if (!shouldFilteredSoftware) {
              choice.items = filteredItems;
              choice.conflictItems = filteredconflictItems;
            }
            return (
              (choice.items && choice.items.length) ||
              (choice.conflictItems && choice.conflictItems.length)
            );
          } else if (
            choice.chaId.startsWith("CAPQ_P_") &&
            polyPackServicesFor.toLowerCase().includes(searchText.toLowerCase())
          ) {
            //For "Poly Services for:"
            return (
              (choice.items && choice.items.length) ||
              (choice.conflictItems && choice.conflictItems.length)
            );
          } else if (
            choice.chaId.startsWith("CAPQ_") &&
            !choice.chaId.startsWith("CAPQ_P_") &&
            carePackServicesFor.toLowerCase().includes(searchText.toLowerCase())
          ) {
            //For "Care pack Services for:"
            return (
              (choice.items && choice.items.length) ||
              (choice.conflictItems && choice.conflictItems.length)
            );
          } else {
            choice.items = filteredItems;
            choice.conflictItems = filteredconflictItems;
            return (
              (choice.items && choice.items.length) ||
              (choice.conflictItems && choice.conflictItems.length)
            );
          }
        }
      });
      let filterData = data.filter(cha => filters.includes(cha.chaDes));
      let newFilteredChoices = [...new Set([...result, ...filterData])];
      dispatch({
        type: SET_FILTERED_CHOICES,
        payload: filterData.length ? newFilteredChoices : result
      });
      // Localizations
      const choicesList = choices
        .map(choice =>
          choice.items?.some(
            chItems =>
              (chItems.visible &&
                chItems.partno
                  .toLowerCase()
                  .includes(searchText.toLowerCase())) ||
              (chItems.visible &&
                chItems.partdes
                  .toLowerCase()
                  .includes(searchText.toLowerCase())) ||
              (choice.type === "loc" &&
                //originating asset is not received from scenario 1 (in the case of create config). so added both parameters(configOrigin and originatingAsset) to cover liteConfig,  create Config,OCIQ.
                (configOrigin !== "OCA" || originatingAsset === "OCIQ"
                  ? chItems.lclzBanding
                  : chItems.lclzDefault) &&
                chItems.locDescription
                  .toLowerCase()
                  .includes(searchText.toLowerCase()))
          ) ||
          (choice.conflictItems &&
            choice.conflictItems.length > 0 &&
            choice.conflictItems.filter(
              chItems =>
                chItems.partno
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                chItems.partdes.toLowerCase().includes(searchText.toLowerCase())
            )) ||
          (none.includes(searchText.toLowerCase()) &&
            choice.visible &&
            !choice.multiple &&
            !choice.type) ||
          choice.chaDes.toLowerCase().includes(searchText.toLowerCase())
            ? choice.chaDes
            : undefined
        )
        .filter(d => d !== undefined);

      //judge Configuration Services Customer
      const hasPartnerCustomer =
        "customer".includes(searchText.toLowerCase()) &&
        defaultConfigStepSlected === "configurationService" &&
        userType?.toUpperCase() !== "PARTNER";
      const inclusiveList = !hasPartnerCustomer
        ? [...filters, ...choicesList]
        : [...filters, ...choicesList, "Customer"];
      setSearchFilters(choicesList);
      dispatch({
        type: SET_FILTERED_LOC,
        payload: inclusiveList.length ? inclusiveList : ["dummy"]
      });
    }
  } else {
    const choicesList = choices.map(choice => choice.chaDes);
    setSearchFilters([]);
    if (!filters.length) {
      if (
        defaultConfigStepSlected === "configurationService" &&
        userType?.toUpperCase() !== "PARTNER" &&
        filteredLoc.indexOf("Customer") !== -1
      ) {
        choicesList.push("Customer");
      }
      dispatch({ type: SET_FILTERED_CHOICES, payload: data });
      dispatch({
        type: SET_FILTERED_LOC,
        payload: filters.length
          ? filters
          : choicesList.length
          ? choicesList
          : ["dummy"]
      });
    } else {
      let filterData = data.filter(cha => filters.includes(cha.chaDes));
      if (showFilterParts) {
        // // For Hardware
        dispatch({ type: SET_FILTERED_CHOICES, payload: filterData });
        dispatch({
          type: SET_FILTERED_LOC,
          payload: filters.length
            ? filters
            : choicesList.length
            ? choicesList
            : ["dummy"]
        });
      } else {
        dispatch({ type: SET_FILTERED_CHOICES, payload: data });
      }
    }
  }
};

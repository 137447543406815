import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getConfigData } from "../../services/common/utils";

const useStyles = makeStyles(theme => ({
  container: {
    margin: "0 5% 5% 5%"
  },
  midPositioner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  },
  spinner: {
    color: "rgb(41, 168, 221)"
  },
  warningColor: {
    color: "#ffbf00"
  },
  typo: {
    color: "#29A8DD"
  },
  yopacity: {
    "&.MuiFab-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      height: "46px",
      width: "48px",
      background: "#0096D6 !important",
      position: "fixed",
      fontSize: "3rem",
      zIndex: "9999",
      cursor: "pointer",
      right: "24px"
    },
    "&:hover": {
      color: "green"
    }
  },
  bottomReconfigure: {
    bottom: "20rem"
  },
  bottom: {
    bottom: "16rem"
  }
}));

function ObseleteWarningMessage({ proCategory }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const showBOMSectionFlag = useSelector(
    state => state.configFilterData.showBOMSectionFlag
  );
  const [configStepId, setConfigStepId] = useState("");
  const steppers = useSelector(state => state.configFilterData.steppers);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const modelData = useSelector(state => state.configFilterData.modelData);

  useEffect(() => {
    let stepperList = "";
    for (let i = 0; i < steppers?.length; i++) {
      const stepper = steppers[i];
      if (defaultConfigStepSlected === stepper.id && stepper.isWarning) {
        stepperList = stepper.id;
        break;
      }
    }
    setConfigStepId(stepperList);
  }, [defaultConfigStepSlected, steppers, modelData]);

  const selectCarePackText = () => {
    if (defaultConfigStepSlected === "hardware") {
      const data = getConfigData(modelData, "carePacks");
      let isSelected = false;
      data.forEach(chaid => {
        if (!isSelected) {
          for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
            isSelected =
              isSelected ||
              (modelData.Items[i].selected === true ? true : false);
            if (isSelected) break;
          }
        }
      });
      return !isSelected ? (
        <Typography
          className={classes.typo}
          component={"span"}
          variant={"body2"}
        >
          {t("common:table.selectCarePack")}
        </Typography>
      ) : (
        <></>
      );
    }
    return <></>;
  };

  return (
    <div>
      {selectCarePackText()}
      <br />
      {configStepId &&
        defaultConfigStepSlected === configStepId &&
        showBOMSectionFlag && (
          <Box display="flex" justifyContent="center">
            <Typography>There are items that will be</Typography>&nbsp;
            <Typography className={classes.warningColor}>obsolete</Typography>
            &nbsp;
            <Typography>within current configuration in 90 days</Typography>
          </Box>
        )}
    </div>
  );
}

export default ObseleteWarningMessage;

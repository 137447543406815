import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { Fragment,useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { togglePopupActions } from "../../../actions/togglePopupActions";

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
    textAlign: "center"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  text: {
    color: "#000000",
    fontSize: 20
  },
  dailogContent: {
    marginBottom: 60
  },
  alertText: {
    fontSize: 18
  }
}));
const ConfirmationDialog = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rskuInfoDialogAlert } = useSelector(state => state.rskuReducer);

  const handleConfirmationDialogClose = useCallback(() => {
    dispatch(togglePopupActions({ name: "confirmationDialog", open: false }));
  }, []);

  const handleRskuInfoDialogOpen = useCallback((params = {}) => {
    dispatch(
      togglePopupActions({
        name: "rskuInfoDialog",
        open: !rskuInfoDialogAlert
      })
    );
    dispatch(
      togglePopupActions({
        name: "requestSkuDialog",
        open: false
      })
    );
    handleConfirmationDialogClose();
  }, []);
  return (
    <>
      <Dialog open={true} onClose={handleConfirmationDialogClose} fullWidth>
        <DialogTitle  className={classes.title}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            {t("common:rsku.dialog.confirmationTitle")}
          </Typography>
          <IconButton
            onClick={handleConfirmationDialogClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dailogContent}>
          {rskuInfoDialogAlert ? (
            <Typography className={classes.alertText}>
              {t("common:rsku.dialog.alertConfirmationText")}
            </Typography>
          ) : (
            <Fragment>
              <DialogContentText className={classes.text}>
                {t("common:rsku.dialog.confirmationSubTitle")}
              </DialogContentText>
              <Typography>
                {t("common:rsku.dialog.confirmationText")}
              </Typography>
            </Fragment>
          )}

          <DialogActions>
            <Button onClick={handleConfirmationDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleRskuInfoDialogOpen()}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
import { useLazyQuery } from "@apollo/client";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { orange } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
// import ConfigContainerHook from "../../../containers/ConfigContainerHook";
import { GET_PROD_CAT_SERIES } from "../../../GraphQL";
import { sortAlphabeticallyByName } from "../../../lib/common/util";
import CategoryCard from "./CategoryCard";
import CategorySeriesCard from "./CategorySeriesCard";
import {
  SET_PRODUCT_CATEGORIES,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_CATEGORY_FROM_API,
  SET_SELECTED_SERIES,
  SET_SHOWSERIES
} from "./Constants";
import DefaultConfigs from "./DefaultConfigs";
const useStyles = makeStyles(theme => ({
  selectProductSeriesText: {
    fontSize: "18px",
    margin: "-4px 0px 10px 0px"
  },
  cardMargin: {
    marginRight: "162px"
  },
  card: {
    display: "grid"
  },
  seriesMargin: {
    marginRight: "160px"
  },
  iconWrapper: {
    color: orange[500]
  }
}));

// function StyledRadio(props) {
//   return <Radio color="primary" size="small" {...props} />;
// }
const CategorySeries = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { showDefaultConfig, setShowDefaultConfig, noShowModel } = props;
  const series = useSelector(state => state.productSelector.series);
  const selectedSeries = useSelector(
    state => state.productSelector.selectedSeries
  );
  const selectedCategory = useSelector(
    state => state.productSelector.selectedCategory
  );
  const categories = useSelector(state => state.productSelector.categories);
  const showSeries = useSelector(state => state.productSelector.showSeries);
  const baseUnitValue = useSelector(
    state => state.productSelector.baseUnitValue
  );
  const country = useSelector(state => state.productSelector.country);
  const [changeShowSeries, setShowSeries] = useState(false);
  const [showCategoryCard, setShowCategoryCard] = useState(false);
  const [showCategorySeriesCard, setShowCategorySeriesCard] = useState(false);
  const dispatch = useDispatch();
  const { proCategory } = props;
  const [token] = useState(() => localStorage.getItem("authToken"));
  const { setBaseUnitValue } = props;
  const [previousInput, setPreviousInput] = useState({});

  useEffect(() => {
    dispatch({
      type: SET_SELECTED_CATEGORY_FROM_API,
      payload: {
        category: selectedCategory ? selectedCategory.name : "",
        series: selectedSeries ? selectedSeries.name : ""
      }
    });
    localStorage.setItem(
      "selectedCategory",
      selectedCategory ? selectedCategory.name : ""
    );
    localStorage.setItem(
      "selectedSeries",
      selectedSeries ? selectedSeries.name : ""
    );
  }, [selectedCategory, selectedSeries]);

  const getDefaultConfig = data => {
    if (data === 0) {
      setShowCategoryCard(false);
      setShowCategorySeriesCard(false);
    } else {
      setShowCategoryCard(false);
      setShowCategorySeriesCard(false);
    }
  };

  const [getProductCategoriesSeries] = useLazyQuery(GET_PROD_CAT_SERIES, {
    fetchPolicy: "no-cache",
    onCompleted({ searchProductCategories }) {
      const sortedCategories =
        Object.keys(searchProductCategories).length > 0 &&
        searchProductCategories.productCategories
          ? searchProductCategories.productCategories
              .sort(sortAlphabeticallyByName)
              .filter(category =>
                proCategory === "poly" ? category : category.imageUrl
              )
          : [];
      setShowSeries(
        sortedCategories.some(item => item.series && item.series.length > 0)
      );
      handleStoreSeries(sortedCategories);
      dispatch({
        type: SET_PRODUCT_CATEGORIES,
        payload: { sortedCategories, showSeries: false }
      });
      setLoading(false);
      setShowCategoryCard(false);
      setShowCategorySeriesCard(false);
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
      setShowCategoryCard(false);
      setShowCategorySeriesCard(false);
    }
  });

  useEffect(() => {
    if (country) {
      batch(() => {
        dispatch({
          type: "RESET_SEARCH_CONFIG"
        });
        dispatch({
          type: "SET_RESET_SELECTED"
        });
      });
      getProductCategoriesSeries({
        variables: {
          countryCode: country || "",
          proCategory: proCategory || ""
        }
      });
    }
    setShowDefaultConfig(false);
  }, [
    country,
    token,
    dispatch,
    getProductCategoriesSeries,
    setShowDefaultConfig,
    proCategory
  ]);

  const handleStoreSeries = category => {
    const obj = {};
    category.forEach(product => {
      obj[product.name] = [];
      const sortedProductSeries = product.series
        ? product.series.sort(sortAlphabeticallyByName)
        : [];
      if (product.series && product.series.length > 0) {
        sortedProductSeries.forEach(series => {
          obj[product.name].push(series);
        });
      }
    });
    dispatch({
      type: "SET_SERIESINFO",
      payload: obj
    });
  };

  const handleSelect = async (type, input) => {
    if (showCategoryCard || showCategorySeriesCard) return; // a card is loading
    let isChanged = false; // if category/series changed
    if (type === "category") {
      if (selectedCategory?.name !== input.name) {
        isChanged = true;
        dispatch({
          type: "RESET_SEARCH_CONFIG"
        });
        dispatch({
          type: "RESET_DEFAULT_CONFIGURATIONS"
        });
        setShowCategoryCard(true);
        setShowCategorySeriesCard(false);
        dispatch({
          type: SET_SHOWSERIES,
          payload: { showSeries: changeShowSeries }
        });
      }
      const sortedSeries = input.series
        ? input.series.sort(sortAlphabeticallyByName)
        : [];
      setShowDefaultConfig(sortedSeries ? !sortedSeries.length : false);
      input = {
        category: input,
        series: sortedSeries
      };

      if (input.series && input.series.length) {
        setShowCategoryCard(false);
      }
    } else {
      if (selectedSeries?.name !== input.name) {
        isChanged = true;
        dispatch({
          type: "RESET_SEARCH_CONFIG"
        });
        dispatch({
          type: "RESET_DEFAULT_CONFIGURATIONS"
        });
        setShowCategoryCard(false);
        setShowCategorySeriesCard(true);
      }
      setShowDefaultConfig(input && Object.keys(input).length);
    }
    if (baseUnitValue !== "") {
      dispatch({
        type: "SET_BASEUNIT",
        payload: {
          baseunit: "",
          category: type === "series" ? selectedCategory : null
        }
      });
    }
    setBaseUnitValue("");
    if (type !== "category") {
      if (previousInput === input) {
        setShowCategoryCard(false);
        setShowCategorySeriesCard(false);
      }
      setPreviousInput(input);
    }
    if (isChanged) {
      await dispatch({
        type: type === "category" ? SET_SELECTED_CATEGORY : SET_SELECTED_SERIES,
        payload: JSON.parse(JSON.stringify(input))
      });
    }
  };

  return (
    <div>
      {!loading ? (
        <React.Fragment>
          <div className={classes.cardMargin}>
            <Box my={3}>
              <Grid container spacing={2}>
                {categories && categories.length ? (
                  categories.map((category, index) => (
                    <Grid
                      key={index}
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      lg={2}
                      className={classes.card}
                      sx={{
                        "&.MuiGrid-root": {
                          padding: "8px"
                        }
                      }}
                    >
                      <CategoryCard
                        active={
                          selectedCategory &&
                          selectedCategory.name === category.name
                        }
                        category={category}
                        handleClick={category =>
                          handleSelect("category", category)
                        }
                        showCategoryCard={showCategoryCard}
                      />
                    </Grid>
                  ))
                ) : (
                  <Typography
                    variant="h6"
                    align="left"
                    className={classes.selectProductSeriesText}
                  >
                    No catalogs available for selected country
                  </Typography>
                )}
              </Grid>
            </Box>
          </div>

          <div className={classes.seriesMargin}>
            {showSeries && (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid xs={12} item>
                    <Typography
                      variant="h6"
                      align="left"
                      className={classes.selectProductSeriesText}
                    >
                      {t(
                        "common:productSelector.textMessage.selectProductSeries"
                      )}
                    </Typography>
                  </Grid>
                  {series &&
                    series.length > 0 &&
                    series.map((product, index) => (
                      <Grid
                        key={product.name + index}
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={3}
                        className={classes.card}
                      >
                        <CategorySeriesCard
                          series={product}
                          active={
                            selectedSeries &&
                            selectedSeries.name === product.name
                          }
                          handleClick={series => handleSelect("series", series)}
                          showCategorySeriesCard={showCategorySeriesCard}
                        />
                      </Grid>
                    ))}
                </Grid>
              </React.Fragment>
            )}

            {showDefaultConfig && (
              <DefaultConfigs
                getDefaultConfig={getDefaultConfig}
                proCategory={proCategory}
              />
            )}
            {noShowModel && (
              <Box display="flex">
                <WarningIcon
                  fontSize="medium"
                  className={classes.iconWrapper}
                />
                &nbsp;
                <Typography variant="h6" color="textPrimary">
                  {t("common:productSelector.textMessage.noModelsAvailable")}
                </Typography>
              </Box>
            )}
            {/* {         
              <DefaultConfigs getDefaultConfig={getDefaultConfig} />            
          } */}
          </div>
        </React.Fragment>
      ) : (
        <div align="center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default CategorySeries;

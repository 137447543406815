/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ManageMyConfigs from "../components/ManageMyConfig/ManageMyConfigs";
import ProductSelector from "../models/configuration/productSelector/ProductSelector";

export default function ProductSelectorContainer() {
  const [isProductSelector, setIsProductSelector] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const loadManageMyConfig = useSelector(
    state => state.productSelector.loadManageMyConfig
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (document.getElementById("oneconfig-manage-myconfig") === null) {
      setIsProductSelector(true);
      setIsFirstLoad(true);
      resetToProdSelector();
    }
    return () => {
      resetToProdSelector();
    };
  }, []);

  useEffect(() => {
    if (!isFirstLoad && loadManageMyConfig) {
      return;
    }
    setIsProductSelector(!loadManageMyConfig);
  }, [loadManageMyConfig]);

  const resetToProdSelector = () => {
    dispatch({
      type: "NAV_MANAGE_MY_CONFIG",
      payload: { loadManageMyConfig: false }
    });
  };

  useEffect(() => {
    return () => {
      localStorage.getItem("isDebug") === "true" &&
        console.log("clear window attributes");
      delete window.authToken;
      delete window.uiSpecData;
      delete window.uiSpecFileName;
      delete window._modelJson;
    };
  }, []);
  return (
    <div>
      <React.Fragment>
        {isProductSelector ? <ProductSelector /> : <ManageMyConfigs />}
      </React.Fragment>
    </div>
  );
}

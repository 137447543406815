import moment from "moment";
import { doesElementExistString } from "./AutoAttach/util.js";
import {
  CAREPACK_ELECT_CPQ,
  CODE_GA_DATE_CONFLICT,
  CODE_PLC_CONFLICT,
  ConfigurationServicesChaidsPrefix,
  DO_DESELECT,
  DO_SELECT,
  DefaultQty,
  DummyChoiceIDs,
  EdgeType_GTE_0,
  EdgeType_I_AVL,
  HIDDEN_CHOICES,
  INVALIDPLCSTATUS,
  ItemDisabledForPartner,
  MONITOR_CAREPACKS_PREFIX,
  OUTPUT_FINISHING_CHOICE_ID,
  PAPER_TRAY_CHOICE_ID,
  PartialCLICEnabled,
  PrintPowerCordsChaid,
  SelectedQty,
  TEXT_GA_DATE_CONFLICT,
  TEXT_PLC_CONFLICT,
  TEXT_PRICE_CONFLICT,
  VALID,
  logisticsServicesAAChaidID,
  logisticsServicesChaidID,
  sysLocChaidsCOMPUTE_locChaidsPRINT
} from "./Constants.js";
import { updateModel } from "./Localization/util.js";
import { getIChaid } from "./NeuralNet/processOutput.js";
import { loadOneModel } from "./main.js";
import { checkStoreFront } from "../lib/common/util.js";
const isDebug = window.localStorage.getItem("isDebug") === "true";

//src/webasm/validate.go
export const isPlcStatusInvalid = (model, i) => {
  return model.Items[i].plcStatus === INVALIDPLCSTATUS;
};

export const isListPriceInvalid = (modelData, i) => {
  return modelData.Items[i].priceStatus != VALID;
};

//src/webasm/validate.go
export const getPlcConflicts = model => {
  let plcInValidSelectedItems = [];
  let priceInvalidSelectedItems = [];
  let chSetVisible = false;
  for (let index = 0; index < model?.Chaids?.length; index++) {
    const chaid = model.Chaids[index];
    if (
      !(
        chaid.iNode == 0 ||
        DummyChoiceIDs.includes(chaid?.chaId)
      )
    ) {
      for (var i = chaid.firstItem; i <= chaid.lastItem; i++) {
        if (isPlcStatusInvalid(model, i)) {
          if (isInvalidItemsVisible(model, i)) {
            model.Items[i].visible = false;
          }
          if (model?.Items[i].selected) {
            plcInValidSelectedItems = [...plcInValidSelectedItems, i];
            model.Items[i].visible = true;
            if (!chSetVisible) {
              chSetVisible = true;
            }
          }
        } else if (isListPriceInvalid(model, i)) {
          if (isInvalidItemsVisible(model, i)) {
            model.Items[i].visible = false;
          }
          if (model.Items[i].selected) {
            priceInvalidSelectedItems = [...priceInvalidSelectedItems, i];
            model.Items[i].visible = true;
            if (!chSetVisible) {
              chSetVisible = true;
            }
          }
        }
      }
    }
    if (
      chSetVisible ||
      model.Chaids[index].required ||
      model.Chaids[index].selcon
    ) {
      model.Chaids[index].visible = true;
    }
    chSetVisible = false;
  }
  let plcConflicts = [];
  for (let j = 0; j < plcInValidSelectedItems.length; j++) {
    const each = plcInValidSelectedItems[j];
    for (var i = 0; i < model.Chaids.length; i++) {
      const chaid = model.Chaids[i];
      if (!HIDDEN_CHOICES[chaid.chaId]) {
        if (each >= chaid.firstItem && each <= chaid.lastItem) {
          const newPlcConflict = {
            code: CODE_PLC_CONFLICT,
            messageType: TEXT_PLC_CONFLICT,
            plcMessage: model.Items[each].plcMessage,
            itemiNode: model.Items[each].inode,
            chaId: chaid.chaId,
            chaDes: chaid.chaDes,
            partNo: model.Items[each].partno,
            saDate: model.Items[each].saDate,
            esDate: model.Items[each].esDate
          };
          plcConflicts = [...plcConflicts, newPlcConflict];
          model.isPlcConflict = true;
          break;
        }
      }
    }
  }
  for (let j = 0; j < priceInvalidSelectedItems.length; j++) {
    const each = priceInvalidSelectedItems[j];
    for (var i = 0; i < model.Chaids.length; i++) {
      const chaid = model.Chaids[i];
      if (!HIDDEN_CHOICES[chaid.chaId]) {
        if (each >= chaid.firstItem && each <= chaid.lastItem) {
          const newPlcConflict = {
            code: CODE_PLC_CONFLICT,
            messageType: TEXT_PRICE_CONFLICT,
            plcMessage: model.Items[each].plcMessage,
            itemiNode: model.Items[each].inode,
            chaId: chaid.chaId,
            chaDes: chaid.chaDes,
            partNo: model.Items[each].partno,
            saDate: model.Items[each].saDate,
            esDate: model.Items[each].esDate
          };
          plcConflicts = [...plcConflicts, newPlcConflict];
          model.isPlcConflict = true;
          break;
        }
      }
    }
  }
  return plcConflicts;
};

const generateGADateConflicts = (model, todayDate) => {
  let gaConflictsItemMap = [];
  let gaDateConflicts = [];
  for (let index = 0; index < model?.Items?.length; index++) {
    const item = model.Items[index];
    if (item.gaDate !== "NA") {
      const gaDate = moment(item.gaDate).format("YYYY-MM-DD");
      if (item.selected && item.visible && gaDate > todayDate) {
        // check if the item is selected & visible &gadate is future
        gaConflictsItemMap.push({
          itemINode: item.inode,
          itemPartNo: item.partno
        });
      }
    }
  }

  for (let i = 0; i < gaConflictsItemMap.length; i++) {
    const item = gaConflictsItemMap[i];
    for (let j = 0; j < model?.Chaids?.length; j++) {
      const chaObj = model.Chaids[j];
      if (
        item.itemINode >= chaObj.firstItem &&
        item.itemINode <= chaObj.lastItem &&
        chaObj.visible &&
        chaObj.precon >= 0
      ) {
        const newCnflts = {
          code: CODE_GA_DATE_CONFLICT,
          messageType: TEXT_GA_DATE_CONFLICT,
          chaId: chaObj.chaId,
          chaDes: chaObj.chaDes,
          partNo: item.itemPartNo
        };
        gaDateConflicts = [...gaDateConflicts, newCnflts];
      }
    }
  }
  return gaDateConflicts;
};

export const getgaDateConflicts = model => {
  const startDate = model?.startDate;
  const date = new Date();
  const todayDate = moment(date).format("YYYY-MM-DD");
  let gaDateConflicts = [];
  const showGaDateError = model.showGaDateConfigError;

  // Case 1 & 2: ShowGADate is off && SPC start date is future or past date
  if (!model.showGADate && showGaDateError) {
    gaDateConflicts = generateGADateConflicts(model, todayDate);
  }
  model.isGaDateConflict = gaDateConflicts.length > 0;
  return gaDateConflicts;
  // Trial ends here
};
//src/webasm/validate.go
export const printModelUpdate = model => {
  let isPaperTraySelected = false;
  for (let index = 0; index < model?.Chaids?.length; index++) {
    const chaObj = model.Chaids[index];
    if (
      chaObj.chaId === PAPER_TRAY_CHOICE_ID &&
      chaObj.precon >= 0 &&
      chaObj.visible
    ) {
      for (var i = chaObj.firstItem; i <= chaObj.lastItem; i++) {
        if (model.items && model.items[i].selected) {
          isPaperTraySelected = true;
          break;
        }
      }
    } else if (chaObj.chaId === OUTPUT_FINISHING_CHOICE_ID) {
      if (isPaperTraySelected) {
        model.Chaids[index].visible = true;
      } else if (!isPaperTraySelected) {
        model.Chaids[index].visible = false;
        for (var j = chaObj.firstItem; j <= chaObj.lastItem; j++) {
          if (model.items && model.items[j].selected) {
            model.Chaids[index].visible = true;
          }
        }
      }
    }
  }
};
//src/webasm/validate.go getEffect
export const getEffects = (model, conflicts) => {
  let eff = [];
  let sameConflictItemsMap = {};
  const Edge_Type_Sel = 68;
  let itmCnfltList = [];
  if (conflicts?.length > 0 && conflicts[0].itemConflictsList.length > 0) {
    itmCnfltList = conflicts[0].itemConflictsList;
  }
  const flagMap = {};
  model?.sameConflictItems?.forEach(each => {
    sameConflictItemsMap[each] = true;
    itmCnfltList?.forEach(conflict => {
      if (conflict?.effect?.iitem == each) {
        flagMap[each] = true;
      }
    });
  });
  for (let i = 0; i < model?.Items?.length; i++) {
    const item = model.Items[i];
    if (item.visible && item.precon < 0 && item.selected) {
      if (flagMap[item.inode]) {
        const { Ichaid, ChaId, ChaDes } = getIChaid(item.inode, model);
        if (!HIDDEN_CHOICES[ChaId]) {
          const newEffect = {
            iitem: item.inode,
            partNo: item.partno,
            ichaid: Ichaid,
            chaId: ChaId,
            chaDes: ChaDes,
            etype: Edge_Type_Sel
          };
          eff = [...eff, newEffect];
        } else if (!sameConflictItemsMap[item.inode]) {
          const { Ichaid, ChaId, ChaDes } = getIChaid(item.inode, model);
          if (!HIDDEN_CHOICES[ChaId]) {
            const newEffect = {
              iitem: item.inode,
              partNo: item.partno,
              ichaid: Ichaid,
              chaId: ChaId,
              chaDes: ChaDes,
              etype: Edge_Type_Sel
            };
            eff = [...eff, newEffect];
          }
        }
      }
      for (let index = 0; index < model?.conflicts?.length; index++) {
        model.conflicts[index].itemConflicts = {
          cause: [],
          effect: eff
        };
      }
    }
  }

  if (eff?.length === 0) {
    for (let index = 0; index < model?.conflicts?.length; index++) {
      model.conflicts[index].itemConflicts = {};
    }
  }
  isDebug && console.log("getEffects uitl=>", eff);
  return model;
};

//src/webasm/print.go
export const printConflicts = modelData => {
  if (
    localStorage.getItem("isDebug") === "true" ||
    localStorage.getItem("showTime") === "true"
  ) {
    console.log(`\n=======CONFLICTS & FLAGS=======`);
    console.log("ERR04 - Required Choice level Warning");
    console.log("ERR03 - Item level Conflict");
    console.log("ERR05 - Counter level Conflict");
    console.log("ERR06 - PLC level Conflict");
    console.log("Conflicts:", JSON.stringify(modelData.conflicts));
    console.log("IsValid:", modelData.isValid);
    console.log(`================================\n`);
  }
};

const sortPayloadByInode = itemChanges => {
  itemChanges.sort(function (a, b) {
    return a?.inputNode - b?.inputNode;
  });
  return itemChanges;
};

const removeDuplicatesFromNonCP = nonCp => {
  const cm = [];
  let res = [];
  nonCp.forEach(x => {
    if (x?.inputNode && x?.changeIn && x?.inputEdge) {
      const chngIn = String(x.changeIn);
      const inptEge = String(x.inputEdge);
      const inptNde = String(x.inputNode);
      cm[chngIn + ":" + inptEge + ":" + inptNde] = x;
    }
  });
  Object?.keys(cm)?.map(each => {
    let payLd = cm[each];
    if (payLd) {
      res = [...res, payLd];
    }
  });
  return res;
};

//src/service/carepacks-selection.go
export const segregatePayload = (payload, modelData) => {
  let carePackArr = [];
  let nonCarePackArr = [];
  let chaId = {};
  const isHP2BOrECOMMConfig = checkStoreFront(modelData);
  for (let i = 0; i < payload.itemChanges.length; i++) {
    const item = payload.itemChanges[i];
    chaId = getIChaid(item.inputNode, modelData);
    if (
      chaId.ChaId &&
      ((chaId.ChaId === CAREPACK_ELECT_CPQ && !isHP2BOrECOMMConfig) ||
        chaId.ChaId.startsWith(MONITOR_CAREPACKS_PREFIX, 0))
    ) {
      carePackArr.push(item);
    } else {
      nonCarePackArr.push(item);
    }
  }
  let cpPayload = {
    itemChanges: carePackArr
  };
  let nonCpPayload = {
    itemChanges: sortPayloadByInode(removeDuplicatesFromNonCP(nonCarePackArr))
  };
  let segModel = {
    cpPayload,
    nonCpPayload,
    chaId
  };
  return segModel;
};

//src/service/carepacks-selection.go
export const updateCarePacks = (payload, modelData, selectQtyOne) => {
  isDebug && console.log("beforeupdateCarePacks", modelData.carePacks);
  isDebug && console.log("beforepayload", payload);
  let cp = modelData.carePacks;

  for (let i = 0; i < payload?.itemChanges?.length; i++) {
    const item = payload.itemChanges[i];
    if (
      item.changeIn === 1 &&
      !modelData.Items[item.inputNode].selected &&
      modelData.Items[item.inputNode].visible
    ) {
      modelData.Items[item.inputNode].selected = true;
      if (selectQtyOne) modelData.Items[item.inputNode].quantity = SelectedQty;
      const { Ichaid, ChaId, ChaDes } = getIChaid(item.inputNode, modelData);
      const doesElementExistSlice = modelData.carePacks?.find(
        carepack => carepack.itemInode === item.inputNode
      );
      if (!doesElementExistSlice) {
        const data = {
          chaId: ChaId,
          itemInode: item.inputNode,
          partNo: modelData.Items[item.inputNode].partno,
          valueId: modelData.Items[item.inputNode].valueId,
          selected: true
        };
        modelData.carePacks = modelData?.carePacks
          ? [...modelData.carePacks, data]
          : [data];
      }
    } else if (item.changeIn === -1) {
      modelData.Items[item.inputNode].selected = false;
      modelData.Items[item.inputNode].quantity = DefaultQty;
      const doesElementExistSlice = modelData.carePacks?.find(
        carepack => carepack.itemInode === item.inputNode
      );
      if (doesElementExistSlice) {
        cp =
          modelData.carePacks.length > 0 &&
          modelData.carePacks.filter(each => each.itemInode !== item.inputNode);
        cp =
          cp &&
          cp.reduce((data, each) => {
            const duplicateItems = data.find(
              item => item.itemInode === each.itemInode
            );
            if (!duplicateItems) {
              return data.concat([each]);
            } else {
              return data;
            }
          }, []);

        modelData.carePacks = cp;
      }
    }
  }
  isDebug && console.log("aftercarepackupdate", modelData.carePacks);
  return modelData;
};

//src/lib/slice.go
export const doesSubElementExistString = (elm, arr) => {
  let idx = -1;
  let flag = false;
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.includes(":")) {
      const data = element.split(":");
      if (data[0] == elm) {
        idx = index;
        flag = true;
        break;
      }
    } else {
      if (element == elm) {
        idx = index;
        flag = true;
        break;
      }
    }
  }
  return { idx, flag };
};

//src/webasm/validate.go
export const isInvalidItemsVisible = (modelData, index) => {
  let result = false;
  if (!modelData.Items[index].selected && modelData.Items[index].visible) {
    result = true;
  }
  return result;
};

//src/webasm/validate.go
export const RemoveElmConflictsForNotSelected = (iNode, itemConflict) => {
  let tempArr = [];
  itemConflict.forEach(conflict => {
    if (conflict.effect.iitem !== iNode) {
      tempArr = [...tempArr, conflict];
    }
  });
  return tempArr;
};

export const doesElementExistInDeletedEntries = (elm, toBeDeletedEntries) => {
  return toBeDeletedEntries.some(each => each?.effect?.partno === elm);
};

export const removeCPconflicts = (itemconflicts, model) => {
  let toBeDeletedEntries = [];
  let tempRetConflicts = [];
  let flagCse = false;
  let flagEff = false;
  itemconflicts?.length > 0 &&
    itemconflicts?.forEach(conflicts => {
      model?.Items?.forEach(items => {
        if (
          conflicts?.effect?.partno?.length > 0 &&
          conflicts?.effect?.partno === items?.partno &&
          (!items?.selected || items.precon >= 0)
        ) {
          toBeDeletedEntries = [...toBeDeletedEntries, conflicts];
        }
        if (conflicts?.cause?.partno == items?.partno) {
          flagCse = true;
        }
        if (conflicts?.effect?.partno == items?.partno) {
          flagEff = true;
        }
      });
      if (!flagCse || !flagEff) {
        toBeDeletedEntries = [...toBeDeletedEntries, conflicts];
      }
      flagCse = false;
      flagEff = false;
    });
  if (toBeDeletedEntries.length > 0) {
    itemconflicts?.forEach(conflicts => {
      let flag = false;
      if (
        doesElementExistInDeletedEntries(
          conflicts.effect.partno,
          toBeDeletedEntries
        )
      ) {
        flag = true;
      }
      if (!flag) {
        tempRetConflicts = [...tempRetConflicts, conflicts];
      }
    });
    itemconflicts = [];
    itemconflicts = tempRetConflicts;
  }
  return itemconflicts;
};

export const DoesElementExistInEffect = (elm, itemConflicts) => {
  for (var i = 0; i < itemConflicts.length; i++) {
    if (itemConflicts[i]?.effect?.partno === elm) return true;
  }
  return false;
};

export const DoesElementExistInIcconflicts = (elm, icConflicts) => {
  for (var i = 0; i < icConflicts.length; i++) {
    if (icConflicts[i]?.partNo === elm) return true;
  }
  return false;
};

export const selectSingleAvailableItemUnderRequiredChoice = (model, conflictDetection)=> {
  let payLoad = {
    itemChanges: [{}]
  };
  model?.Chaids.forEach(choice => {
    if (
      (choice.required || choice.selcon) &&
      choice.visible &&
      choice.precon >= 0
    ) {
      let counter = 0;
      let node;
      for (let i = choice.firstItem; i <= choice.lastItem; i++) {
        const item = model.Items[i];
        if (item.selected) {
          i = choice.lastItem + 1;
          counter++;
        }
        if (
          item.visible &&
          item.plcStatus === VALID &&
          (item.priceStatus === VALID || DummyChoiceIDs.includes(choice.chaId)) &&
          (item.precon >= 0 ||
            ![logisticsServicesChaidID, logisticsServicesAAChaidID].includes(
              choice.chaId
            ))
        ) {
          counter++;
          if (counter > 1 || item.selected) {
            i = choice.lastItem + 1;
            counter++;
          } else {
            node = model.Nodes[item.inode];
          }
        }
      }

      if (counter === 1 && node) {
        const iPayLoad = {
          inputEdge: node.type,
          inputNode: node.id,
          changeIn: DO_SELECT
        };
        if (payLoad?.itemChanges[0]?.inputNode) {
          payLoad.itemChanges = [...payLoad.itemChanges, iPayLoad];
        } else {
          payLoad.itemChanges = [iPayLoad];
        }
        model.Items[node.id].quantity = 1
      }
    }
  });
  if (payLoad?.itemChanges[0]?.inputNode || conflictDetection) {
    const { cpPayload, nonCpPayload, chaId } = segregatePayload(payLoad, model);
    const oneModel = new loadOneModel(model);
    oneModel.updateModelWithMultipleChanges(nonCpPayload);
  }
};

function getLocOption(modelData) {
  const { systemLocOptions } = modelData
  const selectedOption = systemLocOptions.find(sysLoc => sysLoc.selected)
  return selectedOption ? selectedOption.locOption : ""
}

export const updateVisibilityForGrayoutItems = modelData => {
  const sysLocOption = getLocOption(modelData)
  sysLocChaidsCOMPUTE_locChaidsPRINT?.map(sysChaid => {
    modelData.Chaids.map(chaid => {
      if (chaid.chaId == sysChaid) {
        for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
          let item = modelData.Items[i];
          let itemLocCode
          let isLocalizedPartNo = item?.partno?.includes("#")
          if (
            isLocalizedPartNo ||
            chaid.chaId == PrintPowerCordsChaid
          ) {
            if (isLocalizedPartNo) {
              itemLocCode = item.partno?.split("#")[1]
            }
            if (item.precon < 0 && item.visible && !item.selected) {
              modelData.Items[i].plcMessage =
                item?.plcMessage +
                " " +
                "UpdateVisibilityForGrayoutItems applied case1"
              modelData.Items[i].visible = false
            } else if (
              !item.visible &&
              item.precon >= 0 &&
              item.priceStatus == VALID &&
              item.plcStatus == VALID &&
              (item.isBandedItem ||
                item.selected ||
                itemLocCode === sysLocOption)
            ) {
              modelData.Items[i].plcMessage =
                item?.plcMessage +
                " " +
                "UpdateVisibilityForGrayoutItems applied case2"
              modelData.Items[i].visible = true
              modelData.Items[i].isBandedItem = item.visible
            }
          }
        }
      }
      if (HIDDEN_CHOICES && HIDDEN_CHOICES[chaid.chaId]) {
        chaid.visible = false;
      }
    });
  });
  modelData.Chaids.forEach(chaid => {
    for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
      let item = modelData.Items[i];
      const { idx, flag } = doesSubElementExistString(
        String(item?.inode),
        modelData?.clicPartiallyAffectedItems
      );
      if (flag) {
        const clicData = modelData.clicPartiallyAffectedItems[idx]?.split(":");
        let clicEdge = -1;
        if (clicData.length > 1) {
          if (clicData[1]?.includes(",")) {
            const clicD = clicData[1].split(",");
            clicEdge = Number(clicD[clicD.length - 1]);
          } else {
            clicEdge = Number(clicData[1]);
          }
        }
        if (
          modelData.Items[i].precon < 0 &&
          clicEdge == EdgeType_I_AVL &&
          !modelData.Items[i].selected
        ) {
          if (modelData.Items[i].visible) {
            modelData.Items[i].visible = false;
            modelData.Items[i].isBandedItem = modelData.Items[i].visible;
            modelData.Items[i].plcMessage = PartialCLICEnabled;
          }
        } else {
          if (
            !modelData.Items[i].visible &&
            item.precon >= 0 &&
            item.priceStatus == VALID &&
            item.plcStatus == VALID &&
            !item.plcMessage?.includes(ItemDisabledForPartner)
          ) {
            modelData.Items[i].visible = true;
            modelData.Items[i].isBandedItem = modelData.Items[i].visible;
            modelData.Items[i].plcMessage =
              modelData.Items[i].plcMessage +
              " -> UpdateVisibilityForGrayoutItems updated on visibility with CLIC";
          }
        }
      }
    }
    if (HIDDEN_CHOICES && HIDDEN_CHOICES[chaid.chaId]) {
      chaid.visible = false;
    }
  });
  return modelData;
};

export const removeDuplicate = arr => {
  let unique = [];
  arr.forEach(item => {
    if (item.length !== 0) {
      if (!unique.includes(item)) {
        unique = [...unique, item];
      }
    }
  });
  return unique;
};

const updatePriority = (
  deSelectedBaseProducts,
  selectedBaseProducts,
  modelData
) => {
  if (Object.keys(modelData?.priorityStorage)?.length > 0) {
    const deSelectedSkus = {};
    Object.keys(deSelectedBaseProducts)?.forEach(basePart => {
      modelData.priorityStorage[basePart] &&
        Object.keys(modelData.priorityStorage[basePart])?.forEach(sku => {
          deSelectedSkus[sku] = {};
        });
    });

    const selectedSkus = {};
    Object.keys(selectedBaseProducts)?.forEach(basePart => {
      modelData.priorityStorage[basePart] &&
        Object.keys(modelData.priorityStorage[basePart])?.forEach(sku => {
          selectedSkus[sku] = modelData.priorityStorage[basePart][sku];
        });
    });

    for (const item of modelData.Items) {
      const partNo = item.partno.split("#")[0];
      if (deSelectedSkus[partNo]) {
        item.priority = "";
      }

      if (selectedSkus[partNo]) {
        item.priority = selectedSkus[partNo];
      }
    }
  }
};

export const getAutoAttachedParts = (payLoad, modelData, allMonParts) => {
  let selectedProducts = [];
  let deSelectedProducts = [];
  let selectedNodes = [];
  let deSelectedNodes = [];
  let deSelectedMonAccCP = {};
  let selectedMonAccCP = {};
  let selectedBaseProducts = {};
  let deSelectedBaseProducts = {};
  for (let i = 0; i < payLoad.itemChanges.length; i++) {
    const item = payLoad.itemChanges[i];
    if (item.changeIn == 1) {
      selectedNodes = [...selectedNodes, item.inputNode];
      continue;
    }
    deSelectedNodes = [deSelectedNodes, item.inputNode];
  }
  for (let i = 0; i < selectedNodes.length; i++) {
    const node = selectedNodes[i];
    if (modelData.Items[node]) {
      let name = modelData.Items[node].partno;
      if (name.includes("#")) {
        name = name.split("#")[0];
      }
      selectedBaseProducts[name] = {};
    }
  }
  for (let i = 0; i < deSelectedNodes.length; i++) {
    const node = deSelectedNodes[i];
    if (modelData.Items[node]) {
      let name = modelData.Items[node].partno;
      if (name.includes("#")) {
        name = name.split("#")[0];
      }
      deSelectedBaseProducts[name] = {};
    }
  }
  if (modelData.autoAttachProducts) {
    for (let product of modelData.autoAttachProducts) {
      if (selectedBaseProducts[product.name]) {
        if (doesElementExistString(product.name, allMonParts)) {
          selectedMonAccCP[product.name] = [];
          continue;
        }
        product.companions?.forEach(child => {
          if (doesElementExistString(child.name, allMonParts)) {
            selectedMonAccCP[product.name] = [];
          }
          selectedProducts = [...selectedProducts, child.name];
        });
      }
      if (deSelectedBaseProducts[product.name]) {
        if (doesElementExistString(product.name, allMonParts)) {
          deSelectedMonAccCP[product.name] = [];
        }
        for (let child of product.companions) {
          if (deSelectedMonAccCP[product.name]) {
            deSelectedMonAccCP[product.name] = [
              ...deSelectedMonAccCP[product.name],
              child.name
            ];
            continue;
          }
          deSelectedProducts = [...deSelectedProducts, child.name];
        }
      }
    }
  }

  modelData.autoAttachProducts?.forEach(product => {
    if (selectedMonAccCP[product.name]) {
      product.companions?.forEach(child => {
        selectedMonAccCP[product.name] = [
          ...selectedMonAccCP[product.name],
          child.name
        ];
      });
    }
  });
  if (
    modelData?.priorityStorage &&
    Object.keys(modelData?.priorityStorage)?.length > 0
  ) {
    updatePriority(deSelectedBaseProducts, selectedBaseProducts, modelData);
  }
  selectedProducts = removeDuplicate(selectedProducts);
  deSelectedProducts = removeDuplicate(deSelectedProducts);
  const ret = {
    selectedProducts,
    deSelectedProducts,
    deSelectedMonAccCP,
    selectedMonAccCP
  };
  return ret;
};

export const updateAutoAttachedParts = (
  modelData,
  selectedProducts,
  deSelectedProducts,
  deSelectedMonAccCP,
  selectedMonAccCP
) => {
  let deSlectedPayloads = [];
  let cpPayload, nonCpPayload, chaId;
  let updateModelData = modelData;
  deSelectedProducts?.forEach(v => {
    updateModelData.Items.forEach(item => {
      if (item.autoAttach && item.partno == v && item.selected) {
        const iPayload = {
          inputEdge: updateModelData.Nodes[item.inode].type,
          inputNode: updateModelData.Nodes[item.inode].id,
          changeIn: DO_DESELECT
        };
        deSlectedPayloads = [...deSlectedPayloads, iPayload];
        item.autoAttach = false;
      }
    });
  });
  for (const [k, v] of Object.entries(deSelectedMonAccCP)) {
    for (const cp of v) {
      for (const item of modelData.Items) {
        const { ChaId } = getIChaid(item.inode, modelData);
        if (
          item.autoAttach &&
          item.partno === cp &&
          item.selected &&
          ChaId.includes(k)
        ) {
          deSlectedPayloads.push({
            inputEdge: modelData.Nodes[item.inode].type,
            inputNode: modelData.Nodes[item.inode].id,
            changeIn: DO_DESELECT
          });
          item.autoAttach = false;
        }
      }
    }
  }
  let payload = {
    itemChanges: deSlectedPayloads
  };
  ({ cpPayload, nonCpPayload, chaId } = segregatePayload(
    payload,
    updateModelData
  ));

  if (cpPayload.itemChanges?.length !== 0) {
    updateModelData = updateCarePacks(cpPayload, updateModelData, true);
  }
  nonCpPayload.itemChanges?.forEach(iPayLoad => {
    updateModelData = updateModel(
      iPayLoad.inputEdge,
      iPayLoad.inputNode,
      iPayLoad.changeIn,
      updateModelData
    );
  });

  let selectedPayloads = [];
  let selChaids = {};
  let defMonLoc;
  updateModelData.monitorLocOptions?.forEach(monLoc => {
    if (monLoc.selected) {
      defMonLoc = monLoc.locOption;
    }
  });
  for (let i = 0; i < selectedProducts?.length; i++) {
    const valP = selectedProducts[i];
    for (let j = 0; j < updateModelData.Items?.length; j++) {
      let selFlag = false;
      const val = updateModelData.Items[j];
      if (val.partno.length !== 0 && val.partno.includes(valP)) {
        if (
          val.partno.includes("#") &&
          val.partno.includes("#")[1] !== defMonLoc
        ) {
          continue;
        }
        if (val.plcStatus != "I" && val.priceStatus == "V" && val.precon >= 0) {
          for (let ch = 0; ch < updateModelData.Chaids.length; ch++) {
            const choice = updateModelData.Chaids[ch];
            if (val.inode >= choice.firstItem && val.inode <= choice.lastItem) {
              if (!choice.selcon && !choice.required && choice.precon < 0) {
                selFlag = true;
                break;
              }
              if (selChaids[choice.chaId]) {
                if (selChaids[choice.chaId] == 2) {
                  selFlag = true;
                }
              } else {
                selChaids[choice.chaId] = 1;
              }
              for (let i = choice.firstItem; i <= choice.lastItem; i++) {
                if (updateModelData.Items[i].selected) {
                  selChaids[choice.chaId] = 2;
                  selFlag = true;
                  break;
                }
              }
              break;
            }
          }

          if (!selFlag) {
            const node = updateModelData.Nodes[val.inode];
            const iPayLoad = {
              inputEdge: node.type,
              inputNode: val.inode,
              changeIn: DO_SELECT
            };
            selectedPayloads = [...selectedPayloads, iPayLoad];
            val.visible = true;
            val.autoAttach = true;
          }
        }
      }
    }
  }
  for (const p of Object.keys(selectedMonAccCP)) {
    const childParts = selectedMonAccCP[p]
    for (const valP of childParts) {
      for (const val of updateModelData.Items) {
        const selFlag = false
        if (val.partno.includes(valP)) {
          if (
            val.partno.includes("#") &&
            val.partno.split("#")[1] !== defMonLoc
          ) {
            continue
          }
          const { Ichaid, ChaId, ChaDes } = getIChaid(
            val.inode,
            updateModelData
          );
          if (
            val.plcStatus !== "I" &&
            val.priceStatus === "V" &&
            val.precon >= 0 &&
            ChaId.includes(p)
          ) {
            for (const kbCh of modelData.Chaids) {
              if (val.inode >= kbCh.firstItem && val.Inode <= kbCh.lastItem) {
                if (!kbCh.selcon && !kbCh.required && kbCh.precon < 0) {
                  selFlag = true
                  break
                }
                if (selChaids[kbCh.chaId] === 2) {
                  selFlag = true
                } else {
                  selChaids[kbCh.chaId] = 1
                  for (let i = kbCh.firstItem; i <= kbCh.lastItem; i++) {
                    if (modelData.Items[i].selected) {
                      selChaids[kbCh.chaId] = 2
                      selFlag = true
                      break
                    }
                  }
                }
                break
              }
            }

            if (!selFlag) {
              const node = modelData.Nodes[val.inode]
              selectedPayloads.push({
                inputEdge: node.type,
                inputNode: val.inode,
                changeIn: DO_SELECT
              });
              val.visible = true
              val.autoAttach = true
            }
          }
        }
      }
    }
  }

  const payloads = {
    itemChanges: selectedPayloads
  };

  ({ cpPayload, nonCpPayload, chaId } = segregatePayload(
    payloads,
    updateModelData
  ));

  if (cpPayload.itemChanges?.length !== 0) {
    updateModelData = updateCarePacks(cpPayload, updateModelData, true);
  }
  nonCpPayload.itemChanges?.forEach(iPayLoad => {
    updateModelData = updateModel(
      iPayLoad.inputEdge,
      iPayLoad.inputNode,
      iPayLoad.changeIn,
      updateModelData
    );
  });
  return updateModelData;
};

export function isParentChoice(chaId, modelData) {
  for (let ch of modelData.Chaids) {
    if (ch.chaId !== chaId) {
      if (ch.chaId.includes(chaId)) {
        return true;
      }
    }
  }
  return false;
}


export function processQtyForPrntChoicesUnderConfigurationServices(
  payload,
  modelData
) {
  let updatedModel = modelData;
  for (let iPayLoad of payload.itemChanges) {
    const { Ichaid: iChaid, ChaId: chaId } = getIChaid(
      iPayLoad.inputNode,
      updatedModel
    );
    if (!chaId.startsWith(ConfigurationServicesChaidsPrefix)) {
      continue;
    }
    if (isParentChoice(chaId, updatedModel)) {
      // parent choice
      let parentChoice = chaId;
      let choiceChoiceIdx = -1;
      if (
        updatedModel?.parentChildChoiceMapping &&
        updatedModel.parentChildChoiceMapping[parentChoice] &&
        updatedModel.parentChildChoiceMapping[parentChoice].length > 0
      ) {
        choiceChoiceIdx =
          updatedModel.parentChildChoiceMapping[parentChoice][
            iPayLoad.inputNode
          ];
      }

      if (choiceChoiceIdx > 0) {
        if (iPayLoad.changeIn === DO_DESELECT) {
          updatedModel = deselectPartsInChildChoice(
            choiceChoiceIdx,
            updatedModel
          );
          updatedModel.Items[iPayLoad.inputNode].quantity = DefaultQty;
        } else if (iPayLoad.changeIn === DO_SELECT) {
          updatedModel.Items[iPayLoad.inputNode].quantity = SelectedQty;
        }
      }
    } else {
      // child choice
      let qtyUpdated = false;
      if (updatedModel.parentChildChoiceMapping) {
        for (let itmChildChoiceMap of Object.values(
          updatedModel.parentChildChoiceMapping
        )) {
          for (let [prntItmInode, choiceChoiceIdx] of Object.entries(
            itmChildChoiceMap
          )) {
            if (choiceChoiceIdx === iChaid) {
              updatedModel.Items[prntItmInode].quantity =
                getQtyForSelectedPartsInChildChoice(
                  choiceChoiceIdx,
                  updatedModel
                );
              qtyUpdated = true;
              break;
            }
          }
          if (qtyUpdated) {
            break;
          }
        }
      }
    }
  }
  return updatedModel;
}

function getQtyForSelectedPartsInChildChoice(choiceChoiceIdx, modelData) {
  let qty = DefaultQty;
  for (
    let i = modelData.Chaids[choiceChoiceIdx].firstItem;
    i <= modelData.Chaids[choiceChoiceIdx].lastItem;
    i++
  ) {
    if (modelData.Items[i].selected && modelData.Items[i].visible) {
      qty++;
    }
  }
  if (qty > DefaultQty) {
    return qty;
  }
  return SelectedQty;
}

function deselectPartsInChildChoice(choiceChoiceIdx, modelData) {
  let updatedModel = modelData;
  for (
    let i = updatedModel.Chaids[choiceChoiceIdx].firstItem;
    i <= updatedModel.Chaids[choiceChoiceIdx].lastItem;
    i++
  ) {
    if (updatedModel.Items[i].selected) {
      updatedModel = updateModel(
        EdgeType_GTE_0,
        updatedModel.Items[i].inode,
        DO_DESELECT,
        updatedModel
      );
      updatedModel.Items[i].quantity = DefaultQty;
    }
  }
  return updatedModel
}

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { Box, Chip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { togglePopupActions } from "../../../actions/togglePopupActions";
import ExternalProps from "../../../contexts/externalPropsContext";
import ClipboardField from "../../../lib/common/ClipboardField";
import { BOM } from "./Constants";
import ProductBullets from "./ProductBullets";
const useStyles = makeStyles(theme => ({
  title: {
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white"
  },
  card: {
    width: "auto",
    minHeight: "100%",
    marginLeft: "5px"
  },
  cardContent: {
    "&:hover": {
      border: "2px solid #0096D6",
      borderTop: "none",
      cursor: "pointer"
    }
  },
  textform: {
    color: "#808080"
  },
  box: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0"
  }
}));

const CompareCard = props => {
  const { type, product, showCarePacks } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isStandaloneAccess = false } = useContext(ExternalProps);
  const { similarProducts } = useSelector(state => state.rskuReducer);

  const title =
    type === BOM
      ? t("common:rsku.compare.billOfMaterials")
      : t("common:rsku.compare.similarProducts");

  const carePackMessage = product?.isBOM3
    ? t("common:rsku.compare.carePackIncluded")
    : t("common:rsku.compare.carePackNotIncluded");

  const handleComapreDialogClose = useCallback(() => {
    dispatch(togglePopupActions({ name: "compareDialog", open: false }));
  }, []);

  /**
   * Increasing the Quantity of selected closeMatch sku
   * @param {Array<Object>} sku - updating the quantity of selects Sku
   * @param {number} quantity - total quantity of SKu
   * @returns {void}
   */

  const quantityAddition = (sku, quantity) => {
    let isSimilarProduct = false;
    similarProducts.map(products => {
      if (sku === products.sku) {
        isSimilarProduct = true;
      }
    });
    batch(() => {
      dispatch({
        type: "SET_SELECTED_SKU",
        payload: sku
      });

      dispatch({
        type: "SET_UPDATE_QUANTITY",
        payload: {
          sku,
          quantity: quantity + 1,
          isSimilarProduct,
          skuType: product?.skuType
        }
      });
    });
  };

  /**
   * Updating the quantity of the selected closeMatch product
   * It is applicable only Non-standalone application
   * @returns {void}
   */

  const handleCardContent = () => {
    if (type !== BOM) {
      quantityAddition(product?.sku, product?.quantity);
      handleComapreDialogClose();
      dispatch({
        type: "SET_ACTIVE_TAB",
        payload: 0
      });
    }
  };

  return (
    <Card className={classes.card}>
      <Box
        className={classes.title}
        style={{ backgroundColor: type === BOM ? "#0096D6" : "#075782" }}
      >
        {title}
      </Box>
      <CardContent
        style={{ marginBottom: "0px", paddingBottom: "0px" }}
        className={type !== BOM && !isStandaloneAccess && classes.cardContent}
        onClick={!isStandaloneAccess && handleCardContent}
      >
        <Box
          width="100%"
          height="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CardMedia
            style={{
              width: "250px",
              height: "180px"
            }}
            component="img"
            image={product?.image}
          />
        </Box>

        <Typography variant={"subtitle1"}>{product?.name}</Typography>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography variant={"body2"}>
            {product?.sku}
            <ClipboardField text={product?.sku} />
          </Typography>
        </Box>
        {showCarePacks && (
          <Box className={classes.box}>
            <Chip
              icon={
                product?.isBOM3 ? (
                  <VerifiedUserOutlinedIcon color="primary" fontSize="14px" />
                ) : (
                  <CancelOutlinedIcon fontSize="14px" />
                )
              }
              className={!product?.isBOM3 && classes.textform}
              label={carePackMessage}
              color={product?.isBOM3 ? "primary" : "default"}
              variant="outlined"
            />
          </Box>
        )}

        <ProductBullets bullets={product?.attributes} lg={12}></ProductBullets>
      </CardContent>
    </Card>
  );
};
export default CompareCard;

/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CHOICEID,
  GET_DEFAULT_CONFIGURATION,
  GET_RSKU_IMAGE,
  RSKU_MATCH
} from "../../../../GraphQL";
import { togglePopupActions } from "../../../../actions/togglePopupActions";
import { ExternalProps } from "../../../../contexts/externalPropsContext";
import displayNotistack from "../../../../lib/common/SnackBarUtils";
import {
  getAllBomDetails,
  getCloseMatchBomData,
  getFilteredAccessories,
  getFilteredBomData,
  getHardwareAvs,
  getMandatorySkus,
  getProductData,
  getRskuImages,
  getSimilarProductData
} from "../../../../lib/common/util";
import { NA } from "../Constants";
import WithAddToQuote from "../withAddToQuote";
import RskuDialogContent from "./RskuDialogContent";
import useStyles from "./styles";

const RskuDialog = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // states
  const {
    currencyCode,
    incoterms,
    isStandaloneAccess = false
  } = React.useContext(ExternalProps);
  const { addCTOToQuote } = props;
  //useSelectors
  const { countryRegion } = useSelector(state => state.productSelector);
  const { isUploadConfig } = useSelector(state => state.uploadConfig);
  const {
    localization,
    isSkuCloning,
    rSKUBom,
    productCountryCode,
    productRegionCode,
    rskuImage,
    configID,
    skuConfiguration,
    bomThreeCompatiblePartNumbers,
    bomThreeCompatibleCarePacksInfo
  } = useSelector(state => state.rskuReducer);
  const { modelData, configuration } = useSelector(
    state => state.configFilterData
  );

  const filteredAccessories = getFilteredAccessories(rSKUBom);

  //useState
  const [isRequestRsku, setIsRequestRsku] = useState(false);
  const [isSimilar, setIsSimilar] = useState(false);
  const [isExact, setIsExact] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [carePackProducts, setCarePackProducts] = useState(filteredAccessories);
  const [closeMatchData, setCloseMatchData] = useState([]);
  const [exactMatchData, setExactMatchData] = useState([]);
  const [hardwareProducts, setHardwareProducts] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [avs] = useState(getHardwareAvs(rSKUBom));
  const [updatedConfigId, setUpdatedConfigId] = useState(configID);
  const [isFilteredExactMatch, setIsFilteredExactMatch] = useState(false);
  const [mandatorySkus, setMandatorySkus] = useState([]);

  const currency = currencyCode || modelData.currency;
  const isNA = countryRegion === NA;
  const isOtherComponent = !isStandaloneAccess
    ? Boolean(filteredAccessories?.length)
    : false;
  const categoryVal = localStorage.getItem("selectedCategory");
  const handleTabChange = useCallback((_, newValue) => {
    dispatch({
      type: "SET_ACTIVE_TAB",
      payload: newValue
    });
  }, []);

  const handleComapreDialogOpen = useCallback((params = {}) => {
    dispatch(togglePopupActions({ name: "compareDialog", open: true }));
  }, []);

  const handleRskuDialogClose = useCallback(() => {
    dispatch(togglePopupActions({ name: "rskuDialog", open: false }));
    dispatch({ type: "SET_CLEAR_ALL" });
  }, []);

  const handleRskuInfoDialogOpen = useCallback((params = {}) => {
    dispatch(
      togglePopupActions({
        name: "rskuInfoDialog",
        open: true
      })
    );
  }, []);
  const handleConfirmationDialogOpen = React.useCallback((params = {}) => {
    dispatch(togglePopupActions({ name: "confirmationDialog", open: true }));
    dispatch({ type: "SET_RSKU_INFO_DIALOG_ALERT", payload: false });
  }, []);

  // middleware calls
  const [getRskuImage] = useLazyQuery(GET_RSKU_IMAGE, {
    onCompleted(response) {
      if (response?.getProductImages?.length) {
        const baseProductImages = getRskuImages(response.getProductImages);
        dispatch({
          type: "SET_RSKU_IMAGE",
          payload: baseProductImages
        });
      }
    }
  });
  const [getRskuMatch] = useLazyQuery(RSKU_MATCH, {
    onCompleted(response) {
      if (response) {
        const { exactMatchSkus, closeMatchSkus } = response.getSkuMatches;
        if (!isEmpty(exactMatchSkus)) {
          setIsExact(true);
          dispatch({ type: "SET_SKU_SERVICE_NAME", payload: "exactMatch" });
          setExactMatchData(exactMatchSkus);
        } else if (!isEmpty(closeMatchSkus)) {
          setCloseMatchData(closeMatchSkus);
          setIsSimilar(true);
          dispatch({ type: "SET_SKU_SERVICE_NAME", payload: "closeMatch" });
        } else {
          setNoMatch(true);
        }
        setShowSkeleton(false);
      }
    },
    onError({ graphQLErrors, networkError }) {
      setNoMatch(true);
      setShowSkeleton(false);
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          displayNotistack.error(false, `${message}`);
        });
      }
      if (networkError) {
        displayNotistack.error(false, t("common:table.networkErrorMsg"));
      }
    }
  });

  const [getChoiceID] = useLazyQuery(GET_CHOICEID, {
    onCompleted({ getMandatoryChoiceIDs }) {
      const filteredSku = getMandatorySkus(getMandatoryChoiceIDs, rSKUBom);
      setMandatorySkus(filteredSku);
      getRskuImage({
        variables: {
          skuNumbers: [rSKUBom[0].partNumber]
        }
      });
      getRskuMatch({
        variables: {
          filter: {
            configId: updatedConfigId.toString(),
            country: modelData?.country || productCountryCode,
            incoTerm: modelData?.incoTerm || incoterms,
            region: productRegionCode,
            numberOfRecord: 3,
            skuNumbers: [...avs, ...bomThreeCompatiblePartNumbers],
            closeMatchSKUNumbers: filteredSku,
            localization,
            currency
          }
        }
      });
    },
    onError({ graphQLErrors, networkError }) {
      setNoMatch(true);
      setShowSkeleton(false);
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          displayNotistack.error(false, `${message}`);
        });
      }
      if (networkError) {
        displayNotistack.error(false, t("common:table.networkErrorMsg"));
      }
    }
  });

  const [getCategory] = useLazyQuery(GET_DEFAULT_CONFIGURATION, {
    onCompleted({ catalogLookup }) {
      const category = catalogLookup?.data?.searchCatalog?.length
        ? catalogLookup?.data?.searchCatalog[0].category
        : "";

      dispatch({ type: "SET_SELECTED_CATEGORY", payload: category });
      getChoiceID({
        variables: {
          category
        }
      });
    },
    onError(error) {
      getChoiceID({
        variables: {
          category: categoryVal
        }
      });
    }
  });
  // hooks calls
  useEffect(() => {
    dispatch({ type: "SET_ALL_BOM_AV", payload: avs });
  }, [avs]);

  useEffect(() => {
    const {
      exactProduct,
      similarProduct,
      baseProduct,
      compareData,
      filteredBomData,
      closeMatchBomData
    } = hardwareProducts;

    dispatch({
      type: "SET_SEARCH_RSKU",
      payload: {
        exactMatch: exactProduct?.length ? exactProduct : [],
        similarMatch: similarProduct?.length ? similarProduct : [],
        compareData: compareData?.length ? compareData : [],
        baseProduct: baseProduct?.length ? baseProduct : [],
        carepack: carePackProducts,
        filteredBomData: filteredBomData?.length ? filteredBomData : [],
        closeMatchBomData: closeMatchBomData?.length ? closeMatchBomData : []
      }
    });
  }, [JSON.stringify(carePackProducts), JSON.stringify(hardwareProducts)]);

  useEffect(() => {
    const filteredBomData = getFilteredBomData(rSKUBom);
    setHardwareProducts(state => ({
      ...state,
      filteredBomData: filteredBomData
    }));

    const filteredConfig =
      configID ||
      skuConfiguration?.configId ||
      configuration?.configHeader?.configId ||
      0;
    setUpdatedConfigId(filteredConfig);
    dispatch({ type: "SET_EXACT_CLOSE_MATCH_CONFIG", payload: filteredConfig });

    isSkuCloning || isUploadConfig
      ? getCategory({
          variables: {
            filter: {
              baseUnit: rSKUBom[0].partNumber,
              countryCode: productCountryCode,
              storeFront: "IQ"
            }
          }
        })
      : getChoiceID({
          variables: {
            category: categoryVal
          }
        });
  }, []);

  useEffect(() => {
    if (closeMatchData.length) {
      // SImilar Products with 2 differences and 4 other attributes
      const closeMatchProducts = getSimilarProductData(
        currency,
        closeMatchData,
        rskuImage,
        2,
        4
      );
      // Bill of Materials
      const baseUnit = getAllBomDetails(
        rSKUBom,
        rskuImage,
        bomThreeCompatibleCarePacksInfo
      );
      // Similar Products With all attributes
      const compareData = getSimilarProductData(
        currency,
        closeMatchData,
        rskuImage
      );

      const closeMatchBomData = getCloseMatchBomData(closeMatchData);
      setHardwareProducts(state => ({
        ...state,
        similarProduct: closeMatchProducts,
        baseProduct: [baseUnit],
        compareData: compareData,
        closeMatchBomData: closeMatchBomData
      }));
    }
    if (exactMatchData.length) {
      const localizedExcatMatchProduct = exactMatchData.filter(each =>
        each.skuNumber.includes(localization)
      );
      if (localizedExcatMatchProduct.length) {
        const exactMatchProduct = getProductData(
          localizedExcatMatchProduct[0],
          rSKUBom,
          currency,
          rskuImage,
          bomThreeCompatibleCarePacksInfo
        );
        setHardwareProducts(state => ({
          ...state,
          exactProduct: exactMatchProduct
        }));
      } else {
        setIsFilteredExactMatch(true);
      }
    }
  }, [exactMatchData, closeMatchData]);

  useEffect(() => {
    if (!showSkeleton) {
      let requestRsku = isRequestRsku;
      if (isExact) {
        requestRsku = isNA ? true : false;
      } else if (isSimilar) {
        requestRsku = true;
      } else {
        requestRsku = true;
      }
      setIsRequestRsku(requestRsku);
    }
  }, [showSkeleton]);

  useEffect(() => {
    modelData.systemLocOptions.forEach(option => {
      if (option.selected && option.lclzBanding) {
        dispatch({ type: "SET_LOCALIZATION", payload: option.locOption });
        return;
      }
    });
  }, [modelData]);

  const flags = {
    isExact,
    isSimilar,
    isRequestRsku,
    isOtherComponent,
    isStandaloneAccess,
    isFilteredExactMatch,
    noMatch,
    showSkeleton
  };
  return (
    <>
      <Dialog fullScreen open={true} onClose={handleRskuDialogClose} fullWidth>
        <DialogTitle className={classes.title}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {t("common:rsku.dialog.mainTitle")}
          </Typography>
          <IconButton
            onClick={handleRskuDialogClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <RskuDialogContent
          mandatorySkus={mandatorySkus}
          addCTOToQuote={addCTOToQuote}
          handleConfirmationDialogOpen={handleConfirmationDialogOpen}
          handleRskuInfoDialogOpen={handleRskuInfoDialogOpen}
          handleComapreDialogOpen={handleComapreDialogOpen}
          handleTabChange={handleTabChange}
          flags={flags}
        />
      </Dialog>
    </>
  );
};

export default WithAddToQuote(RskuDialog);

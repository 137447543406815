const manageMyConfigColumns = [
  {
    id: "selectRow",
    label: "Select",
    minWidth: 20,
    align: "center"
  },
  {
    id: "configure",
    label: "",
    minWidth: 50,
    align: "center"
  },
  {
    id: "favorite",
    label: "common:manageMyConfig.label.favorite",
    minWidth: 50,
    align: "center"
  },
  {
    id: "configID",
    label: "common:manageMyConfig.label.configID",
    minWidth: 70,
    align: "center"
  },
  {
    id: "configName",
    label: "common:manageMyConfig.label.configName",
    minWidth: 100,
    align: "center"
  },
  {
    id: "country",
    label: "common:manageMyConfig.label.country",
    minWidth: 100,
    align: "center"
  },
  {
    id: "baseunit",
    label: "common:manageMyConfig.label.baseUnit",
    minWidth: 100,
    align: "center"
  },
  {
    id: "createdAt",
    label: "common:manageMyConfig.label.dateCreated",
    minWidth: 100,
    align: "center"
  }
];
export default manageMyConfigColumns;

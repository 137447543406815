import { useLazyQuery } from "@apollo/client";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_BOM_LIST,
  GET_CONFIG_BOM,
  UPLOAD_CONFIG_API_RESTORATION
} from "../../../GraphQL";
import ConfiguratorContainer from "../../../components/Configurator/ConfigContainer";
import { RSKU_REGIONS } from "../../../components/Configurator/Rsku/Constants";
import FullscreenModal from "../../../components/FullscreenModal";
import MyAnimation from "../../../components/MyAnimation";
import ExternalProps from "../../../contexts/externalPropsContext";
import { filteredRskuBomList } from "../../../lib/common/util";
import {
  getUISpecData,
  getUiDataFileName,
  handleAddToQuoteClick
} from "../../../services/common/utils";

const useStyles = makeStyles(theme => ({
  midPositioner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  }
}));

const CloningRsku = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(true);
  const [config, setConfig] = useState({});
  const [kmatId, setKmatId] = useState("");
  const [bomInfo, setBomInfo] = useState([]);
  const { skuIdData, category, businessModel, btoSkuInfo } = useSelector(
    state => state.productSelector
  );
  const { productCountryCode, productRegionCode } = useSelector(
    state => state.rskuReducer
  );

  const categoryFromAPI = useSelector(
    state => state.uploadConfig.categoryFromAPI
  );
  const { configId, skuId } = skuIdData;
  const {
    userType = "INTERNAL",
    routeToMarket,
    userId,
    userEmail,
    companyId,
    setCurrency,
    isStandaloneAccess,
    productCategory,
    pricingGeo,
    currencyCode,
    incoterms
  } = React.useContext(ExternalProps);
  const shipmentDate = moment(new Date()).format("YYYY-MM-DD");
  const region = productRegionCode;

  useEffect(() => {
    if (configId) {
      getConfigBom({
        variables: {
          filter: {
            configID: configId,
            country: "",
            bomformat: "volume",
            originatingAsset: "RSKU"
          }
        }
      });
    } else {
      getBomList({
        variables: {
          skuNumbers: [skuId]
        }
      });
    }
  }, []);

  useEffect(() => {
    if (kmatId?.length && bomInfo?.length) {
      cloningRskuBto({
        variables: {
          filter: {
            modelId: kmatId,
            country: productCountryCode,
            region: RSKU_REGIONS[region],
            language: "",
            mDCPOrgID: "99",
            resellerId: "",
            userType: userType,
            productType: "compute",
            shipmentDate: shipmentDate,
            originatingAsset: "RSKU",
            routeToMarket: isStandaloneAccess ? businessModel : routeToMarket,
            marketProgramType: "",
            marketProgramName: "",
            bom: bomInfo
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmatId, bomInfo]);

  const handleModalClose = () => {
    setModalOpen(false);
    dispatch({ type: "SET_CLONING_SKU", payload: false });
  };

  const [getBomList] = useLazyQuery(GET_BOM_LIST, {
    fetchPolicy: "no-cache",
    onCompleted({ getRSKUComponentList }) {
      const { errorMessage, bomList } = getRSKUComponentList;
      if (!errorMessage?.length) {
        if (bomList[0]?.bomDetail?.length) {
          const bomInfo = filteredRskuBomList(bomList[0].bomDetail, "ms4");
          const kmatId = bomList[0].kmatId;
          setKmatId(kmatId);
          setBomInfo(bomInfo);
        } else {
          handleModalClose();
        }
      } else {
        handleModalClose();
      }
    },
    onError(error) {
      handleModalClose();
    }
  });

  const [cloningRskuBto] = useLazyQuery(UPLOAD_CONFIG_API_RESTORATION, {
    fetchPolicy: "no-cache",
    onCompleted(response) {
      const modelData = response.uploadConfigRestoration.modelData;
      const { configName = "N/A", category } = modelData;
      setConfig(modelData);
      setCurrency(modelData.currency);
      localStorage.setItem(
        "configName",
        configName || btoSkuInfo[0]?.configName
      );
      async function fetchDesktopUiSpec(category) {
        let uiSpecFile = getUiDataFileName(category);
        await getUISpecData.getData(uiSpecFile);
        let configuration = handleAddToQuoteClick(
          modelData,
          configName,
          userId,
          userEmail,
          companyId,
          productCategory,
          isStandaloneAccess,
          pricingGeo,
          currencyCode,
          incoterms
        );
        configuration.configId = configId ? parseInt(configId) : "";
        configuration.configName = configName;
        configuration.configHeader.configId = configId
          ? parseInt(configId)
          : "";
        configuration.configHeader.configName = configName;
        dispatch({
          type: "SET_CONFIGURATION",
          payload: configuration
        });
        dispatch({
          type: "SET_ACCESS_TYPE",
          payload: configuration?.configHeader?.accessType?.toLowerCase()
        });
      }
      fetchDesktopUiSpec(category || "Business Desktop PCs");
    },
    onError(error) {
      handleModalClose();
    }
  });

  const [getConfigBom] = useLazyQuery(GET_CONFIG_BOM, {
    fetchPolicy: "no-cache",
    onCompleted({ getConfigBom }) {
      const { ConfigHeaderData, configBomApiProducts } = getConfigBom;
      const { kmatId } = ConfigHeaderData;
      const bomInfo = filteredRskuBomList(configBomApiProducts, "rSku");
      setKmatId(kmatId);
      setBomInfo(bomInfo);
    },
    onError(error) {
      handleModalClose();
    }
  });

  const lottieLoadingFile =
    "https://dev-ociq.hpcloud.hp.com/assets/lotties/product_selector_animation.json";

  return (
    <FullscreenModal
      isOpen={modalOpen}
      handleModalClose={() => handleModalClose()}
    >
      {isEmpty(config) ? (
        <div className={classes.midPositioner}>
          <Typography variant="h4" align="center">
            {t("common:loader.gifLoaderMessage1")}
          </Typography>
          <Typography align="center">
            {t("common:loader.gifLoaderMessage2")}
          </Typography>
          <MyAnimation
            lottieFile={lottieLoadingFile}
            style={{ height: "300px", width: "400px" }}
          />
        </div>
      ) : (
        <ConfiguratorContainer
          proCategory={category}
          config={config}
          setOpen={setModalOpen}
          categoryFromAPI={categoryFromAPI}
        />
      )}
    </FullscreenModal>
  );
};
export default CloningRsku;

import { combineReducers } from "redux";
import configFilterData from "./ConfiguratorReducers";
import manageMyConfig from "./ManageMyConfigReducers";
import productSelector from "./ProductSelectorReducers";
import rskuReducer from "./RskuReducer";
import snackbarReducer from "./SnackbarReducer";
import userReducer from "./userReducer";
import uploadConfig from "./UploadConfigReducer";

export const reducers = {
  // snackbar,
  productSelector,
  configFilterData,
  snackbarReducer,
  user: userReducer,
  manageMyConfig,
  rskuReducer,
  uploadConfig
};

const rootReducer = combineReducers(reducers);

export default rootReducer;

import React, { useEffect, useRef, useState } from "react";
import { ExternalProps } from "../contexts/externalPropsContext";

const useIsInViewport = (options, isVerticalStepper) => {
  const { isViewPortRendering = true } = React.useContext(ExternalProps);
  const [isInViewport, setIsInViewport] = useState(isViewPortRendering);
  const elementRef = useRef(null);
  const ref = elementRef.current;

  useEffect(() => {
    if (isViewPortRendering && !isVerticalStepper) {
      const observer = new IntersectionObserver(([entry]) => {
        setIsInViewport(entry.isIntersecting);
      }, options);

      if (ref) {
        observer.observe(ref);
      }

      return () => {
        if (ref) {
          observer.unobserve(ref);
        }
      };
    }
    setIsInViewport(true);
  }, [options, ref, isVerticalStepper]);

  return [elementRef, isInViewport];
};

export default useIsInViewport;

import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import LaptopMac from "@mui/icons-material/LaptopMac";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";
import green from "@mui/material/colors/green";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import { makeStyles, styled } from "@mui/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import ClipboardField from "../../../lib/common/ClipboardField";
import {
    displayStyledCurrencyLite,
    getAttributeValue
} from "../../../lib/common/util";
import CpqConfirmationDialog from "../../opportunity/CpqConfirmationDialog";

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1)
  },
  rightButton: {
    padding: "5px"
  },
  backDrop: {
    background: "rgba(0,0,0,0)"
  },
  headStyle: {
    fontSize: 15,
    fontWeight: "bold"
  },
  listHeader: {
    height: 54
  },
  listItem: {
    height: 54
  },
  notes: {
    color: "red"
  }
}));

const StyledGrid = styled(props => <Grid container spacing={1} {...props} />)({
  alignItems: "center"
});

const columns = [
  {
    id: "quantity",
    label: "Quantity",
    displayAs: "quantity",
    visible: true
  },
  {
    id: "name",
    label: "Part Number",
    displayAs: "chip",
    visible: true
  },
  {
    id: "description",
    label: "Description",
    displayAs: "description",
    visible: true
  },
  {
    id: "confirm",
    label: "",
    displayAs: "confirm",
    visible: true
  },
  {
    id: "price",
    label: "List Price",
    displayAs: "currency",
    visible: true
  },
  {
    id: "message",
    label: "Notes",
    displayAs: "notes",
    visible: true
  },
  {
    id: "override",
    label: "Override",
    displayAs: "override",
    visible: true
  }
];

export default function UploadLooseParts() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [alert, setAlert] = useState(false);

  const looseBomItems = useSelector(state => state.uploadConfig.looseBomItems);
  const rows = looseBomItems;
  const quote = useSelector(state => state.quote);
  const quoteRecord = quote.record || {};
  const currencyCode = quoteRecord.hpQuoteNumber_quote
    ? quote.currency
      ? quote.currency.value
      : ""
    : getAttributeValue("attribute-currency");
  //FIXME:
  const handleDelete = () => {};
  const handleConfirm = () => {};
  const handleOverride = () => {
    setChecked(true);
    setAlert(true);
  };
  const handleCancel = () => {
    setChecked(false);
    setAlert(false);
  };

  return (
    <Fragment>
      <Typography variant="h5" gutterBottom style={{ marginTop: "42px" }}>
        {"Adhoc/Loose Parts"}
      </Typography>
      <Box className="MuiPaper-outlined MuiPaper-rounded" component={Paper}>
        <List disablePadding>
          <ListItem
            className={classes.listHeader}
            alignItems="center"
            disableGutters={false}
          >
            <Grid container spacing={1}>
              {columns.map((column, index) => (
                <Grid
                  key={column.label+index}
                  item
                  xs={column.displayAs === "description" ? 4 : 2}
                  md={
                    column.displayAs === "description"
                      ? 4
                      : column.displayAs === "chip"
                      ? 2
                      : column.displayAs === "notes"
                      ? 2
                      : 1
                  }
                >
                  <Typography
                    variant="subtitle2"
                    align={
                      column.name || column.message || column.price
                        ? "right"
                        : "left"
                    }
                  >
                    <Box fontWeight="fontWeightBold" m={1}>
                      {column.label}
                    </Box>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </ListItem>
          <Divider />
        </List>

        {rows.map((item, rowIndex) => (
          <Fragment>
            <ListItem
              key={item.name}
              alignItems="center"
              className={classes.listItem}
            >
              <StyledGrid>
                {columns.map(
                  (column, index) =>
                    column.visible && (
                      <Grid
                        item
                        key={item.name+index}
                        xs={column.displayAs === "description" ? 4 : 2}
                        md={
                          column.displayAs === "description"
                            ? 4
                            : column.displayAs === "chip"
                            ? 2
                            : column.displayAs === "notes"
                            ? 2
                            : 1
                        }
                      >
                        {column.displayAs === "chip" ? (
                          <Typography variant="subtitle2">
                            <ClipboardField
                              style={{
                                backgroundColor: "#e0e0e0",
                                padding: 5,
                                borderColor: "#e0e0e0",
                                textTransform: "uppercase"
                              }}
                              text={item.name}
                              icon={<LaptopMac />}
                              size="medium"
                              color="default"
                              variant="contained"
                              partNumber={item.name}
                            />
                          </Typography>
                        ) : column.displayAs === "override" ? (
                          rowIndex === 0 && (
                          <fragment>
                            <Switch
                              name="Override"
                              checked={checked}
                              onChange={handleOverride}
                              color="primary"
                            />
                            <CpqConfirmationDialog
                              isOpen={alert}
                              handleCancel={handleCancel}
                              handleConfirm={handleConfirm}
                            />
                          </fragment>
                          )
                        ) : column.displayAs === "confirm" ? (
                          <IconButton color="primary" aria-label="delete" onClick={handleDelete} size="large">
                            {item.status === "added" ? (
                              <AddIcon color="primary" />
                            ) : item.status === "check" ? (
                              <CheckIcon style={{ color: green[500] }} />
                            ) : item.status === "delete" ? (
                              <DeleteIcon color="action" />
                            ) : item.status === "removed" ? (
                              <RemoveIcon />
                            ) : (
                              <CloseIcon />
                            )}
                          </IconButton>
                        ) : column.displayAs === "currency" ? (
                          <Typography
                            color={"primary"}
                            variant="subtitle2"
                            align={"right"}
                            style={{ paddingLeft: 10 }}
                          >
                            {displayStyledCurrencyLite({
                              amount: `${item.price}`,
                              currencyArg: currencyCode,
                              locale: "en-US"
                            })}
                          </Typography>
                        ) : (
                          <Typography
                            className={
                              column.displayAs === "notes" ? classes.notes : ""
                            }
                            color={"textPrimary"}
                            variant="subtitle2"
                            align={
                              column.displayAs === "quantity"
                                ? "center"
                                : "left"
                            }
                            style={{ paddingLeft: 10 }}
                          >
                            {item[column.id]}
                          </Typography>
                        )}
                      </Grid>
                    )
                )}
              </StyledGrid>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </Box>
    </Fragment>
  );
}
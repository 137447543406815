import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepConnector from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CONFIG_STEP_SELECTION,
  LOAD_CONFIG_STEPPER,
  SET_CONFLICT_ITEMS,
  SET_SEARCHED_TEXT,
  SET_VERTICAL_SCROLLTO_CHAID,
  UPDATE_SCROLL_FILTER_CHOICE
} from "../../../actions/Types";
import ExternalProps from "../../../contexts/externalPropsContext";
import {
  checkStoreFront,
  getHighestDate,
  scrollToChoice
} from "../../../lib/common/util";
import { getConfigBom } from "../../../services/bomAndCarePackLib/utils";
import {
  checkGaDateWithinRange,
  compare,
  fetchUiSpecVerticalView,
  getConfigData,
  getLocalizedMandaCPItem,
  resolveChaidsVisible,
  shouldHideChoices,
  shouldShowLeastPriorityConflicts,
  validateStepper,
  validateWarning
} from "../../../services/common/utils";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "2%",
    textAlign: "center",
    "& > div.MuiPaper-root": {
      backgroundColor: "transparent"
    }
  },
  iconSize: {
    width: "32px",
    height: "32px",
    color: "#0096d7"
    //transform: "scale(1.34)"
  },
  labelBox: {
    width: "auto",
    position: "absolute",
    top: 10,
    margin: "0px -7px 0px -7px !important",
    "& > span.MuiStepLabel-labelContainer .MuiStepLabel-alternativeLabel": {
      marginTop: "1px"
    },
    "& $span": {
      marginTop: "0px !important"
    }
  },
  iconSizeError: {
    width: "32px",
    height: "32px"
  },
  warningIcon: {
    width: "32px",
    height: "32px",
    color: "#ffbf00"
  },
  labelStepper: {
    cursor: "pointer",
    color: "#000000",
    fontSize: "16px",
    marginLeft: "13px",
    marginRight: "13px"
  },
  activeLabelStepper: {
    color: "#0096d7",
    cursor: "default",
    fontSize: "16px",
    marginLeft: "13px",
    marginRight: "13px"
  },
  labelStepperError: {
    color: "rgb(214,0,42)",
    cursor: "default",
    fontWeight: "bold",
    fontSize: "16px",
    marginLeft: "13px",
    marginRight: "13px"
  },
  labelStepperWarning: {
    color: "#ffbf00",
    cursor: "default",
    fontSize: "16px"
  },
  buttonStepper: {
    cursor: "pointer"
  },
  activeButtonStepper: {
    cursor: "default"
  },
  internalAndexternal: {
    fontSize: "15px !important",
    position: "absolute",
    left: "858px",
    top: "48px"
  },
  internalCls: {
    color: "#0096d7",
    paddingRight: "30px",
    cursor: "default",
    fontSize: "14px !important"
  },
  externalCls: {
    color: "#0096d7",
    paddingLeft: "30px",
    cursor: "default",
    fontSize: "14px !important"
  },
  internalActiveCls: {
    color: "#000000",
    fontSize: "14px !important"
  },
  externalActiveCls: {
    color: "#000000",
    cursor: "pointer",
    fontSize: "14px !important"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: "inline-block"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  errorPlaceholder: {
    marginTop: "60px",
    textAlign: "center",
    "& $a": {
      color: "red",
      textDecoration: "none"
    }
  },
  errorColor: {
    color: "rgb(214,0,42)",
    fontSize: "18px"
  },
  errorMessage: {
    display: "inline-block",
    cursor: "pointer",
    justifyContent: "center",
    "& $p": {
      fontSize: "18px !important"
    }
  },
  GADateMessage: {
    color: "#0096d7",
    fontSize: "18px"
  }
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    position: "absolute",
    margin: "0px -7px 0px -7px !important",
    width: "auto",
    color: "#0096d7",
    active: {
      color: "#0096d7"
    }
  },
  active: {
    "& $line": {
      color: "#0096d7"
    }
  },
  completed: {
    "& $line": {
      color: "#0096d7"
    }
  },
  line: {
    height: "4px",
    border: 0,
    backgroundColor: "#0096d7",
    borderRadius: 0
  }
})(StepConnector);

//------below method not used-----
// function getConfig() {
//   let rootEle = document.getElementById("product-selector");
//   if (!rootEle) {
//     rootEle = document.getElementById("configurator");
//   }
//   let config = getStorageValue("config");
//   if (rootEle.dataset.configsettings) {
//     config = JSON.parse(rootEle.dataset.configsettings);
//   }
//   return config || {};
// }

function focusNextError(filteredChoices, modelData, defaultConfigStepSlected) {
  const chaids = filteredChoices.map(choice => choice.chaId);
  const conflicts = modelData?.conflicts[0]?.icConflicts.filter(
    x => x.code === "ERR04" || x.code === "ERR03" || x.code === "ERR10"
  );

  if (
    modelData?.conflicts[0]?.itemConflictsList &&
    modelData?.conflicts[0]?.itemConflictsList[0]?.cause &&
    conflicts.indexOf(modelData?.conflicts[0]?.itemConflictsList[0]?.cause) ===
      -1
  ) {
    conflicts.push(modelData?.conflicts[0]?.itemConflictsList[0]?.cause);
  }
  conflicts.sort((a, b) => chaids.indexOf(a.chaId) - chaids.indexOf(b.chaId));
  const first = conflicts[0];
  if (first) scrollToChoice({ chaId: first.chaId, defaultConfigStepSlected });
}

export default function ConfigStepper(props) {
  const { proCategory } = props;
  const {
    clientOrigin,
    dataType,
    configOrigin,
    isAutoScrollEnabled = true,
    isSKUModule,
    noConfigServices,
    noCustomerInfo
  } = React.useContext(ExternalProps);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [currentErr, setCurrentErr] = useState([]);
  const [restOfErr, setRestOfErr] = useState([]);
  const [showStepperError, setShowStepperError] = useState(null);
  const [carePacksErr, setCarePacksErr] = useState();
  const [isFocusErrorChaid, setIsFocusErrorChaid] = useState(false);
  const modelData = useSelector(state => state.configFilterData.modelData);
  const filteredChoices = useSelector(
    state => state.configFilterData.filteredChoices
  );

  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );

  const checkedFilterParts = useSelector(
    state => state.configFilterData.checkedFilterParts
  );
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const showBOMSectionFlag = useSelector(
    state => state.configFilterData.showBOMSectionFlag
  );
  const steppers = useSelector(state => state.configFilterData.steppers);
  const showGADate = modelData.showGADate;
  const isConfigOverDate = useSelector(
    state => state.configFilterData.isConfigOverDate
  );
  const currentConfigID = useSelector(
    state => state.productSelector.currentConfigID
  );
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const data = getConfigData(modelData, defaultConfigStepSlected);
  const chaidList = data.map(chaid => chaid.chaId);
  const [visibilityTabs, setVisibilityTabs] = useState({
    hideConfigurationServicesTab: false,
    hideManageabilityServicesTab: false,
    hideMonitorsAccessoriesTab: false,
    hideHardwareTab: false,
    hideCarePacksTab: false,
    hideDeploymentServicesTab: false,
    hideOtherServicesTab: false
  });
  const { haveFutureItem, maxGaDate } = getConfigBom(modelData, {
    currentConfigID,
    showGADate,
    isConfigOverDate
  });

  const chaids = filteredChoices.map(choice => choice.chaId);
  const searchText = useSelector(state => state.configFilterData.searchText);
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected);
  const conflictsTimeout = useRef();
  const scrollTimer = useRef();
  const showFutureGADateMsg = false; //TODO: Hardcoded to turn off the feature #6292

  const monitorBomSequence = () => {
    if (modelData) {
      let tabChaidsList = window.uiSpecData;
      let nonSortedArray = [];
      let indexOfMonitor =
        tabChaidsList.UISpec[0]?.sections?.findIndex(
          section => section.id === "monitoringAccesories"
        ) || -1;
      if (isVerticalView === "N") {
        if (indexOfMonitor > -1) {
          nonSortedArray = tabChaidsList.UISpec[0]?.sections[
            indexOfMonitor
          ]?.subsections[0]?.choices?.filter(
            item => item.id === "ASCM_3242660" || item.id === "ASCM_64283"
          );
          tabChaidsList.UISpec[0]?.sections[
            indexOfMonitor
          ]?.subsections[0]?.choices?.forEach(
            item =>
              item.id.split("_")[1].startsWith("POLY") &&
              nonSortedArray.push(item)
          );

          let choicesWithRecommendedItems = [];
          let choicesWithNonRecommendedItems = [];

          tabChaidsList.UISpec[0]?.sections[
            indexOfMonitor
          ]?.subsections[0]?.choices
            .filter(
              choice =>
                !["ASCM_3242660", "ASCM_64283"].includes(choice.id) &&
                !choice.id.split("_")[1].startsWith("POLY")
            )
            .map(choice =>
              modelData.Chaids.filter(chaid => {
                let isRecommendedItems = false;
                if (chaid.chaId === choice.id) {
                  isRecommendedItems = modelData.Items.some(
                    (item, index) =>
                      index >= chaid.firstItem &&
                      index <= chaid.lastItem &&
                      Number(item.priority) >= 1 &&
                      Number(item.priority) <= 5
                  );
                  if (isRecommendedItems) {
                    choicesWithRecommendedItems.push(choice);
                  } else {
                    choicesWithNonRecommendedItems.push(choice);
                  }
                }
              })
            );
          choicesWithRecommendedItems = choicesWithRecommendedItems.sort(
            (a, b) => (a.title > b.title ? 1 : -1)
          );
          choicesWithNonRecommendedItems = choicesWithNonRecommendedItems.sort(
            (a, b) => (a.title > b.title ? 1 : -1)
          );

          let finalSortedArray = [
            ...nonSortedArray,
            ...choicesWithRecommendedItems,
            ...choicesWithNonRecommendedItems
          ];
          finalSortedArray.map(
            (value, index) => (value.sequence = (index + 1) * 5)
          );
          window.uiSpecData.UISpec[0].sections[
            indexOfMonitor
          ].subsections[0].choices = finalSortedArray;
        }
      }
    }
  };

  useEffect(() => {
    if (!checkStoreFront(modelData)) {
      let additionItems = [];
      let topRecommendedItems = [];
      let additionItemsPartNo = [];
      let topRecommendedItemsPartNo = [];
      setCarePacksErr("");
      filteredChoices.forEach((chaid, index) => {
        if (chaid) {
          if (
            chaid.chaId.includes("ELECTCPQ") &&
            !shouldHideChoices(
              ocfgParams?.hideChoice,
              chaid.chaId,
              modelData
            ) &&
            chaid.visible
          ) {
            topRecommendedItems = [
              ...chaid.items.filter(item => item.priority <= 5 && item.visible)
            ];
            additionItems = [
              ...chaid.items.filter(item => item.priority > 5 && item.visible)
            ];

            topRecommendedItemsPartNo =
              topRecommendedItems &&
              topRecommendedItems.map(item => item.partno);
            additionItemsPartNo =
              additionItems && additionItems.map(item => item.partno);
          }
        }
        const conflictFilterPartNo = conflictFilter.map(item => item.partNo);
        if (
          conflictFilterPartNo.some(item =>
            topRecommendedItemsPartNo.includes(item)
          )
        ) {
          setCarePacksErr("topRecommend");
        }
        if (
          conflictFilterPartNo.some(item => additionItemsPartNo.includes(item))
        )
          setCarePacksErr("additional");
      });
    }
  }, [filteredChoices]);

  useEffect(() => {
    if (ocfgParams?.hideTab) {
      const hideTabs = ocfgParams.hideTab;
      for (const hideTab in hideTabs) {
        setVisibilityTabs(visibilityTabs => {
          return {
            ...visibilityTabs,
            [hideTab]: hideTabs?.[hideTab] === "Y"
          };
        });
      }
    }
    if (ocfgParams?.hideChoice) {
      resolveChaidsVisible(ocfgParams.hideChoice, modelData);
    }
    monitorBomSequence();
  }, [ocfgParams]);

  useEffect(() => {
    let tabChaidsList;
    if (modelData) {
      tabChaidsList = window.uiSpecData;
      let stepperData = tabChaidsList.UISpec[0].sections;
      const filterChaids = accordions => {
        let filteredChaids =
          modelData &&
          modelData.Chaids &&
          modelData.Chaids.filter(
            chaid =>
              (chaid.precon >= 0 ||
                chaid.isOverridePrecon ||
                chaid.required ||
                chaid.selcon) &&
              chaid.visible &&
              accordions &&
              accordions.some(
                acc => acc.id === chaid.chaId && acc.id !== "CS_C"
              )
          ).filter(chaid =>
            chaid.required ||
            chaid.selcon ||
            modelData.Items.some(
              part =>
                part.partno !== "" &&
                part.visible === true &&
                part.inode >= chaid.firstItem &&
                part.inode <= chaid.lastItem
            )
              ? (chaid.isUsed = true)
              : (chaid.isUsed = false)
          );
        return filteredChaids || [];
      };
      stepperData.forEach(stepper => {
        // TODO: for now 'isComplete is set to true directly,
        // once the validation is integrated then this needs to be updated
        stepper["isComplete"] = validateStepper(modelData, stepper);
        stepper["isWarning"] = validateWarning(
          stepper,
          modelData,
          defaultConfigStepSlected
        );
        const tabsMap = {
          hardware: "hideHardwareTab",
          monitoringAccesories: "hideMonitorsAccessoriesTab",
          carePacks: "hideCarePacksTab",
          configurationService: "hideConfigurationServicesTab",
          deploymentServices: "hideDeploymentServicesTab",
          manageabilityService: "hideManageabilityServicesTab",
          otherServices: "hideOtherServicesTab"
        };
        const hideCurrentTab = visibilityTabs[tabsMap[stepper.id]];
        if (hideCurrentTab) {
          stepper.doNotDisplay = hideCurrentTab;
        } else {
          let result;
          if (stepper.isVerticalView === "Y") {
            let accordions = [];
            stepper?.subsections?.forEach(subsection => {
              subsection.choices.forEach(choice => {
                accordions.push({
                  id: choice.id,
                  title: choice.title,
                  uiControlType: choice.uiControlType,
                  sequence: choice.sequence,
                  itemSequenceBy: choice.itemSequenceBy
                });
              });
            });
            result = filterChaids(accordions);
          } else {
            let accordions = [...stepper?.subsections[0]?.choices];
            result = filterChaids(accordions);
          }
          stepper.doNotDisplay = result.length === 0;
        }
      });

      Object.keys(stepperData).forEach(function (key) {
        if (stepperData[key].id == "hardware") {
          stepperData[key].title = t("common:stepperTabs.hardware");
        } else if (stepperData[key].id == "configurationService") {
          stepperData[key].title = t("common:stepperTabs.configurationSrc");
        } else if (stepperData[key].id == "deploymentServices") {
          stepperData[key].title = t("common:stepperTabs.deploymentSrc");
        } else if (stepperData[key].id == "monitoringAccesories") {
          stepperData[key].title = t("common:stepperTabs.monitor&accessories");
        } else if (stepperData[key].id == "carePacks") {
          stepperData[key].title = t("common:stepperTabs.carePacks");
        } else if (stepperData[key].id == "otherServices") {
          stepperData[key].title = t("common:stepperTabs.otherSrc");
        }
      });

      dispatch({
        type: LOAD_CONFIG_STEPPER,
        steppers: stepperData
      });
    }

    if (steppers && steppers[activeStep].doNotDisplay) {
      setActiveStep(0);
      dispatch({
        type: CONFIG_STEP_SELECTION,
        selectedStep: steppers[0].id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData, visibilityTabs]);

  const handleStep = stepIndex => () => {
    if (stepIndex !== activeStep) {
      dispatch({ type: SET_SEARCHED_TEXT, payload: "" });
    }
    if (activeStep === stepIndex) {
      focusNextError(filteredChoices, modelData, defaultConfigStepSlected);
    }
    setActiveStep(stepIndex);
    if (stepIndex !== activeStep) {
      dispatch({
        type: CONFIG_STEP_SELECTION,
        selectedStep: steppers[stepIndex].id
      });
    }
    const stepperList = steppers?.map(stepper => {
      if (stepper.isWarning) return stepper.id;
    });
    props.setStepperList(stepperList);
  };

  const renderWarningIcon = (title, index) => {
    props.getStepDetails(title);
    return <ErrorOutlineIcon className={classes.warningIcon} label={index} />;
  };

  const chaidMap = [];
  for (let choice of filteredChoices) {
    chaidMap[choice.chaId] = choice.chaDes;
  }
  let conflicts = [];
  let plcConflicts = [];
  let gaDateConflicts = [];
  let counterConflicts = [];
  if (
    modelData?.conflicts &&
    modelData?.conflicts.length &&
    modelData?.conflicts[0].icConflicts
  ) {
    conflicts = modelData.conflicts[0].icConflicts.filter(
      icConflict =>
        icConflict.code === "ERR04" ||
        icConflict.code === "ERR03" ||
        icConflict.code === "ERR10"
    );

    counterConflicts = modelData.conflicts[0]?.counterConflicts?.filter(
      icConflict => icConflict.code === "ERR07"
    );

    plcConflicts = modelData.conflicts[0]?.plcConflicts?.filter(
      plcConflict => plcConflict.code === "ERR06"
    );

    gaDateConflicts = modelData.conflicts[0]?.gaDateConflicts?.filter(
      plcConflict => plcConflict.code === "ERR10"
    );
    conflicts = [
      ...conflicts,
      ...plcConflicts,
      ...gaDateConflicts,
      ...counterConflicts
    ];
  }
  if (
    modelData &&
    modelData?.conflicts &&
    modelData?.conflicts[0]?.itemConflicts?.effect &&
    modelData?.conflicts[0].itemConflicts.effect[0] &&
    conflicts.indexOf(modelData.conflicts[0].itemConflicts.effect[0]) === -1
  ) {
    conflicts.push(modelData.conflicts[0].itemConflicts.effect[0]);
  }
  if (
    modelData &&
    modelData?.conflicts &&
    modelData?.conflicts[0] &&
    modelData?.conflicts[0].counterConflicts[0] &&
    conflicts.indexOf(modelData.conflicts[0].counterConflicts[0]) === -1
  ) {
    conflicts.push(modelData.conflicts[0].counterConflicts[0]);
  }
  if (
    modelData &&
    modelData?.conflicts[0]?.hiddenChoiceConflicts &&
    modelData?.conflicts[0]?.itemConflictsList?.length === 0 &&
    modelData?.conflicts[0]?.plcConflicts?.length === 0 &&
    modelData?.conflicts[0]?.counterConflicts?.length === 0 &&
    modelData?.conflicts[0]?.hiddenChoiceConflicts[0]?.cause &&
    conflicts.indexOf(modelData.conflicts[0].hiddenChoiceConflicts[0].cause) ===
      -1
  ) {
    conflicts.push(modelData?.conflicts[0]?.hiddenChoiceConflicts[0]?.cause);
  }
  if (
    modelData?.conflicts[0]?.choiceConflicts &&
    modelData?.conflicts[0]?.choiceConflicts[0]?.effectChoice &&
    conflicts.indexOf(
      modelData.conflicts[0].choiceConflicts[0].effectChoice
    ) === -1
  ) {
    conflicts.push({
      ...modelData?.conflicts[0]?.choiceConflicts[0]?.effectChoice,
      code: modelData?.conflicts[0]?.choiceConflicts[0]?.code
    });
  }
  if (defaultConfigStepSlected === "carePacks") {
    const mandCarepacks = [];
    const otherCarepacks = [];
    let chaid = [];
    data?.forEach(chaid => {
      if (chaid && chaid.chaId.startsWith("CAPQ_")) {
        const item = getLocalizedMandaCPItem(modelData, chaid);
        chaid.priority = Number(item?.priority);
        mandCarepacks.push(chaid);
      } else {
        otherCarepacks.push(chaid);
      }
    });
    mandCarepacks.sort(compare);

    chaid = [...otherCarepacks, ...mandCarepacks];
    chaid = chaid.map(choice => choice.chaId);
    if (conflicts.length > 0 && chaid.length > 0) {
      conflicts.sort((a, b) => chaid.indexOf(a.chaId) - chaid.indexOf(b.chaId));
    }
  } else {
    if (conflicts.length > 0 && chaidList.length > 0) {
      conflicts.sort(
        (a, b) => chaidList.indexOf(a.chaId) - chaidList.indexOf(b.chaId)
      );
    }
  }

  const conflictFilter =
    steppers?.reduce((stepperAcc, step) => {
      if (step.id === defaultConfigStepSlected) {
        const filteredConflicts = conflicts?.reduce((conflictAcc, conflict) => {
          // stepperAcc - Stands for Stepper Accumaulator
          // conflictAcc - Stands for Conflict Accumulator
          if (
            defaultConfigStepSlected === "hardware" &&
            data.some(configData => configData.chaId === conflict.chaId)
          ) {
            conflictAcc = [...conflictAcc, conflict];
          }

          if (
            isVerticalView === "Y" &&
            step?.subsections?.some(choice =>
              choice.choices?.some(choice => choice.id === conflict.chaId)
            )
          ) {
            conflictAcc = [...conflictAcc, conflict];
          }
          if (defaultConfigStepSlected === "carePacks") {
            const newConflicts = step.subsections.reduce((pre, cur) => {
              if (
                cur.subsections?.some(choice => choice.id === conflict.chaId)
              ) {
                return [...pre, conflict];
              }
              return pre;
            }, []);
            conflictAcc = [...conflictAcc, ...newConflicts];
          }

          if (
            step.subsections.some(choice =>
              choice.choices.some(choice => choice.id === conflict.chaId)
            )
          ) {
            conflictAcc = [...conflictAcc, conflict];
          }

          return conflictAcc;
        }, []);
        stepperAcc = [...stepperAcc, ...filteredConflicts];
      }
      return stepperAcc;
    }, []) || [];

  useEffect(() => {
    if (conflictFilter.length > 0 && !checkedFilterParts) {
      clearTimeout(conflictsTimeout.current);
      conflictsTimeout.current = setTimeout(() => {
        if (expandAll.length > 0 && defaultConfigStepSlected) {
          dispatch({
            type: SET_CONFLICT_ITEMS,
            payload: conflictFilter
          });
          const relevantExpandData = expandAll.find(
            expandedData => expandedData.tab === defaultConfigStepSlected
          );
          if (
            relevantExpandData &&
            relevantExpandData.choicesExpandState &&
            !searchText
          ) {
            if (
              relevantExpandData.choicesExpandState[conflictFilter[0].chaId] ===
              false
            ) {
              relevantExpandData.choicesExpandState[conflictFilter[0].chaId] =
                isFocusErrorChaid ? true : isAutoScrollEnabled;
              const payload = {
                tab: defaultConfigStepSlected,
                choicesExpandState: relevantExpandData.choicesExpandState
              };
              dispatch({
                type: UPDATE_SCROLL_FILTER_CHOICE,
                payload
              });
            }
          }
        }
        setIsFocusErrorChaid(false);
      }, 500);
    }
  }, [conflictFilter]);
  const focusErrorChaid = function (chaId, carePacksErr) {
    setIsFocusErrorChaid(true);
    if (isVerticalView === "Y") {
      dispatch({
        type: SET_VERTICAL_SCROLLTO_CHAID,
        payload: chaId
      });
      clearTimeout(scrollTimer.current);
      scrollTimer.current = setTimeout(() => {
        scrollToChoice({
          chaId,
          iNodeORCarepacksErr: carePacksErr,
          defaultConfigStepSlected
        });
      }, 800);
    } else {
      scrollToChoice({
        chaId,
        iNodeORCarepacksErr: carePacksErr,
        defaultConfigStepSlected
      });
    }
  };
  const currentStepError = (title, index) => {
    props.getStepDetails(title);
    let conflictFilterErrorMessages = [];
    //Choice conflicts being given last priority
    if (conflictFilter.some(item => item?.code !== "ERR08")) {
      conflictFilterErrorMessages = conflictFilter.filter(
        item => item?.code !== "ERR08"
      );
      setShowStepperError(false);
    } else {
      conflictFilterErrorMessages = shouldShowLeastPriorityConflicts(modelData)
        ? conflictFilter
        : [];
      setShowStepperError(!conflictFilterErrorMessages.length > 0);
    }
    return conflictFilterErrorMessages.map(item => {
      return (
        <div
          className={classes.errorMessage}
          key={item.chaId}
          onClick={() => focusErrorChaid(item.chaId, carePacksErr)}
        >
          <Box display="flex" justifyContent="center">
            <Typography style={{ fontSize: "18px" }}>
              {t("common:configurator:pleaseCheck")}
            </Typography>
            &nbsp;
            <Typography className={classes.errorColor} key={item.chaId}>
              {carePacksErr === "topRecommend" &&
              defaultConfigStepSlected === "carePacks"
                ? "Top Recommended Care Packs"
                : carePacksErr === "additional" &&
                  defaultConfigStepSlected === "carePacks"
                ? "Additional Care Packs"
                : item.chaId.includes("CAPQ") &&
                  !item.chaId.startsWith("CAPQ_P")
                ? t("common:configurator:plcConflictManda") + " " + item.chaDes
                : item.chaId.startsWith("CAPQ_P")
                ? t("common:stepperTabs:carePacksSrc:polySF") +
                  " " +
                  item.chaDes
                : item.chaDes}
            </Typography>
            &nbsp;
            <Typography style={{ fontSize: "18px" }}>
              {t("common:configurator:selectionToResolveIssue")}
            </Typography>
          </Box>
        </div>
      );
    })[0];
  };

  useEffect(() => {
    if (steppers) {
      let currentErrorSet = [];
      let restOfErrorSet = [];
      steppers.forEach((data, index) => {
        if (data.id === defaultConfigStepSlected) {
          const stepProps = {};
          const buttonProps = {};
          if (!data.isComplete) {
            stepProps.error = "";
            buttonProps.error = "";
            buttonProps.erroricon = "";
            if (!data.doNotDisplay) {
              currentErrorSet.push(currentStepError(data.id, index));
            }
          }
        } else {
          const stepProps = {};
          const buttonProps = {};
          if (!data.isComplete) {
            stepProps.error = "";
            buttonProps.error = "";
            buttonProps.erroricon = "";
          }
          if (!data.doNotDisplay && !data.isComplete) {
            restOfErrorSet.push(data);
          }
        }
      });
      setCurrentErr(currentErrorSet);
      setRestOfErr(restOfErrorSet);
    }
  }, [
    steppers,
    defaultConfigStepSlected,
    modelData,
    filteredChoices,
    carePacksErr
  ]);
  useEffect(() => {
    if (steppers) {
      steppers.forEach(data => {
        if (data.id === defaultConfigStepSlected) {
          data.isComplete = showStepperError;
        }
      });
    }
  }, [showStepperError]);
  const highestDate = getHighestDate(props.configStartDate, maxGaDate);
  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        className={classes.stepper}
      >
        {steppers &&
          steppers.map((data, index) => {
            const stepProps = {};
            const stepLabelProps = {};
            const buttonProps = {};
            if (!data.isComplete) {
              stepProps.error = "";
              stepLabelProps.error = true;
              buttonProps.error = "";
              buttonProps.erroricon = "";
            }
            const isDisplay = data.doNotDisplay || proCategory === "poly";
            return !isDisplay ? (
              <Step key={data.id} {...stepProps}>
                <StepButton
                  className={
                    activeStep === index && !data.isComplete
                      ? classes.buttonStepperError
                      : activeStep === index
                      ? classes.activeButtonStepper
                      : classes.buttonStepper
                  }
                  onClick={handleStep(index)}
                  icon={
                    !data.isComplete ? (
                      <CancelOutlinedIcon
                        color="error"
                        className={classes.iconSizeError}
                        label={index}
                      />
                    ) : data.isWarning && showBOMSectionFlag ? (
                      renderWarningIcon(data.id, index)
                    ) : (
                      <CheckCircleOutlineIcon
                        className={classes.iconSize}
                        label={index}
                      />
                    )
                  }
                  disableRipple
                  {...buttonProps}
                >
                  <StepLabel {...stepLabelProps} className={classes.labelBox}>
                    {!data.isComplete && activeStep === index ? (
                      <Typography
                        title={data.title}
                        className={classes.labelStepperError}
                      >
                        {" "}
                        {data.title}
                      </Typography>
                    ) : data.isWarning && showBOMSectionFlag ? (
                      <Typography
                        title={data.title}
                        className={classes.labelStepperWarning}
                      >
                        {" "}
                        {data.title}
                      </Typography>
                    ) : activeStep === index ? (
                      <Typography
                        title={data.title}
                        className={classes.activeLabelStepper}
                      >
                        {" "}
                        {data.title}
                      </Typography>
                    ) : (
                      <Typography
                        title={data.title}
                        className={classes.labelStepper}
                      >
                        {" "}
                        {data.title}
                      </Typography>
                    )}
                  </StepLabel>
                </StepButton>
              </Step>
            ) : null;
          })}
      </Stepper>
      <br />
      <div className={classes.root}>
        {gaDateConflicts?.length === 0 &&
          ((currentConfigID && props.configStartDate) ||
            dataType === "upload-config") &&
          defaultConfigStepSlected === "hardware" &&
          ((showGADate && isConfigOverDate) || haveFutureItem) && (
            <>
              <Box display="flex" justifyContent="center">
                <Typography style={{ fontSize: "18px" }}>
                  {t("common:GADate.text1").replace(
                    "{0}",
                    moment(highestDate).format("DD MMMM YYYY")
                  )}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography style={{ fontSize: "18px" }}>
                  {clientOrigin === "BMI" &&
                  !checkGaDateWithinRange(highestDate)
                    ? null
                    : t("common:GADate.text2")}
                </Typography>
              </Box>
            </>
          )}

        {configOrigin === "OCA" &&
          showBOMSectionFlag &&
          isSKUModule &&
          noConfigServices && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "18px" }}>
                {t("common:skuModule.configServiceOne")}
              </Typography>{" "}
              &nbsp;
              <Typography className={classes.errorColor}>
                {t("common:skuModule.configServiceTwo")}
              </Typography>{" "}
              &nbsp;
              <Typography style={{ fontSize: "18px" }}>
                {t("common:skuModule.configServiceThree")}
              </Typography>
            </Box>
          )}

        {configOrigin === "OCA" &&
          showBOMSectionFlag &&
          isSKUModule &&
          noCustomerInfo && (
            <Box display="flex" justifyContent="center">
              <Typography style={{ fontSize: "18px" }}>
                {t("common:skuModule.customerInfoOne")}
              </Typography>{" "}
              &nbsp;
              <Typography className={classes.errorColor}>
                {t("common:skuModule.customerInfoTwo")}
              </Typography>{" "}
              &nbsp;
              <Typography style={{ fontSize: "18px" }}>
                {t("common:skuModule.customerInfoThree")}
              </Typography>
            </Box>
          )}
        {configOrigin === "OCA" && showBOMSectionFlag && haveFutureItem && (
          <Box display="flex" justifyContent="center">
            <Typography className={classes.GADateMessage}>
              {showFutureGADateMsg && !isSKUModule
                ? t("common:configurator:futureGADateInfo1")
                : t("common:configurator:futureGADateSKU1")}
              <br />
              {showFutureGADateMsg && !isSKUModule
                ? t("common:configurator:futureGADateInfo2")
                : t("common:configurator:futureGADateSKU2")}
            </Typography>
          </Box>
        )}

        {(currentErr.length > 0 || restOfErr.length > 0) &&
        proCategory !== "poly" ? (
          <Box display="flex" justifyContent="center">
            <Typography style={{ fontSize: "18px" }}>
              {t("common:configurator:issueWithCurrent")}
            </Typography>
          </Box>
        ) : null}
        {proCategory !== "poly" &&
          currentErr.map(errP => {
            return errP;
          })}
        {restOfErr.length > 0 ? (
          <Box display="flex" justifyContent="center">
            <Typography style={{ fontSize: "18px" }}>
              {t("common:configurator:pleaseCheck")}{" "}
            </Typography>
            &nbsp;
            {restOfErr.map((data, index) => {
              return (
                <Typography className={classes.errorColor} key={index}>
                  {data && data?.title
                    ? data.title +
                      (index === restOfErr.length - 1
                        ? ""
                        : index === restOfErr.length - 2
                        ? " and"
                        : ", ")
                    : ""}
                  &nbsp;
                </Typography>
              );
            })}
            &nbsp;
            <Typography style={{ fontSize: "18px" }}>
              {" "}
              {t("common:configurator:tabForOtherIssue")}
            </Typography>
          </Box>
        ) : null}
      </div>
    </div>
  );
}

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_CHOICE_EXPAND } from "../actions/Types";
import ChaidSelectionType from "./ChaidSelectionType";
import { fetchUiSpecVerticalView } from "../services/common/utils";
import ErrorPart from "./ConflictsError";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: "400"
  },
  choiceLevelMarginTop: {
    marginTop: "16px"
  },
  accordian: {
    width: "100%",
    fontSize: "0.75rem",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important"
  },
  accordianDetails: {
    display: "flex",
    flexDirection: "column"
  },
  boxTop: {
    marginTop: "-8px"
  }
}));

const chaidsMap = {
  "Protect (Security Services)": ["HSSS", "WPTSE"],
  "Optimize (Manageability Services)": ["DASE", "HPPIM"],
  "Renew (Recover & Renew Services)": ["DP_DECOM_AA","CBN"],
  "SmartFriend Pro Bundle": ["SFPB"],
  "TC Conversion Solution": ["CNS"]
};
const noPartno = ["ACSS"];

export const OtherServicesStepper = props => {
  const searchText = useSelector(state => state.configFilterData.searchText);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleItemChange, handleQuantityChange } = props;
  const modelData = useSelector(state => state.configFilterData.modelData);
  const verticalStepSelected = useSelector(
    state => state.configFilterData.activeStep
  );
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected)
  let filteredChoices = useSelector(
    state => state.configFilterData.filteredChoices
  );
  filteredChoices =
    defaultConfigStepSlected === "otherServices" && verticalStepSelected
      ? filteredChoices.filter(
          choices => choices.parentId === verticalStepSelected
        )
      : filteredChoices;
  const showWarning = useSelector(state => state.configFilterData.showWarning);
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const [expanded, setExpanded] = useState({
    "Protect (Security Services)" :false,
    "Optimize (Manageability Services)":false,
    "Renew (Recover & Renew Services)" :false,
    "SmartFriend Pro Bundle" :false,
    "TC Conversion Solution" :false
  });
  const [originShowChaids, setOriginShowChaids] = useState({
    "Protect (Security Services)": false,
    "Optimize (Manageability Services)": false,
    "Renew (Recover & Renew Services)": false,
    "SmartFriend Pro Bundle": false,
    "TC Conversion Solution": false
  });
  const searchForOtherServices = useSelector(
    state => state.configFilterData.searchForOtherServices
  );
  const expColButton = useSelector(
    state => state.configFilterData.expColButton
  );
  const isExpandAll = expColButton[defaultConfigStepSlected] || false;
  const [loaded, setLoaded] = useState(false);
  const [originFilteredChoices, setOriginFilteredChoices] = useState([]);
  const otherServicesComponent = chaid => {
    return (chaid.visible &&
      (chaid.precon >= 0 ||
        chaid.isOverridePrecon ||
        chaid.required ||
        chaid.selcon)) ||
      modelData.Items.some(
        part =>
          (noPartno.includes(chaid.chaId) ? true : part.partno !== "") &&
          part.visible === true &&
          part.precon >= 0 &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      ) ? (
      <ChaidSelectionType
        chaid={chaid}
        modelData={modelData}
        handleItemChange={handleItemChange}
        handleQuantityChange={handleQuantityChange}
      />
    ) : null;
  };
  const showChaid = item => {
    return chaidsMap[item].some(chaid =>
      filteredChoices.find(choice => choice.chaId === chaid)
    );
  };
  const showDetails = item => {
    const chaids = chaidsMap[item];
    return chaids.map(chaid => {
      const presentChaid = (
        !showForSearch(item) ? filteredChoices : originFilteredChoices
      ).find(choice => choice.chaId === chaid);
      return presentChaid ? otherServicesComponent(presentChaid) : <></>;
    });
  };
  const handleChange = (currentChaid, e) => {
    e.preventDefault();
    const relevantTabData = expandAll.find(
      expandObject => expandObject.tab === defaultConfigStepSlected
    );
    if (relevantTabData) {
      Object.keys(relevantTabData.choicesExpandState).map(item => {
        if (chaidsMap[currentChaid].indexOf(item) !== -1) {
          relevantTabData.choicesExpandState[item] = false;
        }
      });
      relevantTabData.choicesExpandState[currentChaid] =
        !expanded[currentChaid];
      Object.keys(chaidsMap).map(item => {
        if (
          relevantTabData.choicesExpandState.hasOwnProperty(item) &&
          !(showChaid(item) || showForSearch(item))
        ) {
          delete relevantTabData.choicesExpandState[item];
        }
      });
      const payload = {
        tab: defaultConfigStepSlected,
        choicesExpandState: relevantTabData.choicesExpandState
      };
      dispatch({
        type: UPDATE_CHOICE_EXPAND,
        payload
      });
    }
    setExpanded({
      ...expanded,
      [currentChaid]: !expanded[currentChaid]
    });
  };

  function showForSearch(item) {
    return searchForOtherServices[item] && originShowChaids[item];
  }
  useEffect(() => {
    let expandedData = {};
    Object.keys(chaidsMap).map(item => {
      expandedData[item] = isExpandAll;
    });
    setExpanded(expanded => {
      return {
        ...expanded,
        ...expandedData
      };
    });
  }, [isExpandAll]);

  useEffect(() => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (relevantTabData) {
        let expandedData = {};
        Object.keys(chaidsMap).map(item => {
          expandedData[item] =
            relevantTabData?.choicesExpandState?.[item] || false;
        });
        if (isExpandAll) {
          Object.keys(chaidsMap).map(item => {
            expandedData[item] = false;
          });
        }
        for (let key in relevantTabData?.choicesExpandState) {
          Object.keys(chaidsMap).map(item => {
            if (
              chaidsMap[item].includes(key) &&
              relevantTabData?.choicesExpandState[key]
            ) {
              expandedData[item] = true;
            }
          });
        }
        setExpanded(expanded => {
          return {
            ...expanded,
            ...expandedData
          };
        });
      }
    }
  }, [defaultConfigStepSlected, expandAll]);
  useEffect(() => {
    if (searchText) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      Object.keys(chaidsMap).map(item => {
        if (showForSearch(item)) {
          relevantTabData.choicesExpandState[item] = true;
          originFilteredChoices.map(choice => {
            chaidsMap[item].includes(choice.chaId) &&
              (relevantTabData.choicesExpandState[choice.chaId] = true);
          });
        }
      });
      const payload = {
        tab: defaultConfigStepSlected,
        choicesExpandState: relevantTabData.choicesExpandState
      };
      dispatch({
        type: UPDATE_CHOICE_EXPAND,
        payload
      });
    }
  }, [searchText, searchForOtherServices]);

  useEffect(() => {
    if (!loaded && filteredChoices.length > 0) {
      const map = {};
      Object.keys(chaidsMap).map(item => {
        map[item] = showChaid(item);
      });
      setOriginShowChaids(map);
      setLoaded(true);
      setOriginFilteredChoices(filteredChoices);
    }
  }, [filteredChoices]);
  return (
    <>
      {Object.keys(chaidsMap).map(
        item =>
          (showChaid(item) || showForSearch(item)) && (
            <div className={classes.accordian}>
              {isVerticalView==="N" ? (
                <Accordion
                  expanded={expanded[item] || false}
                  onChange={e => handleChange(item, e)}
                >
                  <AccordionSummary
                    className={
                      showWarning && showWarning.warning
                        ? ""
                        : classes.choiceLevelMarginTop
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="itemPanelbh-content"
                    id="itemPanelbh-header"
                  >
                    <Box
                      flexDirection="column"
                      flexGrow={1}
                      className={classes.boxTop}
                    >
                      {filteredChoices.map(chaid => {
                        if (chaidsMap[item].includes(chaid.chaId)) {
                          return (
                            <ErrorPart
                              chaid={chaid}
                              model={modelData}
                              setMargin={false}
                            />
                          );
                        }
                      })}
                      <Box>
                        <Typography
                          className={classes.heading}
                          component={"span"}
                          variant={"body2"}
                        >
                          {item}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordianDetails}>
                    {showDetails(item)}
                  </AccordionDetails>
                </Accordion>
              ) : (
                showDetails(item)
              )}
            </div>
          )
      )}
    </>
  );
};

import { HP_LOGO } from "../components/Configurator/Rsku/Constants";
import { updateCheckoutRSKUQuantity } from "../lib/common/util";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  products: [],
  popups: {},
  showAddToQuote: false,
  showRskuAddToQuote: false,
  allBomAVs: [],
  checkQuantity: [],
  similarProducts: [],
  carePacks: [],
  requestRskuProduct: [],
  activeTab: 0,
  rskuDailogAlert: false,
  rskuInfoDialogAlert: false,
  configID: "",
  baseProduct: [],
  compareData: [],
  proCategory: "",
  localization: "",
  isCreateRsku: false,
  closeMatchBomDetails: {},
  filteredBomData: [],
  isSkuCloning: false,
  selectedCategory: "",
  skuConfiguration: {},
  otherComponents: [],
  productCountryCode: "",
  productRegionCode: "",
  rskuImage: [HP_LOGO],
  standardCarePacks: [],
  bomThreeCompatibleCarePacks: [],
  bomThreeCompatiblePartNumbers: [],
  bomThreeCompatibleCarePacksInfo: [],
  selectedWarrenty: "",
  skuServiceName: "",
  requestSkuConfig: 0,
  exactCloseMatchConfig: 0,
  visibleSKUs: {},
  isRequestRSKU: false,
  rskuInfo: {},
  isAddtoQuote: false
};

const rskuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SEARCH_RSKU":
      const products =
        action.payload && action.payload.hasOwnProperty("exactMatch")
          ? action.payload.exactMatch.map(item => ({
              ...item,
              skuType: "exactMatch"
            }))
          : [...state.products];
      const similarProducts =
        action.payload && action.payload.hasOwnProperty("similarMatch")
          ? action.payload.similarMatch.map(item => ({
              ...item,
              skuType: "similarMatch"
            }))
          : [...state.similarProducts];
      const carePacks =
        action.payload && action.payload.hasOwnProperty("carepack")
          ? action.payload.carepack.map(item => ({
              ...item,
              skuType: "carepack"
            }))
          : [...state.carePacks];
      const baseProduct = action.payload.baseProduct;
      const compareData =
        action.payload && action.payload.hasOwnProperty("compareData")
          ? action.payload.compareData.map(item => ({
              ...item,
              skuType: "similarMatch"
            }))
          : [...state.compareData];
      const closeMatchBomDetails =
        action.payload &&
        action.payload.hasOwnProperty("closeMatchBomData") &&
        action.payload.closeMatchBomData.reduce((acc, { sku, attributes }) => {
          acc[sku] = attributes;
          return acc;
        }, {});

      const filteredBomData =
        action.payload && action.payload.hasOwnProperty("filteredBomData")
          ? action.payload.filteredBomData
          : [];

      const allProducts = [...products, ...similarProducts]; // FIXME : it should work eihter case
      const checkQuantity = allProducts.find(each => each.quantity > 0);
      const updateQuote = checkQuantity ? true : false;
      return {
        ...state,
        products,
        showAddToQuote: updateQuote,
        similarProducts,
        carePacks,
        checkQuantity: carePacks,
        baseProduct,
        compareData,
        closeMatchBomDetails,
        filteredBomData
      };
    case "SET_REQUEST_RSKU":
      const requestRskuProduct =
        action.payload && action.payload.hasOwnProperty("requestRsku")
          ? action.payload.requestRsku.map(item => ({
              ...item,
              skuType: "requestRsku"
            }))
          : [...state.requestRskuProduct];
      const allProduct = [...requestRskuProduct]; // FIXME : it should work eihter case
      const checkQuantitys = allProduct.find(each => each.quantity > 0);
      const updateQuotes = checkQuantitys ? true : false;
      const filtered = state.checkQuantity.filter(
        each => each.skuType !== "requestRsku"
      );
      return {
        ...state,
        requestRskuProduct,
        showRskuAddToQuote: updateQuotes,
        checkQuantity: [...filtered, ...requestRskuProduct]
      };
    case "SET_UPDATE_QUANTITY":
      const skuType = action.payload?.skuType;
      const skuData =
        skuType === "similarMatch"
          ? state.similarProducts
          : skuType === "exactMatch"
          ? state.products
          : skuType === "requestRsku"
          ? state.requestRskuProduct
          : state.carePacks;
      const productLabel =
        skuType === "similarMatch"
          ? "similarProducts"
          : skuType === "exactMatch"
          ? "products"
          : skuType === "requestRsku"
          ? "requestRskuProduct"
          : "carePacks";
      const sku = action.payload.sku;
      const quantity = action.payload.quantity;
      const updatedProduct = skuData.map(product => {
        if (product.sku === sku && product.quantity !== parseInt(quantity)) {
          product.quantity = parseInt(quantity);
        }
        return product;
      });
      const allProductList = [...state.products, ...state.similarProducts];
      const showButton = allProductList.find(each => each.quantity > 0)
        ? true
        : false;
      const isShowRskuButton = allProductList.find(
        each =>
          each.quantity > 0 &&
          (skuType === "similarMatch" || skuType === "exactMatch")
      )
        ? true
        : false;
      const isShowRskuAddToQuote =
        action.payload.quantity > 0 && skuType === "requestRsku" ? true : false;

      const updatedCompareData = action.payload.isSimilarProduct
        ? state.compareData.map(product => {
            if (
              product.sku === sku &&
              product.quantity !== parseInt(quantity)
            ) {
              product.quantity = parseInt(quantity);
            }
            return product;
          })
        : state.compareData;

      return {
        ...state,
        [productLabel]: updatedProduct,
        checkQuantity: updateCheckoutRSKUQuantity(
          state.checkQuantity,
          sku,
          updatedProduct
        ),
        showAddToQuote: showButton,
        showRskuAddToQuote: isShowRskuAddToQuote,
        compareData: updatedCompareData,
        isCreateRsku: isShowRskuButton
      };
    case "TOGGLE_POPUP":
      return {
        ...state,
        popups: {
          ...state.popups,
          [action.payload.name]: {
            ...state.popups[action.payload.name],
            ...action.payload
          }
        }
      };
    case "SET_SELECTED_SKU":
      return {
        ...state,
        sku: action.payload
      };
    case "SET_ALL_BOM_AV":
      return {
        ...state,
        allBomAVs: action.payload
      };
    case "SET_CLEAR_ALL":
      return {
        ...state,
        products: [],
        popups: {},
        showAddToQuote: false,
        allBomAVs: [],
        checkQuantity: [],
        carePacks: [],
        requestRskuProduct: [],
        activeTab: 0,
        baseProduct: {},
        compareData: [],
        rSKUBom: [],
        isCreateRsku: false,
        isRequestRSKU: false
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload
      };
    case "SET_RSKU_DAILOG_ALERT":
      return {
        ...state,
        rskuDailogAlert: action.payload
      };
    case "SET_CONFIGID":
      return {
        ...state,
        configID: action.payload
      };
    case "SET_PROCATEGORY":
      return {
        ...state,
        proCategory: action.payload
      };
    case "SET_LOCALIZATION":
      return {
        ...state,
        localization: action.payload
      };
    case "SET_CLONING_SKU":
      return {
        ...state,
        isSkuCloning: action.payload
      };
    case "SET_SELECTED_CATEGORY":
      return {
        ...state,
        selectedCategory: action.payload
      };
    case "SET_CONFIGURATION":
      return {
        ...state,
        skuConfiguration: action.payload
      };
    case "SET_OTHER_COMPONENTS":
      return {
        ...state,
        otherComponents: action.payload
      };
    case "SET_RSKU_BOM":
      return {
        ...state,
        rSKUBom: action.payload
      };
    case "SET_PRODUCT_REGION_AND_COUNTRY":
      const { productCountryCode, productRegionCode } = action.payload;
      return {
        ...state,
        productCountryCode,
        productRegionCode
      };
    case "SET_RSKU_IMAGE":
      return {
        ...state,
        rskuImage: action.payload
      };
    case "SET_CAREPACKS":
      const {
        standardCarePacks,
        bomThreeCompatibleCarePacks,
        bomThreeCompatiblePartNumbers,
        bomThreeCompatibleCarePacksInfo
      } = action.payload;
      return {
        ...state,
        standardCarePacks,
        bomThreeCompatibleCarePacks,
        bomThreeCompatiblePartNumbers,
        bomThreeCompatibleCarePacksInfo
      };
    case "SET_WARRANTY_DESCRIPTION":
      return {
        ...state,
        selectedWarrenty: action.payload
      };
    case "SET_SKU_SERVICE_NAME":
      return {
        ...state,
        skuServiceName: action.payload
      };
    case "SET_REQUEST_SKU_CONFIG":
      return {
        ...state,
        requestSkuConfig: action.payload
      };
    case "SET_EXACT_CLOSE_MATCH_CONFIG":
      return {
        ...state,
        exactCloseMatchConfig: action.payload
      };
    case "SET_RSKU_INFO_DIALOG_ALERT":
      return {
        ...state,
        rskuInfoDialogAlert: action.payload
      };
    case "SET_SKU_IDS":
      return {
        ...state,
        visibleSKUs: action.payload
      };
    case "SET_IS_REQUEST_RSKU":
      return {
        ...state,
        isRequestRSKU: action.payload
      };

    case "SET_RSKU_INFO":
      return {
        ...state,
        rskuInfo: action.payload
      };

    case "SET_IS_ADD_TO_QUOTE":
      return {
        ...state,
        isAddtoQuote: action.payload
      };
    default:
      return state;
  }
};

export default rskuReducer;

// import { EdgeType_GTE_0, EdgeType_I_SEL } from "./Constants.js";
import { getAllMonitorParts } from "./AutoAttach/util";
import { conflictDetection } from "./Conflicts/conflictDetection";
import { getCarePackChaId } from "./Conflicts/util";
import {
  MonLocChaids,
  PolyBundleChoice,
  PolyIOMSTChoice,
  PolyMSOChoice,
  PolyPDSChoice,
  PolyPMDChoice,
  PolySGJChoice,
  PolyVCDLChoice
} from "./Constants";
import { updateCarepacksMANDA } from "./Localization/applyLocalizationManda.js";
import { setSameConflictItems, updateModel } from "./Localization/util";
import { processInput } from "./NeuralNet/processInput";
import {
  getChoiceConflicts,
  getDeselItemsList,
  getHiddenChoiceConflicts,
  getIChaid,
  getItemConflictsNn,
  getTechAvItems,
  initializeNeuralNetVars,
  setChoiceConflicts,
  setDeselItemsList,
  setHiddenChoiceConflicts,
  setItemConflictsNn,
  setTechAvItems
} from "./NeuralNet/processOutput";
import { findChoiceByItem } from "./NeuralNet/util";
import {
  getAutoAttachedParts,
  getEffects,
  printConflicts,
  printModelUpdate,
  processQtyForPrntChoicesUnderConfigurationServices,
  segregatePayload,
  selectSingleAvailableItemUnderRequiredChoice,
  updateAutoAttachedParts,
  updateCarePacks,
  updateVisibilityForGrayoutItems
} from "./util.js";
// const this._rawModel = require("./test/test/before.json");
const fs = require("fs");

// console.log(
//   `Model attribute(${Object.keys(this._rawModel).length}): ${Object.keys(this._rawModel)
//     .sort()
//     .join(", ")}`
// );
const DO_DESELECT = -1;
const DO_SELECT = 1;
function OneModel(rawModel) {
  this._rawModel = rawModel;
  this.Nodes = rawModel.Nodes;
  this.Items = rawModel.Items;
}

const doesElementExistInListTechav = (elm, techAvChoiceItems) => {
  let flag = false;
  techAvChoiceItems?.forEach(each => {
    if (each?.cause?.chaId === elm) {
      flag = true;
    }
  });
  return flag;
};
const doesElementExistInTechavChoiceItems = (elm, modelTechAv) => {
  return modelTechAv?.some(each => each.partno === elm) || false;
};
const deselectionTechAvChoice = model => {
  let updateModelData = model;
  let payloads = [];
  if (updateModelData.techAvChoiceItems?.length > 0) {
    updateModelData.techAvChoiceItems?.forEach(techAvItem => {
      updateModelData?.Items.forEach(item => {
        if (
          techAvItem.partNo === item.partno &&
          item.selected &&
          item.valueId?.length > 0
        ) {
          const node = updateModelData.Nodes[item.inode];
          const payload = {
            inputEdge: node.type,
            inputNode: node.id,
            changeIn: DO_DESELECT
          };
          payloads = [...payloads, payload];
        }
      });
    });
    updateModelData.techAvChoiceItems = [];
  }
  if (payloads.length !== 0) {
    if (localStorage.getItem("isDebug") === "true") {
      console.log("PAYLOADS Deselection", payloads);
    }
    payloads.forEach(iPayLoad => {
      updateModelData = updateModel(
        iPayLoad.inputEdge,
        iPayLoad.InputNode,
        iPayLoad.ChangeIn,
        updateModelData
      );
    });
  }
  return updateModelData;
};
function findSelectedItemInSingleChoice(modelData, item) {
  const iChaID = findChoiceByItem(modelData, item);
  if (modelData.Chaids[iChaID]?.multiple) {
    return -1;
  }
  const firstItem = modelData.Chaids[iChaID].firstItem;
  const lastItem = modelData.Chaids[iChaID].lastItem;
  for (let i = firstItem; i <= lastItem; i++) {
    if (modelData.Items[i].selected) {
      return i;
    }
  }
  return -1;
}
const selectTechAVunderChoice = (model, techAvlist) => {
  let payloads = [];
  let updateModelData = model;
  techAvlist?.forEach(techAv => {
    for (let i = 0; i < updateModelData.Items.length; i++) {
      if (
        techAv.partNo === updateModelData.Items[i].partno &&
        updateModelData.Items[i].valueId?.length > 0 &&
        !updateModelData.Items[i].selected &&
        updateModelData.Items[i].precon >= 0
      ) {
        const node = updateModelData.Nodes[updateModelData.Items[i].inode];
        const payload = {
          inputEdge: node.type,
          inputNode: node.id,
          changeIn: DO_SELECT
        };
        payloads = [...payloads, payload];
        break;
      }
    }
  });
  //techavadding
  techAvlist.forEach(techItem => {
    if (
      techItem?.partNo !== techItem?.cause?.partno &&
      !doesElementExistInTechavChoiceItems(
        techItem.partno,
        updateModelData.techAvChoiceItems
      )
    ) {
      const techAvChoiceItems = {
        partNo: techItem.partNo,
        chaId: techItem.chaId,
        itemInode: techItem.itemInode,
        selected: true,
        cause: techItem.cause
      };
      if (updateModelData?.techAvChoiceItems) {
        updateModelData.techAvChoiceItems = [
          ...updateModelData?.techAvChoiceItems,
          techAvChoiceItems
        ];
      } else {
        updateModelData.techAvChoiceItems = [techAvChoiceItems];
      }
    }
  });
  if (payloads.length !== 0) {
    const preDeselection = [];
    for (const iPayLoad of payloads) {
      const index = findSelectedItemInSingleChoice(
        updateModelData,
        iPayLoad.inputNode
      );
      if (index === -1) {
        continue;
      }
      const node = updateModelData.Nodes[index];
      preDeselection.push({
        inputEdge: node.type,
        inputNode: node.id,
        changeIn: DO_DESELECT
      });
    }

    payloads = [...preDeselection, ...payloads];

    if (localStorage.getItem("isDebug") === "true") {
      console.log("PAYLOADS", payloads);
    }
    payloads.forEach(payload => {
      updateModelData = updateModel(
        payload.inputEdge,
        payload.inputNode,
        payload.changeIn,
        updateModelData
      );
    });
  }
  return updateModelData;
};
OneModel.prototype.updateSelections = function (changes) {
  /*
  ProcessInput(change)
    - find Node (from change.nodeID)
    - switch edgeType -- find changeOut1, changeOut2, changeOut3, update a & g attributes as needed
    - process edge in loop, for every edge
        * find change & enableOutput value
        * set subPayload
        * switch (path), based on path values call PathXXX (subPayload)
  
  PathXXX ()
    - go through the nodes in loop, and call PropagateChange

  PropagateChange
    - if edgeType = 64 -- ProcessInput (recursive call)
    - else call ProcessOutput

  ProcessOutput
    - logic based on edgeType
    
  */

  // console.log(`\nupdateSelectionss ${changes}`);
  // const updateNode = change => {
  //   //https://github.azc.ext.hp.com/OneConfig-212852/kb-engine-service/blob/41c2a1afd9c56531f5e27a4fd34f82a1260717f4/src/model/neural-net.go
  //   //This function is replacement of ProcessInput defined in the link -- by simplifying only for const EdgeType_GTE_0
  //   console.log(`=> updateNode ......................................`);
  //   console.log(change);

  //   //find the node based on nodeID
  //   const node = this.Nodes[change.nodeID];

  //   switch (change.type) {
  //     case EdgeType_GTE_0: // type = 2
  //       const pGate = node.g;
  //       node.g = node.g + change.value;
  //       const changeOut =
  //         pGate >= 0 ? (node.g >= 0 ? 0 : -1) : node.g >= 0 ? 1 : 0;
  //       break;
  //   }
  //   //process the node itself (update g value)

  //   const processEdge = edge => {
  //     const edgeTypeH = parseInt(edge.type, 16);
  //     switch (edgeTypeH) {
  //       case EdgeType_I_SEL:
  //     }
  //   };
  //   //process the node neighbours
  //   node.edges.forEach(e => {
  //     console.log("edge", e);
  //   });
  // };

  // changes.forEach(c => {
  //   updateNode(c);
  // });

  changes.sort(function (a, b) {
    return a.value - b.value;
  });
  for (let i = 0; i < changes.length; i++) {
    const item = changes[i];
    const payload = {
      iNode: item.nodeID,
      changeIn: item.value,
      iEdge: item.edgeType
    };
    processInput(payload, this._rawModel);
  }
  printConflicts(this._rawModel);
};

export const isAnyItemSelected = (choiceID, modelData) => {
  let flag = false;
  for (let i = 0; i < modelData.Chaids.length; i++) {
    const cha = modelData.Chaids[i];
    if (choiceID?.includes(cha.chaId)) {
      for (let j = cha.firstItem; j <= cha.lastItem; j++) {
        if (modelData.Items[j].selected) {
          flag = true;
          break;
        }
      }
    }
    if (flag) {
      break;
    }
  }
  return flag;
};

export const findSelectedItems = (choiceID, modelData) => {
  let selectedItems = [];
  let iChaId;
  for (let i = 0; i < modelData.Chaids.length; i++) {
    const cha = modelData.Chaids[i];
    if (choiceID === cha.chaId) {
      iChaId = i
      for (let j = cha.firstItem; j <= cha.lastItem; j++) {
        if (modelData.Items[j].selected) {
          selectedItems = [...selectedItems, j];
        }
      }
      break
    }
  }
  return { selectedItems, iChaId };
};

OneModel.prototype.printStats = function () {
  if (localStorage.getItem("isDebug") === "true") {
    console.log(
      `\n> Chaids (${this.Chaids.length}), attributes: ${Object.keys(
        this.Chaids[0]
      )
        .sort()
        .join(", ")}`
    );
    console.log(
      `> Nodes (${this.Nodes.length}), attributes: ${Object.keys(this.Nodes[0])
        .sort()
        .join(", ")}`
    );
    console.log(
      `> Items (${this.Items.length}), attributes: ${Object.keys(this.Items[0])
        .sort()
        .join(", ")}`
    );
  }
};

function checkPolyMSOAndPDSChoice(modelData) {
  let nonMSO = true;
  let nonPDS = true;

  if (modelData.modelRequirement.productGroup !== "PY") {
    return [nonMSO, nonPDS];
  }

  for (const chaid of modelData.Chaids) {
    if (chaid.chaId === PolyBundleChoice) {
      if (!chaid.required) {
        return [false, false];
      }
      let nonBUNIT = true;
      for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
        if (modelData.Items[i].selected && modelData.Items[i].visible) {
          nonBUNIT = false;
          break;
        }
      }
      if (nonBUNIT) {
        return [false, false];
      }
    } else if (chaid.chaId === PolyMSOChoice) {
      if (chaid.multiple) {
        nonMSO = false;
        continue;
      }
      for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
        if (modelData.Items[i].selected && modelData.Items[i].visible) {
          nonMSO = false;
          break;
        }
      }
    } else if (chaid.chaId === PolyPDSChoice) {
      if (chaid.multiple) {
        nonPDS = false;
        continue;
      }
      for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
        if (modelData.Items[i].selected && modelData.Items[i].visible) {
          nonPDS = false;
          break;
        }
      }
    }
  }

  return [nonMSO, nonPDS];
}


function updatePolyServiceOfferings(modelData, nonMSO, nonPDS) {
  if (modelData.modelRequirement.productGroup !== 'PY') {
    return modelData;
  }

  const setNonPayload = {
    itemChanges: [],
  };

  if (nonMSO) {
    for (const chaid of modelData.Chaids) {
      if (chaid.chaId === PolyMSOChoice) {
        for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
          if (modelData.Items[i].selected) {
            setNonPayload.itemChanges.push({
              inputEdge: 2,
              inputNode: i,
              changeIn: DO_DESELECT
            });
          }
        }
        break;
      }
    }
  }

  if (nonPDS) {
    for (const chaid of modelData.Chaids) {
      if (chaid.chaId === PolyPDSChoice) {
        for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
          if (modelData.Items[i].selected) {
            setNonPayload.itemChanges.push({
              inputEdge: 2,
              inputNode: i,
              changeIn: DO_DESELECT
            });
          }
        }
        break;
      }
    }
  }

  if (setNonPayload.itemChanges.length > 0) {
    const loadModel = new loadOneModel(modelData);
    return loadModel.updateModelWithMultipleChanges(setNonPayload);
  }
  return modelData;
}

const setConflcitsInProcessOutput = (updatedModel) => {
  if (updatedModel?.conflicts?.length > 0) {
    if (updatedModel.conflicts[0].itemConflictsList?.length > 0) {
      setItemConflictsNn(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.itemConflictsList
      );
    }
    if (updatedModel.conflicts[0].hiddenChoiceConflicts?.length > 0) {
      setHiddenChoiceConflicts(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.hiddenChoiceConflicts
      );
    }
    if (updatedModel.conflicts[0].choiceConflicts?.length > 0) {
      setChoiceConflicts(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.choiceConflicts
      );
    }
  }
}
  


//src/main-webasm.go    use
//src/webasm/validate.go   define
OneModel.prototype.updateModelWithMultipleChanges = function (payload) {
  localStorage.getItem("showTime") === "true" &&
    console.time("TIME: updateModelWithMultipleChanges");
  if (localStorage.getItem("isDebug") === "true") {
    console.log("updateModelWithMultipleChanges", payload);
  }
  payload.itemChanges.sort(function (a, b) {
    return a.changeIn - b.changeIn;
  });
  initializeNeuralNetVars();
  let updatedModel = Object.assign({}, this._rawModel);
  setConflcitsInProcessOutput(updatedModel)
  for (let i = 0; i < payload.itemChanges.length; i++) {
    const iPayLoad = payload.itemChanges[i];
    const isItemAlreadySelected =
      iPayLoad.changeIn === DO_SELECT &&
      updatedModel.Items[iPayLoad.inputNode].selected;
    const isItemAlreadyDeselected =
      iPayLoad.changeIn === DO_DESELECT &&
      !updatedModel.Items[iPayLoad.inputNode].selected;

    // If selection input comes for already selected part OR deselection input comes for already deselected part,
    // then we should not process the input.
    if (!(isItemAlreadySelected || isItemAlreadyDeselected)) {
      updatedModel = processInput(iPayLoad, updatedModel);
    }
    const { Ichaid, ChaId, ChaDes } = getIChaid(
      iPayLoad.inputNode,
      updatedModel
    );
    if (iPayLoad?.changeIn == DO_DESELECT && MonLocChaids.includes(ChaId)) {
      const itemBasePart =
        updatedModel.Items[iPayLoad.inputNode].partno?.split("#")[0];
      updatedModel = updateCarepacksMANDA(
        updatedModel,
        getCarePackChaId(itemBasePart, ChaId)
      );
    }
  }
  if (updatedModel?.conflicts?.length > 0) {
    updatedModel.conflicts[0].itemConflictsList = getItemConflictsNn()
    updatedModel.conflicts[0].hiddenChoiceConflicts = getHiddenChoiceConflicts()
    updatedModel.conflicts[0].choiceConflict = getChoiceConflicts()
  }
  printModelUpdate(updatedModel);
  const getDeselItems = getDeselItemsList();
  //Select Techav part numbers under choice if added in BOM
  if (getDeselItems && getDeselItems > 0) {
    const { Ichaid, ChaId, ChaDes } = getIChaid(
      getDeselItemsList(),
      updatedModel
    );
    if (doesElementExistInListTechav(ChaId, updatedModel?.techAvChoiceItems)) {
      updatedModel = deselectionTechAvChoice(updatedModel);
    }
    setDeselItemsList();
  }

  const techav = getTechAvItems();
  setTechAvItems();
  if (techav?.length > 0) {
    updatedModel = selectTechAVunderChoice(updatedModel, techav);
  }
  //SetSameConflictItems will run except first load, will capture iNodes with same cause & effect
  if (payload.itemChanges?.length > 0 && payload.itemChanges[0].inputNode) {
    setSameConflictItems(updatedModel);
    updatedModel = updateVisibilityForGrayoutItems(updatedModel);
  }
  selectSingleAvailableItemUnderRequiredChoice(updatedModel, false);
  setConflcitsInProcessOutput(updatedModel)

  //change the auto attach selection
  const allMonParts = getAllMonitorParts(updatedModel);
  const {
    selectedProducts,
    deSelectedProducts,
    deSelectedMonAccCP,
    selectedMonAccCP
  } = getAutoAttachedParts(payload, updatedModel, allMonParts);
  updatedModel = updateAutoAttachedParts(
    updatedModel,
    selectedProducts,
    deSelectedProducts,
    deSelectedMonAccCP,
    selectedMonAccCP
  );
  updatedModel = processQtyForPrntChoicesUnderConfigurationServices(payload, updatedModel)
  updatedModel = conflictDetection(updatedModel);
  if (!(payload.itemChanges?.length > 0 && payload.itemChanges[0].inputNode)) {
    getEffects(updatedModel, updatedModel?.conflicts);
  }
  printConflicts(updatedModel);
  //For Poly model only, for poly bundle choice and related service as the service is not the TECHAV
  updatedModel = updatePolyModelServiceToBundleChoice(updatedModel);
  if (
    updatedModel.modelRequirement.productGroup === "PY" &&
    payload.itemChanges.length > 0 &&
    window.triggerWasmCall
  ) {
    window.triggerWasmCall = false;
    let selectedHWItemMap = new Map();
    let nonMSO = true
		let nonPDS = true
    let qtyPayload = { partQty: [] };
    const loadModel = new loadOneModel(updatedModel);
    for (let chaid of updatedModel.Chaids) {
      if (chaid.chaId !== PolyMSOChoice && chaid.chaId !== PolyPDSChoice) {
        for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
          if (
            updatedModel.Items[i].selected &&
            updatedModel.Items[i].visible &&
            updatedModel.Items[i].quantity > 0
          ) {
            selectedHWItemMap.set(i, updatedModel.Items[i].quantity);
            qtyPayload.partQty.push({
              partInode: i,
              qty: updatedModel.Items[i].quantity
            });
          }
          continue
        }
      }
      for (const chaid of updatedModel.Chaids) {
        if (chaid.chaId === PolyMSOChoice) {
          for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
            if (updatedModel.Items[i].selected && updatedModel.Items[i].visible) {
              nonMSO = false;
              break;
            }
          }
          continue;
        }
        if (chaid.chaId === PolyPDSChoice) {
          for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
            if (updatedModel.Items[i].selected && updatedModel.Items[i].visible) {
              nonPDS = false;
              break;
            }
          }
          continue;
        }
      }
    }

    let nonPayload = {
      itemChanges: []
    };

    for (let k of selectedHWItemMap.keys()) {
      if (updatedModel.Items[k].selected) {
        nonPayload.itemChanges.push({
          inputEdge: 2,
          inputNode: k,
          changeIn: -1
        });
      }
    }

    if (nonPayload.itemChanges.length > 0) {
      updatedModel = loadModel.updateModelWithMultipleChanges(
        nonPayload,
        updatedModel
      );
    }
    updatedModel = loadModel.updateQuantity(qtyPayload, updatedModel);
    updatedModel = updatePolyServiceOfferings(updatedModel, nonMSO, nonPDS)
  }
  localStorage.getItem("showTime") === "true" &&
    console.timeEnd("TIME: updateModelWithMultipleChanges");
  return updatedModel;
};

function updatePolyModelServiceToBundleChoice(modelData) {
  if (modelData.modelRequirement.productGroup !== "PY") {
    return modelData;
  }

  let bUnitQty = 0;
  let bUnitChaid, msoChaid, pdsChaid;
  let SGJChaid, PMDChaid, VCDLChaid, IOMSTChaid;

  modelData.Chaids.forEach(v => {
    let chaid = v;
    switch (chaid.chaId) {
      case PolyBundleChoice:
        bUnitChaid = chaid;
        break;
      case PolyMSOChoice:
        msoChaid = chaid;
        break;
      case PolyPDSChoice:
        pdsChaid = chaid;
        break;
      case PolySGJChoice:
        SGJChaid = chaid;
        break;
      case PolyPMDChoice:
        PMDChaid = chaid;
        break;
      case PolyVCDLChoice:
        VCDLChaid = chaid;
        break;
      case PolyIOMSTChoice:
        IOMSTChaid = chaid;
        break;
    }
});

if (bUnitChaid === null) {
    return;
}

  for (let i = bUnitChaid?.firstItem; i <= bUnitChaid?.lastItem; i++) {
    if (modelData.Items[i].selected && modelData.Items[i].visible) {
      bUnitQty = modelData.Items[i].quantity;
    }
  }

  if (msoChaid) {
    for (let i = msoChaid.firstItem; i <= msoChaid.lastItem; i++) {
      if (
        modelData.Items[i].selected &&
        modelData.Items[i].visible &&
        bUnitQty > 0 &&
        !msoChaid.multiple
      ) {
        modelData.Items[i].quantity = bUnitQty;
      }
    }
  }

  if (pdsChaid) {
    for (let i = pdsChaid.firstItem; i <= pdsChaid.lastItem; i++) {
      if (
        modelData.Items[i].selected &&
        modelData.Items[i].visible &&
        bUnitQty > 0 &&
        !pdsChaid.multiple
      ) {
        modelData.Items[i].quantity = bUnitQty;
      }
    }
  }
  matchChoiceQuantity(modelData, VCDLChaid, SGJChaid);
  matchChoiceQuantity(modelData, IOMSTChaid, PMDChaid);
  return modelData;
}

function matchChoiceQuantity(modelData, srcChaid, dstChaid) {
  if (!srcChaid || !dstChaid) {
      return false;
  }

  for (let i = srcChaid.firstItem; i <= srcChaid.lastItem; i++) {
      if (modelData.Items[i].selected && modelData.Items[i].visible) {
          for (let j = dstChaid.firstItem; j <= dstChaid.lastItem; j++) {
              if (modelData.Items[j].selected && modelData.Items[j].visible) {
                  modelData.Items[j].quantity = modelData.Items[i].quantity;
                  return true;
              }
          }
          break;
      }
  }
  return false;
}

OneModel.prototype.updateQuantity = function (payload) {
  let payloads = [];
  if (localStorage.getItem("isDebug") === "true") {
    console.log("updateQuantity function start", payload);
  }
  let qtyPayload = {};
  for (let i = 0; i < payload?.partQty?.length; i++) {
    const iPayLoad = payload.partQty[i];
    qtyPayload[iPayLoad.partInode] = iPayLoad.qty;
  }

  let updatedModel = Object.assign({}, this._rawModel);
  const loadModel = new loadOneModel(updatedModel);
  const [ nonMSO, nonPDS ] = checkPolyMSOAndPDSChoice(updatedModel)
  console.log("nonMSO, nonPDS", nonMSO, nonPDS)
  if (updatedModel?.modelRequirement?.productGroup === "PY") {
    const nonPayload = {
      itemChanges: []
    };

    for (const k in qtyPayload) {
      if (updatedModel.Items[k].selected) {
        nonPayload.itemChanges.push({
          inputEdge: 2,
          inputNode: k,
          changeIn: -1
        });
      }
    }
    if (nonPayload.itemChanges.length > 0) {
      updatedModel = loadModel.updateModelWithMultipleChanges(
        nonPayload,
        updatedModel
      );
    }
  }

  for (let i = 0; i < updatedModel?.Items?.length; i++) {
    const item = updatedModel?.Items[i];
    const itemInode = String(item.inode);
    let node = updatedModel.Nodes[item.inode];
    let qty = qtyPayload[itemInode];
    if (Object.keys(qtyPayload).includes(itemInode)) {
      updatedModel.Items[i].quantity = qty;
      if (qty > 0 && !item.selected) {
        let iPayLoad = {
          inputEdge: node.type,
          inputNode: node.id,
          changeIn: 1
        };
        payloads = [...payloads, iPayLoad];
      }

      if (qty == 0 && item.selected) {
        let iPayLoad = {
          inputEdge: node.type,
          inputNode: node.id,
          changeIn: -1
        };
        payloads = [...payloads, iPayLoad];
      }
    }
  }

  let iPayload = {
    itemChanges: payloads
  };
  const oneModel = new loadOneModel(updatedModel);
  const { cpPayload, nonCpPayload, chaId } = segregatePayload(
    iPayload,
    updatedModel
  );
  localStorage.getItem("isDebug") === "true" &&
    console.log("cpPayload segregatePayload", cpPayload, chaId, nonCpPayload);
  if (cpPayload?.itemChanges?.length > 0) {
    updatedModel = updateCarePacks(cpPayload, updatedModel, false);
  }
  updatedModel = oneModel.updateModelWithMultipleChanges(
    nonCpPayload,
    updatedModel
  );
  // "precon < 0" does not guarantee we have to deselect those items under that choice.
  updatedModel = updatePolyServiceOfferings(updatedModel, nonMSO, nonPDS)
  return updatedModel;
};

export const loadOneModel = OneModel;

import { useLazyQuery, useQuery } from "@apollo/client";
import { Grid, StyledEngineProvider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ThemeProvider, makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import RequestAssistanceButton from "../../../src/components/RequestAssistance/RequestAssistanceButton";
import { GET_MODEL_CONFIGURATIONS, PRODUCT_SELECTION } from "../../GraphQL";
import {
  CONFIG_STEP_SELECTION,
  SET_ACCESS_TYPE,
  SET_CONFIG_LOAD_MODEL,
  SET_IS_CONFIG_OVERDATE,
  SET_KMAT_ID,
  SET_ORIGINAL_ACCESS_TYPE,
  SET_ORIGIN_SELECTED_ITEMS,
  SET_SPC_ID
} from "../../actions/Types";
import {
  BOM_CONTAINER_POSTION,
  PANEL_CONTAINER_POSITION
} from "../../config/env";
import { HPMuiTheme } from "../../config/theme";
import { ExternalProps } from "../../contexts/externalPropsContext";
import displayNotistack from "../../lib/common/SnackBarUtils";
import {
  delay,
  getStorageValue,
  updatedModelData
} from "../../lib/common/util";
import {
  SET_COUNTRY_REGION,
  SET_SELECTED_CATEGORY_FROM_API
} from "../../models/configuration/productSelector/Constants";
import { updateLocalization } from "../../oneConfigNative/Localization/updateLocalization";
import { loadOneModel } from "../../oneConfigNative/main";
import { segregatePayload } from "../../oneConfigNative/util";
import { findParentChoiceId } from "../../services/bomAndCarePackLib/utils";
import {
  fetchUiSpecVerticalView,
  getUISpecData,
  getUiDataFileName,
  setIsSpecOrigin
} from "../../services/common/utils";
import MyAnimation from "../MyAnimation";
import ConfigBOM from "./ConfigBOM/ConfigBOM";
import ConfigHome from "./ConfigExpansionDetails/ConfigHome";
import ConfigFilter from "./ConfigFilter/ConfigFilter";
import ConfigStepper from "./ConfigStepper/ConfigStepper";
import ObseleteWarningMessage from "./ObseleteWarningMessage";
import ScrollToTop from "./ScrollToTop";
import { SIMPLE_MODEL_CONFIGURATION } from "./SimpleModelQuery";
const pako = require("pako");
const useStyles = makeStyles(theme => ({
  container: {
    margin: "0 5% 5% 5%"
  },
  midPositioner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  },
  spinner: {
    color: "rgb(41, 168, 221)"
  },
  warningColor: {
    color: "#ffbf00"
  },
  typo: {
    color: "#29A8DD"
  },
  yopacity: {
    "&.MuiFab-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      height: "46px",
      width: "48px",
      background: "#0096D6 !important",
      position: "fixed",
      fontSize: "3rem",
      zIndex: "9999",
      cursor: "pointer",
      right: "24px"
    },
    "&:hover": {
      color: "green"
    }
  },
  bottomReconfigure: {
    bottom: "20rem"
  },
  bottom: {
    bottom: "16rem"
  }
}));
const ConfigContainer = props => {
  //get proCategory from props
  const { proCategory, setOpen, categoryFromAPI } = props;

  const classes = useStyles();
  const prodSelCountry = useSelector(state => state.productSelector.country);
  const modelData = useSelector(state => state.configFilterData.modelData);
  const isUploadConfig = useSelector(
    state => state.uploadConfig.isUploadConfig
  );
  const showGADate = modelData.showGADate;
  const country = props?.config?.country
    ? props?.config?.country
    : prodSelCountry;
  const countryRegion = useSelector(
    state => state.productSelector.countryRegion
  );
  const businessModel = useSelector(
    state => state.productSelector.businessModel
  );
  const { isSkuCloning, skuConfiguration } = useSelector(
    state => state.rskuReducer
  );
  const dispatch = useDispatch();

  const {
    countryCode = "US",
    resellerID,
    channelRegion = "",
    mDCPOrgID = "99",
    pricingGeo = "US",
    currencyCode = "USD",
    incoterms = "DDP",
    routeToMarket,
    purchaseAgreement,
    isStandaloneAccess = false,
    setCurrency,
    userType,
    productCatalog = "Business Desktop PCs",
    transactionID,
    visibility = {},
    clientLocale,
    configOrigin,
    dataType,
    originatingAsset,
    isSKUModule,
    isRestoration,
    isPartnerPortalFlag = false,
    localizationCode,
    localizationOptions,
    defaultSKUType
  } = React.useContext(ExternalProps);
  const isPartnerUser = userType?.toLowerCase() === "partner";
  const { showGaDateConfigError = true } = visibility;
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const steppers = useSelector(state => state.configFilterData.steppers);
  const [stepDetails, getStepDetails] = useState("");
  const [steppersList, setStepperList] = useState([]);
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const showBOMSectionFlag = useSelector(
    state => state.configFilterData.showBOMSectionFlag
  );
  const bomData = useSelector(state => state.configFilterData.bomData);
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const displayBusinessModel = useSelector(
    state => state.productSelector.displayBusinessModel
  );
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected);
  const currentCountry = isStandaloneAccess ? country : countryCode;
  const locale = clientLocale
    ? clientLocale.indexOf("_") !== -1
      ? clientLocale
      : clientLocale + "_" + countryCode
    : "en_US";
  let modelQuery = `query modelConfiguration($filter: ConfigurationFilter) {
        modelConfiguration(filter: $filter)
      }`;

  const [error, setError] = useState({ isError: false, message: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [isUISpecDataLoaded, setIsUISpecDataLoaded] = useState(false);
  const [hasOcfgParams, setHasOcfgParams] = useState(false);
  const { config } = props ? props : getStorageValue("config");
  const [configStartDate, setConfigStartDate] = useState("");
  //define a state to store localization Data as received from api response
  const [localisationData, setLocalisationData] = useState({});
  const { configID, kmatId, spcStartDate } = config;
  const [isWebAInit, setIsWebAInit] = useState(false);
  const { t } = useTranslation();
  const isGoEngine = localStorage.getItem("KB_ENGINE") === "GO";
  const isSimpleModel = localStorage.getItem("isSimpleModel") === "true";
  let myInternal;
  const wasmPath =
    window._WASM_TEST_URL ||
    `${process.env.REACT_APP_S3_BUCKET_URL}/oneconfig-engine.wasm.gz`;

  const verticalStepper = useSelector(
    state => state.configFilterData.verticalStepper
  );
  const parseModelTimer = useRef();
  const loadTimer = useRef();
  const webAssemblyTimer = useRef();

  useEffect(() => {
    if (verticalStepper) {
      const stepperObject = {};
      for (let key in verticalStepper) {
        stepperObject[key] = {};
      }
      if (proCategory === "poly") stepperObject["hardware"] = {};
      // initial setup becuse in vertical sterpper by default expand all true
      dispatch({
        type: "SET_CHAID_EXPAND_COLLAPSE",
        payload: {
          ...stepperObject
        }
      });
      for (let key in verticalStepper) {
        stepperObject[key] = true;
      }
      // expColButton in setting default value true which are vertical stepper
      dispatch({
        type: "SET_EXPAND_DEFAULT_VERTICAL_COLLAPSE_BUTTON",
        payload: stepperObject
      });
    }
  }, []);

  useEffect(() => {
    let webAInterval;
    async function fetchMyAPI() {
      if (!isGoEngine) {
        setIsWebAInit(true);
        return;
      }
      if (!WebAssembly.instantiateStreaming) {
        // polyfill
        WebAssembly.instantiateStreaming = async (resp, importObject) => {
          const source = await (await resp).arrayBuffer();
          return await WebAssembly.instantiate(source, importObject);
        };
      }
      const go = window.Go && new window.Go();
      const url = wasmPath; // the gzip-compressed wasm file
      //const pako = pako.deflate(url);
      let wasm = pako.ungzip(await (await fetch(url)).arrayBuffer());
      let mod;
      let inst;
      const result = await WebAssembly.instantiate(wasm, go && go.importObject)
        .then(async result => {
          inst = result.instance;
          setIsWebAInit(true);
          await go.run(inst);
        })
        .catch(err => {
          console.error(err);
        });
    }

    webAInterval = setInterval(() => {
      if (window.Go) {
        fetchMyAPI();
        clearInterval(webAInterval);
      }
    }, 100); //Fix issue 43300 - Reducing the timeout to 100 to avoid hang issue
    //since wasm engine is loading very late.
  }, [wasmPath]);
  useEffect(() => {
    localStorage.setItem(
      "shouldShowOnUIParams",
      JSON.stringify({
        currentConfigID: configID,
        showGADate,
        isConfigOverDate:
          configStartDate !== "" &&
          moment(configStartDate).format("YYYY-MM-DD") >
            moment().format("YYYY-MM-DD")
      })
    );
  }, [configID, showGADate, configStartDate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const stepperList = steppers?.map(stepper => {
        if (stepper.isWarning) return stepper.id;
      });
      setStepperList(stepperList);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [defaultConfigStepSlected, steppers]);

  const [getSimpleConfiguration] = useLazyQuery(SIMPLE_MODEL_CONFIGURATION, {
    fetchPolicy: "no-cache",
    onCompleted({ simpleModelConfiguration }) {
      window.scrollTo(0, 0);
      setError({ isError: false, message: "" });
      const productCategoryFromAPI =
        simpleModelConfiguration?.productCategories.productCategories.find(
          category => category.selected === "Y"
        );
      // Below line can be removed once currency get from simpleModelConfiguration response.
      simpleModelConfiguration.currency = currencyCode;
      fetchDesktopUiSpec(productCategoryFromAPI, simpleModelConfiguration);
      batch(() => {
        dispatch({
          type: CONFIG_STEP_SELECTION,
          selectedStep: "hardware"
        });
        dispatch({
          type: SET_CONFIG_LOAD_MODEL,
          configuration: simpleModelConfiguration?.configuration
        });
        dispatch({
          type: SET_KMAT_ID,
          payload: simpleModelConfiguration?.kmatId
        });
        dispatch({
          type: SET_SPC_ID,
          payload:
            simpleModelConfiguration?.configuration?.configHeader?.configId
        });
        dispatch({
          type: SET_ACCESS_TYPE,
          payload:
            simpleModelConfiguration?.configuration?.configHeader?.accessType?.toLowerCase()
        });
        dispatch({
          type: SET_ORIGINAL_ACCESS_TYPE,
          payload:
            simpleModelConfiguration?.configuration?.configHeader?.accessType?.toLowerCase() ||
            "organization"
        });
      });
      setCurrency(simpleModelConfiguration?.currency);
      const startDate =
        simpleModelConfiguration?.configuration?.configHeader?.startDate;
      const docType =
        simpleModelConfiguration?.configuration?.configHeader?.docType;
      const date =
        docType === "SP"
          ? startDate
          : (docType === "QU" || docType === "CC") && !spcStartDate
          ? startDate
          : spcStartDate
          ? spcStartDate
          : "";
      if (date && date !== "NA" && date !== "") {
        dispatch({
          type: SET_IS_CONFIG_OVERDATE,
          payload:
            moment(date).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")
        });
        setConfigStartDate(date);
      }
    },
    onError() {
      setIsUISpecDataLoaded(false);
      setIsLoading(false);
      setError({ isError: true, message: error });
      displayNotistack.error(true, {
        snackbarMessage: t("common:productSelector.errorMessage.graphQLErrors"),
        errorMessage: error
      });
      if (typeof setOpen !== "undefined") {
        setOpen(false);
      }
    }
  });
  const [modelConfiguration] = useLazyQuery(GET_MODEL_CONFIGURATIONS, {
    fetchPolicy: "no-cache",
    onCompleted({ modelConfiguration }) {
      window.scrollTo(0, 0);
      setError({ isError: false, message: "" });
      const productCategoryFromAPI =
        modelConfiguration?.ProductCategories.productCategories.find(
          category => category.selected === "Y"
        );
      localStorage.setItem(
        "showGADate",
        modelConfiguration.modelData.showGADate
      );

      fetchDesktopUiSpec(productCategoryFromAPI, modelConfiguration);
      batch(() => {
        dispatch({
          type: CONFIG_STEP_SELECTION,
          selectedStep: "hardware"
        });
        dispatch({
          type: SET_CONFIG_LOAD_MODEL,
          configuration: modelConfiguration?.configuration
        });
        dispatch({
          type: SET_KMAT_ID,
          payload: modelConfiguration?.modelData?.kmatId
        });
        dispatch({
          type: SET_SPC_ID,
          payload: modelConfiguration?.configuration?.configHeader?.configId
        });
        dispatch({
          type: SET_ACCESS_TYPE,
          payload:
            modelConfiguration?.configuration?.configHeader?.accessType?.toLowerCase()
        });
        dispatch({
          type: SET_ORIGINAL_ACCESS_TYPE,
          payload:
            modelConfiguration?.configuration?.configHeader?.accessType?.toLowerCase() ||
            "organization"
        });
      });
      setCurrency(modelConfiguration?.modelData?.currency);
      const startDate =
        modelConfiguration?.configuration?.configHeader?.startDate;
      const docType = modelConfiguration?.configuration?.configHeader?.docType;
      const date =
        docType === "SP"
          ? startDate
          : (docType === "QU" || docType === "CC") && !spcStartDate
          ? startDate
          : spcStartDate
          ? spcStartDate
          : "";

      if (date && date !== "NA" && date !== "") {
        dispatch({
          type: SET_IS_CONFIG_OVERDATE,
          payload:
            moment(date).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")
        });
        setConfigStartDate(date);
      }
    },
    onError() {
      setIsUISpecDataLoaded(false);
      setIsLoading(false);
      setError({ isError: true, message: error });
      displayNotistack.error(true, {
        snackbarMessage: t("common:productSelector.errorMessage.graphQLErrors"),
        errorMessage: error
      });
      if (typeof setOpen !== "undefined") {
        setOpen(false);
      }
    }
  });
  useEffect(() => {
    if (configID) {
      if (isSimpleModel) {
        getSimpleConfiguration({
          variables: {
            filter: {
              configId: configID,
              country: currentCountry,
              pricingGeo: isStandaloneAccess ? country : pricingGeo,
              originatingAsset: "OCIQ",
              currency: isStandaloneAccess ? "" : currencyCode,
              incoTerms: isStandaloneAccess ? "" : incoterms,
              routeToMarket: displayBusinessModel
                ? businessModel
                : routeToMarket || "Direct",
              resellerId: resellerID || "",
              mdcpOrgId: mDCPOrgID,
              banded: false,
              isStandAloneAccess: isStandaloneAccess,
              productType: proCategory || "compute",
              userType:
                userType?.toUpperCase() === "PARTNER" ? "Partner" : "Internal",
              language: locale,
              storeFront: "IQ"
            }
          }
        });
      } else {
        modelConfiguration({
          variables: {
            filter: {
              configId: configID,
              country: currentCountry,
              pricingGeo: isStandaloneAccess ? country : pricingGeo,
              originatingAsset: "OCIQ", // two possible values are OCIQ and OCA. This code is not rendered during OCA, hence hardcoded to OCIQ.
              currency: isStandaloneAccess ? "" : currencyCode,
              incoterms: isStandaloneAccess ? "" : incoterms,
              routeToMarket: displayBusinessModel
                ? businessModel
                : routeToMarket || "Direct",
              resellerId: resellerID || "",
              mDCPOrgID: mDCPOrgID,
              banded: false,
              isStandAloneAccess: isStandaloneAccess,
              purchaseAgreementNumber: purchaseAgreement,
              productType: proCategory || "compute",
              userType:
                userType?.toUpperCase() === "PARTNER" ? "Partner" : "Internal",
              language: locale,
              storeFront: "IQ"
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    configID,
    countryCode,
    channelRegion,
    pricingGeo,
    incoterms,
    routeToMarket,
    resellerID,
    mDCPOrgID,
    modelQuery,
    dispatch,
    purchaseAgreement,
    proCategory
  ]);

  useEffect(() => {
    if (configOrigin === "OCA") {
      updateIframeHeight(isLoading);
    }
  }, [
    isLoading,
    stepDetails,
    steppersList,
    error,
    isUISpecDataLoaded,
    configID,
    kmatId,
    expandAll
  ]);

  const updateIframeHeight = async (isLoading = false) => {
    await delay(1500);
    if (document.querySelector("#product-selector")) {
      const height = isLoading
        ? window.document.querySelector("body")?.offsetHeight
        : window.document.querySelector("#configContainer")?.offsetHeight;
      document.querySelector("#product-selector").dispatchEvent(
        new CustomEvent("iframeloaded", {
          detail: {
            height: `${height + 100}`,
            showBOMSectionFlag,
            bomList: bomData
          }
        })
      );
    } else if (document.querySelector("#configurator")) {
      const height = isLoading
        ? window.document.querySelector("body")?.offsetHeight
        : window.document.querySelector("#configContainer")?.offsetHeight;
      document.querySelector("#configurator").dispatchEvent(
        new CustomEvent("iframeloaded", {
          detail: {
            height: `${height + 100}`,
            showBOMSectionFlag,
            bomList: bomData
          }
        })
      );
    }
  };
  const triggerWebAssembly = (data, configuration) => {
    let payload = {
      itemChanges: [{}]
    };

    data.startDate = configuration?.configHeader?.startDate;
    data.showGaDateConfigError = showGaDateConfigError;
    window._modelJson = data;
    const oneModel = new loadOneModel(data);
    const { cpPayload, nonCpPayload, chaId } = segregatePayload(payload, data);
    if (window._WASM_DELAY) {
      clearTimeout(parseModelTimer.current);
      parseModelTimer.current = setTimeout(
        () => {
          parseModelObj(
            isGoEngine && !window._DISABLE_WASM
              ? window.updateModelWithMultipleChanges &&
                  window.updateModelWithMultipleChanges(JSON.stringify(payload))
              : oneModel.updateModelWithMultipleChanges(nonCpPayload),
            configuration
          );
        },
        isGoEngine ? window._WASM_DELAY : 5
      );
    } else {
      myInternal = setInterval(
        () => {
          if (isGoEngine) {
            window.updateModelWithMultipleChanges &&
              parseModelObj(
                !window._DISABLE_WASM &&
                  window.updateModelWithMultipleChanges(
                    JSON.stringify(payload)
                  ),
                configuration
              );
          } else {
            parseModelObj(
              oneModel.updateModelWithMultipleChanges(nonCpPayload),
              configuration
            );
          }
        },
        isGoEngine ? 1000 : 5
      ); //Fix issue 43300 - Increasing the delay to make sure wasm engine is
      // ready before calling it
    }
  };

  function parseModelObj(isSuccess, configuration) {
    if (isSuccess || window._DISABLE_WASM) {
      if (
        localStorage.getItem("isDebug") === "true" ||
        localStorage.getItem("showTime") === "true"
      ) {
        console.log("isSuccess on first load", isSuccess);
      }
      let modelJson;
      let selectedItems = [];
      if (typeof window._modelJson !== "undefined") {
        !window._WASM_DELAY && clearInterval(myInternal);
        modelJson = isGoEngine ? window._modelJson : isSuccess;
        setIsSpecOrigin(modelJson);
        selectedItems =
          (modelJson.Items &&
            modelJson.Items.filter(
              item =>
                item.selected === true &&
                item.partno !== "" &&
                item.visible === true
            )) ||
          [];
      }

      if (isSKUModule && isRestoration && localizationCode?.length) {
        const locOptions = localizationOptions;
        locOptions.forEach(locType =>
          updateLocalization({ [locType]: localizationCode }, modelJson)
        );
      }

      dispatch({
        type: SET_CONFIG_LOAD_MODEL,
        modelData: updatedModelData(modelJson, t),
        configuration: !isSkuCloning ? configuration : skuConfiguration
      });
      dispatch({
        type: SET_ORIGIN_SELECTED_ITEMS,
        selectedItems: JSON.stringify(selectedItems),
        haveSaved: false
      });
      clearTimeout(loadTimer.current);
      loadTimer.current = setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }
    let modelJson = isGoEngine ? window._modelJson : isSuccess;
    if (document.querySelector("#configurator")) {
      document.querySelector("#configurator").dispatchEvent(
        new CustomEvent("changeItem", {
          detail: { ...modelJson }
        })
      );
    }
  }

  const updateUISpecWithMandaAndPolyCPChaIds = modelData => {
    const tabChaidsList = window.uiSpecData;
    const indexOfCarepacks =
      tabChaidsList?.UISpec[0]?.sections?.findIndex(
        section => section.id === "carePacks"
      ) || -1;

    const indexOfPoly =
      tabChaidsList?.UISpec[0]?.sections[
        indexOfCarepacks
      ]?.subsections?.findIndex(
        choice => choice.title === "Poly Accessories Care Packs"
      ) || -1;
    const indexOfManda =
      tabChaidsList?.UISpec[0]?.sections[
        indexOfCarepacks
      ]?.subsections?.findIndex(
        choice => choice.title === "HP Monitors & Accessories Care Packs"
      ) || -1;

    // filtering manda and poly chaids here
    let polyChaIds = [];
    let mandaChaIds = [];
    let highestCPSequence =
      indexOfCarepacks !== -1
        ? Math.max(
            ...window.uiSpecData?.UISpec[0]?.sections[
              indexOfCarepacks
            ]?.subsections?.map(subsection =>
              subsection?.choices?.map(item => item.sequence)
            ),
            0
          )
        : "";
    modelData?.Chaids?.forEach(item => {
      if (item.chaId.startsWith("CAPQ_") && !item.chaId.startsWith("CAPQ_P_")) {
        mandaChaIds.push({ id: item.chaId, title: item.chaDes });
      }

      if (item?.chaId.startsWith("CAPQ_P_")) {
        polyChaIds.push({ id: item.chaId, title: item.chaDes });
      }
    });
    const compareMandaAndPoly = (a, b) => {
      if (
        (findParentChoiceId(modelData, a.id) === "ASCM_64283" &&
          findParentChoiceId(modelData, b.id) === "ASCM_3242660") ||
        (findParentChoiceId(modelData, a.id) === "ASCM_POLYMON" &&
          findParentChoiceId(modelData, b.id) === "ASCM_POLYACC")
      )
        return -1;
      return 0;
    };
    mandaChaIds = mandaChaIds.sort(compareMandaAndPoly).map(item => {
      highestCPSequence = highestCPSequence + 5;
      return {
        ...item,
        sequence: highestCPSequence
      };
    });
    polyChaIds = polyChaIds.sort(compareMandaAndPoly).map(item => {
      highestCPSequence = highestCPSequence + 5;
      return {
        ...item,
        sequence: highestCPSequence
      };
    });

    if (mandaChaIds.length && indexOfManda > -1) {
      window.uiSpecData.UISpec[0].sections[indexOfCarepacks].subsections[
        indexOfManda
      ].choices = mandaChaIds;
    }

    if (polyChaIds.length && indexOfPoly > -1) {
      window.uiSpecData.UISpec[0].sections[indexOfCarepacks].subsections[
        indexOfPoly
      ].choices = polyChaIds;
    }
  };

  async function fetchDesktopUiSpec(category, result) {
    const uiSpecFile = getUiDataFileName(category?.name);
    //To avoid setting the isLoading flag to false before the UISpec loaded, I moved the triggerwebassemble function inside Axios calls to fetchDesktopUiSpec for reconfigure flow.
    if (dataType === "configurator") {
      dispatch({
        type: SET_SELECTED_CATEGORY_FROM_API,
        payload: {
          category: category?.name,
          series: category?.series?.find(series => series.selected === "Y")
            ?.name
        }
      });
      await getUISpecData.getData(uiSpecFile);
      setIsUISpecDataLoaded(true);
    }

    const modelDataResult = isSimpleModel ? result : result?.modelData;
    const configurationData = result?.configuration;
    window.uiSpecData && updateUISpecWithMandaAndPolyCPChaIds(modelDataResult);
    triggerWebAssembly(modelDataResult, configurationData);
  }
  useEffect(() => {
    if (window.uiSpecData && (configOrigin === "OCA" || isUploadConfig)) {
      updateUISpecWithMandaAndPolyCPChaIds(modelData);
    }
  }, [modelData, configOrigin, isUploadConfig]);

  useEffect(() => {
    if (
      categoryFromAPI ||
      (configOrigin === "OCA" && productCatalog) ||
      isSkuCloning
    ) {
      let categoryVal = categoryFromAPI || productCatalog;
      let selectedCategoryName;
      if (categoryVal) {
        selectedCategoryName = categoryVal;
      } else {
        selectedCategoryName = "Business Desktop PCs";
      }
      async function fetchDesktopUiSpec(category) {
        let uiSpecFile = categoryFromAPI
          ? getUiDataFileName(categoryFromAPI)
          : getUiDataFileName(category);
        await getUISpecData.getData(uiSpecFile);
        setIsUISpecDataLoaded(true);
      }
      fetchDesktopUiSpec(selectedCategoryName);
    }
  }, [categoryFromAPI]);
  console.log("testing for itg2 env ");
  useEffect(() => {
    if (kmatId && isWebAInit && window.uiSpecData) {
      // dispatch({
      //   type: SET_CONFIG_LOAD_MODEL,
      //   configuration: {},
      //   modelData: config
      // });
      webAssemblyTimer.current = setTimeout(() => {
        triggerWebAssembly(config, config.configuration);
      }, 300);
      // setIsLoading(false);
      setError({ isError: false, message: "" });
    }
    return () => {
      clearTimeout(webAssemblyTimer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmatId, config, isWebAInit, window.uiSpecData]);

  useEffect(() => {
    dispatch({
      type: "SET_CURRENT_CONFIGID",
      payload: configID
    });
  }, [configID]);
  const setCountryRegionOnMount = countryRegions => {
    let countryRegion = "";
    countryRegions &&
      currentCountry &&
      Object.keys(countryRegions).forEach(region => {
        const country = countryRegions[region].countries.find(
          country => country.code === currentCountry
        );
        if (country) {
          countryRegion = region;
        }
      });
    dispatch({
      type: SET_COUNTRY_REGION,
      payload: countryRegion
    });
  };

  useQuery(PRODUCT_SELECTION, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        region: countryRegion || channelRegion || "",
        country: currentCountry || "",
        routeToMarket: displayBusinessModel
          ? businessModel
          : routeToMarket || "Direct"
      },
      transactionId: transactionID || ""
    },
    onCompleted({ productSelection }) {
      const countryDataMap =
        productSelection.ProductSelectionData.regionLookupDataMap;
      setCountryRegionOnMount(countryDataMap);
      setLocalisationData(productSelection.ProductSelectionData.localizations);
      dispatch({
        type: "SET_SHOW_GADATE",
        payload: productSelection.ShowGADate
      });

      productSelection?.ocfg_params?.OCIQ?.[proCategory]?.[channelRegion] &&
        localStorage.setItem(
          "gaFutureDateLimit",
          productSelection?.ocfg_params?.OCIQ?.[proCategory]?.[channelRegion]
            ?.ALL?.all?.generic?.GAFutureDtLmt
        );

      const region = countryRegion || channelRegion;
      const filterLevel = [
        originatingAsset || configOrigin || "OCIQ",
        proCategory,
        region,
        currentCountry,
        userType && userType?.toUpperCase() === "PARTNER"
          ? "partner"
          : "internal"
      ];
      let ocfgParams = [productSelection.ocfg_params];

      // Select both the data of target parameters and "ALL"/"all" data at the same level
      filterLevel.map(val => {
        let filteredArr = [];

        ocfgParams.map(item => {
          isSKUModule
            ? filteredArr.push(
                item?.[val],
                item?.["ALL"] || item?.["all"],
                item?.[defaultSKUType]
              )
            : filteredArr.push(item?.[val], item?.["ALL"] || item?.["all"]);
        });
        ocfgParams = filteredArr.filter(value => value !== undefined);
      });
      const ocfgParamsData = ocfgParams.reduce((pre, cur) => {
        return {
          monitorQty: { ...cur?.Monitor_Qty, ...pre?.Monitor_Qty },
          hideTab: { ...cur?.hideTab, ...pre?.hideTab },
          hideChoice: { ...cur?.hideChoice, ...pre?.hideChoice }
        };
      }, {});
      dispatch({
        type: "SET_OCFG_PARAMS",
        payload: ocfgParamsData
      });
      setHasOcfgParams(true);
    },

    onError({ graphQLErrors, networkError }) {
      if (localStorage.getItem("isDebug") === "true") {
        if (graphQLErrors) {
          console.log(graphQLErrors);
        }
        if (networkError) {
          console.log(networkError);
        }
      }
      setHasOcfgParams(true);
    }
  });

  let panelContainer = {
    html: (
      <Grid item xs={12} sm={8} key="panel">
        <ConfigHome
          proCategory={proCategory}
          localisationData={localisationData}
        />
      </Grid>
    ),
    position: PANEL_CONTAINER_POSITION
  };

  let bomContainer = {
    html: (
      <Grid item xs={12} sm={4} key="bom">
        <ConfigBOM />
      </Grid>
    ),
    position: BOM_CONTAINER_POSTION
  };

  function navigateToTop() {
    let topRefLink = document.querySelector("#configContainer");
    topRefLink?.scrollIntoView({ behavior: "smooth" });
  }

  let containers = [panelContainer, bomContainer];
  containers.sort((c1, c2) => (c1.position > c2.position ? 1 : -1));
  const lottieLoadingFile =
    "https://dev-ociq.hpcloud.hp.com/assets/lotties/product_selector_animation.json";

  return (
    <>
      {isLoading || !isUISpecDataLoaded || !hasOcfgParams ? (
        <div className={classes.midPositioner}>
          <Typography variant="h4" align="center">
            {t("common:loader.gifLoaderMessage1")}
          </Typography>
          <Typography align="center">
            {t("common:loader.gifLoaderMessage2")}
          </Typography>
          <MyAnimation
            lottieFile={lottieLoadingFile}
            style={{ height: "300px", width: "400px" }}
          />
        </div>
      ) : error.isError ? (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={HPMuiTheme}>
            <div className={classes.midPositioner}>{error.message}</div>
          </ThemeProvider>
        </StyledEngineProvider>
      ) : (
        <div className={classes.container} id="configContainer">
          <ConfigStepper
            getStepDetails={getStepDetails}
            stepDetails={stepDetails}
            proCategory={proCategory}
            setStepperList={setStepperList}
            configStartDate={configStartDate}
          />
          {proCategory !== "poly" && (
            <ObseleteWarningMessage proCategory={proCategory} />
          )}
          <br />
          <ConfigFilter
            proCategory={proCategory}
            name={configuration?.configHeader?.configName}
            currentCountry={currentCountry}
          />
          <br />
          <br />
          <Grid container spacing={3} id="container">
            {containers.map(container => container.html)}
          </Grid>
          <ScrollToTop />
        </div>
      )}
      {configOrigin !== "OCA" &&
        isPartnerPortalFlag &&
        isStandaloneAccess &&
        isPartnerUser && <RequestAssistanceButton />}
    </>
  );
};

export default ConfigContainer;

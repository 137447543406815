import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { togglePopupActions } from "../../../actions/togglePopupActions";

const RequestRskuV2 = () => {
  const dispatch = useDispatch();
  const { countryRegion } = useSelector(state => state.productSelector);
  const { skuServiceName, rskuInfo, isAddtoQuote } = useSelector(
    state => state.rskuReducer
  );
  const [isIntitalRender, setIsIntialRender] = useState(true);

  const { requestSkuDialog, confirmationDialog, rskuInfoDialog } = useSelector(
    state => state.rskuReducer.popups
  );

  const handleConfirmationDialogOpen = useCallback((params = {}) => {
    dispatch(togglePopupActions({ name: "confirmationDialog", open: true }));
    dispatch({ type: "SET_RSKU_INFO_DIALOG_ALERT", payload: false });
  }, []);

  const handleRskuInfoDialogOpen = useCallback((params = {}) => {
    dispatch(
      togglePopupActions({
        name: "rskuInfoDialog",
        open: true
      })
    );
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsIntialRender(false);
    }, 2000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (!isIntitalRender) {
      if (
        !(
          requestSkuDialog?.open ||
          confirmationDialog?.open ||
          rskuInfoDialog?.open
        ) &&
        !isAddtoQuote
      ) {
        dispatch({
          type: "SET_IS_REQUEST_RSKU",
          payload: false
        });
        document.querySelector("#product-selector").dispatchEvent(
          new CustomEvent("smartSearchIntegration", {
            detail: rskuInfo
          })
        );
      }
    }
  }, [isIntitalRender, requestSkuDialog, confirmationDialog, rskuInfoDialog]);

  useEffect(() => {
    countryRegion === "NA" && skuServiceName === "exactMatch"
      ? handleConfirmationDialogOpen()
      : handleRskuInfoDialogOpen();
  }, []);

  return null;
};

export default RequestRskuV2;

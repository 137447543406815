import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  IconButton,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Chip
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CONFIG_VERTICAL_STEP_SELECTION,
  SET_VERTICAL_SCROLLTO_CHAID
} from "../../../actions/Types";
import ChaidSelectionType from "../../../configurator/ChaidSelectionType";
import { CarePackAdditional } from "../../../configurator/Localization/CarePackAdditional";
import { CarePackRecommendation } from "../../../configurator/Localization/CarePackRecommendation";
import { MandaCarePacks } from "../../../configurator/MandaCarePack";
import UnSplitCarepacks from "../../../configurator/UnSplitCarepacks";
import { checkStoreFront, scrollToStepper } from "../../../lib/common/util";
import {
  fetchUiSpecVerticalView,
  isChaidRenderable,
  shouldHideChoices
} from "../../../services/common/utils";
import ExpandAndCollapseBtn from "../ConfigExpansionDetails/ExpandAndCollapseBtn";
import { ExternalProps } from "../../../contexts/externalPropsContext";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  iconSize: {
    width: "32px",
    height: "32px",
    color: "#0096d7"
  },
  iconSizeError: {
    width: "32px",
    height: "32px"
  },
  stepTitle: {
    fontSize: "20px",
    color: "rgba(0,0,0,0.87)"
  },
  invisibleButton: {
    "& > span.MuiStepLabel-iconContainer": {
      display: "none"
    }
  },
  stepContentInvisible: {
    borderLeft: "0px",
    marginLeft: "0px",
    paddingLeft: "0px"
  },
  verticalStepConnector: {
    "& > div.MuiStepConnector-root > span": {
      borderLeft: "0px",
      minHeight: "5px"
    }
  },
  fontWeight: {
    fontWeight: "bold"
  }
}));

const ConfigVerticalStepper = ({
  proCategory,
  localisationData,
  handleItemChange,
  handleQuantityChange
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const modelData = useSelector(state => state.configFilterData.modelData);
  const steppers = useSelector(state => state.configFilterData.steppers);
  const { configOrigin, userType } = React.useContext(ExternalProps);
  const isPartnerPortalFlag = userType?.toUpperCase() === "PARTNER";
  const verticalStepper = useSelector(
    state => state.configFilterData.verticalStepper
  );
  const scrollToChaId = useSelector(
    state => state.configFilterData.scrollToChaId
  );
  const searchText = useSelector(state => state.configFilterData.searchText);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const [activeStep, setActiveStep] = useState(0);
  let filteredChoices = useSelector(
    state => state.configFilterData.filteredChoices
  );
  const filteredChoiceData = useSelector(
    state => state.configFilterData.filteredChoices
  );
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const showWarning = useSelector(state => state.configFilterData.showWarning);
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const isChaidHideBasedOnRegion = chaidId => {
    return shouldHideChoices(ocfgParams?.hideChoice, chaidId, modelData);
  };
  const isHP2BOrECOMMConfig = checkStoreFront(modelData);
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected);
  const activeCarePack =
    defaultConfigStepSlected === "carePacks" && verticalStepper?.carePacks;
  const activeOtherServices =
    defaultConfigStepSlected === "otherServices" &&
    verticalStepper?.otherServices;
  const activeConfigurationService =
    defaultConfigStepSlected === "configurationService" &&
    verticalStepper?.configurationService;
  const activedeploymentServices =
    defaultConfigStepSlected === "deploymentServices" &&
    verticalStepper?.deploymentServices;
    const activePolySolutions =
      defaultConfigStepSlected === "hardware" && verticalStepper?.hardware;

  filteredChoices =
    defaultConfigStepSlected === "deploymentServices" &&
    isVerticalView === "Y" &&
    ["EU", "AP", "NA"].includes(configuration?.configHeader?.rgnCd)
      ? filteredChoices
          .filter(
            item =>
              !shouldHideChoices(ocfgParams?.hideChoice, item.chaId, modelData)
          )
          .map(chaid => (chaid ? chaid : null))
      : filteredChoices;

  const renderedTimeout = useRef();
  const observerRef = useRef();
  const [isCompleted, setIsCompleted] = useState(false);
  useEffect(() => {
    if (isCompleted) {
      scrollToStepper(activeStep);
      setIsCompleted(false);
      observerRef.current.disconnect();
    }
  }, [isCompleted, activeStep]);
  const renderObserve = stepIndex => {
    const ele = document.querySelector(`#stepper-${stepIndex}`);
    const config = { attributes: true, childList: true, subtree: true };
    const callback = function () {
      clearTimeout(renderedTimeout.current);
      renderedTimeout.current = setTimeout(() => {
        setIsCompleted(true);
      }, 400);
    };
    observerRef.current = new MutationObserver(callback);
    observerRef.current.observe(ele, config);
  };
  const handleStep = (stepIndex, label) => {
    setActiveStep(stepIndex);
    dispatch({
      type: CONFIG_VERTICAL_STEP_SELECTION,
      payload: {
        ...verticalStepper,
        [defaultConfigStepSlected]: label
      }
    });
      dispatch({
        type: SET_VERTICAL_SCROLLTO_CHAID,
        payload: ""
      });
    
    renderObserve(stepIndex);
  };
  // Filtering Parent choices. eg:hpCarePackService, SUPPORT_EXTENSIONS etc...
  const uiSpecChoices = useMemo(
    () =>
      steppers
        ? steppers.find(section => section.id === defaultConfigStepSlected)
            .subsections
        : [],
    [steppers, defaultConfigStepSlected]
  );
  // Here we are comparing the steps from UISpec with modelData and filter out the child chaIds.
  let visibleSteps = [];
  uiSpecChoices.forEach(uiSpecChoice => {
    for (let index = 0; index < uiSpecChoice?.choices?.length; index++) {
      const filterChoiceId = filteredChoices.map(choice => choice.chaId);
      const choice = uiSpecChoice.choices[index];
      let matchedChaid =
        searchText.length > 0
          ? modelData.Chaids.find(
              chaid =>
                chaid.chaId === choice.id &&
                isChaidRenderable(
                  chaid,
                  modelData.Items,
                  modelData,
                  isPartnerPortalFlag
                ) &&
                filterChoiceId.includes(chaid.chaId)
            )
          : modelData.Chaids.find(
              chaid =>
                chaid.chaId === choice.id &&
                isChaidRenderable(
                  chaid,
                  modelData.Items,
                  modelData,
                  isPartnerPortalFlag
                ) 
            );
      if (matchedChaid) {
        visibleSteps.push(uiSpecChoice);
        break;
      }
    }
  });

  visibleSteps = [...new Set(visibleSteps)];
  const verticalStepSelected = visibleSteps.filter(
    (choice, index) => index == activeStep
  );

  const customCPChaids = ["HP Care Pack Services"]; // used to render top and recommended Care packs
  const mandaOrPolyCPchaIds = [
    "HP Monitors & Accessories Care Packs",
    "Poly Accessories Care Packs"
  ];

  const visibleChaIds = visibleSteps?.reduce((pre, cur) => {
    return [...pre, ...cur?.choices?.map(item => item?.id)];
  }, []);

  const filterStepIndex = visibleSteps?.findIndex(choice =>
      scrollToChaId === "" ||
      (scrollToChaId !== "" && !visibleChaIds.includes(scrollToChaId))
        ? choice?.title === activeOtherServices ||
          choice?.title === activeCarePack ||
          choice?.title === activeConfigurationService ||
          choice?.title === activedeploymentServices ||
          choice?.title === activePolySolutions
        : choice.choices.map(chaid => chaid.id).includes(scrollToChaId) ||
          (customCPChaids.includes(choice.title) &&
            ["ELECTCPQ"].includes(scrollToChaId))
    );

  useEffect(() => {
   if (searchText.length === 0 && (filterStepIndex == 0 || filterStepIndex)) {
      setActiveStep(filterStepIndex > -1 ? filterStepIndex : 0);

      dispatch({
        type: CONFIG_VERTICAL_STEP_SELECTION,
        payload: {
          ...verticalStepper,
          [defaultConfigStepSlected]:
            visibleSteps[filterStepIndex]?.title || visibleSteps[0]?.title
        }
      });
    }
  }, [defaultConfigStepSlected, scrollToChaId, searchText, filterStepIndex]);
  useEffect(() => {
    if (searchText.length > 0) {
      setActiveStep(0);
    }
  }, [searchText]);
  // Filtering choices based on the vertical step selected
  filteredChoices = filteredChoices.filter(
    choices => choices.parentId === verticalStepSelected[0]?.id
  );
  // fetchNonCustomChaids would handle the return of non custom chaid components i.e regular chaidds like Base unit, OS etc.
  const fetchNonCustomChaids = () => {
    return filteredChoices.map(chaid => {
      return isChaidRenderable(chaid, modelData.Items, modelData) ? (
        <ChaidSelectionType
          key={chaid.chaId}
          chaid={chaid}
          modelData={modelData}
          handleItemChange={handleItemChange}
          handleQuantityChange={handleQuantityChange}
        />
      ) : null;
    });
  };

  // fetchChaids would handle the return of custom and non custom chaid components
  const fetchChaids = step => {
    if (customCPChaids.includes(step.title)) {
    return isHP2BOrECOMMConfig ? (
      <UnSplitCarepacks
        filteredChoices={filteredChoices}
        modelData={modelData}
        handleItemChange={handleItemChange}
        handleQuantityChange={handleQuantityChange}
      />
    ) : (
      <>
        <CarePackRecommendation
          filteredChoices={filteredChoices}
          proCategory={proCategory}
          isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
          modelData={modelData}
          handleItemChange={handleItemChange}
        />
        <CarePackAdditional
          filteredChoices={filteredChoices}
          isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
          modelData={modelData}
          handleItemChange={handleItemChange}
        />
      </>
    );
   
    } else if (mandaOrPolyCPchaIds.includes(step.title)) {
      return (
        <MandaCarePacks
          filteredChoices={filteredChoices}
          isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
          modelData={modelData}
          handleItemChange={handleItemChange}
          handleQuantityChange={handleQuantityChange}
          showWarning={showWarning}
        />
      );
    } else {
      return fetchNonCustomChaids();
    }
  };

  const fetchStepContent = step => {
    return (
      <>
        <ExpandAndCollapseBtn proCategory={proCategory} />
        {fetchChaids(step)}
      </>
    );
  };

  // Function to fetch success/error icon
  const fetchIcon = choices => {
    const modelConflicts = _.pickBy(
      modelData?.conflicts[0],
      conflicts => conflicts.length > 0
    );
    let otherConflicts = [];
    let itemConflictsList = [];
    let choiceorhiddenConflicts = [];
    const conflictKey = {
      itemConflictsList: ["itemConflictsList"],
      choiceorhiddenConflicts: ["choiceConflicts", "hiddenChoiceConflicts"]
    };
    Object.keys(modelConflicts).map(conflict => {
      if (conflictKey["itemConflictsList"].includes(conflict)) {
        // Handling itemConflictsList
        itemConflictsList = [...itemConflictsList, ...modelConflicts[conflict]];
      } else if (conflictKey["choiceorhiddenConflicts"].includes(conflict)) {
        // Handling choiceConflicts, hiddenChoiceConflicts
        choiceorhiddenConflicts = [
          ...choiceorhiddenConflicts,
          ...modelConflicts[conflict]
        ];
      } else {
        // Handling icConflicts, plcConflicts, counterConflicts, gaDateConflicts
        otherConflicts = [...otherConflicts, ...modelConflicts[conflict]];
      }
    });

    const hasConflict =
      choices?.length &&
      choices?.some(
        choice =>
          otherConflicts.some(
            conflictItem => conflictItem?.chaId === choice.id
          ) ||
          itemConflictsList.some(
            conflictItem => conflictItem.effect === choice.id
          ) ||
          choiceorhiddenConflicts.some(
            conflictItem => conflictItem.cause?.chaId === choice.id
          )
      );

    if (hasConflict)
      return (
        <CancelOutlinedIcon color="error" className={classes.iconSizeError} />
      );

    return <CheckCircleOutlineIcon className={classes.iconSize} />;
  };
  const getCardCount = (visibleSteps, filteredChoiceData) => {
    const cardCount = {};

    visibleSteps.forEach(step => {
      cardCount[step.id] = 0;
    });
    filteredChoiceData.forEach(filteredChoice => {
      const hasSelectedItems = filteredChoice.items.some(
        item => item?.selected
      );
      if (hasSelectedItems) {
        cardCount[filteredChoice.parentId]++;
      }
    });

    return cardCount;
  };

  const cardCount = getCardCount(visibleSteps, filteredChoiceData);
  const filterParentId = filteredChoices.map(choices => choices.parentId);

  return (
    <div className={classes.root}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation="vertical"
        className={classes.verticalStepConnector}
      >
        {visibleSteps.map((step, index) => (
          <Step key={index}>
            <StepButton
              id={`stepper-${index}`}
              onClick={() => handleStep(index, step.title)}
              icon={fetchIcon(step.choices)}
            >
              <StepLabel className={classes.invisibleButton}>
                <Typography className={classes.stepTitle}>
                  {step.title}
                  &nbsp;
                  {cardCount[step.id] > 0 &&(
                    <Chip label={`${cardCount[step.id]}  choice(s) selected`} className={classes.fontWeight} />
                  )}
                  &nbsp;
                  <IconButton>
                    {filterParentId.includes(step.id) ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Typography>
              </StepLabel>
            </StepButton>
            <StepContent className={classes.stepContentInvisible}>
              {fetchStepContent(step)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default ConfigVerticalStepper;

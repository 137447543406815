import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SET_CONFIG_NAME } from "../../../actions/Types";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "0px 0px 8px 0px"
  },
  configid_container: {
    height: "74px",
    background: "#FFFFFF",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px",
    padding: "0 15px",
    position: "relative"
  },
  heading: {
    fontSize: "16px",
    color: "#000000"
  },
  secondaryHeading: {
    color: "#000000DE",
    padding: "6px 24px 6px 0px",
    borderBottomColor: "white !important",
    "&.MuiTypography-root" :{
      fontSize: "12px"
    }
  },
  textbox: {
    width: "90%",
    height: "28px",
    background: "#FFFFFF",
    border: "1px solid #EEEEEE",
    boxSizing: "border-box",
    borderRadius: "4px",
    color: "#000000",
    fontSize: "1rem",
    fontWeight: "normal",
    padding: "0px 6px",
    marginLeft: "-6px"
  },
  characterCount: {
    fontSize: "11px",
    color: "darkgray",
    position: "absolute",
    right: "10px",
    bottom: "0px"
  }
}));

const ConfigName = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const configNameRed = useSelector(
    state => state.configFilterData.configNameRed
  );
  const category = useSelector(
    state => state.productSelector.category
  );


  const [name, setName] = useState(configNameRed ? configNameRed : props.name);
  const { t } = useTranslation();

  React.useEffect(() => {
    const timeOutId = setTimeout(() => dispatch({
      type: SET_CONFIG_NAME,
      payload: name
    }) , 500);
    return () => clearTimeout(timeOutId);
  }, [dispatch, name]);

  const handleOnChange = e => {
    setName(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="center"
        className={classes.configid_container}
      >
        <Grid item xs={12} sm={6}>
          <Typography className={classes.heading}>
            {t("common:productSelector.label.configName")}{" "}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {t("common:configurator:typeconfigname")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <input
            type="text"
            maxLength="100"
            className={classes.textbox}
            value={name}
            onChange={handleOnChange}
          />
          {/* <Typography className={classes.secondaryHeading}>Please type in your configuration name for this configuration.</Typography> */}
          {/* <TextField type="text" className={classes.textbox} value={props.name}></TextField> */}
        </Grid>
        <Typography className={classes.characterCount}>
          {name ? name && name.trim().length : 0}/100 Character
        </Typography>
      </Grid>
    </div>
  );
};

export default ConfigName;

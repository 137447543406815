import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BOM_SELECTION, UPDATE_CHOICE_EXPAND } from "../actions/Types";
import { ExternalProps } from "../contexts/externalPropsContext";
import useIsInViewport from "../hooks/useIsInViewport";
import {
  bomThreeInfoMessage,
  calculateDays,
  displayCurrencyLite,
  getMandaChaid,
  icConflictsFilter,
  isChaidExpanded,
  mandaConflictsFilter,
  obsoleteDateFormat,
  transformDateFormat,
  updateExpandAllChoicePayload,
  updateIframeHeight
} from "../lib/common/util";
import { getExpandState, isConflictsExist } from "../services/common/utils";
import ErrorPart from "./ConflictsError";
import ItemMultipleCheckBox from "./ItemMultipleCheckbox";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxHeight: "100%",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important",
    fontSize: theme.typography.pxToRem(12)
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: 400,
    padding: "8px 0px 0px 0px"
  },
  secondaryHeading: {
    "&.MuiTypography-root": {
      fontSize: theme.typography.pxToRem(16),
      color: "#000000",
      fontWeight: 300,
      padding: "8px 0px 0px 18px"
      // marginLeft: "1.5rem"
    },
    overflow: "hidden"
  },
  choiceLevelErrmsgCls: {
    backgroundColor: "red",
    color: "white",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "grid !important"
  },
  choiceLevelMarginTop: {
    marginTop: "0px !important"
  },
  warningStyle: {
    backgroundColor: "#ffbf00",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  warningErrorStyle: {
    backgroundColor: "#FD0032",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  caption: {
    paddingTop: "7px"
  },
  currencyStyle: {
    fontWeight: "bold",
    fontSize: "12px"
  },
  captionStyle: {
    fontSize: "12px",
    fontWeight: "normal",
    paddingTop: "3px"
  },
  choiceBannerStyles: {
    backgroundColor: "#0096D6",
    color: "#FFFFFF",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    padding: "8px 16px"
  },
  banner: {
    backgroundColor: "#0096D6",
    color: "#FFFFFF",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "grid !important"
  }
}));

function ChaidMultiple({
  chaid,
  model,
  handleItemChange,
  handleQuantityChange
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const errorData = useSelector(state => state.configFilterData.errorData);
  const showWarning = useSelector(state => state.configFilterData.showWarning);
  const bomSelected = useSelector(state => state.configFilterData.bomSelected);
  const [expanded, setExpanded] = React.useState(false);
  const { currencyCode, isSKUModule = false } = React.useContext(ExternalProps);
  const configFilterData = useSelector(state => state.configFilterData);
  // const isExpandAll = getExpandState(configFilterData).expandValue || false;

  const { isVerticalStepper, expandValue } = getExpandState(configFilterData);
  const [elementRef, isInViewport] = useIsInViewport(
    {
      root: null,
      rootMargin: "0px",
      threshold: expanded ? 0.1 : 0.4
    },
    isVerticalStepper
  );
  const isExpandAll =
    typeof expandValue === "boolean" ? expandValue : isVerticalStepper;

  const isChaidExpand = useSelector(
    state => state.configFilterData.isChaidExpand
  );
  const conflictFilter = useSelector(
    state => state.configFilterData.conflictFilter
  );

  const itemQuantityRef = useRef(false);
  const timer = useRef(null);
  const [finalToggleExpandCollapse, setFinalToggleExpandCollapse] =
    React.useState(false);

  const handleToggle = () => {
    if (isInViewport) return true;

    if (!isInViewport && itemQuantityRef.current) return true;

    return false;
  };
  const relevantTabChoice = useMemo(
    () =>
      expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      )?.choicesExpandState[chaid.chaId],
    [expandAll, defaultConfigStepSlected]
  );
  const toggleExpandCollapse = handleToggle();
  useEffect(() => {
    updateIframeHeight(false);
  }, [expanded, toggleExpandCollapse]);
  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setFinalToggleExpandCollapse(toggleExpandCollapse);
    }, 300);
  }, [toggleExpandCollapse]);
  const handleChange = e => {
    e.preventDefault();
    if (isExpandAll) {
      dispatch({
        type: "SET_CHAID_EXPAND_COLLAPSE",
        payload: {
          ...isChaidExpand,
          [defaultConfigStepSlected]: {
            ...isChaidExpand[defaultConfigStepSlected],
            [chaid.chaId]: !expanded
          }
        }
      });
    }
    setExpanded(prevState => !prevState);
    const payload = updateExpandAllChoicePayload(
      expandAll,
      chaid.chaId,
      expanded,
      defaultConfigStepSlected
    );
    dispatch({
      type: UPDATE_CHOICE_EXPAND,
      payload
    });
  };
  useEffect(() => {
    if (bomSelected === chaid.chaId) {
      setExpanded(true);
      dispatch({ type: BOM_SELECTION, payload: "" });
    }
  }, [bomSelected]);

  useEffect(() => {
    setExpanded(
      isChaidExpanded({
        isExpandAll,
        isChaidExpand,
        chaid: chaid.chaId,
        defaultConfigStepSlected
      })
    );
  }, [isExpandAll]);

  //it will dispatch an action and update only the relevant choiceid with an inverse of current expand status

  const renderWarning = (val, formatEod, idx) => {
    return (
      <Box className={classes.warningStyle} padding={1} key={idx}>
        <Typography align="center" variant={"subtitle2"}>
          {`${t("common:configurator:obsolete90days")} - ${
            val.partno
          } ${formatEod}`}
        </Typography>
      </Box>
    );
  };

  //this will be triggered on component mount and will set the local state (expanded) after fetching results from from the redux store
  useEffect(() => {
    if (relevantTabChoice !== expanded) {
      const icConflicts = icConflictsFilter(
        model?.conflicts[0]?.icConflicts,
        chaid
      );
      if (
        icConflicts &&
        (chaid.chaId === "99999" ||
          chaid.chaId === "99998" ||
          chaid.chaId.startsWith("CAPQ_"))
      ) {
        setExpanded(true);
      } else {
        setExpanded(relevantTabChoice || false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevantTabChoice]);

  useEffect(() => {
    if (
      !relevantTabChoice &&
      chaid.chaId.startsWith("CAPQ_") &&
      !chaid.chaId.startsWith("CAPQ_P_")
    ) {
      const counterConflicts = mandaConflictsFilter(
        model?.conflicts[0]?.counterConflicts,
        chaid
      );
      const plcConflicts = mandaConflictsFilter(
        model?.conflicts[0]?.plcConflicts,
        chaid
      );
      if (
        (counterConflicts || plcConflicts) &&
        conflictFilter[0].chaId === getMandaChaid(chaid.chaId)
      )
        setExpanded(true);
    }
  }, [relevantTabChoice, conflictFilter]);

  let itemPriceObj = 0;
  let itemPrice;
  let labelDisplay = [];
  let topLabelDisplay = [];

  if (defaultConfigStepSlected === "monitoringAccesories") {
    if (Array.isArray(chaid.firstItem) && Array.isArray(chaid.lastItem)) {
      chaid.items.forEach(item => {
        if (item.selected) {
          labelDisplay.push(`${item.partno} : ${item.partdes} `);
          itemPrice = `${item.listPrice}`;
        }
      });
    } else {
      for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
        if (
          model &&
          model.Items &&
          model.Items[i].selected &&
          model.Items[i].priority !== "" &&
          parseInt(model.Items[i].priority) >= 0 &&
          parseInt(model.Items[i].priority) <= 5
        ) {
          labelDisplay.push(
            `${model.Items[i].partno} : ${model.Items[i].partdes} `
          );
          itemPrice = `${model.Items[i].netPrice}`;
        } else if (
          model &&
          model.Items &&
          model.Items[i].selected &&
          parseInt(model.Items[i].priority) > 5
        ) {
          labelDisplay.push(
            `${model.Items[i].partno} : ${model.Items[i].partdes} `
          );
          itemPrice = `${model.Items[i].netPrice}`;
        } else if (model && model.Items && model.Items[i].selected) {
          labelDisplay.push(
            `${model.Items[i].partno} : ${model.Items[i].partdes} `
          );
          itemPrice = `${model.Items[i].netPrice}`;
        }
      }
    }
  } else {
    if (Array.isArray(chaid.firstItem) && Array.isArray(chaid.lastItem)) {
      chaid.items.forEach(item => {
        if (item.selected) {
          labelDisplay.push(`${item.partno} : ${item.partdes} `);
          itemPrice = `${item.listPrice}`;
        }
      });
    } else {
      for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
        if (
          model &&
          model.Items &&
          model.Items[i].selected &&
          model.Items[i].priority !== "" &&
          (chaid.chaDes === "Top Recommended Poly Services" ||
            chaid.chaId === "99999" ||
            chaid.chaId.endsWith("_recommended")) &&
          parseInt(model.Items[i].priority) >= 0 &&
          parseInt(model.Items[i].priority) <= 5
        ) {
          topLabelDisplay.push(
            `${model.Items[i].partno} : ${model.Items[i].partdes} `
          );
          itemPrice = `${model.Items[i].netPrice}`;
        } else if (
          model &&
          model.Items &&
          model.Items[i].selected &&
          chaid.chaId !== "99999" &&
          chaid.chaDes !== "Top Recommended Poly Services" &&
          !chaid.chaId.endsWith("_recommended") &&
          (parseInt(model.Items[i].priority) > 5 ||
            model.Items[i].priority === "")
        ) {
          labelDisplay.push(
            `${model.Items[i].partno} : ${model.Items[i].partdes} `
          );
          itemPrice = `${model.Items[i].netPrice}`;
        }
      }
    }
  }
  let ldDisplay;
  if (labelDisplay.length === 1) {
    ldDisplay = labelDisplay;
  } else if (labelDisplay.length > 1) {
    ldDisplay = "";
  } else {
    ldDisplay = t("common:productSelector.none");
  }
  let topDisplay;
  if (topLabelDisplay.length === 1) {
    topDisplay = topLabelDisplay;
  } else if (topLabelDisplay.length > 1) {
    topDisplay = "";
  } else {
    topDisplay = t("common:productSelector.none");
  }
  let visibleItems = chaid.items.filter(
    part => (part.visible && part.precon >= 0) || part.selected
  );
  let isVisible =
    chaid.required ||
    chaid.selcon ||
    chaid.items.some(
      part =>
        part.partno !== "" &&
        (part.visible || part.selected) &&
        part.inode >= chaid.firstItem &&
        part.inode <= chaid.lastItem
    );
  let itemLevelError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.map(res =>
      errorData && errorData.name === chaid.chaId
        ? itemLevelError.push(" " + res.effect.chaDes)
        : null
    );
  const noConflictsExist =
    !isConflictsExist(model, chaid) && itemLevelError.length === 0;
  if (window._DEBUG) {
    if (itemLevelError?.length && localStorage.getItem("isDebug") === "true") {
      console.log("itemLevelError=", itemLevelError, ", MODEL=", model);
    }
  }
  Object.keys(chaid).forEach(function (key) {
    if (chaid.chaDes == "Top Recommended Care Packs") {
      chaid.chaDes = t("common:stepperTabs.carePacksSrc.topRecommend");
    } else if (chaid.chaDes == "Additional Care Pack") {
      chaid.chaDes = t("common:stepperTabs.carePacksSrc.additionalCarePack");
    }
  });

  const selectedItemsPartNo = useSelector(
    state => state.configFilterData.selectedItemsPartNo
  );
  return (
    isVisible && (
      <Box
        className={`${classes.root} choice-container data-${chaid.chaId}`}
        marginBottom="8px"
        key={"chaid-" + chaid.chaId}
        id={"chaid-" + chaid.chaId}
        data-order={
          icConflictsFilter(model?.conflicts[0]?.icConflicts, chaid)
            ? "show"
            : "hide"
        }
      >
        <Box>
          {chaid &&
            chaid.items &&
            chaid.items.map((val, idx) => {
              let formatEod = obsoleteDateFormat(val.esDate);
              const days = calculateDays(formatEod);
              const isDateGreater = new Date(formatEod) > new Date();
              return val.selected &&
                val.visible &&
                days <= 90 &&
                isDateGreater &&
                noConflictsExist
                ? renderWarning(
                    val,
                    transformDateFormat(val.esDate, "MM/DD/YYYY"),
                    idx
                  )
                : null;
            })}
          {!isSKUModule &&
            defaultConfigStepSlected !== "configurationService" &&
            bomThreeInfoMessage(
              selectedItemsPartNo,
              chaid,
              classes.choiceBannerStyles,
              t
            )}
        </Box>
        <Accordion
          expanded={expanded && finalToggleExpandCollapse}
          onChange={handleChange}
          disableGutters={true}
          ref={elementRef}
        >
          <AccordionSummary
            className={
              showWarning && showWarning.warning
                ? ""
                : classes.choiceLevelMarginTop
            }
            expandIcon={<ExpandMoreIcon />}
            aria-controls="itemPanelbh-content"
            id="itemPanelbh-header"
          >
            <Box flex={1} flexDirection="column">
              {/* `${chaid.required} `}  {`${ld} ` */}
              {/* error part starts */}
              {/* {Change to component form} */}
              <ErrorPart chaid={chaid} model={model} expanded={expanded} />
              <Box display="flex">
                <Typography
                  className={classes.heading}
                  component={"span"}
                  variant={"body2"}
                >
                  {chaid.chaDes}
                </Typography>
                <Typography
                  className={classes.secondaryHeading}
                  component={"span"}
                  variant={"body2"}
                >
                  {topDisplay &&
                  (chaid.chaId === "99999" ||
                    chaid.chaDes == "Top Recommended Poly Services" ||
                    chaid.chaId.endsWith("_recommended"))
                    ? topDisplay
                    : ""}
                  {ldDisplay &&
                  chaid.chaId !== "99999" &&
                  chaid.chaDes !== "Top Recommended Poly Services" &&
                  !chaid.chaId.endsWith("_recommended")
                    ? ldDisplay
                    : ""}
                </Typography>
              </Box>
              {labelDisplay &&
              chaid.chaId !== "99999" &&
              chaid.chaDes !== "Top Recommended Poly Services" &&
              !chaid.chaId.endsWith("_recommended") &&
              labelDisplay.length === 1 ? (
                chaid.items && chaid.items.length > 1 ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.captionStyle}
                  >
                    {t("common:configurator:priceOfPart")} &nbsp;
                    <Typography
                      color="primary"
                      className={classes.currencyStyle}
                      component="span"
                      variant="caption"
                    >
                      {currencyCode} &nbsp;
                      {displayCurrencyLite({
                        value:
                          Math.abs(isNaN(itemPrice) ? 0 : itemPrice) || "0.00",
                        currency: currencyCode,
                        locale: "en-US"
                      })}
                    </Typography>
                    .
                  </Box>
                ) : (
                  chaid.items &&
                  chaid.items.length !== 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.captionStyle}
                    >
                      {t("common:configurator:priceOfPart")} &nbsp;
                      <Typography
                        color="primary"
                        className={classes.currencyStyle}
                      >
                        {currencyCode} &nbsp;
                        {displayCurrencyLite({
                          value:
                            Math.abs(isNaN(itemPrice) ? 0 : itemPrice) ||
                            "0.00",
                          currency: currencyCode,
                          locale: "en-US"
                        })}
                      </Typography>
                      . {t("common:configurator:onlyComponent")}.
                    </Box>
                  )
                )
              ) : topLabelDisplay &&
                (chaid.chaDes == "Top Recommended Poly Services" ||
                  chaid.chaId === "99999" ||
                  chaid.chaId.endsWith("_recommended")) &&
                topLabelDisplay.length === 1 ? (
                chaid.items && chaid.items.length > 1 ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.captionStyle}
                  >
                    {t("common:configurator:priceOfPart")} &nbsp;
                    <Typography
                      color="primary"
                      className={classes.currencyStyle}
                      component="span"
                      variant="caption"
                    >
                      {currencyCode} &nbsp;
                      {displayCurrencyLite({
                        value:
                          Math.abs(isNaN(itemPrice) ? 0 : itemPrice) || "0.00",
                        currency: currencyCode,
                        locale: "en-US"
                      })}
                    </Typography>
                    .
                  </Box>
                ) : (
                  chaid.items &&
                  chaid.items.length !== 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.captionStyle}
                    >
                      {t("common:configurator:priceOfPart")} &nbsp;
                      <Typography
                        color="primary"
                        className={classes.currencyStyle}
                      >
                        {currencyCode} &nbsp;
                        {displayCurrencyLite({
                          value:
                            Math.abs(isNaN(itemPrice) ? 0 : itemPrice) ||
                            "0.00",
                          currency: currencyCode,
                          locale: "en-US"
                        })}
                      </Typography>
                      . {t("common:configurator:onlyComponent")}.
                    </Box>
                  )
                )
              ) : (
                expanded === false &&
                (labelDisplay.length > 1 ||
                (topLabelDisplay.length > 1 &&
                  (chaid.chaDes === "Top Recommended Poly Services" ||
                    chaid.chaId === "99999" ||
                    chaid.chaId.endsWith("_recommended"))) ? (
                  <Typography className={classes.captionStyle}>
                    {t("common:configurator:expandToView")}
                  </Typography>
                ) : (
                  <Typography className={classes.captionStyle}>
                    {`${t("common:configurator:clickToPick")} ${chaid.chaDes}`}
                  </Typography>
                ))
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ marginTop: "-15px" }}>
            {expanded && finalToggleExpandCollapse && (
              <ItemMultipleCheckBox
                itemQuantityRef={itemQuantityRef}
                chaid={chaid}
                model={model}
                handleItemChange={handleItemChange}
                selectedItemPrice={itemPriceObj}
                handleQuantityChange={handleQuantityChange}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  );
}

export default ChaidMultiple;

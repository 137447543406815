import moment from "moment";
import {
  calculateDays,
  getStorageValue,
  obsoleteDateFormat,
  shouldShowOnUI
} from "../../lib/common/util";
import { getBOMData, getConfigBom } from "../bomAndCarePackLib/utils";
//import desktop_stepper from "../../data/desktop_stepper.json"
export const chaidBasicCheck = function (chaid, modelData) {
  return (
    (chaid.precon >= 0 ||
      chaid.isOverridePrecon ||
      chaid.required ||
      chaid.selcon ||
      isConflictsExist(modelData, chaid)) &&
    chaid.visible
  );
};

export const partBasicCheck = function (part, chaid) {
  return (
    part.visible &&
    part.inode >= chaid.firstItem &&
    part.inode <= chaid.lastItem
  );
};

export const getChoices = function (
  modelData,
  tabId = "hardware",
  proCategory
) {
  let tabChaidsList = window.uiSpecData;
  const isVerticalView = fetchUiSpecVerticalView(tabId);

  let shouldShowOnUIParams = localStorage.getItem("shouldShowOnUIParams");
  shouldShowOnUIParams = shouldShowOnUIParams
    ? JSON.parse(shouldShowOnUIParams)
    : {};
  const { currentConfigID, showGADate, isConfigOverDate } =
    shouldShowOnUIParams;
  if (!(modelData && modelData.Chaids)) return [];
  // Chaids
  let sections = tabChaidsList.UISpec[0].sections.find(
    section => section.id === tabId
  );
  let accordions =
    sections !== undefined && isVerticalView === "Y"
      ? [...sections.subsections].sort(function (a, b) {
          return a.sequence - b.sequence;
        })
      : [...sections.subsections[0].choices].sort(function (a, b) {
          return a.sequence - b.sequence;
        });

  //sanity check - if accordions are not present, do return empty choice;
  if (accordions === null) {
    return [];
  }
  let highestSequence = accordions[accordions.length - 1].sequence;

  // adding the leftover choices in the hardware tab
  if (
    ["hardware", "carePacks", "monitoringAccesories"].includes(tabId) &&
    modelData
  ) {
    let data = modelData.Chaids.filter(chaid => {
      return (
        (tabId === "hardware"
          ? !chaid.chaId.startsWith("CAPQ_") && !chaid.chaId.startsWith("ASCM_")
          : tabId === "monitoringAccesories"
          ? chaid.chaId.startsWith("ASCM_") &&
            !accordions.some(item => item.id === chaid.chaId)
          : chaid.chaId.startsWith("CAPQ_") &&
            !accordions.some(item => item.id === chaid.chaId)) &&
        !chaid.isSpecOrigin &&
        chaidBasicCheck(chaid)
      );
    });
    data.forEach(entity => {
      let obj = {
        id: entity.chaId,
        title: entity.chaDes,
        uiControlType: entity.multiple ? "checkbox" : "radioGroupGrid",
        sequence: ++highestSequence,
        exclude: false,
        itemSequenceBy: "partno"
      };
      accordions.push(obj);
    });
  }

  const icConflictsArr = modelData.conflicts[0].icConflicts?.map(f => f.chaId);
  const choiceConflicts = modelData.conflicts[0]?.choiceConflicts?.map(
    choiceConflict => choiceConflict.effectChoice.chaId
  );
  const gaDateConflicts = modelData.conflicts[0]?.gaDateConflicts?.map(
    f => f.chaId
  );
  const plcConflicts = modelData.conflicts[0]?.plcConflicts?.map(
    plcConflict => plcConflict.chaId
  );
  const finalConflictsArr = [
    ...icConflictsArr,
    ...choiceConflicts,
    ...gaDateConflicts,
    ...plcConflicts
  ];
  const requiredChoices = modelData.conflicts?.length ? finalConflictsArr : [];
  const noPartno = [
    "PSO",
    "ACSS",
    "CS_C",
    "CMODEL",
    "QTYPE",
    "DUR",
    "INST",
    "PORL"
  ];
  const userType = getStorageValue("userType");
  let ret = modelData.Chaids.filter(choice => {
    const isRequired = requiredChoices.includes(choice.chaId);
    const isChoiceConflict = choiceConflicts?.includes(choice.chaId);
    choice.isOverridePrecon = isChoiceConflict;
    return (
      (choice.precon >= 0 || isRequired) &&
      choice.visible &&
      (choice.chaId !== "CS_C" ||
        (choice.chaId === "CS_C" && userType?.toUpperCase() !== "PARTNER")) &&
      accordions &&
      (isVerticalView === "Y"
        ? accordions.some(subsection =>
            subsection?.choices?.some(chaid => chaid?.id === choice.chaId)
          )
        : accordions.some(acc => acc.id === choice.chaId)) &&
      modelData &&
      (isRequired ||
        choice.required ||
        choice.selcon ||
        modelData.Items.some(
          part =>
            (part.partno !== "" || noPartno.includes(choice.chaId)) &&
            partBasicCheck(part, choice) &&
            (!currentConfigID ||
              shouldShowOnUI(
                { currentConfigID, showGADate, isConfigOverDate },
                part.gaDate,
                part.selected
              ))
        ))
    );
  });

  //handle a case where all items have plcStatus = "I" and visible ==false
  const choicesRequired = modelData.Chaids.filter(
    chaid =>
      chaidBasicCheck(chaid) &&
      chaid.required &&
      accordions &&
      accordions.some(acc => acc.id === chaid.chaId) &&
      modelData &&
      modelData.Items.some(
        part =>
          part.plcStatus === "I" &&
          !part.visible &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      )
  );
  // Here we are handling chaids filtering for carePacks and otherServices with new UISpec
  let chaids = [];
  if (isVerticalView === "Y") {
    // if (tabId === "carePacks" || tabId === "otherServices") {
    accordions.forEach(accordion => {
      accordion?.choices?.forEach(choice => {
        let matchedChaid = modelData?.Chaids.find(
          chaid => chaid.chaId === choice.id
        );
        if (matchedChaid) {
          matchedChaid = { ...matchedChaid };
          matchedChaid.parentId = `${accordion.id}`;
          chaids.push(matchedChaid);
        }
      });
    });
    // }
  }
  chaids = chaids?.filter(chaid => chaidBasicCheck(chaid, modelData));
  if (chaids.length > 0 && isVerticalView === "Y") {
    ret = [...new Set([...ret, ...choicesRequired, ...chaids])];
  } else {
    //merging two arrays while removing duplicates, creating a set
    ret = [...new Set([...ret, ...choicesRequired])];
  }

  if (tabId === "hardware" && modelData && proCategory) {
    // adding localizations for the hardware tab, to Filter Parts
    //we also check if proCategory is not print
    if (!["print", "poly"].includes(proCategory)) {
      const systemLocOptions = {
        chaId: "systemLocOptions",
        chaDes: "System Localization Options",
        type: "loc",
        multiple: false,
        required: false,
        visible: true,
        precon: -1,
        selcon: false,
        firstItem: 0,
        lastItem: modelData.systemLocOptions?.length - 1 || 0,
        iNode: 0,
        specified: false,
        isUsed: true,
        items:
          (modelData.systemLocOptions &&
            modelData.systemLocOptions.map((loc, idx) => ({
              partno: loc.locOption,
              partdes: "",
              lclzDefault: loc.lclzDefault,
              lclzBanding: loc.lclzBanding,
              precon: 0,
              selcon: false,
              selected: loc.selected,
              locDescription: `${loc.locOption}-${loc.locDescription}`,
              inode: idx
            }))) ||
          []
      };
      const osLocOptions = {
        chaId: "osLocOptions",
        chaDes: "OS Localization Options",
        type: "loc",
        multiple: false,
        required: false,
        visible: true,
        precon: -1,
        selcon: false,
        firstItem: 0,
        lastItem: modelData.osLocOptions?.length - 1 || 0,
        iNode: 0,
        specified: false,
        isUsed: true,
        items:
          (modelData.osLocOptions &&
            modelData.osLocOptions.map((loc, idx) => ({
              partno: loc.locOption,
              partdes: "",
              lclzDefault: loc.lclzDefault,
              lclzBanding: loc.lclzBanding,
              precon: 0,
              selcon: false,
              locDescription: `${loc.locOption}-${loc.locDescription}`,
              selected: loc.selected,
              inode: idx
            }))) ||
          []
      };
      const kybdLocOptions = {
        chaId: "kybdLocOptions",
        chaDes: "Keyboard Localization Options",
        type: "loc",
        multiple: false,
        required: false,
        visible: true,
        precon: -1,
        selcon: false,
        firstItem: 0,
        lastItem: modelData.kybdLocOptions?.length - 1 || 0,
        iNode: 0,
        specified: false,
        isUsed: true,
        items:
          (modelData.kybdLocOptions &&
            modelData.kybdLocOptions.map((loc, idx) => ({
              partno: loc.locOption,
              partdes: "",
              lclzDefault: loc.lclzDefault,
              lclzBanding: loc.lclzBanding,
              precon: 0,
              selcon: false,
              locDescription: `${loc.locOption}-${loc.locDescription}`,
              selected: loc.selected,
              inode: idx
            }))) ||
          []
      };
      const monitorLocOptions = {
        chaId: "monitorLocOptions",
        chaDes: "Monitor Localization Options",
        type: "loc",
        multiple: false,
        required: false,
        visible: true,
        precon: -1,
        selcon: false,
        firstItem: 0,
        lastItem: modelData.monitorLocOptions?.length - 1 || 0,
        iNode: 0,
        specified: false,
        isUsed: true,
        items:
          (modelData.monitorLocOptions &&
            modelData.monitorLocOptions.map((loc, idx) => ({
              partno: loc.locOption,
              partdes: "",
              lclzDefault: loc.lclzDefault,
              lclzBanding: loc.lclzBanding,
              precon: 0,
              selcon: false,
              selected: loc.selected,
              locDescription: `${loc.locOption}-${loc.locDescription}`,
              inode: idx
            }))) ||
          []
      };
      ret.splice(
        0,
        0,
        systemLocOptions,
        osLocOptions,
        kybdLocOptions,
        monitorLocOptions
      );
    }
  }
  return ret;
};

export const compare = (a, b) => {
  // Inside choice by priority (must be 2nd)
  const p = a.priority - b.priority;
  if (p !== 0) return p;
  // By part number
  const valueA = a.chaId.toUpperCase();
  const valueB = b.chaId.toUpperCase();
  let comparison = 0;
  if (valueA > valueB) {
    comparison = 1;
  } else if (valueA < valueB) {
    comparison = -1;
  }
  return comparison;
};

export const comparePriority = (a, b) => {
  const valueA = a.priority;
  const valueB = b.priority;
  let comparison = 0;
  if (valueA > valueB) {
    comparison = 1;
  } else if (valueA < valueB) {
    comparison = -1;
  }
  return comparison;
};

export const getItem = (modelData, chaid) => {
  return (
    modelData &&
    modelData.Items &&
    modelData.Items.find(
      part =>
        part.visible &&
        part.inode >= chaid.firstItem &&
        part.inode <= chaid.lastItem &&
        part.selected
    )
  );
};

export const getConfigData = (modelData, tabId = "hardware") => {
  let tabChaidsList = window.uiSpecData;
  const isVerticalView = fetchUiSpecVerticalView(tabId);
  const choices = getChoices(modelData, tabId);
  const dataFromApi = choices.map(chaid => {
    chaid.items =
      modelData &&
      modelData?.Items?.filter(part => partBasicCheck(part, chaid));
    return chaid;
  });
  //After filtering the qualified data from the API, we are simplying making it,in the order of UI specs.json file.
  const sectionFromUi = tabChaidsList.UISpec[0].sections.find(
    section => section.id === tabId
  );

  let resultsArray = [];
  let foundArray = [];

  if (sectionFromUi) {
    isVerticalView === "Y"
      ? sectionFromUi.subsections.forEach(subsection => {
          subsection.choices.forEach(choice => {
            const choicefoundInUISpec = dataFromApi.find(
              chaid => chaid.chaId === choice.id
            );
            if (choicefoundInUISpec) {
              foundArray.push(choicefoundInUISpec);
              //remove this element from the api data array based on its index
              const index = dataFromApi.indexOf(choicefoundInUISpec);
              dataFromApi.splice(index, 1);
            }
          });
        })
      : sectionFromUi.subsections[0].choices.forEach(choice => {
          const choicefoundInUISpec = dataFromApi.find(
            chaid => chaid.chaId === choice.id
          );
          if (choicefoundInUISpec) {
            foundArray.push(choicefoundInUISpec);
            //remove this element from the api data array based on its index
            const index = dataFromApi.indexOf(choicefoundInUISpec);
            dataFromApi.splice(index, 1);
          }
        });
  }
  //displaying the choices present in the UI specs first and then displaying all the left over items
  resultsArray = [...foundArray, ...dataFromApi];
  return resultsArray;
};

// choiceConflicts and hiddenChoiceConflicts are given last priority over other conflicts
// The below function is used to check if there are no conflicts across the stepper
export const shouldShowLeastPriorityConflicts = modelData => {
  return (
    modelData &&
    modelData.conflicts &&
    modelData.conflicts[0]?.plcConflicts?.length === 0 &&
    modelData.conflicts[0]?.counterConflicts?.length === 0 &&
    modelData.conflicts[0]?.itemConflictsList?.length === 0 &&
    modelData.conflicts[0]?.icConflicts?.length === 0
  );
};

//Function to handle conflict errors
export const validateStepper = (modelData, stepperData) => {
  if (
    Object.keys(modelData).length > 0 &&
    Object.keys(stepperData).length > 0
  ) {
    const choices = getConfigData(modelData, stepperData.id);
    let isComplete = true;
    let icConflictFound = null;
    let counterConflictsFound = null;
    let itemLevelConflictFound = null;
    let plcConflictFound = null;
    let gaDateConflictFound = null;
    let choiceConfilctFound = null;
    //before finding ensure that the array has the length
    icConflictFound =
      modelData.conflicts[0].icConflicts.length &&
      modelData.conflicts[0].icConflicts
        .filter(
          x => x.code === "ERR04" || x.code === "ERR03" || x.code === "ERR10"
        )
        .find(conflictChoice => {
          return choices.find(
            stepperChoice => stepperChoice.chaId === conflictChoice.chaId
          );
        });
    itemLevelConflictFound =
      modelData?.conflicts[0]?.itemConflicts?.effect &&
      choices.find(
        stepperChoice =>
          stepperChoice.chaId ===
          modelData.conflicts[0].itemConflicts.effect[0].chaId
      );
    choiceConfilctFound =
      modelData?.conflicts[0]?.choiceConflicts[0]?.effectChoice &&
      choices.find(
        stepperChoice =>
          stepperChoice.chaId ===
          modelData.conflicts[0].choiceConflicts[0].effectChoice.chaId
      );
    counterConflictsFound =
      modelData.conflicts[0].counterConflicts.length &&
      modelData.conflicts[0].counterConflicts.find(conflictChoice => {
        return choices.find(
          stepperChoice => stepperChoice.chaId === conflictChoice.chaId
        );
      });
    //adding validation for plc conflict
    plcConflictFound =
      modelData.conflicts[0].plcConflicts.length &&
      modelData.conflicts[0].plcConflicts.find(conflictChoice => {
        return choices.find(
          stepperChoice => stepperChoice.chaId === conflictChoice.chaId
        );
      });
    //adding validation for gaDate conflict
    gaDateConflictFound =
      modelData.conflicts[0].gaDateConflicts.length &&
      modelData.conflicts[0].gaDateConflicts.find(conflictChoice => {
        return choices.find(
          gaDatefuture => gaDatefuture.chaId === conflictChoice.chaId
        );
      });
    if (
      icConflictFound ||
      counterConflictsFound ||
      itemLevelConflictFound ||
      plcConflictFound ||
      gaDateConflictFound ||
      (choiceConfilctFound && shouldShowLeastPriorityConflicts(modelData))
    ) {
      isComplete = false;
    }
    return isComplete;
  } else {
    return true;
  }
};

export const getUiDataFileName = category => {
  if (category === "Business Laptop PCs") {
    return "notebook-stepper";
  } else if (category === "Business Desktop PCs") {
    return "desktop-stepper";
  } else if (category === "Mobile Workstations") {
    return "mobilews-stepper";
  } else if (category === "Workstations") {
    return "workstation-stepper";
  } else if (category === "Point of Sale Solutions") {
    return "pointofsale-stepper";
  } else if (
    category.toLowerCase().includes("printer") ||
    category.toLowerCase().includes("color laserjet")
  ) {
    return "printer-stepper";
  } else if (category === "Voice Systems") {
    return "voiceSystems-stepper";
  } else if (category === "Video Systems") {
    return "videoSystems-stepper";
  } else if (category === "Infrastructure") {
    return "infrastructure-stepper";
  } else {
    return "desktop-stepper";
  }
};

export const getUISpecData = (function () {
  window.uiSpecData = null;
  window.uiSpecFileName = "";
  return {
    getData: async function (uiSpecFileName) {
      if (window.uiSpecFileName !== uiSpecFileName) {
        let data = {};
        const response = await fetch(
          `${process.env.REACT_APP_S3_BUCKET_URL}/${uiSpecFileName}.json`
        );
        data = await response.json();
        window.uiSpecData = data;
        window.uiSpecFileName = uiSpecFileName;
      }
      return window.uiSpecData;
    }
  };
})();

export const excluceDropList = (choice, dropListPartNumbers) => {
  const dropListChoices = ["CS_C"];
  const filteredItems =
    choice.items?.length > 0 && !dropListChoices.includes(choice.chaId)
      ? choice.items.filter(item => !dropListPartNumbers.includes(item.partno))
      : [];

  choice.items = filteredItems;
  return choice;
};

// to frame config object structure for save api
export const getConfigObjectDataForSave = (
  records,
  dropListPartNumbers = [],
  type = ""
) => {
  return records && records.length
    ? records
        .filter(
          choice =>
            choice.items &&
            choice.items.length > 0 &&
            (type === "rSKU"
              ? excluceDropList(choice, dropListPartNumbers)
              : true)
        )
        .map(ele => {
          const data = { choiceId: ele.chaId };
          data.items =
            ele.items && ele.items.length > 0
              ? ele.items
                  .filter(item => item.selected || item.localSelected)
                  .map(detail => ({
                    qty: detail.quantity !== 0 ? detail.quantity : 1,
                    valueId: detail.valueId ? detail.valueId.toString() : "",
                    partNo: detail?.partno ? detail.partno : ""
                  }))
              : [];
          return data;
        })
        .filter(choices => choices.items && choices.items.length > 0)
    : [];
};

export const setModelDataForDefaultConfig = records => {
  const chaids =
    records?.Chaids && records?.Chaids?.length > 0
      ? records.Chaids.map(chaid => {
          const items = records.Items.filter(part => {
            let partToBeReturned;
            if (
              part.partno === "" &&
              part.valueId !== "" &&
              part.inode >= chaid.firstItem &&
              part.inode <= chaid.lastItem
            ) {
              partToBeReturned = part;
            } else if (
              part.partno !== "" &&
              part.inode >= chaid.firstItem &&
              part.inode <= chaid.lastItem
            ) {
              partToBeReturned = part;
            }
            return partToBeReturned;
          });
          chaid["items"] =
            chaid.items && chaid.items.length > 0
              ? chaid.items
              : items && items.length > 0
              ? items
              : [];
          return chaid;
        })
      : [];
  return chaids;
};

export const getLocalizedMandaCPItem = (modelData, chaid) => {
  const monitorLocOptions =
    modelData.monitorLocOptions &&
    modelData.monitorLocOptions
      .filter(item => item.selected === true)
      .map(item => item.locOption);
  let ipartno = chaid.chaId.slice(-7);
  let item = modelData?.Items.find(v => v.partno === ipartno);
  if (item === undefined) {
    ipartno = ipartno.concat("#" + monitorLocOptions);
    item = modelData?.Items.find(v => v.partno === ipartno);
    return item;
  }
  return item;
};

export const getLocalizationOption = records => {
  return records && records.length
    ? records
        .filter(item => item.selected)
        .map(locOpt => ({ id: locOpt.locOption }))
    : [];
};

export const validateWarning = (stepperData, modelData) => {
  let isItemWarning = false;
  let tabChaidsList = window.uiSpecData;
  let tabData = tabChaidsList.UISpec[0].sections.find(
    section => section.id === stepperData.id
  );
  for (let k = 0; k < tabData?.subsections.length; k++) {
    for (let j = 0; j < tabData?.subsections?.[k].choices?.length; j++) {
      const choice = tabData?.subsections?.[k].choices[j];
      const chaid =
        modelData &&
        modelData?.Chaids?.find(
          chaid =>
            chaid.chaId === choice.id &&
            chaid.visible &&
            (chaid.precon >= 0 ||
              chaid.isOverridePrecon ||
              chaid.required ||
              chaid.selcon)
        );
      for (let i = chaid?.firstItem; i <= chaid?.lastItem; i++) {
        const item = modelData.Items[i];
        let formatEod = obsoleteDateFormat(item.esDate);
        const isDateGreater = new Date(formatEod) > new Date();
        const days = calculateDays(formatEod);
        if (item.selected && item.visible && isDateGreater && days <= 90) {
          isItemWarning = true;
          break;
        }
      }
      if (isItemWarning) {
        break;
      }
    }
  }
  return isItemWarning;
};

export const setIsSpecOrigin = data => {
  data.Chaids.forEach(chaid => {
    chaid.isSpecOrigin = isSpecOrigin(chaid);
  });
};

const isSpecOrigin = chaid => {
  let index = window.uiSpecData?.UISpec[0].sections.findIndex(section => {
    return (
      section.subsections.findIndex(
        choice =>
          choice.id === chaid.chaId ||
          (Array.isArray(choice.choices) &&
            choice.choices.find(chaidChoice => chaidChoice.id === chaid.chaId))
      ) > -1
    );
  });
  return index > -1;
};

export const processCapqName = capqName => {
  let additionalIndex = capqName.indexOf("_additional");
  if (additionalIndex !== -1) capqName = capqName.substring(0, additionalIndex);
  let recommendedIndex = capqName.indexOf("_recommended");
  if (recommendedIndex !== -1)
    capqName = capqName.substring(0, recommendedIndex);
  return capqName;
};

export const handleAddToQuoteClick = (
  modelData,
  configNameRed,
  userId,
  userEmail,
  companyId,
  productCategory,
  isStandaloneAccess,
  pricingGeo,
  currencyCode,
  incoterms
) => {
  let bmiData = JSON.parse(localStorage.getItem("bmiData"));
  const mDCPOrgID = bmiData["mDCPOrgID"] || "";
  let configDescRes = "";
  const updatedChoicesDetails = setModelDataForDefaultConfig(modelData);
  let { formattedBOMItems: configBOM } = getConfigBom(modelData);
  const getConfigObjectDataRes = getConfigObjectDataForSave(
    updatedChoicesDetails
  );
  const systemLocalizationDefault = getLocalizationOption(
    modelData.systemLocOptions
  );
  const osLocalizationDefault = getLocalizationOption(modelData.osLocOptions);
  const keyBoardLocalizationDefault = getLocalizationOption(
    modelData.kybdLocOptions
  );
  const monitorLocalizationDefault = getLocalizationOption(
    modelData.monitorLocOptions
  );
  let systemLocOptions, osLocOptions, kybdLocOptions, monitorLocOptions;
  systemLocOptions =
    modelData.systemLocOptions &&
    modelData.systemLocOptions.filter(item => item.selected === true);
  osLocOptions =
    modelData.osLocOptions &&
    modelData.osLocOptions.filter(item => item.selected === true);
  kybdLocOptions =
    modelData.kybdLocOptions &&
    modelData.kybdLocOptions.filter(item => item.selected === true);
  monitorLocOptions =
    modelData.monitorLocOptions &&
    modelData.monitorLocOptions.filter(item => item.selected === true);

  let countryLcLzRes = [
    {
      countryCode: modelData.country,
      systemLocalization: systemLocOptions && systemLocOptions[0]?.locOption,
      osLocalization: osLocOptions && osLocOptions[0]?.locOption,
      keyBoardLocalization: kybdLocOptions && kybdLocOptions[0]?.locOption,
      monLocalization: monitorLocOptions && monitorLocOptions[0]?.locOption,
      delFl: "N"
    }
  ];

  // Extract Base Unit to pass inside configHeader if changed
  let selectedBaseUnit = undefined;
  if (configBOM && configBOM.length) {
    selectedBaseUnit = configBOM.find(config => config.choiceId === "BUNIT");
  }

  let inputData = {
    configName: configNameRed,
    configId: null,
    configDesc: configDescRes,
    configHeader: {
      addtnlRegs: "",
      configType: "custom",
      leadCountryCode: modelData.country || "US",
      configHighLights: "",
      configImage: "",
      kmatId: modelData.kmatId || "",
      baseUnitAv: selectedBaseUnit?.materialNumber || "",
      programName: "",
      accessType: "organization",
      refConfigId: "",
      userId: userId,
      userEmail: userEmail,
      customerCompanyName: companyId,
      mdcpOrgId: mDCPOrgID || "",
      originatingAsset: "OCIQ",
      bandingFlag: "N",
      bandingType: "OC-FIX",
      docType: "QU",
      regionCode: modelData.region || "NA",
      lead_locale: "",
      autoLocalized: "",
      kbVersion: "",
      marketProgramName: "",
      marketProgramType: "",
      shipmentDate: "",
      exportStatusId: "new",
      bandingEol: null,
      configEol: null,
      businessModel: "indirect",
      copiedFrom: "",
      localizationType: "",
      priceGeo: !isStandaloneAccess ? pricingGeo : modelData.priceGeo,
      currencyCd: !isStandaloneAccess ? currencyCode : modelData.currency,
      incoterm: !isStandaloneAccess ? incoterms : modelData.incoTerm,
      priceListType: modelData.priceListType,
      globalFl: "N",
      activeFl: "Y",
      delFl: "N",
      favoriteFl: "N",
      productLine: modelData?.modelRequirement?.productGroup || "NA",
      storeFront: "IQ",
      startDate: moment().format("YYYY-MM-DD") || null,
      endDate: moment().add(1, "year").format("YYYY-MM-DD") || null,
      geoMrkt: "",
      spcType: "C"
    },
    countryLcLz: countryLcLzRes,
    configBom: configBOM && configBOM.length ? configBOM : [],
    configObject: {
      configDefault: {
        configuration: {
          localization: [
            {
              choiceId: "systemLocalization",
              values: [...systemLocalizationDefault]
            },
            {
              choiceId: "osLocalization",
              values: [...osLocalizationDefault]
            },
            {
              choiceId: "keyBoardLocalization",
              values: [...keyBoardLocalizationDefault]
            },
            {
              choiceId: "monitorLocalization",
              values: [...monitorLocalizationDefault]
            }
          ],
          choices: [...getConfigObjectDataRes]
        }
      },
      configBanding: { banding: {} }
    },
    hierarchy: {
      categoryName: "N/A",
      seriesName: "N/A",
      productType: productCategory
    }
  };
  return inputData;
};

export const handleData = (
  newConfigId,
  modelData,
  configNameRed,
  transactionID,
  productCategory
) => {
  let { formattedBOMItems } = getBOMData(modelData);
  const data = {
    transactionID: transactionID,
    eventTriggered: "ADD_TO_QUOTE",
    configID: newConfigId,
    configName: configNameRed,
    refConfigID: newConfigId,
    disableReconfigure: false,
    retainConfigID: true,
    productCategory: productCategory,
    components: formattedBOMItems.map(bom => {
      return {
        partNumber: bom.partNumber,
        description: bom.description,
        quantity: bom.qty,
        listPrice: bom.listPrice.toFixed(2).toString(),
        requestedBDNetPrice: "150.00",
        plcOverride: false,
        ...(productCategory !== "print" && { choiceId: bom?.choiceId })
      };
    })
  };
  handleEvent(data);
};

const handleEvent = modelJson => {
  if (document.querySelector("#product-selector")) {
    document
      .querySelector("#product-selector")
      .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
  } else if (document.querySelector("#configurator")) {
    document
      .querySelector("#configurator")
      .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
  } else if (document.querySelector("#upload-config")) {
    document
      .querySelector("#upload-config")
      .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
  }
};

//This function will get all elements collapsed or expanded
export const getAllDataExpandedOrCollapsedManul = data => {
  //checking if all choices are expanded
  const allChoiceExpanded = Object.values(data).every(choice => {
    return choice === true;
  });
  const allChoiceCollapsed = Object.values(data).every(choice => {
    return choice === false;
  });
  return [allChoiceExpanded, allChoiceCollapsed];
};

export const handleDuplicateItemConflicts = model => {
  if (model?.conflicts && model?.conflicts[0]) {
    for (let i = 0; i < model.conflicts[0]?.itemConflictsList?.length; i++) {
      for (
        let j = i + 1;
        j < model.conflicts[0]?.itemConflictsList.length;
        j++
      ) {
        if (
          model.conflicts[0]?.itemConflictsList[i].effect.chaDes ===
          model.conflicts[0]?.itemConflictsList[j]?.effect?.chaDes
        ) {
          model.conflicts[0]?.itemConflictsList.splice(i + 1, 1);
        }
      }
    }
  }
  return model;
};

// This function will handle more than one banner shown during cause-effect conflict
export const isConflictsExist = (model, chaid) => {
  let counterConflictsError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.counterConflicts?.forEach(res => {
      return chaid.chaId === res?.chaId
        ? counterConflictsError.push(" " + res.chaDes)
        : null;
    });
  let gaDateConflictsError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.gaDateConflicts?.forEach(res => {
      return chaid.chaId === res?.chaId
        ? gaDateConflictsError.push(" " + res.chaDes)
        : null;
    });
  let icConflictsError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.icConflicts?.forEach(res => {
      return chaid.chaId === res?.chaId
        ? icConflictsError.push(" " + res.chaDes)
        : null;
    });
  let plcConflictsError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.plcConflicts?.forEach(res => {
      return chaid.chaId === res?.chaId
        ? plcConflictsError.push(" " + res.chaDes)
        : null;
    });
  let choiceConflictsError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.choiceConflicts?.forEach(res => {
      return chaid.chaId === res?.effectChoice.chaId
        ? choiceConflictsError.push(" " + res.chaDes)
        : null;
    });
  return !(
    plcConflictsError.length === 0 &&
    icConflictsError.length === 0 &&
    gaDateConflictsError.length === 0 &&
    counterConflictsError.length === 0 &&
    choiceConflictsError.length === 0
  );
};

export const hasSelectedItems = (modelData, chaId) => {
  const chaid = modelData.Chaids.find(chaid => chaid.chaId === chaId);
  const selectedItems = modelData.Items.find(
    item =>
      item.inode >= chaid.firstItem &&
      item.inode <= chaid.lastItem &&
      item.selected
  );
  if (selectedItems) {
    return true;
  } else {
    return false;
  }
};

export const shouldHideChoices = (hideChoices, chaid, modelData) => {
  const newHideChoice = {};
  for (const key in hideChoices) {
    if (hideChoices[key] === "Y") {
      newHideChoice[key] = hideChoices[key];
    }
  }
  if (
    Object.keys(newHideChoice).includes(chaid) &&
    !hasSelectedItems(modelData, chaid)
  ) {
    return true;
  } else {
    return false;
  }
};

export const resolveChaidsVisible = (hideChoices, modelData) => {
  const hideChoicesKeys = [];
  for (const hideChoice in hideChoices) {
    if (hideChoices[hideChoice] === "Y") hideChoicesKeys.push(hideChoice);
  }
  modelData.Chaids.map(chaid => {
    if (
      hideChoicesKeys.includes(chaid.chaId) &&
      !hasSelectedItems(modelData, chaid.chaId)
    )
      chaid.visible = false;
  });
};

export const skipChaIdInformation = [
  "CS_C",
  "ACSS",
  "PSO",
  "CMODEL",
  "QTYPE",
  "DUR",
  "INST",
  "PORL"
];
export const isChaidRenderable = (chaid, items, model, isPartnerPortalFlag) => {
  let isValid = false;
  if (
    (chaid.precon >= 0 ||
      chaid.isOverridePrecon ||
      chaid.required ||
      chaid.selcon ||
      (model && isConflictsExist(model, chaid))) &&
    chaid.visible
  ) {
    isValid = chaid.required || chaid.selcon;
    if (!isValid) {
      const noPartno = [
        "ACSS",
        "CS_C",
        "CMODEL",
        "QTYPE",
        "DUR",
        "INST",
        "PORL"
      ];
      for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
        const item = items[i];
        if (
          (item.visible && item.partno !== "") ||
          (item.visible && noPartno.includes(chaid.chaId))
        ) {
          isValid = true;
          break;
        }
      }
    }
  }
  if (isValid && isPartnerPortalFlag && chaid.chaId === "CS_C") isValid = false;
  return isValid;
};

export const checkGaDateWithinRange = calibratTime => {
  const gaFutureDateLimit = Number(localStorage.getItem("gaFutureDateLimit"));
  const contrastTime = moment(moment().format("YYYY-MM-DD")).add(
    isNaN(gaFutureDateLimit) ? 30 : gaFutureDateLimit,
    "days"
  );
  const showGaDate = JSON.parse(localStorage.getItem("showGADate")) || false;
  if (!showGaDate) return true;
  const isInvalidDate = moment(calibratTime, "YYYY-MM-DD")?.format(
    "YYYY-MM-DD"
  );
  const isLessThanNowDate =
    calibratTime && moment(calibratTime).isBefore(moment().format());
  if (
    typeof calibratTime !== "string" ||
    !calibratTime.trim() ||
    ["Invalid date", null, "", undefined]?.includes(isInvalidDate) ||
    isLessThanNowDate
  ) {
    return true;
  }
  calibratTime = moment(calibratTime, "YYYY-MM-DD");
  return calibratTime?.diff(contrastTime) > 0;
};

export const getExpandState = configFilterData => {
  const { verticalStepper, expColButton, defaultConfigStepSlected } =
    configFilterData;
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected);
  //TODO in future isVerticalStepper and defaultsteps eg: ["carePacks", "otherServices"] will refer from UI spec values
  const isVerticalStepper = isVerticalView === "Y";
  const activeCarePack =
    defaultConfigStepSlected === "carePacks" && verticalStepper.carePacks;
  const activeOtherServices =
    defaultConfigStepSlected === "otherServices" &&
    verticalStepper.otherServices;
  const activeConfigurationService =
    defaultConfigStepSlected === "configurationService" &&
    verticalStepper.configurationService;
  const activedeploymentServices =
    defaultConfigStepSlected === "deploymentServices" &&
    verticalStepper.deploymentServices;

  const activeHardware =
    isVerticalStepper &&
    defaultConfigStepSlected == "hardware" &&
    verticalStepper.hardware;

  const stepId =
    activeCarePack ||
    activeOtherServices ||
    activeConfigurationService ||
    activeHardware ||
    activedeploymentServices;
  const expandValue = isVerticalStepper
    ? expColButton[stepId]
    : expColButton[defaultConfigStepSlected];
  return { isVerticalStepper, stepId, expandValue };
};

export const fetchUiSpecVerticalView = defaultConfigStepSlected => {
  const indexOfDefaultConfigstepSelected =
    window.uiSpecData?.UISpec[0]?.sections?.findIndex(
      tab => tab.id === defaultConfigStepSlected
    );
  const isVerticalView =
    window.uiSpecData?.UISpec[0]?.sections[indexOfDefaultConfigstepSelected]
      .isVerticalView;
  return isVerticalView;
};

import { useMutation, useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ExternalProps from "../../contexts/externalPropsContext";
import { PRODUCT_ACCESS_TYPE, SAVE_FAV } from "../../GraphQL";
import displayNotistack from "../../lib/common/SnackBarUtils";
import TableSkeleton from "../../lib/common/TableSkeleton";
import { SET_SELECTED_CATEGORY_FROM_API } from "../../models/configuration/productSelector/Constants";
import ConfigContainer from "../Configurator/ConfigContainer";
import FullscreenModal from "../FullscreenModal";

const headCells = [
  {
    width: "1"
  },
  {
    width: "1"
  },
  {
    width: "1"
  },
  {
    width: "2"
  },
  {
    width: "2"
  },
  {
    width: "1"
  },
  {
    width: "1"
  },
  {
    width: "1"
  },
  {
    width: "1"
  },
  {
    width: "1"
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    alignItems: "left",
    justifyContent: "left",
    paddingTop: "10px"
  },
  container: {
    maxHeight: 440
  },
  topPadding: {
    paddingTop: "20px"
  },
  headStyle: {
    fontSize: 15,
    fontWeight: "bold"
  },
  cellEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  textTransform: {
    textTransform: "capitalize"
  },
  wordBreak: {
    overflowWrap: "break-word"
  },
  fillColor: {
    color: "#ffd700"
  },
  fillColorNoFav: {
    color: "#0096d6"
  },
  borderColor: {
    border: "1px solid red"
  },
  textTransformNone: {
    textTransform: "none"
  },
  tableHeaderBackground: {
    backgroundColor: "#E8E8E8"
  },
  deleteColor: {
    color: "#D6002A"
  }
}));

function ConfigResultsTable(props) {
  const [open, setOpen] = useState(false);
  const { userType, companyId, configOrigin, hasPrintAccess, isPartnerPortalFlag } =
    React.useContext(ExternalProps);
  let { countryCode, country } = React.useContext(ExternalProps);
  countryCode = countryCode ? countryCode : country;
  const category = useSelector(state => state.productSelector.category);
  const isPartnerUser = userType?.toLowerCase() === "partner";
  const [setFavourites] = useMutation(SAVE_FAV, {
    fetchPolicy: "no-cache",
    onCompleted({ updateConfigurationStatus }) {
      if (updateConfigurationStatus) {
        if (updateConfigurationStatus.issuccess) {
          const successMsg = updateConfigurationStatus.message;
          displayNotistack.success(successMsg, "success", 6000);
          props.handleClick("MY_CONFIG");
          setTimeout(() => {
            setSelectedConfigID([]);
            setGridManageMyConfigResultList(manageMyConfigResultList);
            setCurrentPage(0);
            gridManageMyConfigResultList.forEach(config => {
              config.selected = false;
            });
            let selectedList = gridManageMyConfigResultList.filter(
              config => config.selected
            );
            let filteredFavResult = gridManageMyConfigResultList.filter(
              config => config.favorite === "Y" && config.selected
            );
            let filteredNonFavResult = gridManageMyConfigResultList.filter(
              config =>
                (config.favorite === "" ||
                  config.favorite === " " ||
                  config.favorite === "N") &&
                config.selected
            );
            if (filteredFavResult.length === selectedList.length) {
              setDisableResetFavourites(false);
            } else {
              setDisableResetFavourites(true);
            }
            if (filteredNonFavResult.length === selectedList.length) {
              setDisableFavourites(false);
            } else {
              setDisableFavourites(true);
            }

            if (
              filteredNonFavResult.length === 0 &&
              filteredFavResult.length === 0
            ) {
              setDisableResetFavourites(true);
              setDisableFavourites(true);
            }
          });
        } else {
          displayNotistack.error(
            false,
            updateConfigurationStatus.message,
            "error",
            6000
          );
        }
      }
    },
    onError({ graphQLErrors, networkError }) {
      if (localStorage.getItem("isDebug") === "true") {
        if (graphQLErrors) {
          console.log(graphQLErrors);
        }
        if (networkError) {
          console.log(networkError);
        }
      }
    }
  });
  const [disableResetFavourites, setDisableResetFavourites] = useState(true);
  const [disableFavourites, setDisableFavourites] = useState(true);
  const { t } = useTranslation();
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [switchLable, setSwitchLable] = useState("showAll");
  const [showAll, setShowAll] = React.useState(false);
  const [selectedNonFavIds, setSelectedNonfavIds] = useState([]);
  const [selectedFavIds, setSelectedfavIds] = useState([]);
  const [selectedConfigID, setSelectedConfigID] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(undefined);
  const { columns, manageMyConfigResultList, page } = useSelector(
    state => state.manageMyConfig
  );
  const [currentPage, setCurrentPage] = React.useState(0);
  const [gridManageMyConfigResultList, setGridManageMyConfigResultList] =
    useState([]);
  const dispatch = useDispatch();
  const [proCategory, setProCategory] = useState("");
  const skipProCategoryCall = Boolean(configOrigin === "OCA" && hasPrintAccess);
  useEffect(() => {
    if (manageMyConfigResultList) {
      manageMyConfigResultList.map(configuration => {
        if (configuration)
          configuration.createdAt = moment(configuration.createdAt).format(
            "MM/DD/YYYY"
          );
        return configuration;
      });

      setGridManageMyConfigResultList(manageMyConfigResultList);

      if (switchLable === "showFav") {
        setTimeout(() => {
          const favL = manageMyConfigResultList.filter(
            config => config.favorite === "Y"
          );
          setGridManageMyConfigResultList(favL);
          setCurrentPage(0);
        });
      } else {
        setTimeout(() => {
          setGridManageMyConfigResultList(manageMyConfigResultList);
          setCurrentPage(0);
        });
      }
      setIsAllSelected(false);
    }
  }, [manageMyConfigResultList]);

  // call to fetch product access type details
  useQuery(PRODUCT_ACCESS_TYPE, {
      fetchPolicy: "no-cache",
      variables: {
        filter: {
          userType,
          companyId,
          country: countryCode ? countryCode : ""
        }
      },
      skip : skipProCategoryCall,
      onCompleted({ getProductAccessType }) {
        if (
          (getProductAccessType?.productAccessType === "" && !isPartnerUser) ||
          (getProductAccessType?.productAccessType === "all" && category !== "print") ||
          getProductAccessType?.productAccessType === "compute"
        ) {
          setProCategory(skipProCategoryCall ? "print" : "compute");
      } else if (getProductAccessType?.productAccessType === "print" || category === "print") {
          setProCategory("print");
        } else if (getProductAccessType?.productAccessType === "" && isPartnerUser) {
          setProCategory("compute");
        }
      },
      onError({ queryError, networkError }) {
        if (localStorage.getItem("isDebug") === "true") {
          if (queryError) {
            console.log(queryError);
          }
          if (networkError) {
            console.log(networkError);
          }
        }
      }
  });

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: "SET_RESET_PAGE",
      payload: newPage
    });

    setCurrentPage(newPage);
  };
  const handleSwitch = event => {
    setGridManageMyConfigResultList([]);
    setShowAll(event.target.checked);
    if (event.target.checked) {
      setSwitchLable("showFav");

      setTimeout(() => {
        const favL = manageMyConfigResultList.filter(
          config => config.favorite === "Y"
        );
        setGridManageMyConfigResultList(favL);
        setCurrentPage(0);
      });
    } else {
      setSwitchLable("showAll");
      setTimeout(() => {
        setGridManageMyConfigResultList(manageMyConfigResultList);
        setCurrentPage(0);
      });
    }
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    dispatch({
      type: "SET_RESET_PAGE",
      payload: 0
    });
  };

  const setConfigurePath = config => {
    dispatch({
      type: SET_SELECTED_CATEGORY_FROM_API,
      payload: {
        category:  config.productCategory
      }
    });
    localStorage.setItem("selectedCategory", config.productCategory || "");
    setSelectedConfig(config);
    setOpen(true);
  };

  const handleFullscreenModalClose = () => {
    setOpen(false);
  };
  const handleFavouritesConfig = event => {
    setSelectedfavIds(
      gridManageMyConfigResultList
        .filter(config => config.favorite === "Y" && config.selected)
        .map(con => con.configID)
    );
    setFavourites({
      variables: {
        input: {
          configId: gridManageMyConfigResultList
            .filter(config => config.favorite === "Y" && config.selected)
            .map(con => con.configID),
          favoriteFl: "N"
        }
      }
    });
  };
  const handleDeleteConfig = event => {
    setSelectedNonfavIds(
      gridManageMyConfigResultList
        .filter(config => config.selected)
        .map(con => con.configID)
    );
    setFavourites({
      variables: {
        input: {
          configId: gridManageMyConfigResultList
            .filter(config => config.selected)
            .map(con => con.configID),
          delFl: "Y"
        }
      }
    });
  };

  const handleNonFavouritesConfig = event => {
    setSelectedNonfavIds(
      gridManageMyConfigResultList
        .filter(
          config =>
            (config.favorite === "" || config.favorite === "N") &&
            config.selected
        )
        .map(con => con.configID)
    );
    setFavourites({
      variables: {
        input: {
          configId: gridManageMyConfigResultList
            .filter(
              config =>
                (config.favorite === "" || config.favorite === "N") &&
                config.selected
            )
            .map(con => con.configID),
          favoriteFl: "Y"
        }
      }
    });
  };
  const isSelected = name => selectedConfigID.indexOf(name) !== -1;

  const handleSelectAll = event => {
    if (event.target.checked) {
      gridManageMyConfigResultList.forEach(config => {
        config.selected = true;
      });
      setSelectedConfigID(
        gridManageMyConfigResultList.map(config => config.configID)
      );
      setIsAllSelected(true);
    } else {
      gridManageMyConfigResultList.forEach(config => {
        config.selected = false;
      });
      setSelectedConfigID([]);
      setIsAllSelected(false);
    }
    setGridManageMyConfigResultList(gridManageMyConfigResultList);

    setTimeout(() => {
      let selectedList = gridManageMyConfigResultList.filter(
        config => config.selected
      );
      let filteredFavResult = gridManageMyConfigResultList.filter(
        config => config.favorite === "Y" && config.selected
      );
      let filteredNonFavResult = gridManageMyConfigResultList.filter(
        config =>
          (config.favorite === "" ||
            config.favorite === " " ||
            config.favorite === "N") &&
          config.selected
      );
      if (filteredFavResult.length === selectedList.length) {
        setDisableResetFavourites(false);
      } else {
        setDisableResetFavourites(true);
      }
      if (filteredNonFavResult.length === selectedList.length) {
        setDisableFavourites(false);
      } else {
        setDisableFavourites(true);
      }
      if (filteredNonFavResult.length === 0 && filteredFavResult.length === 0) {
        setDisableResetFavourites(true);
        setDisableFavourites(true);
      }
    });
  };

  const handleSelectConfig = (ind, column) => {
    gridManageMyConfigResultList.forEach(config => {
      if (config.configID === column.configID) {
        config.selected = !config.selected;
      }
    });

    setGridManageMyConfigResultList(gridManageMyConfigResultList);

    if (selectedConfigID.includes(column.configID)) {
      const selectedconfigs = [...selectedConfigID];
      selectedconfigs.splice(selectedConfigID.indexOf(column.configID), 1);
      setSelectedConfigID(selectedconfigs);
      setIsAllSelected(
        gridManageMyConfigResultList.length === selectedconfigs.length
      );
    } else {
      const selectedconfigs = [...selectedConfigID];
      selectedconfigs.push(column.configID);
      setSelectedConfigID(selectedconfigs);
      setIsAllSelected(
        gridManageMyConfigResultList.length === selectedconfigs.length
      );
    }

    setTimeout(() => {
      let selectedList = gridManageMyConfigResultList.filter(
        config => config.selected
      );
      let filteredFavResult = gridManageMyConfigResultList.filter(
        config => config.favorite === "Y" && config.selected
      );
      let filteredNonFavResult = gridManageMyConfigResultList.filter(
        config =>
          (config.favorite === "" ||
            config.favorite === " " ||
            config.favorite === "N") &&
          config.selected
      );
      if (filteredFavResult.length === selectedList.length) {
        setDisableResetFavourites(false);
      } else {
        setDisableResetFavourites(true);
      }
      if (filteredNonFavResult.length === selectedList.length) {
        setDisableFavourites(false);
      } else {
        setDisableFavourites(true);
      }
      if (filteredNonFavResult.length === 0 && filteredFavResult.length === 0) {
        setDisableResetFavourites(true);
        setDisableFavourites(true);
      }
    });
  };
  return props.isLoading ? (
    <TableSkeleton lineNumber={3} cells={headCells} spacing={3} height={20} />
  ) : (
    <React.Fragment>
      <FullscreenModal
        isOpen={open}
        handleModalClose={() => handleFullscreenModalClose()}
      >
        {/* <div id="configuratorContainer"></div> */}
        <ConfigContainer
          proCategory={proCategory}
          config={selectedConfig}
          setOpen={setOpen}
          categoryFromAPI={selectedConfig?.productCategory}
        ></ConfigContainer>
      </FullscreenModal>

      <TableContainer component={Paper}>
        <Table stickyHeader size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell
                classes={{ root: classes.tableHeaderBackground }}
              ></TableCell>
              <TableCell
                colSpan={7}
                classes={{ root: classes.tableHeaderBackground }}
              >
                <FormControlLabel
                  classes={{ root: classes.deleteColor }}
                  onClick={handleDeleteConfig}
                  control={<DeleteIcon />}
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      {t("common:button.delete")}
                    </span>
                  }
                />
                <Button
                  classes={{ root: classes.textTransformNone }}
                  disabled={disableFavourites}
                  onClick={handleNonFavouritesConfig}
                >
                  <FormControlLabel
                    control={<StarRoundedIcon />}
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        {t("common:button.markAsFav")}
                      </span>
                    }
                  />
                </Button>
                <Button
                  classes={{ root: classes.textTransformNone }}
                  disabled={disableResetFavourites}
                  onClick={handleFavouritesConfig}
                >
                  <FormControlLabel
                    control={<StarBorderIcon />}
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        {t("common:button.resetAsFav")}
                      </span>
                    }
                  />
                </Button>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={showAll}
                      onChange={handleSwitch}
                      color="primary"
                      name="showAll"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      {t(`common:button:${switchLable}`)}
                    </span>
                  }
                />
              </TableCell>
            </TableRow>
          </TableHead>
          {gridManageMyConfigResultList.length > 0 ? (
            <TableBody>
              <TableRow hover>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    classes={{ head: classes.headStyle }}
                  >
                    {column.label == "Select" ? (
                      <Checkbox
                        color="primary"
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                      />
                    ) : (
                      <span style={{ fontWeight: "bold" }}>
                        {t(column.label)}
                      </span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
              {gridManageMyConfigResultList
                .slice(
                  currentPage * rowsPerPage,
                  currentPage * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(row.configID);

                  return (
                    <TableRow hover>
                      {columns.map(column => {
                        const value = row[column.id];
                        return column.id !== "selectRow" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={classes.wordBreak}
                            style={{
                              maxWidth: column.minWidth
                            }}
                          >
                            {column.id === "favorite" && value === "Y" ? (
                              <StarRoundedIcon className={classes.fillColor} />
                            ) : column.id === "favorite" &&
                              (value === "N" || value === "") ? (
                              <StarBorderIcon
                                className={classes.fillColorNoFav}
                              />
                            ) : column.id === "configure" ? (
                              <Button
                                classes={{ root: classes.textTransform }}
                                variant="text"
                                color="primary"
                                onClick={() =>
                                  setConfigurePath(
                                    gridManageMyConfigResultList[index]
                                  )
                                }
                              >
                                {t("common:manageMyConfig.button.Configure")}
                              </Button>
                            ) : column.format && typeof value === "number" ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={column.id + 1}
                            align={column.align}
                            style={{ maxWidth: column.minWidth }}
                          >
                            <Checkbox
                              color="primary"
                              onChange={() => handleSelectConfig(index, row)}
                              checked={isItemSelected}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          ) : (
            <Typography variant="h6" align="center">
              <Box class="searchResult">
                {t("common:manageMyConfig.errorMessage.resultNotFound")}
              </Box>
            </Typography>
          )}
        </Table>
      </TableContainer>
      {gridManageMyConfigResultList.length > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={
            (gridManageMyConfigResultList &&
              gridManageMyConfigResultList.length) ||
            0
          }
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("common:table.rowsPerPage")}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default ConfigResultsTable;

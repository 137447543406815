import React, { useContext } from "react";
import { useSelector } from "react-redux";
import Chaid from "../../../configurator/Chaid";
import { fetchUiSpecVerticalView } from "../../../services/common/utils";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import DisplayKmatId from "./DisplayKmatId";
import DisplaySpcId from "./DisplaySpcId";
import ExpandAndCollapse from "./ExpandAndCollapseBtn";

const ConfigHome = ({ proCategory, localisationData }) => {
  const { visibility = {}, userType } = useContext(ExternalProps);
  const { showStartingPointInformation = true, isExpertConfig } = visibility;
  const defaultConfigStepSelected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const searchText = useSelector(state => state.configFilterData.searchText);
  const checkedFilterParts = useSelector(
    state => state.configFilterData.checkedFilterParts
  );
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSelected)
  const isPartnerPortalFlag = userType?.toUpperCase() === "PARTNER";
  return (
    <>
      {isVerticalView === "N" ? (
        <ExpandAndCollapse proCategory={proCategory} />
      ) : null}
      <Chaid
        tabId={defaultConfigStepSelected}
        proCategory={proCategory}
        localisationData={localisationData}
        configurationName={configuration?.configHeader?.configName}
      />
      {defaultConfigStepSelected === "hardware" &&
        !searchText &&
        !checkedFilterParts && <DisplayKmatId chaid="kmat-display" />}
      {!isPartnerPortalFlag &&
        defaultConfigStepSelected === "hardware" &&
        !searchText &&
        !checkedFilterParts &&
        showStartingPointInformation && (
          <DisplaySpcId chaid="spcid-display" isExpertConfig={isExpertConfig} />
        )}
    </>
  );
};

export default ConfigHome;

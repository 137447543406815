import { isEmpty } from "lodash";
import moment from "moment";
import { SKIP_USER_VALIDATION_REGIONS } from "../components/Configurator/Rsku/Constants";
import { getAttributeValue } from "../lib/common/util";
import {
  FETCH_PRODUCT_SELECTION,
  RECEIVE_USER_DETAILS,
  SET_ALL_SEARCH_CONFIG,
  SET_BUSINESS_MODEL,
  SET_COUNTRY,
  SET_COUNTRY_REGION,
  SET_DISABLE,
  SET_PROCATEGORY,
  SET_PRODUCT_CATEGORIES,
  SET_PROGRAM_TYPE,
  SET_RESULTS_LIST,
  SET_SEARCH_KEY,
  SET_SEARCH_TYPE,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_CATEGORY_FROM_API,
  SET_SELECTED_SERIES,
  SET_SHOWSERIES
} from "../models/configuration/productSelector/Constants";
import configColumns from "./../config";
const {
  productSelectorColumns,
  productSelectorBaseUnitColumns,
  productSelectorBtoSkuColumns
} = configColumns;

const productSelector = (
  state = {
    categories: [],
    series: [],
    selectedCategory: null,
    selectedCategoryFromAPI: "",
    selectedSeriesFromAPI: "",
    selectedSeries: null,
    columns: productSelectorColumns,
    defaultConfigurations: [],
    searchAllConfigs: [],
    countrySelectedDropdown: "",
    programType: "",
    searchValue: "",
    isDisable: true,
    configResultList: [],
    baseUnitConfigResult: [],
    baseUnitColumns: productSelectorBaseUnitColumns,
    showPreApproved: false,
    showSeries: false,
    isPartnerPortalFlag: false,
    isCustomBanded: false,
    isShowProgramType: false,
    showConfigResults: false,
    baseUnitValue: "",
    seriesInfo: null,
    userEmail1: "",
    hideServicesForPartners: false,
    loadManageMyConfig: false,
    page: 0,
    category: "",
    businessModel: "Direct",
    searchType: "None",
    showGADate: false,
    displayBusinessModel: false,
    userAccess: {},
    showSearchBto: false,
    showSearchSku: false,
    showRequestSku: false,
    showRskuBackDrop: false,
    btoSkuInfo: [],
    skuIdData: {},
    warningMessage: "",
    ocfgParams: {}
  },
  action
) => {
  switch (action.type) {
    case SET_PRODUCT_CATEGORIES:
      return {
        ...state,
        categories: action.payload.sortedCategories,
        showSeries: action.payload.showSeries
      };
    case SET_SHOWSERIES:
      return {
        ...state,
        showSeries: action.payload.showSeries,
        defaultConfigurations:
          action.payload.searchListConfigurations === null
            ? action.payload.searchListConfigurations
            : []
      };
    case "NAV_MANAGE_MY_CONFIG":
      return {
        ...state,
        loadManageMyConfig: action.payload.loadManageMyConfig ? true : false
      };
    case SET_SELECTED_CATEGORY_FROM_API:
      //This use case is used for the configurator page when we click on the configure or reconfigure button.
      return {
        ...state,
        selectedCategoryFromAPI: action.payload.category,
        selectedSeriesFromAPI: action.payload?.series
      };
    case SET_SELECTED_CATEGORY:
      //This use case is used to display the series of cards when we select any category in the product selector page.
      return {
        ...state,
        selectedCategory: action.payload.category,
        series: action.payload.series
      };
    case SET_SELECTED_SERIES:
      return {
        ...state,
        selectedSeries: action.payload
      };
    case SET_SEARCH_KEY:
      return {
        ...state,
        searchValue: action.payload
      };
    case SET_DISABLE:
      return {
        ...state,
        isDisable: action.payload
      };
    case SET_ALL_SEARCH_CONFIG:
      return {
        ...state,
        searchAllConfigs: action.payload
      };
    case SET_COUNTRY:
      return {
        ...state,
        countrySelectedDropdown: action.payload,
        country: action.payload
      };
    case SET_PROGRAM_TYPE:
      return {
        ...state,
        programType: action.payload
      };
    case SET_RESULTS_LIST:
      const sorted =
        action.payload && action.payload.length > 0
          ? action.payload
              .sort((a, b) => {
                return b.configID - a.configID;
              })
              .sort((a, b) => {
                return (
                  moment(b.createdAt).format("YYYYMMDD") -
                  moment(a.createdAt).format("YYYYMMDD")
                );
              })
          : [];
      //Added code to display Favorite Configs first
      const favSorted = sorted.filter(ctry => ctry.favorite);
      const nonFavSorted = sorted.filter(ctry => !ctry.favorite);
      const newSorted = [...favSorted, ...nonFavSorted];

      return {
        ...state,
        configResultList: newSorted,
        columns: productSelectorColumns,
        btoSkuInfo: []
      };
    case "SHOW_CONFIG_RESULTS":
      return {
        ...state,
        showConfigResults: action.payload
      };
    case "RESET_SEARCH_CONFIG":
      return {
        ...state,
        configResultList: [],
        searchAllConfigs: [],
        showConfigResults: false,
        searchValue: ""
      };
    case "SET_INITIAL_VALUES":
      const {
        showCustomBandedFlag,
        isPartnerPortalFlag,
        initialCountryCode,
        userEmail,
        countryCode,
        resellerID,
        quoteNumber,
        channelRegion,
        mDCPOrgID,
        category,
        countryCodeFromFetchUserDetails
      } = action.payload || {};
      const defaultCountryCode = initialCountryCode;
      const countryCodeValue = countryCodeFromFetchUserDetails
        ? countryCodeFromFetchUserDetails
        : defaultCountryCode
        ? defaultCountryCode
        : countryCode
        ? countryCode
        : "US";
      const partnerID = resellerID || "";

      const prdRegion = getAttributeValue("attribute-prdRegion");
      const mDCPOrgIDValue =
        mDCPOrgID || getAttributeValue("attribute-mDCPOrgID");
      const resellerId = getAttributeValue("attribute-resellerId");
      const hideServicesForPartners = quoteNumber
        ? !(
            window._BM_USER_COMPANY &&
            window._BM_USER_COMPANY.startsWith(
              window.location.hostname.split(".")[0]
            )
          )
        : getAttributeValue("attribute-hideServicesForPartners") === "true";
      const isPreApproved =
        ((channelRegion === "AP" || channelRegion === "APJ") &&
          (mDCPOrgIDValue !== "" || partnerID !== "") &&
          !hideServicesForPartners) ||
        ((prdRegion === "APJ" || prdRegion === "AP") &&
          (mDCPOrgIDValue !== "" || resellerId !== "") &&
          !hideServicesForPartners);

      return {
        ...state,
        country: state.countrySelectedDropdown || countryCodeValue,
        showPreApproved: isPreApproved,
        isPartnerPortalFlag,
        isCustomBanded: showCustomBandedFlag,
        userEmail1: userEmail,
        hideServicesForPartners,
        category
      };
    case "SET_RESET_SELECTED":
      return {
        ...state,
        selectedCategory: null,
        selectedSeries: null,
        showSeries: false,
        defaultConfigurations: []
      };
    case "RESET_DEFAULT_CONFIGURATIONS":
      return {
        ...state,
        defaultConfigurations: []
      };
    case "SET_BASEUNIT":
      return {
        ...state,
        baseUnitValue: action.payload.baseunit,
        selectedCategory: action.payload.category,
        selectedSeries: null
      };
    case "SEARCH_DEFAULT_SPC":
      return {
        ...state,
        defaultConfigurations: action.payload.searchListConfigurations,
        isShowProgramType: action.payload.isProgramType
      };
    case "SEARCH_DEFAULT_SPC_BY_BASEUNIT":
      return {
        ...state,
        defaultConfigurations: action.payload.searchListConfigurations,
        isShowProgramType: action.payload.isProgramType,
        selectedCategory: { name: action.payload.selectedCategory },
        selectedSeries: { name: action.payload.selectedSeries },
        series: action.payload.series,
        showSeries: action.payload.showSeries
      };
    case "SET_SERIESINFO":
      return {
        ...state,
        seriesInfo: action.payload
      };
    case "SET_RESET_PAGE":
      return {
        ...state,
        page: action.payload
      };

    case FETCH_PRODUCT_SELECTION:
      return {
        ...state,
        prodSelectionData: action.payload.productSelection
      };

    case SET_COUNTRY_REGION:
      return {
        ...state,
        countryRegion: action.payload
      };
    case SET_PROCATEGORY:
      return {
        ...state,
        category: action.payload
      };
    case SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.payload
      };
    case SET_BUSINESS_MODEL:
      return {
        ...state,
        businessModel: action.payload
      };
    case "SET_SHOW_GADATE":
      return {
        ...state,
        showGADate: action.payload
      };
    case "SET_DISPLAY_BUSINESSMODEL":
      return {
        ...state,
        displayBusinessModel: action.payload
      };
    case "SET_BOM_LIST":
      const { getRSKUMaterialNumber, countryCode: country } = action.payload;
      const bomResult = getRSKUMaterialNumber?.length
        ? getRSKUMaterialNumber.reduce((acc, ele) => {
            acc.push({
              skuId: ele.rskuMaterialNumber,
              description: ele.productShortDescription,
              configId: ele.configId,
              skuStatus: ele.status,
              category: ele.category,
              configName: ele.configName
            });
            return acc;
          }, [])
        : [];

      return {
        ...state,
        btoSkuInfo: bomResult ? bomResult : [],
        configResultList: bomResult ? bomResult : [],
        columns: productSelectorBtoSkuColumns
      };
    case "SET_RSKU_USER_ACCESS":
      const {
        routeToMarket,
        roleName,
        userCountryMap,
        productRegionCode,
        productCountryCode
      } = action.payload;
      let showSearchBto, showSearchSku, showRequestSku;

      showSearchBto = !(
        routeToMarket === "Direct" &&
        (productRegionCode === "EMEA" || productRegionCode === "EU")
      );

      if (!isEmpty(userCountryMap)) {
        const { countryRegionMaps } = userCountryMap;
        const findCountry = countryRegionMaps?.length
          ? countryRegionMaps.find(
              each =>
                each.userCountry === productCountryCode ||
                each.userCountry === "WW"
            )
          : false;
        if (findCountry) {
          showSearchSku = true;
          showRequestSku = findCountry.roleName === "RSKUFull";
        }
      } else {
        showSearchSku = true;
        showRequestSku = roleName === "RSKUFull";
      }

      // Enabling SearchSku & RequestRsku option for all EMEA region users.
      if (SKIP_USER_VALIDATION_REGIONS.includes(productRegionCode)) {
        showSearchSku = true;
        showRequestSku = true;
      }
      return {
        ...state,
        userAccess: userCountryMap,
        showSearchBto: showSearchBto,
        showSearchSku: showSearchSku,
        showRequestSku: showRequestSku
      };
    case "SET_CURRENT_CONFIGID":
      return {
        ...state,
        currentConfigID: action.payload
      };
    case "SET_SHOW_BACKDROP":
      return {
        ...state,
        showRskuBackDrop: action.payload
      };
    case RECEIVE_USER_DETAILS:
      return {
        ...state,
        userInfo: { ...state.userInfo, userDetails: action.payload }
      };

    case "SEARCH_SKU_ID":
      return {
        ...state,
        skuIdData: action.payload
      };
    case "SEARCH_SKU_ID_MESSAGE":
      return {
        ...state,
        warningMessage: action.payload
      };
    case "SET_OCFG_PARAMS":
      return {
        ...state,
        ocfgParams: action.payload
      };
    default:
      return state;
  }
};
export default productSelector;

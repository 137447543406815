import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from "@mui/material";

import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatArrayOfValues } from "../../../../lib/common/util";
import { HARDWARE, NA, OTHER_COMPONENTS } from "../Constants";
import ProductList from "../ProductList";
import ProductSkeleton from "../ProductSkeleton";
import SimilarProducts from "../SimilarProducts";
import TabPanel from "../TabPanel";
import useStyles from "./styles";

const RskuDialogContent = props => {
  const {
    mandatorySkus,
    addCTOToQuote,
    flags,
    handleConfirmationDialogOpen,
    handleRskuInfoDialogOpen,
    handleComapreDialogOpen,
    handleTabChange
  } = props;

  const {
    isExact,
    isSimilar,
    isRequestRsku,
    isOtherComponent,
    isStandaloneAccess,
    isFilteredExactMatch,
    noMatch,
    showSkeleton
  } = flags;

  const classes = useStyles();

  const { t } = useTranslation();

  const productSkeleton = generatArrayOfValues(3);

  const {
    products,
    similarProducts,
    showAddToQuote,
    carePacks,
    activeTab,
    isCreateRsku,
    rskuImage
  } = useSelector(state => state.rskuReducer);
  const { showRequestSku, countryRegion } = useSelector(
    state => state.productSelector
  );

  const { steppers } = useSelector(state => state.configFilterData);

  const eolHardware = steppers.some(
    stepper => stepper.isWarning && stepper.id === "hardware"
  );

  const isNA = countryRegion === NA;

  const TextTooltip = withStyles({
    tooltip: {
      maxWidth: "none"
    }
  })(Tooltip);

  return (
    <DialogContent className={classes.dailogContent}>
      <Grid container spacing={0} className={classes.content}>
        <Grid item xs={12}>
          <Box sx={{ pl: 24 }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabs}
            >
              <Tab label={HARDWARE} />
              <Tab label={OTHER_COMPONENTS} disabled={!isOtherComponent} />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.allowMargin}>
          <TabPanel value={activeTab} index={0} style={{ width: "100%" }}>
            {showSkeleton ? (
              productSkeleton.map((each, index) => (
                <ProductSkeleton key={index} />
              ))
            ) : (
              <>
                {isExact && (
                  <DialogContentText className={classes.text}>
                    {t("common:rsku.dialog.exactMatch")}
                  </DialogContentText>
                )}
                <Box>
                  {isSimilar || noMatch ? (
                    <Paper variant="outlined" className={classes.paper}>
                      {noMatch && (
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          {t("common:rsku.dialog.noMatch")}
                        </Typography>
                      )}

                      {isSimilar && (
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          {t("common:rsku.dialog.closeMatch")}
                        </Typography>
                      )}
                    </Paper>
                  ) : (
                    <>
                      {isExact && isFilteredExactMatch ? (
                        <Paper variant="outlined" className={classes.paper}>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: "bold" }}
                          >
                            {t("common:rsku.dialog.noFilteredExactMatch")}
                          </Typography>
                        </Paper>
                      ) : (
                        products.map(product => (
                          <ProductList
                            key={product.sku}
                            {...product}
                            image={rskuImage}
                          />
                        ))
                      )}
                    </>
                  )}
                </Box>
                <Box>
                  {isSimilar && (
                    <SimilarProducts
                      data={similarProducts}
                      isSimilar={isSimilar}
                    />
                  )}
                </Box>
              </>
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={1} style={{ width: "100%" }}>
            <DialogContentText className={classes.text}>
              {t("common:rsku.dialog.otherTabTitle")}
            </DialogContentText>
            <Box>
              {carePacks.map(carepack => (
                <ProductList
                  key={carepack.sku}
                  {...carepack}
                  tabInfo={"carepack"}
                />
              ))}
            </Box>
          </TabPanel>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Box sx={{ pr: 18.5 }}>
            <DialogActions>
              {isRequestRsku && showRequestSku && (
                <>
                  <TextTooltip
                    arrow
                    title={t("common:rsku.tooltip.requestSKU")}
                    placement="top"
                  >
                    <InfoOutlinedIcon
                      color="action"
                      className={classes.infoIcon}
                    />
                  </TextTooltip>
                  <Button
                    color="primary"
                    onClick={
                      isExact && isNA
                        ? () => handleConfirmationDialogOpen()
                        : () => handleRskuInfoDialogOpen()
                    }
                    className={classes.buttonSpacing}
                  >
                    {t("common:rsku.button.requestRsku")}
                  </Button>
                </>
              )}
              {isSimilar && (
                <Button
                  onClick={handleComapreDialogOpen}
                  variant="contained"
                  color="primary"
                  className={`${classes.buttonSpacing} ${classes.compareButton} `}
                >
                  {t("common:rsku.button.compare")}
                </Button>
              )}

              {(isExact || isSimilar) && !isStandaloneAccess && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isStandaloneAccess || !showAddToQuote}
                  onClick={addCTOToQuote}
                >
                  {t("common:rsku.button.addToQuote")}
                </Button>
              )}
            </DialogActions>
          </Box>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default RskuDialogContent;

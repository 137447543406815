import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { makeStyles, withStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BUNDLED_COMPONENT } from "../components/Configurator/Rsku/Constants";
import ExternalProps from "../contexts/externalPropsContext";
import {
  dateCompare,
  displayCurrencyLite,
  isFutureDate,
  loadingLabel,
  shouldShowOnUI,
  showEsAndPriceSource,
  showFutureGADate,
  transformDateFormat,
  updateIframeHeight
} from "../lib/common/util";
import { skipChaIdInformation } from "../services/common/utils";
import {
  getFieldWidth,
  maxQuantityValue,
  minQuantityValue,
  preventSpecialCharacters
} from "./util";

function getPrice(item, selected, currencyCode) {
  let sign = "";
  // below condition is for issue no:3127
  let price = item.netPrice;
  let color = "#1f96d8";
  if (item.costPriceSource) {
    price = item.productCost;
    color = "#08a06d";
  }

  if (
    selected.inode &&
    selected.inode !== item.inode &&
    item.costPriceSource !== "CP"
  ) {
    const delta = price - (selected.netPrice || 0);
    sign = delta < 0 ? "-" : "+";
    price = delta;
  }
  if (selected.inode && selected.inode === item.inode) {
    sign = "";
  }
  return (
    <>
      {sign ? (
        <Box component="span" marginRight="5px">
          {sign}
        </Box>
      ) : null}
      <Typography
        style={{ color: color }}
        variant={item.selected ? "inherit" : "caption"}
        fontWeight={item.selected ? "bold" : "normal"}
      >
        {currencyCode} &nbsp;
        {displayCurrencyLite({
          value: Math.abs(isNaN(price) ? 0 : price) || "0.00",
          currency: currencyCode,
          locale: "en-US"
        })}
      </Typography>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  radioLabel: {
    fontSize: "12px"
  },
  quantity: {
    margin: `0px ${theme.spacing(0.5)}`,
    "& [class*=MuiInput-input]": {
      textAlign: "center"
    },
    "& input::-webkit-inner-spin-button": {
      opacity: 1
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      fontSize: "0.9rem"
    },
    left: 5,
    right: 10,
    marginRight: 10
  },
  negativePrecon: {
    fontSize: "12px",
    color: "#9e9e9e"
  },
  selectedItemStyle: {
    fontSize: 12,
    fontWeight: "bold"
  },
  box: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
    whiteSpace: "nowrap"
  },
  itemName: {
    flex: 1
  },
  caption: {
    paddingTop: "7px"
  },
  notCompatible: {
    color: "#FF8540",
    borderBottom: "1px solid #FF8540"
  },
  viewMore: {
    cursor: "pointer"
  },
  required: {
    color: "#ff0000"
  }
}));

function ItemSingleRadio({
  chaid,
  handleItemChange,
  handleQuantityChange,
  model,
  itemQuantityRef,
  chaidReq
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState({ inode: "None" });
  const {
    channelRegion,
    countryCode,
    currencyCode,
    isStandaloneAccess = false,
    configID,
    configOrigin,
    isGenericSKU,
    visibility: { showGaDateConfigError = true },
    proCategory: proCategoryFromContext,
    restrictedChoicesForSKUModule = [],
    isSKUModule,
    isBaseCreated,
    disableSelectedPN
  } = React.useContext(ExternalProps);

  const country = useSelector(state => state.productSelector.country);
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const proCategory =
    useSelector(state => state.productSelector.category) ||
    proCategoryFromContext;
  const errorData = useSelector(state => state.configFilterData.errorData);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const loadingSelection = useSelector(
    state => state.configFilterData.loadingSelection
  );
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  // const modelData = useSelector(state => state.configFilterData.modelData);
  const showGADate = model.showGADate;
  const isConfigOverDate = useSelector(
    state => state.configFilterData.isConfigOverDate
  );
  const region = isStandaloneAccess
    ? configuration?.configHeader?.rgnCd || "NA"
    : channelRegion;
  const currentConfigID = useSelector(
    state => state.productSelector.currentConfigID
  );
  const { t } = useTranslation();
  const [toggleName, setToggleName] = React.useState(
    t("common:conflicts.viewMore")
  );
  const [check, setCheck] = React.useState(true);

  const [conflictItems2, setConflictItems2] = React.useState([]);

  const handleOpen = () => {
    setCheck(!check);
    setConflictItems2(check ? itemShowOnPopup : []);
    setToggleName(
      check ? t("common:conflicts.viewLess") : t("common:conflicts.viewMore")
    );
    updateIframeHeight(false);
  };

  useEffect(() => {
    const items =
      model &&
      model.Items?.filter(
        part =>
          part.visible &&
          // part.precon &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      );
    items?.some(item => {
      if (item.selected) {
        setValue({ inode: item.inode, netPrice: item.netPrice });
        return true;
      } else {
        setValue({ inode: "None" });
        return false;
      }
    });
  }, [chaid, model]);

  const compare = (a, b) => {
    const valueA = a.partdes.toUpperCase();
    const valueB = b.partdes.toUpperCase();
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    // To resolve incorrect order for ACSS in otherServices tab.
    function improveOrder(item) {
      if (valueA.includes(item)) {
        comparison = -1;
      } else if (valueB.includes(item)) {
        comparison = 1;
      }
    }
    if (["PSO", "ACSS"].includes(chaid.chaId)) {
      improveOrder("YES");
      improveOrder("NONE");
    }
    return comparison;
  };

  const compareN = (a, b) => {
    const valueA = a.partno.toUpperCase();
    const valueB = b.partno.toUpperCase();
    let comparisonN = 0;
    if (valueA > valueB) {
      comparisonN = 1;
    } else if (valueA < valueB) {
      comparisonN = -1;
    }
    return comparisonN;
  };

  const comparePriority = (a, b) => {
    const valueA = parseInt(a.priority);
    const valueB = parseInt(b.priority);
    let comparisonN = 0;
    if (valueA > valueB) {
      comparisonN = 1;
    } else if (valueA < valueB) {
      comparisonN = -1;
    }
    return comparisonN;
  };

  const handleChange = useCallback(
    event => {
      const items =
        model &&
        model.Items?.filter(
          part =>
            part.visible &&
            part.precon >= 0 &&
            part.inode >= chaid.firstItem &&
            part.inode <= chaid.lastItem
        );
      if (event.target.value === "None") {
        setValue({ inode: "None" });
      } else {
        items?.forEach(item => {
          if (item.inode.toString() === event.target.value) {
            setValue({ inode: item.inode, netPrice: item.netPrice });
          }
        });
      }
    },
    [model]
  );

  const BlueRadio = withStyles({
    root: {
      color: "#00000054;",
      paddingBottom: "1px",
      paddingTop: "1px",
      alignSelf: "baseline",
      "&$checked": {
        color: "#29A8DD"
      },
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    checked: {}
  })(props => <Radio color="default" {...props} />);

  const conflictItems = useMemo(() => {
    return (
      model?.Items?.filter(
        part =>
          part.visible === true &&
          part.precon < 0 &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem &&
          shouldShowOnUI(
            {
              currentConfigID: currentConfigID || showGaDateConfigError,
              showGADate,
              isConfigOverDate
            },
            part.gaDate,
            part.selected
          )
      ) || []
    );
  }, [model]);

  // Check itemConflicts
  conflictItems.forEach(part => {
    if (
      model &&
      model.conflicts &&
      model.conflicts.length &&
      model.conflicts[0].itemConflictsList
    ) {
      let index = model.conflicts[0].itemConflictsList.filter(
        (x, i) => x.effect.partno === part.partno
      );
      if (index.length >= 0) {
        const causeItem = index[0]?.cause;
        if (causeItem) {
          part.cause = causeItem;
        }
      }
    }
  });

  useEffect(() => {
    if (model) {
      let chaidIndex =
        model.Chaids?.findIndex(el => el.chaId === chaid.chaId) ?? -1;
      if (chaidIndex !== -1) {
        model.Chaids[chaidIndex].conflictItems = conflictItems;
      }
    }
  }, [conflictItems, model, chaid.chaId]);
  const startDateHide = isFutureDate();

  const chaidValue = chaid?.carePackParentChoiceID
    ? chaid?.carePackParentChoiceID
    : chaid.chaId;
  const maxQuantity = maxQuantityValue(
    chaidValue,
    proCategory,
    ocfgParams,
    model
  );
  const minQuantity = minQuantityValue(
    chaidValue,
    proCategory,
    ocfgParams,
    model
  );
  const maxQtyLength = maxQuantity.toString().length;

  const ignoreLeadingZeroes = event => {
    if (event.target.value.length > maxQtyLength) {
      event.target.value = parseInt(event.target.value);
    }
  };

  let itemShowOnPopup = conflictItems?.slice(5);
  const finalConflictItems = [...conflictItems];
  finalConflictItems?.sort(a => (a?.selected !== true ? 1 : -1));
  const finalConflictItemsSort = finalConflictItems.slice(0, 5);
  const recommndedProducts =
    (model &&
      model.Items?.filter(
        item => Number(item.priority) >= 1 && Number(item.priority) <= 5
      )) ||
    [];
  const nonRecommendedProducts =
    (model &&
      model.Items?.filter(
        item => Number(item.priority) > 5 || item.priority === ""
      )?.sort(compareN)) ||
    [];
  let sortByPriority = [...recommndedProducts, ...nonRecommendedProducts];
  if (["ELECTCPQ"].includes(chaid.chaId)) {
    sortByPriority = sortByPriority.sort(comparePriority);
  } else {
    let filterItemsForsorting = [];
    let filterAllCustomer = [];
    sortByPriority?.map(item => {
      if (item?.partdes === "All Customers") {
        filterAllCustomer.push(item);
      } else {
        filterItemsForsorting.push(item);
      }
    });
    sortByPriority = [
      ...filterAllCustomer,
      ...filterItemsForsorting.sort(compare)
    ];
    sortByPriority = [
      ...filterAllCustomer,
      ...filterItemsForsorting.sort(compareN)
    ];
  }
  const removeNone = ["PSO"];
  const sortedItems = sortByPriority
    .filter(
      part =>
        part.visible &&
        part.precon >= 0 &&
        part.inode >= chaid.firstItem &&
        part.inode <= chaid.lastItem &&
        shouldShowOnUI(
          {
            currentConfigID: currentConfigID || showGaDateConfigError,
            showGADate,
            isConfigOverDate
          },
          part.gaDate,
          part.selected
        )
    )
    .concat(
      conflictItems2.length === 0 ? finalConflictItemsSort : finalConflictItems
    );
  // For handling itemQuantityRef
  sortedItems.length > 10
    ? (itemQuantityRef.current = true)
    : (itemQuantityRef.current = false);

  const itemWithRType = sortedItems.find(
    item => item.selcon && item.iDefault && item.selected
  );
  const RtypePreselectionMap = [];
  let isRtypeChaid = false;
  if (sortedItems.includes(itemWithRType)) {
    isRtypeChaid = true;
    sortedItems.map(item => {
      if (item.partno !== itemWithRType.partno) {
        RtypePreselectionMap.push(item.inode); // setting all the items as true except the one with rType
      }
    });
  }

  const hasBOMThreeSelection = sortedItems.some(
    item => BUNDLED_COMPONENT.includes(item.bndlBomType) && item.selected
  );

  return (
    <>
      <Box id={"chaid-dropdown-" + chaid.chaId} width="100%">
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            defaultValue="None"
            value={value.inode}
            onChange={handleChange}
          >
            {!removeNone.includes(chaid.chaId) && !chaid.qtyFlag && (
              <FormControlLabel
                name={chaid.chaId}
                disabled={
                  chaidReq ||
                  isGenericSKU ||
                  isRtypeChaid ||
                  (isSKUModule &&
                    isBaseCreated &&
                    (restrictedChoicesForSKUModule.includes(chaid.chaId) ||
                      hasBOMThreeSelection ||
                      disableSelectedPN))
                }
                value="None"
                control={<BlueRadio disableRipple size="small" />}
                label={t("common:productSelector.none")}
                classes={{ label: classes.radioLabel }}
                onChange={e => handleItemChange(e)}
              />
            )}
            {sortedItems.map(item => {
              const isGaDateConflicts =
                model &&
                model.conflicts &&
                model.conflicts[0].gaDateConflicts &&
                model.conflicts[0].gaDateConflicts.some(
                  res =>
                    res.code === "ERR10" &&
                    res.chaId == chaid.chaId &&
                    res.partNo === item.partno
                );
              return (
                <div key={item.inode}>
                  {item.selected && isFutureDate(item.saDate)}
                  {!startDateHide ? (
                    <Box key={item.inode} display="flex" alignItems="center">
                      <Box flex="1" style={{ wordBreak: "break-word" }}>
                        <FormControlLabel
                          name={chaid.chaId}
                          id={item.inode}
                          value={item.inode}
                          disabled={
                            RtypePreselectionMap.includes(item.inode) ||
                            (isSKUModule &&
                              isBaseCreated &&
                              (restrictedChoicesForSKUModule.includes(
                                chaid.chaId
                              ) ||
                                hasBOMThreeSelection ||
                                BUNDLED_COMPONENT.includes(item.bndlBomType) ||
                                disableSelectedPN))
                          }
                          classes={{
                            label: item.selected
                              ? classes.selectedItemStyle
                              : item.precon < 0
                              ? classes.negativePrecon
                              : classes.radioLabel
                          }}
                          control={
                            chaid.qtyFlag ? (
                              <Tooltip
                                title={
                                  t("common:configurator.maxQuantity") +
                                  maxQuantity
                                }
                                placement="right-start"
                                arrow
                              >
                                <TextField
                                  variant="standard"
                                  onBlur={event => {
                                    if (item.quantity === "") {
                                      handleQuantityChange(
                                        event,
                                        0,
                                        item.partno,
                                        item.chaId,
                                        item.inode,
                                        maxQuantity,
                                        minQuantity
                                      );
                                    }
                                  }}
                                  className={classes.quantity}
                                  type="number"
                                  onKeyDown={event =>
                                    preventSpecialCharacters(event)
                                  }
                                  style={{
                                    minWidth: "50px",
                                    maxWidth: `${getFieldWidth(
                                      item.quantity
                                    )}px`
                                  }}
                                  InputProps={{
                                    defaultValue: item.quantity || 1,
                                    inputProps: {
                                      min: minQuantity,
                                      max: maxQuantity
                                    }
                                  }}
                                  onInput={event => ignoreLeadingZeroes(event)}
                                  value={parseInt(item.quantity)}
                                  onChange={event =>
                                    handleQuantityChange(
                                      event,
                                      event.target.value,
                                      item.partno,
                                      item.chaId,
                                      item.inode,
                                      maxQuantity,
                                      minQuantity
                                    )
                                  }
                                />
                              </Tooltip>
                            ) : (
                              <BlueRadio disableRipple size="small" />
                            )
                          }
                          label={loadingLabel(
                            item.partno !== ""
                              ? `${item.partno} - ${item.partdes}`
                              : `${item.partdes}`,
                            loadingSelection === item.inode,
                            false,
                            t("common:configurator.futureGADateMsg"),
                            showFutureGADate(
                              configID,
                              configOrigin,
                              item.gaDate
                            ) && item.selected
                          )}
                          onChange={e => handleItemChange(e)}
                          //checked={item.selected}
                          className={
                            model &&
                            model.conflicts &&
                            model.conflicts[0]?.itemConflictsList[0]?.cause
                              ?.length === 0 &&
                            Number(errorData.value) === item.inode
                              ? classes.required
                              : null
                          }
                        />
                      </Box>
                      {item.precon >= 0 ||
                      (item.precon < 0 && item.selected) ? (
                        <>
                          <Box display="flex">
                            {(defaultConfigStepSlected ===
                              "monitoringAccesories" ||
                              (defaultConfigStepSlected === "carePacks" &&
                                chaid.chaId === "ELECTCPQ")) &&
                              Number(item.priority) >= 1 &&
                              Number(item.priority) <= 5 && (
                                <Box width="100px">
                                  {t("common:configurator.recommended")}
                                </Box>
                              )}
                          </Box>
                          {skipChaIdInformation.includes(chaid.chaId) ? (
                            ""
                          ) : (
                            <Box
                              display="flex"
                              fontWeight={item.selected ? "bold" : "normal"}
                              style={{ fontSize: "0.74rem" }}
                            >
                              <Box
                                width="120px"
                                className={classes.box}
                                fontWeight={item.selected ? "bold" : "normal"}
                              >
                                {getPrice(item, value, currencyCode)}
                              </Box>
                              {showFutureGADate(
                                configID,
                                configOrigin,
                                item.gaDate
                              ) && (
                                <Box
                                  width="100px"
                                  className={classes.box}
                                  fontWeight={item.selected ? "bold" : "normal"}
                                >
                                  {item?.gaDate && (
                                    <>
                                      GA:
                                      {item.gaDate
                                        ? transformDateFormat(
                                            item.gaDate,
                                            "MM/DD/YYYY"
                                          )
                                        : ""}
                                    </>
                                  )}
                                </Box>
                              )}
                              {showEsAndPriceSource(
                                region,
                                isStandaloneAccess ? country : countryCode,
                                "EM",
                                currentConfigID,
                                configOrigin
                              ) && (
                                <Box
                                  width="100px"
                                  fontWeight={item.selected ? "bold" : "normal"}
                                >
                                  {t("common:configurator.EM") +
                                    (item.esDate
                                      ? transformDateFormat(
                                          item.esDate,
                                          "MM/DD/YYYY"
                                        )
                                      : "")}
                                </Box>
                              )}

                              {/* Commented below code for getting pricesource for allthe regions
                            issue no: 3126 */}
                              {showEsAndPriceSource(
                                region,
                                isStandaloneAccess ? country : countryCode,
                                "PriceSource",
                                currentConfigID,
                                configOrigin
                              ) && (
                                <Box
                                  width="100px"
                                  fontWeight={item.selected ? "bold" : "normal"}
                                >
                                  {/*Below condition is for issue no:3126 */}
                                  {t("common:configurator.priceSource") +
                                    (item.costPriceSource
                                      ? item.costPriceSource
                                      : item.priceSource)}
                                </Box>
                              )}
                              {currentConfigID &&
                                showGADate &&
                                isConfigOverDate && (
                                  <Box
                                    width="100px"
                                    className={classes.box}
                                    fontWeight={
                                      item.selected ? "bold" : "normal"
                                    }
                                  >
                                    {item?.gaDate &&
                                      dateCompare(item?.gaDate) && (
                                        <>
                                          GA:
                                          {item.gaDate
                                            ? transformDateFormat(
                                                item.gaDate,
                                                "MM/DD/YYYY"
                                              )
                                            : ""}
                                        </>
                                      )}
                                  </Box>
                                )}
                            </Box>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="caption"
                            className={classes.negativePrecon}
                          >
                            {item.cause
                              ? `This component has a conflict with current ${item.cause.chaDes} ${item.cause.partno}`
                              : t("common:conflicts.text4")}
                          </Typography>
                        </>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                  {item.selected && item.plcStatus === "I" && (
                    <div style={{ color: "red" }}>{item.plcMessage}</div>
                  )}
                  {item.plcStatus !== "I" && isGaDateConflicts && (
                    <Box style={{ color: "red" }}>
                      {t("common:configurator.componentNotAvailable")}
                    </Box>
                  )}
                  {/* Handling item messages here */}
                  {model.icMsgs?.itemMsgs?.map(itemMsg =>
                    itemMsg?.itemInode == item?.inode &&
                    item?.selected &&
                    itemMsg.msgs.length > 0 ? (
                      <Alert
                        severity="info"
                        style={{
                          margin: "8px 0px",
                          borderRadius: "5",
                          alignItems: "center"
                        }}
                      >
                        {itemMsg.msgs?.map(itemMsg => (
                          <Typography variant={"subtitle2"}>
                            {itemMsg}
                          </Typography>
                        ))}
                      </Alert>
                    ) : null
                  )}
                  {/* item messages ends here */}
                </div>
              );
            })}
          </RadioGroup>
          {itemShowOnPopup?.length > 0 && (
            <Typography variant="caption" className={classes.caption}>
              {t("common:conflicts.text5").replace(
                "{0}",
                finalConflictItems.length
              )}
              <Typography
                component="span"
                variant="caption"
                color="primary"
                onClick={handleOpen}
              >
                <span className={classes.viewMore}>{toggleName}.</span>
              </Typography>
            </Typography>
          )}
        </FormControl>
      </Box>
    </>
  );
}

export default ItemSingleRadio;

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import React from "react";
import { REGEX_CHINESE } from "../../../models/configuration/productSelector/Constants";
import { displayCurrencyLite } from "../../../lib/common/util";

Font.register({
  family: "Microsoft YaHei",
  format: "truetype",
  src: "https://dev-ociq.hpcloud.hp.com/assets/fonts/msyh.ttf"
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: "30px 10px"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  choiceContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 5
  },
  choiceDetails: {
    display: "flex",
    marginLeft: 5
  },
  docTitle: {
    fontSize: "20px",
    marginBottom: 10,
    fontWeight: "bold",
    width: "100%",
    textAlign: "center"
  },
  choiceTitle: {
    backgroundColor: "#f6f6f5",
    fontSize: 15,
    marginBottom: 10,
    fontWeight: "bold"
  },
  partTitle: {
    fontSize: 10,
    marginBottom: 3
  },
  choiceOverview: {
    fontSize: 10
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    fontSize: "12px"
  },
  priceContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    paddingRight: 5,
    fontSize: 14,
    fontWeight: 900
  },
  infoItem: {
    flex: 0.5
  },
  containerImage: {
    alignItems: "flex-end"
  },
  image: {
    height: 50,
    width: 50
  },
  subtitle: {
    marginBottom: 3,
    fontSize: "16px"
  },
  detailsFooter: {
    display: "flex",
    flexDirection: "row"
  },
  circle: {
    width: 20,
    height: 20
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    marginTop: 5
  },
  tableRowHeader: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    backgroundColor: "#008ff3",
    color: "white",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableRow: {
    display: "flex",
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  configName: {
    flex: 0.5,
    fontFamily: "Microsoft YaHei"
  }
});

export function ConfigPdfDoc({
  configuration,
  formattedBOMItems,
  newSavedConfigId,
  grandPrice,
  currencyCode,
  proCategory
}) {
  const text = configuration?.configHeader?.configName;
  const hasChineseText = text?.match(REGEX_CHINESE);
  const formatPrice = num => {
    return num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.containerImage}>
            <Image
              style={styles.image}
              src={`${process.env.REACT_APP_IMG_BASE_URL}/hpLogo.png`}
            />
          </View>
          {proCategory !== "poly" && (
            <>
              <View style={styles.infoContainer}>
                <Text style={styles.infoItem}>Config Name -</Text>
                <Text
                  style={hasChineseText ? styles.configName : styles.infoItem}
                >
                  {configuration?.configHeader?.configName.substring(0, 40)}
                </Text>
              </View>
              {configuration?.configHeader?.configName.length >= 40 && (
                <View style={styles.infoContainer}>
                  <Text style={styles.infoItem}></Text>
                  <Text
                    style={hasChineseText ? styles.configName : styles.infoItem}
                  >
                    {configuration?.configHeader?.configName.substring(40, 80)}
                  </Text>
                </View>
              )}
              {configuration?.configHeader?.configName.length >= 80 && (
                <View style={styles.infoContainer}>
                  <Text style={styles.infoItem}></Text>
                  <Text
                    style={hasChineseText ? styles.configName : styles.infoItem}
                  >
                    {configuration?.configHeader?.configName.substring(80)}
                  </Text>
                </View>
              )}
              <View style={styles.infoContainer}>
                <Text style={styles.infoItem}>Config ID -</Text>
                <Text style={styles.infoItem}>
                  {newSavedConfigId
                    ? newSavedConfigId
                    : configuration?.configHeader?.docType === "QU"
                    ? configuration.configHeader.configId
                    : "NA"}
                </Text>
              </View>
            </>
          )}

          <View style={styles.infoContainer}>
            <Text style={styles.infoItem}>Grand Price -</Text>
            <Text style={styles.infoItem}>{formatPrice(grandPrice)}</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRowHeader}>
              <View style={{ width: "50%" }}>
                <Text style={styles.tableCellHeader}>Component</Text>
              </View>
              <View style={{ width: "20%" }}>
                <Text style={styles.tableCellHeader}>Product Number</Text>
              </View>
              <View style={{ width: "15%" }}>
                <Text style={styles.tableCellHeader}>Quantity</Text>
              </View>
              <View style={{ width: "15%", textAlign: "right" }}>
                <Text style={styles.tableCellHeader}>Price</Text>
              </View>
            </View>
            {formattedBOMItems.map(row => (
              <View key={row.partNumber} style={styles.tableRow} wrap={false}>
                <View style={{ width: "50%" }}>
                  <Text style={styles.tableCell}>{row.description}</Text>
                </View>
                <View style={{ width: "20%" }}>
                  <Text style={styles.tableCell}>{row.partNumber}</Text>
                </View>
                <View style={{ width: "15%" }}>
                  <Text style={styles.tableCell} align="left">
                    {row.qty}
                  </Text>
                </View>
                <View style={{ width: "15%", textAlign: "right" }}>
                  <Text style={styles.tableCell}>
                    {displayCurrencyLite({
                      value: row.listPrice || "0.00",
                      currency: currencyCode,
                      locale: "en-US"
                    })}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.priceContainer}>
            <Text>Grand Price:</Text>
            <Text>{formatPrice(grandPrice)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

// Positions to change the order of panel and bom containers.
export const PANEL_CONTAINER_POSITION = 1;
export const BOM_CONTAINER_POSTION = 2;

export const USERS = [
  {
    username: "stacy.boyle",
    password: "1",
    email: "stacy.boyle@hp.com",
    roles: ["Internal"],
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN0YWN5LmJveWxlIiwicGFzc3dvcmQiOiIxIiwiZW1haWwiOiJzdGFjeS5ib3lsZUBocC5jb20iLCJyb2xlcyI6WyJJbnRlcm5hbCJdfQ.GZ6MPdYv9GuTIXy6DFA3JwUIovmojlK1gmQrmO9Wye4"
  },
  {
    username: "stacy.boyle",
    password: "1",
    email: "stacy.boyle@hp.com",
    roles: ["Partner"],
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN0YWN5LmJveWxlIiwicGFzc3dvcmQiOiIxIiwiZW1haWwiOiJzdGFjeS5ib3lsZUBocC5jb20iLCJyb2xlcyI6WyJQYXJ0bmVyIl19.czMPF1WbwT6DlcvgynN_VbF4VDoztIrBxo_li07q9M4"
  }
];

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "0px 0px 8px 0px"
  },
  configid_container: {
    height: "74px",
    background: "#FFFFFF",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px",
    padding: "0 15px"
  },
  heading: {
    fontSize: "16px",
    color: "#000000"
  }
}));

const DisplayKmatId = ({ chaid }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  //ftech configurator from state
  const modelData = useSelector(state => state.configFilterData.modelData);
  const kmatId = useSelector(state => state.configFilterData.kmatId);
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const modelId = useSelector(state => state.uploadConfig.modelId);

  //define local state
  const [kmatID, setKmatID] = useState(kmatId);

  useEffect(() => {
    if (configuration && Object.keys(configuration).length > 0) {
      const { kmatId } = configuration.configHeader;
      setKmatID(kmatId);
    }
  }, [configuration]);
  useEffect(() => {
    if (modelData && modelData?.kmatId) {
      const { kmatId } = modelData;
      setKmatID(kmatId);
    }
  }, [modelData]);
  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="center"
        className={classes.configid_container}
      >
        <Grid item xs={12} sm={6}>
          <Typography className={classes.heading}>
            {t("common:configurator.kmatID")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.heading}>
            {kmatID ? kmatID : modelData?.isUploadFlow ? modelId : ""}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default DisplayKmatId;

import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { togglePopupActions } from "../../../actions/togglePopupActions";
import CompareCard from "./CompareCard";
import { BOM } from "./Constants";

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1
  },
  title: {
    margin: 0,
    textAlign: "center"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  content: {
    paddingBottom: theme.spacing(5),
    marginBottom: 60
  },
  contentTextWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  textColor: {
    color: "#0096d6"
  },
  subTitle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px"
  }
}));

const CompareDialog = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { compareData, baseProduct } = useSelector(state => state.rskuReducer);

  const handleComapreDialogClose = useCallback(() => {
    dispatch(togglePopupActions({ name: "compareDialog", open: false }));
  }, []);
  return (
    <Dialog fullScreen open={true} onClose={handleComapreDialogClose} fullWidth>
      <DialogTitle className={classes.title}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          {t("common:rsku.compare.compareProducts")}
        </Typography>
        <IconButton
          onClick={handleComapreDialogClose}
          className={classes.closeButton}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <Grid className={classes.subTitle}>
          <Typography>
            {t("common:rsku.similar.subTitle")}&nbsp;
            <span className={classes.textColor}>
              {t("common:rsku.similar.highlighted")}
            </span>
            &#46;
          </Typography>
          <Button
            onClick={handleComapreDialogClose}
            variant="contained"
            color="primary"
          >
            {t("common:rsku.compare.back")}
          </Button>
        </Grid>
        <Grid container className={classes.container} spacing={0}>
          <Grid item xs={3}>
            <CompareCard product={baseProduct[0]} type={BOM}></CompareCard>
          </Grid>

          {compareData.map((similarProduct, index) => (
            <Grid key={index} item xs={3}>
              <CompareCard product={similarProduct} showCarePacks />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CompareDialog;

import {
  DO_DESELECT,
  DO_SELECT,
  INVALID,
  KBD_LOCALIZATION_CATEGORY,
  KYBD_LOC_OPTION,
  MON_LOCALIZATION_CATEGORY,
  MON_LOC_OPTION,
  OS_LOCALIZATION_CATEGORY,
  OS_LOC_OPTION,
  SPECIAL_ITEM_MESSAGE,
  SYS_LOCALIZATION_CATEGORY,
  SYS_LOC_OPTION,
  TEXT_EMPTY,
  VALID
} from "../Constants";
import { processInput } from "../NeuralNet/processInput";
import {
  getChoiceConflicts,
  getHiddenChoiceConflicts,
  getIChaid,
  getItemConflictsNn,
  getSameConflictItems,
  initializeNeuralNetVars,
  setChoiceConflicts,
  setHiddenChoiceConflicts,
  setItemConflictsNn
} from "../NeuralNet/processOutput";
import { RemoveElmConflictsForNotSelected } from "../util";
let itemConflictLoc = [];

//src/webasm/localization.go
export const getLocChoiceIdsByCategory = (category, updatedModel) => {
  const sysMap = {
    CTRYLOC: true,
    CKIT: true,
    CLOC: true,
    DIB: true,
    DIBHW: true,
    PWRCRD: true
  }

  const keyboardMap = {
    KYBD: true
  };
  const osMap = {
    OS: true,
    OSLOC: true,
    APPSW: true,
    APPSW1: true,
    APPSW2: true
  };
  const monitorMap = {
    ASCM_64283: true,
    ASCM_3242660: true,
    ASCM_POLYMON: true,
    ASCM_POLYACC: true
  };
  switch (category) {
    case SYS_LOCALIZATION_CATEGORY:
      for (const choice of updatedModel.Chaids) {
        if (choice.chaId.startsWith("ASCM_") && !monitorMap[choice.chaId]) {
          sysMap[choice.chaId] = true;
        }
      }
      return sysMap;
    case KBD_LOCALIZATION_CATEGORY:
      return keyboardMap;
    case OS_LOCALIZATION_CATEGORY:
      return osMap;
    case MON_LOCALIZATION_CATEGORY:
      return monitorMap;
    default:
      return {};
  }
};

//src/webasm/localization.go
export const isSpecificItem = (choice, item) => {
  if (
    item.partno === TEXT_EMPTY &&
    item.plcStatus === VALID &&
    item.emDate != ""
  ) {
    return true;
  }
  return false;
};

//src/webasm/localization.go
export const isBetweenDate = (referenceTime, priceStartDate, priceEndDate) => {
  if (!priceStartDate || !priceEndDate) {
    return false;
  }
  // Product Backlog Item 62157: [Enabler] Allow components with future start date on Configurator
  const startDate = new Date(priceStartDate);
  const endDate = new Date(priceEndDate);
  if (startDate < endDate && referenceTime < endDate) {
    return true;
  }
  return false;
};

export const isSpecialDateFilter = (startDate, endDate, targetDate) => {
  return endDate.getFullYear() == 9999 || endDate.getFullYear() == 8888;
};

export const isSpecialValidationPass = (target, startDate, endDate) => {
  return !isSpecialDateFilter(startDate, endDate, target);
};

//src/webasm/localization.go
export const isMaterialNotExpired = (referenceTime, item) => {
  let isItemNotExpired = false;
  if (item.plcStatus !== VALID) {
    return { item, isItemNotExpired };
  }
  const startDate =
    item?.saDate !== ""
      ? item?.saDate?.slice(0, 4) +
        "-" +
        item?.saDate?.slice(4, 6) +
        "-" +
        item?.saDate?.slice(6)
      : "";
  const endDate =
    item?.esDate !== ""
      ? item?.esDate?.slice(0, 4) +
        "-" +
        item?.esDate?.slice(4, 6) +
        "-" +
        item?.esDate?.slice(6)
      : "";
  const startDt = new Date(item?.saDate);
  const endDt = new Date(item?.esDate);
  if (!isSpecialValidationPass(startDt, endDt, referenceTime)) {
    return false;
  }
  if (isBetweenDate(referenceTime, startDate, endDate)) {
    isItemNotExpired = true;
    return { item, isItemNotExpired };
  }
  item.plcStatus = INVALID;
  item.plcMessage =
    "Material date invalid for " + getFormattedTime(referenceTime);
  return { item, isItemNotExpired };
};

export const getFormattedTime = time => {
  return (
    time.getFullYear().toString() +
    "-" +
    (time.getMonth() + 1).toString() +
    "-" +
    time.getDate().toString()
  );
};

//src/webasm/localization.go
export const isPriceValid = (referenceTime, item) => {
  let isValidPrice = false;
  if (item.priceStatus !== VALID) {
    return { item, isValidPrice };
  }
  const sDate = new Date(item.priceStartDate);
  const eDate = new Date(item.priceEndDate);
  if (isBetweenDate(referenceTime, sDate, eDate)) {
    isValidPrice = true;
    return { item, isValidPrice };
  }
  item.priceStatus = INVALID;
  item.priceMessage =
    "Price date invalid for " + getFormattedTime(referenceTime);
  return { item, isValidPrice };
};

//src/webasm/localization.go
export const isItemDatesValidOrMark = (referenceTime, choice, item) => {
  let isValid = false;
  if (isSpecificItem(choice, item)) {
    item.priceMessage = SPECIAL_ITEM_MESSAGE;
    item.plcMessage = SPECIAL_ITEM_MESSAGE;
    isValid = true;
    return { item, isValid };
  }
  const priceValidityData = isPriceValid(referenceTime, item);
  item = priceValidityData.item;
  const { isValidPrice } = priceValidityData;
  const itemExpiryData = isMaterialNotExpired(referenceTime, item);
  item = itemExpiryData.item;
  const { isItemNotExpired } = itemExpiryData;
  if (isValidPrice && isItemNotExpired) {
    isValid = true;
    return { item, isValid };
  }
  return { item, isValid };
};

//src/main-webasm.go
export const updateModel = (iEdge, iNode, changeIn, modelData) => {
  initializeNeuralNetVars();
  const payload = {
    inputEdge: iEdge,
    inputNode: iNode,
    changeIn
  };
  let updatedModel = modelData;
  const isItemAlreadySelected =
    changeIn === DO_SELECT && modelData.Items[iNode].selected;

  const isItemAlreadyDeselected =
    changeIn === DO_DESELECT && !modelData.Items[iNode].selected;

  // If selection input comes for already selected part OR deselection input comes for already deselected part,
  // then we should not process the input.
  if (!(isItemAlreadySelected || isItemAlreadyDeselected)) {
    updatedModel = processInput(payload, modelData);
  }

  if (updatedModel?.conflicts?.length > 0) {
    if (updatedModel.conflicts[0].itemConflictsList?.length > 0) {
      setItemConflictsNn(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.itemConflictsList
      );
    }
    if (updatedModel.conflicts[0].hiddenChoiceConflicts?.length > 0) {
      setHiddenChoiceConflicts(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.hiddenChoiceConflicts
      );
    }
    if (updatedModel.conflicts[0].choiceConflicts?.length > 0) {
      setChoiceConflicts(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.choiceConflicts
      );
    }
  }
  if (updatedModel?.conflicts?.length > 0) {
    updatedModel.conflicts[0].itemConflictsList = getItemConflictsNn();
    updatedModel.conflicts[0].hiddenChoiceConflicts =
      getHiddenChoiceConflicts();
    updatedModel.conflicts[0].choiceConflicts = getChoiceConflicts();
  }
  setSameConflictItems(updatedModel);
  return updatedModel;
};

const doesElementExistInSelectConflictItems = (modelMapArr, mapp) => {
  let flag = false;
  for (let i = 0; i < modelMapArr?.length; i++) {
    if (mapp[i]) {
      flag = true;
      break;
    }
  }
  return flag;
};

const doesElementExistItemConflictsLoc = (inode, itemConflictsLoc) => {
  let flag = false;
  itemConflictsLoc.forEach(each => {
    if (each.effect.iitem === inode) {
      flag = true;
    }
  });
  return flag;
};

export const setSameConflictItems = modelData => {
  let sameConflictItemsTemp = modelData?.sameConflictItems;
  modelData.sameConflictItems = [];
  sameConflictItemsTemp?.forEach(each => {
    if (
      modelData.Items[each].selected &&
      modelData.Items[each].precon < 0 &&
      !modelData.sameConflictItems?.includes(each)
    ) {
      modelData.sameConflictItems = [...modelData.sameConflictItems, each];
    }
  });

  let sameConflictItems = getSameConflictItems();
  if (
    sameConflictItems &&
    !doesElementExistInSelectConflictItems(
      modelData?.sameConflictItems,
      sameConflictItems
    )
  ) {
    Object.keys(sameConflictItems)?.forEach(each => {
      let conflictNode = Number(each);
      if (!modelData?.sameConflictItems?.includes(conflictNode)) {
        modelData.sameConflictItems = [
          ...modelData.sameConflictItems,
          conflictNode
        ];
      }
    });
  }
};

export const getCauseNEffect = (model, localization, change) => {
  // let eff = [];
  // let cause = [];
  let chg = -1;
  if (change) {
    chg = 1;
  }

  let tempItemConflicts = itemConflictLoc;
  itemConflictLoc?.forEach(conflicts => {
    if (localization.locOptionCategory === KYBD_LOC_OPTION) {
      if (conflicts.cause.chaId?.includes(KYBD_LOC_OPTION)) {
        if (conflicts.cause.partno !== localization.locOption) {
          tempItemConflicts = RemoveElmConflictsForNotSelected(
            conflicts?.effect.iitem,
            tempItemConflicts
          );
        }
      }
    }
    if (localization.locOptionCategory === OS_LOC_OPTION) {
      if (conflicts.cause.chaId?.includes(OS_LOC_OPTION)) {
        if (conflicts.cause.partno !== localization.locOption) {
          tempItemConflicts = RemoveElmConflictsForNotSelected(
            conflicts?.effect.iitem,
            tempItemConflicts
          );
        }
      }
    }
    if (localization.locOptionCategory === SYS_LOC_OPTION) {
      if (conflicts.cause.chaId?.includes(SYS_LOC_OPTION)) {
        if (conflicts.cause.partno !== localization.locOption) {
          tempItemConflicts = RemoveElmConflictsForNotSelected(
            conflicts?.effect.iitem,
            tempItemConflicts
          );
        }
      }
    }
    if (localization.locOptionCategory === MON_LOC_OPTION) {
      if (conflicts.cause.chaId?.includes(MON_LOC_OPTION)) {
        if (conflicts.cause.partno !== localization.locOption) {
          tempItemConflicts = RemoveElmConflictsForNotSelected(
            conflicts?.effect.iitem,
            tempItemConflicts
          );
        }
      }
    }
  });
  itemConflictLoc = [];
  itemConflictLoc = tempItemConflicts;
  for (let i = 0; i < model?.Items?.length; i++) {
    const item = model.Items[i];
    if (
      item.visible &&
      item.selected &&
      item.precon < 0 &&
      !doesElementExistItemConflictsLoc(item?.inode, itemConflictLoc)
    ) {
      const { Ichaid, ChaId, ChaDes } = getIChaid(item.inode, model);
      let newEffect = {
        cause: {
          chaDes: "",
          partno: localization?.locOption,
          chaId: localization?.locOptionCategory,
          ichange: chg,
          etype: 0,
          ichaid: 0,
          iitem: 0
        },
        effect: {
          iitem: item.inode,
          partno: item.partno,
          ichaid: Ichaid,
          chaId: ChaId,
          chaDes: ChaDes
        }
      };
      itemConflictLoc = [...itemConflictLoc, newEffect];
    }
  }
};

export const getItemConflictsLoc = () => {
  return itemConflictLoc;
};

export const setItemConflictsLoc = itemConflicts => {
  itemConflictLoc = itemConflicts;
};

import { DefaultQty, VALID, sysLocChaidsCOMPUTE_locChaidsPRINT } from "../Constants"
import { initializeNeuralNetVars } from "../NeuralNet/processOutput"
import {
  getLocChoiceIdsByCategory,
  isItemDatesValidOrMark,
  updateModel
} from "./util"

const valid = "V"
const DO_DESELECT = -1
const DO_SELECT = 1

//src/webasm/localization.go
export const applyLocalization = (
  selectedLocCat,
  choice,
  oldOption,
  newOption,
  model
) => {
  const isDebug = localStorage.getItem("isDebug") === "true"
  let updatedModel = model
  initializeNeuralNetVars();
  if (!getLocChoiceIdsByCategory(selectedLocCat, updatedModel)[choice.chaId]) {
    return updatedModel
  }
  let bandedFalseItms = []
  for (let i = choice.firstItem; i <= choice.lastItem; i++) {
    let item = updatedModel.Items[i]
    if (!item.partno.includes("#") || item.plcStatus !== valid) {
      continue
    }
    let itemLocOpt = item.partno.split("#")[1]
    const itemValidationOutput = isItemDatesValidOrMark(
      new Date(),
      choice,
      item
    )
    updatedModel.Items[i] = itemValidationOutput?.item
    item = itemValidationOutput?.item
    if (
      itemLocOpt.toUpperCase() === newOption.locOption.toUpperCase() &&
      itemValidationOutput?.isValid
    ) {
      item.visible = true
      item.isBandedItem = item.visible
      continue
    }
    if (!item.visible) {
      continue
    }
    item.visible = false
    if (
      itemLocOpt === oldOption.locOption
    ) {
      item.isBandedItem = false;
    }
    if (item.selected) {
      updatedModel = updateModel(
        updatedModel.Nodes[item.inode].type,
        updatedModel.Nodes[item.inode].id,
        DO_DESELECT,
        updatedModel
      )

      item.isBandedItem = false
      bandedFalseItms = [...bandedFalseItms, item.inode]

      isDebug &&
        console.log(
          "---Deselected item:",
          item.partno,
          item.partdes,
          "in choice ",
          choice.chaId,
          choice.chaDes,
          "by updateVisibleFlagsForLocalizedItems ---"
        )
      if (choice.qtyFlag) {
        item.quantity = DefaultQty
      } else {
        let newLocItemSelected = false
        const basePart = item.partno.split("#")[0]
        let newPartNo = item.partno.split("#")[0] + "#" + newOption.locOption
        for (let j = choice.firstItem; j <= choice.lastItem; j++) {
          let nItem = updatedModel.Items[j]
          const itemValidationOutput = isItemDatesValidOrMark(
            new Date(),
            choice,
            nItem
          )
          if (
            nItem.partno === newPartNo &&
            nItem.plcStatus === valid &&
            itemValidationOutput?.isValid
          ) {
            let node = updatedModel.Nodes[nItem.inode]
            updatedModel = updateModel(
              node.type,
              node.id,
              DO_SELECT,
              updatedModel
            )
            newLocItemSelected = true
            break
          }
        }

        // fallback to other defaultlclz items
        if (!newLocItemSelected) {
          let defaultlclzdata = updatedModel?.locFallbackCodes?.split(":") || []
          for (let lclz of defaultlclzdata) {
            lclz = lclz.trim()
            for (let j = choice.firstItem; j <= choice.lastItem; j++) {
              let nItem = updatedModel.Items[j]
              if (
                nItem.partno.includes(lclz) &&
                nItem.precon >= 0 &&
                !nItem.selected &&
                nItem.plcStatus === valid &&
                isItemDatesValidOrMark(new Date(), choice, nItem)
              ) {
                let node = updatedModel.Nodes[nItem.inode]
                initializeNeuralNetVars()
                updateModel(node.type, node.id, DO_SELECT, updatedModel)
                nItem.visible = true
                nItem.isBandedItem = true
                newLocItemSelected = true
                break
              }
            }
          }
        }

        // fallback to other available item
        if (!newLocItemSelected) {
          for (let j = choice.firstItem; j <= choice.lastItem; j++) {
            let nItem = updatedModel.Items[j]
            const itemValidationOutput = isItemDatesValidOrMark(
              new Date(),
              choice,
              nItem
            )
            if (
              nItem.partno.startsWith(basePart + "#") &&
              nItem.precon >= 0 &&
              nItem.plcStatus === valid &&
              !nItem.selected &&
              itemValidationOutput?.isValid
            ) {
              let node = updatedModel.Nodes[nItem.inode]
              updatedModel = updateModel(
                node.type,
                node.id,
                DO_SELECT,
                updatedModel
              )
              nItem.visible = true
              nItem.isBandedItem = true
              break
            }
          }
        }
      }
      isDebug &&
        console.log(
          "---Localized item is set invisible for =",
          item.partno,
          item.partdes,
          "under choice =",
          choice.chaId,
          choice.chaDes,
          "by updateVisibleFlagsForLocalizedItems"
        )
    }
  }
  return {updatedModel, bandedFalseItms}
}

export const applyLocalizationFallbackWASM = (chIdx, model, oldLocOpt) => {
  let bandedFalseItemsArr = []
  const incompatibleSelectedItems = {};
  let updatedModel = model
  for (let i = updatedModel.Chaids[chIdx].firstItem; i <= updatedModel.Chaids[chIdx].lastItem; i++) {
    const item = updatedModel.Items[i];
    if (item.selected && item.precon < 0) {
      incompatibleSelectedItems[item.partno] = item;
    }
  }
  if (Object.keys(incompatibleSelectedItems).length > 0) {
    for (const locPart in incompatibleSelectedItems) {
      const locPartDetails = incompatibleSelectedItems[locPart];
      const basePart = locPart.split("#")[0];
      for (let i = updatedModel.Chaids[chIdx].firstItem; i <= updatedModel.Chaids[chIdx].lastItem; i++) {
        const newItm = updatedModel.Items[i];
        if (newItm.partno.includes("#")) {
          const [newItmbasePart, newItmlocOpt] = newItm.partno.split("#");
          if (basePart === newItmbasePart) {
            const isItemValid = () => {
              return newItmlocOpt !== oldLocOpt && !newItm.selected && newItm.precon >= 0 && newItm.priceStatus === VALID && newItm.plcStatus === VALID;
            };
            if (isItemValid()) {
              initializeNeuralNetVars();
              updatedModel = updateModel(
                updatedModel.Nodes[locPartDetails.inode].type, updatedModel.Nodes[locPartDetails.inode].id, DO_DESELECT, updatedModel
              );
              locPartDetails.visible = false;
              locPartDetails.isBandedItem = false
              bandedFalseItemsArr = [
                ...bandedFalseItemsArr,
                locPartDetails.inode
              ]
              initializeNeuralNetVars();
              updatedModel = updateModel(
                updatedModel.Nodes[newItm.inode].type, updatedModel.Nodes[newItm.inode].id, DO_SELECT, updatedModel
              );
              newItm.visible = true;
              newItm.isBandedItem = newItm.visible;
              break;
            }
          }
        }
      }
    }
  }
  return { updatedModel, bandedFalseItemsArr };
}

export const setIsBandedItemFlagForGrayoutItems = (
  choice,
  oldOption,
  newOption,
  model,
  bandedFalseItems
) => {
  let updatedModel = model;
  for (const vSysChaid of sysLocChaidsCOMPUTE_locChaidsPRINT) {
    if (choice.chaId === vSysChaid) {
      for (let i = choice.firstItem; i <= choice.lastItem; i++) {
        const item = updatedModel.Items[i];
        if (
          item.partno.includes("#") &&
          item.plcStatus === VALID &&
          item.priceStatus === VALID
        ) {
          const itemLocOpt = item.partno.split("#")[1];
          if (
            oldOption !== itemLocOpt &&
            !bandedFalseItems.includes(i) &&
            !item.selected
          ) {
            item.isBandedItem = item.precon >= 0;
          }
        }
      }
      break;
    }
  }
  return updatedModel;
};

const productSelectorColumns = [
  {
    id: "configPunchInURL",
    label: "",
    minWidth: 70
  },
  {
    id: "favorite",
    label: "common:productSelector.label.favorite",
    minWidth: 5,
    displayAs: "image"
  },
  {
    id: "configID",
    label: "common:productSelector.label.configID",
    minWidth: 70,
    displayAs: "chip"
  },
  {
    id: "configName",
    label: "common:productSelector.label.configName",
    minWidth: 100
  },
  {
    id: "description",
    label: "common:productSelector.label.description",
    minWidth: 100
  },
  {
    id: "createdBy",
    label: "common:productSelector.label.createdBy",
    minWidth: 100
  },
  {
    id: "email",
    label: "common:productSelector.label.email",
    minWidth: 90
  },
  {
    id: "companyName",
    label: "common:productSelector.label.companyName",
    minWidth: 80
  },
  {
    id: "accessType",
    label: "common:productSelector.label.accessType",
    minWidth: 90
  },
  {
    id: "createdAt",
    label: "common:productSelector.label.dateCreated",
    minWidth: 80
  }
];

export default productSelectorColumns;

import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// const useStyles = makeStyles(theme => ({
//   appBar: {
//     position: "relative",
//     backgroundColor: "#fff",
//     color: "rgba(0, 0, 0, 0.87)"
//   },
//   title: {
//     marginLeft: theme.spacing(2),
//     flex: 1
//   }
// }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  container: {
    scrollBehavior: "smooth",
    backgroundColor: "#fafafa"
  }
});

const FullscreenModal = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { children, isOpen, handleModalClose, hasToolbar = true } = props;

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const handleClose = () => {
    // setOpen(false);
    dispatch({
      type: "SET_WARNING",
      payload: {}
    });
    handleModalClose();
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{ paperFullScreen: classes.container }}
      >
        {children}
      </Dialog>
    </>
  );
};

export default FullscreenModal;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { customerSpecificChaIds } from "../../services/bomAndCarePackLib/utils";
import ChaidSelectionType from "../ChaidSelectionType";
import ErrorPart from "../ConflictsError";
import { fetchUiSpecVerticalView } from "../../services/common/utils";
const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: "400"
  },
  choiceLevelMarginTop: {
    marginTop: "16px"
  },
  accordion: {
    width: "100%",
    fontSize: "0.75rem",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important"
  },
  accordionMargin: { marginBottom: "16px" },
  accordionDetails: {
    display: "flex",
    flexDirection: "column"
  },
  accordionWrapperStyle: {
    width: "100%",
    borderRadius: "4px !important",
    boxShadow: "0px 5px 10px rgb(0 0 0 / 5%)"
  }
}));

export const OtherCarePacks = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { proCategory, modelData, handleItemChange, handleQuantityChange } =
    props;
    const defaultConfigStepSlected = useSelector(
      state => state.configFilterData.defaultConfigStepSlected
    );
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected)
  const fetchCarePackChaid = chaid => {
    return chaid.required ||
      chaid.selcon ||
      props.modelData.Items.some(
        part =>
          (["ACSS"].includes(chaid.chaId) ? true : part.partno !== "") &&
          part.visible === true &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      ) ? (
      <ChaidSelectionType
        key={chaid.chaId}
        chaid={chaid}
        modelData={modelData}
        handleItemChange={handleItemChange}
        handleQuantityChange={handleQuantityChange}
      />
    ) : null;
  };

  const cbnCpCarePack = [];
  const adpCarePacks = [];
  const supportExtension = [];
  const softwareSolutions = [];
  const customerSpecificServices = [];

  props.filteredChoices.forEach(chaid => {
    const showCarepack =
      chaid &&
      !chaid.chaId.includes("ELECTCPQ") &&
      !chaid.chaId.startsWith("CAPQ_") &&
      !props.isChaidHideBasedOnRegion(chaid.chaId);
    if (showCarepack && customerSpecificChaIds.includes(chaid.chaId)) {
      fetchCarePackChaid(chaid) &&
        customerSpecificServices.push(fetchCarePackChaid(chaid));
      window[chaid.chaId] = true;
    }
    if (
      showCarepack &&
      !chaid.chaId.includes("SUPPEXT_CP") &&
      !chaid.chaId.includes("SEE") &&
      !chaid.chaId.includes("ADP") &&
      !chaid.chaId.includes("CBN_CP") &&
      !customerSpecificChaIds.includes(chaid.chaId)
    ) {
      fetchCarePackChaid(chaid) &&
        softwareSolutions.push(fetchCarePackChaid(chaid));
      window[chaid.chaId] = true;
    }

    if (showCarepack && chaid.chaId.includes("CBN_CP")) {
      fetchCarePackChaid(chaid) &&
        cbnCpCarePack.push(fetchCarePackChaid(chaid));
      window[chaid.chaId] = true;
    }

    if (showCarepack && chaid.chaId.includes("ADP")) {
      fetchCarePackChaid(chaid) && adpCarePacks.push(fetchCarePackChaid(chaid));
      window[chaid.chaId] = true;
    }

    if (
      showCarepack &&
      (chaid.chaId.includes("SUPPEXT_CP") || chaid.chaId.includes("SEE"))
    ) {
      fetchCarePackChaid(chaid) &&
        supportExtension.push(fetchCarePackChaid(chaid));
      window[chaid.chaId] = true;
    }
  });

  const carePacks = [customerSpecificServices.length, softwareSolutions.length];
  proCategory === "compute" &&
    carePacks.push(supportExtension.length, adpCarePacks.length);
  proCategory === "compute" && carePacks.splice(0, 0, cbnCpCarePack.length);
  return carePacks?.reverse()?.map((carePack, index) =>
    carePack > 0 ? (
      index === 4 ? (
        <>{cbnCpCarePack}</>
      ) : (
        <div
          id={
            index === 0
              ? "chaid-" + "ACPS99"
              : index === 1
              ? "chaid-" + "SE99"
              : index === 2
              ? "chaid-" + "SSS99"
              : "chaid-" + "CSS99"
          }
          key={index}
          className={classes.accordionWrapperStyle}
        >
          {/* Handling error here */}
          {props.filteredChoices.map((chaid, ind) => {
            const carePacksError =
              chaid &&
              !chaid.chaId.includes("ELECTCPQ") &&
              !chaid.chaId.startsWith("CAPQ_") &&
              !props.isChaidHideBasedOnRegion(chaid.chaId) &&
              !chaid.chaId.includes("CBN_CP");
            if (
              (isVerticalView ==="N" &&
                carePacksError &&
                proCategory !== "print" &&
                (!customerSpecificChaIds.includes(chaid.chaId) ||
                  (customerSpecificChaIds.includes(chaid.chaId) &&
                    index === 3))) ||
              chaid.chaId.includes("ADP") ||
              chaid.chaId.includes("SUPPEXT_CP") ||
              chaid.chaId.includes("SEE")
            ) {
              return (
                <ErrorPart
                  chaid={chaid}
                  model={props.modelData}
                  setMargin={true}
                  key={ind}
                />
              );
            }
          })}
          {/* Handling compute here */}
          {proCategory === "compute" ? (
            <>
              {isVerticalView ==="N" ? (
                <Accordion
                  expanded={
                    index === 0
                      ? props.expandedAdp
                      : index === 1
                      ? props.expandedSE
                      : index === 2
                      ? Boolean(props.expanded)
                      : Boolean(props.expandedCSS)
                  }
                  onChange={
                    index === 0
                      ? props.handleChange.bind(this, adpCarePacks)
                      : index === 1
                      ? props.handleChange.bind(this, supportExtension)
                      : index === 2
                      ? props.handleChange.bind(this, softwareSolutions)
                      : props.handleChange.bind(this, customerSpecificServices)
                  }
                  key={index}
                  className={
                    cbnCpCarePack.length
                      ? `${classes.accordion} ${classes.accordionMargin}`
                      : `${classes.accordion}`
                  }
                >
                  <AccordionSummary
                    className={
                      props.showWarning && props.showWarning.warning
                        ? ""
                        : classes.choiceLevelMarginTop
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="itemPanelbh-content"
                    id="itemPanelbh-header"
                  >
                    <div>
                      <Typography
                        className={classes.heading}
                        component={"span"}
                        variant={"body2"}
                      >
                        {index === 0
                          ? t("common:stepperTabs.carePacksSrc.adpCarePackSrc")
                          : index === 1
                          ? t("common:stepperTabs.carePacksSrc.superExtension")
                          : index === 2
                          ? t(
                              "common:stepperTabs.carePacksSrc.softwareSolutionSrc"
                            )
                          : t(
                              "common:stepperTabs.carePacksSrc.customerSpecificSrc"
                            )}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {index === 0
                      ? adpCarePacks
                      : index === 1
                      ? supportExtension
                      : index === 2
                      ? softwareSolutions
                      : customerSpecificServices}
                  </AccordionDetails>
                </Accordion>
              ) : index === 0 ? (
                adpCarePacks
              ) : index === 1 ? (
                supportExtension
              ) : (
                softwareSolutions
              )}
            </>
          ) : (
            // Handling print here
            <>{softwareSolutions}</>
          )}
        </div>
      )
    ) : (
      <></>
    )
  );
};

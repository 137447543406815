import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionSummary, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ExternalProps from "../contexts/externalPropsContext";
import {
  errorCodeFilter,
  icConflictsFilter,
  shouldShowOnUI,
  displayCurrencyLite
} from "../lib/common/util";
import { handleDuplicateItemConflicts, shouldShowLeastPriorityConflicts } from "../services/common/utils";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important",
    fontSize: theme.typography.pxToRem(12),
    "& .Mui-expanded": {
      marginTop: 0
    }
  },
  heading: {
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: 400,
    padding: "8px 0px 0px 0px",
    "&.MuiTypography-root": {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  secondaryHeading: {
    "&.MuiTypography-root": {
      fontSize: theme.typography.pxToRem(16),
      color: "#000000",
      fontWeight: 300,
      padding: "8px 0px 0px 18px"
    },
    overflow: "hidden"
  },
  currencyStyle: {
    fontWeight: "bold",
    fontSize: "12px"
  },
  captionStyle: {
    fontSize: "12px",
    fontWeight: "normal",
    paddingTop: "3px"
  },
  expanded: {
    "&$expanded": {
      margin: "1px 0"
    }
  },
  choiceLevelErrmsgCls: {
    backgroundColor: "red",
    color: "white",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "grid !important"
  },
  choiceLevelMarginTop: {
    marginTop: "0px !important"
  },
  accordionDetails: {
    width: "100%",
    boxSizing: "border-box",
    marginTop: "-15px"
  },
  warningStyle: {
    backgroundColor: "#ffbf00",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  warningErrorStyle: {
    backgroundColor: "#FD0032",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    padding: "8px 16px !important"
  },
  choiceBannerStyles: {
    backgroundColor: "#0096D6",
    color: "#FFFFFF",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    padding: "8px 16px"
  },
  banner: {
    backgroundColor: "#0096D6",
    color: "#FFFFFF",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "grid !important"
  }
}));

function ChaidSingleConflicts(props) {
  const { expanded, chaid, errorInParent } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const expandedMargin = { margin: "-0px -41px 0px -16px" };
  const collapsedMargin = { margin: "-12px -41px 13px -16px" };
  const expandedMarginBanner = { margin: "0px -41px 32px -16px" };
  const collapsedMarginBanner = { margin: "-13px -41px 17px -16px" };
  let labelDisplay, itemSelected, itemPrice;
  const { currencyCode } = React.useContext(ExternalProps);
  const showWarning = useSelector(state => state.configFilterData.showWarning);
  const errorData = useSelector(state => state.configFilterData.errorData);
  const model = useSelector(state => state.configFilterData.modelData);
  const isConfigOverDate = useSelector(
    state => state.configFilterData.isConfigOverDate
  );
  const loadingSelection = useSelector(
    state => state.configFilterData.loadingSelection
  );
  const currentConfigID = useSelector(
    state => state.productSelector.currentConfigID
  );
  const showGADate = model.showGADate;
  const singleChoice = chaid?.items?.filter(
    part =>
      part.visible &&
      part.inode >= chaid.firstItem &&
      part.inode <= chaid.lastItem &&
      shouldShowOnUI(
        { currentConfigID, showGADate, isConfigOverDate },
        part.gaDate,
        part.selected
      )
  );
  let item;
  for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
    if (model?.Items[i]?.selected) {
      item = model?.Items[i];
      if (i === item.inode) {
        labelDisplay = item.partno
          ? `${item.partno} - ${item.partdes} `
          : `${item.partdes} `;
        itemPrice = `${item.netPrice}`;
      }
      if (item.selected) {
        itemSelected = true;
      }
    }
  }
  let selectedNetPrice = item?.netPrice || 0;
  const updatedItemConflictsList = handleDuplicateItemConflicts(model);
  const singleChoiceText = singleChoice?.length;
  //const choiceLevelErrmsg = (chaidReq && itemSelected === undefined) ? true : false;
  let objCause;
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.some(res => {
      if (res.chaId === chaid.chaId) {
        objCause = res.cause[0];
        return true;
      } else return false;
    });
  let effects = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.map(itemConflict => {
      if (itemConflict.effect.chaId === chaid.chaId) {
        !effects.includes(itemConflict.effect.chaDes) &&
          effects.push(itemConflict.effect.chaDes);
        return true;
      } else return false;
    });
  let itemLevelError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.forEach(res =>
      errorData && errorData.name === chaid.chaId
        ? itemLevelError.push(" " + res.cause.chaDes)
        : null
    );
  const localizationChDes = {
    SYS_LOC_OPTION: "System Localization Options",
    OS_LOC_OPTION: "OS Localization Options",
    KYBD_LOC_OPTION: "Keyboard Localization Options"
  };

  const isLoadingSelection =
    model &&
    model.Items &&
    model.Items.some(item => item.inode === loadingSelection);

  const choices =
    model &&
    model.Items &&
    model.Items.filter(
      part =>
        part.visible &&
        part.inode >= chaid.firstItem &&
        part.inode <= chaid.lastItem
    );

  let netPriceArray = [];
  choices &&
    choices.forEach(element => {
      netPriceArray.push(element.netPrice);
    });

  let isMinNetPrice = Math.min(...netPriceArray) === Number(selectedNetPrice);

  // const ld = labelDisplay ? true : false;
  const ldDisplay = labelDisplay
    ? labelDisplay
    : t("common:productSelector.none");

  return (
    <div>
      <AccordionSummary
        className={
          showWarning && showWarning.warning ? "" : classes.choiceLevelMarginTop
        }
        expandIcon={<ExpandMoreIcon />}
        aria-controls="itemPanelbh-content"
        id="itemPanelbh-header"
      >
        <Box flexDirection="column" flexGrow={1}>
          {/* {`${chaid.required} `}  {`${itemSelected} `} */}
          {!errorInParent && (
            <>
              {/* error part starts */}
              {(!isLoadingSelection ||
                (errorData.name !== chaid.chaId && isLoadingSelection)) &&
                model &&
                model.conflicts &&
                model.conflicts[0].icConflicts &&
                model.conflicts[0].icConflicts.map(res =>
                  res.code === "ERR04" && res.chaId === chaid.chaId ? (
                    <div
                      className={classes.choiceLevelErrmsgCls}
                      style={expanded ? expandedMargin : collapsedMargin}
                      key={res.chaId}
                    >
                      <Box className={classes.warningErrorStyle} padding={1}>
                        <Typography align="center" variant={"subtitle2"}>
                          {`${t(
                            "common:configurator:plcConflict1"
                          )} ${chaid.chaDes.toUpperCase()} ${t(
                            "common:configurator:currentConflict5"
                          )} `}
                        </Typography>
                      </Box>
                    </div>
                  ) : null
                )}
              {/* RED banner on effected Choice */}
              {(!isLoadingSelection ||
                (errorData.name !== chaid.chaId && isLoadingSelection)) &&
              objCause ? (
                <div
                  className={classes.choiceLevelErrmsgCls}
                  style={expanded ? expandedMargin : collapsedMargin}
                >
                  <Box className={classes.warningErrorStyle} padding={1}>
                    <Typography align="center" variant={"subtitle2"}>
                      {`${t("common:configurator:currentConflict1")} ${
                        chaid.chaDes
                      } ${t("common:configurator:currentConflict3")} ${
                        objCause.chaDes
                      } - ${t("common:configurator:currentConflict4")} `}
                    </Typography>
                  </Box>
                </div>
              ) : null}
              {(!isLoadingSelection ||
                (errorData.name !== chaid.chaId && isLoadingSelection)) &&
              !objCause &&
              effects.length === 0 &&
              model &&
              model.conflicts &&
              model.conflicts[0].icConflicts &&
              (errorCodeFilter(model?.conflicts[0]?.icConflicts, "ERR03") ||
                errorCodeFilter(model?.conflicts[0]?.icConflicts, "ERR10")) &&
              icConflictsFilter(model?.conflicts[0]?.icConflicts, chaid) ? (
                <div
                  className={classes.choiceLevelErrmsgCls}
                  style={expanded ? expandedMargin : collapsedMargin}
                >
                  <Box className={classes.warningErrorStyle} padding={1}>
                    <Typography align="center" variant={"subtitle2"}>
                      {`${t("common:configurator:currentConflict1")} ${
                        chaid.chaDes
                      } ${t("common:configurator:currentConflict2")} `}
                    </Typography>
                  </Box>
                </div>
              ) : null}
              {/* Handling PLC conflict here */}
              {(!isLoadingSelection ||
                (errorData.name !== chaid.chaId && isLoadingSelection)) &&
                model &&
                model.conflicts &&
                model.conflicts[0].plcConflicts &&
                model.conflicts[0].plcConflicts
                  .map(res =>
                    res.code === "ERR06" &&
                    (res.chaId === chaid.chaId ||
                      chaid?.items?.some(
                        choice =>
                          res.partNo === choice.partno &&
                          res.itemiNode === choice.inode
                      )) ? (
                      <div
                        className={classes.choiceLevelErrmsgCls}
                        style={expanded ? expandedMargin : collapsedMargin}
                        key={res.chaId}
                      >
                        <Box className={classes.warningErrorStyle} padding={1}>
                          <Typography align="center" variant={"subtitle2"}>
                            {`${t("common:configurator:plcConflict1")} ${
                              chaid.chaId.includes("CAPQ")
                                ? t("common:configurator:plcConflictManda")
                                : ""
                            } ${chaid.chaDes.toUpperCase()} ${t(
                              "common:configurator:plcConflict2"
                            )} `}
                          </Typography>
                        </Box>
                      </div>
                    ) : null
                  )
                  .find(err => err)}
              {/* error part ends */}

              {/* Handling GA-Date conflict here */}
              {(!isLoadingSelection ||
                (errorData.name !== chaid.chaId && isLoadingSelection)) &&
                model &&
                model.conflicts &&
                model.conflicts[0].gaDateConflicts &&
                model.conflicts[0].gaDateConflicts.map(res =>
                  res.code === "ERR10" && res.chaId === chaid.chaId ? (
                    <div
                      className={classes.choiceLevelErrmsgCls}
                      style={expanded ? expandedMargin : collapsedMargin}
                      key={res.chaId}
                    >
                      <Box className={classes.warningErrorStyle} padding={1}>
                        <Typography align="center" variant={"subtitle2"}>
                          {`${t(
                            "common:configurator:gaDateConflict1"
                          )} ${chaid.chaId.toUpperCase()} ${t(
                            "common:configurator:gaDateConflict2"
                          )} `}
                        </Typography>
                      </Box>
                    </div>
                  ) : null
                )}
              {/* error part ends */}

              {/* Handling itemConflicts conflict here */}
              {(!isLoadingSelection ||
                (errorData.name !== chaid.chaId && isLoadingSelection)) &&
                updatedItemConflictsList &&
                updatedItemConflictsList.conflicts &&
                updatedItemConflictsList.conflicts[0]?.itemConflictsList &&
                updatedItemConflictsList.conflicts[0]?.itemConflictsList?.map(
                  res =>
                    res.effect.chaId === chaid.chaId ? (
                      <div
                        className={classes.choiceLevelErrmsgCls}
                        style={expanded ? expandedMargin : collapsedMargin}
                        key={res.chaId}
                      >
                        <Box className={classes.warningErrorStyle} padding={1}>
                          <Typography align="center" variant={"subtitle2"}>
                            {`${t("common:configurator:currentConflict1")} ${
                              chaid.chaDes
                            }
                         ${t("common:configurator:currentConflict3")}  ${
                              res.cause.chaDes !== ""
                                ? res.cause.chaDes
                                : localizationChDes[res.cause.chaId] || ""
                            } - ${t("common:configurator:currentConflict4")} `}
                          </Typography>
                        </Box>
                      </div>
                    ) : null
                )}
              {/* error part ends */}
              {/* {Handeling counterConflicts} */}
              {(!isLoadingSelection ||
                (errorData.name !== chaid.chaId && isLoadingSelection)) &&
                model &&
                model.conflicts &&
                model.conflicts[0].counterConflicts &&
                model.conflicts[0].counterConflicts.map(res =>
                  res.code === "ERR05" && res.chaId === chaid.chaId ? (
                    <div
                      className={classes.choiceLevelErrmsgCls}
                      style={expanded ? expandedMargin : collapsedMargin}
                      key={res.chaId}
                    >
                      <Box className={classes.warningErrorStyle} padding={1}>
                        <Typography align="center" variant={"subtitle2"}>
                          {res?.counterDesc.includes("PCIE")
                            ? `${res.chaDes.toUpperCase()} ${t(
                                "common:configurator:pcieConflict"
                              )}${t("common:configurator:pcieConflict3")} ${
                                res?.partNos
                              }.`
                            : `${res.chaDes.toUpperCase()} ${t(
                                "common:configurator:pcieConflict2"
                              )}${t("common:configurator:pcieConflict3")} ${
                                res?.partNos
                              }.`}
                        </Typography>
                      </Box>
                    </div>
                  ) : null
                )}

              {/* {Handeling hiddenChoiceConflicts error here } */}
              {(!isLoadingSelection ||
                (errorData.name !== chaid.chaId && isLoadingSelection)) &&
                shouldShowLeastPriorityConflicts(model) &&
                model.conflicts[0]?.hiddenChoiceConflicts &&
                model.conflicts[0]?.hiddenChoiceConflicts?.map(res =>
                  res.cause.chaId === chaid.chaId ? (
                    <div
                      className={classes.choiceLevelErrmsgCls}
                      style={expanded ? expandedMargin : collapsedMargin}
                      key={res.chaId}
                    >
                      <Box className={classes.warningErrorStyle} padding={1}>
                        <Typography align="center" variant={"subtitle2"}>
                          {`${t("common:configurator:currentConflict1")} ${
                            chaid.chaDes
                          }
                         ${t("common:configurator:hiddenChoiceConflict")} `}
                        </Typography>
                      </Box>
                    </div>
                  ) : null
                )}
              {/* error part ends */}
              {/* {Handeling choiceConflicts error here } */}
              {(!isLoadingSelection ||
                (errorData.name !== chaid.chaId && isLoadingSelection)) &&
                shouldShowLeastPriorityConflicts(model) &&
                model.conflicts[0]?.choiceConflicts &&
                model.conflicts[0]?.choiceConflicts?.map(res =>
                  res.effectChoice.chaId === chaid.chaId ? (
                    <div
                      className={classes.choiceLevelErrmsgCls}
                      style={expanded ? expandedMargin : collapsedMargin}
                      key={res.chaId}
                    >
                      <Box className={classes.warningErrorStyle} padding={1}>
                        <Typography align="center" variant={"subtitle2"}>
                          {`${t("common:configurator:currentConflict1")} ${
                            chaid.chaDes
                          }
                       ${t("common:configurator:currentConflict3")}  ${
                            res.cause.chaDes
                          } - ${t("common:configurator:currentConflict4")} `}
                        </Typography>
                      </Box>
                    </div>
                  ) : null
                )}
              {/* error part ends */}
            </>
          )}
          {/* handling choice level messages here */}
          {model?.icMsgs?.choiceMsgs?.map(choiceMsg =>
            choiceMsg?.choiceId === chaid?.chaId &&
            choiceMsg.msgs.length > 0 ? (
              <div
                className={classes.banner}
                style={expanded ? expandedMarginBanner : collapsedMarginBanner}
              >
                <Box className={classes.choiceBannerStyles}>
                  {choiceMsg.msgs?.map(choiceMsg => (
                    <Typography align="center" variant="subtitle2">
                      {choiceMsg}
                    </Typography>
                  ))}
                </Box>
              </div>
            ) : null
          )}
          {/* choice level ends */}
          <Box display="flex">
            <Typography
              className={classes.heading}
              component={"span"}
              variant={"body2"}
            >
              {chaid.chaDes}
            </Typography>
            <Typography
              className={classes.secondaryHeading}
              component={"span"}
              variant={"body2"}
            >
              {ldDisplay}
            </Typography>
          </Box>
          {itemSelected && itemPrice !== "" ? (
            singleChoiceText > 1 && chaid.items ? (
              <Box
                display="flex"
                alignItems="center"
                className={classes.captionStyle}
              >
                {t("common:configurator:priceOfPart")} &nbsp;
                <Typography
                  color="primary"
                  className={classes.currencyStyle}
                  component="span"
                  variant="caption"
                >
                  {currencyCode}&nbsp;
                  {displayCurrencyLite({
                    value: Math.abs(isNaN(itemPrice) ? 0 : itemPrice) || "0.00",
                    currency: currencyCode,
                    locale: "en-US"
                  })}
                </Typography>
                . {isMinNetPrice
                  ? t("common:configurator.text2")
                  : t("common:configurator.text1")}
              </Box>
            ) : (
              chaid.items &&
              chaid.items.length !== 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.captionStyle}
                >
                  {t("common:configurator:priceOfPart")} &nbsp;
                  <Typography
                    color="primary"
                    component="span"
                    variant="caption"
                    className={classes.currencyStyle}
                  >
                    {currencyCode}&nbsp;
                    {displayCurrencyLite({
                      value:
                        Math.abs(isNaN(itemPrice) ? 0 : itemPrice) || "0.00",
                      currency: currencyCode,
                      locale: "en-US"
                    })}
                  </Typography>
                  . {t("common:configurator:onlyComponent")}.
                </Box>
              )
            )
          ) : (
            expanded === false && (
              <Typography className={classes.captionStyle}>
                {`${t("common:configurator:clickToPick")} ${chaid.chaDes}`}
              </Typography>
            )
          )}
        </Box>
      </AccordionSummary>
    </div>
  );
}

export default ChaidSingleConflicts;

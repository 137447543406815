import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ExportRskuBomToExcel from "../ExportRskuBomToExcel";
import ProductList from "../ProductList";
import ProductSkeleton from "../ProductSkeleton";

const RequestRskuContent = ({
  loading,
  isGraphQLErrors,
  isError,
  handleClickRsku,
  isStandaloneAccess,
  addCTOToQuote
}) => {
  const { t } = useTranslation();
  const { requestRskuProduct, showRskuAddToQuote, rskuImage, popups } =
    useSelector(state => state.rskuReducer);

  const { requestSkuDialog } = popups;

  return (
    <>
      <DialogContent>
        {!loading ? (
          !isGraphQLErrors ? (
            <>
              <Typography variant="h6" style={{ fontSize: "medium" }}>
                {t("common:rsku.requestSKU.subTitle")}
              </Typography>
              <Box>
                {isError ? (
                  <>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      {t("common:rsku.requestSKU.queryFailLine1")}
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      {t("common:rsku.requestSKU.queryFailLine2")}
                    </Typography>
                  </>
                ) : (
                  <>
                    {requestRskuProduct.map(product => (
                      <ProductList
                        key={product.sku}
                        {...product}
                        image={rskuImage}
                      />
                    ))}
                  </>
                )}
              </Box>
            </>
          ) : (
            <>
              <Typography color="primary" variant="h6">
                {t("common:rsku.requestSKU.graphQLfail")}
              </Typography>
            </>
          )
        ) : (
          <ProductSkeleton />
        )}
      </DialogContent>
      <DialogActions>
        {isGraphQLErrors ? (
          <Button
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleClickRsku}
          >
            {t("common:rsku.button.tryAgain")}
          </Button>
        ) : (
          <>
            {requestSkuDialog?.open && isStandaloneAccess && (
              <ExportRskuBomToExcel loading={loading} isError={isError} />
            )}
            {!isStandaloneAccess && (
              <Button
                variant="contained"
                color="primary"
                disabled={isStandaloneAccess || !showRskuAddToQuote}
                onClick={addCTOToQuote}
              >
                {t("common:rsku.button.addToQuote")}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </>
  );
};

export default RequestRskuContent;

import { useMutation } from "@apollo/client";
import React from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { LOG_RSKU_ACTION } from "../../../GraphQL";
import { togglePopupActions } from "../../../actions/togglePopupActions";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import displayNotistack from "../../../lib/common/SnackBarUtils";
import {
  getProductAttribute,
  getStorageValue,
  validateRsku
} from "../../../lib/common/util";

const WithAddToQuote = WrappedComponent => {
  const UpdatedComponent = props => {
    const { transactionID, quoteNumber, userEmail } =
      React.useContext(ExternalProps);
    const dispatch = useDispatch();
    const {
      checkQuantity,
      proCategory,
      closeMatchBomDetails,
      requestRskuProduct,
      products,
      rSKUBom,
      popups,
      skuServiceName,
      productCountryCode,
      requestSkuConfig,
      exactCloseMatchConfig,
      bomThreeCompatibleCarePacksInfo
    } = useSelector(state => state.rskuReducer);
    const isUploadConfig = useSelector(
      state => state.uploadConfig.isUploadConfig
    );
    const { configuration } = useSelector(state => state.configFilterData);
    const { requestSkuDialog } = popups;
    const isDebug = window.localStorage.getItem("isDebug") === "true";
    const configID = configuration?.configHeader?.configId?.toString() || "";

    const clientOrigin = getStorageValue("clientOrigin");

    const [logRskuAction] = useMutation(LOG_RSKU_ACTION, {
      fetchPolicy: "no-cache"
    });
    // Remove the code once hardening sprint tested succesfully
    /*  
    const addBTOToQuote = () => {
      let skuArray = [];
      checkQuantity?.forEach(rsku => {
        if (
          rsku.skuType === "exactMatch" ||
          rsku.skuType === "similarMatch" ||
          rsku.skuType === "requestRsku"
        ) {
          skuArray = [
            ...skuArray,
            {
              ...rsku,
              sku: rsku.sku.split("#")[0],
              listPrice: parseFloat(rsku.listPrice).toFixed(2),
              isRsku: true
            }
          ];
          skuArray = [...skuArray, { ...rsku, listPrice: 0.0, isRsku: true }];
        } else {
          skuArray = [...skuArray, { ...rsku, isRsku: false }];
        }
      });
      isDebug && console.log("skuArray", skuArray);
      if (document.querySelector("#product-selector")) {
        document
          .querySelector("#product-selector")
          .dispatchEvent(new CustomEvent("saveRSKU", { detail: skuArray }));
      }
      //FIXME : redirection to LIG - once successfully data svaed
      dispatch(togglePopupActions({ name: "rskuDialog", open: false }));
      dispatch({ type: "SET_CLEAR_ALL" });
      const successMsg = `Config ID ${configID} has been saved, under your profile`;
      displayNotistack.success(successMsg, "success", 6000);
   
    }; */

    const addCTOToQuote = () => {
      const docType = configuration?.configHeader?.docType;
      const refConfigId =
        docType === "SP" || docType === "CC"
          ? configuration?.configHeader?.configId?.toString() || ""
          : configuration?.configHeader?.refConfigId?.toString() || "";
      let btoProduct = [];
      let ctoProduct = [];
      let ctoProductComponents = [];
      let rskuAnalytics = {
        configId: requestSkuDialog?.open
          ? parseInt(requestSkuConfig) || 0
          : parseInt(exactCloseMatchConfig) || 0,
        quantity: [],
        sku_id: [],
        rsku_status: [],
        appName: "RSKU",
        country: productCountryCode,
        createdBy: userEmail,
        serviceStatus: "Success",
        serviceName: requestSkuDialog?.open
          ? "AddtoQuote_newRSKU"
          : skuServiceName === "exactMatch"
          ? "AddToQuote_ExactMatch"
          : "AddToQuote_CloseMatch",
        quoteNumber: quoteNumber || "",
        transactionID: transactionID || "",
        bomUsage:
          requestSkuDialog?.open && bomThreeCompatibleCarePacksInfo?.length
            ? "3"
            : ""
      };
      let isCloseMatch;
      checkQuantity?.forEach(el => {
        if (
          (el.skuType === "exactMatch" ||
            el.skuType === "similarMatch" ||
            el.skuType === "requestRsku") &&
          el.isRSKUFinalized === false
        ) {
          ctoProduct.push(el);
        } else {
          btoProduct.push(el);
        }
      });

      const ctoProductIds = ctoProduct.reduce((acc, el) => {
        acc.push(el.sku);
        return acc;
      }, []);
      const ctoProductNames = ctoProduct.reduce((acc, el) => {
        acc.push(el.name);
        return acc;
      }, []);
      const ctoProductQuantities = ctoProduct.reduce((acc, el) => {
        acc.push(el.quantity);
        return acc;
      }, []);

      if (products?.length || requestRskuProduct?.length) {
        isCloseMatch = false;
        if (btoProduct?.length) {
          rSKUBom.filter(each => {
            const findDuplicate = btoProduct.find(
              ele => ele.sku === each.partNumber
            );
            if (!findDuplicate && each.sectionId === "hardware") {
              ctoProductComponents.push(getProductAttribute(each));
            }
          });
        } else {
          rSKUBom.map(each => {
            ctoProductComponents.push(getProductAttribute(each));
          });
        }
        if (bomThreeCompatibleCarePacksInfo?.length) {
          bomThreeCompatibleCarePacksInfo?.map(each => {
            ctoProductComponents.push(getProductAttribute(each));
          });
        }
      } else {
        isCloseMatch = true;
        ctoProductComponents = ctoProductIds?.length
          ? ctoProductIds.map(each =>
              closeMatchBomDetails[each].map(bom => {
                return getProductAttribute(bom);
              })
            )
          : [];
      }

      const data = ctoProductIds?.length
        ? {
            transactionID,
            eventTriggered: "ADD_TO_QUOTE",
            configID: ctoProductIds,
            configName: ctoProductNames,
            refConfigID: refConfigId,
            disableReconfigure: false,
            retainConfigID: true,
            proCategory,
            isRsku: true,
            quantity: ctoProductQuantities,
            components: isCloseMatch
              ? ctoProductComponents
              : [ctoProductComponents]
          }
        : {};
      const { bto, cto, skuAnalytics } = validateRsku(
        btoProduct,
        data,
        rskuAnalytics
      );
      logRskuAction({
        variables: {
          input: skuAnalytics
        }
      });

      isDebug && console.log("addCtoToQuote", cto, "addBtoToQuote", bto);
      document
        .querySelector(
          `${isUploadConfig ? "#upload-config" : "#product-selector"}`
        )
        .dispatchEvent(
          new CustomEvent("saveRSKU", {
            detail: {
              bto,
              cto,
              isRskuFinalized: ctoProductIds?.length ? false : true
            }
          })
        );
      batch(() => {
        dispatch(togglePopupActions({ name: "rskuDialog", open: false }));
        dispatch({ type: "SET_IS_ADD_TO_QUOTE", payload: true });
      });

      if (clientOrigin === "BMI") {
        dispatch({ type: "SET_SHOW_BACKDROP", payload: true });
      }
      dispatch({ type: "SET_CLEAR_ALL" });
      const successMsg = `Config ID ${configID} has been saved, under your profile`;
      displayNotistack.success(successMsg, "success", 6000);
    };

    return (
      <WrappedComponent
        // addBTOToQuote={addBTOToQuote}
        addCTOToQuote={addCTOToQuote}
      />
    );
  };
  return UpdatedComponent;
};

export default WithAddToQuote;

import { Box, IconButton, TextField, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import "moment/min/locales.min";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../lib/common/CustomTable";

export default function AddNotesAttachmentsList(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const notesAttachments = useSelector(
    state => state.configFilterData.notesAttachments
  );
  const attachments = props?.attachments
    ? props?.attachments
    : notesAttachments;

  const handleChange = (record, event) => {
    const updatedNote = attachments.map(file => {
      return file.fileName === record.fileName &&
        file.uploadedTime === record.uploadedTime
        ? { ...file, notes: event.target.value }
        : file;
    });
    if (!props?.attachments) {
      dispatch({
        type: "SET_NOTES_ATTACHMENTS",
        payload: updatedNote
      });
    } else {
      props.setAttachments(updatedNote);
    }
  };
  const handleDelete = record => {
    const remainingAttachments = attachments.filter(
      file =>
        !(
          file.fileName === record.fileName &&
          file.uploadedTime === record.uploadedTime
        )
    );
    if (!props?.attachments) {
      dispatch({
        type: "SET_NOTES_ATTACHMENTS",
        payload: remainingAttachments
      });
    } else {
      props.setAttachments(remainingAttachments);
    }
  };

  let columns = [
    {
      id: "notes",
      label: <b>{t("common:opportunity.label.notes")}</b>,
      render: ({ record }) => {
        return (
          <TextField
            id="notes"
            multiline
            maxRows={4}
            size="small"
            variant="outlined"
            onChange={event => {
              handleChange(record, event);
            }}
            disabled={!record.newRecord}
            value={record.notes}
            InputProps={{
              inputProps: {
                maxLength: 500
              }
            }}
          />
        );
      }
    },
    {
      id: "fileName",
      label: <b>FileName</b>,
      render: ({ record }) => {
        return record.fileName ? (
          <a
            href={record.file}
            style={{ color: "black", textDecoration: "none" }}
            download={record.fileName}
          >
            <Typography sx={{ fontSize: "inherit" }}>
              {record.fileName}
              <IconButton color="primary" size="small">
                <SaveAltOutlinedIcon fontSize="small" />
              </IconButton>
            </Typography>
          </a>
        ) : (
          ""
        );
      }
    },
    {
      id: "createdAt",
      label: <b>{t("common:productSelector.label.createdAt")}</b>
    },
    {
      id: "userName",
      label: <b>{t("common:productSelector.label.createdBy")}</b>
    },
    {
      id: "action",
      label: <b>Action</b>,
      render: ({ record }) => {
        return record.newRecord ? (
          <Typography color="primary" sx={{ fontSize: "inherit" }}>
            <IconButton color="primary" size="small">
              <DeleteIcon onClick={() => handleDelete(record)} />
            </IconButton>
          </Typography>
        ) : (
          ""
        );
      }
    }
  ];

  columns = props?.requestAssist
    ? columns.filter(value => value.id !== "notes")
    : props?.open
    ? columns.filter(value => value.id !== "createdAt")
    : columns;

  return (
    <Fragment>
      <Box border="solid #DCDCDC 1px" borderRadius="10px" p={3} my={3}>
        <CustomTable
          columns={columns}
          data={attachments}
          hidePagination={true}
        />
      </Box>
    </Fragment>
  );
}

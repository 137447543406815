import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_CHOICE_EXPAND } from "../actions/Types";
import {
  findParentChoiceId,
  getCarePackLabel
} from "../services/bomAndCarePackLib/utils";
import {
  compare,
  comparePriority,
  getLocalizedMandaCPItem,
  fetchUiSpecVerticalView
} from "../services/common/utils";
import ChaidSelectionType from "./ChaidSelectionType";
import ErrorPart from "./ConflictsError";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: "400"
  },
  choiceLevelMarginTop: {
    marginTop: "16px"
  },
  accordian: {
    width: "100%",
    fontSize: "0.75rem",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important"
  },
  accordianDetails: {
    display: "flex",
    flexDirection: "column"
  }
}));

export const MandaCarePacks = props => {
  const {
    modelData,
    handleItemChange,
    handleQuantityChange,
    filteredChoices,
    isChaidHideBasedOnRegion,
    showWarning
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected);
  const searchText = useSelector(state => state.configFilterData.searchText);
  const dispatch = useDispatch();
  let mandaCarePacks = [];
  const _expanded = [];
  const _choiceMap = {};
  const expColButton = useSelector(
    state => state.configFilterData.expColButton
  );
  const isExpandAll = expColButton[defaultConfigStepSlected] || false;
  filteredChoices.forEach(chaid => {
    if (
      chaid &&
      chaid.chaId.startsWith("CAPQ_") &&
      !isChaidHideBasedOnRegion(chaid.chaId)
    ) {
      const item = getLocalizedMandaCPItem(modelData, chaid);
      chaid.priority = Number(item?.priority);
      mandaCarePacks.push(chaid);
      _expanded.push(false);
    }
  });

  const recommndedProducts = [];
  const nonRecommendedProducts = [];
  const carePacksServicesData = [];
  mandaCarePacks.forEach(item => {
    item.chaId.startsWith("CAPQ_P_") &&
      Number(item.priority) >= 1 &&
      Number(item.priority) <= 5 &&
      recommndedProducts.push(item);
    item.chaId.startsWith("CAPQ_P_") &&
      (Number(item.priority) > 5 || item.priority === "") &&
      nonRecommendedProducts.push(item);
    item.chaId.startsWith("CAPQ_") &&
      !item.chaId.startsWith("CAPQ_P_") &&
      carePacksServicesData.push(item);
  });
  const compareMandaAndPoly = (a, b) => {
    if (
      (findParentChoiceId(modelData, a.chaId) === "ASCM_64283" &&
        findParentChoiceId(modelData, b.chaId) === "ASCM_3242660") ||
      (findParentChoiceId(modelData, a.chaId) === "ASCM_POLYMON" &&
        findParentChoiceId(modelData, b.chaId) === "ASCM_POLYACC")
    )
      return -1;
    return 0;
  };
  recommndedProducts?.sort(comparePriority);
  nonRecommendedProducts?.sort(compare).sort(compareMandaAndPoly);
  carePacksServicesData?.sort(compare).sort(compareMandaAndPoly);
  mandaCarePacks = [
    ...carePacksServicesData,
    ...recommndedProducts,
    ...nonRecommendedProducts
  ];

  mandaCarePacks.forEach((mandaCarePacks, index) => {
    _choiceMap[mandaCarePacks.chaId] = index;
  });

  const [expanded, setExpanded] = useState(_expanded);

  useEffect(() => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (relevantTabData && isExpandAll) {
        const _ex = [];
        Object.keys(relevantTabData.choicesExpandState).forEach(ch => {
          if (ch.startsWith("CAPQ_") && !_ex.includes(ch)) {
            _ex.push(ch);
          }
        });
        // setExpanded(
        //   relevantTabData.expand ||
        //     Object.keys(relevantTabData.choicesExpandState).some(
        //       ch =>
        //         relevantTabData.choicesExpandState[ch] === true && ch.startsWith("CAPQ_")
        //     )
        // );
        _ex.forEach(ch => {
          _expanded[_choiceMap[ch]] = relevantTabData.choicesExpandState[ch];
        });
        if (mandaCarePacks.length === 0) {
          _ex.forEach((dch, index) => {
            delete relevantTabData.choicesExpandState[dch];
          });
        }
        setExpanded([..._expanded]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll, defaultConfigStepSlected]);

  useEffect(() => {
    if (searchText) {
      const _expanded = Array.isArray(expanded) ? [...expanded] : [expanded];
      // eslint-disable-next-line array-callback-return
      filteredChoices.map((choice, index) => {
        _expanded[index] = true;
        setExpanded(_expanded);
      });
    } else {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleChange = (index, e) => {
    e.preventDefault();
    const relevantExpandData = expandAll.find(
      expandObject => expandObject.tab === defaultConfigStepSlected
    );
    const _expanded = Array.isArray(expanded) ? [...expanded] : [expanded];
    if (relevantExpandData) {
      if (
        Object.keys(relevantExpandData.choicesExpandState).indexOf(
          mandaCarePacks[index].chaId
        ) !== -1
      ) {
        relevantExpandData.choicesExpandState[mandaCarePacks[index].chaId] =
          !_expanded[index];
      }

      const isShowAdditionalMandaCarePacks = mandaCarePacks[
        index
      ]?.items.filter(_ch => {
        return _ch.priority === "" || parseInt(_ch.priority) > 5;
      });
      if (!isShowAdditionalMandaCarePacks.length) {
        const recommendedCarePacksState = Object.keys(
          relevantExpandData.choicesExpandState
        ).filter(chaid => {
          return chaid === mandaCarePacks[index].chaId + "_additional";
        });
        delete relevantExpandData?.choicesExpandState[
          recommendedCarePacksState
        ];
      }

      const isShowRecommendedMandaCarePacks = mandaCarePacks[
        index
      ]?.items.filter(_ch => {
        return (
          _ch.priority !== "" &&
          parseInt(_ch.priority) >= 0 &&
          parseInt(_ch.priority) <= 5
        );
      });
      if (!isShowRecommendedMandaCarePacks.length) {
        const recommendedCarePacksState = Object.keys(
          relevantExpandData.choicesExpandState
        ).filter(chaid => {
          return chaid === mandaCarePacks[index].chaId + "_recommended";
        });
        delete relevantExpandData?.choicesExpandState[
          recommendedCarePacksState
        ];
      }
      // eslint-disable-next-line array-callback-return
      Object.keys(relevantExpandData.choicesExpandState).map((chaid, key) => {
        if (chaid.startsWith(mandaCarePacks[index].chaId)) {
          if (chaid.endsWith("_additional") || chaid.endsWith("_recommended")) {
            relevantExpandData.choicesExpandState[chaid] = false;
          }
        }
      });
      const payload = {
        tab: defaultConfigStepSlected,
        choicesExpandState: relevantExpandData.choicesExpandState
      };
      dispatch({
        type: UPDATE_CHOICE_EXPAND,
        payload
      });
    }
    _expanded[index] = !_expanded[index];
    setExpanded(_expanded);
  };

  const otherCarePackComponent = chaid => {
    return chaid.required ||
      chaid.selcon ||
      modelData.Items.some(
        part =>
          part.partno !== "" &&
          part.visible === true &&
          part.precon >= 0 &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      ) ? (
      <ChaidSelectionType
        chaid={chaid}
        modelData={modelData}
        handleItemChange={handleItemChange}
        handleQuantityChange={handleQuantityChange}
      />
    ) : null;
  };
  const tabChaidsList = window.uiSpecData;
  const indexOfCarepacks =
    tabChaidsList?.UISpec[0]?.sections?.findIndex(
      section => section.id === "carePacks"
    ) || -1;
  const fetchingSearchedParentChaid =
    searchText.length > 0 && indexOfCarepacks !== -1
      ? tabChaidsList?.UISpec[0]?.sections[indexOfCarepacks]?.subsections?.find(
          choice =>
            choice.title.toLowerCase().includes(searchText.toLowerCase())
        )
      : "";

  const shouldMandaShowInSearch = chaid => {
    let shouldMandaShow =
      chaid.items.some(
        item =>
          item.partno.toLowerCase().includes(searchText.toLowerCase()) ||
          item.partdes.toLowerCase().includes(searchText.toLowerCase())
      ) ||
      chaid.chaDes.toLowerCase().includes(searchText.toLowerCase()) ||
      fetchingSearchedParentChaid;
    return shouldMandaShow;
  };

  const getRecommendedMandaCarePacks = (chaid, partNo) => {
    const _items = chaid.items.filter(_ch => {
      return (
        _ch.priority !== "" &&
        parseInt(_ch.priority) >= 0 &&
        parseInt(_ch.priority) <= 5
      );
    });
    if (_items.length > 0) {
      const recommChaid = {
        ...chaid,
        chaId: chaid.chaId + "_recommended",
        chaDes:
          isVerticalView === "N"
            ? getCarePackLabel(t, "recommendedLabel", chaid)
            : `${getCarePackLabel(t, "recommendedLabel", chaid)} - ${partNo}`,
        items: _items
      };
      if (searchText) {
        return shouldMandaShowInSearch(recommChaid)
          ? otherCarePackComponent(recommChaid)
          : [];
      }
      return otherCarePackComponent(recommChaid);
    } else {
      return [];
    }
  };

  const getAdditionalMandaCarePacks = (chaid, partNum) => {
    const _items = chaid.items.filter(_ch => {
      return _ch.priority === "" || parseInt(_ch.priority) > 5;
    });
    if (_items.length > 0) {
      const addchaid = {
        ...chaid,
        chaId: chaid.chaId + "_additional",
        chaDes:
          isVerticalView === "N"
            ? getCarePackLabel(t, "addLabel", chaid)
            : `${getCarePackLabel(t, "addLabel", chaid)} - ${partNum}`,
        items: _items
      };

      if (searchText) {
        return shouldMandaShowInSearch(addchaid)
          ? otherCarePackComponent(addchaid)
          : [];
      }
      return otherCarePackComponent(addchaid);
    } else {
      return [];
    }
  };

  return mandaCarePacks?.map((_manda, index) => {
    const partDesc = getLocalizedMandaCPItem(modelData, _manda);
    return (
      partDesc && (
        <div
          className={classes.accordian}
          key={index}
          id={"chaid-" + _manda.chaId}
        >
          <ErrorPart chaid={_manda} model={modelData} setMargin={true} />
          {isVerticalView === "N" ? (
            <Accordion
              expanded={expanded[index] || false}
              onChange={e => handleChange(index, e)}
            >
              <AccordionSummary
                className={
                  showWarning && showWarning.warning
                    ? ""
                    : classes.choiceLevelMarginTop
                }
                expandIcon={<ExpandMoreIcon />}
                aria-controls="itemPanelbh-content"
                id="itemPanelbh-header"
              >
                <div>
                  <Typography
                    className={classes.heading}
                    component={"span"}
                    variant={"body2"}
                  >
                    {`${getCarePackLabel(t, "mandaCarePackLabel", _manda)}: ${
                      partDesc?.partno
                    } - ${partDesc?.partdes}`}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.accordianDetails}>
                <React.Fragment>
                  {getRecommendedMandaCarePacks(_manda)}
                  {getAdditionalMandaCarePacks(_manda, partDesc?.partno)}
                </React.Fragment>
              </AccordionDetails>
            </Accordion>
          ) : (
            <div
              className={
                showWarning && showWarning.warning
                  ? ""
                  : classes.choiceLevelMarginTop
              }
            >
              {getRecommendedMandaCarePacks(_manda, partDesc?.partno)}
              {getAdditionalMandaCarePacks(_manda, partDesc?.partno)}
            </div>
          )}
        </div>
      )
    );
  });
};

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MyAnimation from "../../../components/MyAnimation";
import UploadConfigHeader from "./UploadConfigHeader";
import UploadSuccess from "./UploadSuccess";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: "5%"
  },
  uploadConfiguration: {
    marginTop: "-25px",
    fontSize: "29px"
  },
  config: {
    fontSize: "17px"
  }
}));

export default function Upload(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentStep = useSelector(state => state.uploadConfig.activeStep);
  const [hasUpload, setHasUpload] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const lottieLoadingFile =
    "https://dev-ociq.hpcloud.hp.com/assets/lotties/product_selector_animation.json";

  return (
    <div className={classes.root}>
      {currentStep <= 2 ? (
        <Fragment>
          <Grid>
            {currentStep === 2 ? (
              <Fragment>
                <Typography variant="h5" gutterBottom>
                  Upload Bill of Materials
                </Typography>
                <Typography>Upload Configuration Summary.</Typography>
              </Fragment>
            ) : (
              !hasUpload &&
              !loading && (
                <Fragment>
                  <Typography
                    className={classes.uploadConfiguration}
                    variant="h5"
                    gutterBottom
                  >
                    {t("common:uploadConfig:configuration")}
                  </Typography>
                  <Typography className={classes.config}>
                    {t("common:uploadConfig:stepsTip")}
                  </Typography>
                </Fragment>
              )
            )}
          </Grid>
          <UploadConfigHeader
            hasUpload={hasUpload}
            setHasUpload={setHasUpload}
            loading={loading}
            setLoading={setLoading}
          ></UploadConfigHeader>

          {loading && (
            <Grid
              container
              justifyContent="center"
              className={classes.paddingTopBottom}
            >
              <div>
                <Typography variant="h4" align="center">
                  {t("common:loader.gifLoaderMessage1")}
                </Typography>
                <Typography align="center">
                  {t("common:loader.gifLoaderMessage2")}
                </Typography>
                <MyAnimation
                  lottieFile={lottieLoadingFile}
                  style={{ height: "300px", width: "400px" }}
                />
              </div>
              ;
            </Grid>
          )}
        </Fragment>
      ) : (
        <UploadSuccess handleClose={props.handleClose} />
      )}
    </div>
  );
}

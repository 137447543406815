import React from "react";
import { useSelector } from "react-redux";
import {
  carePackAdditionalChaId,
  customCPChaids
} from "../../services/bomAndCarePackLib/utils";
import ChaidMultiple from "../ChaidMultiple";

export const CarePackAdditional = props => {
  const additionalCarePacks = {
    chaId: carePackAdditionalChaId,
    chaDes: "Additional Care Packs",
    multiple: true,
    required: false,
    visible: true,
    precon: 0,
    selcon: false,
    firstItem: 99999999,
    lastItem: -1,
    isNode: -1,
    specified: true,
    isUsed: true,
    items: []
  };
  let hasAnyAdditionalPacks = false;
  window.hasAnyAdditionalPacks = false;
  let updatedFilteredChoices = JSON.parse(
    JSON.stringify(props.filteredChoices)
  );
  const searchText = useSelector(state => state.configFilterData.searchText);
  const showAdditional = useSelector(
    state => state.configFilterData.showAdditional
  );
  props.filteredChoices.forEach((chaid, index) => {
    if (chaid) {
      if (
        chaid.chaId.includes("ELECTCPQ") &&
        !props.isChaidHideBasedOnRegion(chaid.chaId) &&
        chaid.visible
      ) {
        additionalCarePacks.firstItem = additionalCarePacks.firstItem
          ? chaid.firstItem < additionalCarePacks.firstItem
            ? chaid.firstItem
            : additionalCarePacks.firstItem
          : chaid.firstItem;
        additionalCarePacks.lastItem = additionalCarePacks.lastItem
          ? chaid.lastItem > additionalCarePacks.lastItem
            ? chaid.lastItem
            : additionalCarePacks.lastItem
          : chaid.lastItem;
        additionalCarePacks.items = [
          ...additionalCarePacks.items,
          ...chaid.items.filter(
            item => (item.priority > 5 || item.priority == "") && item.visible
          )
        ];
        updatedFilteredChoices = [
          ...updatedFilteredChoices.slice(0, index),
          ...updatedFilteredChoices.slice(index + 1)
        ];
        let isVisible = additionalCarePacks.items.some(
          part =>
            part.partno !== "" &&
            (part.visible || part.selected) &&
            part.inode >= additionalCarePacks.firstItem &&
            part.inode <= additionalCarePacks.lastItem
        );
        hasAnyAdditionalPacks =
          (additionalCarePacks.items.some(
            item =>
              item.partno.toLowerCase().includes(searchText.toLowerCase()) ||
              item.partdes.toLowerCase().includes(searchText.toLowerCase())
          ) &&
            isVisible) ||
          (additionalCarePacks.chaDes
            .toLowerCase()
            .includes(searchText.toLowerCase()) &&
            isVisible) ||
          ((chaid.chaDes.toLowerCase().includes(searchText.toLowerCase()) ||
            customCPChaids.toLowerCase().includes(searchText.toLowerCase())) &&
            isVisible);
        window.hasAnyAdditionalPacks = hasAnyAdditionalPacks;
      }
    }
  });

  const choices = props.filteredChoices.filter(
    item => item.chaId !== "ELECTCPQ" && item.parentId === "hpCarePackService"
  );

  const updatedChoices = [additionalCarePacks, ...choices];

  const additionalCarepack = updatedChoices.map(
    chaid =>
      (showAdditional || hasAnyAdditionalPacks) && (
        <ChaidMultiple
          key={additionalCarePacks.chaidId}
          chaid={chaid}
          model={props.modelData}
          handleItemChange={props.handleItemChange}
          handleQuantityChange={props.handleQuantityChange}
        />
      )
  );

  return <>{additionalCarepack}</>;
};

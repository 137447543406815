import Skeleton from "@mui/lab/Skeleton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useContext, useState } from "react";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import { generatArrayOfValues } from "../../../lib/common/util";
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: 10
  },
  avatar: {
    width: 130,
    height: 100
  },
  listItem: {
    paddingRight: 18
  },

  header: {
    marginLeft: 16,
    marginRight: 16
  },
  bullets: {
    paddingLeft: 16,
    marginTop: 2,
    listStyleType: "none"
  },
  quanity: {
    width: 25,
    height: 25
  }
}));

const ProductSkeleton = () => {
  const classes = useStyles();
  const { isStandaloneAccess = false } = useContext(ExternalProps);
  const [productAttributes, setProductAttributes] = useState(
    generatArrayOfValues(6)
  );
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper} elevation={3}>
        <Grid container item xs={12} spacing={2}>
          <Grid zeroMinWidth item xs="auto">
            <Skeleton
              variant="rectangle"
              className={classes.avatar}
              animation="wave"
            />
            <Typography key="caption" variant="caption">
              <Skeleton animation="wave" />
            </Typography>
          </Grid>
          <Grid zeroMinWidth item xs sm md lg xl>
            <Typography component="div" variant="body1">
              <Skeleton className={classes.header} animation="wave" />
            </Typography>
            <Grid container component={"ul"} className={classes.bullets}>
              {productAttributes.map((each, index) => (
                <Grid
                  item
                  xs={6}
                  key={index}
                  component={"li"}
                  zeroMinWidth
                  className={classes.listItem}
                >
                  <Typography variant="caption" component={"div"} gutterBottom>
                    <Skeleton animation="wave" />
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid zeroMinWidth item xs={"auto"}>
            <Typography
              variant={"body1"}
              noWrap
              component={"div"}
              color="primary"
              style={{ marginBottom: "2rem" }}
            >
              <Skeleton width="100px" animation="wave" />
            </Typography>
            {!isStandaloneAccess && (
              <Box display={"flex"} justifyContent="space-between">
                <Skeleton
                  variant="circular"
                  className={classes.quanity}
                  animation="wave"
                />
                <Typography variant={"caption"} style={{ paddingTop: 10 }}>
                  <Skeleton width={25} height={10} animation="wave" />
                </Typography>
                <Skeleton
                  variant="circular"
                  className={classes.quanity}
                  animation="wave"
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ProductSkeleton;

import Skeleton from "@mui/lab/Skeleton";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  }
}));

export default function TableSkeleton(props) {
  const classes = useStyles();
  const { lineNumber, cells, spacing, height } = props;
  return (
    <List dense className={classes.root}>
      {Array(lineNumber)
        .fill("")
        .map((item ,index) => (
          <ListItem key={index}>
            <Grid container alignItems="center" wrap="wrap" spacing={spacing}>
              {cells.map((cell, index) => (
                <Grid item xs={parseInt(cell.width)} key={index}>
                  <Skeleton variant="rectangular" width="100%" height={height} />
                </Grid>
              ))}
            </Grid>
          </ListItem>
        ))}
    </List>
  );
}

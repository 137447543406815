import {
  CODE_CONFLICT_ITEM,
  CODE_COUNTER_CONFLICT,
  CODE_INVALID_CHOICE_SELECTION,
  CODE_MONITORCAPQ_QTY,
  CODE_REQUIRED_CHOICE_EMTY,
  ChaIdBusinessMonitor,
  ChaIdCarbonNeutralMonitorServices,
  ChaIdPointOfSalesSystem,
  DO_DESELECT,
  HIDDEN_CHOICES,
  MON_CP_QTY_MSG,
  TEXT_CONFLICT,
  TEXT_COUNTER_CONFLICT,
  TEXT_EMPTY,
  TEXT_WARNING
} from "../Constants";
import { updateModel } from "../Localization/util";
import {
  getChoiceConflicts,
  getHiddenChoiceConflicts,
  getItemConflictsNn
} from "../NeuralNet/processOutput"
import { isAnyItemSelected, findSelectedItems } from "../main";

export const doesElementExistInDeletedEntriesChoice = (
  elm,
  choiceConflicts
) => {
  return choiceConflicts.some(each => each?.effectChoice?.chaId === elm);
};

export const filterChoiceConflicts = (chConflicts, model) => {
  let toBeDeletedEntries = [];
  let tempRetConflicts = [];
  if (chConflicts?.length > 0) {
    chConflicts.forEach(conflict => {
      let selFlag = false;
      let firstItem = -1;
      let lastItem = -1;
      for (let i = 0; i < model.Chaids?.length; i++) {
        let chaid = model.Chaids[i];
        if (conflict?.effectChoice.chaId == chaid?.chaId) {
          firstItem = chaid.firstItem;
          lastItem = chaid.lastItem;
          break;
        }
      }
      if (firstItem != -1 && lastItem != -1) {
        for (let i = firstItem; i <= lastItem; i++) {
          if (model.Items[i].selected) {
            selFlag = true;
            break;
          }
        }
        if (!selFlag) {
          toBeDeletedEntries = [...toBeDeletedEntries, conflict];
        }
      }
    });
  }
  if (toBeDeletedEntries?.length > 0) {
    chConflicts?.forEach(conflict => {
      let flag = false;
      if (
        doesElementExistInDeletedEntriesChoice(
          conflict?.effectChoice?.chaId,
          toBeDeletedEntries
        )
      ) {
        flag = true;
      }
      if (!flag) {
        tempRetConflicts = [...tempRetConflicts, conflict];
      }
    });
    chConflicts = [];
    chConflicts = tempRetConflicts;
  }
  return chConflicts;
};

export const getCarePackChaId = (monitor, choiceID) => {
  if (choiceID == ChaIdBusinessMonitor || choiceID == ChaIdPointOfSalesSystem) {
    return "CAPQ_" + monitor?.split("#")[0];
  } else {
    console.log("getSelectedItems chaid)", monitor);
    return "CAPQ_P_" + monitor?.split("#")[0];
  }
};
export const doesElementExistInCounterConflicts = (elm, cntrCnfltsArray) => {
  return cntrCnfltsArray?.some(each => each?.partNo === elm);
};

const getSelectedItems = (model, ChaIds) => {
  let result = {};
  let chaDes = {};
  if (ChaIds?.length == 0) {
    return { chaDes, result };
  }
  let count = 0;
  for (let i = 0; i < model.Chaids?.length; i++) {
    const chaid = model.Chaids[i];
    if (!chaid.visible) {
      continue;
    }
    if (ChaIds.includes(chaid.chaId)) {
      let selectedItems = [];
      for (let j = chaid.firstItem; j <= chaid.lastItem; j++) {
        let item = model.Items[j];
        if (item.visible && item.selected) {
          selectedItems = [...selectedItems, item];
        }
      }
      result[chaid.chaId] = selectedItems;
      chaDes[chaid.chaId] = chaid.chaDes;
      count++;
    }
    if (count == ChaIds?.length) {
      break;
    }
  }
  return { chaDes, result };
};

export const checkQuantity = modelData => {
  let monitorCAPQConflicts = [];
  let monitorChoiceIds = ["ASCM_64283", "ASCM_3242660", "ASCM_POLYMON"];
  const { chaDes, result: selectedMonitors } = getSelectedItems(
    modelData,
    monitorChoiceIds
  ); // Monitors ChaIds

  Object.keys(selectedMonitors)?.forEach(each => {
    let sumMonitorsQty = 0;
    let sumCarepacksQty = 0;
    let carepackChaIds = [];
    const monitors = selectedMonitors[each];
    monitors?.forEach(monitor => {
      let updatedCarePackChaId = getCarePackChaId(monitor.partno, each);
      carepackChaIds = [...carepackChaIds, updatedCarePackChaId];
      sumMonitorsQty += monitor.quantity;
    });
    const { chaDes: carepackDesc, result: selectedCarepacks } =
      getSelectedItems(modelData, carepackChaIds);
    Object.keys(selectedCarepacks)?.forEach(each => {
      const carepacks = selectedCarepacks[each];
      carepacks?.forEach(carepack => {
        sumCarepacksQty += carepack.quantity;
      });
    });
    let msg = "";
    if (sumCarepacksQty == 0 || sumCarepacksQty == sumMonitorsQty) {
      //pass
    } else {
      const conf = {
        code: CODE_MONITORCAPQ_QTY,
        messageType: TEXT_COUNTER_CONFLICT,
        chaId: each,
        chaDes: chaDes[each],
        counterDesc: MON_CP_QTY_MSG,
        qty: [sumCarepacksQty, sumMonitorsQty]
      };
      monitorCAPQConflicts = [...monitorCAPQConflicts, conf];
      Object.keys(selectedCarepacks)?.forEach(chaID => {
        const conf = {
          code: CODE_MONITORCAPQ_QTY,
          messageType: TEXT_COUNTER_CONFLICT,
          chaId: chaID,
          chaDes: carepackDesc[chaID],
          counterDesc: MON_CP_QTY_MSG,
          qty: [sumCarepacksQty, sumMonitorsQty]
        };
        monitorCAPQConflicts = [...monitorCAPQConflicts, conf];
      });
    }
  });
  return monitorCAPQConflicts;
};

//src/webasm/validate.go
export const getAllCounterConflicts = model => {
  let ctrConflictsMap = [];
  let ctrConflictsItemsMap = [];
  let cntrCnflts = [];
  let cntrCnfltsArray = [];

  for (let index = 0; index < model?.Counters?.length; index++) {
    const item = model.Counters[index];
    if (item.count < item.min || item.count > item.max) {
      ctrConflictsMap.push(index);
    }
  }
  for (let index = 0; index < ctrConflictsMap.length; index++) {
    const each = ctrConflictsMap[index];
    for (let itemIndex = 0; itemIndex < model?.Items?.length; itemIndex++) {
      const modelItem = model.Items[itemIndex];
      if (modelItem.selected) {
        let edgesData = model?.Nodes[modelItem.inode]?.edges;
        for (let i = 0; i < edgesData.length; i++) {
          let edgesDataItem = edgesData[i];
          if (edgesDataItem.type === 0x5) {
            for (let k = 0; k < edgesDataItem?.node?.length; k++) {
              const edgesItemNode = edgesDataItem.node[k];
              let nodeData = model?.Nodes[edgesItemNode]?.edges;
              for (let j = 0; j < nodeData.length; j++) {
                let nodeDataItem = nodeData[j];
                if (nodeDataItem.type === 0x1) {
                  let ctrInode = nodeDataItem.node[0];
                  let ctrData = model?.Nodes[ctrInode]?.edges;
                  for (let l = 0; l < ctrData.length; l++) {
                    let ctrDataItem = ctrData[l];
                    if (ctrDataItem.type === 0x46) {
                      if (ctrDataItem.node[0] === each) {
                        ctrConflictsItemsMap[each] = ctrConflictsItemsMap[each]
                          ? [...ctrConflictsItemsMap[each], modelItem.inode]
                          : [modelItem.inode];
                        break;
                      }
                    }
                  }
                } else {
                  if (nodeDataItem.type === 0x46) {
                    let nodesData = nodeDataItem?.node;
                    for (let k = 0; k < nodesData.length; k++) {
                      let ctrDataItem = nodesData[k];
                      if (ctrDataItem === each) {
                        ctrConflictsItemsMap[each] = ctrConflictsItemsMap[each]
                          ? [...ctrConflictsItemsMap[each], modelItem.inode]
                          : [modelItem.inode];
                        break;
                      }
                    }
                    break;
                  }
                }
              }
            }
            break;
          }
        }
      }
    }
  }

  for (const [key, value] of Object.entries(ctrConflictsItemsMap)) {
    // eslint-disable-next-line no-loop-func
    for (let itemIndex = 0; itemIndex < model?.Items?.length; itemIndex++) {
      const item = model.Items[itemIndex];
      for (let j = 0; j < value.length; j++) {
        const val = value[j];
        if (item.inode === val) {
          for (let k = 0; k < model?.Chaids?.length; k++) {
            const v = model.Chaids[k];
            if (item.inode >= v.firstItem && item.inode <= v.lastItem) {
              let counter = model?.Counters;
              for (let i = 0; i < counter.length; i++) {
                let vC = counter[i];
                if (i === parseInt(key)) {
                  if (
                    !doesElementExistInCounterConflicts(
                      item.partno,
                      cntrCnfltsArray
                    )
                  ) {
                    const newConflitElements = {
                      partNo: item.partno,
                      count: vC.count,
                      counterDesc: vC.chaDes,
                      max: vC.max,
                      min: vC.min,
                      chaId: v.chaId,
                      chaDes: v.chaDes
                    };
                    cntrCnfltsArray = [...cntrCnfltsArray, newConflitElements];
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
    model.isCounterConflict = true;
  }
  if (cntrCnfltsArray && cntrCnfltsArray?.length > 0) {
    let checkMap = {};
    let cntrMap = {};
    cntrCnfltsArray?.forEach(cntrConflict => {
      const isExistingChaID = cntrMap[cntrConflict?.chaId];
      if (!isExistingChaID) {
        cntrMap[cntrConflict?.chaId] = {
          code: CODE_COUNTER_CONFLICT,
          messageType: TEXT_COUNTER_CONFLICT,
          chaId: cntrConflict?.chaId,
          chaDes: cntrConflict?.chaDes,
          count: cntrConflict?.count,
          counterDesc: cntrConflict?.counterDesc,
          min: cntrConflict?.min,
          max: cntrConflict?.max
        };
        if (checkMap[cntrConflict?.chaId]) {
          checkMap[cntrConflict?.chaId] = [
            ...checkMap[cntrConflict?.chaId],
            cntrConflict?.partNo
          ];
        } else {
          checkMap[cntrConflict?.chaId] = [cntrConflict?.partNo];
        }
      } else {
        checkMap[cntrConflict?.chaId] = [
          ...checkMap[cntrConflict?.chaId],
          cntrConflict?.partNo
        ];
      }
    });
    Object.keys(cntrMap).forEach(each => {
      const cntrMapitem = cntrMap[each];
      if (checkMap[each]) {
        const newCnflts2 = {
          partNos: checkMap[each],
          code: cntrMapitem?.code,
          messageType: cntrMapitem?.messageType,
          chaDes: cntrMapitem?.chaDes,
          chaId: cntrMapitem?.chaId,
          count: cntrMapitem?.count,
          counterDesc: cntrMapitem?.counterDesc,
          max: cntrMapitem?.max,
          min: cntrMapitem?.min
        };
        cntrCnflts = [...cntrCnflts, newCnflts2];
      }
    });
  }
  const monitorCAPQConflicts = checkQuantity(model);
  if (monitorCAPQConflicts?.length > 0) {
    cntrCnflts = [...cntrCnflts, ...monitorCAPQConflicts];
    model.isCounterConflict = true;
  }
  return cntrCnflts;
};

//src/webasm/validate.go
export const getConflictsForAllSelectedItemInPreconFalseChoices = model => {
  let icCnflts = [];
  for (let j = 0; j < model?.Chaids?.length; j++) {
    let partno = "";
    const chaObj = model.Chaids[j];
    if (chaObj.precon < 0 && chaObj.visible) {
      if (chaObj.firstItem === chaObj.lastItem) {
        if (model.Items[chaObj.firstItem].selected) {
          partno = model.Items[chaObj.firstItem].partno;
        }
      } else {
        for (let i = chaObj.firstItem; i <= chaObj.lastItem; i++) {
          if (model.Items[i].selected && model.Items[i].visible) {
            partno = model.Items[i].partno;
            break;
          }
        }
      }
    }
    if (partno !== "") {
      chaObj.visible = true;
      const newCnflts = {
        code: CODE_INVALID_CHOICE_SELECTION,
        messageType: TEXT_WARNING,
        chaId: chaObj.chaId,
        chaDes: chaObj.chaDes,
        partNo: partno
      };
      icCnflts = [...icCnflts, newCnflts];
    }
  }
  return icCnflts;
};

export const getConflictsForAllUnselectedItemInRequiredChoices = model => {
  let isComplete = true;
  let icCnflts = [];
  for (let j = 0; j < model?.Chaids?.length; j++) {
    const chaObj = model.Chaids[j];
    let noItemsSelected = false;
    if ((chaObj.required || chaObj.selcon) && chaObj.visible) {
      if (chaObj.firstItem === chaObj.lastItem) {
        if (!model.Items[chaObj.firstItem].selected) {
          noItemsSelected = true;
        }
      } else {
        for (var i = chaObj.firstItem; i <= chaObj.lastItem; i++) {
          if (model.Items[i].selected && model.Items[i].visible) {
            noItemsSelected = false;
            break;
          } else {
            noItemsSelected = true;
          }
        }
      }
    }

    if (noItemsSelected) {
      const newCnflts = {
        code: CODE_REQUIRED_CHOICE_EMTY,
        messageType: TEXT_WARNING,
        chaId: chaObj.chaId,
        chaDes: chaObj.chaDes,
        partNo: TEXT_EMPTY
      };

      icCnflts = [...icCnflts, newCnflts];

      if (isComplete) {
        isComplete = false;
      }
    }
  }

  model.isComplete = isComplete;
  return icCnflts;
};

//src/webasm/validate.go
export const getConflictsForSelectedItemInPreconFalseItems = (
  model,
  itmCnfltList
) => {
  let conflictsItemMap = [];
  let isConflict = false;
  let icCnflts = [];
  let sameConflictItemsMap = {};
  let flagMap = {};
  model?.sameConflictItems?.forEach(each => {
    sameConflictItemsMap[each] = true;
    itmCnfltList?.forEach(conflict => {
      if (conflict?.effect.iitem == each) {
        flagMap[each] = true;
      }
    });
  });

  for (let i = 0; i < model?.Items?.length; i++) {
    const item = model.Items[i];
    const isDebug = window.localStorage.getItem("isDebug") === "true";
    item?.selected &&
      item?.precon <= -2 &&
      isDebug &&
      console.log("PRECON WITH -2", item);
    if (item.visible && item.precon < 0 && item.selected) {
      if (flagMap[item.inode]) {
        conflictsItemMap.push({
          itemINode: item.inode,
          itemPartNo: item.partno
        });
        if (!isConflict) {
          isConflict = true;
        }
      } else if (!sameConflictItemsMap[item.inode]) {
        conflictsItemMap.push({
          itemINode: item.inode,
          itemPartNo: item.partno
        });
        if (!isConflict) {
          isConflict = true;
        }
      }
    }
  }

  for (let i = 0; i < conflictsItemMap.length; i++) {
    const item = conflictsItemMap[i];
    for (let j = 0; j < model?.Chaids?.length; j++) {
      const chaObj = model.Chaids[j];
      if (!HIDDEN_CHOICES[chaObj?.chaId]) {
        if (
          item.itemINode >= chaObj.firstItem &&
          item.itemINode <= chaObj.lastItem
        ) {
          chaObj.visible = true;
          const newCnflts = {
            code: CODE_CONFLICT_ITEM,
            messageType: TEXT_CONFLICT,
            chaId: chaObj.chaId,
            chaDes: chaObj.chaDes,
            partNo: item.itemPartNo
          };
          icCnflts = [...icCnflts, newCnflts];
        }
      }
    }
  }
  model.isConflict = isConflict;
  return icCnflts;
};

function updateCBN_CP(updateModelData) {
  const { selectedItems, iChaId } = findSelectedItems(
    ChaIdCarbonNeutralMonitorServices,
    updateModelData
  );
  if (!iChaId) {
    return [];
  }
  const payloads = [];
  // "precon < 0" does not guarantee we have to deselect those items under that choice.
  if (
    isAnyItemSelected(
      [ChaIdBusinessMonitor, ChaIdPointOfSalesSystem],
      updateModelData
    )
  ) {
    updateModelData.Chaids[iChaId].visible = true;
  } else {
    updateModelData.Chaids[iChaId].visible = false;
    if (selectedItems?.length > 0) {
      for (const i of selectedItems) {
        const node = updateModelData.Nodes[updateModelData.Items[i].inode];
        payloads.push({
          inputEdge: node.type,
          inputNode: node.id,
          changeIn: DO_DESELECT
        });
        updateModelData.Items[i].quantity = 0;
      }
    }
  }
  return payloads;
}

export function preConflictDetection(modelData) {
  const payloads = [];
  let updateModelData = modelData;
  const choiceConflicts = getChoiceConflicts();
  if (updateModelData?.conflicts?.length > 0) {
    updateModelData.conflicts[0].itemConflictsList = getItemConflictsNn()
    updateModelData.conflicts[0].hiddenChoiceConflicts = getHiddenChoiceConflicts()
    updateModelData.conflicts[0].choiceConflict = choiceConflicts
  }
  for (const vC of choiceConflicts) {
    const effectChoice = updateModelData.Chaids.find(
      ch => ch.chaId === vC.effectChoice.chaId
    );
    if (!effectChoice) continue;
    const isDependent =
      vC.effectChoice.chaId.includes(vC.cause.chaId) &&
      vC.effectChoice.chaId !== vC.cause.chaId;
    if (isDependent) {
      // items in effect choice will be auto-deselected
      for (let i = effectChoice.firstItem; i <= effectChoice.lastItem; i++) {
        if (updateModelData.Items[i].selected) {
          const node = updateModelData.Nodes[updateModelData.Items[i].inode];
          payloads.push({
            inputEdge: node.type,
            inputNode: node.id,
            changeIn: DO_DESELECT
          });
        }
      }
    }
  }
  // auto-deselection logic of CBN_CP
  payloads.push(...updateCBN_CP(updateModelData));
  for (const iPayLoad of payloads) {
    updateModelData = updateModel(
      iPayLoad.inputEdge,
      iPayLoad.inputNode,
      iPayLoad.changeIn,
      updateModelData
    );
  }
  return updateModelData;
}

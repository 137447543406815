import { KeyboardArrowUp } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import ExternalProps from "../../contexts/externalPropsContext";
const useStyles = makeStyles(theme => ({
  container: {
    margin: "0 5% 5% 5%"
  },
  midPositioner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  },
  spinner: {
    color: "rgb(41, 168, 221)"
  },
  warningColor: {
    color: "#ffbf00"
  },
  typo: {
    color: "#29A8DD"
  },
  yopacity: {
    "&.MuiFab-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      height: "46px",
      width: "48px",
      background: "#0096D6 !important",
      position: "fixed",
      fontSize: "3rem",
      zIndex: "9999",
      cursor: "pointer",
      right: "24px"
    },
    "&:hover": {
      color: "green"
    }
  },
  bottomReconfigure: {
    bottom: "20rem"
  },
  bottom: {
    bottom: "16rem"
  }
}));
function ScrollToTop() {
  const classes = useStyles();
  const { isOneConfigIQ, isOpenOneConfigReconfigure } =
    React.useContext(ExternalProps);
  const [containerScrollValue, setContainerScrollValue] = useState(0);
  const containerItem = document?.getElementById("configContainer");
  if (containerItem && !isOneConfigIQ) {
    const scrollParent = containerItem?.closest("[role=dialog]");
    const handleScroll = () => {
      let scrollValue = scrollParent?.scrollTop;
      setContainerScrollValue(scrollValue > 0);
    };
    scrollParent?.removeEventListener("scroll", handleScroll);
    scrollParent?.addEventListener("scroll", handleScroll);
  }
  function navigateToTop() {
    let topRefLink = document.querySelector("#configContainer");
    topRefLink?.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      {!isOneConfigIQ && containerScrollValue ? (
        <Fab
          color="primary"
          className={
            isOpenOneConfigReconfigure
              ? [classes.bottomReconfigure, classes.yopacity]
              : [classes.bottom, classes.yopacity]
          }
          onClick={navigateToTop}
        >
          <KeyboardArrowUp />
        </Fab>
      ) : null}
    </>
  );
}

export default ScrollToTop;

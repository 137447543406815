import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import displayNotistack from "./SnackBarUtils";

export default function ViewDetails(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showErrorDetails, errorMessage } = useSelector(
    state => state.snackbarReducer
  );
  const handleClose = () => {
    dispatch({ type: "SET_SHOW_ERROR_DETAILS", payload: false });
  };

  return (
    <>
      {showErrorDetails && (
        <Dialog
          open={showErrorDetails}
          onClose={() => {
            dispatch({ type: "SET_SHOW_ERROR_DETAILS", payload: false });
          }}
        >
          <DialogTitle>
            <Typography
              variant="h6"
              align="left"
              style={{ fontWeight: "bold" }}
              dispaly="inline"
            >
              Error Details
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: "#000000" }}>
              <pre>
                <font face="Courier">{JSON.stringify(errorMessage)}</font>
              </pre>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CopyToClipboard
              text={
                typeof errorMessage !== "string"
                  ? JSON.stringify(errorMessage)
                  : errorMessage
              }
              onCopy={() => {
                displayNotistack.info(t("common:snackbar.copied"), {
                  autoHideDuration: 2000
                });
              }}
            >
              <Button variant="contained" onClick={handleClose} color="primary">
                copy
              </Button>
            </CopyToClipboard>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

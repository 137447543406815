import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "20px 0",
    width: "100%",
    minHeight: 100,
    backgroundColor: "#f6f6f6",
    borderBottom: "4px solid #0096d6",
    "& label": {
      margin: "10px 0 0 15px"
    },
    padding: "20px 0 0 15px"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  list: {
    padding: "0 16px"
  }
}));

export default function NestedList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const handleBomList = event => {
    let bomList = event.target.value.split(/[\n,]/).map(item => item.trim());
    let indexSplice = [];
    for (const index in bomList) {
      if (!bomList[index]) {
        indexSplice.push(index);
      }
    }
    indexSplice.reverse().forEach(i => {
      bomList.splice(i, 1);
    });
    setValue(event.target.value.split(",").join("\n"));
    dispatch({
      type: "SET_BILL_OF_MATERIALS_ITEMS",
      payload: bomList
    });
  };
  return (
    <TextField
      variant="standard"
      id="standard-multiline-static"
      label={t("common:uploadConfig.bill")}
      multiline
      value={value}
      className={classes.root}
      onChange={handleBomList}
      InputLabelProps={{
        shrink: true,
        style: { color: "#787a7a", fontWeight: "bolder" }
      }}
      InputProps={{
        disableUnderline: true
      }} />
  );
}

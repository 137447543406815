import { useLazyQuery, useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, CircularProgress, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import StepConnector from "@mui/material/StepConnector";
import Typography from "@mui/material/Typography";
import { makeStyles, withStyles } from "@mui/styles";
import { useSnackbar, withSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  SAVE_CONFIGURATION,
  UPLOAD_CONFIG_API_RESTORATION
} from "../../../GraphQL";
import {
  SET_ACCESS_TYPE,
  SET_CONFIG_NAME,
  SET_MODEL_ID,
  SET_ORIGINAL_ACCESS_TYPE
} from "../../../actions/Types";
import ConfiguratorContainer from "../../../components/Configurator/ConfigContainer";
import useInvokeRskuUserAccess from "../../../components/Configurator/Rsku/hooks/useInvokeRskuUserAccess";
import FullscreenModal from "../../../components/FullscreenModal";
import ExternalProps from "../../../contexts/externalPropsContext";
import {
  checkAllGaDateBeforeSystemDate,
  getMaxGaDateFromItems
} from "../../../lib/common/util";
import {
  checkGaDateWithinRange,
  getUISpecData,
  getUiDataFileName,
  handleAddToQuoteClick,
  handleData
} from "../../../services/common/utils";
import UploadConfigBOM from "./UploadConfigBOM";
import UploadConfigSku from "./UploadConfigSku";
import UploadConfigSkuTable from "./UploadConfigSkuTable";
const QontoConnector = withStyles({
  horizontal: {
    display: "none"
  }
})(StepConnector);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  button: {
    marginRight: theme.spacing(1),
    marginLeft: "6px",
    textTransform: "none"
  },
  uploadConfigButton: {
    backgroundColor: "#000",
    marginLeft: "6px"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  horizontal: {
    width: "33%"
  },
  uploadsku: {
    padding: "30px 0"
  },
  paddingTopBottom: {
    padding: "40px 0"
  },
  gridStyle: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  gridChildrenOneStyle: {
    marginBottom: theme.spacing(2)
  },
  gridChildrenTwoStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around"
  },
  ConfigurationName: {
    color: "#989898",
    fontSize: "18px"
  },
  inputbox: {
    border: "none",
    outline: "none",
    width: "530px",
    fontSize: "14px",
    background: "none",
    margin: "5px 0 4px 0"
  },
  ConfigurationLine: {
    width: "530px",
    height: "2px",
    background: "#2196f3"
  },
  step: {
    paddingLeft: 4,
    "& .MuiStepConnector-root": {
      flex: "0 0 auto !important"
    }
  },
  bottomSnackbar: {
    marginBottom: 115
  },
  oracleExitSnackbar: {
    marginBottom: 115,
    backgroundColor: "#00d072 !important"
  }
}));

const UploadConfigBody = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.uploadConfig.activeStep);
  const { hasUpload, setHasUpload } = props;
  const { loading, setLoading } = props;
  const [value, setValue] = React.useState(10);
  const [textName, setTextName] = React.useState("");
  const [modelId, setModelId] = React.useState("");
  const [region, setRegion] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [modelResponse, setModelResponse] = React.useState("");
  const bomList = useSelector(state => state.uploadConfig.bomList);
  const [isConfigContainerOpen, setIsConfigContainerOpen] = useState(false);
  const [showConfigurator, setShowConfigurator] = useState(true);
  const configurationName = useSelector(
    state => state.uploadConfig.configurationName
  );

  const isAddToQuoteBtnVisible = useSelector(
    state => state.uploadConfig.isVisible
  );
  const isInValidTechAv = useSelector(
    state => state.uploadConfig.isInValidTechAv
  );

  const categoryFromAPI = useSelector(
    state => state.uploadConfig.categoryFromAPI
  );
  const {
    countryCode = "US",
    userType = "INTERNAL",
    userId,
    userEmail,
    companyId,
    regionCode,
    productCategory,
    transactionID = "",
    mDCPOrgID = "99",
    routeToMarket = "Direct",
    resellerID = "",
    snackbarPosition = "top-left",
    isOracleExit = false,
    isStandaloneAccess = false,
    pricingGeo,
    clientLocale,
    currencyCode,
    incoterms
  } = React.useContext(ExternalProps);
  const isPoly = productCategory === "poly";
  const locale = clientLocale
    ? clientLocale.indexOf("_") !== -1
      ? clientLocale
      : clientLocale + "_" + countryCode
    : "en_US";

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const invokeRskuUserAccess = useInvokeRskuUserAccess();
  const isGaDateWithinRange = bomList.some(item =>
    checkGaDateWithinRange(item.gaDate)
  );

  useEffect(() => {
    setTextName(configurationName);
  }, [configurationName]);

  const setProgressValue = value => {
    setValue(value);
  };
  const [uploadConfigApiRestoration] = useLazyQuery(
    UPLOAD_CONFIG_API_RESTORATION,
    {
      fetchPolicy: "no-cache",
      onCompleted(response) {
        dispatch({
          type: SET_ACCESS_TYPE,
          payload: "organization"
        });
        dispatch({
          type: SET_ORIGINAL_ACCESS_TYPE,
          payload: "organization"
        });

        let modelData = response.uploadConfigRestoration?.modelData;
        modelData.isUploadFlow = true;
        modelData.configuration = {
          configHeader: { rgnCd: modelData.region }
        };
        setModelResponse(modelData);
        setIsConfigContainerOpen(showConfigurator);
        if (!showConfigurator) {
          setLoading(true);
          async function fetchDesktopUiSpec(category) {
            let uiSpecFile = categoryFromAPI
              ? getUiDataFileName(categoryFromAPI)
              : getUiDataFileName(category);
            await getUISpecData.getData(uiSpecFile);
            const inputData = handleAddToQuoteClick(
              response.uploadConfigRestoration?.modelData,
              textName,
              userId,
              userEmail,
              companyId,
              productCategory,
              isStandaloneAccess,
              pricingGeo,
              currencyCode,
              incoterms
            );
            saveConfiguration({
              variables: {
                input: inputData
              }
            });
          }
          fetchDesktopUiSpec("Business Desktop PCs");
        }
      },
      onError({ response }) {
        setLoader(false);
        setHasUpload(false);
        console.log("Error");
      }
    }
  );

  const [saveConfiguration] = useMutation(SAVE_CONFIGURATION, {
    fetchPolicy: "no-cache",
    onCompleted({ createConfiguration }) {
      if (createConfiguration) {
        const position = snackbarPosition.split("-");
        const snackbarKey = enqueueSnackbar(
          t("common:snackbar.saveConfiguration"),
          {
            variant: "success",
            anchorOrigin: {
              vertical: position[0],
              horizontal: position[1]
            },
            className:
              snackbarPosition.includes("bottom") && isOracleExit
                ? classes.oracleExitSnackbar
                : snackbarPosition.includes("bottom")
                ? classes.bottomSnackbar
                : {},
            action: (
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={() => closeSnackbar(snackbarKey)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            )
          }
        );
        setLoading(false);
        handleData(
          createConfiguration.configId,
          modelResponse,
          textName,
          transactionID,
          productCategory
        );
      }
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
      if (localStorage.getItem("isDebug") === "true") {
        if (graphQLErrors) {
          console.log(graphQLErrors);
        }
        if (networkError) {
          console.log(networkError);
        }
      }
    }
  });

  const handleChangeInput = e => {
    setTextName(e.target.value);
  };

  useEffect(() => {
    dispatch({
      type: SET_MODEL_ID,
      payload: modelId
    });
  }, [modelId]);

  const handleOnClickReconfigure = (isShowConfigurator = true) => {
    setLoader(true);
    setHasUpload(true);
    setShowConfigurator(
      isShowConfigurator !== undefined ? isShowConfigurator : true
    );
    const formattedBomList = bomList.map(bom => bom.partno);
    const input = {
      variables: {
        userEmail,
        countryCode: countryCode,
        regionCode: region
      }
    };
    isShowConfigurator && invokeRskuUserAccess(input);
    const bomQty =
      bomList.map(item => {
        return {
          productNumber: item.partno,
          quantity: item.quantity
        };
      }) || [];
    uploadConfigApiRestoration({
      variables: {
        filter: {
          modelId: modelId,
          country: countryCode,
          region: region,
          language: locale,
          mDCPOrgID: mDCPOrgID ? mDCPOrgID : "99",
          resellerId: resellerID,
          userType: userType,
          productType: productCategory,
          shipmentDate: "2021-03-21",
          originatingAsset: "OCIQ",
          routeToMarket: routeToMarket ? routeToMarket : "Direct",
          marketProgramType: "",
          marketProgramName: "",
          bom: formattedBomList,
          bomQty: bomQty
        }
      }
    });

    batch(() => {
      dispatch({
        type: "SET_PRODUCT_REGION_AND_COUNTRY",
        payload: {
          productCountryCode: countryCode,
          productRegionCode: region
        }
      });
      dispatch({
        type: "IS_UPLOAD_CONFIG",
        payload: true
      });
      dispatch({
        type: "SET_KMAT_ID",
        payload: modelId
      });
    });
  };
  const handleOnCLickAddToQuote = () => {
    handleOnClickReconfigure(false);
  };
  const handleStartOver = () => {
    window.location.reload();
  };

  const configContainer = () => {
    return (
      <ConfiguratorContainer
        config={modelResponse}
        proCategory={productCategory}
        categoryFromAPI={categoryFromAPI}
      />
    );
  };

  React.useEffect(() => {
    const timeOutIds = setTimeout(
      () =>
        dispatch({
          type: SET_CONFIG_NAME,
          payload: textName
        }),
      500
    );
    return () => clearTimeout(timeOutIds);
  }, [textName]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.root}>
        <UploadConfigBOM
          setHasUpload={setHasUpload}
          loading={loading}
          hasUpload={hasUpload}
          setLoading={setLoading}
          setModelId={setModelId}
          setRegion={setRegion}
        ></UploadConfigBOM>

        {!hasUpload ? (
          currentStep === 0 ? (
            <div className={classes.uploadsku}>
              <UploadConfigSku
                setProgressValue={setProgressValue}
                setLoading={setLoading}
                setHasUpload={setHasUpload}
                setModelId={setModelId}
                setRegion={setRegion}
                loading={loading}
              />
            </div>
          ) : (
            <Fragment>
              <Grid container className={classes.gridStyle}>
                {productCategory !== "print" &&
                  bomList.some(item => item.quantity > 1) && (
                    <Typography
                      style={{
                        color: "red",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        padding: "10px",
                        backgroundColor: "yellow"
                      }}
                    >
                      {t("common:conflicts.text6")}
                    </Typography>
                  )}
                <Grid className={classes.gridChildrenOneStyle}>
                  <Typography className={classes.ConfigurationName}>
                    {t("common:productSelector.label.configName")}
                  </Typography>
                  <input
                    type="text"
                    maxLength="100"
                    className={classes.inputbox}
                    value={textName}
                    onChange={handleChangeInput}
                  />
                  <Typography
                    className={classes.ConfigurationLine}
                  ></Typography>
                </Grid>
                <Grid className={classes.gridChildrenTwoStyle}>
                  {!isPoly && modelId && !isInValidTechAv && (
                    <Grid>
                      <Button
                        color="primary"
                        variant="outlined"
                        className={classes.button}
                        onClick={handleOnClickReconfigure}
                      >
                        {t("common:productSelector.button.reConfigure")}
                      </Button>
                    </Grid>
                  )}
                  {!isPoly &&
                  isAddToQuoteBtnVisible &&
                  (productCategory === "print" ||
                    bomList.every(item => item.quantity <= 1)) &&
                  (!checkGaDateWithinRange(getMaxGaDateFromItems(bomList)) ||
                    checkAllGaDateBeforeSystemDate(bomList)) ? (
                    <Grid>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={handleOnCLickAddToQuote}
                      >
                        {t("common:searchProduct.addToQuote")}
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {(!modelId || isInValidTechAv || isPoly) && (
                    <Grid>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        size="medium"
                        onClick={handleStartOver}
                      >
                        {t("common:productSelector.button.startOver")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <UploadConfigSkuTable />
            </Fragment>
          )
        ) : (
          ""
        )}
        <FullscreenModal isOpen={isConfigContainerOpen}>
          {configContainer()}
        </FullscreenModal>
      </div>
    </>
  );
};

export default withSnackbar(UploadConfigBody);

import React from "react";

export const ExternalProps = React.createContext();

export const ExternalPropsProvider = (props) => {

    const setCurrency = (curr) => {
      setState({...state, currencyCode: curr})
    }
    
    const setProductCategory = (val) => {
      setState({...state, productCategory: val})
    }

    const initState = {
        ...props.value,
        setCurrency: setCurrency,
        setProductCategory: setProductCategory,
        channelRegion: props.value?.channelRegion || props.value?.configRegion
    }
  
    const [state, setState] = React.useState(initState)
  
    return (
      <ExternalProps.Provider value={state}>
        {props.children}
      </ExternalProps.Provider>
    )
}
  export default ExternalProps;

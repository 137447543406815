/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BOM_SELECTION, UPDATE_CHOICE_EXPAND } from "../actions/Types";
import { ExternalProps } from "../contexts/externalPropsContext";
import useIsInViewport from "../hooks/useIsInViewport";
import {
  bomThreeInfoMessage,
  calculateDays,
  getStorageValue,
  icConflictsFilter,
  isChaidExpanded,
  obsoleteDateFormat,
  transformDateFormat,
  updateExpandAllChoicePayload,
  updateIframeHeight
} from "../lib/common/util";
import { getExpandState, isConflictsExist } from "../services/common/utils";
import ChaidSingleConflicts from "./ChaidSingleConflicts";
import ItemSingleRadio from "./ItemSingleRadio";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important",
    fontSize: theme.typography.pxToRem(12),
    "& .Mui-expanded": {
      marginTop: 0
    }
  },
  heading: {
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: 400,
    padding: "8px 0px 0px 0px",
    "&.MuiTypography-root": {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  secondaryHeading: {
    "&.MuiTypography-root": {
      fontSize: theme.typography.pxToRem(16),
      color: "#000000",
      fontWeight: 300,
      padding: "8px 0px 0px 18px"
    }
  },
  currencyStyle: {
    fontWeight: "bold",
    fontSize: "12px"
  },
  captionStyle: {
    fontSize: "12px",
    fontWeight: "normal",
    paddingTop: "3px"
  },
  expanded: {
    "&$expanded": {
      margin: "1px 0"
    }
  },
  choiceLevelErrmsgCls: {
    backgroundColor: "red",
    color: "white",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "grid !important"
  },
  choiceLevelMarginTop: {
    marginTop: "0px !important"
  },
  accordionDetails: {
    width: "100%",
    boxSizing: "border-box",
    marginTop: "-15px"
  },
  warningStyle: {
    backgroundColor: "#ffbf00",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  warningErrorStyle: {
    backgroundColor: "#FD0032",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    padding: "8px 16px !important"
  },
  choiceBannerStyles: {
    backgroundColor: "#0096D6",
    color: "#FFFFFF",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    padding: "8px 16px"
  },
  banner: {
    backgroundColor: "#0096D6",
    color: "#FFFFFF",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "grid !important"
  }
}));

function ChaidSingle({
  chaid,
  model,
  handleItemChange,
  handleQuantityChange,
  errorInParent = false
}) {
  //setting margins based on expand collapse state

  const classes = useStyles();
  const dispatch = useDispatch();
  const { isSKUModule = false } = React.useContext(ExternalProps);

  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );

  const bomSelected = useSelector(state => state.configFilterData.bomSelected);
  const errorData = useSelector(state => state.configFilterData.errorData);

  const [expanded, setExpanded] = React.useState(false);
  const configFilterData = useSelector(state => state.configFilterData);
  // const isExpandAll = getExpandState(configFilterData).expandValue || false;

  const isChaidExpand = useSelector(
    state => state.configFilterData.isChaidExpand
  );

  const { isVerticalStepper, expandValue } = getExpandState(configFilterData);
  const [elementRef, isInViewport] = useIsInViewport(
    {
      root: null,
      rootMargin: "0px",
      threshold: expanded ? 0.1 : 0.4
    },
    isVerticalStepper
  );
  const isExpandAll =
    typeof expandValue === "boolean" ? expandValue : isVerticalStepper;

  const handleChange = e => {
    e.preventDefault();
    if (isExpandAll) {
      dispatch({
        type: "SET_CHAID_EXPAND_COLLAPSE",
        payload: {
          ...isChaidExpand,
          [defaultConfigStepSlected]: {
            ...isChaidExpand[defaultConfigStepSlected],
            [chaid.chaId]: !expanded
          }
        }
      });
    }
    setExpanded(expanded => !expanded);
    const payload = updateExpandAllChoicePayload(
      expandAll,
      chaid.chaId,
      expanded,
      defaultConfigStepSlected
    );
    dispatch({
      type: UPDATE_CHOICE_EXPAND,
      payload
    });
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (bomSelected === chaid.chaId) {
      setExpanded(true);
      dispatch({ type: BOM_SELECTION, payload: "" });
    }
  }, [bomSelected]);
  //it will dispatch an action and update only the relevant choiceid with an inverse of current expand status

  const renderWarning = (val, formatEod, idx) => {
    return (
      <Box className={classes.warningStyle} padding={1} key={idx}>
        <Typography align="center" variant={"subtitle2"}>
          {`${t("common:configurator:obsolete90days")} - ${
            val.partno
          } ${formatEod}`}
        </Typography>
      </Box>
    );
  };

  const relevantTabChoice = useMemo(
    () =>
      expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      )?.choicesExpandState[chaid.chaId],
    [expandAll, defaultConfigStepSlected]
  );

  useEffect(() => {
    setExpanded(
      isChaidExpanded({
        isExpandAll,
        isChaidExpand,
        chaid: chaid.chaId,
        defaultConfigStepSlected
      })
    );
  }, [isExpandAll]);

  useEffect(() => {
    if (relevantTabChoice !== expanded) {
      const icConflicts = icConflictsFilter(
        model?.conflicts[0]?.icConflicts,
        chaid
      );
      if (
        (icConflicts && chaid.chaId === "ELECTCPQ") ||
        chaid.chaId.startsWith("CAPQ_")
      ) {
        setExpanded(true);
      } else {
        setExpanded(relevantTabChoice || false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevantTabChoice]);

  const chaidReq = chaid.required || chaid.selcon ? true : false;
  const itemQuantityRef = useRef(false);
  const timer = useRef(null);
  const [finalToggleExpandCollapse, setFinalToggleExpandCollapse] =
    useState(false);

  const handleToggle = () => {
    if (isInViewport) return true;

    if (!isInViewport && itemQuantityRef.current) return true;
    return false;
  };
  const toggleExpandCollapse = handleToggle();
  useEffect(() => {
    updateIframeHeight(false);
  }, [expanded, toggleExpandCollapse]);
  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setFinalToggleExpandCollapse(toggleExpandCollapse);
    }, 300);
  }, [toggleExpandCollapse]);
  let itemLevelError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.forEach(res =>
      errorData && errorData.name === chaid.chaId
        ? itemLevelError.push(" " + res.cause.chaDes)
        : null
    );
  const noConflictsExist =
    !isConflictsExist(model, chaid) && itemLevelError.length === 0;

  let userType = getStorageValue("userType");
  const selectedItemsPartNo = useSelector(
    state => state.configFilterData.selectedItemsPartNo
  );

  return userType?.toUpperCase() !== "PARTNER" || chaid.chaId !== "CS_C" ? (
    <Box
      className={`${classes.root} choice-container`}
      marginBottom="8px"
      key={"chaid-" + chaid.chaId}
      id={"chaid-" + chaid.chaId}
    >
      <Box>
        {chaid &&
          chaid.items &&
          chaid.items.map((val, idx) => {
            let formatEod = obsoleteDateFormat(val.esDate);
            const days = calculateDays(formatEod);
            const isDateGreater = new Date(formatEod) > new Date();
            return val.selected &&
              val.visible &&
              days <= 90 &&
              isDateGreater &&
              noConflictsExist
              ? renderWarning(
                  val,
                  transformDateFormat(val.esDate, "MM/DD/YYYY"),
                  idx
                )
              : null;
          })}

        {!isSKUModule &&
          defaultConfigStepSlected !== "configurationService" &&
          bomThreeInfoMessage(
            selectedItemsPartNo,
            chaid,
            classes.choiceBannerStyles,
            t,
            isSKUModule
          )}
      </Box>
      <Accordion
        expanded={expanded && finalToggleExpandCollapse}
        onChange={handleChange}
        ref={elementRef}
      >
        <ChaidSingleConflicts
          expanded={expanded && finalToggleExpandCollapse}
          chaid={chaid}
          errorInParent={errorInParent}
          model={model}
        />
        <AccordionDetails className={classes.accordionDetails}>
          {expanded && finalToggleExpandCollapse && (
            <ItemSingleRadio
              itemQuantityRef={itemQuantityRef}
              chaid={chaid}
              handleItemChange={handleItemChange}
              handleQuantityChange={handleQuantityChange}
              model={model}
              chaidReq={chaidReq}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  ) : (
    <div></div>
  );
}

export default ChaidSingle;

import { useLazyQuery } from "@apollo/client";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { UPLOAD_CONFIG_API } from "../../../GraphQL";
import { SET_CONFIG_NAME } from "../../../actions/Types";
import ExternalProps from "../../../contexts/externalPropsContext";
import { showAddToQuoteBtnWithGADate } from "../../../lib/common/util";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1),
    marginLeft: "6px",
    textTransform: "none"
  }
}));

const UploadConfigBOM = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.uploadConfig.activeStep);
  const [textName, setTextName] = React.useState("");
  const bomList = useSelector(state => state.uploadConfig.bomList);
  const [validate, setValidate] = useState(false);
  const { hasUpload, setHasUpload } = props;
  const { loading, setLoading } = props;
  const [error, setError] = useState();
  const configurationName = useSelector(
    state => state.uploadConfig.configurationName
  );
  const {
    countryCode = "US",
    setProductCategory,
    routeToMarket
  } = React.useContext(ExternalProps);
  useEffect(() => {
    setTextName(configurationName);
  }, [configurationName]);

  const formatBomList = [];
  bomList.map(item => {
    if (item && typeof item === "string") {
      let formatItem = item
        .match(/([A-Za-z0-9 ][\#]?)/g)
        .join("")
        .replace(/[\t\r\f\n\s]+/, ",,");
      formatBomList.push(formatItem);
    }
  });
  const handleValidate = () => {
    uploadConfigApi({
      variables: {
        filter: {
          bomList: formatBomList,
          shipmentDate: "2021-03-21",
          country: countryCode,
          region: "",
          mktProgramName: "",
          mktProgramType: "",
          routeToMarket: routeToMarket
        }
      }
    });
    dispatch({
      type: "SET_ACTIVE_STEP",
      payload: currentStep + 1
    });
    setLoading(true);
    setHasUpload(true);
  };

  const [uploadConfigApi] = useLazyQuery(UPLOAD_CONFIG_API, {
    fetchPolicy: "no-cache",
    onCompleted(response) {
      setLoading(false);
      setHasUpload(false);
      props.setModelId(
        response.uploadConfigApi?.bunitKmatResponse?.response?.kmatId
      );
      props.setRegion(response.uploadConfigApi?.configHeader?.region);
      setError(
        response.uploadConfigApi?.bomValidateResponse?.bomReponse?.error
      );
      const productLine =
        response.uploadConfigApi?.bunitKmatResponse?.response?.product_line;
      setProductCategory(
        productLine === "PY"
          ? "poly"
          : productLine === "PR"
          ? "print"
          : "compute"
      );
      const isInValidTechAv = response?.uploadConfigApi.products?.some(
        item =>
          item?.plcMessage?.includes("TechAV") &&
          ["", "N/A"].includes(item.listPrice)
      );

      response.uploadConfigApi.GAFutureDtLmt &&
        localStorage.setItem(
          "gaFutureDateLimit",
          response.uploadConfigApi.GAFutureDtLmt
        );

      localStorage.setItem("showGADate", response?.uploadConfigApi?.showGADate);
      const isValid =
        response.uploadConfigApi?.bomValidateResponse?.bomReponse?.status ===
          "BUILDABLE" &&
        showAddToQuoteBtnWithGADate(response.uploadConfigApi?.products);
      batch(() => {
        dispatch({
          type: "SET_CONFIGURATION_NAME",
          payload: response.uploadConfigApi?.configName
        });
        dispatch({
          type: "SET_BILL_OF_MATERIALS_ITEMS",
          payload: response.uploadConfigApi?.products
        });
        dispatch({
          type: "SET_ADD_TO_QUOTE_BUTTON_VISIBILITY",
          payload: isValid
        });
        dispatch({
          type: "SET_ERRORS",
          payload:
            response.uploadConfigApi?.bomValidateResponse?.bomReponse?.error
        });
        dispatch({
          type: "SET_WARNINGS",
          payload:
            response.uploadConfigApi?.bomValidateResponse?.bomReponse?.warning
        });
        dispatch({
          type: "SET_STATUS",
          payload:
            response.uploadConfigApi?.bomValidateResponse?.bomReponse?.status
        });
        dispatch({
          type: "SET_CATEGORY",
          payload:
            response.uploadConfigApi?.bunitKmatResponse?.response?.category
        });
        dispatch({
          type: "IS_INVALID_TECHAV",
          payload: isInValidTechAv
        });
      });
    },
    onError({ response }) {
      console.log("Error");
    }
  });

  useEffect(() => {
    if (bomList.length > 0) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [bomList]);
  React.useEffect(() => {
    const timeOutIds = setTimeout(
      () =>
        dispatch({
          type: SET_CONFIG_NAME,
          payload: textName
        }),
      500
    );
    return () => clearTimeout(timeOutIds);
  }, [textName]);

  return (
    <div className={classes.root}>
      {!hasUpload && !loading && (
        <div>
          <Grid container justifyContent="flex-end">
            {currentStep !== 1 ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleValidate}
                disabled={!validate}
              >
                {t("common:uploadConfig.validate")}
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        </div>
      )}
    </div>
  );
};

export default withSnackbar(UploadConfigBOM);

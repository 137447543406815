import moment from "moment";
import { dateCompare } from "../../lib/common/util";
const getHighestSequence = function (uiChoices, sectionId) {
  let highestSequence = Math.max(
    ...uiChoices
      .filter(t => t.sectionId === sectionId)
      .map(o => o.choiceSequence),
    0
  );
  return highestSequence;
};

const addQuantityForSamePartNumber = (items = [], selectedItem = {}) => {
  const index = items.findIndex(obj => obj.partNumber === selectedItem.partno);
  if (index > -1) {
    items[index] = {
      ...items[index],
      qty: items[index].qty + (selectedItem.quantity || 1),
      listPrice:
        selectedItem.listPrice *
        (items[index].qty + (selectedItem.quantity || 1)),
      netPrice:
        parseFloat(selectedItem.netPrice) *
        (items[index].qty + (selectedItem.quantity || 1))
    };
  }
  return index > -1 ? items : false;
};

const updateCarePackSelection = modelData => {
  modelData?.carePacks?.forEach(carePack => {
    modelData.Items[carePack.itemInode].selected = modelData.Items[
      carePack.itemInode
    ]
      ? true
      : false;
  });
};

export const compareDate = formatGaD => {
  if (!["", "NA"].includes(formatGaD)) {
    return moment(formatGaD).isAfter(moment());
  }
  return false;
};
export const isInvalidDate = gaDate => {
  return gaDate.includes("9999") || gaDate.includes("8888");
};

export const getBOMData = (
  modelData,
  type,
  configuration,
  skuType,
  isSKUModule
) => {
  let filteredBOMItems,
    formattedBOMItems = [];
  let grandPrice = 0;
  let choiceBOMItems = [];
  let saveBomChoices = [];
  let tabChaidsList = window.uiSpecData;
  choiceBOMItems = modelData.Chaids;
  updateCarePackSelection(modelData);
  if (modelData && modelData.Items) {
    filteredBOMItems = modelData.Items.filter(
      item =>
        item.selected === true && item.partno !== "" && item.visible === true
    );
    saveBomChoices = [];
    filteredBOMItems.forEach((selectedItem, index) => {
      const choiceObj = choiceBOMItems.find(
        choice =>
          selectedItem.inode >= choice.firstItem &&
          selectedItem.inode <= choice.lastItem
      );
      saveBomChoices = [
        ...saveBomChoices,
        {
          choiceId: choiceObj?.chaId || "",
          choiceName: choiceObj?.chaDes || "",
          selectedItem
        }
      ];
    });

    // Fill uiChoices
    let uiChoices = [];
    tabChaidsList.UISpec[0].sections
      .map(section => section)
      .forEach(section => {
        section.subsections.forEach(subsection => {
          subsection.choices.forEach(choice => {
            uiChoices.push({
              sectionId: section.id,
              sectionSequence: section.sequence,
              choiceId: choice.id,
              choiceSequence: choice.sequence,
              subSequence: subsection.sequence
            });
          });
        });
      });

    // Create sorted array of selected items
    let selectedItems = [];
    const hardwareChoice = uiChoices[0]; // assuming "hardware"
    const carePackChoice = uiChoices.find(
      chaid => chaid.sectionId === "carePacks"
    );
    const monitoringAccesoriesChoice = uiChoices.find(
      chaid => chaid.sectionId === "monitoringAccesories"
    );
    let highestSequence = getHighestSequence(uiChoices, "hardware");
    let highestCPSequence = getHighestSequence(uiChoices, "carePacks");
    let highestMASequence = getHighestSequence(
      uiChoices,
      "monitoringAccesories"
    );
    saveBomChoices.forEach(item => {
      const uiChoice = uiChoices.find(c => c.choiceId === item.choiceId);
      if (uiChoice) {
        selectedItems.push({
          ...uiChoice,
          selectedItem: item.selectedItem,
          choiceName: item.choiceName
        });
      } else if (item.choiceId.startsWith("CAPQ_")) {
        selectedItems.push({
          sectionId: "carePacks",
          sectionSequence: carePackChoice.sectionSequence,
          choiceId: item.choiceId,
          choiceSequence: ++highestCPSequence,
          selectedItem: item.selectedItem,
          choiceName: item.choiceName
        });
      } else if (item.choiceId.startsWith("ASCM_")) {
        selectedItems.push({
          sectionId: "monitoringAccesories",
          sectionSequence: monitoringAccesoriesChoice.sectionSequence,
          choiceId: item.choiceId,
          choiceSequence: ++highestMASequence,
          selectedItem: item.selectedItem,
          choiceName: item.choiceName
        });
      } else {
        selectedItems.push({
          sectionId: "hardware",
          sectionSequence: hardwareChoice.sectionSequence,
          choiceId: item.choiceId,
          choiceSequence: ++highestSequence,
          selectedItem: item.selectedItem,
          choiceName: item.choiceName
        });
      }
    });
    const findParentChoiceId = choice =>
      choiceBOMItems.find(item => item.chaId === choice.choiceId)
        ?.carePackParentChoiceID;
    selectedItems
      .sort(
        // Sort by section->choice->item
        (a, b) =>
          a.sectionSequence - b.sectionSequence ||
          a.subSequence - b.subSequence ||
          a.choiceSequence - b.choiceSequence
      )
      .sort((a, b) => {
        if (
          (findParentChoiceId(a) === "ASCM_POLYMON" &&
            findParentChoiceId(b) === "ASCM_POLYACC" &&
            a.sectionId === "carePacks" &&
            b.sectionId === "carePacks") ||
          (a.choiceId === "ELECTCPQ" &&
            a.selectedItem.priority <= 5 &&
            b.choiceId === "ELECTCPQ" &&
            b.selectedItem.priority) ||
          (a.choiceId.startsWith("CAPQ_") &&
            !a.choiceId.startsWith("CAPQ_P") &&
            a.selectedItem.priority <= 5 &&
            b.choiceId.startsWith("CAPQ_") &&
            !b.choiceId.startsWith("CAPQ_P") &&
            b.selectedItem.priority) ||
          (a.choiceId.startsWith("CAPQ_P") &&
            a.selectedItem.priority <= 5 &&
            b.choiceId.startsWith("CAPQ_P") &&
            b.selectedItem.priority) ||
          (a.choiceId === "BUNIT" && b.choiceId === "RCOO")
        )
          return -1;
        return 0;
      })
      .forEach(item => {
        // Iterate and fill formattedBOMItems array
        const selectedItem = item.selectedItem;
        let formattedListPrice =
          selectedItem.listPrice === ""
            ? 0
            : parseFloat(selectedItem.listPrice);
        let formattedNetPrice =
          selectedItem.netPrice === "" ? 0 : parseFloat(selectedItem.netPrice);
        if (selectedItem.partno.includes("#")) {
          if (type && type === "exportToExcel") {
            formattedBOMItems.push({
              partNumber: selectedItem.partno,
              description: selectedItem.partdes,
              qty: selectedItem.quantity !== 0 ? selectedItem.quantity : 1,
              listPrice:
                selectedItem.quantity === 0
                  ? formattedListPrice
                  : selectedItem.quantity * formattedListPrice,
              netPrice:
                selectedItem.quantity === 0
                  ? formattedNetPrice
                  : selectedItem.quantity * formattedNetPrice,

              choiceId: item.choiceId,
              inode: selectedItem.inode,
              sectionId: item.sectionId,
              bndlBomType: selectedItem.bndlBomType,
              choiceName: item.choiceName,
              isFutureItem: compareDate(selectedItem.gaDate)
            });
          } else {
            formattedBOMItems.push({
              partNumber: selectedItem.partno.split("#")[0],
              description: selectedItem.partdes,
              qty: selectedItem.quantity !== 0 ? selectedItem.quantity : 1,
              listPrice:
                selectedItem.quantity === 0
                  ? formattedListPrice
                  : selectedItem.quantity * formattedListPrice,
              netPrice:
                selectedItem.quantity === 0
                  ? formattedNetPrice
                  : selectedItem.quantity * formattedNetPrice,
              choiceId: item.choiceId,
              inode: selectedItem.inode,
              sectionId: item.sectionId,
              bndlBomType: selectedItem.bndlBomType,
              choiceName: item.choiceName,
              isFutureItem: compareDate(selectedItem.gaDate)
            });
            formattedBOMItems.push({
              partNumber: selectedItem.partno,
              description: selectedItem.partdes,
              choiceId: item.choiceId,
              qty: selectedItem.quantity !== 0 ? selectedItem.quantity : 1,
              listPrice: 0.0,
              netPrice: 0.0,
              inode: selectedItem.inode,
              sectionId: item.sectionId,
              bndlBomType: selectedItem.bndlBomType,
              choiceName: item.choiceName,
              isFutureItem: compareDate(selectedItem.gaDate)
            });
          }
        } else {
          const updatedItems = addQuantityForSamePartNumber(
            formattedBOMItems,
            selectedItem
          );
          if (updatedItems) {
            formattedBOMItems = [...updatedItems];
          } else {
            formattedBOMItems.push({
              partNumber: selectedItem.partno,
              description: selectedItem.partdes,
              qty: selectedItem.quantity !== 0 ? selectedItem.quantity : 1,
              listPrice:
                selectedItem.quantity === 0
                  ? formattedListPrice
                  : selectedItem.quantity * formattedListPrice,
              netPrice:
                selectedItem.quantity === 0
                  ? formattedNetPrice
                  : selectedItem.quantity * formattedNetPrice,
              inode: selectedItem.inode,
              choiceId: item.choiceId,
              sectionId: item.sectionId,
              bndlBomType: selectedItem.bndlBomType,
              choiceName: item.choiceName,
              isFutureItem: compareDate(selectedItem.gaDate)
            });
          }
        }
      });
  }

  formattedBOMItems = isSKUModule
    ? getSkuModlueBomList(skuType, formattedBOMItems)
    : formattedBOMItems;
  grandPrice = formattedBOMItems.reduce((sum, f) => sum + f.listPrice, 0);
  return {
    formattedBOMItems,
    grandPrice
  };
};
export const getSkuModlueBomList = (skuType, formattedBOMItems) => {
  let updatedBomList;
  const skuSections =
    skuType === "GSKU"
      ? ["hardware", "carePacks", "otherServices", "deploymentServices"]
      : [
          "hardware",
          "carePacks",
          "configurationService",
          "deploymentServices",
          "otherServices"
        ];

  updatedBomList = formattedBOMItems?.length
    ? formattedBOMItems.reduce((bomInfo, product) => {
        if (skuSections.includes(product.sectionId)) {
          bomInfo.push(product);
        }
        return bomInfo;
      }, [])
    : [];
  return updatedBomList;
};

export const getShowBOMSectionFlag = (modelData, visibility) => {
  if (typeof modelData === "undefined" || Object.keys(modelData).length === 0) {
    return false;
  } else {
    const { formattedBOMItems } = getConfigBom(modelData);
    let showBOMSectionFlag =
      ((modelData.isValid &&
        modelData.conflicts &&
        modelData.conflicts[0] &&
        Object.values(modelData.conflicts[0]).every(
          item => Object.keys(item).length === 0
        )) ||
        (!modelData.isValid &&
          (!modelData.conflicts ||
            (modelData.conflicts &&
              modelData.conflicts[0] &&
              modelData.conflicts[0].choiceConflicts.length === 0 &&
              modelData.conflicts[0].counterConflicts.length === 0 &&
              modelData.conflicts[0].icConflicts.length === 0 &&
              modelData.conflicts[0].gaDateConflicts.length === 0 &&
              modelData.conflicts[0].itemConflictsList.length === 0 &&
              modelData.conflicts[0].plcConflicts.length === 0 &&
              modelData.conflicts[0].plcConflicts.filter(
                item =>
                  modelData.Chaids.filter(
                    items => items.chaId === item.chaId
                  )[0].required
              ).length === 0)))) &&
      formattedBOMItems &&
      formattedBOMItems.length > 0;
    return showBOMSectionFlag;
  }
};

export const getConfigBom = (modelData, filterParams) => {
  let filteredBOMItems = [];
  let choiceBOMItems = [];
  let saveBomChoices = [];
  let formattedBOMItems = [];
  let maxGaDate = "";
  let tabChaidsList = window.uiSpecData;
  choiceBOMItems = modelData.Chaids;
  let haveFutureItem = false;
  updateCarePackSelection(modelData);
  if (modelData && modelData.Items) {
    filteredBOMItems = modelData.Items.filter(
      item =>
        item.selected === true && item.partno !== "" && item.visible === true
    );
    saveBomChoices = [];
    filteredBOMItems.forEach((selectedItem, index) => {
      const choiceObj = choiceBOMItems.find(
        choice =>
          selectedItem.inode >= choice.firstItem &&
          selectedItem.inode <= choice.lastItem
      );
      saveBomChoices = [
        ...saveBomChoices,
        {
          choiceId: choiceObj?.chaId || "",
          choiceName: choiceObj?.chaDes || "",
          selectedItem
        }
      ];
    });
    // Fill uiChoices
    let uiChoices = [];
    tabChaidsList.UISpec[0].sections
      .map(section => section)
      .forEach(section => {
        section.subsections.forEach(subsection => {
          subsection.choices.forEach(choice => {
            uiChoices.push({
              sectionId: section.id,
              sectionSequence: section.sequence,
              choiceId: choice.id,
              choiceSequence: choice.sequence,
              subSequence: subsection.sequence
            });
          });
        });
      });

    // Create sorted array of selected items
    let selectedItems = [];
    const hardwareChoice = uiChoices[0]; // assuming "hardware"
    const carePackChoice = uiChoices.find(
      chaid => chaid.sectionId === "carePacks"
    );
    const monitoringAccesoriesChoice = uiChoices.find(
      chaid => chaid.sectionId === "monitoringAccesories"
    );
    let highestSequence = getHighestSequence(uiChoices, "hardware");
    let highestCPSequence = getHighestSequence(uiChoices, "carePacks");
    let highestMASequence = getHighestSequence(
      uiChoices,
      "monitoringAccesories"
    );
    saveBomChoices.forEach(item => {
      const uiChoice = uiChoices.find(c => c.choiceId === item.choiceId);
      if (uiChoice) {
        selectedItems.push({
          ...uiChoice,
          selectedItem: item.selectedItem,
          choiceId: item.choiceId,
          choiceName: item.choiceName
        });
      } else if (item.choiceId.startsWith("CAPQ_")) {
        selectedItems.push({
          sectionId: "carePacks",
          sectionSequence: carePackChoice.sectionSequence,
          choiceId: item.choiceId,
          choiceSequence: ++highestCPSequence,
          selectedItem: item.selectedItem,
          choiceName: item.choiceName
        });
      } else if (item.choiceId.startsWith("ASCM_")) {
        selectedItems.push({
          sectionId: "monitoringAccesories",
          sectionSequence: monitoringAccesoriesChoice.sectionSequence,
          choiceId: item.choiceId,
          choiceSequence: ++highestMASequence,
          selectedItem: item.selectedItem,
          choiceName: item.choiceName
        });
      } else {
        selectedItems.push({
          sectionId: "hardware",
          selectedItem: item.selectedItem,
          sectionSequence: hardwareChoice.sectionSequence,
          choiceId: item.choiceId,
          choiceName: item.choiceName,
          choiceSequence: ++highestSequence
        });
      }
    });
    filterParams &&
      selectedItems.forEach(item => {
        if (dateCompare(item.selectedItem?.gaDate)) {
          maxGaDate =
            maxGaDate && maxGaDate > item.selectedItem?.gaDate
              ? maxGaDate
              : item.selectedItem?.gaDate;
          haveFutureItem = !isInvalidDate(item.selectedItem?.gaDate);
          return true;
        } else {
          return false;
        }
      });
    const findParentChoiceId = choice =>
      choiceBOMItems.find(item => item.chaId === choice.choiceId)
        ?.carePackParentChoiceID;
    // Sort by section->choice->item
    selectedItems = selectedItems
      .sort(
        (a, b) =>
          a.sectionSequence - b.sectionSequence ||
          a.subSequence - b.subSequence ||
          a.choiceSequence - b.choiceSequence
      )
      .sort((a, b) => {
        if (
          (findParentChoiceId(a) === "ASCM_POLYMON" &&
            findParentChoiceId(b) === "ASCM_POLYACC" &&
            a.sectionId === "carePacks" &&
            b.sectionId === "carePacks") ||
          (a.choiceId === "ELECTCPQ" &&
            a.selectedItem.priority <= 5 &&
            b.choiceId === "ELECTCPQ" &&
            b.selectedItem.priority) ||
          (a.choiceId.startsWith("CAPQ_") &&
            !a.choiceId.startsWith("CAPQ_P") &&
            a.selectedItem.priority <= 5 &&
            b.choiceId.startsWith("CAPQ_") &&
            !b.choiceId.startsWith("CAPQ_P") &&
            b.selectedItem.priority) ||
          (a.choiceId.startsWith("CAPQ_P") &&
            a.selectedItem.priority <= 5 &&
            b.choiceId.startsWith("CAPQ_P") &&
            b.selectedItem.priority) ||
          (a.choiceId === "BUNIT" && b.choiceId === "RCOO")
        )
          return -1;
        return 0;
      });
    let lineItemIncremental = 1;
    // Iterate and fill formattedBOMItems array
    selectedItems.forEach((item, index) => {
      const selectedItem = item.selectedItem;
      if (selectedItem.partno.includes("#")) {
        formattedPushFunc("LPN");
        formattedPushFunc("NLPN");
      } else {
        formattedPushFunc("NLPN");
      }
      function formattedPushFunc(pnval) {
        formattedBOMItems.push({
          lineItemId: lineItemIncremental++,
          materialNumber:
            pnval === "LPN"
              ? selectedItem.partno.split("#")[0]
              : selectedItem.partno || selectedItem.valueId,
          startingPointFlag: selectedItem.localSelected ? "N" : "Y",
          parentLineItemId: 1,
          qty: selectedItem.quantity !== 0 ? selectedItem.quantity : 1,
          choiceId: item.choiceId || "N/A",
          choiceName: item.choiceName || "N/A",
          materialDescription: selectedItem.partdes,
          delFl: "N"
        });
      }
    });
  }
  return { formattedBOMItems, haveFutureItem, maxGaDate };
};

export const carePackRecommendedChaId = "99999";
export const carePackAdditionalChaId = "99998";
export const otherCarePacksChaId = "SSS99";
export const adpCarepackChaId = "ACPS99";
export const supportExtensionCarepackChaId = "SE99";
export const customerSpecificServicesChaId = "CSS99";
export const customerSpecificChaIds = [
  "ACSS",
  "ACSS_CS",
  "ACSS_CGS",
  "ACSS_BS"
];
export const customCPChaids = "HP Care Pack Services";
export const getCarePackLabel = (t, labelID, chaid) => {
  const otherCarePacksLabelObj = {
    recommendedLabel: t("common:stepperTabs.carePacksSrc.topRecommend"),
    addLabel: t("common:stepperTabs.carePacksSrc.additionalCarePack"),
    mandaCarePackLabel: t("common:stepperTabs.carePacksSrc.otherCPSF")
  };
  const polyCarePacksLabelObj = {
    recommendedLabel: t("common:stepperTabs.carePacksSrc.polyTRPS"),
    addLabel: t("common:stepperTabs.carePacksSrc.polyAPS"),
    mandaCarePackLabel: t("common:stepperTabs.carePacksSrc.polySF")
  };
  const isPolyCarePack = chaid?.chaId.startsWith("CAPQ_P");
  return isPolyCarePack
    ? polyCarePacksLabelObj[labelID]
    : otherCarePacksLabelObj[labelID];
};

export const findParentChoiceId = (modelData, id) => {
  return modelData?.Chaids?.find(item => item.chaId === id)
    ?.carePackParentChoiceID;
};

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import ClipboardField from "../../../lib/common/ClipboardField";
import ImageSwiper from "../../ImageSwiper";

const useStyles = makeStyles(theme => {
  return createStyles({
    content: {
      padding: theme.spacing(0),
      overflowX: "hidden"
    },
    title: {
      padding: theme.spacing(1, 2)
    },
    details: {
      padding: theme.spacing(3)
    }
  });
});

const ProductDescription = props => {
  const { handleClose, sku, name, image } = props;
  const classes = useStyles();

  return (
    <Dialog open={true} onClose={handleClose} fullScreen>
      <DialogTitle className={classes.title}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={5}></Grid>
          <Grid item xs={4} zeroMinWidth>
            <Typography variant="h6" noWrap component="div" align="start">
              Detail Description
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign="right">
              <IconButton onClick={handleClose} size="large">
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <div className={classes.details}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} zeroMinWidth>
              <ImageSwiper images={image} />
            </Grid>
            <Grid item xs={12} md={7} zeroMinWidth>
              <Typography variant="h6">{name}</Typography>
              <Typography
                variant={"body2"}
                component={"span"}
                color="textSecondary"
                noWrap
              >
                {sku}
                <ClipboardField text={sku} />
              </Typography>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProductDescription;

import { useLazyQuery } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_SEARCH_CONFIG,
  GET_BTO_SKU,
  GET_PREAPPROVED_CONFIGS
} from "../../../GraphQL";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import { getPlaceholder, validateSkuIDSearch } from "../../../lib/common/util";
import ConfigResultsTable from "./ConfigResultsTable";
import {
  BTO_SKU,
  SET_DISABLE,
  SET_RESULTS_LIST,
  SET_SEARCH_KEY
} from "./Constants";
const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    alignItems: "left",
    justifyContent: "left",
    paddingTop: "10px"
  },
  container: {
    maxHeight: 440
  },
  topPadding: {
    paddingTop: "20px"
  },
  searchInput: {
    marginTop: "8px"
  },
  input: {
    padding: "9px !important",
    border: "none !important"
  },
  listItem: {
    marginTop: "8px",
    "& button span": {
      textTransform: "capitalize !important"
    }
  },
  searchButtonStyle: {
    marginLeft: "10px"
  },
  button: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      width: 100,
      "& .MuiButton-label": {
        overflow: "hidden",
        "text-overflow": "ellipsis"
      }
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    position: "relative",
    display: "inline-block"
  },
  radioLabel: {
    fontSize: "12px !important"
  }
}));

// Inspired by blueprintjs
function StyledRadio(props) {
  return <Radio color="primary" size="small" {...props} />;
}
const SearchConfigs = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState("None");
  const [placeholderName, setPlaceholderName] = useState(
    t("common:productSelector.textMessage.pleaseEnterNone")
  );
  const [isTextFieldDisable, setIsTextFieldDisable] = useState(true);
  const [isSearchDisable, setIsSearchDisable] = useState(true);
  const [isShowLoading, setShowLoading] = useState(false);
  const [isSearchAllLoading, setSearchAllLoading] = React.useState(false);
  const [isSearchMyConfigLoading, setSearchMyConfigLoading] =
    React.useState(false);
  const {
    countryCode = "US",
    resellerID,
    mDCPOrgID = "99",
    pricingGeo = "US",
    currencyCode = "USD",
    incoterms = "DDP",
    routeToMarket,
    userType,
    isStandaloneAccess = false,
    userEmail,
    isDisplayProdCountry,
    productLine,
    companyId,
    purchaseAgreement,
    isListOfPLForPANumber = false,
    visibility = {},
    locationID,
    brID,
    isPartnerPortalFlag,
    channelRegion
  } = React.useContext(ExternalProps);
  const { showManageMyConfigButton = false } = visibility;
  const dispatch = useDispatch();
  const showConfigResults = useSelector(
    state => state.productSelector.showConfigResults
  );
  const showPreApprovedConfigurationButton = useSelector(
    state => state.configFilterData.showPreApprovedConfigButton
  );
  const searchValue = useSelector(state => state.productSelector.searchValue);
  const isDisable = useSelector(state => state.productSelector.isDisable);
  const country = useSelector(state => state.productSelector.country);
  const { productRegionCode } = useSelector(state => state.rskuReducer);
  const { showSearchBto, countryRegion } = useSelector(
    state => state.productSelector
  );

  let textInput = useRef(null);

  const handleChange = event => {
    setValue(event.target.value);
    textInput.current.select();
    if (event.target.value === "None") {
      dispatch({ type: SET_SEARCH_KEY, payload: "" });
      dispatch({ type: SET_DISABLE, payload: true });
      setIsTextFieldDisable(true);
      setIsSearchDisable(true);
    } else {
      dispatch({ type: SET_DISABLE, payload: false });
      setIsTextFieldDisable(false);
    }
    setPlaceholderName(getPlaceholder(t, event.target.value));
  };

  const handleSearchChange = event => {
    const searchvalue = event.target.value;
    // setSearchText(value);
    if (value === "configID") {
      dispatch({
        type: SET_SEARCH_KEY,
        payload: isNaN(searchvalue) ? searchvalue : searchvalue.trim()
      });
    } else {
      dispatch({
        type: SET_SEARCH_KEY,
        payload: searchvalue
      });
    }

    if (searchvalue !== "") {
      dispatch({ type: SET_DISABLE, payload: false });
      setIsSearchDisable(false);
    } else {
      setIsSearchDisable(true);
      dispatch({ type: SET_DISABLE, payload: true });
    }
  };
  const { setShowDefaultConfig, proCategory } = props;
  const resetCategorySeries = () => {
    dispatch({
      type: "SET_RESET_SELECTED"
    });
  };

  const setShowConfigResults = show => {
    dispatch({
      type: "SHOW_CONFIG_RESULTS",
      payload: show
    });
  };
  const resetTablePage = () => {
    dispatch({
      type: "SET_RESET_PAGE",
      payload: 0
    });
  };

  const [getPreApprovedConfigurations] = useLazyQuery(GET_PREAPPROVED_CONFIGS, {
    fetchPolicy: "no-cache",
    onCompleted({ preApprovedConfigs }) {
      setShowLoading(false);
      dispatch({
        type: SET_RESULTS_LIST,
        payload: preApprovedConfigs?.data?.searchAllConfigurations
      });
    },
    onError({ graphQLErrors, networkError }) {
      setShowLoading(false);
      setShowConfigResults(true);
    }
  });

  const [getAllSearch] = useLazyQuery(GET_ALL_SEARCH_CONFIG, {
    fetchPolicy: "no-cache",
    onCompleted({ searchSavedConfig }) {
      setShowLoading(false);
      setSearchAllLoading(false);
      dispatch({
        type: SET_RESULTS_LIST,
        payload: searchSavedConfig.data
          ? searchSavedConfig.data.searchAllConfigurations
          : null
      });
    },
    onError({ graphQLErrors, networkError }) {
      setSearchAllLoading(false);
      setShowLoading(false);
      setShowConfigResults(true);
      dispatch({
        type: SET_RESULTS_LIST,
        payload: []
      });
    }
  });

  const handleSkuIdSearch = value => {
    const region = productRegionCode;
    const { isValidSkuId, validSkuVersion = "valid" } = validateSkuIDSearch(
      value,
      region
    );

    if (isValidSkuId) {
      btoSkuSearch({
        variables: {
          rskuMaterialNumber: searchValue.toUpperCase()
        }
      });
    } else {
      dispatch({
        type: "SET_BOM_LIST",
        payload: { getRSKUMaterialNumber: [], countryCode }
      });
      dispatch({
        type: "SEARCH_SKU_ID_MESSAGE",
        payload: `SKU not meeting criteria. Please use an ${validSkuVersion} type of SKU.`
      });
      setShowLoading(false);
      setSearchAllLoading(false);
    }
  };

  const [btoSkuSearch] = useLazyQuery(GET_BTO_SKU, {
    fetchPolicy: "no-cache",
    onCompleted({ getRSKUMaterialNumber }) {
      setShowLoading(false);
      setSearchAllLoading(false);
      batch(() => {
        dispatch({
          type: "SET_BOM_LIST",
          payload: { getRSKUMaterialNumber, countryCode }
        });
        dispatch({
          type: "SET_CATEGORY",
          payload: getRSKUMaterialNumber?.[0]?.category
        });
        dispatch({
          type: "SEARCH_SKU_ID_MESSAGE",
          payload: ""
        });
      });
    },
    onError({ graphQLErrors, networkError }) {
      setSearchAllLoading(false);
      setShowLoading(false);
      setShowConfigResults(true);
      dispatch({
        type: "SET_BOM_LIST",
        payload: { getRSKUMaterialNumber: [], countryCode }
      });
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (localStorage.getItem("isDebug") === "true") {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
        });
      }
      if (networkError) {
        console.log(networkError);
      }
    }
  });

  const handleClick = action => {
    setShowDefaultConfig(false);
    resetCategorySeries();
    setShowConfigResults(true);
    resetTablePage();
    batch(() => {
      dispatch({
        type: "SET_SEARCH_TYPE",
        payload: value
      });
      dispatch({
        type: "SEARCH_SKU_ID_MESSAGE",
        payload: ""
      });
      dispatch({
        type: "SET_SHOWSERIES",
        payload: { showSeries: false }
      });
    });
    if (action !== "MANAGE_CONFIG") setShowLoading(true);
    if (action === "ALL_SEARCH") {
      setSearchAllLoading(true);
    }
    if (action === "MANAGE_CONFIG") {
      // displayNotistack.success(t("common:snackbar.redirecting"), {
      //   autoHideDuration: 10000
      // });
      // history.push("/managemyconfig");
      dispatch({
        type: "NAV_MANAGE_MY_CONFIG",
        payload: { loadManageMyConfig: true }
      });
    } else if (action === "PRE_APPROVE_CONFIG") {
      getPreApprovedConfigurations({
        variables: {
          filter: {
            originatingAsset: "OCIQ",
            countryCode: isStandaloneAccess ? country : countryCode, //eg. "SG"
            priceGeo: isStandaloneAccess ? country : pricingGeo, //eg. "SG"
            currencyCode: isStandaloneAccess ? "" : currencyCode, //eg. "SGD"
            incoterms: isStandaloneAccess ? "" : incoterms, //eg. "DDU"
            routeToMarket: routeToMarket, //eg. "direct",
            mDCPOrgID: mDCPOrgID, //eg. "702308887"
            priceListType: "",
            offerSource: "Corona_IQ",
            responseFrom: "",
            resellerID,
            opsiId: "",
            userId: userEmail,
            userEmail: userEmail
          }
        }
      });
    } else if (action === "MY_CONFIG") {
      setSearchMyConfigLoading(false);
      getAllSearch({
        variables: {
          searchConfigRequest: {
            searchValue: searchValue,
            searchType:
              value === "configID"
                ? "ID"
                : value === "configName"
                ? "NAME"
                : "EMAIL",
            searchActionScope: "SearchMyConfig",
            countryCode: (isStandaloneAccess ? country : countryCode) || "US",
            currencyCode: isStandaloneAccess ? "" : currencyCode,
            hideServicesForPartners: false,
            incoTerms: isStandaloneAccess ? "" : incoterms,
            isDisplayProdCountry: isDisplayProdCountry,
            isManageConfig: true,
            listOfPLForPANumber: isListOfPLForPANumber
              ? productLine
                ? productLine
                : ""
              : "",
            locationID: locationID || "",
            mDCPOrgID: "",
            pricingGeo: isStandaloneAccess
              ? country
              : pricingGeo
              ? pricingGeo
              : "",
            prtnrPortalFlag: isPartnerPortalFlag || false,
            resellerID: resellerID || "",
            routeToMarket: routeToMarket,
            transactionID: "manageConfig1617702690388",
            userCompanyID: companyId,
            userEmail: userEmail,
            userType: userType,
            isStandaloneAccess: isStandaloneAccess,
            purchaseAgreement: purchaseAgreement ? purchaseAgreement : "",
            productCategory: proCategory,
            brID: brID || companyId,
            storeFront: "IQ"
          }
        }
      });
    } else {
      value === BTO_SKU
        ? handleSkuIdSearch(searchValue)
        : getAllSearch({
            variables: {
              searchConfigRequest: {
                searchValue: searchValue,
                searchType:
                  value === "configID"
                    ? "ID"
                    : value === "configName"
                    ? "NAME"
                    : "EMAIL",
                searchActionScope: "SearchALL",
                countryCode: isStandaloneAccess ? country : countryCode || "US",
                currencyCode: isStandaloneAccess ? "" : currencyCode,
                hideServicesForPartners: false,
                incoTerms: isStandaloneAccess ? "" : incoterms,
                isDisplayProdCountry: isDisplayProdCountry,
                isManageConfig: true,
                listOfPLForPANumber: isListOfPLForPANumber
                  ? productLine
                    ? productLine
                    : ""
                  : "",
                locationID: locationID || "",
                mDCPOrgID: "",
                pricingGeo: isStandaloneAccess
                  ? country
                  : pricingGeo
                  ? pricingGeo
                  : "",
                prtnrPortalFlag: isPartnerPortalFlag || false,
                resellerID: resellerID || "",
                routeToMarket,
                transactionID: "manageConfig1617702690388",
                userCompanyID: companyId,
                userEmail: userEmail ? userEmail : "",
                userType: userType,
                isStandaloneAccess: isStandaloneAccess,
                purchaseAgreement: purchaseAgreement ? purchaseAgreement : "",
                productCategory: proCategory,
                brID: brID || companyId,
                storeFront: "IQ"
              }
            }
          });
    }
  };

  return (
    <React.Fragment>
      <Grid container className={classes.root}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} style={{ display: "flex" }}>
          <FormControl variant="standard" component="fieldset">
            <RadioGroup
              name="Config"
              value={value}
              onChange={handleChange}
              style={{ display: "inline-block" }}
            >
              <FormControlLabel
                classes={{ label: classes.radioLabel }}
                value="None"
                control={<StyledRadio />}
                label={t("common:productSelector.none")}
              />
              <FormControlLabel
                classes={{ label: classes.radioLabel }}
                value="configID"
                control={<StyledRadio />}
                label={t("common:productSelector.label.configID")}
              />
              <FormControlLabel
                classes={{ label: classes.radioLabel }}
                value="configName"
                control={<StyledRadio />}
                label={t("common:productSelector.label.configName")}
              />
              <FormControlLabel
                classes={{ label: classes.radioLabel }}
                value="email"
                control={<StyledRadio />}
                label={t("common:productSelector.label.createdByEmail")}
              />
              {showSearchBto && (
                <FormControlLabel
                  classes={{ label: classes.radioLabel }}
                  value="btoSku"
                  control={<StyledRadio />}
                  label={t("common:productSelector.label.skuId")}
                />
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            placeholder={placeholderName}
            variant="outlined"
            fullWidth
            size="small"
            className={classes.searchInput}
            value={searchValue}
            onChange={handleSearchChange}
            onKeyPress={e => {
              if (e.key === "Enter") handleClick("ALL_SEARCH");
            }}
            InputProps={{
              classes: { input: classes.input },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            inputRef={textInput}
            disabled={isTextFieldDisable}
          />
        </Grid>
        <Grid item xs className={classes.listItem}>
          <div className={classes.wrapper}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="medium"
              disabled={isDisable || isSearchDisable || isSearchAllLoading}
              style={{
                marginLeft: "15px",
                textTransform: "capitalize",
                fontWeight: "bold"
              }}
              onClick={event => handleClick("ALL_SEARCH")}
            >
              {t("common:productSelector.button.searchAll")}
            </Button>
            {isSearchAllLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.wrapper}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="medium"
              disabled={isSearchMyConfigLoading}
              style={{
                marginLeft: "15px",
                textTransform: "capitalize",
                fontWeight: "bold"
              }}
              onClick={event => handleClick("MY_CONFIG")}
            >
              {t("common:productSelector.button.searchMyConfig")}
            </Button>
            {isSearchMyConfigLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          {(userType.toLowerCase() === "internal" ||
            showPreApprovedConfigurationButton) && (
            <div className={classes.wrapper}>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                size="medium"
                style={{
                  marginLeft: "15px",
                  textTransform: "capitalize",
                  fontWeight: "bold"
                }}
                onClick={event => handleClick("PRE_APPROVE_CONFIG")}
              >
                {t("common:productSelector.button.showPreApproved")}
              </Button>
            </div>
          )}
          {showManageMyConfigButton && (
            <div className={classes.wrapper}>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                size="medium"
                style={{
                  marginLeft: "15px",
                  textTransform: "capitalize",
                  fontWeight: "bold"
                }}
                onClick={event => handleClick("MANAGE_CONFIG")}
              >
                {t("common:productSelector.button.manageMyConfig")}
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
      {showConfigResults && (
        <div className={classes.topPadding}>
          <ConfigResultsTable isLoading={isShowLoading} />
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchConfigs;

export default (
  state = {
    activeStep: 0,
    uploadConfigData: [
      {
        quantity: 1,
        partNumber: "U4414E",
        description:
          "HP 3 year Next Business Day Onsite Hardware Support for Desktops",
        icon: "checkIcon",
        listPrice: "24",
        notes: "-",
        override: false
      },
      {
        quantity: 1,
        partNumber: "U7899E",
        description:
          "HP 3 year Next Business Day Onsite Hardware Support for Notbooks",
        icon: "addIcon",
        listPrice: "2289",
        notes: "-",
        override: false
      },
      {
        quantity: 1,
        partNumber: "UHJ64AV",
        description: "HP Z6 G4 Workstation",
        icon: "removeIcon",
        listPrice: "1539",
        notes: "-",
        override: false
      }
    ],
    looseBomItems: [],
    bomList: [],
    bomItems: [],
    modelId: "",
    warningMessage: "",
    uploadStatus: "",
    categoryFromAPI: "",
    isInValidTechAv: false,
    isUploadConfig: false
  },
  action
) => {
  switch (action.type) {
    case "SET_ACTIVE_STEP":
      return {
        ...state,
        activeStep: action.payload
      };
    case "SET_UPLOAD_CONFIG_ITEMS":
      return {
        ...state,
        bomItems: action.payload
      };
    case "SET_VALIDATE_UPLOAD_CONFIG":
      return {
        ...state,
        looseBomItems: action.payload
      };

    case "SET_UPLOAD_CONFIG_DATA":
      return {
        ...state,
        uploadConfigData: action.payload
      };
    case "SET_BOM_ITEMS":
      return {
        ...state,
        bomItems: action.payload
      };

    case "SET_BILL_OF_MATERIALS_ITEMS":
      return {
        ...state,
        bomList: action.payload
      };
    case "SET_CONFIGURATION_NAME":
      return {
        ...state,
        configurationName: action.payload
      };
    case "SET_ERRORS":
      return {
        ...state,
        errorMessage: action.payload
      };
    case "SET_WARNINGS":
      return {
        ...state,
        warningMessage: action.payload
      };
    case "SET_STATUS":
      return {
        ...state,
        uploadStatus: action.payload
      };

    case "SET_ADD_TO_QUOTE_BUTTON_VISIBILITY":
      return {
        ...state,
        isVisible: action.payload
      };
    case "SET_MODEL_ID":
      return {
        ...state,
        modelId: action.payload
      };
    case "SET_CATEGORY":
      return {
        ...state,
        categoryFromAPI: action.payload
      };
    case "IS_INVALID_TECHAV":
      return {
        ...state,
        isInValidTechAv: action.payload
      };
    case "IS_UPLOAD_CONFIG":
      return {
        ...state,
        isUploadConfig: action.payload
      };
    default:
      return state;
  }
};

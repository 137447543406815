export const EdgeType_ENCODE = 0x0; // Input from environment = Value in -> Change out (sigma delta modulation encoder)
export const EdgeType_DECODE = 0xf; // Output to environment = Change in -> Value out (Sigma Delta Modualtion Decoder)
export const EdgeType_SUM_a = 0x1; // sums input to Transitive Node state & output = input
export const EdgeType_GTE_0 = 0x2; // sums input to Transitive Node state & output = (-1, 0, +1)
export const EdgeType_EQU_0 = 0x3;
export const EdgeType_LTE_0 = 0x4; // Less than or equal to zero
export const EdgeType_MUL_a = 0x5;
export const EdgeType_MUL_b = 0x6;
export const EdgeType_SEL_1 = 0x7;
export const EdgeType_SEL_a = 0x8;
export const EdgeType_MUX_1 = 0x9; //if MUX_1 is true then MUX_a else MUX_b
export const EdgeType_MUX_a = 0xa;
export const EdgeType_MUX_b = 0xb;
export const EdgeType_ACE_A = 0xc; // Antecedent, Consequent, Evaluation (antecedent) jwl 171206
export const EdgeType_ACE_B = 0xd; // Antecedent, Consequent, Evaluation (antecedent 2) jwl 171206
export const EdgeType_ACE_C = 0xe; // Antecedent, Consequent, Evaluation (consequent - or not) jwl 171206
export const EdgeType_FLAGS = 0x40; // Process Flags (Conflict, Complete) jwl 171010
export const EdgeType_C_AVL = 0x41; // Characteristic available jwl 171010
export const EdgeType_C_REQ = 0x42; // Characteristic required jwl 171010
export const EdgeType_I_AVL = 0x43; // CharVal/Item available jwl 171010
export const EdgeType_I_SEL = 0x44; // CharVal/Item selected jwl 171010
export const EdgeType_I_REQ = 0x45; // CharVal/Item required jwl 190812 Support Required Items
export const EdgeType_INVERT = 0x80; // Operand Invert - Invert the signal going into a state
export const EdgeType_R_INC = 0x46; //Resource Counters
export const EdgeType_C_INC = 0x47; // Chaid - selected Items Counter
export const EdgeType_I_DEF = 0x48; // Dynamic selection
export const EdgeType_C_MSG = 0x49 // Chaid level messaging
export const EdgeType_I_MSG = 0x4A  // Item level messaging

export const ChangeOut_CH1 = 0x0; // ChangeOut1 Select [Default]
export const ChangeOut_CH2 = 0x4000; // ChangeOut2 Select
export const ChangeOut_CH3 = 0x8000; // ChangeOut3 Select

export const OutputGate_NOT_0 = 0x0; // OutPut Change != 0 [Default]
export const OutputGate_OPEN = 0x1000; // OutPut Change = any change

export const NodeListEncoding_LUT = 0x0; // Adjacency Look Up Table - (LUT) format [index1, index2,
// index3... ] [Default]
export const NodeListEncoding_CML = 0x100; // Consecutive Memory Location - (CML) format [Index1, Count]
export const NodeListEncoding_CMP = 0x200; // Consecutive Memory Pattern - (CMP) format [Index1, Count,
// Increment]
export const NodeListEncoding_ABC = 0x300; // Adaptive Behavior Connection - (ABC) fornat [(fromIndex1,
// toIndex1), (fromIndex2,toIndex2), ...]
export const NodeListEncoding_ABB = 0x400; // Adaptive Behavior Conn Buss - (ABB) fornat [fromIndex1,
// toIndex1, Count]
export const CODE_GA_DATE_CONFLICT = "ERR10";
export const TEXT_GA_DATE_CONFLICT = "GADATE CONFLICT";
export const CODE_PLC_CONFLICT = "ERR06";
export const TEXT_PLC_CONFLICT = "PLC Conflict";
export const PAPER_TRAY_CHOICE_ID = "INPPRHDL";
export const OUTPUT_FINISHING_CHOICE_ID = "OF";
export const CODE_REQUIRED_CHOICE_EMTY = "ERR04";
export const TEXT_WARNING = "Warning";
export const TEXT_EMPTY = "";
export const CODE_CONFLICT_ITEM = "ERR03";
export const CODE_CHOICE_CONFLICT = "ERR08";
export const CODE_HIDDEN_CHOICE_CONFLICT = "ERR09";
export const CODE_INVALID_CHOICE_SELECTION = "ERR10"
export const TEXT_CONFLICT = "Conflict";
export const CAREPACK_ELECT_CPQ = "ELECTCPQ";
export const MONITOR_CAREPACKS_PREFIX = "CAPQ_";
export const CODE_COUNTER_CONFLICT = "ERR05";
export const TEXT_COUNTER_CONFLICT = "Counter Conflict";
export const INVALIDPLCSTATUS = "I";
export const OS_LOCALIZATION_CATEGORY = "OSLOC";
export const SYS_LOCALIZATION_CATEGORY = "SYSLOC";
export const KBD_LOCALIZATION_CATEGORY = "KYBD";
export const MON_LOCALIZATION_CATEGORY = "MONLOC";
export const SPECIAL_ITEM_MESSAGE = "Special item";
export const VALID = "V";
export const INVALID = "I";
export const DO_SELECT = 1;
export const SYS_LOC_OPTION = "SYS_LOC_OPTION";
export const OS_LOC_OPTION = "OS_LOC_OPTION";
export const KYBD_LOC_OPTION = "KYBD_LOC_OPTION";
export const MON_LOC_OPTION = "MON_LOC_OPTION";
export const HIDDEN_CHOICES = {
  CLIC: true,
  ZSYS: true,
  COUNTRY: true,
  SAM_SKU_TYPE: true,
  BAPP: true,
  BMODEL: true
};
export const PermanentCLICEnabled =
  "Permanent CLIC enabled - Material not available";
export const PartialCLICEnabled =
  "Partial CLIC enabled - Material not available";
export const MANDACarepackChaidPreffix = "CAPQ_";
export const MonLocChaids = "ASCM_64283,ASCM_3242660,ASCM_POLYMON,ASCM_POLYACC";
export const TEXT_PRICE_CONFLICT = "Invalid price";
export const ClicChaid = "CLIC";
export const CODE_MONITORCAPQ_QTY = "ERR07";
export const MON_CP_QTY_MSG = "Monitors Care Packs Quantity Mismatch";
export const DefaultQty = 0;
export const SelectedQty = 1
export const DO_NOTHING = 0;
export const DO_DESELECT = -1;
export const PrintPowerCordsChaid = "PWCRD";
export const ChaIdBusinessMonitor = "ASCM_64283";
export const ChaIdPointOfSalesSystem = "ASCM_3242660";
export const ChaIdCarbonNeutralMonitorServices = "CBN_CP";
export const sysLocChaidsCOMPUTE_locChaidsPRINT = [
  "PWRCRD",
  "WRRTY",
  "RSTRCD",
  "MON",
  "DIBHW",
  "DIB2",
  "DIBMISC",
  "DIB",
  "RCB",
  "PWCRD"
];
export const ItemDisabledForPartner = "Item disabled for Partner User";
export const PolyBundleChoice = "BUNIT"
export const PolyMSOChoice = "MSO"
export const PolyPDSChoice = "PDS"
export const PolySGJChoice = "SGJ";
export const PolyPMDChoice = "PMD";
export const PolyVCDLChoice = "VCDL";
export const PolyACDLChoice = "ACDL";
export const PolyIOMSTChoice = "IOMST";
export const logisticsServicesAAChaidID = "LS_BD_AA" // Logistic Services - Basic Delivery - AA
export const logisticsServicesChaidID   = "LS_BD"    // Logistic Services Basic Delivery
export const ConfigurationServicesChaidsPrefix = "V4_"

export const DummyChoiceIDs = [
  "COUNTRY",
  "CS_C",
  "BMODEL",
  "ACSS",
  "BAPP",
  "PSO",
  "PSELECT",
  "CMODEL",
  "QTYPE",
  "DUR",
  "INST",
  "PORL",
  "PNAME"
];

// module.exports = {
//   EdgeType_ENCODE,
//   EdgeType_DECODE,
//   EdgeType_SUM_a,
//   EdgeType_GTE_0,
//   EdgeType_EQU_0,
//   EdgeType_LTE_0,
//   EdgeType_MUL_a,
//   EdgeType_MUL_b,
//   EdgeType_SEL_1,
//   EdgeType_SEL_a,
//   EdgeType_MUX_1,
//   EdgeType_MUX_a,
//   EdgeType_MUX_b,
//   EdgeType_ACE_A,
//   EdgeType_ACE_B,
//   EdgeType_ACE_C,
//   EdgeType_FLAGS,
//   EdgeType_C_AVL,
//   EdgeType_C_REQ,
//   EdgeType_I_AVL,
//   EdgeType_I_SEL,
//   EdgeType_I_REQ,
//   EdgeType_INVERT,
//   EdgeType_R_INC,
//   EdgeType_C_INC,
//   ChangeOut_CH1,
//   ChangeOut_CH2,
//   ChangeOut_CH3,
//   OutputGate_NOT_0,
//   OutputGate_OPEN,
//   NodeListEncoding_LUT,
//   NodeListEncoding_CML,
//   NodeListEncoding_CMP,
//   NodeListEncoding_ABC,
//   NodeListEncoding_ABB,
//   CODE_PLC_CONFLICT,
//   TEXT_PLC_CONFLICT,
//   PAPER_TRAY_CHOICE_ID,
//   OUTPUT_FINISHING_CHOICE_ID,
//   CODE_REQUIRED_CHOICE_EMTY,
//   TEXT_WARNING,
//   TEXT_EMPTY,
//   CODE_CONFLICT_ITEM,
//   TEXT_CONFLICT,
//   CAREPACK_ELECT_CPQ,
//   CAREPACK_EU_CPQ,
//   MONITOR_CAREPACKS_PREFIX,
//   CODE_COUNTER_CONFLICT,
//   TEXT_COUNTER_CONFLICT,
//   INVALIDPLCSTATUS
// };

export const preventSpecialCharacters = event => {
  //Ignoring left arrow, right arrow backspace and delete key, as we need them to work in TextField
  if ([37, 39, 8, 46].includes(event.keyCode)) {
    return;
  }
  !/[0-9]/.test(event.key) && event.preventDefault();
};
export const getFieldWidth = value => {
  return value ? (parseInt(value.toString().length) + 2) * 12 : 40;
};

export const maxQuantityValue = (value, proCategory, ocfgParams, modelData)   => {
  let maximumValue =
    proCategory !== "poly"
      ? ocfgParams?.monitorQty?.[value] || ocfgParams?.monitorQty?.ASCM_ACC
      : modelData?.Counters?.find(
          item =>
            item?.counter?.substring(item?.counter?.length - 4, 0) === value
        )?.max;

  return Number(maximumValue);
};
export const minQuantityValue = (value, proCategory, ocfgParams, modelData)  => {
  let minimumValue =
    proCategory !== "poly"
      ? 0
      : modelData?.Counters?.find(
          item =>
            item?.counter?.substring(item?.counter?.length - 4, 0) === value
        )?.min;
  return Number(minimumValue);
};

export const SET_PRODUCT_CATEGORIES = "SET_PRODUCT_CATEGORIES";
export const SET_PRODUCT_SERIES = "SET_PRODUCT_SERIES";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_SELECTED_SERIES = "SET_SELECTED_SERIES";
export const SET_ALL_SEARCH_CONFIG = "SET_ALL_SEARCH_CONFIG";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_PROGRAM_TYPE = "SET_PROGRAM_TYPE";
export const SET_RESULTS_LIST = "SET_RESULTS_LIST";
export const SET_SEARCH_BASE_UNIT = "SET_SEARCH_BASE_UNIT";
export const SET_SHOWSERIES = "SET_SHOWSERIES";
export const PROCESS_ID = "4653759";
export const DOCUMENT_ID = "4653823";
export const VERSION_ID = "4748762";
export const ACTION_ID = "4654396";
export const SET_SEARCH_KEY = "SET_SEARCH_KEY";
export const SET_DISABLE = "SET_DISABLE";
export const FETCH_PRODUCT_SELECTION = "FETCH_PRODUCT_SELECTION";
export const SET_COUNTRY_REGION = "SET_COUNTRY_REGION";
export const SET_PROCATEGORY = "SET_PROCATEGORY";
export const REGEX_CHINESE =
  /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;
export const BTO_SKU = "btoSku";
export const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";
export const SET_BUSINESS_MODEL = "SET_BUSINESS_MODEL";
export const RECEIVE_USER_DETAILS = "RECEIVE_USER_DETAILS";
export const SET_SELECTED_CATEGORY_FROM_API = "SET_SELECTED_CATEGORY_FROM_API";

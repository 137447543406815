import { useLazyQuery } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ExternalProps } from "../../contexts/externalPropsContext";
import { GET_ALL_SEARCH_CONFIG, PRODUCT_SELECTION } from "../../GraphQL";
import displayNotistack from "../../lib/common/SnackBarUtils";
import {
  getPlaceholder,
  sortAlphabeticallyByName
} from "../../lib/common/util";
import ConfigResultsTable from "./ConfigResultsTable";
import { SET_MANAGE_MY_CONFIG_RESULTS_LIST, SET_SEARCH_KEY } from "./Constants";

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: "bold"
  },
  breadCrumbLink: {
    cursor: "pointer"
  },
  breadCrumbLastItem: {
    color: "#000"
  },
  outer: {
    marginLeft: "8%",
    marginTop: "5%",
    width: "auto !important"
  },
  modelConfig: {
    position: "relative",
    width: "auto",
    height: "68px",
    marginLeft: "0px",
    top: "22px",
    fontWeight: "bold"
  },
  root: {
    position: "relative",
    alignItems: "left",
    justifyContent: "left",
    paddingTop: "10px"
  },
  container: {
    maxHeight: 440
  },
  topPadding: {
    paddingTop: "20px"
  },
  searchInput: {
    marginTop: "8px"
  },
  input: {
    padding: "9px !important",
    border: "none !important"
  },
  listItem: {
    marginTop: "8px",
    "& button span": {
      textTransform: "capitalize !important"
    }
  },
  searchButtonStyle: {
    marginLeft: "10px"
  },
  button: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      width: 100,
      "& .MuiButton-label": {
        overflow: "hidden",
        "text-overflow": "ellipsis"
      }
    }
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    position: "relative",
    display: "inline-block"
  },
  margin: {
    margin: "4%"
  }
}));

// Inspired by blueprintjs
function StyledRadio(props) {
  return <Radio color="primary" size="small" {...props} />;
}
const ManageMyConfigs = props => {
  // const [searchText, setSearchText] = useState('');
  const [isSearchDisable, setIsSearchDisable] = useState(true);
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState("None");
  const [placeholderName, setPlaceholderName] = useState(
    t("common:manageMyConfig.textMessage.pleaseEnterNone")
  );
  const [isTextFieldDisable, setIsTextFieldDisable] = useState(true);
  const [isShowLoading, setShowLoading] = useState(false);
  const [isSearchMyConfigLoading, setSearchMyConfigLoading] = useState(false);
  const [countryCd, setCountryCd] = useState("");
  const dispatch = useDispatch();
  const { showManageMyConfigResults, searchValue } = useSelector(
    state => state.manageMyConfig
  );
  const [countryData, setCountryData] = useState();
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const isDirect = document.getElementById("oneconfig-manage-myconfig");

  const {
    countryCode = "US",
    resellerID,
    mDCPOrgID = "99",
    pricingGeo = "US",
    currencyCode = "USD",
    incoterms = "DDP",
    routeToMarket,
    userType,
    isStandaloneAccess = false,
    productCategory = "compute",
    userEmail,
    userId,
    isDisplayProdCountry,
    productLine,
    companyId,
    purchaseAgreement,
    isListOfPLForPANumber = false,
    visibility = {},
    locationID,
    brID,
    isPartnerPortalFlag
  } = React.useContext(ExternalProps);

  let textInput = useRef(null);
  const handleChange = event => {
    setValue(event.target.value);
    textInput.current.select();
    if (event.target.value === "None") {
      dispatch({ type: SET_SEARCH_KEY, payload: "" });
      setIsTextFieldDisable(true);
    } else {
      setIsTextFieldDisable(false);
    }
    setPlaceholderName(getPlaceholder(t, event.target.value));
  };

  const handleCountryChange = (e, type) => {
    // countryCode = e.target.value;
    setCountryCd(e.target.value);
  };

  useEffect(() => {
    if (!countryCd) {
      setCountryCd(countryCd);
    } else {
      setCountryCd(countryCode);
    }
  }, [countryCode]);

  useEffect(() => {
    if (value === "None") {
      dispatch({ type: SET_SEARCH_KEY, payload: "" });
    }

    handleClick("MY_CONFIG");
  }, []);

  const handleSearchChange = event => {
    dispatch({ type: SET_SEARCH_KEY, payload: event.target.value });
  };
  const setShowConfigResults = show => {
    dispatch({
      type: "SHOW_MANAGE_MY_CONFIG_RESULTS",
      payload: show
    });
  };
  const resetTablePage = () => {
    dispatch({
      type: "SET_RESET_PAGE",
      payload: 0
    });
  };
  const [productSelectionData] = useLazyQuery(PRODUCT_SELECTION, {
    fetchPolicy: "no-cache",
    onCompleted({ productSelection }) {
      const countryDt =
        productSelection.ProductSelectionData.regionLookupDataMap;
      setCountryData(countryDt);
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
    }
  });

  useEffect(() => {
    productSelectionData();
  }, [productSelectionData]);
  useEffect(() => {
    const country = [];
    if (countryData && countryData.length !== 0) {
      for (let keys in countryData) {
        countryData[keys].countries.map(cnty => country.push(cnty));
      }
      setCountryList(country);
    }
  }, [countryData]);
  countryList.sort(sortAlphabeticallyByName);

  const [getAllSearch] = useLazyQuery(GET_ALL_SEARCH_CONFIG, {
    fetchPolicy: "no-cache",
    onCompleted({ searchSavedConfig }) {
      const { data, messages } = searchSavedConfig;
      const { searchAllConfigurations } = data;
      setShowLoading(false);
      if (
        searchAllConfigurations &&
        Array.isArray(searchAllConfigurations) &&
        searchAllConfigurations.length > 0
      ) {
        dispatch({
          type: SET_MANAGE_MY_CONFIG_RESULTS_LIST,
          payload: searchAllConfigurations
        });
      } else {
        dispatch({
          type: SET_MANAGE_MY_CONFIG_RESULTS_LIST,
          payload: []
        });
        const msg = Array.isArray(messages) ? messages[0].message : messages;
        displayNotistack.error(false, msg);
      }
    },
    onError({ graphQLErrors, networkError }) {
      setShowLoading(false);
      setShowConfigResults(true);
      dispatch({
        type: SET_MANAGE_MY_CONFIG_RESULTS_LIST,
        payload: []
      });
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (localStorage.getItem("isDebug") === "true") {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
          displayNotistack.error(
            false,
            t("common:manageMyConfig.errorMessage.noConfigsFound")
          );
        });
      }
      if (networkError) {
        dispatch({
          type: SET_MANAGE_MY_CONFIG_RESULTS_LIST,
          payload: []
        });
        displayNotistack.error(
          false,
          t("common:manageMyConfig.errorMessage.graphQLErrors")
        );
      }
    }
  });
  const handleClick = action => {
    setShowConfigResults(true);
    resetTablePage();
    setShowLoading(true);
    if (action === "MY_CONFIG") {
      setSearchMyConfigLoading(false);
      getAllSearch({
        variables: {
          searchConfigRequest: {
            searchValue: searchValue,
            searchType:
              value === "configID"
                ? "ID"
                : value === "configName"
                ? "NAME"
                : value === "baseUnit"
                ? "BU"
                : "EMAIL",
            searchActionScope: "SearchMyConfig",
            countryCode: isDisplayProdCountry ? countryCd : countryCode,
            currencyCode: currencyCode || "",
            hideServicesForPartners: false,
            incoTerms: incoterms || "",
            isDisplayProdCountry: false,
            isManageConfig: true,
            listOfPLForPANumber: productLine || "",
            locationID: locationID || "",
            mDCPOrgID: mDCPOrgID || "",
            pricingGeo: pricingGeo || "",
            prtnrPortalFlag: isPartnerPortalFlag || false,
            resellerID: resellerID || "",
            routeToMarket: routeToMarket || "Direct",
            transactionID: "manageConfig1617702690388",
            userCompanyID: companyId || "",
            userEmail: userEmail || "",
            userType: userType || "",
            isStandaloneAccess: false,
            purchaseAgreement: purchaseAgreement || "",
            productCategory: productCategory || "compute",
            brID: brID || companyId,
            storeFront: "IQ"
          }
        }
      });
    }
  };

  const gotoProductSelector = () => {
    dispatch({
      type: "NAV_MANAGE_MY_CONFIG",
      payload: { loadManageMyConfig: false }
    });
  };

  return (
    <div className={classes.margin}>
      <Box className={classes.modelConfig}>
        <Typography variant="h5" align="left" className={classes.bold}>
          {t("common:manageMyConfig.textMessage.manageMyConfiguration")}
        </Typography>
      </Box>
      <br />
      {!isDirect ? (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            onClick={() => gotoProductSelector()}
            className={classes.breadCrumbLink}
          >
            {t("common:manageMyConfig.textMessage.modelConfiguration")}
          </Link>
          <Typography className={classes.breadCrumbLastItem}>
            {t("common:manageMyConfig.textMessage.manageMyConfig")}
          </Typography>
        </Breadcrumbs>
      ) : (
        <></>
      )}
      <Grid container className={classes.root}>
        <Grid item xs={12}></Grid>
        <br />
        <Grid item xs={12} style={{ display: "flex" }}>
          <FormControl variant="standard" component="fieldset">
            <RadioGroup
              name="Config"
              value={value}
              onChange={handleChange}
              style={{ display: "inline-block" }}
            >
              <FormControlLabel
                value="None"
                control={<StyledRadio />}
                label={t("common:productSelector.none")}
              />
              <FormControlLabel
                value="configID"
                control={<StyledRadio />}
                label={t("common:productSelector.label.configID")}
              />
              <FormControlLabel
                value="configName"
                control={<StyledRadio />}
                label={t("common:productSelector.label.configName")}
              />
              <FormControlLabel
                value="baseUnit"
                control={<StyledRadio />}
                label={t("common:manageMyConfig.label.baseUnit")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            placeholder={placeholderName}
            variant="outlined"
            fullWidth
            size="small"
            className={classes.searchInput}
            value={searchValue}
            onChange={handleSearchChange}
            onKeyPress={e => {
              if (e.key === "Enter") handleClick("MY_CONFIG");
            }}
            InputProps={{
              classes: { input: classes.input },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            inputRef={textInput}
            disabled={isTextFieldDisable}
          />
        </Grid>
        <Grid item xs className={classes.listItem} sx = {{textTransform :"capitalize"}}>
          <div className={classes.wrapper}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="medium"
              disabled={isSearchMyConfigLoading}
              style={{ marginLeft: "15px" }}
              onClick={event => handleClick("MY_CONFIG")}
            >
              {t("common:manageMyConfig.button.searchMyConfig")}
            </Button>
            {isDisplayProdCountry && (
              <NativeSelect
                style={{ marginLeft: "20px" }}
                value={countryCd || ""}
                onChange={e => handleCountryChange(e, "country")}
              >
                {" "}
                <option aria-label="Country" value="">
                  Country/Region
                </option>
                {countryList &&
                  countryList.map(country => (
                    <option value={country.code}>{country.name}</option>
                  ))}
              </NativeSelect>
            )}
            {isSearchMyConfigLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.root}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      {showManageMyConfigResults && (
        <div className={classes.topPadding}>
          <ConfigResultsTable
            isLoading={isShowLoading}
            handleClick={handleClick}
          />
        </div>
      )}
    </div>
  );
};

export default withSnackbar(ManageMyConfigs);

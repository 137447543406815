const initialState = {
  showErrorDetails: false,
  errorMessage: ""
};
const snackbarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_SHOW_ERROR_DETAILS":
      return {
        ...state,
        showErrorDetails: payload
      };
    case "SET_STACK_TRACE":
      if (localStorage.getItem("isDebug") === "true") {
        console.log(
          "Inside snackbar reducer-> show error details",
          payload,
          type
        );
      }
      return {
        ...state,
        errorMessage: payload
      };
    default:
      return state;
  }
};

export default snackbarReducer;

import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { togglePopupActions } from "../../../actions/togglePopupActions";
import ExternalProps from "../../../contexts/externalPropsContext";

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
    textAlign: "center"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  message: {
    fontWeight: "bold",
    fontSize: "1.12rem"
  },
  paper: {
    minWidth: "45%",
    minHeight: "35%"
  },
  infoContainer: {
    display: "flex",
    border: "1px solid #0096d6",
    padding: "10px 15px",
    margin: "10px 0px",
    borderRadius: 5
  },
  infoMessage: {
    fontSize: "small",
    paddingLeft: "10px"
  }
}));

const InfoDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isStandaloneAccess = false } = useContext(ExternalProps);
  const { otherComponents, standardCarePacks, bomThreeCompatibleCarePacks } =
    useSelector(state => state.rskuReducer);
  const renderList = (listInfo, type) => {
    return (
      <Box component={"ul"} style={{ margin: 0, paddingTop: "5px" }}>
        {listInfo.map((each, index) => (
          <Box key={index} component={"li"} style={{ color: "#0096d6" }}>
            <Typography color="primary" style={{ fontSize: "small" }}>
              {type === "standard" ? each.partNumber : each.productNumber} -{" "}
              {each.description}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };
  const handleRequestSkuDialogClose = () => {
    dispatch({ type: "SET_RSKU_DAILOG_ALERT", payload: false });
    dispatch({ type: "SET_RSKU_INFO_DIALOG_ALERT", payload: true });
    dispatch(togglePopupActions({ name: "rskuInfoDialog", open: false }));
  };
  const handleRequestSkuDialogOpen = React.useCallback((params = {}) => {
    dispatch(
      togglePopupActions({
        name: "requestSkuDialog",
        open: true
      })
    );

    handleRequestSkuDialogClose();
  }, []);
  const DisplayInfoMessage = ({ message, children }) => {
    return (
      <Box className={classes.infoContainer}>
        <InfoOutlinedIcon color="primary" />
        <Box>
          <Typography
            color="primary"
            className={classes.infoMessage}
            style={{ display: "flex", alignItems: "center" }}
          >
            {message}
          </Typography>
          {children}
        </Box>
      </Box>
    );
  };
  return (
    <Dialog open={true} fullWidth maxWidth="md">
      <DialogTitle className={classes.title}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          {t("common:rsku.requestSKU.mainTitle")}
        </Typography>
        <IconButton
          onClick={handleRequestSkuDialogClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          <Box className={classes.infoContainer}>
            <InfoOutlinedIcon color="primary" />
            <Typography color="primary" className={classes.infoMessage}>
              {t("common:rsku.requestSKU.tatDisclaimer")}
            </Typography>
          </Box>
          {!isEmpty(otherComponents) && (
            <Box className={classes.infoContainer}>
              <InfoOutlinedIcon color="primary" />
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  color="primary"
                  className={classes.infoMessage}
                  style={{
                    marginBottom: "10px"
                  }}
                >
                  {t("common:rsku.dialog.configurationWarningOne")}
                  {!isStandaloneAccess && (
                    <span>
                      &nbsp; {t("common:rsku.dialog.configurationWarningTwo")}
                    </span>
                  )}
                </Typography>
                <Typography color="primary" className={classes.infoMessage}>
                  {t("common:rsku.dialog.configurationWarningThree")}
                </Typography>

                {otherComponents?.length &&
                  renderList(otherComponents, "standard")}
              </Box>
            </Box>
          )}
          {!isStandaloneAccess && !isEmpty(standardCarePacks) && (
            <DisplayInfoMessage
              message={t("common:rsku.dialog.standardCarepack")}
            >
              {renderList(standardCarePacks, "standard")}
            </DisplayInfoMessage>
          )}

          {!isEmpty(bomThreeCompatibleCarePacks) && (
            <DisplayInfoMessage
              message={t("common:rsku.dialog.autoAttachedCarepack")}
            >
              {renderList(bomThreeCompatibleCarePacks, "bom3")}
            </DisplayInfoMessage>
          )}
        </div>
        <DialogActions>
          <Button onClick={handleRequestSkuDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleRequestSkuDialogOpen()}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default InfoDialog;

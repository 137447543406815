import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  fprogress: {
    position: "relative"
  },
  media: {
    margin: "10px 10px 0px",
    height: 100,
    backgroundSize: "contain"
  },
  cardContent: {
    padding: "10px !important",
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold"
  },
  progress: {
    position: "absolute",
    margin: "auto",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  categoryBorderselect: {
    border: `2px solid ${theme.palette.primary.light}`
  },
  cardMedia: {
    marginTop: 10,
    height: 100,
    backgroundSize: "contain"
  }
}));

export default function CategoryCard(props) {
  const classes = useStyles();
  const { category, handleClick, active, showCategoryCard } = props;
  const { description, name, imageUrl } = category;

  return (
    <Card
      raised={active}
      className={active ? classes.categoryBorderselect : ""}
      onClick={() => handleClick(category)}
    >
      <CardActionArea className={classes.fprogress}>
        <CardMedia
          className={
            name === "Workstations" ? classes.cardMedia : classes.media
          }
          image={imageUrl}
          title={name}
        />
        <CircularProgress
          style={{
            display: active && showCategoryCard ? `block` : `none`
          }}
          className={classes.progress}
        />
        <CardContent className={classes.cardContent}>
          <Typography variant="subtitle2" align="center">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

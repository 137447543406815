import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "0px 0px 8px 0px"
  },
  configid_container: {
    height: "74px",
    background: "#FFFFFF",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px",
    padding: "0 15px"
  },
  heading: {
    fontSize: "16px",
    color: "#000000"
  }
}));

const DisplaySpcId = ({ chaid, isExpertConfig }) => {
  const classes = useStyles();
  //ftech configurator from state
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const spcId = useSelector(state => state.configFilterData.spcId);

  //define local state
  const [spcID, setSpcID] = useState(spcId);
  const [showSpcID, setShowSpcId] = useState(true);

  useEffect(() => {
    if (configuration && Object.keys(configuration).length > 0) {
      const docType = configuration.configHeader.docType;
      const showSpcID =
        configuration.configHeader.configId !=
        configuration.configHeader.refConfigId;
      setShowSpcId(showSpcID);
      if (docType === "SP") {
        const spcId = configuration.configHeader.configId;
        setSpcID(spcId);
      } else {
        const spcId = configuration.configHeader.refConfigId;
        setSpcID(spcId);
      }
    }
  }, [configuration]);

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="center"
        className={classes.configid_container}
      >
        <Grid item xs={12} sm={6}>
          <Typography className={classes.heading}>
            {isExpertConfig ? "Banded Config ID" : "Starting Point Config ID"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.heading}>
            {spcID && showSpcID ? spcID : "-"}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default DisplaySpcId;

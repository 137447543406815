import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  media: {
    padding: 10,
    width: "80%",
    height: 90,
    marginLeft: "10px",
    "&.MuiCardMedia-root" :{
      backgroundSize :"100%"
    }
  },
  progress: {
    position: "absolute",
    margin: "auto",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  seriesBorderselect: {
    border: `2px solid ${theme.palette.primary.light}`
  },
  cardEllipsis: {
    "&.MuiTypography-root":{
    fontWeight: "bold"
    }
  }
}));

export default function CategorySeriesCard(props) {
  const classes = useStyles();

  const { series, active, handleClick, showCategorySeriesCard } = props;

  const { name, imageUrl } = series;
  let fallBackImageUrl = `${process.env.REACT_APP_IMG_BASE_URL}/pdc_missing_image.png`;
  return (
    <Card
      raised={active}
      className={active ? classes.seriesBorderselect : ""}
      onClick={() => handleClick(series)}
    >
      <CardActionArea>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={3}>
            <CardMedia
              className={classes.media}
              image={imageUrl ? imageUrl : fallBackImageUrl}
              title={name}
              component="div"
            />
            <CircularProgress
              style={{
                display: active && showCategorySeriesCard ? "block" : "none"
              }}
              className={classes.progress}
            />
          </Grid>
          <Grid item xs={9}>
            <CardContent>
              <Typography
                className={classes.cardEllipsis}
                variant="subtitle2"
                color={active ? "textPrimary" : "initial"}
                align="left"
              >
                {name}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  SET_ERROR_DATA,
  SET_LOADING_SELECTION,
  SET_VERTICAL_SCROLLTO_CHAID,
  SET_WARNING,
  UPDATE_SCROLL_FILTER_CHOICE,
  WEB_ASSEMBLY_UPDATE_MODEL
} from "../actions/Types";
import AccessOrganization from "../components/Configurator/ConfigExpansionDetails/AccessOrganisation";
import ConfigID from "../components/Configurator/ConfigExpansionDetails/ConfigID";
import ConfigName from "../components/Configurator/ConfigExpansionDetails/ConfigName";
import ConfigVerticalStepper from "../components/Configurator/ConfigStepper/ConfigVerticalStepper";
import { ExternalProps } from "../contexts/externalPropsContext";
import {
  calculateDays,
  formatDate,
  getStorageValue,
  scrollToChoice,
  updatedModelData
} from "../lib/common/util";
import { loadOneModel } from "../oneConfigNative/main";
import { segregatePayload, updateCarePacks } from "../oneConfigNative/util";
import {
  fetchUiSpecVerticalView,
  processCapqName,
  resolveChaidsVisible,
  shouldHideChoices,
  shouldShowLeastPriorityConflicts
} from "../services/common/utils";
import CarePacksStepper from "./CarePacksStepper";
import ChaidSelectionType from "./ChaidSelectionType";
import Localization from "./Localization/Localization";
import { OtherServicesStepper } from "./OtherServicesStepper";

let itemConflicts = [];
function useConflictFocus(
  filteredChoices,
  modelData,
  expandAll,
  defaultConfigStepSlected,
  isAutoScrollEnabled
) {
  const dispatch = useDispatch();
  const nextConflict = useRef();
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected);
  const scrollTimer = useRef();
  let conflicts =
    (modelData &&
      modelData.conflicts &&
      modelData.conflicts[0].icConflicts.filter(x => {
        return (
          (x.code === "ERR04" || x.code === "ERR03" || x.code === "ERR10") &&
          filteredChoices.filter(_f => _f.chaId === x.chaId).length > 0
        );
      })) ||
    [];

  let itemConflictsList =
    (modelData &&
      modelData.conflicts &&
      modelData.conflicts[0].itemConflictsList.filter(x => {
        return (
          filteredChoices.filter(_f => _f.chaId === x?.effect?.chaId).length > 0
        );
      })) ||
    [];

  const plcConflicts =
    (modelData &&
      modelData.conflicts &&
      modelData.conflicts[0].plcConflicts?.filter(x => {
        return (
          x.code === "ERR06" &&
          filteredChoices.filter(_f => _f.chaId === x.chaId).length > 0
        );
      })) ||
    [];

  const gaDateConflicts =
    (modelData &&
      modelData.conflicts &&
      modelData.conflicts[0].gaDateConflicts?.filter(x => {
        return (
          x.code === "ERR10" &&
          filteredChoices.filter(_f => _f.chaId === x.chaId).length > 0
        );
      })) ||
    [];
  const counterConflicts =
    (modelData &&
      modelData.conflicts &&
      modelData.conflicts[0].counterConflicts.filter(x => {
        return filteredChoices.filter(_f => _f.chaId === x?.chaId).length > 0;
      })) ||
    [];
  const choiceConflicts =
    (shouldShowLeastPriorityConflicts(modelData) &&
      modelData.conflicts[0]?.choiceConflicts?.map(
        choiceConflict => choiceConflict?.effectChoice
      )) ||
    [];

  if (itemConflictsList.length) {
    conflicts = modelData?.conflicts[0]?.itemConflictsList?.map(
      item => item?.effect
    );
  } else {
    conflicts = [
      ...conflicts,
      ...gaDateConflicts,
      ...plcConflicts,
      ...counterConflicts,
      ...choiceConflicts
    ];
  }

  useEffect(() => {
    if (!modelData.conflicts || modelData.conflicts.length === 0) return;
    const effectsOfItemConflictsList =
      modelData?.conflicts[0]?.itemConflictsList?.map(item => item?.effect);
    if (
      modelData & modelData?.conflicts &&
      modelData?.conflicts[0] &&
      effectsOfItemConflictsList
    ) {
      conflicts.push(effectsOfItemConflictsList);
    }
    if (conflicts.length) {
      let chaids = filteredChoices.map(choice => choice.chaId);
      if (
        isVerticalView === "Y" &&
        ["carePacks"].includes(defaultConfigStepSlected)
      ) {
        const stepperUiData = window.uiSpecData.UISpec[0]?.subsections
          ?.find(section => section?.id === defaultConfigStepSlected)
          ?.choices.map(choice => choice?.id);
        chaids = filteredChoices
          .sort(
            (a, b) =>
              stepperUiData?.indexOf(a.parentId) -
              stepperUiData?.indexOf(b.parentId)
          )
          ?.map(choice => choice?.chaId);
      }
      conflicts.sort(
        (a, b) => chaids.indexOf(a.chaId) - chaids.indexOf(b.chaId)
      );
      if (isVerticalView === "Y" && chaids.length) {
        conflicts = conflicts.filter(conflict =>
          chaids.includes(conflict.chaId)
        );
      }
      nextConflict.current = conflicts[0]?.chaId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData.conflicts, filteredChoices, modelData]);
  itemConflicts = conflicts;
  return conflicts => {
    if (conflicts.length > 0 && isAutoScrollEnabled) {
      if (expandAll.length > 0 && defaultConfigStepSlected) {
        const relevantExpandData = expandAll.find(
          expandedData => expandedData.tab === defaultConfigStepSlected
        );
        if (relevantExpandData && relevantExpandData.choicesExpandState) {
          relevantExpandData.choicesExpandState[nextConflict.current] = true;
          const payload = {
            tab: defaultConfigStepSlected,
            choicesExpandState: relevantExpandData.choicesExpandState
          };
          dispatch({
            type: UPDATE_SCROLL_FILTER_CHOICE,
            payload
          });
        }
      }
      let RecommendErr = document.querySelectorAll(".data-99999");
      let additionalErr = document.querySelectorAll(".data-99998");
      if (RecommendErr[0]?.dataset?.order === "show") {
        scrollToChoice({
          chaId: "99999",
          defaultConfigStepSlected,
          isAutoScrollEnabled
        });
      } else if (additionalErr[0]?.dataset?.order === "show") {
        scrollToChoice({
          chaId: "99998",
          defaultConfigStepSlected,
          isAutoScrollEnabled
        });
      } else {
        if (isVerticalView === "Y") {
          dispatch({
            type: SET_VERTICAL_SCROLLTO_CHAID,
            payload: nextConflict.current
          });
          clearTimeout(scrollTimer.current);
          scrollTimer.current = setTimeout(() => {
            scrollToChoice({
              chaId: nextConflict.current,
              defaultConfigStepSlected,
              isAutoScrollEnabled
            });
          }, 800);
        } else {
          scrollToChoice({
            chaId: nextConflict.current,
            defaultConfigStepSlected,
            isAutoScrollEnabled
          });
        }
      }
    }
  };

}

function Chaid(props) {
  const selectedRadioRef = useRef();
  const { t } = useTranslation();
  const { tabId = "hardware" } = props;
  const { proCategory, localisationData, configurationName } = props;
  const bomData = useSelector(state => state.configFilterData.bomData);
  const showBOMSectionFlag = useSelector(
    state => state.configFilterData.showBOMSectionFlag
  );
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const isVerticalView = fetchUiSpecVerticalView(defaultConfigStepSlected);
  const carePacks = [
    {
      chaId: "99999",
      name: "recommended"
    },
    {
      chaId: "99998",
      name: "additional"
    }
  ];

  const { visibility = {}, isAutoScrollEnabled = true } =
    useContext(ExternalProps);
  const {
    showConfigurationID = false,
    showConfigurationName = false,
    isExpertConfig,
    showAccessType
  } = visibility;
  if (localStorage.getItem("isDebug") === "true") {
    console.log("isExpertConfig", isExpertConfig);
  }
  const checkedFilterParts = useSelector(
    state => state.configFilterData.checkedFilterParts
  );
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const modelData = useSelector(state => state.configFilterData.modelData);
  const filteredChoices = useSelector(
    state => state.configFilterData.filteredChoices
  );

  const filteredLoc = useSelector(state => state.configFilterData.filteredLoc);
  const searchText = useSelector(state => state.configFilterData.searchText);
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const dispatch = useDispatch();
  const stateUpdate = useRef();
  const uiCompleted = useRef(false);
  const [isItemChanged, setIsItemChanged] = useState(false);
  const focusNextTimer = useRef();
  const goEngineTimer = useRef();
  const uiCompletedTimer = useRef();

  useEffect(() => {
    if (isItemChanged) {
      clearTimeout(stateUpdate.current);
      stateUpdate.current = setTimeout(() => {
        uiCompleted.current = true;
      }, 200);
      setIsItemChanged(false);
    }
  });

  const focusNext = useConflictFocus(
    filteredChoices,
    modelData,
    expandAll,
    defaultConfigStepSlected,
    isAutoScrollEnabled
  );
  const isGoEngine = localStorage.getItem("KB_ENGINE") === "GO";

  async function handleQuantityChange(
    e,
    quantity,
    partno,
    chaid,
    inode,
    maxQty,
    minQty
  ) {
    if (
      Number.isInteger(Number(quantity)) &&
      quantity <= maxQty &&
      quantity >= minQty
    ) {
      modelData.Items[inode].quantity = Number(quantity);
      let updatedModelData = modelData;
        scrollToChoice({
          chaId: "",
          iNodeORCarepacksErr: parseInt(inode),
          modelData,
          defaultConfigStepSlected,
          isAutoScrollEnabled
        });
      const { name } = e.target;
      selectedRadioRef.current = e.target.closest("label");
      selectedRadioRef.current.style.opacity = 0.4;
      let payload = null;
      const carePackObject = carePacks.find(
        carePack => carePack.chaId === name
      );
      let chaidName = name;
      if (name.startsWith("CAPQ_")) chaidName = processCapqName(chaidName);
      const chaidObj = modelData.Chaids.find(
        chaid => chaid.chaId === chaidName
      );
      let selectedData = {
        name: chaidName,
        value: inode
      };

      let previousItemId = null;
      if (chaidObj && !chaidObj.multiple) {
        // elementId = chaidObj.chaId;
        for (let i = chaidObj.firstItem; i <= chaidObj.lastItem; i++) {
          if (modelData.Items[i].selected) {
            previousItemId = modelData.Items[i].inode;
            break;
          }
        }
        if (previousItemId) {
          payload = {
            partQty:
              previousItemId !== inode
                ? [
                    {
                      partInode: previousItemId,
                      qty: 0
                    },
                    {
                      partInode: inode,
                      qty: Number(quantity)
                    }
                  ]
                : [
                    {
                      partInode: inode,
                      qty: Number(quantity)
                    }
                  ]
          };
        } else {
          payload = {
            partQty: [
              {
                partInode: inode,
                qty: Number(quantity)
              }
            ]
          };
        }
      } else {
        payload = {
          partQty: [
            {
              partInode: inode,
              qty: Number(quantity)
            }
          ]
        };
      }
      dispatch({
        type: SET_ERROR_DATA,
        payload: selectedData
      });
      const oneModel = new loadOneModel(updatedModelData);
      parseModelObj(
        isGoEngine && !window._DISABLE_WASM
          ? window.updateQuantity &&
              window.updateQuantity(JSON.stringify(payload))
          : oneModel.updateQuantity(payload),
        chaidObj || carePackObject
      );
      if (isVerticalView === "Y") {
        clearTimeout(focusNextTimer.current);
        focusNextTimer.current = setTimeout(() => {
          focusNext(itemConflicts);
        }, 800);
      }
    }
  }

  async function handleItemChange(e) {
    const { name, value, checked } = e.target;
    selectedRadioRef.current = e.target.closest("label");
    selectedRadioRef.current.style.opacity = 0.4;
    let currentItemId = value === "None" ? null : parseInt(value);
      scrollToChoice({
        chaId: "",
        iNodeORCarepacksErr: parseInt(currentItemId),
        modelData,
        defaultConfigStepSlected,
        isAutoScrollEnabled
      });
    const carePackObject = carePacks.find(carePack => carePack.chaId === name);

    if (value === "None") {
      dispatch({
        type: SET_LOADING_SELECTION,
        payload: "None"
      });
    } else {
      dispatch({
        type: SET_LOADING_SELECTION,
        payload: modelData.Items[currentItemId].inode
      });
    }
    // Removing _additional or _recommended text in capq based care packs
    let chaidName = name;
    if (name.startsWith("CAPQ_")) chaidName = processCapqName(chaidName);

    let selectedData = {
      name: chaidName,
      value: value
    };
    if (value !== "None") {
      const dateFormat = formatDate(modelData.Items[currentItemId].esDate);
      const days = calculateDays(dateFormat);
      batch(() => {
        dispatch({
          type: SET_WARNING,
          payload: {
            tab: defaultConfigStepSlected,
            warning: days <= 90
          }
        });
        dispatch({
          type: SET_ERROR_DATA,
          payload: selectedData
        });
      });
    }
    let previousItemId = null;
    let payload = null;
    // let elementId = null;

    const chaidObj = modelData.Chaids.find(chaid => chaid.chaId === chaidName);

    if (chaidObj && !chaidObj.multiple) {
      // elementId = chaidObj.chaId;
      for (let i = chaidObj.firstItem; i <= chaidObj.lastItem; i++) {
        if (modelData.Items[i].selected) {
          previousItemId = modelData.Items[i].inode;
          break;
        }
      }
      if (previousItemId) {
        payload = {
          // changeType: ITEM_CHANGE,
          itemChanges: currentItemId
            ? [
                {
                  inputEdge: 2,
                  inputNode: previousItemId,
                  changeIn: -1
                },
                {
                  inputEdge: 2,
                  inputNode: currentItemId,
                  changeIn: 1
                }
              ]
            : [
                {
                  inputEdge: 2,
                  inputNode: previousItemId,
                  changeIn: -1
                }
              ]
        };
      } else {
        payload = {
          // changeType: ITEM_CHANGE,
          itemChanges: [
            {
              inputEdge: 2,
              inputNode: currentItemId,
              changeIn: 1
            }
          ]
        };
      }
    } else {
      payload = {
        // changeType: ITEM_CHANGE,
        itemChanges: [
          {
            inputEdge: 2,
            inputNode: currentItemId,
            changeIn: e.target.checked ? 1 : -1
          }
        ]
      };
      // elementId = currentItemId;
    }

    console.time("radioButtonWaitingTime");
    clearTimeout(goEngineTimer.current);
    goEngineTimer.current = setTimeout(
      () => {
        const { cpPayload, nonCpPayload, chaId } = segregatePayload(
          payload,
          modelData
        );
        let updatedModelData = modelData;
        if (cpPayload?.itemChanges?.length > 0) {
          updatedModelData = updateCarePacks(cpPayload, modelData, true);
        }
        const oneModel = new loadOneModel(updatedModelData);
        if (modelData.modelRequirement.productGroup === "PY") {
          // Added this flag to distinguish calls from the UI handleItemChange function
          window.triggerWasmCall = true;
        }
        parseModelObj(
          isGoEngine && !window._DISABLE_WASM
            ? window.updateModelWithMultipleChanges &&
                window.updateModelWithMultipleChanges(JSON.stringify(payload))
            : oneModel.updateModelWithMultipleChanges(nonCpPayload),
          chaidObj || carePackObject
        );
        if (isAutoScrollEnabled) {
          setIsItemChanged(true);
          clearTimeout(uiCompletedTimer.current);
          uiCompletedTimer.current = setTimeout(() => {
            const excutionInterval = setInterval(() => {
              if (uiCompleted.current === true) {
                focusNext(itemConflicts);
                uiCompleted.current = false;
                clearInterval(excutionInterval);
              }
            }, 100);
          });
        }
      },
      isGoEngine ? 200 : 5
    );
    console.timeLog("radioButtonWaitingTime");
  }

  function parseModelObj(isSuccess, selectedData) {
    if (
      localStorage.getItem("isDebug") === "true" ||
      localStorage.getItem("showTime") === "true"
    ) {
      console.log("IsSuccess", isSuccess);
    }
    if (isSuccess || window._DISABLE_WASM) {
      let modelJson = isGoEngine ? window._modelJson : isSuccess;
      if (window._DEBUG) {
        printResult("window.modelJsonlocas js object has updated!");
        printResult(modelJson);
      }
      resolveChaidsVisible(ocfgParams.hideChoice, modelJson);
      dispatch({
        type: WEB_ASSEMBLY_UPDATE_MODEL,
        modelData: updatedModelData(modelJson, t)
      });
      handleEvent(modelJson, selectedData);
      if (
        selectedRadioRef &&
        selectedRadioRef.current &&
        selectedRadioRef.current.style
      ) {
        selectedRadioRef.current.style.opacity = 1;
        dispatch({
          type: SET_LOADING_SELECTION,
          payload: "None"
        });
      }
      console.timeEnd("radioButtonWaitingTime");
    }
  }

  async function printResult(result) {
    if (localStorage.getItem("isDebug") === "true") {
      console.log("webassembly response", result);
    }
  }
  const handleEvent = (modelJson, selectedData) => {
    if (
      (modelJson &&
        selectedData &&
        modelJson.conflicts[0] &&
        ((modelJson.conflicts[0].icConflicts &&
          !modelJson.conflicts[0].icConflicts.find(
            item => item.chaId === selectedData.chaId && item.code === "ERR04"
          )) ||
          (modelJson.conflicts[0].itemConflicts?.cause &&
            !modelJson.conflicts[0].itemConflicts.cause[0].chaId ===
              selectedData.chaId))) ||
      (selectedData &&
        (selectedData.chaId === 99999 || selectedData.chaId === 99998))
    ) {
      if (document.querySelector("#product-selector")) {
        document.querySelector("#product-selector").dispatchEvent(
          new CustomEvent("changeItem", {
            detail: { ...modelJson, bomList: bomData, showBOMSectionFlag }
          })
        );
      } else if (document.querySelector("#configurator")) {
        document.querySelector("#configurator").dispatchEvent(
          new CustomEvent("changeItem", {
            detail: { ...modelJson, bomList: bomData, showBOMSectionFlag }
          })
        );
      }
    } else {
      if (localStorage.getItem("isDebug") === "true") {
        console.log(
          `${selectedData && selectedData.chaDes} still have conflicts`
        );
      }
    }
  };
  function showLoc(locCaption) {
    return filteredLoc.includes(locCaption);
  }

  const getSortedOptionObject = localOptionsArray => {
    let sortedArray =
      localOptionsArray &&
      localOptionsArray.sort((a, b) =>
        a.locOption > b.locOption ? 1 : a.locOption < b.locOption ? -1 : 0
      );

    sortedArray =
      sortedArray &&
      sortedArray.length &&
      sortedArray.map(option => {
        option["locDescription"] =
          localisationData && localisationData[option.locOption];
        return option;
      });
    return sortedArray;
  };

  const hardwareAddOns = modelData ? (
    <>
      {showConfigurationID &&
        !searchText &&
        !checkedFilterParts &&
        proCategory !== "poly" && (
          <ConfigID
            id={configuration?.configHeader?.configId}
            isUploadFlow={modelData?.isUploadFlow}
          />
        )}

      {showConfigurationName &&
        !searchText &&
        !checkedFilterParts &&
        proCategory !== "poly" && <ConfigName name={configurationName} />}
      {showAccessType &&
        !searchText &&
        !checkedFilterParts &&
        proCategory !== "poly" && <AccessOrganization chaid={"access-type"} />}
      {showLoc("System Localization Options") && (
        <Localization
          name="System Localization Options"
          options={getSortedOptionObject(modelData.systemLocOptions)}
          chaid={"local-1"}
        />
      )}
      {showLoc("OS Localization Options") && (
        <Localization
          name="OS Localization Options"
          options={getSortedOptionObject(modelData.osLocOptions)}
          chaid={"local-2"}
        />
      )}
      {showLoc("Keyboard Localization Options") && (
        <Localization
          name="Keyboard Localization Options"
          options={getSortedOptionObject(modelData.kybdLocOptions)}
          chaid={"local-3"}
        />
      )}
      {showLoc("Monitor Localization Options") &&
        modelData?.monitorLocOptions?.length > 0 && (
          <Localization
            name="Monitor Localization Options"
            options={getSortedOptionObject(modelData.monitorLocOptions)}
            chaid={"local-4"}
          />
        )}
    </>
  ) : null;
  return (
    <>
      <div>
        {tabId === "hardware" ? hardwareAddOns : null}
        {isVerticalView === "Y" && tabId !== "otherServices" ? (
          <ConfigVerticalStepper
            proCategory={proCategory}
            handleItemChange={handleItemChange}
            handleQuantityChange={handleQuantityChange}
          />
        ) : tabId === "carePacks" && isVerticalView === "N" ? (
          <CarePacksStepper
            proCategory={proCategory}
            handleItemChange={handleItemChange}
            handleQuantityChange={handleQuantityChange}
          />
        ) : (
          <></>
        )}

        {/* This has been to show the filtered choices on the hard ware tab */}
        {tabId === "hardware" && isVerticalView === "N"
          ? Object.keys(filteredLoc).length === 0
            ? filteredChoices.map(chaid =>
                chaid ? (
                  <ChaidSelectionType
                    chaid={chaid}
                    modelData={modelData}
                    handleItemChange={handleItemChange}
                    handleQuantityChange={handleQuantityChange}
                  />
                ) : null
              )
            : filteredChoices
                .filter(filterChoice =>
                  filteredLoc.includes(filterChoice.chaDes)
                )
                .map(chaid =>
                  chaid ? (
                    <ChaidSelectionType
                      chaid={chaid}
                      modelData={modelData}
                      handleItemChange={handleItemChange}
                      handleQuantityChange={handleQuantityChange}
                    />
                  ) : null
                )
          : null}
        {/* This is for any other tab excluding carepacks and hardware */}
        {tabId !== "carePacks" &&
          tabId !== "hardware" &&
          tabId !== "deploymentServices" &&
          tabId !== "otherServices" &&
          isVerticalView === "N" &&
          filteredChoices.map(chaid =>
            chaid ? (
              <ChaidSelectionType
                chaid={chaid}
                modelData={modelData}
                handleItemChange={handleItemChange}
                handleQuantityChange={handleQuantityChange}
              />
            ) : null
          )}
        {tabId === "deploymentServices" &&
          isVerticalView === "N" &&
          (["EU", "AP", "NA"].includes(configuration?.configHeader?.rgnCd)
            ? filteredChoices
                .filter(
                  item =>
                    !shouldHideChoices(
                      ocfgParams?.hideChoice,
                      item.chaId,
                      modelData
                    )
                )
                .map(chaid =>
                  chaid ? (
                    <ChaidSelectionType
                      chaid={chaid}
                      modelData={modelData}
                      handleItemChange={handleItemChange}
                      handleQuantityChange={handleQuantityChange}
                    />
                  ) : null
                )
            : filteredChoices.map(chaid =>
                chaid ? (
                  <ChaidSelectionType
                    chaid={chaid}
                    modelData={modelData}
                    handleItemChange={handleItemChange}
                    handleQuantityChange={handleQuantityChange}
                  />
                ) : null
              ))}
        {tabId === "otherServices" && isVerticalView === "Y" ? (
          <ConfigVerticalStepper
            proCategory={proCategory}
            handleItemChange={handleItemChange}
            handleQuantityChange={handleQuantityChange}
          />
        ) : tabId == "otherServices" && isVerticalView === "N" ? (
          <OtherServicesStepper
            handleItemChange={handleItemChange}
            handleQuantityChange={handleQuantityChange}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Chaid;

import { useApolloClient, useLazyQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from "@mui/icons-material/Warning";
import { InputBase, Table, TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { orange } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CATALOG_LOOKUP } from "../../../GraphQL";
import ExternalProps from "../../../contexts/externalPropsContext";
import displayNotistack from "../../../lib/common/SnackBarUtils";
import { getStorageValue } from "../../../lib/common/util";
import DefaultConfigCard from "./DefaultConfigCard";
const useStyles = makeStyles(theme => ({
  iconWrapper: {
    color: orange[500]
  },
  gridWrapper: {
    padding: "10px 10px 10px 10px"
  },
  infoLabel: {
    display: "inline"
  },
  tableWrapper: {
    position: "sticky",
    top: "0px",
    background: "white",
    marginRight: "7px",
    zIndex: 10
  },
  warningStyle: {
    display: "flex",
    align: "center",
    paddingTop: theme.spacing(3)
  },
  topPadding: {
    paddingTop: "20px",
    marginBottom: "70px"
  },
  table: {
    minWidth: 650
  },
  searchBar: {
    position: "absolute",
    width: "40%",
    zIndex: "1",
    top: "2px",
    left: "7px",
    borderRadius: "5px",
    backgroundColor: "white",
    height: "48px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
  },
  tablePagination: {
    width: "100%"
  },
  searchIconButton: {
    position: "absolute",
    transform: "none"
  },
  input: {
    marginLeft: "40px",
    width: "96%"
  }
}));

const DefaultConfigs = props => {
  const classes = useStyles();
  const defaultConfigurations = useSelector(
    state => state.productSelector.defaultConfigurations
  );
  const selectedSeries = useSelector(
    state => state.productSelector.selectedSeries
  );
  const selectedCategory = useSelector(
    state => state.productSelector.selectedCategory
  );
  const country = useSelector(state => state.productSelector.country);
  const programType = useSelector(state => state.productSelector.programType);
  const showSeries = useSelector(state => state.productSelector.showSeries);
  const baseUnitValue = useSelector(
    state => state.productSelector.baseUnitValue
  );
  const businessModel = useSelector(
    state => state.productSelector.businessModel
  );
  const showDefaultConfig =
    (selectedCategory && !showSeries) ||
    (selectedSeries && showSeries && selectedCategory)
      ? true
      : false;

  const { t } = useTranslation();

  //getting pro category from the props
  const { proCategory } = props;
  const [configureButton, setConfigureButton] = useState(null);
  const dispatch = useDispatch();

  const [fetchPolicy, setFetchPolicy] = useState("cache-only");

  const expiration = moment.duration(3600000); // 1 hour in ms
  const key = "lastFetch_getDefaultConfiguration";
  const client = useApolloClient();
  const clientOrigin = getStorageValue("clientOrigin");

  useEffect(() => {
    const lastFetch = localStorage.getItem(key);
    try {
      if (
        lastFetch === null ||
        moment().diff(moment(lastFetch)) > expiration.asMilliseconds()
      ) {
        try {
          client.cache.evict("ROOT_QUERY");
          localStorage.setItem(key, moment().toISOString());
          setFetchPolicy("network-first");
        } catch (e) {
          console.warn(`Failed to save time of last fetch for ${key}`);
        }
      } else {
        setFetchPolicy("cache-first");
      }
    } catch (e) {
      setFetchPolicy("network-only");
    }
  }, [selectedCategory, selectedSeries]);

  const queryConfig = policy => {
    return {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      onCompleted({ catalogLookup }) {
        let data = null;
        if (catalogLookup && catalogLookup.data?.searchCatalog?.length) {
          data = catalogLookup.data.searchCatalog.map(item => {
            let imageURL = "";
            if (item.imageURL) {
              imageURL = item.imageURL.replace(
                "$BASE_PATH$",
                `${process.env.REACT_APP_IMG_BASE_URL}`
              );
            } else {
              imageURL = `${process.env.REACT_APP_IMG_BASE_URL}/pdc_missing_image.png`;
            }
            return {
              ...item,
              imageURL
            };
          });
        }
        dispatch({
          type: "SEARCH_DEFAULT_SPC",
          payload: {
            searchListConfigurations: data,
            isProgramType: true
          }
        });
        props.getDefaultConfig(1);
      },
      onError({ graphQLErrors, networkError }) {
        props.getDefaultConfig(0);
      }
    };
  };

  const [getDefaultConfiguration] = useLazyQuery(
    CATALOG_LOOKUP,
    queryConfig("cache-first")
  );
  const [getDefaultConfigurationNetwork] = useLazyQuery(
    CATALOG_LOOKUP,
    queryConfig("network-only")
  );

  const {
    currencyCode,
    locationID,
    resellerID,
    productLine,
    isListOfPLForPANumber = false,
    isPartnerPortalFlag,
    userType,
    isStandaloneAccess = false
  } = React.useContext(ExternalProps);
  let { routeToMarket } = React.useContext(ExternalProps);
  routeToMarket =
    isStandaloneAccess && userType.toUpperCase() === "INTERNAL"
      ? businessModel
      : routeToMarket;
  useEffect(() => {
    if (baseUnitValue === "") {
      if (selectedCategory) {
        const query =
          fetchPolicy !== "cache-first"
            ? getDefaultConfigurationNetwork
            : getDefaultConfiguration;
        query({
          variables: {
            filter: {
              countryCode: country || "US",
              createCustomBandingFlag: false,
              currencyCode: currencyCode,
              hideServicesForPartners: false,
              incoTerms: "",
              isDisplayProdCountry: false,
              isFixedConfiguration: false,
              isManageConfig: false,
              listOfPLForPANumber: isListOfPLForPANumber ? productLine : "",
              locationID: locationID,
              mDCPOrgID: "",
              pricingGeo: "",
              proCategory: proCategory ? proCategory : "compute",
              productHierarchyL1Value:
                (selectedCategory && selectedCategory.name) || "",
              productHierarchyL2Value:
                (selectedSeries && selectedSeries.name) || "",
              programType: programType === "None Selected" ? "" : programType,
              prtnrPortalFlag: isPartnerPortalFlag || false,
              resellerID,
              routeToMarket: routeToMarket,
              userCompanyID: "",
              userEmailOne: "",
              userGroupOne: "",
              userLogin: "",
              storeFront: clientOrigin === "BMI" ? "IQ" : ""
            }
          }
        });
      }
    }
  }, [
    selectedCategory,
    selectedSeries,
    country,
    programType,
    baseUnitValue,
    proCategory,
    fetchPolicy,
    businessModel,
    getDefaultConfiguration
  ]);

  useEffect(() => {
    if (!_.isEmpty(defaultConfigurations) || !defaultConfigurations) {
      setAllContainerDataItems(defaultConfigurations || []);
    }
    return () => {
      setPage(0);
    };
  }, [defaultConfigurations]);

  const setConfigurePath = (configURL, index) => {
    setConfigureButton(index);
    displayNotistack.success(t("common:snackbar.redirecting"), {
      autoHideDuration: 10000
    });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [allContainerDataItems, setAllContainerDataItems] = useState(
    defaultConfigurations
  );
  const [searchValue, setSearchValue] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setSearchValue("");
    setPage(0);
    setAllContainerDataItems(defaultConfigurations);
  };

  const filterData = searchVal => {
    let searchResult = defaultConfigurations.filter(
      item =>
        item.name
          .toLowerCase()
          .includes(searchVal.target.value.toLowerCase()) ||
        item.baseUnits
          .toString()
          .toLowerCase()
          .includes(searchVal.target.value.toLowerCase())
    );
    setSearchValue(searchVal.target.value);
    setAllContainerDataItems(searchResult);
    setPage(0);
  };

  return (
    (showDefaultConfig || defaultConfigurations === null) && (
      <Box id="default-config-wrapper">
        <Box my={3}>
          <Grid
            container
            spacing={2}
            className={classes.topPadding}
            alignItems="flex-end"
          >
            <Grid xs={12} item>
              <Typography variant="h6" align="left">
                <Box fontSize={18} m={1}>
                  {proCategory !== "poly"
                    ? t(
                        "common:productSelector.textMessage.defaultsConfiguration"
                      )
                    : t("common:productSelector.textMessage.selectModels")}
                </Box>
              </Typography>
            </Grid>
            {defaultConfigurations ? (
              <Table className={classes.tableWrapper}>
                <InputBase
                  placeholder={
                    proCategory !== "poly"
                      ? t("common:productSelector.textMessage.searchSPC")
                      : t("common:productSelector.textMessage.searchModels")
                  }
                  className={classes.searchBar}
                  value={searchValue}
                  onChange={e => {
                    filterData(e);
                  }}
                  startAdornment={
                    <InputAdornment position="start" sx={{ padding: "10px" }}>
                      <SearchIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    searchValue && (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={handleClose}
                          className={classes.closeButton}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
                <TablePagination
                  className={classes.tablePagination}
                  rowsPerPageOptions={[12, 28, 52]}
                  component="div"
                  count={allContainerDataItems.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("common:table.rowsPerPage")}
                />
              </Table>
            ) : null}
            {defaultConfigurations ? (
              _.orderBy(allContainerDataItems, ["name"], ["asc"])
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((list, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <DefaultConfigCard
                      list={list}
                      index={index}
                      configureButton={configureButton}
                      proCategory={proCategory}
                    />
                  </Grid>
                ))
            ) : (
              <div className={classes.warningStyle}>
                <WarningIcon
                  fontSize="medium"
                  className={classes.iconWrapper}
                />
                &nbsp;
                <Typography
                  className={classes.infoLabel}
                  variant="h6"
                  color="textPrimary"
                >
                  {t("common:productSelector.textMessage.noModelsAvailable")}
                </Typography>
              </div>
            )}
          </Grid>
        </Box>
      </Box>
    )
  );
};

export default DefaultConfigs;

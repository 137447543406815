import { useMutation } from "@apollo/client";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CREATE_CASE_REQUEST_ASSISTANCE } from "../../GraphQL";
import { ExternalProps } from "../../contexts/externalPropsContext";
import CpqButton from "../../lib/common/CpqButton";
import displayNotistack from "../../lib/common/SnackBarUtils";
import AddNotesAttachmentsList from "../../models/opportunity/AddNotesAttachmentsList";
import { REGIONS } from "../Configurator/Rsku/Constants";
const MAX_NUM_OF_ATTACHMENTS = 5;
const MAX_ATTACHMENT_SIZE = 15700000;
const useStyles = makeStyles(theme => ({
  rootIcon: {
    height: 150,
    width: 150
  },
  dragNdropStyle: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 10,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: theme.palette.grey[400]
  },
  textColor: {
    color: "#0096d6"
  },
  asterisk: {
    color: "#FF7272"
  },
  disableText: {
    color: theme.palette.text.disabled
  },
  checkCircleOutlineRoundedIcon: {
    width: "auto",
    height: "150px",
    color: "rgb(0, 150, 214)"
  },
  dialogContnet: {
    textAlign: "center"
  },
  typography: {
    fontWeight: "700"
  },

  buttonContainer: {
    "&>div": {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    display: "flex",
    alignItems: "center"
  }
}));

export default function RequestAssistanceDialog(props) {
  const { setOpen } = props;
  const classes = useStyles();
  const [description, setDescription] = useState("");
  const [toggle, setToggle] = useState(false);
  const [value, setValue] = useState("Request Assistance - Configurations");
  const [caseNumber, setCaseNumber] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbar = { enqueueSnackbar, closeSnackbar };
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const successAttachmentRef = useRef(0);
  const { channelRegion, userEmail, country, clientLocale, countryCode } =
    React.useContext(ExternalProps);
  const countryRegion = useSelector(
    state => state?.productSelector?.countryRegion
  );
  const showWarning = () => {
    displayNotistack.warning(t("common:snackbar.uploadLimit"), {
      vertical: "bottom"
    });
  };

  const [RequestAssistance] = useMutation(CREATE_CASE_REQUEST_ASSISTANCE, {
    onCompleted(data) {
      if (data.RequestAssistance) {
        setCaseNumber(data.RequestAssistance);
        setLoading(false);
        setToggle(true);
      } else {
        displayNotistack.error(
          false,
          RequestAssistance ||
            t("common:productSelector.errorMessage.graphQLErrors"),
          "error",
          6000
        );
        setLoading(false);
      }
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
      if (localStorage.getItem("isDebug") === "true") {
        if (graphQLErrors) {
          console.log(graphQLErrors);
        }
        if (networkError) {
          console.log(networkError);
        }
      }
    }
  });
  const callRequestAssistance = createAttaches => {
    RequestAssistance({
      variables: {
        input: {
          caseType: "CPQ Channel",
          caseReason: value,
          caseScenario: value,
          accountName: "HPI",
          title: value,
          description: description,
          priorityCode: "Medium",
          originatingSystem: "OneConfig",
          requestorRole: "Partner",
          requestorLanguage: clientLocale || "EN",
          caseRequestorEmail: userEmail,
          countryOfSubmitter: country || countryCode || "",
          region: REGIONS[channelRegion] || REGIONS[countryRegion] || "WW",
          customerSegment: "",
          updateReceived: "",
          pbmhpSalesRepEmail: "",
          relatedDocuments: "",
          routeToMarket: "",
          caseRequestor: "",
          subRegion: "",
          createAttaches: createAttaches
        }
      }
    });
  };

  const handleSave = () => {
    setLoading(true);
    if (attachments.length) {
      const createAttaches = attachments.map(attachment => ({
        file: attachment.documentBody,
        fileName: attachment.fileName
      }));
      callRequestAssistance(createAttaches);
    } else {
      callRequestAssistance();
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      let existingAttachments = attachments;
      let fileExceedsSize = [];
      let invalidFiles = [];
      let isLimitExceeds = false;
      if (rejectedFiles) {
        console.log(rejectedFiles, "rejectedFiles");
        rejectedFiles.forEach(each => {
          if (each?.size > MAX_ATTACHMENT_SIZE) {
            fileExceedsSize.push(each?.name);
          } else {
            invalidFiles.push(each?.name);
          }
        });
      }
      if (fileExceedsSize.length > 0) {
        enqueueSnackbar(
          fileExceedsSize.join(", ") +
            " " +
            t("common:snackbar.reUploadFileLowerThan15MB"),
          {
            variant: "error",
            persist: false
          }
        );
      }
      if (invalidFiles.length > 0) {
        displayNotistack.error(false, t("common:snackbar.fileFormat"));
      }
      acceptedFiles.forEach((file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          if (!isLimitExceeds) {
            let encoded = reader?.result
              ?.toString()
              .replace(/^data:(.*,)?/, "");
            if (encoded?.length % 4 > 0) {
              encoded += "=".repeat(4 - (encoded?.length % 4));
            }
            const newAttachment = {
              fileName: file.name,
              createdAt: moment(Date.now()).format("DD/MM/YYYY[ ]HH:mm:ss"),
              userName: userEmail,
              // userName: `${partnerUserRecord?.user?.firstName} ${partnerUserRecord?.user?.lastName}`,
              newRecord: true,
              uploadedTime: new Date(),
              mimeType: file.name?.substr(file.name.lastIndexOf(".") + 1) || "",
              documentBody: encoded || "",
              file: reader?.result
            };

            existingAttachments = [...existingAttachments, newAttachment];
            let newlength = existingAttachments.filter(
              each => each.newRecord
            ).length;
            if (newlength === MAX_NUM_OF_ATTACHMENTS) {
              setAttachments(existingAttachments);
              showWarning();
            } else if (newlength > MAX_NUM_OF_ATTACHMENTS) {
              displayNotistack.info(t("common:snackbar.fileLengthLimit"), {
                vertical: "bottom"
              });
              isLimitExceeds = true;
            } else {
              setAttachments(existingAttachments);
            }
          }
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachments]
  );

  const handleChange = e => {
    let value = e.target.value;
    setValue(value);
  };
  const issueReasons = [
    {
      value: "Request Assistance - Configurations",
      label: "Configuration"
    }
  ];

  return (
    <>
      <Dialog
        maxWidth="md"
        open={!toggle}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          <Typography variant="h6">
            <b>{t("common:dialog.requestAssistanceDialog.title")}</b>
          </Typography>
          <Grid className={classes.closeButton}>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => {
                setOpen(false);
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                <b>{t("common:dialog.requestAssistanceDialog.issueTitle")}</b>
                <span className={classes.asterisk}>*</span>
              </Typography>

              <Paper>
                <Select
                  margin="dense"
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={handleChange}
                  fullWidth
                >
                  {issueReasons.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>{t("common:dialog.requestAssistanceDialog.description")}</b>
                <span className={classes.asterisk}>* </span>
              </Typography>
              <Paper>
                <TextField
                  placeholder={t(
                    "common:dialog.requestAssistanceDialog.placeholder"
                  )}
                  id="outlined-multiline-static"
                  multiline
                  variant="outlined"
                  fullWidth={true}
                  minRows={5}
                  onChange={e => setDescription(e.target.value)}
                  InputProps={{
                    inputProps: {
                      maxLength: 500
                    }
                  }}
                  required
                />
              </Paper>
            </Grid>
            {attachments?.filter(each => each.newRecord).length <
              MAX_NUM_OF_ATTACHMENTS && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <b>
                    {t(
                      "common:dialog.requestAssistanceDialog.screenshotOrAttachmentsTitle"
                    )}
                  </b>
                </Typography>
                <Typography className={classes.disableText}>
                  {t("common:dialog.requestAssistanceDialog.inputScreenshot")}
                </Typography>

                <Grid item>
                  <Box
                    p={1}
                    textAlign="center"
                    className={classes.dragNdropStyle}
                  >
                    <Dropzone
                      onDrop={handleDrop}
                      multiple
                      accept={
                        ".jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .csv"
                      }
                      maxSize={MAX_ATTACHMENT_SIZE}
                      disabled={loading}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Grid container direction="column" {...getRootProps()}>
                          <Typography
                            variant="body1"
                            style={{ marginTop: "20px" }}
                          >
                            {t(
                              "common:dialog.requestAssistanceDialog.dropFiles"
                            )}
                          </Typography>
                          <Typography variant="body1">
                            {t("common:dialog.requestAssistanceDialog.or")}
                            <Typography className={classes.textColor}>
                              {t(
                                "common:dialog.requestAssistanceDialog.browseFolder"
                              )}
                            </Typography>
                            <Typography>
                              {t(
                                "common:dialog.requestAssistanceDialog.fileLimit"
                              )}
                              <br />
                              {t(
                                "common:dialog.requestAssistanceDialog.supportedFile"
                              )}
                              {t(
                                "common:dialog.requestAssistanceDialog.supportedFiles"
                              )}
                            </Typography>
                          </Typography>
                          <input
                            {...getInputProps()}
                            style={{ visibility: "hidden", width: 0 }}
                          />
                        </Grid>
                      )}
                    </Dropzone>
                  </Box>
                </Grid>
              </Grid>
            )}
            {attachments?.length > 0 && (
              <Grid item xs={12}>
                <AddNotesAttachmentsList
                  attachments={attachments}
                  setAttachments={setAttachments}
                  requestAssist={true}
                />
              </Grid>
            )}
            <Grid item container className={classes.buttonContainer}>
              <CpqButton
                id="createCase"
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={!description?.length || loading}
                loading={loading}
              >
                {t("common:dialog.requestAssistanceDialog.save")}
              </CpqButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {toggle && (
        <Dialog open={toggle}>
          <DialogTitle>
            <Typography variant="h6">REQUEST ASSISTANCE</Typography>
          </DialogTitle>
          <CheckCircleOutlineRoundedIcon
            color="primary"
            className={classes.checkCircleOutlineRoundedIcon}
          />
          <DialogContent className={classes.dialogContnet}>
            <Typography className={classes.typography}>
              {t(
                "common:dialog.requestAssistanceDialog.requestAssistanceTicket"
              )}
              &nbsp;
              {caseNumber}&nbsp;
              {t("common:dialog.requestAssistanceDialog.alreadyCreated")}
            </Typography>
            <Typography>
              System will trigger notification to your email for further
              updates.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setToggle(false);
                setOpen(false);
              }}
              color="primary"
            >
              {t("common:dialog.requestAssistanceDialog.close")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

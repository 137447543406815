import { useLazyQuery, useQuery } from "@apollo/client";
import { StyledEngineProvider } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import { ThemeProvider, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import RequestAssistanceButton from "../../../../src/components/RequestAssistance/RequestAssistanceButton";
import { FETCH_USER_DETAILS, PRODUCT_ACCESS_TYPE } from "../../../GraphQL";
import useInvokeRskuUserAccess from "../../../components/Configurator/Rsku/hooks/useInvokeRskuUserAccess";
import { HPMuiTheme } from "../../../config/theme";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import CategoryCard from "./CategoryCard";
import CategorySeries from "./CategorySeries";
import { RECEIVE_USER_DETAILS, SET_PROCATEGORY } from "./Constants";
import DefaultConfigSearchFilters from "./DefaultConfigSearchFilters";
import SearchConfigs from "./SearchConfigs";
const useStyles = makeStyles(theme => ({
  midPositioner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  },
  spinner: {
    color: "rgb(41, 168, 221)"
  },
  modelConfig: {
    position: "relative",
    width: "auto",
    height: "68px",
    marginLeft: "0px",
    top: "22px",
    fontWeight: "bold"
  },
  radioLabel: {
    fontSize: "12px !important"
  },
  box: {
    margin: "20px 0px 0px 0px"
  },
  cardMargin: { marginRight: "162px" }
}));

// Inspired by blueprintjs
function StyledRadio(props) {
  return <Radio color="primary" size="small" {...props} />;
}
const ProductSelector = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [prodChangeFlag, setProdChangeFlag] = useState(false);
  const {
    userEmail,
    resellerID,
    userType,
    quoteNumber,
    channelRegion,
    mDCPOrgID,
    companyId,
    isPartnerPortalFlag = false,
    isDisplayProdCountry = false,
    visibility = {},
    setProductCategory,
    isStandaloneAccess,
    configOrigin,
    hasPrintAccess,
    routeToMarket
  } = React.useContext(ExternalProps);
  let { countryCode, country } = React.useContext(ExternalProps);
  countryCode = countryCode ? countryCode : country;
  let { productCategory } = React.useContext(ExternalProps);
  const { showCustomBandedFlag = false } = visibility;
  const prdCountry = window.sessionStorage.getItem("prdCountry" + userEmail);
  const fetchCountryOCA = window.sessionStorage.getItem(
    "countryFetchFromUserOCA" + userEmail
  );
  const { productRegionCode, productCountryCode } = useSelector(
    state => state.rskuReducer
  );
  const countryCodeFromFetchUserDetails = useSelector(
    state => state.productSelector?.userInfo?.userDetails?.countryCode
  );
  const businessModel = useSelector(
    state => state.productSelector?.businessModel
  );
  const initialCountryCode = isDisplayProdCountry
    ? window.sessionStorage.getItem("prdCountry" + userEmail)
    : countryCode;
  const [noShowModel, setNoShowModel] = useState(false);
  //define a local state which will store the product Access type
  let [productAccessType, setProductAccessType] = useState(null);

  //Defining states for loading and error
  const [error, setError] = useState({ isError: false, message: "" });
  const [isLoading, setIsLoading] = useState(true);

  //Defining state to set the productCategory based on the access type.
  const [proCategory, setProCategory] = useState(null);
  const skipProCategoryCall = Boolean(configOrigin === "OCA" && hasPrintAccess);
  const isPartnerUser = userType?.toLowerCase() === "partner";

  //useEffect to fetch the data from API

  useQuery(PRODUCT_ACCESS_TYPE, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        userType,
        companyId,
        country: countryCode ? countryCode : ""
      }
    },
    skip: skipProCategoryCall,
    onCompleted(response) {
      setIsLoading(false);
      if (response) {
        setProductAccessType(response);
      }
    },
    onError({ queryError, networkError }) {
      setIsLoading(false);
      if (queryError && queryError instanceof Array) {
        setError({ isError: true, message: queryError.join("/n") });
      }
    }
  });
  const [getUserDetails] = useLazyQuery(FETCH_USER_DETAILS, {
    fetchPolicy: "no-cache",
    onCompleted({ fetchUserDetails }) {
      const loggedIdUserDetails = Array.isArray(fetchUserDetails)
        ? fetchUserDetails[0]
        : fetchUserDetails;
      // TODO: default region to NA ?
      if (!loggedIdUserDetails["region"]) loggedIdUserDetails["region"] = "NA";
      dispatch({ type: RECEIVE_USER_DETAILS, payload: loggedIdUserDetails });
      if (isDisplayProdCountry && isStandaloneAccess) {
        window.sessionStorage.setItem(
          "countryFetchFromUserOCA" + userEmail,
          fetchUserDetails[0].countryCode
        );
      }
    }
  });

  const invokeRskuUserAccess = useInvokeRskuUserAccess();
  useEffect(() => {
    if (productCountryCode && productRegionCode) {
      const input = {
        variables: {
          userEmail,
          countryCode: productCountryCode,
          regionCode: productRegionCode
        }
      };
      configOrigin !== "OCA" && invokeRskuUserAccess(input);
    }
  }, [productCountryCode, productRegionCode]);

  useEffect(() => {
    if (!prdCountry && fetchCountryOCA === null && isDisplayProdCountry) {
      getUserDetails();
    }
    if (isStandaloneAccess) {
      dispatch({ type: "SET_BUSINESS_MODEL", payload: routeToMarket });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //update the props productCategory as soon as data gets loaded
  useEffect(() => {
    //if prodChangeFlag is true, ignore initializations.
    if (prodChangeFlag === true) {
      setProdChangeFlag(false);
      return;
    }
    //case 1, if access is all, compute or null we will set pro category to compute
    if (
      (productAccessType &&
        productAccessType?.getProductAccessType?.productAccessType === "" &&
        !isPartnerUser) ||
      productAccessType?.getProductAccessType?.productAccessType === "all" ||
      productAccessType?.getProductAccessType?.productAccessType ===
        "compute" ||
      !productAccessType
    ) {
      setProCategory(skipProCategoryCall ? "print" : "compute");
      setProductCategory("compute");
    } else if (
      productAccessType?.getProductAccessType?.productAccessType === "" &&
      isPartnerUser
    ) {
      setProCategory("compute");
    }
    //case 2 if access is only print then we set proCategory as print
    if (
      productAccessType &&
      productAccessType?.getProductAccessType?.productAccessType === "print"
    ) {
      setProCategory("print");
      setProductCategory("print");
    }
  }, [productAccessType]);

  useEffect(() => {
    if (proCategory) {
      dispatch({
        type: "SET_INITIAL_VALUES",
        payload: {
          showCustomBandedFlag,
          isPartnerPortalFlag,
          initialCountryCode,
          userEmail,
          countryCode,
          resellerID,
          quoteNumber,
          channelRegion,
          mDCPOrgID,
          productCategory: proCategory,
          category: proCategory,
          countryCodeFromFetchUserDetails: fetchCountryOCA
            ? isDisplayProdCountry && isStandaloneAccess && fetchCountryOCA
            : isDisplayProdCountry &&
              isStandaloneAccess &&
              countryCodeFromFetchUserDetails
        }
      });
      setIsLoading(false);
    }
  }, [
    initialCountryCode,
    showCustomBandedFlag,
    isPartnerPortalFlag,
    userEmail,
    productCategory,
    countryCode,
    resellerID,
    quoteNumber,
    channelRegion,
    mDCPOrgID,
    dispatch,
    proCategory,
    countryCodeFromFetchUserDetails
  ]);

  const [showDefaultConfig, setShowDefaultConfig] = useState(false);
  const [baseUnitValue, setBaseUnitValue] = useState("");
  const imgBaseUrl = process.env.REACT_APP_IMG_BASE_URL;
  const baseCategories = [
    {
      id: "compute",
      show: ["compute", "", null, "all"].includes(
        productAccessType?.getProductAccessType?.productAccessType
      ),
      detail: {
        description: "PC",
        name: "compute",
        imageUrl: `${imgBaseUrl}/pccompute.svg`
      }
    },
    {
      id: "print",
      show:
        productAccessType?.getProductAccessType?.productAccessType ===
          "print" ||
        (productAccessType?.getProductAccessType?.productAccessType === "all" &&
          configOrigin !== "OCA"),
      detail: {
        description: "Printer",
        name: "print",
        imageUrl: `${imgBaseUrl}/print.svg`
      }
    },
    {
      id: "poly",
      show: productAccessType?.getProductAccessType?.polyAccessType === "show",
      detail: {
        description: "Poly Solutions",
        name: "poly",
        imageUrl: `${imgBaseUrl}/polylogo.svg`
      }
    }
  ];

  //Function which handles the radio button changes to fetch the new productCategory
  const handleProductCategoryChange = category => {
    setProCategory(category.name);
    setProdChangeFlag(true);
    setProductCategory(category.name);
    setBaseUnitValue("");
    dispatch({
      type: SET_PROCATEGORY,
      payload: category.name
    });
  };
  const shouldRadioGroupBeDisplayed = () => {
    if (
      ["", null, "compute", "print"].includes(
        productAccessType?.getProductAccessType?.productAccessType
      )
    ) {
      if (productAccessType?.getProductAccessType?.polyAccessType !== "show") {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };
  return (
    <>
      {isLoading ? (
        <div className={classes.midPositioner}>
          <CircularProgress className={classes.spinner} />
        </div>
      ) : error.isError ? (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={HPMuiTheme}>
            <div className={classes.midPositioner}>{error.message}</div>
          </ThemeProvider>
        </StyledEngineProvider>
      ) : (
        <React.Fragment>
          <Container
            sx={{
              marginBottom: "10%",
              marginLeft: "8%",
              paddingTop: "5%",
              width: "auto !important",
              "&.MuiContainer-root": {
                maxWidth: "1280px"
              }
            }}
          >
            {configOrigin !== "OCA" && (
              <Box className={classes.modelConfig}>
                <Typography
                  variant="h5"
                  align="left"
                  sx={{ fontWeight: "bold" }}
                >
                  {t("common:productSelector.textMessage.modelConfiguration")}
                </Typography>
              </Box>
            )}
            {showCustomBandedFlag && configOrigin !== "OCA" && (
              <SearchConfigs
                setShowDefaultConfig={setShowDefaultConfig}
                proCategory={proCategory}
              />
            )}
            {shouldRadioGroupBeDisplayed() &&
              baseCategories.filter(category => category.show).length > 1 && (
                <div className={classes.cardMargin}>
                  <Box my={3}>
                    <Typography variant="h6" align="left">
                      {t("common:productSelector.textMessage.mainProducts")}
                    </Typography>
                  </Box>
                  <Box my={3}>
                    <Grid container spacing={2}>
                      {baseCategories.map((category, index) =>
                        category.show ? (
                          <Grid
                            key={index}
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                            className={classes.card}
                          >
                            <CategoryCard
                              active={
                                proCategory && proCategory === category.id
                              }
                              category={category.detail}
                              handleClick={category =>
                                handleProductCategoryChange(category)
                              }
                            />
                          </Grid>
                        ) : (
                          ""
                        )
                      )}
                    </Grid>
                  </Box>
                </div>
              )}
            <DefaultConfigSearchFilters
              setBaseUnitValue={setBaseUnitValue}
              baseUnitValue={baseUnitValue}
              setShowDefaultConfig={setShowDefaultConfig}
              setNoShowModel={setNoShowModel}
              proCategory={proCategory}
            />
            <CategorySeries
              setBaseUnitValue={setBaseUnitValue}
              setShowDefaultConfig={setShowDefaultConfig}
              showDefaultConfig={showDefaultConfig}
              proCategory={proCategory}
              noShowModel={noShowModel}
            />
            {configOrigin !== "OCA" &&
              isPartnerPortalFlag &&
              isStandaloneAccess &&
              isPartnerUser && <RequestAssistanceButton />}
          </Container>
        </React.Fragment>
      )}
    </>
  );
};

export default ProductSelector;

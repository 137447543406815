import { getItemConflictsLoc } from "../Localization/util.js";
import {
  getChoiceConflicts,
  getHiddenChoiceConflicts,
  getItemConflicts,
  getItemConflictsNn
} from "../NeuralNet/processOutput.js";
import {
  getPlcConflicts,
  getgaDateConflicts,
  removeCPconflicts
} from "../util.js";
import {
  filterChoiceConflicts,
  getAllCounterConflicts,
  getConflictsForAllSelectedItemInPreconFalseChoices,
  getConflictsForAllUnselectedItemInRequiredChoices,
  getConflictsForSelectedItemInPreconFalseItems,
  preConflictDetection
} from "./util.js";

//src/webasm/validate.go
export const conflictDetection = model => {
  let modelData = preConflictDetection(model);
  modelData.isComplete = false;
  modelData.isConflict = false;
  modelData.isCounterConflict = false;
  modelData.isPlcConflict = false;
  modelData.isValid = false;
  modelData.isGaDateConflict = false;
  modelData.isHiddenConflict = false;
  modelData.isChoiceConflict = false;
  // CASE 1

  const plcCflt = [...getPlcConflicts(modelData)];
  let itemConflictsNn = [];
  let hiddenConflicts = [];
  itemConflictsNn = [...itemConflictsNn, ...getItemConflictsNn()];
  itemConflictsNn = [...itemConflictsNn, ...getItemConflictsLoc()];
  const finalConflict = removeCPconflicts(itemConflictsNn, modelData);
  const gaDateConflicts = [...getgaDateConflicts(modelData)];
  hiddenConflicts = [...hiddenConflicts, ...getHiddenChoiceConflicts()];
  const choiceConflicts = [...getChoiceConflicts()];
  const finalChoiceConflicts = filterChoiceConflicts(
    choiceConflicts,
    modelData
  );
  let icCflct = [
    ...getConflictsForAllSelectedItemInPreconFalseChoices(modelData)
  ];
  // CASE 2
  icCflct = [
    ...icCflct,
    ...getConflictsForAllUnselectedItemInRequiredChoices(modelData)
  ];

  // CASE 3
  icCflct = [
    ...icCflct,
    ...getConflictsForSelectedItemInPreconFalseItems(modelData, finalConflict)
  ];

  if (hiddenConflicts?.length > 0) {
    modelData.isHiddenConflict = true;
  }
  if (finalChoiceConflicts?.length > 0) {
    modelData.isChoiceConflict = true;
  }

  const itemConflicts = getItemConflicts();

  modelData.conflicts = [
    {
      icConflicts: icCflct,
      counterConflicts: getAllCounterConflicts(modelData), // TODO getAllCounterConflicts(modelData), // CASE 4
      itemConflicts: itemConflicts, // it is set from processOutput for cse and eff
      plcConflicts: plcCflt,
      itemConflictsList: finalConflict,
      hiddenChoiceConflicts: hiddenConflicts,
      choiceConflicts: finalChoiceConflicts,
      gaDateConflicts: gaDateConflicts
    }
  ];

  if (
    modelData.isComplete &&
    !modelData.isConflict &&
    !modelData.isCounterConflict &&
    !modelData.isPlcConflict &&
    !modelData.isGaDateConflict &&
    !modelData.isChoiceConflict
  ) {
    modelData.isValid = true;
  }
  return modelData;
};

// module.exports = {
//   conflictDetection
// };
